export const convertS3UriToHttpsUrl = (s3Uri: string) => {
	const parts = s3Uri?.split("/");
	const bucketName = parts && parts[2];
	const filePath = parts && parts.slice(3).join("/");
	const region = "us-east-1";

	return `https://${bucketName}.s3.${region}.amazonaws.com/${filePath}`;
};

export const getImageMeta = (url: string) =>
	new Promise<HTMLImageElement>((resolve, reject) => {
		const image = new Image();
		image.addEventListener("load", () => resolve(image));
		image.addEventListener("error", (error) => reject(error));
		image.setAttribute("crossOrigin", "anonymous");
		image.src = url;
	});
