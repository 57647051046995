import { useEffect, useState } from "react";

interface IImage {
	name: string;
	className?: string;
	onClick?: () => void;
}

const ImageViewer = ({ name, className, onClick, ...rest }: IImage) => {
	const [path, setPath] = useState("");

	useEffect(() => {
		const importIcon = async () => {
			try {
				const { default: _path } = await import(/* @vite-ignore */ `../../../assets/${name}?import`);
				setPath(_path);
			} catch (err) {
				console.error(err);
			}
		};
		importIcon();
	}, [name]);

	return path && <img className={className} src={path} alt=" " onClick={onClick} {...rest} />;
};

export default ImageViewer;
