import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import RouterConstants from "../../constants/RouterConstants";
import useOrgNavigation from "../../hooks/useOrgNavigation";
import { useAppStore } from "../../hooks/useStores";
import TabsLayout from "../../layout/TabsLayout/TabsLayout";

const API = () => {
	const { t } = useTranslation("translation", { keyPrefix: "apis_page" });
	const { orgNavigationHandler } = useOrgNavigation();
	const { appStore } = useAppStore();
	const subTabs = ["base-platform", "ecommerce-suite", "branded-generation"];
	const primaryButtonClickHandler = () => {
		orgNavigationHandler(RouterConstants.AUTH_KEYS.fullPath);
	};

	return (
		<>
			<TabsLayout
				title="API"
				subTabs={subTabs}
				isLoading={appStore.isLoading}
				primaryButtonLabel={t("getAPIKey")}
				primaryButtonClickHandler={primaryButtonClickHandler}
				hideTitle={true}
			>
				<Outlet />
			</TabsLayout>
		</>
	);
};

export default observer(API);
