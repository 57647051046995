function Customize({ size }: { size: number }) {
	return (
		<svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.1981 5.06265C11.0371 4.47036 10.6857 3.9475 10.1981 3.57473C9.71049 3.20196 9.11377 3 8.5 3C7.88623 3 7.28951 3.20196 6.80191 3.57473C6.3143 3.9475 5.96291 4.47036 5.80194 5.06265H3V6.56261H5.80194C5.96291 7.1549 6.3143 7.67776 6.80191 8.05052C7.28951 8.42329 7.88623 8.62526 8.5 8.62526C9.11377 8.62526 9.71049 8.42329 10.1981 8.05052C10.6857 7.67776 11.0371 7.1549 11.1981 6.56261H20.9996V5.06265H11.1981ZM8.49962 7.1251C8.24004 7.1251 7.98629 7.04812 7.77045 6.90391C7.55462 6.75969 7.3864 6.55471 7.28706 6.31489C7.18772 6.07507 7.16173 5.81117 7.21237 5.55658C7.26301 5.30198 7.38802 5.06812 7.57157 4.88457C7.75512 4.70102 7.98898 4.57602 8.24357 4.52538C8.49817 4.47474 8.76206 4.50073 9.00188 4.60006C9.24171 4.6994 9.44669 4.86763 9.5909 5.08346C9.73512 5.29929 9.81209 5.55305 9.81209 5.81263C9.8115 6.16053 9.67303 6.49402 9.42702 6.74003C9.18102 6.98603 8.84753 7.1245 8.49962 7.1251Z"
				fill="currentColor"
			/>
			<path
				d="M15.5 9.18736C14.8868 9.18956 14.2912 9.39233 13.8041 9.76473C13.317 10.1371 12.9652 10.6587 12.8023 11.2498H3V12.7498H12.8023C12.963 13.3422 13.3142 13.8653 13.8018 14.2382C14.2894 14.6111 14.8861 14.8132 15.5 14.8132C16.1138 14.8132 16.7106 14.6111 17.1981 14.2382C17.6857 13.8653 18.0369 13.3422 18.1977 12.7498H20.9996V11.2498H18.1977C18.0348 10.6587 17.6829 10.1371 17.1958 9.76473C16.7087 9.39233 16.1131 9.18956 15.5 9.18736ZM15.5 13.3123C15.2404 13.3123 14.9866 13.2353 14.7708 13.0911C14.555 12.9469 14.3867 12.7419 14.2874 12.5021C14.1881 12.2622 14.1621 11.9983 14.2127 11.7437C14.2634 11.4892 14.3884 11.2553 14.5719 11.0717C14.7555 10.8882 14.9893 10.7632 15.2439 10.7125C15.4985 10.6619 15.7624 10.6879 16.0022 10.7872C16.242 10.8866 16.447 11.0548 16.5912 11.2706C16.7355 11.4865 16.8124 11.7402 16.8124 11.9998C16.812 12.3478 16.6736 12.6814 16.4276 12.9274C16.1815 13.1735 15.8479 13.3119 15.5 13.3123Z"
				fill="currentColor"
			/>
			<path
				d="M8.49962 15.3747C7.88609 15.3764 7.29 15.579 6.80244 15.9514C6.31489 16.3239 5.96269 16.8457 5.79969 17.4372H3V18.9371H5.80194C5.96291 19.5294 6.3143 20.0523 6.80191 20.425C7.28951 20.7978 7.88623 20.9998 8.5 20.9998C9.11377 20.9998 9.71049 20.7978 10.1981 20.425C10.6857 20.0523 11.0371 19.5294 11.1981 18.9371H20.9996V17.4372H11.1981C11.0351 16.8459 10.6832 16.3243 10.1959 15.9519C9.70865 15.5795 9.1129 15.3768 8.49962 15.3747ZM8.49962 19.4996C8.24004 19.4996 7.98629 19.4226 7.77045 19.2784C7.55462 19.1342 7.3864 18.9292 7.28706 18.6894C7.18772 18.4496 7.16173 18.1857 7.21237 17.9311C7.26301 17.6765 7.38802 17.4426 7.57157 17.2591C7.75512 17.0755 7.98898 16.9505 8.24357 16.8999C8.49817 16.8493 8.76206 16.8753 9.00188 16.9746C9.24171 17.0739 9.44669 17.2421 9.5909 17.458C9.73512 17.6738 9.81209 17.9276 9.81209 18.1872C9.8115 18.5351 9.67303 18.8685 9.42702 19.1145C9.18102 19.3606 8.84753 19.499 8.49962 19.4996Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default Customize;
