import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { getSelectedOrganization } from "../../helpers/localStorage";
import { useAppStore } from "../../hooks/useStores";
import TabsLayout from "../../layout/TabsLayout/TabsLayout";

const Account = () => {
	const { t } = useTranslation();
	const { appStore } = useAppStore();
	const subTabs = ["usage", "api-keys", "users", "subscription"];
	const selectedOrganization = getSelectedOrganization()?.organization.name ?? "";

	return (
		<>
			<TabsLayout
				title={t("briaOrgSettings", { selectedOrganization })}
				subTabs={subTabs}
				isLoading={appStore.isLoading}
			>
				<Outlet />
			</TabsLayout>
		</>
	);
};

export default observer(Account);
