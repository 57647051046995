import { Box, Divider, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import StaticDropdown, {
	TailoredStyles,
	TailoredSubStyles,
} from "../../../../../../components/common/DropDowns/StaticDropdown";
import { useAppStore } from "../../../../../../hooks/useStores";
import InputLayout from "../../../../../../layout/InputLayout/InputLayout";
import { ImageToImageConfigType, PlaygroundImage } from "../../../../../../models/image-to-image";
import styles from "./StyleConfig.module.scss";

export type StyleConfigType = {
	tailored_style?: TailoredStyles;
	sub_style?: TailoredSubStyles;
	face_seed?: number;
	body_seed?: number;
	face_sd_edit?: number;
	is_normalize_face?: boolean;
	prompt?: string;
	image?: File;
};

const StyleConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config.features.style" });
	const { imageToImageStore, playgroundStore } = useAppStore();
	const selectedImages: PlaygroundImage[] = playgroundStore.getSelectedImages();
	const countSelected = playgroundStore.getSelectedImages().length;
	const isGenerateEnabled =
		((imageToImageStore.config.style &&
			imageToImageStore.config.style.tailored_style &&
			imageToImageStore.config.style.sub_style) ||
			(imageToImageStore.config.background && imageToImageStore.config.background.remove) ||
			imageToImageStore.config.brand ||
			imageToImageStore.config.size) &&
		countSelected &&
		countSelected * imageToImageStore.config.num_results <= 35 &&
		!playgroundStore.playgroundResults[playgroundStore.playgroundResults.length - 1]?.images.some(
			(image) => image.loading,
		);
	const isMixEnabled =
		selectedImages.length === 2 &&
		selectedImages.every(
			(image) =>
				image.type === "imageToImage" &&
				(image.config as ImageToImageConfigType)?.original_image.id ===
					(selectedImages[0].config as ImageToImageConfigType)?.original_image.id,
		) &&
		isGenerateEnabled;

	const isRefineColorEnabled =
		selectedImages.length &&
		selectedImages.every(
			(img) => (img.config as ImageToImageConfigType).style?.tailored_style && isGenerateEnabled,
		);

	return (
		<Box className={styles.root}>
			<InputLayout label={t("tailoredStyles.label")}>
				<StaticDropdown<TailoredStyles>
					type="tailoredStyles"
					value={imageToImageStore.config?.style?.tailored_style}
					onChange={(e) =>
						imageToImageStore.handleConfigChange("style", {
							...imageToImageStore.config.style,
							tailored_style: e.target.value as TailoredStyles,
						})
					}
					placeholder={t("tailoredStyles.placeholder")}
				/>
			</InputLayout>
			{imageToImageStore.config?.style?.tailored_style && (
				<InputLayout label={t("tailoredSubStyles.label")}>
					<StaticDropdown<TailoredSubStyles>
						type={`tailoredSubStyles.${imageToImageStore.config?.style?.tailored_style}` as any}
						value={imageToImageStore.config?.style?.sub_style}
						onChange={(e) =>
							imageToImageStore.handleConfigChange("style", {
								...imageToImageStore.config.style,
								sub_style: e.target.value as TailoredSubStyles,
							})
						}
						placeholder={t("tailoredSubStyles.placeholder")}
					/>
				</InputLayout>
			)}
			<Divider />
			<Box className={styles.actionBtnWrapper}>
				<Typography className={styles.actionBtnLabel}>{t("mixFaceBody.label")}</Typography>
				<BriaButton
					buttonType="secondarySmall"
					disabled={!isMixEnabled}
					onClick={imageToImageStore.mixFaceBody}
				>
					{t("mixFaceBody.button")}
				</BriaButton>
			</Box>
			<Divider />
			<Box className={styles.actionBtnWrapper}>
				<Typography className={styles.actionBtnLabel}>{t("refineColor.label")}</Typography>
				<BriaButton
					buttonType="secondarySmall"
					disabled={!isRefineColorEnabled}
					onClick={imageToImageStore.refineColor}
				>
					{t("refineColor.button")}
				</BriaButton>
			</Box>

			{/* <InputLayout label={t("prompt.label")}>
				<BriaInput
					value={imageToImageStore.config?.style?.prompt}
					onChange={(e) =>
						imageToImageStore.handleConfigChange("style", {
							...imageToImageStore.config.style,
							prompt: e.target.value as TailoredStyles,
						})
					}
					multiline
					minRows={4}
					placeholder={t("prompt.placeholder")}
					inputProps={{ className: styles.inputBase }}
				/>
			</InputLayout>
			<InputLayout label={t("refImage.label")}>
				<ImageUploader description={t("refImage.description")} />
			</InputLayout>
			<Divider>{t("configDivider")}</Divider>
			<InputLayout label={t("images.label")}>
				<Gallery
					images={Array.from({ length: 6 }).map((_) => ({ url: "https://picsum.photos/200" }))}
					maxInLine={3}
				/>
			</InputLayout> */}
		</Box>
	);
};

export default observer(StyleConfig);
