import Canvas from "../../../../components/Canvas";
import { ICanvasProps } from "../../../../components/Canvas/canvas.tsx";
import useDesignEditorContext from "../../../../hooks/useDesignEditorContext";
import { useEditor } from "../../../../hooks/useEditor";
import ContextMenu from "../ContextMenu";
import Playback from "../Playback";

export default function (props: ICanvasProps) {
	const { displayPlayback } = useDesignEditorContext();
	const editor = useEditor();
	editor?.canvas?.setBackgroundColor("#FAFAFA");
	return (
		<div style={{ flex: 1, display: "flex", position: "relative" }}>
			{displayPlayback && <Playback />}
			<ContextMenu />
			<Canvas
				id={props.id}
				config={
					props.config ?? {
						background: "#f1f2f6",
						controlsPosition: {
							rotation: "BOTTOM",
						},
						shadow: {
							blur: 4,
							color: "#fcfcfc",
							offsetX: 0,
							offsetY: 0,
						},
					}
				}
			/>
		</div>
	);
}
