import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Menu, MenuItem, PopoverOrigin, Typography } from "@mui/material";
import { useState } from "react";
import { DownArrow } from "../../../assets/images/icons/DownArrow.tsx";
import styles from "./ActionsDotsDropdown.module.scss";

interface IProps {
	label?: any;
	items: any;
	transformOrigin?: PopoverOrigin;
}

export default function ActionsDotsDropdown({ label, items, transformOrigin }: IProps) {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<Box className={styles.actionContainer}>
			<Box onClick={handleClick}>
				{label ? (
					<Box className={styles.labelContainer}>
						<Typography component="span" className={styles.moreIcon}>
							{label}
						</Typography>
						<DownArrow />
					</Box>
				) : (
					<MoreHorizIcon className={styles.moreIcon} />
				)}
			</Box>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				id="demo-simple-select"
				onClose={handleClose}
				autoFocus={false}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				{...(transformOrigin ? { transformOrigin } : {})}
				PopoverClasses={{
					paper: styles.mainMenu,
				}}
				MenuListProps={{
					style: {
						borderRadius: 8,
						padding: 0,
						minWidth: 93,
						boxShadow: " 0px 2px 16px rgba(36, 0, 88, 0.08)",
					},
				}}
			>
				{items.map((item: any, index: number) => {
					return (
						<MenuItem
							disableRipple={true}
							classes={{
								root: styles.menuItemRoot,
							}}
							key={index}
							onClick={item.onClick}
							disabled={item.disabled}
						>
							{item.text}
						</MenuItem>
					);
				})}
			</Menu>
		</Box>
	);
}
