import { ToggleButtonGroup, ToggleButtonGroupProps, styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
	width: "100%",
	"& .MuiToggleButtonGroup-grouped": {
		fontWeight: 600,
		textTransform: "initial",
		width: "max-content",
		padding: "5px 12px",
		color: "black",
		flex: "50%",
		"&.Mui-selected": {
			border: "2px solid",
			borderColor: theme.palette.primary.dark,
			color: theme.palette.primary.dark,
			backgroundColor: "#F2EDF8",
		},
	},
}));

const BriaToggleButtonGroup = ({ onChange, ...rest }: ToggleButtonGroupProps) => {
	const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string | null) => {
		if (newValue !== null) {
			onChange?.(event, newValue);
		}
	};

	return (
		<StyledToggleButtonGroup onChange={handleChange} exclusive aria-label="text alignment" {...rest}>
			{rest.children}
		</StyledToggleButtonGroup>
	);
};

export default observer(BriaToggleButtonGroup);
