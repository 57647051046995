import { Box } from "@mui/material";
import clsx from "clsx";
import TextLtrIcon from "../../../assets/images/icons/Alignment/Text/TextLtrIcon";
import TextRtlIcon from "../../../assets/images/icons/Alignment/Text/TextRtlIcon";
import { TextDirectionEnum } from "../../../models/common";
import styles from "./FontPopover.module.scss";

type Props = {
	value?: TextDirectionEnum;
	handleChange?: (direction: TextDirectionEnum) => void;
	disable?: boolean;
};

const TextDirectionButtons = ({ value, handleChange, disable }: Props) => {
	return (
		<Box className={styles.iconsGroup}>
			<TextLtrIcon
				className={clsx({
					[styles.selected]: value === TextDirectionEnum.LTR,
					[styles.disabled]: disable,
				})}
				onClick={() => handleChange?.(TextDirectionEnum.LTR)}
			/>
			<TextRtlIcon
				className={clsx({
					[styles.selected]: value === TextDirectionEnum.RTL,
					[styles.disabled]: disable,
				})}
				onClick={() => handleChange?.(TextDirectionEnum.RTL)}
			/>
		</Box>
	);
};

export default TextDirectionButtons;
