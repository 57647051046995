import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { ScrollPosition } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ANALYTICS_EVENTS } from "../../../../../analytics-store.tsx";
import FullScreenViewer, {
	FullScreenViewerProps,
} from "../../../../../components/common/FullScreenViewer/FullScreenViewer";
import { FullScreenImageType } from "../../../../../components/common/FullScreenViewer/Image/FullScreenImage.tsx";
import BriaImage, { BriaImageProps } from "../../../../../components/common/Galleries/BriaImage";
import { useAppStore } from "../../../../../hooks/useStores.tsx";
import { PlaygroundImage } from "../../../../../models/image-to-image";
import styles from "./ProductPlacementGallery.module.scss";

type ProductPlacementGalleryProps = {
	images: (BriaImageProps & PlaygroundImage)[];
	maxInLine?: number;
	className?: string;
	fullScreenProps?: Partial<FullScreenViewerProps>;
	scrollPosition?: ScrollPosition;
};

const ProductPlacementGallery = ({
	images,
	maxInLine = 4,
	className,
	fullScreenProps,
	scrollPosition,
}: ProductPlacementGalleryProps) => {
	const { analyticsStore, playgroundStore } = useAppStore();
	const [imageToView, setImageToView] = useState(0);
	const [isViewerOpen, setIsViewerOpen] = useState<boolean | undefined>(fullScreenProps?.open);
	const [displayMode, setDisplayMode] = useState(fullScreenProps?.displayMode);

	useEffect(() => {
		setIsViewerOpen(fullScreenProps?.open);
	}, [fullScreenProps?.open]);

	const handleOpenFullScreen = (index: number) => {
		setDisplayMode("arrows");
		setImageToView(index);
		setIsViewerOpen(true);
	};

	const handleCloseFullScreen = () => {
		setIsViewerOpen(false);
		fullScreenProps?.onClose?.();
		setDisplayMode(fullScreenProps?.displayMode);
	};

	const handleSelectImage = (image: any) => {
		playgroundStore.handleSelectImage(image);
	};

	return (
		<>
			<Box
				className={clsx(className, styles.ppGallery)}
				sx={{
					"&  > span": { width: `calc( ${100 / maxInLine}% - 0.8vw)` },
				}}
			>
				{images.map((image, index) => (
					<span className={styles.imageWrapper}>
						<BriaImage
							key={index}
							{...image}
							image={image}
							handleFullScreen={() => handleOpenFullScreen(index)}
							lazyImageProps={{ ...image.lazyImageProps, scrollPosition: scrollPosition }}
							fullScreenProps={image.fullScreenProps}
							onDownload={() => {
								analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_PP);
							}}
							onDownloadPsd={() => {
								analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_PSD_DOWNLOAD_PP);
							}}
							handleClick={() => handleSelectImage(image)}
							isProductPlacement={true}
							hideDownloadIcon={false}
						/>
					</span>
				))}
			</Box>
			{isViewerOpen && (
				<FullScreenViewer
					{...fullScreenProps}
					open={isViewerOpen}
					images={images
						.filter((image) => !image.hideFullScreenButton && !image.errorType)
						.map((image) => ({ src: image.url, ...image.fullScreenProps }))}
					currentIndex={imageToView}
					onClose={handleCloseFullScreen}
					displayMode={displayMode}
					onDownload={(fullscreenImages: FullScreenImageType[]) => {
						fullscreenImages.forEach((_fullscreenImage) => {
							analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_PP);
						});
					}}
				/>
			)}
		</>
	);
};

export default observer(ProductPlacementGallery);
