import { AspectRatio, GenModel } from "../../components/common/DropDowns/StaticDropdown";
import { Environment, getCurrentEnv } from "../../config/env";
import { CustomStyle } from "../../models/iframe";
import { isAccentureOrg, isBriaOrg, isPublicisOrg } from "../../utils";

export enum IframePostMessageTypes {
	InitialLoadComplete = "Bria_InitialLoadComplete",
	Auth = "Bria_Auth",
	AuthInit = "Bria_AuthInit",
}

export enum LanguagesEnum {
	English = "english",
	Japanese = "japanese",
}

export enum PagesEnum {
	Gallery = "gallery",
	Playground = "playground",
	Assets = "assets",
	Campaign = "campaign",
}

export type Pages = {
	[key in PagesEnum]?: boolean;
};

export type Languages = {
	[key in LanguagesEnum]?: boolean;
};

export const IFRAME_LANGUAGES_MAP: { [key in LanguagesEnum]: string } = {
	[LanguagesEnum.English]: "1",
	[LanguagesEnum.Japanese]: "2",
};

export const IFRAME_PAGES_MAP: { [key in PagesEnum]: string } = {
	[PagesEnum.Gallery]: "1",
	[PagesEnum.Playground]: "2",
	[PagesEnum.Assets]: "3",
	[PagesEnum.Campaign]: "4",
};

export enum IframeStatusEnum {
	ACTIVE = "Active",
	INACTIVE = "Inactive",
}

export const PLAYGROUND_VHASH_EXAMPLE = "8679a552aa79cc96";

enum ViewerFeaturesEnum {
	Background = "background",
	Replace = "replace",
	ExpandBackground = "expand_background",
	Expression = "expression",
	Diversity = "diversity",
	Appearance = "appearance",
	Remove = "remove",
	Blur = "blur",
	RemoveObject = "remove_object",
	Crop = "crop",
	PsdDownload = "psd_download",
	SuperResolution = "super_resolution",
	AutoObjIdentification = "auto_obj_identification",
}

export const defaultIframe: IframeFormType = {
	name: "New iFrame",
	enabled_pages: {
		gallery: true,
		playground: true,
		assets: isPublicisOrg(),
		campaign: isBriaOrg() || isAccentureOrg(),
	},
	target: PagesEnum.Playground,
	default_gen_aspect_ratio: "4:3",
	enabled_features: Object.fromEntries(Object.values(ViewerFeaturesEnum).map((feature) => [feature, true])),
	enabled_languages: Object.fromEntries(
		Object.values(LanguagesEnum).map((lng) => [lng, lng === LanguagesEnum.English]),
	),
	embedded_iframe: true,
	use_public_gallery: true,
	enable_download: true,
	enable_close_button: true,
};

export const getIframeHost = () => {
	switch (getCurrentEnv()) {
		case Environment.local:
			return "https://int.embedded.bria.ai";
		case Environment.integration:
			return "https://int.embedded.bria.ai";
		default:
			return "https://prod.embedded.bria.ai";
	}
};

export type IframeGenModels = {
	[key in GenModel]?: string;
};

export type IframeFormType = {
	name: string;
	description?: string;
	target?: PagesEnum;
	enabled_pages?: Pages;
	enabled_features: Features;
	use_public_gallery?: boolean;
	custom_style?: CustomStyle;
	embedded_iframe?: boolean;
	usageText?: string;
	enable_download?: boolean;
	disable_download_psd?: boolean;
	disable_upload?: boolean;
	disable_save?: boolean;
	disable_save_psd?: boolean;
	enable_close_button?: boolean;
	save_label?: string;
	gen_models?: IframeGenModels;
	enabled_languages?: Languages;
	enabled_tg_models?: string[];
	default_gen_aspect_ratio?: AspectRatio;
};

export type IframeUrlParams = {
	iframeId: string;
	whiteLabel?: "true" | "false";
	orgIdForGallery?: string;
	orgId?: string;
	name?: string;
	description?: string;
	enabledPages?: string;
	enabledTgModels?: string;
	enabledLanguages?: string;
	enabledFeatures?: string;
	enableDownload?: "true" | "false";
	enableCloseButton?: "true" | "false";
	disableUpload?: "true" | "false";
	embeddedIframe?: "true" | "false";
	disableDownloadPsd?: "true" | "false";
	disableSave?: "true" | "false";
	disableSavePsd?: "true" | "false";
	saveLabel?: string;
	target?: string;
	vhash?: string;
	logo?: string;
	color?: string;
	usageText?: string;
	defaultGenAspectRatio?: string;
} & {
	[K in GenModel]?: string;
};

export type Checkboxes = {
	enabled_pages: Pages;
	enabled_features: Features;
	importExportConfig: ImportExportConfig;
};

export type PagesLabels = {
	[key in keyof Pages]: keyof typeof PagesEnum;
};

export type FeaturesLabels = {
	[key in keyof Features]: string;
};

export type ImportExportLabels = {
	[key in keyof ImportExportConfig]: string;
};

export enum FeaturesEnum {
	Expression = "expression",
	Diversity = "diversity",
	Appearance = "appearance",
	CameraMovementVideo = "camera_movement_video",
	Logo = "logo",
	FineTuning = "fine_tuning",
	Mood = "mood",
	Remove = "remove",
	Blur = "blur",
	Paint = "paint",
	Replace = "replace",
	Background = "background",
	RemoveObject = "remove_object",
	TextEditor = "text_editor",
	Crop = "crop",
	PsdDownload = "psd_download",
	SuperResolution = "super_resolution",
	ExpandBackground = "expand_background",
	ImageStyle = "image_style",
	CampaignCreate = "campaign_create",
	AutoObjIdentification = "auto_obj_identification",
}

export const IFRAME_FEATURES_MAP: { [key in FeaturesEnum]: string } = {
	[FeaturesEnum.Expression]: "1",
	[FeaturesEnum.Diversity]: "2",
	[FeaturesEnum.Appearance]: "3",
	[FeaturesEnum.CameraMovementVideo]: "4",
	[FeaturesEnum.Logo]: "5",
	[FeaturesEnum.FineTuning]: "6",
	[FeaturesEnum.Mood]: "7",
	[FeaturesEnum.Remove]: "8",
	[FeaturesEnum.Blur]: "9",
	[FeaturesEnum.Paint]: "10",
	[FeaturesEnum.Replace]: "11",
	[FeaturesEnum.Background]: "12",
	[FeaturesEnum.RemoveObject]: "13",
	[FeaturesEnum.TextEditor]: "14",
	[FeaturesEnum.Crop]: "15",
	[FeaturesEnum.PsdDownload]: "16",
	[FeaturesEnum.SuperResolution]: "17",
	[FeaturesEnum.ExpandBackground]: "18",
	[FeaturesEnum.ImageStyle]: "19",
	[FeaturesEnum.CampaignCreate]: "20",
	[FeaturesEnum.AutoObjIdentification]: "21",
};

export type Features = {
	[key in FeaturesEnum]?: boolean;
};

export type ImportExportConfig = {
	enable_download: boolean;
	disable_download_psd: boolean;
	disable_upload: boolean;
};
