import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function TextLtrIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 19" {...props}>
			<path
				d="M1 16H15M15 16L13 18M15 16L13 14M12 1H5.5C4.57174 1 3.6815 1.36875 3.02513 2.02513C2.36875 2.6815 2 3.57174 2 4.5C2 5.42826 2.36875 6.3185 3.02513 6.97487C3.6815 7.63125 4.57174 8 5.5 8H6M10 12V1M6 12V1"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default TextLtrIcon;
