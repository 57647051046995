import { Close } from "@mui/icons-material";
import { Box, IconButton, TextField, TextFieldProps, debounce } from "@mui/material";
import React, { useCallback, useState } from "react";
import SearchIcon from "../../../assets/images/icons/SearchIcon";
import styles from "./SearchInputField.module.scss";

interface DebouncedInputProps {
	debounceDuration?: number;
	searchIcon?: boolean;
	height?: string;
	placeHolder?: string;
}

const SearchInputField: React.FC<DebouncedInputProps & TextFieldProps> = ({
	debounceDuration = 500,
	searchIcon = false,
	height,
	value,
	onChange,
	placeHolder,
	...rest
}) => {
	const [localValue, setLocalValue] = useState("");
	const [isHovered, setIsHovered] = useState(false);

	const eraseSearch = () => {
		setLocalValue("");
		handleChange({ target: { value: "" } } as React.ChangeEvent<HTMLInputElement>);
	};

	const handleDebouncedChange = useCallback(
		debounce((e) => onChange?.(e), debounceDuration),
		[],
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLocalValue(e.target.value);
		handleDebouncedChange(e);
	};

	return (
		<Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			{isHovered ? (
				<TextField
					placeholder={placeHolder || ""}
					value={localValue}
					onChange={handleChange}
					InputProps={{
						style: {
							height,
							paddingLeft: "8px",
							fontSize: "14px",
						},
						startAdornment: searchIcon && (
							<IconButton
								className={styles.IconButton}
								style={{ padding: "0px", backgroundColor: "white", color: "#5b5b5b" }}
								disableFocusRipple
								disableTouchRipple
							>
								<SearchIcon className={styles.searchIconStyle} />
							</IconButton>
						),

						endAdornment: localValue && (
							<IconButton size="small" onClick={eraseSearch}>
								<Close sx={{ fontSize: "16px" }} />
							</IconButton>
						),
					}}
					{...rest}
				/>
			) : (
				<IconButton>
					<SearchIcon />
				</IconButton>
			)}
		</Box>
	);
};

export default SearchInputField;
