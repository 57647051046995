import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import RouterConstants from "../../constants/RouterConstants";
import { useAppStore } from "../../hooks/useStores";
import TabsLayout from "../../layout/TabsLayout/TabsLayout";

const Campaign = () => {
	const { t } = useTranslation();
	const { appStore } = useAppStore();
	const subTabs = [
		RouterConstants.TEMPLATES.path,
		RouterConstants.BRANDS.path,
		RouterConstants.FONTS.path,
		// RouterConstants.PLACEMENTS.path,
	];

	return (
		<>
			<TabsLayout title={t("campaignTab.title")} subTabs={subTabs} isLoading={appStore.isLoading}>
				<Outlet />
			</TabsLayout>
		</>
	);
};

export default observer(Campaign);
