import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../assets/images/icons/InfoIcon";
import BriaTooltip from "../../components/common/BriaTooltip/BriaTooltip";
import styles from "./InputLayout.module.scss";

interface IProps {
	label?: string;
	labelClassName?: string;
	labelIcon?: any;
	info?: string;
	className?: string;
	children: React.ReactNode;
	showLabel?: boolean;
	validateOn?: boolean;
	validationMessage?: string;
	optional?: boolean;
}

const InputLayout = ({
	label,
	labelClassName,
	labelIcon,
	info,
	showLabel = true,
	validateOn = false,
	validationMessage = "",
	className,
	children,
	optional = false,
}: IProps) => {
	const { t } = useTranslation();
	const [openInfo, setOpenInfo] = useState(false);
	const infoIconRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (infoIconRef.current && !infoIconRef.current.contains(event.target as Node)) {
				setOpenInfo(false);
			}
		};

		document.addEventListener("click", handleClickOutside as unknown as EventListener);
		return () => {
			document.removeEventListener("click", handleClickOutside as unknown as EventListener);
		};
	}, []);

	const handleIconClick = (event: MouseEvent<SVGSVGElement>) => {
		event.stopPropagation();
		setOpenInfo((prev) => !prev);
	};

	return (
		<Box className={`${className} ${styles.container}`}>
			{showLabel && (
				<Box className={`${!optional ? styles.textLabel : styles.textLabelOptionalStyle} ${labelClassName}`}>
					<Typography className={styles.inputLabel}>{label || <span>&nbsp;</span>}</Typography>
					{info && (
						<BriaTooltip
							title={info}
							open={openInfo}
							className={styles.info}
							componentsProps={{ tooltip: { className: styles.tooltip, ref: infoIconRef } }}
						>
							<InfoIcon className={styles.infoIcon} onClick={handleIconClick} />
						</BriaTooltip>
					)}
					{labelIcon && <img className={styles.labelIcon} src={labelIcon} />}
					{optional && <Typography className={styles.optionalLabel}>{t("optional")}</Typography>}
				</Box>
			)}
			{children}
			{validateOn && <Typography className={`${styles.inputError}`}>{validationMessage}</Typography>}
		</Box>
	);
};

export default observer(InputLayout);
