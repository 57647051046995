// @ts-ignore
function Layers({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.4115 8.31741L9.03312 10.6985C9.32215 10.8505 9.65111 10.9365 9.99984 10.9365C10.3485 10.9365 10.6775 10.8505 10.9666 10.6985L15.5882 8.31741C15.9196 8.14309 16.1457 7.79538 16.1457 7.39486C16.1457 6.99434 15.9196 6.6466 15.5882 6.4723L10.9666 4.09126C10.6775 3.93926 10.3485 3.85319 9.99984 3.85319C9.65111 3.85319 9.32215 3.93926 9.03312 4.09126L4.4115 6.4723C4.08002 6.6466 3.854 6.99434 3.854 7.39486C3.854 7.79538 4.08002 8.14309 4.4115 8.31741Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.854 13.1286L9.03312 15.9069C9.32218 16.059 9.65111 16.145 9.99984 16.145C10.3486 16.145 10.6775 16.059 10.9666 15.9069L16.1457 13.1286"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.854 10.5246L9.03312 13.3029C9.32218 13.4549 9.65111 13.541 9.99984 13.541C10.3486 13.541 10.6775 13.4549 10.9666 13.3029L16.1457 10.5246"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Layers;
