import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import EmptyState from "../../../../../../assets/images/svgs/DesignEditor/templates_empty_state.svg";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton.tsx";
import RouterConstants from "../../../../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import styles from "./CampaignEmptyState.module.scss";

const CampaignEmptyState = () => {
	const { designEditorStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor" });

	return (
		<Box className={styles.templatesEmptyState}>
			<Box className={styles.content}>
				<img className={styles.image} src={EmptyState} />
				<Typography className={styles.title}>
					{t("popup.emptyState.title.part1")}
					<span className={styles.boldTitle}>{t("popup.emptyState.title.part2")}</span>
					{t("popup.emptyState.title.part3")}
				</Typography>
				<Typography className={styles.or}>{t("popup.emptyState.title.or")}</Typography>
				<BriaButton
					className={styles.button}
					buttonType="textMedium"
					onClick={async () => {
						window.location.pathname = RouterConstants.DESIGN_EDITOR.fullPath;
					}}
					disabled={designEditorStore.isLoadingTemplate}
				>
					{t("popup.buttons.startWithoutATemplate")}
				</BriaButton>
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(CampaignEmptyState);
export default ObservedComponent;
