export const editorFonts = [
	{
		name: "Uber Move Text",
		url: "https://d3q7mfli5umxdg.cloudfront.net/UberMoveTextLight.otf",
		options: { style: "normal", weight: 300 },
	},
	{
		name: "Uber Move Text",
		url: "https://d3q7mfli5umxdg.cloudfront.net/UberMoveTextRegular.otf",
		options: { style: "normal", weight: 400 },
	},
	{
		name: "Uber Move Text",
		url: "https://d3q7mfli5umxdg.cloudfront.net/UberMoveTextMedium.otf",
		options: { style: "normal", weight: 500 },
	},
	{
		name: "Uber Move Text",
		url: "https://d3q7mfli5umxdg.cloudfront.net/UberMoveTextBold.otf",
		options: { style: "normal", weight: 700 },
	},
];

export const fontStyleLabels = {
	"100": {
		id: 0,
		label: "Thin",
	},
	"100italic": {
		id: 1,
		label: "Thin Italic",
	},
	"200": {
		id: 2,
		label: "ExtraLight",
	},
	"200italic": {
		id: 3,
		label: "ExtraLight Italic",
	},
	"300": {
		id: 4,
		label: "Light",
	},
	"300italic": {
		id: 5,
		label: "Light Italic",
	},
	regular: {
		id: 6,
		label: "Regular",
	},
	italic: {
		id: 7,
		label: "Regular Italic",
	},
	"500": {
		id: 8,
		label: "Medium",
	},
	"500italic": {
		id: 9,
		label: "Medium Italic",
	},
	"600": {
		id: 10,
		label: "SemiBold",
	},
	"600italic": {
		id: 11,
		label: "SemiBold Italic",
	},
	"700": {
		id: 12,
		label: "Bold",
	},
	"700italic": {
		id: 13,
		label: "Bold Italic",
	},
	"800": {
		id: 14,
		label: "ExtraBold",
	},
	"800italic": {
		id: 15,
		label: "ExtraBold Italic",
	},
	"900": {
		id: 16,
		label: "Black",
	},
	"900italic": {
		id: 17,
		label: "Black Italic",
	},
};
