import { Box, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppStore } from "../../../hooks/useStores";
import { HorizontalEnum, TextDirectionEnum, VerticalEnum } from "../../../models/common";
import { Font } from "../../../models/font";
import BriaDropDown, { DropDownItem } from "../BriaDropDown/BriaDropDown";
import styles from "./FontPopover.module.scss";
import TextDirectionButtons from "./TextDirectionButtons";
import TextHeightButtons from "./TextHeightButtons";
import TextHorizontalButtons from "./TextHorizontalButtons";
import TextVerticalButtons from "./TextVerticalButtons";

type Props = {
	open: boolean;
	fontId?: number;
	handleFontChange?: (fontId: number) => void;
	horizontalAlignment?: HorizontalEnum;
	handleHorizontalChange?: (alignment: HorizontalEnum) => void;
	disableHorizontal?: boolean;
	hideHorizontal?: boolean;
	hideTextHeight?: boolean;
	disableTextHeight?: boolean;
	verticalAlignment?: VerticalEnum;
	handleVerticalChange?: (alignment: VerticalEnum) => void;
	disableVertical?: boolean;
	hideVertical?: boolean;
	textDirection?: TextDirectionEnum;
	handleDirectionChange?: (alignment: TextDirectionEnum) => void;
	disableTextDirection?: boolean;
	hideTextDirection?: boolean;
	handleClose?: () => void;
	anchorEl?: SVGSVGElement;
};

const FontPopover = ({
	open = false,
	fontId = NaN,
	handleFontChange = () => {},
	horizontalAlignment,
	handleHorizontalChange,
	disableHorizontal,
	hideHorizontal,
	hideTextHeight,
	disableTextHeight,
	verticalAlignment,
	handleVerticalChange,
	disableVertical,
	hideVertical,
	textDirection,
	handleDirectionChange,
	disableTextDirection,
	hideTextDirection,
	handleClose,
	anchorEl,
}: Props) => {
	const { fontsStore } = useAppStore();
	const [fontsOptions, setFontsOptions] = useState<DropDownItem[]>([]);

	useEffect(() => {
		const loadAndSetFontsOptions = async () => {
			if (!fontsStore.orgFonts.length) {
				await fontsStore.getAllOrgFonts(true);
			}
			const fontsOptions = fontsStore.orgFonts.map(({ name, id }: Font) => ({
				key: name,
				value: id,
			}));
			setFontsOptions(fontsOptions);
		};

		loadAndSetFontsOptions();
	}, []);

	return (
		<Popover
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			slotProps={{
				paper: {
					className: styles.popover,
				},
			}}
		>
			<BriaDropDown
				value={fontId}
				items={fontsOptions}
				onChange={(e) => handleFontChange(e.target.value as number)}
				height="34px"
			/>
			<Box className={styles.icons}>
				{!hideHorizontal && (
					<TextHorizontalButtons
						value={horizontalAlignment}
						handleChange={handleHorizontalChange}
						disable={disableHorizontal}
					/>
				)}
				{!hideTextHeight && <TextHeightButtons disable={disableTextHeight} />}
				{!hideVertical && (
					<TextVerticalButtons
						value={verticalAlignment}
						handleChange={handleVerticalChange}
						disable={disableVertical}
					/>
				)}
				{!hideTextDirection && (
					<TextDirectionButtons
						value={textDirection}
						handleChange={handleDirectionChange}
						disable={disableTextDirection}
					/>
				)}
			</Box>
		</Popover>
	);
};

export default FontPopover;
