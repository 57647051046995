import { Box, Divider, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BriaButton from "../../../../components/common/BriaButton/BriaButton.tsx";
import BriaCheckbox from "../../../../components/common/BriaCheckbox/BriaCheckbox.tsx";
import BriaInput from "../../../../components/common/BriaInput/BriaInput.tsx";
import RouterConstants from "../../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import styles from "./ProductPlacementConfig.module.scss";

enum generatingTypes {
	AUTO = "AUTO",
	SELECTED = "SELECTED",
}

const ProductPlacementConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.productPlacement" });
	const { productPlacementStore, playgroundStore } = useAppStore();
	const [generatingType, setGeneratingType] = useState<generatingTypes>(generatingTypes.AUTO);
	const isAuto = generatingType !== generatingTypes.SELECTED;
	const navigate = useNavigate();
	const widthValue = productPlacementStore.config.width ?? 0;
	const heightValue = productPlacementStore.config.height ?? 0;
	const selectedImages = playgroundStore.getSelectedImages();

	const isGenerateEnabled =
		productPlacementStore.config.prompt &&
		productPlacementStore.config.width &&
		productPlacementStore.config.height &&
		!playgroundStore.playgroundResults[playgroundStore.playgroundResults.length - 1]?.images.some(
			(image) => image.loading,
		);

	useEffect(() => {
		if (playgroundStore.playgroundResults.length === 0) {
			navigate(`/${RouterConstants.PRODUCT_PLACEMENT_GALLERY.fullPath}`);
		}
	}, [playgroundStore.playgroundResults]);

	useEffect(() => {
		if (selectedImages.length > 0) {
			setGeneratingType(generatingTypes.SELECTED);
		} else {
			setGeneratingType(generatingTypes.AUTO);
		}
	}, [selectedImages]);

	const handleGeneratingTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.checked) {
			setGeneratingType(generatingTypes.AUTO);
			playgroundStore.clearSelectedImages();
		}
	};

	return (
		<Box className={styles.config}>
			<Typography className={styles.title}>{t("config.title")}</Typography>

			<BriaInput
				value={productPlacementStore.config.prompt}
				onChange={(e) => productPlacementStore.handleConfigChange("prompt", e.target.value)}
				onEnterPress={productPlacementStore.generateProductPlacement}
				className={styles.searchBox}
				label={t("config.prompt")}
				placeholder={t("config.generator.searchBox.placeholder")}
				inputProps={{ className: styles.inputField }}
				multiline
				minRows={3}
			/>
			<FormControlLabel
			className={styles.enhancePrompt}
				control={
					<BriaCheckbox
						checked={productPlacementStore.config?.prompt_enhancement}
						onChange={(e) =>
							productPlacementStore.handleConfigChange("prompt_enhancement", e.target.checked)
						}
					/>
				}
				label={t("config.generator.searchBox.enhancedPrompt")}
			/>
			<FormControlLabel
			className={styles.enhancePrompt}
				control={
					<BriaCheckbox
						checked={productPlacementStore.config?.fast}
						onChange={(e) =>
							productPlacementStore.handleConfigChange("fast", e.target.checked)
						}
					/>
				}
				label={t("config.generator.searchBox.fastBGModel")}
			/>
			<Box className={styles.dimensions}>
				<BriaInput
					value={widthValue}
					onChange={(e) => productPlacementStore.handleConfigChange("width", parseInt(e.target.value))}
					className={styles.inputWrapper}
					label={t("config.width")}
					inputProps={{ className: styles.inputField }}
					type="number"
					customEndAdornmentText={"px"}
				/>
				<BriaInput
					value={heightValue}
					onChange={(e) => productPlacementStore.handleConfigChange("height", parseInt(e.target.value))}
					className={styles.inputWrapper}
					label={t("config.height")}
					inputProps={{ className: styles.inputField }}
					type="number"
					customEndAdornmentText={"px"}
				/>
			</Box>
			<Box className={styles.helpMessage}>{t("errors.ExceedsTotalNumberOfPixels")}</Box>
			<Box className={styles.generatingType}>
				<FormGroup>
					<FormControlLabel
						className={clsx(styles.FormControlLabel, {
							[styles.adminModeFormControlLabel]: generatingType === generatingTypes.SELECTED,
						})}
						control={
							<Switch
								checked={!isAuto}
								onChange={handleGeneratingTypeChange}
								classes={{
									root: clsx(styles.switchBase, {
										[styles.selected]: !isAuto,
									}),
								}}
							/>
						}
						label={t("config.generator.toggleLabel")}
						labelPlacement="end"
						value={!isAuto}
						classes={{ label: styles.adminModeLabel }}
					/>
				</FormGroup>
				<Box className={styles.helpMessage}>
					{isAuto ? t("config.generator.autoType") : t("config.generator.selectedType")}
				</Box>
			</Box>
			<Divider className={styles.divider} />
			<BriaButton
				buttonType="primaryMedium"
				className={styles.button}
				onClick={() => productPlacementStore.generateProductPlacement()}
				disabled={!isGenerateEnabled}
			>
				{t("config.generator.searchBox.button")}
			</BriaButton>
		</Box>
	);
};

export default observer(ProductPlacementConfig);
