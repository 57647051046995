import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { BriaAxios } from "../config/axios";

function useBriaAxios<T>(config: AxiosRequestConfig) {
	const [data, setData] = useState<T | undefined>();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | undefined>();

	useEffect(() => {
		const handleRequest = async () => {
			try {
				setError(undefined);
				const axiosInstance: AxiosInstance = await BriaAxios();
				const response: AxiosResponse = await axiosInstance.request({
					method: config.method,
					url: config.url,
					data: config.data,
				});
				setData(response.data);
			} catch (e: any) {
				setError(e);
			} finally {
				setLoading(false);
			}
		};

		handleRequest();
	}, []);

	return { data, loading, error };
}

export default useBriaAxios;
