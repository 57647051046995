import { Select } from "@mui/material";
import { SelectProps } from "@mui/material/Select";
import React from "react";
import classes from "./BriaSelect.module.scss";

interface IProps {}

const BriaSelect: React.FC<IProps & SelectProps> = ({ children, ...rest }) => {
	return (
		<Select className={classes.briaRoot} {...rest}>
			{children}
		</Select>
	);
};

export default BriaSelect;
