import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../hooks/useStores.tsx";
import HorizontalLinearStepper from "../../common/HorizontalLinearStepper/HorizontalLinearStepper.tsx";
import UpdateCaptionButtons from "../../common/UpdateCaptionButtons/UpdateCaptionButtons.tsx";
import CreateDataset from "../CreateDataset/CreateDataset.tsx";
import SelectType from "../SelectType/SelectType.tsx";
import UploadImages from "../UploadImages/UploadImages.tsx";
import styles from "./DatasetCreation.module.scss";

const DatasetCreation = () => {
	const { uiStore, tailoredGenerationStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });
	const [activeStep, setActiveStep] = useState(0);
	let createModelSteps = [
		t("models.stepper.selectType.stepperName"),
		t("models.stepper.createDataSet.datasetStepperName"),
		t("models.stepper.createDataSet.stepperName"),
	];
	if (uiStore.duplicateDatasetFlow) {
		createModelSteps = [t("models.stepper.selectType.stepperName"), t("models.stepper.createDataSet.stepperName")];
	}

	useEffect(() => {
		uiStore.createDataSet = true;
		return () => {
			uiStore.createDataSet = false;
		};
	}, []);

	const handleNext: () => void = async () => {
		setActiveStep((prevState: number) => prevState + 1);
	};

	return (
		<Box className={styles.container}>
			<Box className={styles.stepperCaptionswrapper}>
				<Box className={styles.stepperWrapper}>
					<Box className={!uiStore.duplicateDatasetFlow ? styles.stepper : styles.duplicateFlowStepper}>
						<HorizontalLinearStepper
							steps={createModelSteps}
							activeStep={activeStep}
							setActiveStep={setActiveStep}
						/>
					</Box>
				</Box>
				<UpdateCaptionButtons step={uiStore.tg_step || ""} />
			</Box>
			<Box
				className={
					!uiStore.duplicateDatasetFlow
						? clsx({
								[styles.stepperContent]: true,
								[styles.stepperContent2]: activeStep === 1,
								[styles.stepperContent1]: activeStep === 0,
								[styles.wideStepperContent]: activeStep >= 2,
						  })
						: clsx({
								[styles.stepperContent]: true,
								[styles.stepperContent1]: activeStep === 0,
								[styles.wideStepperContent]: activeStep >= 1,
						  })
				}
			>
				<input
					ref={uiStore.tgUploadFileInput}
					type="file"
					multiple
					className={styles.hidden}
					onChange={async (e) => {
						handleNext();
						await tailoredGenerationStore.handleUploadSelectMultiple(e);
						e.target.value = "";
					}}
					accept=".png, .jpg, .jpeg, .webp"
				/>
				{!uiStore.duplicateDatasetFlow ? (
					activeStep === 0 ? (
						<SelectType handleNext={handleNext} />
					) : activeStep === 1 ? (
						<UploadImages handleNext={handleNext} />
					) : (
						<CreateDataset />
					)
				) : activeStep === 0 ? (
					<SelectType handleNext={handleNext} />
				) : (
					<CreateDataset />
				)}
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(DatasetCreation);
export default ObservedComponent;
