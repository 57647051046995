import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { AIEditorOption } from "../../../models/aiEditor.ts";
import styles from "./Sidebar.module.scss";

interface ISidebar {
	items: AIEditorOption[];
}

const Sidebar = ({ items }: ISidebar) => {
	const { aiEditorStore } = useAppStore();
	return (
		<Box className={styles.container}>
			{items.map((item: AIEditorOption) =>
				item.isEnabled ? (
					<Box
						key={item.label}
						className={clsx({
							[styles.sidebarItem]: true,
							[styles.selectedItem]: item.label === aiEditorStore.selectedEditorTabLabel,
						})}
						onClick={() => {
							aiEditorStore.setProperty("selectedEditorTabLabel", item.label);
						}}
					>
						<img className={styles.sidebarIcon} src={item.icon} alt={item.label} />
						<Typography className={styles.sidebarText}>{item.label}</Typography>
					</Box>
				) : null,
			)}
		</Box>
	);
};

const ObservedComponent = observer(Sidebar);
export default ObservedComponent;
