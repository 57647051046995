import { ElementType } from "react-selecto";
import Selecto from "selecto";

export type SelectableUtils = {
	selectAll: () => void;
	selectElements: (elements?: ElementType[]) => void;
	clearSelection: () => void;
};

const useSelectable = (selecto?: Selecto | null): SelectableUtils => {
	const selectAll = () => {
		selectElements(selecto?.getSelectableElements());
	};

	const selectElements = (elements?: ElementType[]) => {
		elements?.forEach((el) => el.classList.add("selected"));
		selecto?.setSelectedTargets(elements ?? []);
	};

	const clearSelection = () => {
		selecto?.getSelectableElements()?.forEach((el) => el.classList.remove("selected"));
		selecto?.setSelectedTargets([]);
	};

	return {
		selectAll,
		selectElements,
		clearSelection,
	};
};

export default useSelectable;
