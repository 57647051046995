import { Box, ToggleButton } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaToggleButtonGroup from "../../../../components/common/BriaToggleButtonGroup/BriaToggleButtonGroup";
import ImageUploader from "../../../../components/common/ImageUploader/ImageUploader";
import { useAppStore } from "../../../../hooks/useStores";
import { Layout } from "../../../../models/layout";
import useImageUtils from "../../../../utils/useImageUtils";
import styles from "./LayoutPreview.module.scss";
import LayoutSkeleton from "./LayoutSkeleton";

type Props = {
	layout: Layout;
	loading?: boolean;
	onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
	onDelete?: () => Promise<void>;
};
const LayoutPreview = ({ layout, loading = false, onUpload, onDelete }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.layoutEditor.form" });
	const { layoutsStore } = useAppStore();
	const { convertS3UriToHttpsUrl } = useImageUtils();
	const [selectedPreviewBtn, setSelectedPreviewBtn] = useState<"preview" | "imageExample">("preview");

	return (
		<Box className={styles.toggleButtons}>
			<BriaToggleButtonGroup value={selectedPreviewBtn} onChange={(_e, value) => setSelectedPreviewBtn(value)}>
				<ToggleButton value="preview">{t("preview.toggleBtn")}</ToggleButton>
				<ToggleButton value="imageExample">{t("imageExample.toggleBtn")}</ToggleButton>
			</BriaToggleButtonGroup>
			{selectedPreviewBtn === "preview" ? (
				<LayoutSkeleton {...{ layout, loading }} />
			) : (
				selectedPreviewBtn === "imageExample" && (
					<ImageUploader
						className={styles.imgUploader}
						src={layout?.image_example ? convertS3UriToHttpsUrl(layout?.image_example) : ""}
						description={t("imageExample.description")}
						onUpload={onUpload}
						onDelete={onDelete}
						loading={layoutsStore.isLoadingImgExample || layoutsStore.isLoading}
						disabled={!layout?.id}
					/>
				)
			)}
		</Box>
	);
};

export default observer(LayoutPreview);
