import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function TextBottomIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 16" {...props}>
			<path
				d="M14.75 14.75L1.25 14.75"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.97852 11.293L7.97852 0.792969"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.875 11L8.00327 11.75L9.125 11"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default TextBottomIcon;
