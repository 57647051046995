import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import DeleteIcon from "../../../../../assets/images/icons/DeleteIcon";
import UploadImageIcon from "../../../../../assets/images/icons/Image/UploadImageIcon";
import FileUploader from "../../../../../components/common/FileUploader/FileUploader";
import FontPopoverButton from "../../../../../components/common/FontPopover/FontPopoverButton";
import ObjectAlignmentButton from "../../../../../components/common/ObjectAlignment/ObjectAlignmentButton";
import { useAppStore } from "../../../../../hooks/useStores";
import { HorizontalEnum, TextDirectionEnum, VerticalEnum } from "../../../../../models/common";
import { Alignment, Layout, LayoutLayer, LayoutMessage } from "../../../../../models/layout";
import useImageUtils from "../../../../../utils/useImageUtils";
import { getGraphicIndex, isGraphicalElement } from "./LayoutConfig";
import styles from "./LayoutLayerActions.module.scss";

type Props = {
	type: LayoutLayer;
	layoutForm: Layout;
	handleFormChange: <K extends keyof Layout>(key: K, value: Layout[K]) => void;
	graphicImagesToUpload: File[];
	setGraphicImagesToUpload: (updatedArray: File[]) => void;
	graphicImagesToDelete: string[];
	setGraphicImagesToDelete: (updatedArray: string[]) => void;
};

const LayoutLayerActions = ({
	type,
	layoutForm,
	handleFormChange,
	graphicImagesToUpload,
	setGraphicImagesToUpload,
	graphicImagesToDelete,
	setGraphicImagesToDelete,
}: Props) => {
	const { layoutsStore } = useAppStore();
	const { isS3Uri } = useImageUtils();

	const handleAlignmentChange = (layerType: LayoutLayer, alignment: Alignment) => {
		handleFormChange(layerType, { ...layoutForm[type], alignment });
	};

	const uploadGraphicImage = async (e: React.ChangeEvent<HTMLInputElement>, type: LayoutLayer) => {
		const file: File | null = e.target.files && e.target.files[0];
		if (file) {
			const copyGraphics = layoutForm.graphical_elements;
			const graphicIndex = getGraphicIndex(type);
			setGraphicImagesToUpload([...graphicImagesToUpload, file]);
			if (copyGraphics) {
				copyGraphics[graphicIndex] = { ...layoutForm.graphical_elements?.[graphicIndex], element: file.name };
				handleFormChange("graphical_elements", copyGraphics);
			}
		}
	};

	const deleteGraphicImage = async (type: LayoutLayer) => {
		const graphicIndex = getGraphicIndex(type);
		if (layoutForm.graphical_elements) {
			if (isS3Uri(layoutForm.graphical_elements?.[graphicIndex].element)) {
				setGraphicImagesToDelete([
					...graphicImagesToDelete,
					layoutForm.graphical_elements[graphicIndex].element.split("/").pop()!,
				]);
			}
		}
		const fileName = layoutForm.graphical_elements?.[graphicIndex].element.split("/").pop();
		if (fileName) {
			const copyGraphics = layoutForm.graphical_elements;
			if (copyGraphics) {
				copyGraphics[graphicIndex] = { ...layoutForm.graphical_elements?.[graphicIndex], element: "" };
				handleFormChange("graphical_elements", copyGraphics);
			}
		}
	};

	const reorderGraphics = (type: LayoutLayer) => {
		const updatedOrder = layoutForm.order
			?.filter((item) => item !== type)
			.map((item) =>
				isGraphicalElement(item) && getGraphicIndex(item) > getGraphicIndex(type)
					? `graphical_element_${getGraphicIndex(item)}`
					: item,
			) as LayoutLayer[];
		handleFormChange("order", updatedOrder);
	};

	const deleteGraphic = async (type: LayoutLayer) => {
		const updatedGraphics = [...(layoutForm.graphical_elements || [])];
		const graphicIndex = getGraphicIndex(type);
		if (isS3Uri(updatedGraphics[graphicIndex].element)) {
			setGraphicImagesToDelete([
				...graphicImagesToDelete,
				updatedGraphics[graphicIndex].element.split("/").pop()!,
			]);
		}
		updatedGraphics.splice(graphicIndex, 1)[0];
		handleFormChange("graphical_elements", updatedGraphics);
		reorderGraphics(type);
	};

	const handleMessageChange = <K extends keyof LayoutMessage>(
		messageLayer: LayoutLayer,
		key: K,
		value: LayoutMessage[K],
	) => {
		handleFormChange(messageLayer, { ...layoutForm[messageLayer], [key]: value });
	};

	return (
		<>
			<Box className={styles.actionsContainer}>
				{(type === "primary_message" || type === "secondary_message") && (
					<FontPopoverButton
						fontId={layoutForm[type]?.font_id}
						handleFontChange={(fontId) => handleMessageChange(type, "font_id", fontId)}
						horizontalAlignment={layoutForm[type]?.alignment?.horizontal}
						handleHorizontalChange={(horizontal: HorizontalEnum) =>
							handleMessageChange(type, "alignment", {
								...layoutForm[type]?.alignment,
								horizontal:
									horizontal === layoutForm[type]?.alignment?.horizontal ? undefined : horizontal,
							})
						}
						disableTextHeight
						verticalAlignment={layoutForm[type]?.alignment?.vertical}
						handleVerticalChange={(vertical: VerticalEnum) =>
							handleMessageChange(type, "alignment", {
								...layoutForm[type]?.alignment,
								vertical: vertical === layoutForm[type]?.alignment?.vertical ? undefined : vertical,
							})
						}
						textDirection={layoutForm[type]?.text_direction}
						handleDirectionChange={(direction: TextDirectionEnum) =>
							handleMessageChange(
								type,
								"text_direction",
								direction === layoutForm[type]?.text_direction ? undefined : direction,
							)
						}
					/>
				)}
				{(type === "foreground" || type === "logo" || type === "cta") && (
					<ObjectAlignmentButton
						value={layoutForm[type]?.alignment}
						handleIconClick={(alignment) => handleAlignmentChange(type, alignment)}
					/>
				)}
				{isGraphicalElement(type) && (
					<>
						<FileUploader
							buttonText={<UploadImageIcon className={styles.actionBtn} sx={{ marginTop: "4px" }} />}
							fileName={layoutForm.graphical_elements?.[getGraphicIndex(type)]?.element?.split("/").pop()}
							onUpload={(e) => uploadGraphicImage(e, type)}
							onDelete={() => deleteGraphicImage(type)}
							loading={layoutsStore.loadingGraphicIndex === getGraphicIndex(type)}
							inputProps={{ accept: "image/png, image/jpeg, image/jpg" }}
						/>
						<DeleteIcon className={styles.actionBtn} onClick={() => deleteGraphic(type)} />
					</>
				)}
			</Box>
		</>
	);
};

export default observer(LayoutLayerActions);
