import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import { shapes, vectors } from "../../../../../../constants/graphics";
import { useEditor } from "../../../../../../hooks/useEditor";
import styles from "./Graphics.module.scss";

const Graphics = () => {
	const editor = useEditor();
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.graphics" });

	const getScaleFactorFromViewport = () => {
		const transform = editor?.canvas.canvas.viewportTransform;
		return transform ? transform[0] : 1;
	};

	const addObject = React.useCallback(
		(url: string, strokeOnly: boolean) => {
			const scale = 1 / getScaleFactorFromViewport();
			if (editor) {
				const options = {
					type: "StaticVector",
					src: url,
					scaleX: scale,
					scaleY: scale,
					strokeOnly: strokeOnly,
				};
				editor.objects.add(options);
			}
		},

		[editor],
	);

	return (
		<Box className={styles.graphicsTapContainer}>
			<MainTabHeader title={t("title")} />
			<Box className={styles.tabs}>
				<Box className={styles.graphicsTapContent}>
					<Typography className={styles.titleLabel}>{t("lines")}</Typography>
					<Box className={`${styles.graphicsContainer} ${styles.borderBottom}`}>
						{vectors.map((vector, _index) => (
							<img key={_index} src={vector} alt="" onClick={() => addObject(vector, true)} />
						))}
					</Box>
					<Typography className={`${styles.titleLabel} ${styles.paddingTop}`}>{t("shapes")}</Typography>
					<Box className={styles.graphicsContainer}>
						{shapes.map((shape, _index) => (
							<img key={_index} src={shape} alt={t("vector")} onClick={() => addObject(shape, false)} />
						))}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Graphics;
