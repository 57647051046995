import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DesignEditorContext } from "../../../DesignEditor/contexts/DesignEditor";
import Selectable from "../../../components/common/Selectable/Selectable";
import { useAppStore } from "../../../hooks/useStores";
import { showErrorToast } from "../../../utils/toast";
import PlaygroundActionsHeader from "../ActionsHeader/PlaygroundActionsHeader";
import ImageToImageResult from "../ImageToImage/Results/ImageToImageResult";
import NewCollectionResult from "../ImageToImage/Results/New/NewCollectionResult";
import UploadedOrSavedResult from "../ImageToImage/Results/Uploaded/UploadedOrSavedResult";
import ProductPlacementResult from "../ProductPlacement/Generator/ProductPlacementResult";
import TextToImageSearch from "../TextToImage/Generator/Search/TextToImageSearch";
import TextToImageResult from "../TextToImage/Generator/TextToImageResult";
import EmptyPlayground from "./EmptyState/EmptyPlayground";
import styles from "./PlaygroundImages.module.scss";

const PlaygroundImages = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground" });
	const { playgroundStore, imageToImageStore } = useAppStore();
	const resultsRef = useRef<HTMLDivElement>(null);
	const { isPopupView } = useContext(DesignEditorContext);
	const [lastCountResults, setLastCountResult] = useState(playgroundStore.playgroundResults.length);

	useEffect(() => {
		const scrollBottom = () => {
			if (resultsRef.current) {
				resultsRef.current.scrollTo({ behavior: "smooth", top: resultsRef.current.scrollHeight });
			}
		};
		playgroundStore.playgroundResults.length > lastCountResults && scrollBottom();
		setLastCountResult(playgroundStore.playgroundResults.length);
	}, [playgroundStore.playgroundResults.length]);

	const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
		if (playgroundStore.selectedConfig === "imageToImage") {
			e.preventDefault();
			const droppedFiles = e.dataTransfer.files;
			if (droppedFiles.length > 0) {
				const files = Array.from(droppedFiles);
				if (files.length > imageToImageStore.MAX_FILES_LIMIT) {
					showErrorToast(t("imageToImage.filesExceedLimit"));
					return;
				}

				// const oversizedFile = files.find((file) => file.size > imageToImageStore.MAX_FILE_SIZE);
				// if (oversizedFile) {
				// 	showErrorToast(t("imageToImage.fileExceedsSize"));
				// 	return;
				// }
			}
			imageToImageStore.handleUploadImages({ target: { files: droppedFiles }, value: "" } as any);
		}
	};

	return (
		<Box className={styles.root}>
			<PlaygroundActionsHeader />
			{playgroundStore.selectedConfig === "productPlacement" ? (
				<Box className={styles.results}>
					{playgroundStore.playgroundResults.map((result, index) => (
						<ProductPlacementResult key={index} playgroundResult={result} rowIndex={index} />
					))}
				</Box>
			) : (
				<Selectable
					selectoRef={playgroundStore.selectoRef as any}
					selectableTargets={[".selectableImage"]}
					onSelect={(e) => playgroundStore.handleSelectImagesElements(e.selected, isPopupView)}
					innerScrollRef={resultsRef}
					className={styles.selectable}
				>
					<Box
						ref={resultsRef}
						className={`results ${styles.results}`}
						onDrop={handleDrop}
						onDragOver={(e) => e.preventDefault()}
					>
						<>
							{!playgroundStore.playgroundResults.length ? (
								<EmptyPlayground />
							) : (
								playgroundStore.playgroundResults.map((result, index) =>
									result.type === "textToImage" ? (
										<TextToImageResult key={index} playgroundResult={result} />
									) : result.type === "imageToImage" ? (
										<ImageToImageResult key={index} playgroundResult={result} />
									) : result.type === "upload" || result.type === "save" ? (
										<UploadedOrSavedResult
											key={index}
											playgroundResult={result}
											label={result.type}
										/>
									) : (
										result.type === "new" && (
											<NewCollectionResult key={index} playgroundResult={result} />
										)
									),
								)
							)}
						</>
					</Box>
					{playgroundStore.selectedConfig === "textToImage" && <TextToImageSearch />}
				</Selectable>
			)}
		</Box>
	);
};

export default observer(PlaygroundImages);
