import { Unstable_Popup as BasePopup } from "@mui/base";
import { Close } from "@mui/icons-material";
import { Box, IconButton, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import SubmittedFeedbackSVG from "../../../assets/images/svgs/SubmittedFeedback.svg";
import QueryService from "../../../utils/QueryService.ts";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import styles from "./SupportButton.module.scss";

interface IProps {
	page: string;
}

const SupportButton = ({ page }: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "supportButton" });
	const [supportPopupAnchor, setSupportPopupAnchor] = useState<null | HTMLElement>(null);
	const [supportLoading, setSupportLoading] = useState<boolean>(false);
	const [supportSubmitted, setSupportSubmitted] = useState<boolean>(false);
	const supportPopupRef = useRef(null);
	const supportTextRef = useRef<any>(null);
	const supportQueryService = new QueryService("/");

	const handleSupportButtonClick = (event: React.MouseEvent<HTMLElement>) => {
		setSupportPopupAnchor(supportPopupAnchor ? null : event.currentTarget);
	};

	const handleCloseSupportPopup = () => {
		setSupportPopupAnchor(null);
		setSupportSubmitted(false);
		setSupportLoading(false);
	};

	// useOutsideClick(supportPopupRef, () => {
	// 	handleCloseSupportPopup();
	// });

	const handleSupportSubmit = () => {
		if (!supportLoading && (supportTextRef.current?.value ?? "").length > 0) {
			setSupportLoading(true);
			supportQueryService
				.post("support", { page, supportText: supportTextRef.current?.value })
				.then(() => {
					setSupportSubmitted(true);
				})
				.finally(() => {
					setSupportLoading(false);
				});
		}
	};

	const SupportPopup = () => (
		<BasePopup open={Boolean(supportPopupAnchor)} anchor={supportPopupAnchor} disablePortal>
			<Box className={styles.supportPopup}>
				<IconButton onClick={() => handleCloseSupportPopup()} className={styles.closeButton}>
					<Close />
				</IconButton>
				<Box className={styles.supportPopupContent}>
					{supportSubmitted ? (
						<Box className={styles.submittedContainer}>
							<img src={SubmittedFeedbackSVG} />
							<Typography>
								<Trans i18nKey={"supportButton.thankYou"} />
							</Typography>
						</Box>
					) : (
						<>
							<Typography className={styles.supportPopupTitle}>{t("title")}</Typography>
							<TextField
								inputRef={supportTextRef}
								placeholder={t("placeholder")}
								fullWidth
								multiline
								autoFocus
								minRows={4}
								InputProps={{
									classes: {
										root: styles.supportTextField,
									},
								}}
							/>
							<Box className={styles.actionsContainer}>
								<BriaButton
									className={styles.supportPopupSubmitButton}
									buttonType="primary"
									loading={supportLoading}
									onClick={handleSupportSubmit}
								>
									{t("submit")}
								</BriaButton>
							</Box>
						</>
					)}
				</Box>
			</Box>
		</BasePopup>
	);

	return (
		<Box className={styles.supportButtonContainer} ref={supportPopupRef}>
			<BriaButton buttonType="textMedium" onClick={handleSupportButtonClick}>
				{t("button")}
			</BriaButton>
			<SupportPopup />
		</Box>
	);
};

export default observer(SupportButton);
