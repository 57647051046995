import { Dialog } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import LoadingPlaceholder from "../../common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import styles from "./LoadingPopup.module.scss";

interface IProps {
	open: boolean;
	onClose?: () => void;
	paperClassName?: string;
}

const LoadingPopup = ({ open, onClose = () => {}, paperClassName }: IProps) => {
	return (
		<Dialog
			classes={{
				paper: clsx(styles.container, paperClassName),
			}}
			onClose={onClose}
			open={open}
			fullWidth
		>
			<LoadingPlaceholder className={styles.loaderStyle} isLoading={true}></LoadingPlaceholder>
		</Dialog>
	);
};

export default observer(LoadingPopup);
