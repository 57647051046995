import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import BriaTable from "../../../../../components/common/BriaTable/BriaTable";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import { TableRow } from "../../../../../layout/TableLayout/TableLayout";
import { Layout, LayoutLayer, LayoutLayersEnum, ObjectPlacement, defaultGraphic } from "../../../../../models/layout";
import styles from "./LayoutConfig.module.scss";
import LayoutLayerActions from "./LayoutLayerActions";

type LayoutConfigRow = {
	order: string | ReactElement;
	topics: string | ReactElement;
	width: string | ReactElement;
	height: string | ReactElement;
	topLeftX: string | ReactElement;
	topLeftY: string | ReactElement;
	actions: ReactElement;
};

type Props = {
	layoutForm: Layout;
	handleFormChange: <K extends keyof Layout>(key: K, value: Layout[K]) => void;
	graphicImagesToUpload: File[];
	setGraphicImagesToUpload: (updatedArray: File[]) => void;
	graphicImagesToDelete: string[];
	setGraphicImagesToDelete: (updatedArray: string[]) => void;
};

const LayoutConfig = ({
	layoutForm,
	handleFormChange,
	graphicImagesToUpload,
	setGraphicImagesToUpload,
	graphicImagesToDelete,
	setGraphicImagesToDelete,
}: Props) => {
	const { layoutsStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.layoutEditor.form" });
	const [rows, setRows] = useState<TableRow<LayoutConfigRow>[]>([]);
	const headerArray = [
		"",
		t("table.headers.topics"),
		t("table.headers.topLeftX"),
		t("table.headers.topLeftY"),
		t("table.headers.width"),
		t("table.headers.height"),
		t("table.headers.actions"),
	];

	useEffect(() => {
		setRows(layoutForm.order.map(createRow));
	}, [layoutForm, layoutsStore.configErrors]);

	const reorderObject = (currIndex: number, targetIndex: number) => {
		const newOrder = [...layoutForm.order];
		const removedElement = newOrder.splice(currIndex, 1)[0];
		newOrder.splice(targetIndex, 0, removedElement);
		handleFormChange("order", newOrder);
	};

	const createRow = (topic: LayoutLayer, orderValue: number): TableRow<LayoutConfigRow> => {
		const topicName = isGraphicalElement(topic) ? "Graphics" : LayoutLayersEnum[topic];
		const topicPlacement = isGraphicalElement(topic)
			? layoutForm["graphical_elements"]?.[getGraphicIndex(topic)]?.placement
			: layoutForm[topic]?.placement;

		const errorPlacement = isGraphicalElement(topic)
			? layoutsStore.configErrors["graphical_elements"]?.[getGraphicIndex(topic)]?.placement
			: layoutsStore.configErrors[topic]?.placement;

		return {
			key: topic,
			data: {
				order: (
					<BriaInput
						value={orderValue + 1}
						onChange={(e) => reorderObject(orderValue, parseInt(e.target.value) - 1)}
						InputProps={{ className: styles.orderInput }}
						variant="outlined"
						type="number"
					/>
				),
				topics: <Box className={styles.outlinedInputStyle}>{topicName}</Box>,
				topLeftX: (
					<BriaInput
						value={topicPlacement?.x ?? NaN}
						onChange={(e) => updatePlacement(topic, "x", parseInt(e.target.value))}
						InputProps={{
							className: styles.inputStyle,
							inputProps: {
								max: layoutForm.placement.width,
							},
						}}
						FormHelperTextProps={{
							classes: {
								root: styles.helperText,
							},
						}}
						variant="outlined"
						type="number"
						error={typeof errorPlacement === "string" ? !!errorPlacement : !!errorPlacement?.x}
						helperText={typeof errorPlacement === "string" ? errorPlacement : errorPlacement?.x}
					/>
				),
				topLeftY: (
					<BriaInput
						value={topicPlacement?.y ?? NaN}
						onChange={(e) => updatePlacement(topic, "y", parseInt(e.target.value))}
						InputProps={{
							className: styles.inputStyle,
							inputProps: {
								max: layoutForm.placement.height,
							},
						}}
						FormHelperTextProps={{
							classes: {
								root: styles.helperText,
							},
						}}
						variant="outlined"
						type="number"
						error={typeof errorPlacement === "string" ? !!errorPlacement : !!errorPlacement?.y}
						helperText={typeof errorPlacement === "string" ? errorPlacement : errorPlacement?.y}
					/>
				),
				width: (
					<BriaInput
						value={topicPlacement?.width ?? NaN}
						onChange={(e) => updatePlacement(topic, "width", parseInt(e.target.value))}
						InputProps={{
							className: styles.inputStyle,
							inputProps: {
								max: layoutForm.placement.width,
							},
						}}
						FormHelperTextProps={{
							classes: {
								root: styles.helperText,
							},
						}}
						variant="outlined"
						type="number"
						error={typeof errorPlacement === "string" ? !!errorPlacement : !!errorPlacement?.width}
						helperText={typeof errorPlacement === "string" ? errorPlacement : errorPlacement?.width}
					/>
				),
				height: (
					<BriaInput
						value={topicPlacement?.height ?? NaN}
						onChange={(e) => updatePlacement(topic, "height", parseInt(e.target.value))}
						InputProps={{
							className: styles.inputStyle,
							inputProps: {
								max: layoutForm.placement.height,
							},
						}}
						FormHelperTextProps={{
							classes: {
								root: styles.helperText,
							},
						}}
						variant="outlined"
						type="number"
						error={typeof errorPlacement === "string" ? !!errorPlacement : !!errorPlacement?.height}
						helperText={typeof errorPlacement === "string" ? errorPlacement : errorPlacement?.height}
					/>
				),
				actions: (
					<LayoutLayerActions
						type={topic}
						{...{
							layoutForm,
							handleFormChange,
							graphicImagesToUpload,
							graphicImagesToDelete,
							setGraphicImagesToUpload,
							setGraphicImagesToDelete,
						}}
					/>
				),
			},
		};
	};

	const updatePlacement = (topic: LayoutLayer, placementKey: keyof ObjectPlacement, newValue: number) => {
		if (isGraphicalElement(topic)) {
			const graphicElementIndex = getGraphicIndex(topic);
			const graphicErrors = layoutsStore.configErrors.graphical_elements?.[graphicElementIndex];
			const updatedGraphics = layoutForm.graphical_elements?.map((graphic, i) =>
				i === graphicElementIndex
					? handleLayerPlacement(graphic, placementKey, newValue, graphicErrors)
					: graphic,
			);
			handleFormChange("graphical_elements", updatedGraphics);
		} else {
			handleFormChange(
				topic,
				handleLayerPlacement(layoutForm[topic], placementKey, newValue, layoutsStore.configErrors[topic]),
			);
		}
	};

	const handleLayerPlacement = (layer: any, placementKey: keyof ObjectPlacement, newValue: number, errors: any) => {
		const updatedLayer = { ...layer, placement: { ...layer?.placement, [placementKey]: newValue } };
		if (Object.values(updatedLayer.placement as ObjectPlacement).every(isNaN)) {
			delete updatedLayer.placement;
			delete errors?.placement;
		}
		errors?.placement?.[placementKey] && delete errors.placement[placementKey];
		return updatedLayer;
	};

	const addGraphic = () => {
		const graphicsCount = layoutForm.order.filter(isGraphicalElement).length;
		const graphicOrderTopic = `graphical_element_${graphicsCount + 1}` as LayoutLayer;
		handleFormChange("order", [...layoutForm.order, graphicOrderTopic]);
		handleFormChange("graphical_elements", [...(layoutForm.graphical_elements || []), defaultGraphic]);
	};

	return (
		<InputLayout label={t("table.label")} labelClassName={styles.label} className={styles.container}>
			<BriaTable
				{...{ headerArray, rows }}
				bottomButtonText={t("table.addGraphics")}
				bottomButtonIconType="plus"
				bottomButtonClick={addGraphic}
			/>
		</InputLayout>
	);
};

export default observer(LayoutConfig);

export const isGraphicalElement = (topic: string) => /^graphical_element_\d+$/.test(topic);
export const getGraphicIndex = (topic: string) => Number(topic.split("_")[2]) - 1;
