import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { Trans } from "react-i18next";

import LinkConstants from "../../../constants/LinkConstants.ts";
import classes from "./Agreement.module.scss";

const Agreement: React.FC = () => {
	return (
		<Box>
			<Typography className={classes.typo3}>
				<Trans
					i18nKey="acceptPrivacyPolicyAndTermsOfService"
					components={[
						<Link
							onClick={(_e: React.MouseEvent<any>) => {
								window.open(LinkConstants.PRIVACY_POLICY, "_blank");
							}}
							className={classes.link}
						/>,
						<Link
							onClick={(_e: React.MouseEvent<any>) => {
								window.open(LinkConstants.TERMS_AND_CONDITIONS_LINK, "_blank");
							}}
							className={classes.link}
						/>,
					]}
				/>
			</Typography>
		</Box>
	);
};

export default Agreement;
