import { firebaseAuth } from "../../config/firebase";
import { getSelectedOrganization } from "../../helpers/localStorage";
import { CampaignEntityStatus } from "../../models/common";

export interface Template {
	id?: number;
	name: string;
	description?: string;
	status: CampaignEntityStatus;
	org_id?: string | null;
	user_id?: string | null;
	preview: string;
	ads_json: any;
	created_at: Date;
	updated_at: Date;
}

export const defaultTemplate: Template = {
	id: undefined,
	name: "Untitled - Template Name",
	description: "",
	status: CampaignEntityStatus.PENDING,
	org_id: getSelectedOrganization()?.organization?.uid || null,
	user_id: firebaseAuth.currentUser?.uid || null,
	preview: "",
	ads_json: {},
	created_at: new Date(),
	updated_at: new Date(),
};
