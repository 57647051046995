function Uploads1({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.00977 10.6685V12.0018C3.00977 12.3554 3.15024 12.6946 3.40029 12.9446C3.65034 13.1946 3.98948 13.3351 4.3431 13.3351H12.3431C12.6967 13.3351 13.0359 13.1946 13.2859 12.9446C13.536 12.6946 13.6764 12.3554 13.6764 12.0018V10.6685M5.00977 6.00179L8.3431 2.66846M8.3431 2.66846L11.6764 6.00179M8.3431 2.66846V10.6685"
				stroke="#5B5B5B"
				stroke-width="1.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default Uploads1;
