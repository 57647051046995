import { Tooltip, TooltipProps } from "@mui/material";
import Box from "@mui/material/Box";
import clsx from "clsx";
import styles from "./BriaTooltip.module.scss";

const BriaTooltip = ({
	placement = "top",
	enterDelay = 100,
	leaveDelay = 50,
	arrow = true,
	className,
	children,
	...rest
}: TooltipProps) => {
	return (
		<Tooltip
			{...rest}
			placement={placement}
			enterDelay={enterDelay}
			leaveDelay={leaveDelay}
			arrow
			className={className}
			componentsProps={{
				...rest.componentsProps,
				tooltip: {
					...rest.componentsProps?.tooltip,
					className: clsx(rest.componentsProps?.tooltip?.className, styles.tooltip),
				},
				arrow: {
					...rest.componentsProps?.arrow,
					className: clsx(rest.componentsProps?.arrow?.className, styles.arrow),
				},
				popper: {
					...rest.componentsProps?.popper,
					className: clsx(rest.componentsProps?.popper?.className, className),
				},
			}}
		>
			<Box>{children}</Box>
		</Tooltip>
	);
};

export default BriaTooltip;
