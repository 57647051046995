import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function ImageIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 34 35" {...props}>
			<path
				d="M23 9.36816H23.02M1 23.3682L9 15.3682C9.91211 14.4905 10.9468 14.0284 12 14.0284C13.0532 14.0284 14.0879 14.4905 15 15.3682L25 25.3682M21 21.3682L23 19.3682C23.9121 18.4905 24.9468 18.0284 26 18.0284C27.0532 18.0284 28.0879 18.4905 29 19.3682L33 23.3682M7 1.36816H27C30.3137 1.36816 33 4.05446 33 7.36816V27.3682C33 30.6819 30.3137 33.3682 27 33.3682H7C3.68629 33.3682 1 30.6819 1 27.3682V7.36816C1 4.05446 3.68629 1.36816 7 1.36816Z"
				stroke="#5B5B5B"
				strokeOpacity="0.5"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default ImageIcon;
