import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FileUploader from "../../../../components/common/FileUploader/FileUploader";
import RouterConstants from "../../../../constants/RouterConstants";
import { useAppStore } from "../../../../hooks/useStores";
import EditorLayout from "../../../../layout/EditorLayout/EditorLayout";
import { Layout, defaultLayout } from "../../../../models/layout";
import { Template, defaultTemplate } from "../../../../models/template";
import Layouts from "../../Layouts/Layouts";
import LayoutPreview from "../../Layouts/Preview/LayoutPreview";
import styles from "./TemplateEditor.module.scss";

export type EditTemplateLocationState = {
	templateToEdit?: Template;
};

const TemplateEditor = () => {
	const location = useLocation();
	const { templatesStore, uiStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.templateEditor" });
	const params = useParams();
	const navigate = useNavigate();
	const [templateForm, setTemplateForm] = useState<Template>(location.state?.templateToEdit ?? defaultTemplate);
	const { layoutsStore } = useAppStore();
	const [layoutToPreview, setLayoutToPreview] = useState<Layout>(defaultLayout);

	const handleFormChange = <K extends keyof Template>(key: K, value: Template[K]) => {
		setTemplateForm((prevIframe) => ({ ...prevIframe, [key]: value }));
	};

	useEffect(() => {
		uiStore.hideSideBar();
		uiStore.showBackButton(RouterConstants.TEMPLATES.fullPath);

		templatesStore.formErrors = {};
		if (!location.state?.templateToEdit && params.id && !isNaN(Number(params.id))) {
			templatesStore.getTemplate(Number(params.id)).then(setTemplateForm);
		}

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
			window.history.replaceState({}, "");
		};
	}, [params.id]);

	const uploadPsd = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: File | null = e.target.files && e.target.files[0];

		if (file && file.type === "image/vnd.adobe.photoshop") {
			const psd_example: string = await templatesStore.uploadTemplatePsd(templateForm.id, file);
			handleFormChange("psd_example", psd_example);
		} else {
			console.error("Please choose a valid PSD file.");
		}
	};

	const deletePsd = async () => {
		const fileName = templateForm.psd_example?.split("/").pop();
		if (fileName) {
			await templatesStore.deleteTemplatePsd(templateForm.id, fileName);
			handleFormChange("psd_example", undefined);
		}
	};

	const saveTemplate = async () => {
		try {
			if (templateForm.id) {
				await templatesStore.updateTemplate(templateForm);
			} else {
				await templatesStore.createTemplate(templateForm);
			}
			navigate(RouterConstants.TEMPLATES.fullPath);
		} catch (e) {
			console.error(e);
		}
	};

	const showPreview = async (layoutToPreview: Layout) => {
		setLayoutToPreview(layoutToPreview);
	};

	const uploadImgExample = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: File | null = e.target.files && e.target.files[0];

		if (file) {
			const imgExample = await layoutsStore.uploadImgExample(
				layoutToPreview.id,
				layoutToPreview.template_id,
				file,
			);
			setLayoutToPreview((prevLayout) => ({ ...prevLayout, image_example: imgExample }));
		}
	};

	const deleteImgExample = async () => {
		const fileName = layoutToPreview.image_example?.split("/").pop();
		if (fileName) {
			await layoutsStore.deleteImgExample(layoutToPreview.id, layoutToPreview.template_id, fileName);
			setLayoutToPreview((prevLayout) => ({ ...prevLayout, image_example: undefined }));
		}
	};

	const handleNameChange = async (e: ChangeEvent<HTMLInputElement>) => {
		templatesStore.formErrors.invalidName = false;
		handleFormChange("name", e.target.value);
	};

	return (
		<EditorLayout
			loading={templatesStore.isLoadingEditor}
			name={templateForm.name}
			onNameChange={handleNameChange}
			errorName={templatesStore.formErrors.invalidName}
			primaryButton={t("saveBtn")}
			primaryButtonClick={saveTemplate}
			secondaryButton={
				!!templateForm.id && (
					<FileUploader
						fileName={templateForm.psd_example?.split("/").pop()}
						buttonText={t("uploadPsd")}
						loading={templatesStore.isLoadingPsd}
						onUpload={uploadPsd}
						onDelete={deletePsd}
						inputProps={{ accept: ".psd" }}
					/>
				)
			}
		>
			<Box className={styles.content}>
				<Layouts templateId={Number(params.id)} showPreview={showPreview} />
				<LayoutPreview
					layout={layoutToPreview}
					loading={layoutsStore.isLoading}
					onUpload={uploadImgExample}
					onDelete={deleteImgExample}
				/>
			</Box>
		</EditorLayout>
	);
};

export default observer(TemplateEditor);
