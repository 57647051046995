import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Box, Dialog } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAppStore } from "../../../hooks/useStores.tsx";
import Playground from "../../../pages/Playground/Playground.tsx";
import TextToImageConfig from "../../../pages/Playground/TextToImage/Config/TextToImageConfig.tsx";
import styles from "./TextToImagePopup.module.scss";

const TextToImagePopup = () => {
	const { playgroundStore } = useAppStore();

	return (
		<Dialog
			onClose={playgroundStore.closeModal}
			open={playgroundStore.isText2ImagePopupOpened}
			maxWidth={false}
			classes={{
				paper: `${styles.genContainer}`,
			}}
		>
			<Box className={styles.configContiner}>
				<TextToImageConfig />
			</Box>
			<Box className={styles.textToImageContiner}>
				<Playground
					selectedConfig="textToImage"
					location={{
						state: { prompt: playgroundStore.textToGenerate, aspectRatio: playgroundStore.aspectRatio },
					}}
				/>
			</Box>
			<CloseIcon onClick={playgroundStore.closeModal} className={styles.closeIcon} />
		</Dialog>
	);
};

const ObservedComponent = observer(TextToImagePopup);
export default ObservedComponent;
