import { nanoid } from "nanoid";
import useScenesUtils from "../../../../../../../CustomUtils/ScenesUtils";
import { getDefaultTemplate } from "../../../../../../../constants/design-editor";
import useDesignEditorContext from "../../../../../../../hooks/useDesignEditorContext";
import { useEditor } from "../../../../../../../hooks/useEditor";
import { useFrame } from "../../../../../../../hooks/useFrame";
import { IScene } from "../../../../../../../types";

const useSceneOperations = () => {
	const { setScenes, setContextMenuTimelineRequest, contextMenuTimelineRequest, setCurrentScene, setCurrentDesign } =
		useDesignEditorContext();
	const frame = useFrame();
	const editor = useEditor();
	const { updateScenes } = useScenesUtils();

	const deleteScene = async (id?: string) => {
		const scenes = await updateScenes();
		const updatedScenes = id
			? scenes.filter((scene) => scene.id !== id)
			: scenes.filter((scene) => scene.id !== contextMenuTimelineRequest.id);

		setContextMenuTimelineRequest({ ...contextMenuTimelineRequest, visible: false });

		if (updatedScenes.length > 0) {
			setScenes(updatedScenes);
		} else {
			const defaultTemplate = getDefaultTemplate({
				width: frame.width,
				height: frame.height,
			});

			await editor?.scene.importFromJSON(defaultTemplate);
			setCurrentDesign({
				id: nanoid(),
				frame: defaultTemplate.frame,
				metadata: {},
				name: "Untitled Design",
				preview: "",
				scenes: [],
			});

			const initialDesign = editor?.scene.exportToJSON() as IScene;
			const preview = await editor?.renderer.render(initialDesign);
			setCurrentScene({ ...initialDesign, preview, duration: 5000 });
			setScenes([{ ...initialDesign, preview, duration: 5000 }]);
		}
	};

	const duplicateScene = async (id?: string) => {
		const scenes = await updateScenes();
		const currentScene: IScene | undefined = id
			? scenes.find((scene: IScene) => scene.id === id)
			: scenes.find((scene: IScene) => scene.id === contextMenuTimelineRequest.id);
		if (currentScene) {
			const updatedScenes: IScene[] = [...scenes, { ...currentScene, id: nanoid() }];
			setScenes(updatedScenes);
			setContextMenuTimelineRequest({ ...contextMenuTimelineRequest, visible: false });
		}
	};

	return { deleteScene, duplicateScene };
};

export default useSceneOperations;
