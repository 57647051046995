export enum StatusEnum {
	ACTIVE = "Active",
	PENDING = "Pending",
	INACTIVE = "Inactive",
	DELETED = "Deleted",
}

export enum CampaignEntityStatus {
	ACTIVE = "Active",
	PENDING = "Pending",
	INACTIVE = "Inactive",
	DELETED = "Deleted",
	DRAFT = "Draft",
}

export enum LogoTypeEnum {
	LOGO_1 = "LOGO_1",
	LOGO_2 = "LOGO_2",
	LOGO_3 = "LOGO_3",
	LOGO_4 = "LOGO_4",
	LOGO_5 = "LOGO_5",
	LOGO_6 = "LOGO_6",
}

export enum LogoTypeMappingEnum {
	LOGO_1 = "Positive horizontal logo",
	LOGO_2 = "Positive vertical logo",
	LOGO_3 = "Positive icon logo",
	LOGO_4 = "Negative horizontal logo",
	LOGO_5 = "Negative vertical logo",
	LOGO_6 = "Negative icon logo",
}

export enum ColorTypeMappingEnum {
	COLOR_1 = "Dark color #1",
	COLOR_2 = "Dark color #2",
	COLOR_3 = "Dark color #3",
	COLOR_4 = "Light color #1",
	COLOR_5 = "Light color #2",
	COLOR_6 = "Light color #3",
}

export enum FontTypeEnum {
	FONT_1 = "FONT_1",
	FONT_2 = "FONT_2",
	FONT_3 = "FONT_3",
	FONT_4 = "FONT_4",
	FONT_5 = "FONT_5",
}

export enum VerticalEnum {
	TOP = "top",
	BOTTOM = "bottom",
	CENTER = "center",
}

export enum TextDirectionEnum {
	RTL = "rtl",
	LTR = "ltr",
}

export enum HorizontalEnum {
	LEFT = "left",
	RIGHT = "right",
	CENTER = "center",
}

export interface PaginatedItems<T> {
	total: number;
	items: T[];
}

export interface PaginatedImages<T> {
	total_pages: number;
	total_images: number;
	images: T[];
}

export interface AllItems<T> {
	items: T[];
}

export enum EventsLogEnum {
	NEW_ORGANIZATION = "NEW_ORGANIZATION",
	CREATE_STAGING_API_KEY = "CREATE_STAGING_API_KEY",
	CREATE_PRODUCTION_API_KEY = "CREATE_PRODUCTION_API_KEY",
	ACTIVE_ORG = "ACTIVE_ORG",
	FREE_USER_LIMIT = "FREE_USER_LIMIT",
	INACTIVE_ORG = "INACTIVE_ORG",
	START_IFRAME_CONFIG = "START_IFRAME_CONFIG",
	ACTIVATED_IFRAME = "ACTIVATED_IFRAME",
	SUBSCRIPTION_MODELS = "SUBSCRIPTION_MODELS",
	SUBSCRIPTION_API = "SUBSCRIPTION_API",
	BOUGHT_STARTER = "BOUGHT_STARTER",
	NEW_USER = "NEW_USER",
	INVITED_USER = "INVITED_USER",
	INACTIVE_ORG_FOR_5_DAYS = "INACTIVE_ORG_FOR_5_DAYS",
	USER_COPIED_API_KEY = "USER_COPIED_API_KEY",
	INACTIVE_IFRAME_FOR_10_DAYS = "INACTIVE_IFRAME_FOR_10_DAYS",
	FREE_USER_800_API_CALLS_THIS_MONTH = "FREE_USER_800_API_CALLS_THIS_MONTH",
	REQUEST_FOR_TAILORED_MODELS = "REQUEST_FOR_TAILORED_MODELS",
	REQUEST_FOR_FOUNDATION_MODELS = "REQUEST_FOR_FOUNDATION_MODELS",
	STARTED_FIRST_TRAINING_MODEL = "STARTED_FIRST_TRAINING_MODEL",
	SUBMITTED_FIRST_TRAINING_MODEL = "SUBMITTED_FIRST_TRAINING_MODEL",
}

export enum CampaignViewModes {
	FULL_VIEW = "FullView",
	GRID_VIEW = "GridView",
}

export interface CampaignViewMode {
	viewMode: CampaignViewModes;
}
