import { getSelectedOrganization } from "../helpers/localStorage";
import { LogoTypeEnum } from "./common";

export interface BrandLogo {
	id?: number;
	name?: string;
	src: string;
	org_id?: string;
	user_id?: string;
	created_at: Date;
	updated_at: Date;
	type: LogoTypeEnum;
	file?: any;
}

export const defaultBrandLogos: BrandLogo[] = Array(6).fill({
	name: "",
	src: "",
	org_id: getSelectedOrganization()?.organization?.uid || "",
});
