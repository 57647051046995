import imageCompression from "browser-image-compression";
import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { IMAGE_COMPRESSION_CONFIG } from "../../config/image.ts";
import { AIEditorOption, AIEditorOptions, BoxGranularityEnum } from "../../models/aiEditor.ts";
import QueryService from "../../utils/QueryService.ts";

export interface IAIEditorStore {
	isLoading: boolean;
	isError: boolean;
	selectedEditorTabLabel: string | undefined;
	selectedImageUrl: string;
	shapeSize: number;
	aiEditorPopup: boolean;
	isUploadingImage: boolean;
	imageViewerOpen: boolean;
	openImageUploadPopup: boolean;

	setProperty<K extends keyof AIEditorStore>(key: K, value: AIEditorStore[K]): void;

	getTabObjectByLabel(label: string | undefined): Promise<AIEditorOption | undefined>;

	handleUploadImageToEngine(e: ChangeEvent<HTMLInputElement>, uploadToS3Only?: boolean): Promise<string>;

	handleCloseImageUploadPopup(): void;
}

export default class AIEditorStore implements IAIEditorStore {
	isLoading: boolean = false;
	isError: boolean = false;
	shapeSize: number = BoxGranularityEnum.Max / 2;
	selectedImageUrl: string = "";
	selectedEditorTabLabel: string | undefined = AIEditorOptions.find((option) => option.isEnabled)?.label;
	aiEditorPopup: boolean = false;
	isUploadingImage: boolean = false;
	imageViewerOpen: boolean = false;
	openImageUploadPopup: boolean = false;
	private fileQueryService: QueryService = new QueryService("/upload-image");

	constructor() {
		makeAutoObservable(this);
	}

	setProperty = async <K extends keyof AIEditorStore>(key: K, value: AIEditorStore[K]) => {
		runInAction(() => ((this as AIEditorStore)[key] = value));
	};

	getTabObjectByLabel = async (label: string | undefined): Promise<AIEditorOption | undefined> => {
		return AIEditorOptions.find((item) => item.label === label);
	};

	handleUploadImageToEngine = async (e: ChangeEvent<HTMLInputElement>, uploadToS3Only?: boolean): Promise<string> => {
		try {
			let imageUrl: string | URL = "";
			let uploadingPath = "/file?assign_to_org=true";
			this.isUploadingImage = true;
			const imgFile: File | null = e.target.files && e.target.files[0];
			if (!imgFile) {
				throw new Error("No file selected");
			}

			const compressedFile = await imageCompression(imgFile, IMAGE_COMPRESSION_CONFIG);
			e.target.value = "";
			const formData = new FormData();
			formData.append("file", compressedFile);

			if (uploadToS3Only) {
				uploadingPath = "/s3/file";
				formData.append("file", compressedFile, imgFile.name);
			}
			imageUrl = await this.fileQueryService.post(uploadingPath, formData, {
				"Content-Type": "multipart/form-data",
			});

			runInAction(() => {
				imageUrl = new URL(imageUrl);
				imageUrl.searchParams.set("imageId", uuidv4());
				this.selectedImageUrl = imageUrl.toString();
				this.isError = false;
				this.isUploadingImage = false;
				this.handleCloseImageUploadPopup();
			});

			return imageUrl as string;
		} catch (error: any) {
			this.isUploadingImage = false;
			throw new Error(`Error uploading image file: ${error.message || error.toString()}`);
		}
	};

	handleCloseImageUploadPopup = () => {
		this.openImageUploadPopup = false;
	};
}
