import imageCompression from "browser-image-compression";
import { makeAutoObservable, runInAction } from "mobx";
import { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";
import { ANALYTICS_EVENTS } from "../../../analytics-store.tsx";
import { IMAGE_COMPRESSION_CONFIG } from "../../../config/image.ts";
import useErrorToast from "../../../hooks/useErrorToast.tsx";
import { IRootStore } from "../../../mobx/root-store.tsx";
import {
	ImageToImageConfigType,
	OfflineProps,
	PlaygroundImage,
	PlaygroundResult,
} from "../../../models/image-to-image.ts";
import QueryService from "../../../utils/QueryService.ts";

export interface IImageToImageStore {
	config: ImageToImageConfigType;
	handleConfigChange: <K extends keyof ImageToImageConfigType>(key: K, value: ImageToImageConfigType[K]) => void;
	handleUploadImages: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	generateImageToImage: (images?: PlaygroundImage[], config?: ImageToImageConfigType) => Promise<void>;
	nflRmbgBeforeStyle: (images: PlaygroundImage[]) => Promise<void>;
	mixFaceBody: () => Promise<void>;
	refineColor: () => Promise<void>;
	addAIEditorImageInARow: (imageUrl: string) => Promise<void>;
	MAX_FILES_LIMIT: number;
	MAX_FILE_SIZE: number;
}

export default class ImageToImageStore implements IImageToImageStore {
	private queryService: QueryService = new QueryService("/image-to-image");
	rootStore: IRootStore;
	config: ImageToImageConfigType = {
		original_image: { id: "", url: "" },
		num_results: 4,
	};
	errorToast = useErrorToast();

	MAX_FILE_SIZE = 12 * 1024 * 1024;

	MAX_FILES_LIMIT = 35;

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	handleConfigChange = <K extends keyof ImageToImageConfigType>(key: K, value: ImageToImageConfigType[K]) => {
		this.config[key] = value;
	};

	generateImageToImage = async (
		images?: PlaygroundImage[],
		config: ImageToImageConfigType = this.config,
		isFromUpload?: boolean,
	): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		const selectedImages: PlaygroundImage[] = images || playgroundStore.getSelectedImages();
		if (!selectedImages.length) return;
		const savedIndex = playgroundStore.playgroundResults.length;
		const resultsSkeletons: PlaygroundResult = {
			id: uuidv4(),
			config: { ...config },
			type: "imageToImage",
			isFromUpload,
			images: Array.from({ length: selectedImages.length * config.num_results }).map((_) => ({
				id: "",
				url: "",
				loading: true,
			})),
		};
		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];

		selectedImages.forEach(async (selectedImage, index) => {
			try {
				const formData = new FormData();
				selectedImage.file && formData.append("file", selectedImage.file);
				const existingVariations = playgroundStore.playgroundResults.flatMap((result) =>
					result.images.filter(
						(img) =>
							img.type === "imageToImage" &&
							(img.config as ImageToImageConfigType).original_image.id === selectedImage.id,
					),
				).length;
				formData.append(
					"config",
					JSON.stringify({
						...config,
						original_image: {
							...selectedImage,
							offline_props: { ...selectedImage.offline_props, variation: existingVariations },
						},
					}),
				);
				const { results, original_url } = await this.queryService.post("/", formData, {
					"Content-Type": "multipart/form-data",
				});
				runInAction(() => {
					const startIndex = index * config.num_results;

					results.forEach((generatedImage: PlaygroundImage, index: number) => {
						playgroundStore.playgroundResults[savedIndex].images[startIndex + index] = {
							...generatedImage,
							id: uuidv4(),
							loading: true,
							config: {
								...playgroundStore.playgroundResults[savedIndex].config,
								original_image: { ...selectedImage, url: original_url },
							} as ImageToImageConfigType,
							type: "imageToImage",
							sessionId: (selectedImage.sessionId ?? original_url).split("?")[0],
							variationNum: existingVariations + index + 1,
							offline_props: {
								...(selectedImage.offline_props as OfflineProps),
								variation: existingVariations + index + 1,
							},
						};
						if (isFromUpload) {
							this.rootStore.analyticsStore.logImageToImageEvent(
								ANALYTICS_EVENTS.IMAGE_UPLOAD_ITI,
								playgroundStore.playgroundResults[savedIndex].images[startIndex + index],
							);
						} else {
							this.rootStore.analyticsStore.logImageToImageEvent(
								ANALYTICS_EVENTS.ITI_GENERATE,
								playgroundStore.playgroundResults[savedIndex].images[startIndex + index],
							);
						}
					});
				});
			} catch (e: any) {
				runInAction(() => {
					playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
						(_, index) => index !== savedIndex,
					);
					throw new Error(`Error generating images: ${e.message || e.toString()}`);
				});
			}
		});
	};

	mixFaceBody = async (): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		const selectedImages: PlaygroundImage[] = playgroundStore.getSelectedImages();
		const isMixEnabled =
			selectedImages.length === 2 &&
			selectedImages.every(
				(image) =>
					image.type === "imageToImage" &&
					(image.config as ImageToImageConfigType)?.original_image.id ===
						(selectedImages[0].config as ImageToImageConfigType)?.original_image.id,
			);
		if (!isMixEnabled) return;
		const savedIndex = playgroundStore.playgroundResults.length;
		const resultsSkeletons: PlaygroundResult = {
			id: uuidv4(),
			config: { ...this.config, num_results: 1 },
			type: "imageToImage",
			images: Array.from({ length: 2 }).map((_) => ({
				id: "",
				url: "",
				loading: true,
			})),
		};
		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];
		Array.from({ length: 2 }).forEach(async (_, index) => {
			try {
				const formData = new FormData();
				this.handleConfigChange(
					"original_image",
					(selectedImages[index].config as ImageToImageConfigType).original_image,
				);
				formData.append(
					"config",
					JSON.stringify({
						...this.config,
						num_results: 1,
						style: {
							...this.config.style,
							face_seed: selectedImages[index % 2].style_props?.face_seed,
							body_seed: selectedImages[(index + 1) % 2].style_props?.body_seed,
							face_sd_edit: selectedImages[index % 2].style_props?.face_sd_edit,
						},
					}),
				);
				const { results } = await this.queryService.post("/", formData, {
					"Content-Type": "multipart/form-data",
				});

				runInAction(() => {
					const startIndex = index;
					results.forEach((generatedImage: PlaygroundImage, index: number) => {
						playgroundStore.playgroundResults[savedIndex].images[startIndex + index] = {
							...generatedImage,
							id: uuidv4(),
							loading: true,
							config: {
								...playgroundStore.playgroundResults[savedIndex].config,
								original_image: (selectedImages[index].config as ImageToImageConfigType).original_image,
							} as ImageToImageConfigType,
							type: "imageToImage",
							sessionId: selectedImages[index].sessionId,
						};
						this.rootStore.analyticsStore.logImageToImageEvent(
							ANALYTICS_EVENTS.ITI_MIX_FACE_BODY,
							playgroundStore.playgroundResults[savedIndex].images[startIndex + index],
						);
					});
				});
			} catch (e: any) {
				runInAction(() => {
					playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
						(_, index) => index !== savedIndex,
					);
					throw new Error(`Error generating images: ${e.message || e.toString()}`);
				});
			}
		});
	};

	refineColor = async (): Promise<void> => {
		const { playgroundStore } = this.rootStore;
		const selectedImages: PlaygroundImage[] = playgroundStore.getSelectedImages();
		if (!selectedImages.length) return;
		const savedIndex = playgroundStore.playgroundResults.length;
		const resultsSkeletons: PlaygroundResult = {
			id: uuidv4(),
			config: { ...this.config, num_results: 1 },
			type: "imageToImage",
			images: Array.from({ length: selectedImages.length }).map((_) => ({
				id: "",
				url: "",
				loading: true,
			})),
		};
		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, resultsSkeletons];
		Array.from({ length: selectedImages.length }).forEach(async (_, index) => {
			try {
				const formData = new FormData();
				this.handleConfigChange(
					"original_image",
					(selectedImages[index].config as ImageToImageConfigType).original_image,
				);
				formData.append(
					"config",
					JSON.stringify({
						...this.config,
						num_results: 1,
						style: {
							...this.config.style,
							face_seed: selectedImages[index].style_props?.face_seed,
							body_seed: selectedImages[index].style_props?.body_seed,
							face_sd_edit: selectedImages[index].style_props?.face_sd_edit,
							is_normalize_face: true,
						},
					}),
				);
				const { results } = await this.queryService.post("/", formData, {
					"Content-Type": "multipart/form-data",
				});

				runInAction(() => {
					const startIndex = index;
					results.forEach((generatedImage: PlaygroundImage, index: number) => {
						playgroundStore.playgroundResults[savedIndex].images[startIndex + index] = {
							...generatedImage,
							id: uuidv4(),
							loading: true,
							config: {
								...playgroundStore.playgroundResults[savedIndex].config,
								original_image: (selectedImages[index].config as ImageToImageConfigType).original_image,
							} as ImageToImageConfigType,
							type: "imageToImage",
						};
						this.rootStore.analyticsStore.logImageToImageEvent(
							ANALYTICS_EVENTS.ITI_COLOR_REFINE_IMAGE,
							playgroundStore.playgroundResults[savedIndex].images[startIndex + index],
						);
					});
				});
			} catch (e: any) {
				runInAction(() => {
					playgroundStore.playgroundResults = playgroundStore.playgroundResults.filter(
						(_, index) => index !== savedIndex,
					);
					throw new Error(`Error generating images: ${e.message || e.toString()}`);
				});
			}
		});
	};

	nflRmbgBeforeStyle = async (images: PlaygroundImage[]): Promise<void> => {
		await this.generateImageToImage(
			images,
			{
				original_image: { id: "", url: "" },
				background: { remove: true },
				num_results: 1,
			},
			true,
		);
	};

	handleUploadImages = async (e: ChangeEvent<HTMLInputElement>) => {
		const files: File[] | null = Array.from(e.target.files || []);
		e.target.value = "";

		if (files.length) {
			// const { playgroundStore } = this.rootStore;
			const compressedFiles: File[] = await Promise.all(
				files.map(async (file: File) => {
					const compressedBlob = await imageCompression(file, IMAGE_COMPRESSION_CONFIG);
					const compressedFile = new File([compressedBlob], file.name, { type: compressedBlob.type });
					return compressedFile;
				}),
			);
			const imagesBeforeUpload: PlaygroundResult = {
				id: uuidv4(),
				config: { ...this.config },
				type: "upload",
				images: compressedFiles.map((file) => ({
					id: uuidv4(),
					url: URL.createObjectURL(file),
					file: file,
					type: "upload",
					offline_props: {
						prefix_id: file.name.split(".")[0],
						variation: 0,
					},
				})),
			};
			await this.nflRmbgBeforeStyle(imagesBeforeUpload.images);
			runInAction(() => {
				// playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, imagesBeforeUpload];
				this.rootStore.playgroundStore.selectImages(imagesBeforeUpload.images);
			});
		}
	};

	addAIEditorImageInARow = async (imageUrl: string) => {
		const response = await fetch(imageUrl);
		const blob = await response.blob();
		const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
		const { playgroundStore } = this.rootStore;

		const analyticsUploadedImageUrl = new URL(imageUrl);
		const analyticsUploadedImageId = analyticsUploadedImageUrl.searchParams.get("imageId");
		const imagesBeforeUpload: PlaygroundResult = {
			id: uuidv4(),
			config: { ...this.config },
			type: "save",
			images: [
				{
					id: uuidv4(),
					url: imageUrl,
					file: new File([blob], filename, { type: blob.type }),
					type: "imageToImage",
					config: { ...this.config },
					sessionId:
						imageUrl.split("?")[0] +
						(analyticsUploadedImageId ? `?imageId=${analyticsUploadedImageId}` : ""),
				},
			],
		};

		if (analyticsUploadedImageId) {
			this.rootStore.analyticsStore.logImageToImageEvent(
				ANALYTICS_EVENTS.PLAYGROUND_IMAGE_SAVE_ITI,
				imagesBeforeUpload.images[0],
			);
		} else {
			const existingItiImage = playgroundStore.getAvailableImages().find((image) => imageUrl === image?.url);
			if (existingItiImage) {
				this.rootStore.analyticsStore.logImageToImageEvent(
					ANALYTICS_EVENTS.PLAYGROUND_IMAGE_SAVE_ITI,
					existingItiImage,
				);
			}
		}

		playgroundStore.playgroundResults = [...playgroundStore.playgroundResults, imagesBeforeUpload];
	};
}
