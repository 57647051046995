function AlignBottom({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.33301 16.6668H16.6663M9.16634 3.3335H10.833C11.7535 3.3335 12.4997 4.07969 12.4997 5.00016V11.6668C12.4997 12.5873 11.7535 13.3335 10.833 13.3335H9.16634C8.24587 13.3335 7.49967 12.5873 7.49967 11.6668V5.00016C7.49967 4.07969 8.24587 3.3335 9.16634 3.3335Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AlignBottom;
