import { Box, Typography } from "@mui/material";
import React, { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../hooks/useStores";
import BriaButton from "../../common/BriaButton/BriaButton";
import styles from "./TypeItem.module.scss";
import clsx from "clsx";

interface TypeItemProps {
	id?: string;
	handleClick: MouseEventHandler<any>;
	title: string;
	description: string;
	imgSrc: string;
	imgAlt: string;
}

const TypeItem: React.FC<TypeItemProps> = ({ id, handleClick, title, description, imgSrc, imgAlt }) => {
	const { uiStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });

	return (
		<Box className={styles.typeContainer}>
			<Typography className={styles.typeName}>{title}</Typography>
			<Typography className={styles.typeDescription}>{description}</Typography>
			<Box className={styles.imgContainer}>
				<img src={imgSrc} alt={imgAlt} />
			</Box>
			<BriaButton buttonType="primary" className={styles.button} onClick={handleClick}>
				{t("models.select")}
			</BriaButton>
			<BriaButton
				buttonType="textMedium"
				className={clsx(styles.button,styles.reviewLink)}
				onClick={() => {
					uiStore.showDialog("GuidelinesForTailoredModelsDialog");
					if (id) {
						localStorage.setItem("guidelineActiveTap", id);
						setTimeout(() => {
							const element = document.getElementById(id);
							if (element) {
								element.scrollIntoView({ behavior: "smooth" });
							}
						}, 10);
					}
				}}
			>
				{t("models.bestPractices")}
			</BriaButton>
		</Box>
	);
};

export default TypeItem;
