function TextSize({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2.50049 6.33317V4.6665H13.3338V6.33317M8.33382 4.6665V16.3332M10.0005 16.3332H6.66715M12.5005 11.3332V10.4998H17.5005V11.3332M15.0005 10.4998V16.3332M14.1672 16.3332H15.8338"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default TextSize;
