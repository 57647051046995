import { Block } from "baseui/block";
import { Button, SIZE } from "baseui/button";
import { nanoid } from "nanoid";
import React from "react";
import DropZone from "../../../../../components/Dropzone";
import AngleDoubleLeft from "../../../../../components/Icons/AngleDoubleLeft";
import Scrollable from "../../../../../components/Scrollable";
import { useEditor } from "../../../../../hooks/useEditor";
import useSetIsSidebarOpen from "../../../../../hooks/useSetIsSidebarOpen";
import { captureFrame, loadVideoResource } from "../../../../../utils/video";

const toBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export default function () {
	const inputFileRef = React.useRef<HTMLInputElement>(null);
	const [uploads, setUploads] = React.useState<any[]>([]);
	const editor = useEditor();
	const setIsSidebarOpen = useSetIsSidebarOpen();

	const handleDropFiles = async (files: FileList) => {
		const file = files[0];

		const base64 = (await toBase64(file)) as string;
		const video = await loadVideoResource(base64);
		const frame = await captureFrame(video);

		const type = file.type.includes("video") ? "StaticVideo" : "StaticImage";

		const upload = {
			id: nanoid(),
			src: base64,
			preview: frame,
			type: type,
		};

		setUploads([...uploads, upload]);
	};

	const handleInputFileRefClick = () => {
		inputFileRef.current?.click();
	};

	const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleDropFiles(e.target.files!);
	};

	const addImageToCanvas = (props: any) => {
		editor?.objects.add(props);
	};
	return (
		<DropZone handleDropFiles={handleDropFiles}>
			<Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
				<Block
					$style={{
						display: "flex",
						alignItems: "center",
						fontWeight: 500,
						justifyContent: "space-between",
						padding: "1.5rem",
					}}
				>
					<Block>Uploads</Block>

					<Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
						<AngleDoubleLeft size={18} />
					</Block>
				</Block>
				<Scrollable>
					<Block padding={"0 1.5rem"}>
						<Button
							onClick={handleInputFileRefClick}
							size={SIZE.compact}
							overrides={{
								Root: {
									style: {
										width: "100%",
									},
								},
							}}
						>
							Computer
						</Button>
						<input
							onChange={handleFileInput}
							type="file"
							id="file"
							ref={inputFileRef}
							style={{ display: "none" }}
						/>

						<div
							style={{
								marginTop: "1rem",
								display: "grid",
								gap: "0.5rem",
								gridTemplateColumns: "1fr 1fr",
							}}
						>
							{uploads.map((upload) => (
								<div
									key={upload.id}
									style={{
										display: "flex",
										alignItems: "center",
										cursor: "pointer",
									}}
									onClick={() => addImageToCanvas(upload)}
								>
									<div>
										<img
											width="100%"
											src={upload.preview ? upload.preview : upload.url}
											alt="preview"
										/>
									</div>
								</div>
							))}
						</div>
					</Block>
				</Scrollable>
			</Block>
		</DropZone>
	);
}
