import { Box, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import BriaDropDown, { DropDownItem } from "../../../../components/common/BriaDropDown/BriaDropDown";
import ColorPicker from "../../../../components/common/ColorPicker/ColorPicker";
import CustomSingleSelect from "../../../../components/common/CustomSingleSelectDropdown/ٍSingleSelectGroupedDropDown";
import ImageUploader from "../../../../components/common/ImageUploader/ImageUploader";
import { BRIA_ORG_ID } from "../../../../constants/OrgConstants";
import RouterConstants from "../../../../constants/RouterConstants";
import { useBrand } from "../../../../hooks/useBrand";
import { useAppStore } from "../../../../hooks/useStores";
import EditorLayout from "../../../../layout/EditorLayout/EditorLayout";
import InputLayout from "../../../../layout/InputLayout/InputLayout";
import { Brand, defaultBrand } from "../../../../models/brand";
import { Font, fontSizesList } from "../../../../models/font";
import { convertS3UriToHttpsUrl } from "../utils";
import styles from "./BrandEditor.module.scss";

export type EditBrandLocationState = {
	brandToEdit?: Brand;
};
const BrandEditor = () => {
	const location = useLocation();
	const { brandsStore, uiStore, fontsStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.brandEditor" });
	const params = useParams();
	const [brandForm, setBrandForm] = useState<BrandFormType>(location.state?.brandToEdit ?? defaultBrand);
	const handleFormChange = <K extends keyof BrandFormType>(key: K, value: BrandFormType[K]) => {
		setBrandForm((prevBrand) => ({ ...prevBrand, [key]: value }));
	};
	const [logo, setLogo] = useState<any>({
		logoUrl: brandForm?.logo?.src ? convertS3UriToHttpsUrl(brandForm?.logo?.src) : "",
		logoFile: "",
	});
	const [colors, setColors] = useState(["", "", "", "", ""]);
	const [fontsOptions, setFontsOptions] = useState<any[]>([]);
	const [savedBrandActionClicked, setSavedBrandActionClicked] = useState<boolean>(false);
	const newArray: DropDownItem[] = fontSizesList.map((value: string, _index: number) => ({
		key: String(value),
		value: value,
	}));
	const sizeOptions = newArray;
	const {
		handleColorChange,
		deleteLogo,
		handleUpload,
		handlePrimaryColorChange,
		handleSecondaryColorChange,
		onNameChange,
		onPrimaryFontChange,
		onSecondaryFontChange,
		onSecondarySizeChange,
		onPrimarySizeChange,
		saveBrand,
	} = useBrand({
		colors,
		setColors,
		brandForm,
		handleFormChange,
		setSavedBrandActionClicked,
		setLogo,
		logo,
	});

	useEffect(() => {
		uiStore.hideSideBar();
		uiStore.showBackButton(RouterConstants.BRANDS.fullPath);

		brandsStore.formErrors = {};
		if (!location.state?.brandToEdit && params.id && !isNaN(Number(params.id))) {
			brandsStore.getBrand(Number(params.id)).then(setBrandForm);
		}

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
			window.history.replaceState({}, "");
		};
	}, [params.id]);

	useEffect(() => {
		fontsStore.getAllOrgFonts(true).then(() => {
			const fontOptions = fontsStore?.orgFonts?.map((font: Font) => ({
				key: font.name,
				value: font.id,
				src: font.src,
				org_id: font.org_id,
			}));
			const groupedFonts = fontOptions.reduce((acc: any, font: any) => {
				const orgId = font?.org_id;
				if (!acc[orgId]) {
					acc[orgId] = [];
				}
				acc[orgId].push({
					key: font.key,
					label: font.key,
					value: font.value,
					src: font.src,
					org_id: font.org_id,
				});
				return acc;
			}, {});
			// Step 1: Get private fonts
			const privateFonts = Object.entries(groupedFonts)
				.filter(([orgId, _options]) => orgId !== BRIA_ORG_ID)
				.map(([_orgId, options]) => ({
					name: "Private Fonts",
					options,
				}));

			// Step 2: Get public fonts
			const publicFonts = Object.entries(groupedFonts)
				.filter(([orgId, _options]) => orgId === BRIA_ORG_ID)
				.map(([_orgId, options]) => ({
					name: "Public Fonts",
					options,
				}));

			// Combine private and public fonts
			const orgFonts = [...privateFonts, ...publicFonts];
			setFontsOptions(orgFonts);
		});
	}, []);

	useEffect(() => {
		const selectedBrandColorPallet = brandForm?.color_pallete?.colors;
		const selectedBrandColors = selectedBrandColorPallet?.slice(0, 5);
		selectedBrandColors?.forEach((color: string, index: number) => {
			colors[index] = color;
		});
		setLogo({ logoUrl: brandForm?.logo?.src ? convertS3UriToHttpsUrl(brandForm?.logo?.src) : "", logoFile: "" });
		handleFormChange("color_pallete_id", brandForm?.color_pallete?.id);
		handleFormChange("logo_id", brandForm?.logo?.id);
	}, []);

	return (
		<EditorLayout
			name={brandForm.name}
			onNameChange={onNameChange}
			errorName={brandsStore.formErrors.invalidName}
			primaryButton={t("saveBtn")}
			primaryButtonClick={saveBrand}
			disablePrimary={savedBrandActionClicked}
		>
			<Box className={styles.brandPresetForm}>
				<InputLayout label={t("logo")}>
					<ImageUploader
						src={logo?.logoUrl}
						description={t("uploadFile")}
						inputProps={{ accept: "image/png" }}
						onUpload={handleUpload}
						onDelete={deleteLogo}
					/>
				</InputLayout>
				<InputLayout className={styles.inputText} label={t("branddescription.label")}>
					<TextField
						value={brandForm?.description}
						onChange={(e) => handleFormChange("description", e.target.value)}
						placeholder={t("branddescription.placeholder")}
						multiline
						minRows={3}
						InputProps={{
							classes: {
								root: styles.bigTextField,
							},
						}}
					/>
				</InputLayout>

				<Typography className={styles.brandNameLabel}>{t("brandColors")}</Typography>
				<Box className={styles.colorsLables}>
					{colors.map((_color: string, index: number) => (
						<Box>
							{index < 2 && (
								<Typography className={styles.colorHeader}>
									{index === 0 && <>{t("Primary")}</>}
									{index === 1 && <>{t("Secondary")}</>}
								</Typography>
							)}
						</Box>
					))}
				</Box>

				<Box className={styles.colorOptions}>
					{colors.map((color: string, index: number) => (
						<Box key={index}>
							<ColorPicker
								circleWidth="40px"
								color={color}
								onColorChange={(color: string, remove?: boolean) =>
									handleColorChange(color, index, remove)
								}
							/>
						</Box>
					))}
				</Box>
				<InputLayout label="Primary Message" className={styles.brandNameLabel}>
					<Box className={styles.messageBoxStyle}>
						<CustomSingleSelect
							selectedValue={brandForm.primary_font_id}
							handleChange={onPrimaryFontChange}
							groups={fontsOptions || []}
							className="custom-single-select"
							paddingClass={styles.rootFirstSelect}
							placeholder="Font"
							selectStyle={styles.selectStyle}
							menuMaxHeight="90vh"
							fontOptions={true}
							loading={fontsStore.isLoading}
						/>
						<BriaDropDown
							value={brandForm?.primary_message?.size + ""}
							onChange={onPrimarySizeChange}
							placeholder="Size"
							items={sizeOptions}
							className={styles.dropDown}
						/>
						<Box>
							<ColorPicker
								circleWidth="40px"
								color={brandForm?.primary_message?.color}
								onColorChange={(color: string, remove?: boolean) =>
									handlePrimaryColorChange(color, remove)
								}
							/>
						</Box>
					</Box>
				</InputLayout>
				<InputLayout label="Secondary Message" className={styles.brandNameLabel}>
					<Box className={styles.messageBoxStyle}>
						<CustomSingleSelect
							selectedValue={brandForm.secondary_font_id}
							handleChange={onSecondaryFontChange}
							groups={fontsOptions || []}
							className="custom-single-select"
							paddingClass={styles.rootFirstSelect}
							placeholder="Font"
							selectStyle={styles.selectStyle}
							menuMaxHeight="90vh"
							fontOptions={true}
							loading={fontsStore.isLoading}
						/>
						<BriaDropDown
							value={brandForm?.secondary_message?.size + ""}
							onChange={(e) => onSecondarySizeChange(e)}
							placeholder="Size"
							items={sizeOptions}
							className={styles.dropDown}
						/>
						<Box>
							<ColorPicker
								circleWidth="40px"
								color={brandForm?.secondary_message?.color}
								onColorChange={(color: string, remove?: boolean) =>
									handleSecondaryColorChange(color, remove)
								}
							/>
						</Box>
					</Box>
				</InputLayout>
			</Box>
		</EditorLayout>
	);
};

export default observer(BrandEditor);
export type BrandFormType = Brand;
