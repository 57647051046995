import { BriaAxios } from "../config/axios.tsx";
import { ITailoredGenerationModel } from "../models/tailoredGeneration.ts";

const BASE_URL = "/tailored_generation";

class TailoredGenerationService {
	get = async (url: string) => {
		const response = await (await BriaAxios()).get(`${BASE_URL}/${url}`);
		return response.data;
	};
	post = async (url: string, data: any, headers: Record<string, string> = {}, options = {}) => {
		const response = await (await BriaAxios(headers)).post(`${BASE_URL}/${url}`, data, options);
		return response.data;
	};

	put = async (url: string, model: ITailoredGenerationModel) => {
		const response = await (await BriaAxios()).put(`${BASE_URL}/${url}`, model);
		return response.data;
	};

	delete = async (url: string) => {
		const response = await (await BriaAxios()).delete(`${BASE_URL}/${url}`);
		return response.data;
	};
}

export default TailoredGenerationService;
