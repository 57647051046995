import { BriaAxios } from "../config/axios.tsx";

const BASE_URL = "/models";

class ModelsService {
	verifyAgentInstallation = async () => {
		const response = await (await BriaAxios()).post(`${BASE_URL}/agent/`);
		return response.data;
	};
}

export default ModelsService;
