function AlignRight({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.6663 3.3335V16.6668M4.99967 7.50016H11.6663C12.5868 7.50016 13.333 8.24635 13.333 9.16683V10.8335C13.333 11.754 12.5868 12.5002 11.6663 12.5002H4.99967C4.0792 12.5002 3.33301 11.754 3.33301 10.8335V9.16683C3.33301 8.24635 4.0792 7.50016 4.99967 7.50016Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AlignRight;
