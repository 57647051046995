import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../../../components/common/BriaTable/BriaTable";
import useErrorToast from "../../../hooks/useErrorToast";
import { Channel } from "../../../models/channel";
import { PaginatedItems } from "../../../models/common";
import QueryService from "../../../utils/QueryService";

export interface IChannelsStore {
	paginatedChannels: PaginatedItems<Channel>;
	rowsPerPage: number;
	isLoading: boolean;
	isLoadingEditor: boolean;
	isError: boolean;
	getChannel(channelId: number): Promise<Channel>;
	loadChannels(pageNumber: number, includePublic?: boolean, filterBy?: FilterByOptions): Promise<void>;
	createChannel(newChannel: Channel): Promise<number>;
	updateChannel(updatedChannel: Channel): Promise<void>;
}

export default class ChannelsStore implements IChannelsStore {
	private queryService: QueryService = new QueryService("/channels");
	errorToast = useErrorToast();
	paginatedChannels: PaginatedItems<Channel> = { total: 0, items: [] };
	rowsPerPage: number = 20;
	isLoading: boolean = false;
	isLoadingEditor: boolean = false;
	isError: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	loadChannels = async (
		pageNumber: number,
		includePublic: boolean = false,
		filterBy?: FilterByOptions,
	): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			const channels: PaginatedItems<Channel> = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					page: pageNumber,
					per_page: this.rowsPerPage,
					include_public: includePublic,
				},
			});

			runInAction(() => {
				this.paginatedChannels = channels;
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getChannel = async (channelId: number): Promise<Channel> => {
		try {
			this.isLoadingEditor = true;
			const channel: Channel = await this.queryService.get(`/${channelId}`);
			runInAction(() => {
				this.isLoadingEditor = false;
			});
			return channel;
		} catch (e) {
			runInAction(() => {
				this.isLoadingEditor = false;
				this.isError = true;
			});

			return Promise.reject(e);
		}
	};

	createChannel = async (newChannel: Channel): Promise<number> => {
		this.isError = false;
		try {
			const channelId = await this.queryService.post("/", newChannel);
			runInAction(() => {
				this.isError = false;
			});
			return channelId;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(`Error creating new channel: ${e.message || e.toString()}`);
		}
	};

	updateChannel = async (updatedChannel: Channel): Promise<void> => {
		this.isError = false;
		try {
			await this.queryService.put("/", updatedChannel);
			runInAction(() => {
				this.paginatedChannels.items = this.paginatedChannels?.items.map((channel) =>
					channel.id === updatedChannel.id ? updatedChannel : channel,
				);
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
		}
	};
}
