import { Popover } from "@mui/material";
import clsx from "clsx";
import ObjectBottomCenterIcon from "../../../assets/images/icons/Alignment/Object/ObjectBottomCenterIcon";
import ObjectBottomLeftIcon from "../../../assets/images/icons/Alignment/Object/ObjectBottomLeftIcon";
import ObjectBottomRightIcon from "../../../assets/images/icons/Alignment/Object/ObjectBottomRightIcon";
import ObjectCenterIcon from "../../../assets/images/icons/Alignment/Object/ObjectCenterIcon";
import ObjectCenterLeftIcon from "../../../assets/images/icons/Alignment/Object/ObjectCenterLeftIcon";
import ObjectCenterRightIcon from "../../../assets/images/icons/Alignment/Object/ObjectCenterRightIcon";
import ObjectTopIcon from "../../../assets/images/icons/Alignment/Object/ObjectTopIcon";
import ObjectTopLeftIcon from "../../../assets/images/icons/Alignment/Object/ObjectTopLeftIcon";
import ObjectTopRightIcon from "../../../assets/images/icons/Alignment/Object/ObjectTopRightIcon";
import { HorizontalEnum, VerticalEnum } from "../../../models/common";
import { Alignment } from "../../../models/layout";
import styles from "./ObjectAlignmentPopover.module.scss";
type Props = {
	open: boolean;
	value?: Alignment;
	handleIconClick?: (alignment: Alignment) => void;
	handleClose?: () => void;
	anchorEl?: SVGSVGElement;
};

const ObjectAlignmentPopover = ({ open = false, value, handleIconClick, handleClose, anchorEl }: Props) => {
	const handleClick = (vertical: VerticalEnum, horizontal: HorizontalEnum) => {
		handleIconClick?.({ vertical, horizontal });
	};

	return (
		<Popover
			open={open}
			onClose={handleClose}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			slotProps={{
				paper: {
					className: styles.popover,
				},
			}}
		>
			<ObjectTopLeftIcon
				className={clsx({
					[styles.selected]:
						value?.vertical === VerticalEnum.TOP && value?.horizontal === HorizontalEnum.LEFT,
				})}
				onClick={() => handleClick(VerticalEnum.TOP, HorizontalEnum.LEFT)}
			/>
			<ObjectTopIcon
				className={clsx({
					[styles.selected]:
						value?.vertical === VerticalEnum.TOP && value?.horizontal === HorizontalEnum.CENTER,
				})}
				onClick={() => handleClick(VerticalEnum.TOP, HorizontalEnum.CENTER)}
			/>
			<ObjectTopRightIcon
				className={clsx({
					[styles.selected]:
						value?.vertical === VerticalEnum.TOP && value?.horizontal === HorizontalEnum.RIGHT,
				})}
				onClick={() => handleClick(VerticalEnum.TOP, HorizontalEnum.RIGHT)}
			/>
			<ObjectCenterLeftIcon
				className={clsx({
					[styles.selected]:
						value?.vertical === VerticalEnum.CENTER && value?.horizontal === HorizontalEnum.LEFT,
				})}
				onClick={() => handleClick(VerticalEnum.CENTER, HorizontalEnum.LEFT)}
			/>
			<ObjectCenterIcon
				className={clsx({
					[styles.selected]:
						value?.vertical === VerticalEnum.CENTER && value?.horizontal === HorizontalEnum.CENTER,
				})}
				onClick={() => handleClick(VerticalEnum.CENTER, HorizontalEnum.CENTER)}
			/>
			<ObjectCenterRightIcon
				className={clsx({
					[styles.selected]:
						value?.vertical === VerticalEnum.CENTER && value?.horizontal === HorizontalEnum.RIGHT,
				})}
				onClick={() => handleClick(VerticalEnum.CENTER, HorizontalEnum.RIGHT)}
			/>
			<ObjectBottomLeftIcon
				className={clsx({
					[styles.selected]:
						value?.vertical === VerticalEnum.BOTTOM && value?.horizontal === HorizontalEnum.LEFT,
				})}
				onClick={() => handleClick(VerticalEnum.BOTTOM, HorizontalEnum.LEFT)}
			/>
			<ObjectBottomCenterIcon
				className={clsx({
					[styles.selected]:
						value?.vertical === VerticalEnum.BOTTOM && value?.horizontal === HorizontalEnum.CENTER,
				})}
				onClick={() => handleClick(VerticalEnum.BOTTOM, HorizontalEnum.CENTER)}
			/>
			<ObjectBottomRightIcon
				className={clsx({
					[styles.selected]:
						value?.vertical === VerticalEnum.BOTTOM && value?.horizontal === HorizontalEnum.RIGHT,
				})}
				onClick={() => handleClick(VerticalEnum.BOTTOM, HorizontalEnum.RIGHT)}
			/>
		</Popover>
	);
};

export default ObjectAlignmentPopover;
