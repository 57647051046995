import { Box, Grid, Typography } from "@mui/material";
import styles from "./HubCardsComponent.module.scss";

interface IProps {
	title: string;
	description: string;
	image: string;
	onClick: () => void;
	onImageLoad?: () => void;
}

const HubCardsComponent = ({ title, description, image, onImageLoad, onClick }: IProps) => {
	return (
		<Grid className={styles.cardContainer} onClick={onClick}>
			<Box className={styles.imgContainter}>
				<img className={styles.image} src={image} alt="" onLoad={onImageLoad} />
			</Box>
			<Box className={styles.textAndButtonContainer}>
				<Typography className={styles.title}>{title} </Typography>
				<Typography className={styles.description}>{description}</Typography>
			</Box>
		</Grid>
	);
};

export default HubCardsComponent;
