import { Box } from "@mui/material";
import clsx from "clsx";
import TextBottomIcon from "../../../assets/images/icons/Alignment/Text/Vertical/TextBottomIcon";
import TextVerticalCenterIcon from "../../../assets/images/icons/Alignment/Text/Vertical/TextCenterIcon";
import TextTopIcon from "../../../assets/images/icons/Alignment/Text/Vertical/TextTopIcon";
import { VerticalEnum } from "../../../models/common";
import styles from "./FontPopover.module.scss";

type Props = {
	value?: VerticalEnum;
	handleChange?: (alignment: VerticalEnum) => void;
	disable?: boolean;
};

const TextVerticalButtons = ({ value, handleChange, disable }: Props) => {
	return (
		<Box className={styles.iconsGroup}>
			<TextTopIcon
				className={clsx({
					[styles.selected]: value === VerticalEnum.TOP,
					[styles.disabled]: disable,
				})}
				onClick={() => handleChange?.(VerticalEnum.TOP)}
			/>
			<TextVerticalCenterIcon
				className={clsx({
					[styles.selected]: value === VerticalEnum.CENTER,
					[styles.disabled]: disable,
				})}
				onClick={() => handleChange?.(VerticalEnum.CENTER)}
			/>
			<TextBottomIcon
				className={clsx({
					[styles.selected]: value === VerticalEnum.BOTTOM,
					[styles.disabled]: disable,
				})}
				onClick={() => handleChange?.(VerticalEnum.BOTTOM)}
			/>
		</Box>
	);
};

export default TextVerticalButtons;
