import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";

import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TGBackground from "../../../assets/images/svgs/TGBackground.svg";
import RouterConstants from "../../../constants/RouterConstants.ts";
import useOrgNavigation from "../../../hooks/useOrgNavigation.tsx";
import Background from "../../common/Background/Background.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import styles from "./TailoredGenerationWidget.module.scss";

const TailoredGenerationWidget = () => {
	const { t } = useTranslation("translation", { keyPrefix: "home.tailoredGenerationWidget" });
	const { orgNavigationHandler } = useOrgNavigation();

	return (
		<Box className={styles.container}>
			<Box className={styles.content}>
				<Typography className={styles.title}>{t("title")}</Typography>
				<Typography className={styles.description}>{t("description")}</Typography>
				<BriaButton
					onClick={() =>
						orgNavigationHandler(RouterConstants.TG_MODELS.fullPath)
					}
					className={styles.upgradeButton}
					buttonType="secondaryMedium"
				>
					{t("createATailoredModel")}
				</BriaButton>
			</Box>
			<Background
				background={TGBackground}
				width="100%"
				height="290px"
				borderRadius="0px"
				backgroundRepeat="no-repeat"
				backgroundPosition="center"
			/>
		</Box>
	);
};

const ObservedComponent = observer(TailoredGenerationWidget);
export default ObservedComponent;
