import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { useContext } from "react";
import BriaIconButton from "../../../../components/common/BriaIconButton/BriaIconButton.tsx";
import Close from "../../../assets/svgs/Close.svg";
import Search from "../../../assets/svgs/search.svg";
import { DesignEditorContext } from "../../../contexts/DesignEditor.tsx";
import useSetIsSidebarOpen from "../../../hooks/useSetIsSidebarOpen.tsx";
import styles from "./MainTabHeader.module.scss";

export type Iprops = {
	setSearchBoxVisible?: (value: boolean) => void;
	searchBoxVisible?: boolean;
	title: string;
	enableSearch?: boolean;
};

const MainTabHeader = ({ setSearchBoxVisible, searchBoxVisible, title, enableSearch = false }: Iprops) => {
	const { isPopupView } = useContext(DesignEditorContext);
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const { t } = useTranslation("translation", { keyPrefix: "editor.images" });

	return (
		<Box className={styles.mainTabContainer}>
			<Box className={styles.mainTabHeader}>
				<Typography className={styles.title}>{title}</Typography>
				<Box className={styles.iconsContainer}>
					{enableSearch && (
						<BriaIconButton
							onClick={() => {
								setSearchBoxVisible && setSearchBoxVisible(!searchBoxVisible);
							}}
						>
							<img className={styles.searchImg} src={Search} alt={t("alt.searchIcon")} />
						</BriaIconButton>
					)}
					{!isPopupView && (
						<BriaIconButton className={styles.actionBtn} onClick={() => setIsSidebarOpen(false)}>
							<img src={Close} alt={t("alt.closeIcon")} />
						</BriaIconButton>
					)}
				</Box>
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(MainTabHeader);
export default ObservedComponent;
