function Copy({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.3335 6.66683V5.00016C13.3335 4.55814 13.1579 4.13421 12.8453 3.82165C12.5328 3.50909 12.1089 3.3335 11.6668 3.3335H5.00016C4.55814 3.3335 4.13421 3.50909 3.82165 3.82165C3.50909 4.13421 3.3335 4.55814 3.3335 5.00016V11.6668C3.3335 12.1089 3.50909 12.5328 3.82165 12.8453C4.13421 13.1579 4.55814 13.3335 5.00016 13.3335H6.66683M8.3335 6.66683H15.0002C15.9206 6.66683 16.6668 7.41302 16.6668 8.3335V15.0002C16.6668 15.9206 15.9206 16.6668 15.0002 16.6668H8.3335C7.41302 16.6668 6.66683 15.9206 6.66683 15.0002V8.3335C6.66683 7.41302 7.41302 6.66683 8.3335 6.66683Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Copy;
