import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function ObjectBottomLeftIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 18 18" {...props}>
			<path
				d="M17 15.222V2.778C17 1.796 16.204 1 15.222 1H2.778C1.796 1 1 1.796 1 2.778V15.222C1 16.204 1.796 17 2.778 17H15.222C16.204 17 17 16.204 17 15.222Z"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				x="3"
				y="12"
				width="6"
				height="3"
				fill="none"
				stroke="#5B5B5B"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default ObjectBottomLeftIcon;
