import { useState } from "react";
import { showErrorToast } from "../utils/toast";

interface IParams {
	toastId?: string;
}

const useErrorToast = (params: IParams = {}) => {
	const [toastId, setToastId] = useState<string | undefined>(params.toastId);

	function showError(errorMessage?: string) {
		const taostId = showErrorToast(errorMessage, toastId);
		setToastId(taostId);
	}

	return {
		showError,
		toastId,
	};
};

export default useErrorToast;
