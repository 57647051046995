import { useState, useEffect, useRef } from "react";

const useHoverOverlay = () => {
	const [isHovered, setIsHovered] = useState(false);
	const ref = useRef<HTMLElement | null>(null);

	useEffect(() => {
		const handleMouseEnter = () => setIsHovered(true);
		const handleMouseLeave = () => setIsHovered(false);
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				setIsHovered(false);
			}
		};

		const node = ref.current;
		if (node) {
			node.addEventListener("mouseenter", handleMouseEnter);
			node.addEventListener("mouseleave", handleMouseLeave);
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			if (node) {
				node.removeEventListener("mouseenter", handleMouseEnter);
				node.removeEventListener("mouseleave", handleMouseLeave);
			}
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref]);

	return [ref, isHovered, setIsHovered] as const;
};

export default useHoverOverlay;
