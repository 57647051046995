import { Box } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { EditIcon } from "../../../assets/images/icons/EditIcon";
import styles from "./ColorPicker.module.scss";

interface ColorPickerProps {
	color?: string;
	onColorChange?: (color: string, remove?: boolean) => void;
	disabled?: boolean;
	colorInputClass?: string;
	circleWidth?: number | string;
}

export default function ColorPicker({ color, onColorChange, disabled, colorInputClass }: ColorPickerProps) {
	const [localColor, setLocalColor] = useState(color || "");

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const {
			target: { value: color },
		} = e;
		onColorChange?.(color);
		setLocalColor(color);
	};

	const handleDelete = () => {
		onColorChange?.("", true);
		setLocalColor("");
	};

	useEffect(() => {
		if (color && CSS.supports("color", color)) {
			setLocalColor(color);
		} else {
			setLocalColor("");
		}
	}, [color]);

	return (
		<Box className={styles.container}>
			<Box className={`${styles.roundedWrapper} ${colorInputClass}`}>
				<input
					type="color"
					value={localColor}
					className={disabled ? styles.disabledColorInput : styles.colorInput}
					onChange={handleChange}
					disabled={disabled}
				/>
				{localColor ? (
					<Box bgcolor={localColor} className={`${styles.roundedWrapper} ${styles.filledCircleBorder}`}>
						{!disabled && (
							<>
								<EditIcon className={`${styles.editIcon} ${styles.hover}`} />
								<Box onClick={handleDelete} className={`${styles.deleteIcon} ${styles.hover}`}>
									+
								</Box>
							</>
						)}
					</Box>
				) : (
					<Box className={`${styles.roundedWrapper} ${styles.placeholder}`}>
						{!disabled && <span>+</span>}
					</Box>
				)}
			</Box>
		</Box>
	);
}
