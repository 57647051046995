import { Box, ButtonProps } from "@mui/material";
import React from "react";
import Beta from "../Beta/Beta";
import styles from "./MenuCell.module.scss";

interface IProps {
	text: string;
	selected: boolean;
	icon?: any;
	isBeta?: boolean;
}

export default function MenuCell({ text, selected, icon, isBeta, ...rest }: IProps & ButtonProps) {
	const seclectedButtonStyle = selected ? styles.selectedStyle : "none";
	const selectedContainerStyle = selected ? styles.selectedContainerStyle : "none";

	return (
		<button {...rest} className={`${styles.container} ${selectedContainerStyle}`}>
			<Box className={`${styles.cellButton} ${seclectedButtonStyle}`}>
				{icon && (
					<div className={styles.iconStyle}>
						{React.createElement(icon, { stroke: selected ? "#5300C9" : "#5B5B5B" })}
					</div>
				)}
				{isBeta ? <Beta>{text}</Beta> : text}
			</Box>
		</button>
	);
}
