import { Box, Container, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HubgalleryImage, galleryExampleImages } from "../../../constants/HubGalleryImagesConstants";
import RouterConstants from "../../../constants/RouterConstants";
import useWindowSize from "../../../hooks/useWindowSize";
import BriaImage from "../Galleries/BriaImage";
import styles from "./GalleryHub.module.scss";

interface IProps {
	buildStaticGallery?: boolean; // Indicates whether the platform Hub page builds in a static way, using galleryExampleImagesColumns to specify the exact number of images in each column, particularly for 4 columns.
	galleryExampleImagesColumns?: HubgalleryImage[][];
	galleryImages: HubgalleryImage[];
}
const GalleryHub = ({ buildStaticGallery = false, galleryExampleImagesColumns, galleryImages }: IProps) => {
	const [numberOfColumns, setNumberOfColumns] = useState(0);
	const imageContainerRef = useRef<HTMLDivElement>(null);
	const windowWidth = useWindowSize().width;
	const [imagesGallery, setImageGallery] = useState<any>([]);
	const { t } = useTranslation("translation", { keyPrefix: "galleryHub" });
	const navigate = useNavigate();

	useEffect(() => {
		if (imageContainerRef.current && imageContainerRef.current!.clientWidth) {
			let tempColumns = Math.max(Math.floor((imageContainerRef.current!.clientWidth - 100) / 296), 1);
			setNumberOfColumns(tempColumns);
		}
	}, [imageContainerRef, imageContainerRef.current, windowWidth]);

	useEffect(() => {
		if (imageContainerRef && imageContainerRef.current) {
			galleryExampleImages?.length < numberOfColumns
				? imageContainerRef.current.classList.add("galleryNotFull")
				: imageContainerRef.current.classList.remove("galleryNotFull");
		}
	}, [imageContainerRef, imageContainerRef.current]);

	useEffect(() => {
		const imageGallary = new Array(numberOfColumns).fill(0);
		setImageGallery(imageGallary);
	}, [numberOfColumns]);

	return (
		<Grid item className={styles.GalleryGrid}>
			<Box className={styles.galleyImagesBox} ref={imageContainerRef}>
				<Container disableGutters maxWidth={false} className={styles.gallery}>
					{/* This block to handle to draw the same design we have on figma by specifying exactly the images and the number of images on each column */}
					{buildStaticGallery && numberOfColumns == 4
						? imagesGallery.map((_: HubgalleryImage, index: number) => (
								<Box
									className={styles.imageContainer}
									key={index}
									marginRight={index < imagesGallery.length - 1 ? 2.5 : 0}
								>
									{galleryExampleImagesColumns?.[index]?.map((image: any, index2: number) => (
										<Box className={styles.imageWrapper} key={index2}>
											<BriaImage
												{...image}
												url={image.image}
												handleClick={() =>
													navigate(RouterConstants.TEXT_TO_IMAGE.path, {
														state: { prompt: t(image?.prompt) },
													})
												}
												hideFullScreenButton
												lazyImageProps={{ ...image.lazyImageProps }}
												displayOverlay="customHover"
												image={image}
											/>
										</Box>
									))}
								</Box>
							))
						: //  {/* This is the main block to draw the gallery that has images with different aspect ratio */}

							imagesGallery.map((_: HubgalleryImage, index: number) => (
								<Box
									className={styles.imageContainer}
									key={index}
									marginRight={index < imagesGallery.length - 1 ? 2.5 : 0}
								>
									{galleryImages
										?.filter((_: HubgalleryImage, i: number) => i % numberOfColumns === index)
										.map((image: HubgalleryImage, index2: number) => (
											<Box className={styles.imageWrapper} key={index2}>
												<BriaImage
													{...image}
													url={image.image}
													handleClick={() =>
														navigate(RouterConstants.TEXT_TO_IMAGE.path, {
															state: { prompt: t(image?.prompt) },
														})
													}
													hideFullScreenButton
													lazyImageProps={{ ...image.lazyImageProps }}
													displayOverlay="customHover"
													image={image}
												/>
											</Box>
										))}
								</Box>
							))}
				</Container>
			</Box>
		</Grid>
	);
};

export default GalleryHub;
