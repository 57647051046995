function Download({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.3066 16.784V18.4311C18.3066 18.8679 18.1331 19.2868 17.8242 19.5957C17.5153 19.9046 17.0964 20.0781 16.6596 20.0781H6.77723C6.3404 20.0781 5.92147 19.9046 5.61258 19.5957C5.3037 19.2868 5.13017 18.8679 5.13017 18.4311V16.784M15.8361 11.8428L11.7184 15.9605M11.7184 15.9605L7.60076 11.8428M11.7184 15.9605V6.07812"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Download;
