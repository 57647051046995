import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function DeleteIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 18 20" {...props}>
			<path
				d="M0.999939 4.86914H16.9999M6.99994 8.86914V14.8691M10.9999 8.86914V14.8691M1.99994 4.86914L2.99994 16.8691C2.99994 17.3996 3.21065 17.9083 3.58573 18.2834C3.9608 18.6584 4.46951 18.8691 4.99994 18.8691H12.9999C13.5304 18.8691 14.0391 18.6584 14.4142 18.2834C14.7892 17.9083 14.9999 17.3996 14.9999 16.8691L15.9999 4.86914M5.99994 4.86914V1.86914C5.99994 1.60392 6.1053 1.34957 6.29283 1.16203C6.48037 0.974497 6.73472 0.869141 6.99994 0.869141H10.9999C11.2652 0.869141 11.5195 0.974497 11.707 1.16203C11.8946 1.34957 11.9999 1.60392 11.9999 1.86914V4.86914"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default DeleteIcon;
