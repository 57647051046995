function Eye({ size }: { size: number }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
			<path
				d="M20.8661 11.1359C20.2076 9.69587 17.6253 5 12.0001 5C6.37499 5 3.79267 9.69587 3.13416 11.1359C3.04576 11.3289 3 11.5387 3 11.7509C3 11.9632 3.04576 12.173 3.13416 12.3659C3.79267 13.8045 6.37499 18.5003 12.0001 18.5003C17.6253 18.5003 20.2076 13.8045 20.8661 12.3644C20.9543 12.1717 21 11.9622 21 11.7502C21 11.5382 20.9543 11.3287 20.8661 11.1359V11.1359ZM12.0001 17.0003C7.26976 17.0003 5.06246 12.9757 4.49994 11.7584C5.06246 10.5246 7.26976 6.50004 12.0001 6.50004C16.7193 6.50004 18.9273 10.5074 19.5003 11.7502C18.9273 12.993 16.7193 17.0003 12.0001 17.0003Z"
				fill="#5B5B5B"
			/>
			<path
				d="M12.0001 8C11.2584 8 10.5334 8.21994 9.91666 8.63201C9.29996 9.04407 8.8193 9.62976 8.53546 10.315C8.25162 11.0002 8.17736 11.7543 8.32206 12.4817C8.46676 13.2092 8.82392 13.8774 9.34838 14.4018C9.87284 14.9263 10.541 15.2834 11.2685 15.4281C11.9959 15.5728 12.75 15.4986 13.4352 15.2147C14.1204 14.9309 14.7061 14.4502 15.1182 13.8335C15.5303 13.2168 15.7502 12.4918 15.7502 11.7501C15.749 10.7559 15.3535 9.80271 14.6505 9.09969C13.9475 8.39667 12.9943 8.00119 12.0001 8V8ZM12.0001 14.0002C11.5551 14.0002 11.1201 13.8682 10.75 13.621C10.38 13.3737 10.0916 13.0223 9.92132 12.6112C9.75101 12.2 9.70646 11.7476 9.79327 11.3111C9.88009 10.8747 10.0944 10.4737 10.4091 10.1591C10.7237 9.84439 11.1247 9.63009 11.5611 9.54327C11.9976 9.45645 12.45 9.50101 12.8612 9.67131C13.2723 9.84162 13.6237 10.13 13.871 10.5C14.1182 10.8701 14.2502 11.3051 14.2502 11.7501C14.2502 12.3468 14.0131 12.9192 13.5911 13.3411C13.1692 13.7631 12.5969 14.0002 12.0001 14.0002Z"
				fill="#5B5B5B"
			/>
		</svg>
	);
}

export default Eye;
