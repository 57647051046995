import JSZip from "jszip";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../hooks/useStores";
import useDesignEditorContext from "../hooks/useDesignEditorContext";
import { useEditor } from "../hooks/useEditor";
import { IDesign } from "../interfaces/DesignEditor";
import { Template } from "../models/template";
import { IFrame, IScene } from "../types";
import { loadTemplateFonts } from "../utils/fonts";
import { loadVideoEditorAssets } from "../utils/video";
import useScenesUtils from "./ScenesUtils";

type TemplateUtils = {
	loadGraphicTemplate: (payload: IDesign) => Promise<{
		scenes: IScene[];
		design: { id: string; name: string; frame: IFrame; preview: string; metadata: {} };
	}>;
	loadTemplate: (template: Template) => Promise<void>;
	handleDownloadTemplate: (template: any) => Promise<void>;
	exportTemplate: (l_scenes?: IScene[]) => Promise<IDesign | undefined>;
};

const UseTemplateUtils = (): TemplateUtils => {
	const editor = useEditor();
	const { campaignStore } = useAppStore();
	const { updateScenes } = useScenesUtils();
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.templates" });
	const { currentScene, setScenes, setCurrentDesign, scenes, currentDesign, setCurrentScene } =
		useDesignEditorContext();

	const loadGraphicTemplate = async (payload: IDesign) => {
		const scenes = [];
		const { scenes: scns, ...design } = payload;

		for (let index = 0; index < scns.length; index++) {
			const scn = scns[index];
			const scene: IScene = {
				name: scn.name,
				frame: {
					width: scn.layers[0].width,
					height: scn.layers[0].height,
				},
				id: scn.id,
				layers: scn.layers,
				metadata: {},
			};
			const loadedScene = await loadVideoEditorAssets(scene);
			await loadTemplateFonts(loadedScene);
			const preview = (await editor?.renderer.render(loadedScene)) as string;
			scenes.push({ ...loadedScene, preview });
		}

		return { scenes, design };
	};

	const handleImportTemplate = React.useCallback(
		async (data: any) => {
			const template = await loadGraphicTemplate(data);
			setScenes(template.scenes);
			setCurrentScene(template.scenes[0]);
			// @ts-ignore
			setCurrentDesign(template.design);
		},
		[editor],
	);

	function base64toBlob(base64Data: string, contentType: string) {
		const sliceSize = 1024;
		const base64WithoutPrefix = base64Data.split(",")[1];
		const byteCharacters = atob(base64WithoutPrefix);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, { type: contentType });
	}

	const handleDownloadTemplate = async (template: Template) => {
		let storedScenes;
		const zip = new JSZip();
		let i = 0;
		if (campaignStore.selectedTemplate && campaignStore?.selectedTemplate?.id == template.id) {
			const scenes = await updateScenes();
			storedScenes = scenes.map((scene) => ({
				...scene,
				preview: scene.preview ?? "",
			}));
		} else {
			const templateToImport = await loadGraphicTemplate(template.ads_json);
			storedScenes = templateToImport.scenes.map((scene) => ({
				...scene,
				preview: scene.preview ?? "",
			}));
		}

		for (const scene of storedScenes) {
			i += 1;
			const imageData: any = await editor?.renderer.render(scene);
			const blob = base64toBlob(imageData, "image/png");
			const imageName = scene?.name ? `${scene.name + i}.png` : `scene${i}.png`;
			zip.file(imageName, blob);
		}

		const zipContent = await zip.generateAsync({ type: "blob" });
		const zipUrl = URL.createObjectURL(zipContent);
		const link = document.createElement("a");
		link.href = zipUrl;
		link.download = "scenes.zip";
		document.body.appendChild(link);
		link.click();

		// Clean up
		URL.revokeObjectURL(zipUrl);
		document.body.removeChild(link);
	};

	const loadTemplate = React.useCallback(
		async (template: any) => {
			if (editor) {
				await handleImportTemplate(template);
				editor?.canvas.canvas.requestRenderAll();
			}
		},
		[editor, currentScene],
	);

	const exportTemplate = async (l_scenes?: IScene[]) => {
		if (!l_scenes) {
			l_scenes = scenes;
		}

		const currentScene = editor && editor.scene.exportToJSON();
		if (currentScene) {
			const updatedScenes = l_scenes.map((scene) => {
				if (scene.id === currentScene.id) {
					return {
						id: currentScene.id,
						layers: currentScene.layers,
						name: currentScene.name,
					};
				}
				return {
					id: scene.id,
					layers: scene.layers,
					name: scene.name,
				};
			});
			if (currentDesign) {
				const graphicTemplate: IDesign = {
					id: currentDesign.id,
					name: currentDesign.name,
					frame: currentDesign.frame,
					scenes: updatedScenes,
					metadata: {},
					preview: "",
				};
				return graphicTemplate;
			} else {
				console.log(t("NoCurrentdesign"));
				return undefined;
			}
		}
	};

	return {
		loadGraphicTemplate,
		loadTemplate,
		handleDownloadTemplate,
		exportTemplate,
	};
};

export default UseTemplateUtils;
