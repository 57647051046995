import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { DropDownItem } from "../../../components/common/BriaDropDown/BriaDropDown";
import { BriaAxios } from "../../../config/axios";
import RouterConstants from "../../../constants/RouterConstants";
import useIframePreview from "../../../hooks/useIframePreview";
import { useAppStore } from "../../../hooks/useStores";
import EditorLayout from "../../../layout/EditorLayout/EditorLayout";
import { EventsLogEnum } from "../../../models/common.ts";
import { Iframe } from "../../../models/iframe";
import { isAccentureOrg, isBriaOrg, isPublicisOrg } from "../../../utils";
import { IframeFormType, PagesEnum, defaultIframe } from "../iframeTypes";
import IframeForm from "./Form/IframeForm";
import styles from "./IframeEditor.module.scss";
import IframePreview from "./Preview/IframePreview";

export type EditIframeLocationState = {
	iframeToEdit?: Iframe;
};

const IframeEditor = () => {
	const { t } = useTranslation("translation", { keyPrefix: "iframeEditor" });
	const { uiStore, appStore } = useAppStore();
	const navigate = useNavigate();
	const location = useLocation();
	const { iframeToEdit } = (location.state as EditIframeLocationState) || {};
	const [iframeForm, setIframeForm] = useState<IframeFormType>((iframeToEdit as IframeFormType) ?? defaultIframe);
	const pagesOptions: DropDownItem[] = Object.entries(iframeForm.enabled_pages || {})
		.filter(
			([key, value]) =>
				value === true &&
				((key !== PagesEnum.Assets && key !== PagesEnum.Campaign) ||
					(key === PagesEnum.Assets && isPublicisOrg()) ||
					(key === PagesEnum.Campaign && (isBriaOrg() || isAccentureOrg()))),
		)
		.map(([key]) => ({
			key: Object.keys(PagesEnum).find((enumKey) => PagesEnum[enumKey as keyof typeof PagesEnum] === key) || "",
			value: key,
		}));
	const [previewPage, setPreviewPage] = useState(iframeForm?.target);
	const iframeUrl: string = useIframePreview(iframeForm, previewPage);

	useEffect(() => {
		uiStore.hideSideBar();
		uiStore.showBackButton(RouterConstants.IFRAME_CONFIG.fullPath);

		appStore.logEvent({ event: EventsLogEnum.START_IFRAME_CONFIG });

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
		};
	}, []);

	const handleFormChange = <K extends keyof IframeFormType>(key: K, value: IframeFormType[K]) => {
		setIframeForm((prevIframe) => ({ ...prevIframe, [key]: value }));
	};

	const openPreview = () => {
		window.open(iframeUrl, "_blank");
	};

	const saveIframe = async () => {
		try {
			if (iframeToEdit) {
				await updateIframeToEdit();
			} else {
				await createNewIframe();
			}
		} catch (e) {
			console.error(e);
		}
	};

	const updateIframeToEdit = async () => {
		try {
			const updatedIframe: IframeFormType = {
				...iframeToEdit,
				...iframeForm,
				enabled_pages: filterTruthyValues(iframeForm?.enabled_pages),
				enabled_features: filterTruthyValues(iframeForm?.enabled_features),
			};
			await (await BriaAxios()).put(`/update_iframe/${iframeToEdit?.uid}`, updatedIframe);
			navigate(RouterConstants.IFRAME_CONFIG.fullPath);
		} catch (e) {
			console.error(e);
		}
	};

	const createNewIframe = async () => {
		try {
			const newIframe: IframeFormType = {
				...iframeForm,
				enabled_pages: filterTruthyValues(iframeForm?.enabled_pages),
				enabled_features: filterTruthyValues(iframeForm?.enabled_features),
			};
			await (await BriaAxios()).post(`/create_iframe/`, newIframe);
			navigate(RouterConstants.IFRAME_CONFIG.fullPath);
		} catch (e) {
			console.error(e);
		}
	};

	const filterTruthyValues = (object: { [key: string]: boolean } = {}) => {
		return Object.fromEntries(Object.entries(object ?? {}).filter(([_, value]) => value));
	};

	return (
		<EditorLayout
			name={iframeForm?.name}
			onNameChange={(e) => handleFormChange("name", e.target.value)}
			primaryButton={t("saveBtn")}
			primaryButtonClick={saveIframe}
			secondaryButton={t("previewBtn")}
			secondaryButtonClick={openPreview}
		>
			<Box className={styles.content}>
				<IframeForm {...{ iframeForm, pagesOptions, handleFormChange, setPreviewPage }} />
				<IframePreview {...{ iframeUrl, pagesOptions, previewPage, setPreviewPage }} />
			</Box>
		</EditorLayout>
	);
};

export default IframeEditor;
