export const SecondLevelMenus = ["FontFamily"];
export const FirstLevelMenus = ["Background"];

export enum SubMenuType {
	FONT_FAMILY = "FontFamily",
	BACKGROUND = "Background",
	COLOR = "Color",
	ANIMATIONS = "Animations",
	EFFECTS = "Effects",
}

export const SAMPLE_TEMPLATES = [
	{
		id: "0sXf_YEww2RXXCjdNkrWh",
		name: "Untitled design",
		layers: [
			{
				id: "background",
				name: "Initial Frame",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 0,
				top: 0,
				width: 1200,
				height: 1200,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 1,
				scaleY: 1,
				type: "Background",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: {
					color: "#ced6e0",
					blur: 8,
					offsetX: 0,
					offsetY: 0,
					affectStroke: false,
					nonScaling: false,
				},
				fill: "#F8E71D",
			},
			{
				id: "98EdqEwYWtPkLFzmnT7Q3",
				name: "StaticText",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 391.78,
				top: 441.65,
				width: 420,
				height: 103.96,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 1,
				scaleY: 1,
				type: "StaticText",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				charSpacing: 0,
				fill: "#333333",
				fontFamily: "OpenSans-Bold",
				fontSize: 92,
				lineHeight: 1.16,
				text: "SPECIAL",
				textAlign: "center",
				fontURL:
					"https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
				metadata: {},
			},
			{
				id: "E2mcHFkwGA-MTJcfl3Abs",
				name: "StaticPath",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 328,
				top: 574.22,
				width: 60,
				height: 60,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 9.19,
				scaleY: 2,
				type: "StaticPath",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				path: [["M", 60, 0], ["L", 0, 0], ["L", 0, 60], ["L", 60, 60], ["L", 60, 0], ["Z"]],
				fill: "#ff4040",
				metadata: {},
			},
			{
				id: "TCU2EB0sH6yMjsN92qRfo",
				name: "StaticText",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 398.89,
				top: 588.54,
				width: 420,
				height: 103.96,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 1,
				scaleY: 1,
				type: "StaticText",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				charSpacing: 0,
				fill: "#ffffff",
				fontFamily: "OpenSans-Bold",
				fontSize: 92,
				lineHeight: 1.16,
				text: "OFFER",
				textAlign: "center",
				fontURL:
					"https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
			},
			{
				id: "E2mcHFkwGA-MTJcfl3Abs",
				name: "StaticPath",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 290.28,
				top: 704.45,
				width: 60,
				height: 60,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 10.32,
				scaleY: 0.18,
				type: "StaticPath",
				flipX: false,
				flipY: true,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				path: [["M", 60, 0], ["L", 0, 0], ["L", 0, 60], ["L", 60, 60], ["L", 60, 0], ["Z"]],
				fill: "#161616",
				metadata: {},
			},
			{
				id: "UXU4h1xR-M_oVOkbTw0g6",
				name: "StaticPath",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 366.99,
				top: 431.72,
				width: 60,
				height: 60,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 7.77,
				scaleY: 0.18,
				type: "StaticPath",
				flipX: false,
				flipY: true,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				path: [["M", 60, 0], ["L", 0, 0], ["L", 0, 60], ["L", 60, 60], ["L", 60, 0], ["Z"]],
				fill: "#161616",
			},
		],
		frame: {
			width: 1200,
			height: 1200,
		},
		metadata: {
			animated: false,
		},
		preview: "https://ik.imagekit.io/scenify/OnktZ7CLGntNeirIYZ_eN8Cm.png",
	},
	{
		id: "sRQQYw0OOnctiJUBsMg6S",
		name: "Untitled design",
		layers: [
			{
				id: "background",
				name: "Initial Frame",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 0,
				top: 0,
				width: 1200,
				height: 1200,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 1,
				scaleY: 1,
				type: "Background",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: {
					color: "#ced6e0",
					blur: 8,
					offsetX: 0,
					offsetY: 0,
					affectStroke: false,
					nonScaling: false,
				},
				fill: "#ef5757",
			},
			{
				id: "3w9KPQbyeUe3kgtwpMZx4",
				name: "StaticPath",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 479.68,
				top: 311.62,
				width: 512,
				height: 455.52,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 0.47,
				scaleY: 0.47,
				type: "StaticPath",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				path: [
					["M", 471.382812, 44.578125],
					["C", 444.87890600000003, 15.832031, 408.51171899999997, 0, 368.97265600000003, 0],
					["C", 339.417969, 0, 312.35156200000006, 9.34375, 288.52343800000006, 27.769531],
					["C", 276.50000000000006, 37.070312, 265.6054690000001, 48.449219, 256.00000000000006, 61.730469],
					["C", 246.39843800000006, 48.453125, 235.50000000000006, 37.070312, 223.47265600000006, 27.769531],
					["C", 199.64843800000006, 9.34375, 172.58203100000006, 0, 143.02734400000006, 0],
					["C", 103.48828100000006, 0, 67.11718800000006, 15.832031, 40.61328100000006, 44.578125],
					[
						"C",
						14.425781000000057,
						72.988281,
						5.684341886080802e-14,
						111.800781,
						5.684341886080802e-14,
						153.871094,
					],
					[
						"C",
						5.684341886080802e-14,
						197.171875,
						16.136719000000056,
						236.808594,
						50.78125000000006,
						278.61328100000003,
					],
					[
						"C",
						81.77343800000006,
						316.00781200000006,
						126.31640600000006,
						353.96875,
						177.89843800000006,
						397.92578100000003,
					],
					["C", 195.51171900000006, 412.9375, 215.47656200000006, 429.953125, 236.20703100000006, 448.078125],
					["C", 241.68359400000006, 452.875, 248.71093800000006, 455.515625, 256.00000000000006, 455.515625],
					["C", 263.28515600000003, 455.515625, 270.31640600000003, 452.875, 275.78515600000003, 448.085938],
					[
						"C",
						296.515625,
						429.957031,
						316.49218800000006,
						412.93359399999997,
						334.11328100000003,
						397.914062,
					],
					["C", 385.6875, 353.96484399999997, 430.230469, 316.007812, 461.22265600000003, 278.609375],
					["C", 495.86718800000006, 236.808594, 512, 197.171875, 512, 153.867188],
					["C", 512, 111.800781, 497.57421899999997, 72.988281, 471.382812, 44.578125],
					["z"],
					["M", 471.382812, 44.578125],
				],
				fill: "#ffffff",
				metadata: {},
			},
			{
				id: "ZFuu3wPmsF-Dp_XF2HE1W",
				name: "StaticText",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 291,
				top: 709.95,
				width: 618,
				height: 67.8,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 1,
				scaleY: 1,
				type: "StaticText",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				charSpacing: 0,
				fill: "#333333",
				fontFamily: "ComicNeue-Regular",
				fontSize: 60,
				lineHeight: 1.16,
				text: "My awesome template",
				textAlign: "center",
				fontURL: "https://fonts.gstatic.com/s/comicneue/v3/4UaHrEJDsxBrF37olUeDx63j5pN1MwI.ttf",
				metadata: {},
			},
			{
				id: "iBeV6S7kcta3lVzMJ7uH_",
				name: "StaticText",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 291,
				top: 801.72,
				width: 618,
				height: 54.24,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 1,
				scaleY: 1,
				type: "StaticText",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				charSpacing: 0,
				fill: "#333333",
				fontFamily: "ComicNeue-Regular",
				fontSize: 48,
				lineHeight: 1.16,
				text: "Doble click to edit",
				textAlign: "center",
				fontURL: "https://fonts.gstatic.com/s/comicneue/v3/4UaHrEJDsxBrF37olUeDx63j5pN1MwI.ttf",
			},
		],
		frame: {
			width: 1200,
			height: 1200,
		},
		metadata: {
			animated: false,
		},
		preview: "https://ik.imagekit.io/scenify/2ZdeFFC6gdlw_LkzDN2J5cHs.png",
	},
	{
		id: "x2shx-AJICr0dZo32dq9y",
		name: "Untitled design",
		layers: [
			{
				id: "background",
				name: "Initial Frame",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 0,
				top: 0,
				width: 1200,
				height: 1200,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 1,
				scaleY: 1,
				type: "Background",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: {
					color: "#ced6e0",
					blur: 8,
					offsetX: 0,
					offsetY: 0,
					affectStroke: false,
					nonScaling: false,
				},
				fill: "#474787",
			},
			{
				id: "3w9KPQbyeUe3kgtwpMZx4",
				name: "StaticPath",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 479.68,
				top: 311.62,
				width: 512,
				height: 455.52,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 0.47,
				scaleY: 0.47,
				type: "StaticPath",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				path: [
					["M", 471.382812, 44.578125],
					["C", 444.87890600000003, 15.832031, 408.51171899999997, 0, 368.97265600000003, 0],
					["C", 339.417969, 0, 312.35156200000006, 9.34375, 288.52343800000006, 27.769531],
					["C", 276.50000000000006, 37.070312, 265.6054690000001, 48.449219, 256.00000000000006, 61.730469],
					["C", 246.39843800000006, 48.453125, 235.50000000000006, 37.070312, 223.47265600000006, 27.769531],
					["C", 199.64843800000006, 9.34375, 172.58203100000006, 0, 143.02734400000006, 0],
					["C", 103.48828100000006, 0, 67.11718800000006, 15.832031, 40.61328100000006, 44.578125],
					[
						"C",
						14.425781000000057,
						72.988281,
						5.684341886080802e-14,
						111.800781,
						5.684341886080802e-14,
						153.871094,
					],
					[
						"C",
						5.684341886080802e-14,
						197.171875,
						16.136719000000056,
						236.808594,
						50.78125000000006,
						278.61328100000003,
					],
					[
						"C",
						81.77343800000006,
						316.00781200000006,
						126.31640600000006,
						353.96875,
						177.89843800000006,
						397.92578100000003,
					],
					["C", 195.51171900000006, 412.9375, 215.47656200000006, 429.953125, 236.20703100000006, 448.078125],
					["C", 241.68359400000006, 452.875, 248.71093800000006, 455.515625, 256.00000000000006, 455.515625],
					["C", 263.28515600000003, 455.515625, 270.31640600000003, 452.875, 275.78515600000003, 448.085938],
					[
						"C",
						296.515625,
						429.957031,
						316.49218800000006,
						412.93359399999997,
						334.11328100000003,
						397.914062,
					],
					["C", 385.6875, 353.96484399999997, 430.230469, 316.007812, 461.22265600000003, 278.609375],
					["C", 495.86718800000006, 236.808594, 512, 197.171875, 512, 153.867188],
					["C", 512, 111.800781, 497.57421899999997, 72.988281, 471.382812, 44.578125],
					["z"],
					["M", 471.382812, 44.578125],
				],
				fill: "#ffffff",
				metadata: {},
			},
			{
				id: "ZFuu3wPmsF-Dp_XF2HE1W",
				name: "StaticText",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 291,
				top: 708.1700000000001,
				width: 618,
				height: 67.8,
				opacity: 1,
				originX: "left",
				originY: "top",
				scaleX: 1,
				scaleY: 1,
				type: "StaticText",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				charSpacing: 0,
				fill: "#ffffff",
				fontFamily: "ComicNeue-Bold",
				fontSize: 60,
				lineHeight: 1.16,
				text: "My awesome template",
				textAlign: "center",
				fontURL: "https://fonts.gstatic.com/s/comicneue/v3/4UaErEJDsxBrF37olUeD_xHMwpteLwtHJlc.ttf",
				metadata: {},
			},
			{
				id: "iBeV6S7kcta3lVzMJ7uH_",
				name: "StaticText",
				angle: 0,
				stroke: null,
				strokeWidth: 0,
				left: 291,
				top: 801.72,
				width: 618,
				height: 54.24,
				opacity: 0.7,
				originX: "left",
				originY: "top",
				scaleX: 1,
				scaleY: 1,
				type: "StaticText",
				flipX: false,
				flipY: false,
				skewX: 0,
				skewY: 0,
				visible: true,
				shadow: null,
				charSpacing: 0,
				fill: "#ffffff",
				fontFamily: "ComicNeue-Regular",
				fontSize: 48,
				lineHeight: 1.16,
				text: "Doble click to edit",
				textAlign: "center",
				fontURL: "https://fonts.gstatic.com/s/comicneue/v3/4UaHrEJDsxBrF37olUeDx63j5pN1MwI.ttf",
			},
		],
		frame: {
			width: 1200,
			height: 1200,
		},
		metadata: {
			animated: false,
		},
		preview: "https://ik.imagekit.io/scenify/hkHXvT7iOVnyZwLIkniU8WpA.png",
	},
];

export const editorFonts = [
	{
		name: "Roboto",
		preview:
			"https://font-public.canva.com/YACgEev4gKc/0/thumbnail3539468006015936417.786a5ed94cf61f05978893c9088a06ea.png",
	},
	{
		name: "Quicksand",
		preview:
			"https://font-public.canva.com/YADWjpfPmdk/0/thumbnail1564020220251902443.bc4d1dede1fc33ec1e227ccfbc723b00.png",
	},

	{
		name: "Josefin Sans",
		preview:
			"https://font-public.canva.com/YADWjoIs6hY/0/thumbnail5311368174505054542.97ad47e884b96eb86f0a0f6b9abb0e61.png",
	},
	{
		name: "Arimo",
		preview:
			"https://font-public.canva.com/YACgEZ1cb1Q/0/thumbnail2753710016227887457.6dfe783f5d32581a0de7b11c49badc89.png",
	},
	{
		name: "Open Sans",
		preview:
			"https://font-public.canva.com/YAD7QhG2T6o/0/thumbnail4397746033903160930.50a02b6e1eabffa186ed7d1592726fe7.png",
	},
];

export const gradients = [
	{
		angle: 0,
		colors: ["#00c3ff", "#ffff1c"], //linear-gradient(to right, #00c3ff, #ffff1c)
	},
	{
		angle: 0,
		colors: ["#8e2de2", "#4a00e0"], //linear-gradient(to right, #00c3ff, #ffff1c)
	},
	{
		angle: 0,
		colors: ["#c0392b", "#8e44ad"], //linear-gradient(to right, #00c3ff, #ffff1c)
	},
	{
		angle: 0,
		colors: ["#ff00cc", "#333399"], //linear-gradient(to right, #00c3ff, #ffff1c)
	},
	{
		angle: 0,
		colors: ["#ff4b1f", "#1fddff"], //linear-gradient(to right, #00c3ff, #ffff1c)
	},
	{
		angle: 0,
		colors: ["#6a3093", "#a044ff"], //linear-gradient(to right, #00c3ff, #ffff1c)
	},
	{
		angle: 0,
		colors: ["#fc00ff", "#00dbde"], //linear-gradient(to right, #00c3ff, #ffff1c)
	},
	{
		angle: 0,
		colors: ["#ff0084", "#33001b"], //linear-gradient(to right, #00c3ff, #ffff1c)
	},
	{
		angle: 0,
		colors: ["#43cea2", "#185a9d"], //linear-gradient(to right, #00c3ff, #ffff1c)
	},
];

export const FONT_SIZES = [10, 11, 12, 13, 14, 15, 16, 20, 24, 28, 32, 36, 40, 48, 64, 96, 124];

export const SAMPLE_FONTS = [
	{
		id: "font_zjroeXKL5b6UdbfdqDXVhgf9",
		family: "Gaegu",
		full_name: "Gaegu Light",
		postscript_name: "Gaegu-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/9idoq0LRDb67USctK3Z5iDnt.png",
		style: "Gaegu-Light",
		url: "https://fonts.gstatic.com/s/gaegu/v10/TuGSUVB6Up9NU57nifw74sdtBk0x.ttf",
		category: "handwriting",
	},
	{
		id: "font_9qrREGdm_L_MD9sbbGK4uU_y",
		family: "Gaegu",
		full_name: "Gaegu Bold",
		postscript_name: "Gaegu-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/T_Ohnhj6Fw5oG7vhXyLLsfR9.png",
		style: "Gaegu-Bold",
		url: "https://fonts.gstatic.com/s/gaegu/v10/TuGSUVB6Up9NU573jvw74sdtBk0x.ttf",
		category: "handwriting",
	},
	{
		id: "font_lXvnSq7qpnFjfMo2HPFCc4ro",
		family: "Gaegu",
		full_name: "Gaegu Regular",
		postscript_name: "Gaegu-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/xWL8ubgRVFNmAFcaZMF3ZMps.png",
		style: "Gaegu-Regular",
		url: "https://fonts.gstatic.com/s/gaegu/v10/TuGfUVB6Up9NU6ZLodgzydtk.ttf",
		category: "handwriting",
	},
	{
		id: "font_AjCoMecaFjiE5j7fl9QPiy4A",
		family: "Open Sans",
		full_name: "Open Sans Light",
		postscript_name: "OpenSans-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/jgejc4VINZf6zQAbabtQlxu3.png",
		style: "OpenSans-Light",
		url: "https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf",
		category: "sans-serif",
	},
	{
		id: "font_vxgUdYVWaWHbrBACbZ9uttEP",
		family: "Open Sans",
		full_name: "Open Sans Medium",
		postscript_name: "OpenSans-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/yFd_K2edGXGX85qTDvKszYNT.png",
		style: "OpenSans-Medium",
		url: "https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4nY1M2xLER.ttf",
		category: "sans-serif",
	},
	{
		id: "font_gWVynVgRBxLTDzy4h7RxQq1z",
		family: "Open Sans",
		full_name: "Open Sans SemiBold",
		postscript_name: "OpenSans-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/kkZGtych455XT0Q2eIc8s5U0.png",
		style: "OpenSans-SemiBold",
		url: "https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf",
		category: "sans-serif",
	},
	{
		id: "font_UjIR0iJcIMThxwihyCJnr4ko",
		family: "Open Sans",
		full_name: "Open Sans Bold",
		postscript_name: "OpenSans-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/rx4iLqyv88IknaBF2ViLg7O5.png",
		style: "OpenSans-Bold",
		url: "https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
		category: "sans-serif",
	},
	{
		id: "font_uX2I6f79yV42VGCyrDHpu8Ik",
		family: "Open Sans",
		full_name: "Open Sans ExtraBold",
		postscript_name: "OpenSans-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/LgwiS9aO6fshqgu92pqBVws_.png",
		style: "OpenSans-ExtraBold",
		url: "https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4nY1M2xLER.ttf",
		category: "sans-serif",
	},
	{
		id: "font_YGTi4PM92RVKr5nHi6zfoiKK",
		family: "Open Sans",
		full_name: "Open Sans Regular",
		postscript_name: "OpenSans-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/idYUdN83yaYYi9xTpSv47bwY.png",
		style: "OpenSans-Regular",
		url: "https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Dxo0qO8aDEriiB3kMBhI8oEC",
		family: "Open Sans",
		full_name: "Open Sans Light Italic",
		postscript_name: "OpenSans-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/E5cecP8IAOngmc5vA_pXoWAc.png",
		style: "OpenSans-LightItalic",
		url: "https://fonts.gstatic.com/s/opensans/v27/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk5hkaVcUwaERZjA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_6Nbkm9TrfpiQLuBibXky0ZWe",
		family: "Open Sans",
		full_name: "Open Sans Italic",
		postscript_name: "OpenSans-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/cBh5ew__DQ1sXpA7oYMN_klC.png",
		style: "OpenSans-Italic",
		url: "https://fonts.gstatic.com/s/opensans/v27/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkaVcUwaERZjA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_hGG2oGypsF5_5ZFZSmeftLRH",
		family: "Open Sans",
		full_name: "Open Sans Medium Italic",
		postscript_name: "OpenSans-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/M7UtaOMfyE9WIJSTGy8716i_.png",
		style: "OpenSans-MediumItalic",
		url: "https://fonts.gstatic.com/s/opensans/v27/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk_RkaVcUwaERZjA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_6w9leZe74p5DOUcA1G3pqvKy",
		family: "Open Sans",
		full_name: "Open Sans SemiBold Italic",
		postscript_name: "OpenSans-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/D0AYrGM92wfE3lTAEPsJ7ySQ.png",
		style: "OpenSans-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/opensans/v27/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkxhjaVcUwaERZjA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_avi1F5vMnIo227AWbau16Xi3",
		family: "Open Sans",
		full_name: "Open Sans Bold Italic",
		postscript_name: "OpenSans-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/MHh7C73bctVL4JJv1CcCiX8H.png",
		style: "OpenSans-BoldItalic",
		url: "https://fonts.gstatic.com/s/opensans/v27/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0RkyFjaVcUwaERZjA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_AJxyfp85zY86S341o79fXJhN",
		family: "Open Sans",
		full_name: "Open Sans ExtraBold Italic",
		postscript_name: "OpenSans-ExtraBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/MR71UD9JxBfufoMA_nys6u2C.png",
		style: "OpenSans-ExtraBoldItalic",
		url: "https://fonts.gstatic.com/s/opensans/v27/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk0ZjaVcUwaERZjA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ftMs6fqDtGiuLcYJBz55j83j",
		family: "Open Sans Condensed",
		full_name: "Open Sans Condensed Light",
		postscript_name: "OpenSansCondensed-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/5ab72FvSaeC88BWioQs9sXHV.png",
		style: "OpenSansCondensed-Light",
		url: "https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhPuLGRpWRyAs.ttf",
		category: "sans-serif",
	},
	{
		id: "font_xL1BrgzE1Qjf4spu0goVXEQq",
		family: "Open Sans Condensed",
		full_name: "Open Sans Condensed Bold",
		postscript_name: "OpenSansCondensed-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/c6PN3RWESfpUyA2m_m5QokmI.png",
		style: "OpenSansCondensed-Bold",
		url: "https://fonts.gstatic.com/s/opensanscondensed/v15/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff0GmPuLGRpWRyAs.ttf",
		category: "sans-serif",
	},
	{
		id: "font_QaEklZND6kGOy6xX15BjUB5_",
		family: "Open Sans Condensed",
		full_name: "Open Sans Condensed Light Italic",
		postscript_name: "OpenSansCondensed-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/syq3YZNPR1aqmU_s_wT6nsPe.png",
		style: "OpenSansCondensed-LightItalic",
		url: "https://fonts.gstatic.com/s/opensanscondensed/v15/z7NHdQDnbTkabZAIOl9il_O6KJj73e7Fd_-7suDMQreU2AsJSg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_qyGJzDkepEiEWn_JAn1tnUZX",
		family: "Lato",
		full_name: "Lato Thin",
		postscript_name: "Lato-Hairline",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Fg9zO3eElyd0ZIOadwYJTRT9.png",
		style: "Lato-Hairline",
		url: "https://fonts.gstatic.com/s/lato/v20/S6u8w4BMUTPHh30wWyWrFCbw7A.ttf",
		category: "sans-serif",
	},
	{
		id: "font_bntNxigNBtj49dGq7dTG3IpU",
		family: "Lato",
		full_name: "Lato Light",
		postscript_name: "Lato-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Eqf3kZgguRLMj5oQmr6XMmZK.png",
		style: "Lato-Light",
		url: "https://fonts.gstatic.com/s/lato/v20/S6u9w4BMUTPHh7USew-FGC_p9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ueqtHprxH5n0n6FKkaiiYFst",
		family: "Lato",
		full_name: "Lato Bold",
		postscript_name: "Lato-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/tH0iIATTo7oKH1K6HEEUAU1u.png",
		style: "Lato-Bold",
		url: "https://fonts.gstatic.com/s/lato/v20/S6u9w4BMUTPHh6UVew-FGC_p9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_TN7erzg5Abs9NQ0k_Duk4Oi9",
		family: "Lato",
		full_name: "Lato Black",
		postscript_name: "Lato-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/_v5OEVpvegKp7ysEleMo0s16.png",
		style: "Lato-Black",
		url: "https://fonts.gstatic.com/s/lato/v20/S6u9w4BMUTPHh50Xew-FGC_p9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_19AFvXIgk9o_LEljOfZwejdn",
		family: "Lato",
		full_name: "Lato Thin Italic",
		postscript_name: "Lato-HairlineItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/ZrIAVsc8UMjE4IbPVcpp4XM4.png",
		style: "Lato-HairlineItalic",
		url: "https://fonts.gstatic.com/s/lato/v20/S6u-w4BMUTPHjxsIPy-vNiPg7MU0.ttf",
		category: "sans-serif",
	},
	{
		id: "font_pkWGsczbTCHIGSkRmEh5foS4",
		family: "Lato",
		full_name: "Lato Light Italic",
		postscript_name: "Lato-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/5DN9zu_gxqcqKOU3iSA7lbz0.png",
		style: "Lato-LightItalic",
		url: "https://fonts.gstatic.com/s/lato/v20/S6u_w4BMUTPHjxsI9w2PHA3s5dwt7w.ttf",
		category: "sans-serif",
	},
	{
		id: "font_EzWBF31sna3Bbg3cJuK8HT4t",
		family: "Lato",
		full_name: "Lato Regular",
		postscript_name: "Lato-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/X8EKUPPeag3C7PWVhyTPsNxD.png",
		style: "Lato-Regular",
		url: "https://fonts.gstatic.com/s/lato/v20/S6uyw4BMUTPHvxk6XweuBCY.ttf",
		category: "sans-serif",
	},
	{
		id: "font_YzbuvPeMk8MXhqfKuXacMbhF",
		family: "Lato",
		full_name: "Lato Italic",
		postscript_name: "Lato-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/mn7W89v2uOzhtaAUFeHht0fl.png",
		style: "Lato-Italic",
		url: "https://fonts.gstatic.com/s/lato/v20/S6u8w4BMUTPHjxswWyWrFCbw7A.ttf",
		category: "sans-serif",
	},
	{
		id: "font_LNdVSlgSMrNQLVkM7J3STDJK",
		family: "Lato",
		full_name: "Lato Bold Italic",
		postscript_name: "Lato-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/FyoM8QjqwL3gG2tFdDex16ZC.png",
		style: "Lato-BoldItalic",
		url: "https://fonts.gstatic.com/s/lato/v20/S6u_w4BMUTPHjxsI5wqPHA3s5dwt7w.ttf",
		category: "sans-serif",
	},
	{
		id: "font_vAVP6x69VGTyfKHhZ2AemGLj",
		family: "Lato",
		full_name: "Lato Black Italic",
		postscript_name: "Lato-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/9tU9dBig0blMPlxA7WArpZ53.png",
		style: "Lato-BlackItalic",
		url: "https://fonts.gstatic.com/s/lato/v20/S6u_w4BMUTPHjxsI3wiPHA3s5dwt7w.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ifJqJvlMGadeNY89ia0qoulM",
		family: "Outfit",
		full_name: "Outfit Thin",
		postscript_name: "Outfit-Thin",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/wcIlbZBOt7vegAMo2ZfckkXp.png",
		style: "Outfit-Thin",
		url: "https://fonts.gstatic.com/s/outfit/v1/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC0C4G-EiAou6Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_sfQuFUsVAI4lwzH5bSdHd5HD",
		family: "Outfit",
		full_name: "Outfit ExtraLight",
		postscript_name: "Outfit-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/VgZSJ8OzwZEgWa97ketQWB9k.png",
		style: "Outfit-ExtraLight",
		url: "https://fonts.gstatic.com/s/outfit/v1/QGYyz_MVcBeNP4NjuGObqx1XmO1I4bC1C4G-EiAou6Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_8ESxhfW7906RxjwOtrA0Uyk2",
		family: "Outfit",
		full_name: "Outfit Light",
		postscript_name: "Outfit-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/mui1jM4FlwFDULtKIDi0x0CX.png",
		style: "Outfit-Light",
		url: "https://fonts.gstatic.com/s/outfit/v1/QGYyz_MVcBeNP4NjuGObqx1XmO1I4W61C4G-EiAou6Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_vP1HAYYiXaMHbW0Rqi1p45YU",
		family: "Outfit",
		full_name: "Outfit Medium",
		postscript_name: "Outfit-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/hkJc9HbLVPwLo3FVJvj2RMbN.png",
		style: "Outfit-Medium",
		url: "https://fonts.gstatic.com/s/outfit/v1/QGYyz_MVcBeNP4NjuGObqx1XmO1I4QK1C4G-EiAou6Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_GgYpzSy2NqM_7J4WTyhd9DPM",
		family: "Outfit",
		full_name: "Outfit SemiBold",
		postscript_name: "Outfit-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/TiEcMQypkNqruX1pHiAATTIg.png",
		style: "Outfit-SemiBold",
		url: "https://fonts.gstatic.com/s/outfit/v1/QGYyz_MVcBeNP4NjuGObqx1XmO1I4e6yC4G-EiAou6Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_8aY7zJkbq_b81n14sraJd4ag",
		family: "Outfit",
		full_name: "Outfit Bold",
		postscript_name: "Outfit-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/N6Lga3M0jSGnV_vcEhbr3cgi.png",
		style: "Outfit-Bold",
		url: "https://fonts.gstatic.com/s/outfit/v1/QGYyz_MVcBeNP4NjuGObqx1XmO1I4deyC4G-EiAou6Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_6tokt7lQnf5GK9BPe0VkSZ0W",
		family: "Outfit",
		full_name: "Outfit ExtraBold",
		postscript_name: "Outfit-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/diZvNt7pZHz2p3gA50FiYYzl.png",
		style: "Outfit-ExtraBold",
		url: "https://fonts.gstatic.com/s/outfit/v1/QGYyz_MVcBeNP4NjuGObqx1XmO1I4bCyC4G-EiAou6Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_XZGu1I7C34daTL0BIRt7x3h9",
		family: "Outfit",
		full_name: "Outfit Black",
		postscript_name: "Outfit-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/QjiJ1gPFQKs71_Y1UXN_FySi.png",
		style: "Outfit-Black",
		url: "https://fonts.gstatic.com/s/outfit/v1/QGYyz_MVcBeNP4NjuGObqx1XmO1I4ZmyC4G-EiAou6Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_BhnwONmz30xFIeY7rM9XHkl4",
		family: "Outfit",
		full_name: "Outfit Regular",
		postscript_name: "Outfit-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/aBPDr5DsI6CzjisoZwqrFjGE.png",
		style: "Outfit-Regular",
		url: "https://fonts.gstatic.com/s/outfit/v1/QGYyz_MVcBeNP4NjuGObqx1XmO1I4TC1C4G-EiAou6Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_LhGbhqC9lLOA9g3KicBzWIIS",
		family: "Poppins",
		full_name: "Poppins Thin",
		postscript_name: "Poppins-Thin",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/HUhqU7t6YWqpcJWwY1aLiQm_.png",
		style: "Poppins-Thin",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_o94CDRfGgHSfG2HlIWi_qKyc",
		family: "Poppins",
		full_name: "Poppins ExtraLight",
		postscript_name: "Poppins-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/3UzFhaaQX1fJbkJBoc3S7JY6.png",
		style: "Poppins-ExtraLight",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ZJdN1Mxd6tg4PSFHmdBac5VC",
		family: "Poppins",
		full_name: "Poppins Light",
		postscript_name: "Poppins-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/xvOXz3pGje4lXaRbLtGF0HT1.png",
		style: "Poppins-Light",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Yzj_tDRMJiioAHdUdtMeTWzb",
		family: "Poppins",
		full_name: "Poppins Medium",
		postscript_name: "Poppins-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/QI1zQnH_TCzrTMntFKehrqm8.png",
		style: "Poppins-Medium",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_IVpYv4htb1wJubUfTOp9AywS",
		family: "Poppins",
		full_name: "Poppins SemiBold",
		postscript_name: "Poppins-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Gv3KDEE6F984bJ3pWzSk04Vz.png",
		style: "Poppins-SemiBold",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_2SzDYGM8DB3hvaC6a9B5z7bO",
		family: "Poppins",
		full_name: "Poppins Bold",
		postscript_name: "Poppins-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/iNhKoZf3_6h6lfDCzflgrwqx.png",
		style: "Poppins-Bold",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_szImRJgTMvnYVF95fBKpKnqa",
		family: "Poppins",
		full_name: "Poppins ExtraBold",
		postscript_name: "Poppins-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/UpWtxG0PhnBmPz5US_fzIdvp.png",
		style: "Poppins-ExtraBold",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_dtT3qsayheTQCVzsc0rOOkWN",
		family: "Poppins",
		full_name: "Poppins Black",
		postscript_name: "Poppins-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/nIFbSpOrW3G4j4CbXx46hVT_.png",
		style: "Poppins-Black",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_2PZsNiQYBVZUQP09PolLP0gA",
		family: "Poppins",
		full_name: "Poppins Thin Italic",
		postscript_name: "Poppins-ThinItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/2rNOQzzbqHA91_YMzVcTxsqn.png",
		style: "Poppins-ThinItalic",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf",
		category: "sans-serif",
	},
	{
		id: "font_PApUhqy3BBgQwiwLWGJatAGB",
		family: "Poppins",
		full_name: "Poppins ExtraLight Italic",
		postscript_name: "Poppins-ExtraLightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/5d2uGlgIuDRzuUwArNxU_l9L.png",
		style: "Poppins-ExtraLightItalic",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_AwIVmhnBfjwrMMpqQhFYqviW",
		family: "Poppins",
		full_name: "Poppins Light Italic",
		postscript_name: "Poppins-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/p8Wmw4muvDWQS09VW8uWJkQZ.png",
		style: "Poppins-LightItalic",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_5oCSW5K7m2VcwE1oPdioXDno",
		family: "Poppins",
		full_name: "Poppins Regular",
		postscript_name: "Poppins-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/KT49YeENhVy7CVLmQygOY7nJ.png",
		style: "Poppins-Regular",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
		category: "sans-serif",
	},
	{
		id: "font_5xeFgrpzzKpTI1HviPNWgqSI",
		family: "Poppins",
		full_name: "Poppins Italic",
		postscript_name: "Poppins-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/0pIwLS1PdSwLlxWa4buIP8Wq.png",
		style: "Poppins-Italic",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_RHNqsJem43EIYjqBjkPwuLmm",
		family: "Poppins",
		full_name: "Poppins Medium Italic",
		postscript_name: "Poppins-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Ilds83Nah2Q8cYhZ3n4R1VnP.png",
		style: "Poppins-MediumItalic",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_MJ0aGF7qjCH40WCjAKz82_lJ",
		family: "Poppins",
		full_name: "Poppins SemiBold Italic",
		postscript_name: "Poppins-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/VEJSBNGV5Moka2Am1eDhVilq.png",
		style: "Poppins-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_BqSlba2mf9LjoOkjuulsleZ_",
		family: "Poppins",
		full_name: "Poppins Bold Italic",
		postscript_name: "Poppins-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/CKT8hBV3f14gaKuRFELkc8Bp.png",
		style: "Poppins-BoldItalic",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_nrtpgcg4pR4dwI4vgEU_wls1",
		family: "Poppins",
		full_name: "Poppins ExtraBold Italic",
		postscript_name: "Poppins-ExtraBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/6y0KMPNmb68MMzv1Qv9EGh3V.png",
		style: "Poppins-ExtraBoldItalic",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_BNDRNOJrefdEqC553tSG40KP",
		family: "Poppins",
		full_name: "Poppins Black Italic",
		postscript_name: "Poppins-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/QRCKs2Jn3wb8AF3Sea_FkLvo.png",
		style: "Poppins-BlackItalic",
		url: "https://fonts.gstatic.com/s/poppins/v15/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_5N0kQNQLeTI5b9JIBRnW_Myy",
		family: "Nunito Sans",
		full_name: "Nunito Sans ExtraLight",
		postscript_name: "NunitoSans-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/d54XRNUZzAz8QItB_BkVItJI.png",
		style: "NunitoSans-ExtraLight",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc9yAv5qWVAgVol-.ttf",
		category: "sans-serif",
	},
	{
		id: "font_v1DoTqlSTRpLdWBZS7Ei4s5y",
		family: "Nunito Sans",
		full_name: "Nunito Sans Light",
		postscript_name: "NunitoSans-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/87GgbiiRZ7q7nAtP8kmf2pog.png",
		style: "NunitoSans-Light",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8WAf5qWVAgVol-.ttf",
		category: "sans-serif",
	},
	{
		id: "font_CS23mTw9X5tJnjSmJTVayZyB",
		family: "Nunito Sans",
		full_name: "Nunito Sans SemiBold",
		postscript_name: "NunitoSans-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/IC2WNpbQC34FMnSfHFEmAnVZ.png",
		style: "NunitoSans-SemiBold",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc9iB_5qWVAgVol-.ttf",
		category: "sans-serif",
	},
	{
		id: "font_BUDUCFMqoQ3v8LVTwtKqMPkF",
		family: "Nunito Sans",
		full_name: "Nunito Sans Bold",
		postscript_name: "NunitoSans-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/I1G9mcRQG1OMBfV84kbDiS2s.png",
		style: "NunitoSans-Bold",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8GBv5qWVAgVol-.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Ih_30LcV2WTFfm6BzIEf6Vlk",
		family: "Nunito Sans",
		full_name: "Nunito Sans ExtraBold",
		postscript_name: "NunitoSans-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/61_u99_emMdrvvcPA7NhB_ex.png",
		style: "NunitoSans-ExtraBold",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8aBf5qWVAgVol-.ttf",
		category: "sans-serif",
	},
	{
		id: "font_tCCJEi2jzrO02eNZppZccmEd",
		family: "Nunito Sans",
		full_name: "Nunito Sans Black",
		postscript_name: "NunitoSans-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/nNtyfX96G4QIAQW6Ll2IdvB5.png",
		style: "NunitoSans-Black",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe03MImSLYBIv1o4X1M8cc8-BP5qWVAgVol-.ttf",
		category: "sans-serif",
	},
	{
		id: "font_zooS3waRqiglYQ7losSShptM",
		family: "Nunito Sans",
		full_name: "Nunito Sans ExtraLight Italic",
		postscript_name: "NunitoSans-ExtraLightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/31ShcsNYlbd53AQaLbVOGvNO.png",
		style: "NunitoSans-ExtraLightItalic",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe01MImSLYBIv1o4X1M8cce4GxZrU1QCU5l-06Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_tM3PASqp3HPs3mldWB7FJl4r",
		family: "Nunito Sans",
		full_name: "Nunito Sans Light Italic",
		postscript_name: "NunitoSans-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/noj5Y8wlDE7h6rKov6TxP19w.png",
		style: "NunitoSans-LightItalic",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe01MImSLYBIv1o4X1M8cce4G3JoU1QCU5l-06Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_wZt0U9m7HCVzdVVIUseDXK4t",
		family: "Nunito Sans",
		full_name: "Nunito Sans Regular",
		postscript_name: "NunitoSans-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/PlXvSoK7QdRLJ8hFr5MWHyRW.png",
		style: "NunitoSans-Regular",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe0qMImSLYBIv1o4X1M8cfe6Kdpickwp.ttf",
		category: "sans-serif",
	},
	{
		id: "font_9CqojRXhEzFbKb2Zbtn_2aCY",
		family: "Nunito Sans",
		full_name: "Nunito Sans Italic",
		postscript_name: "NunitoSans-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/t_qgTgeIaxpPVadwrDqv2FIf.png",
		style: "NunitoSans-Italic",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe0oMImSLYBIv1o4X1M8cce4I95Ad1wpT5A.ttf",
		category: "sans-serif",
	},
	{
		id: "font_onuBaFJEsfk8dL02TFvT4CBI",
		family: "Nunito Sans",
		full_name: "Nunito Sans SemiBold Italic",
		postscript_name: "NunitoSans-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/LujXAiPz2TLoWvBTgEsWbMjj.png",
		style: "NunitoSans-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe01MImSLYBIv1o4X1M8cce4GwZuU1QCU5l-06Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_0KREXdiwU5o5H3sqHOrfCV_c",
		family: "Nunito Sans",
		full_name: "Nunito Sans Bold Italic",
		postscript_name: "NunitoSans-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/32XwAIINuS4QefmZwBhRWlwW.png",
		style: "NunitoSans-BoldItalic",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe01MImSLYBIv1o4X1M8cce4G2JvU1QCU5l-06Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_oKICV8PTe0yF6lruxhjLHWV5",
		family: "Nunito Sans",
		full_name: "Nunito Sans ExtraBold Italic",
		postscript_name: "NunitoSans-ExtraBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/dfYfDtNwMskqDviSErXq_3J4.png",
		style: "NunitoSans-ExtraBoldItalic",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe01MImSLYBIv1o4X1M8cce4G35sU1QCU5l-06Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_uavckRTQ3G5QkVRb2n6dJ9Dt",
		family: "Nunito Sans",
		full_name: "Nunito Sans Black Italic",
		postscript_name: "NunitoSans-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/AmGDYTgNUNVTnm2zwqLnEZmL.png",
		style: "NunitoSans-BlackItalic",
		url: "https://fonts.gstatic.com/s/nunitosans/v8/pe01MImSLYBIv1o4X1M8cce4G1ptU1QCU5l-06Y.ttf",
		category: "sans-serif",
	},
	{
		id: "font_KQN5IXl1evqTqcdjeQVkquwS",
		family: "Raleway",
		full_name: "Raleway Thin",
		postscript_name: "Raleway-Thin",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/iBgc3vZ8r92Ye2O3tKBvOgpr.png",
		style: "Raleway-Thin",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvao4CPNLA3JC9c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_PHKGyJpAT0ChYjW4ekOnp77S",
		family: "Raleway",
		full_name: "Raleway ExtraLight",
		postscript_name: "Raleway-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/EfZ_OKGuvn3bP63uCjnKl0Rn.png",
		style: "Raleway-ExtraLight",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtaooCPNLA3JC9c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_jFx4oObCNtE7M1gU59V2sbBm",
		family: "Raleway",
		full_name: "Raleway Light",
		postscript_name: "Raleway-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/XYhMZaWO7cOP2suw6miRGG56.png",
		style: "Raleway-Light",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVuEooCPNLA3JC9c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_w8ZAV5DtQLt6YeNLdasoETIy",
		family: "Raleway",
		full_name: "Raleway Medium",
		postscript_name: "Raleway-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/NqskNajzZPdLskpH8ERt8d7v.png",
		style: "Raleway-Medium",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvoooCPNLA3JC9c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ZTjx6uqqtInMJ_kAF4SpNeGG",
		family: "Raleway",
		full_name: "Raleway SemiBold",
		postscript_name: "Raleway-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/BRYX2yO6NhOurGWmfwZs8jkK.png",
		style: "Raleway-SemiBold",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVsEpYCPNLA3JC9c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_W4JEsS4adhJQrlGVdw9m4ODX",
		family: "Raleway",
		full_name: "Raleway Bold",
		postscript_name: "Raleway-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/CeeUDNWxtE_C0vOB8jAoNkhb.png",
		style: "Raleway-Bold",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVs9pYCPNLA3JC9c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_IFicEuTythJhqni5yrxLITR7",
		family: "Raleway",
		full_name: "Raleway ExtraBold",
		postscript_name: "Raleway-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/DZXQkgOpEck89UuxD_QYWd4T.png",
		style: "Raleway-ExtraBold",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtapYCPNLA3JC9c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_aOMuijfiz7eiv6G8CRMztB9w",
		family: "Raleway",
		full_name: "Raleway Black",
		postscript_name: "Raleway-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/5oYiWc_UBDRZlTgEcSwzuwcq.png",
		style: "Raleway-Black",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVtzpYCPNLA3JC9c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_uND1aoGP4274R7pTQh5lOqLT",
		family: "Raleway",
		full_name: "Raleway Regular",
		postscript_name: "Raleway-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/GjFErFdegRUsC5WQNa7Ddxyy.png",
		style: "Raleway-Regular",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_rAei_r7aD0viGsKg8LQlcsaY",
		family: "Raleway",
		full_name: "Raleway Thin Italic",
		postscript_name: "Raleway-ThinItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/HNmeU9g8yUDQ5TU7D89JlEDf.png",
		style: "Raleway-ThinItalic",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjNPrQVIT9c2c8.ttf",
		category: "sans-serif",
	},
	{
		id: "font_kmqK34r6Lpb_HoLseJq72KpW",
		family: "Raleway",
		full_name: "Raleway ExtraLight Italic",
		postscript_name: "Raleway-ExtraLightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/4_uODiKqOIlKraGLUuSF3awG.png",
		style: "Raleway-ExtraLightItalic",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejMPrQVIT9c2c8.ttf",
		category: "sans-serif",
	},
	{
		id: "font_omqrjIlQhzr3dI4fwULaTtm9",
		family: "Raleway",
		full_name: "Raleway Light Italic",
		postscript_name: "Raleway-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/j7DberwtwSzzkh00aeQGxSSl.png",
		style: "Raleway-LightItalic",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4TbMPrQVIT9c2c8.ttf",
		category: "sans-serif",
	},
	{
		id: "font_R8KQ7db7nEiUrN5rDHqCXlAW",
		family: "Raleway",
		full_name: "Raleway Italic",
		postscript_name: "Raleway-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/r397B0amHlxuCDAyG2SCYXYB.png",
		style: "Raleway-Italic",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4WjMPrQVIT9c2c8.ttf",
		category: "sans-serif",
	},
	{
		id: "font_lEae5Bg0riaCfWC1U2ZCERBk",
		family: "Raleway",
		full_name: "Raleway Medium Italic",
		postscript_name: "Raleway-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/8u6YJH4tZ67SRFRb22kuqhBe.png",
		style: "Raleway-MediumItalic",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4VrMPrQVIT9c2c8.ttf",
		category: "sans-serif",
	},
	{
		id: "font_QHL_kGgn_vUu4tftrMhruYPi",
		family: "Raleway",
		full_name: "Raleway SemiBold Italic",
		postscript_name: "Raleway-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/0qAgokkdrKiWu0Rto95dmd1V.png",
		style: "Raleway-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4bbLPrQVIT9c2c8.ttf",
		category: "sans-serif",
	},
	{
		id: "font_AAiGO4gObktpVKL_VKdNiNMv",
		family: "Raleway",
		full_name: "Raleway Bold Italic",
		postscript_name: "Raleway-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/YOl1H4qw9QbzoPlS5LX7ide2.png",
		style: "Raleway-BoldItalic",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4Y_LPrQVIT9c2c8.ttf",
		category: "sans-serif",
	},
	{
		id: "font_GlSzdG6jWWfsKpzdOdC7cTkc",
		family: "Raleway",
		full_name: "Raleway ExtraBold Italic",
		postscript_name: "Raleway-ExtraBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/bVAkK_xW5Ui3XXxYitcAd8TS.png",
		style: "Raleway-ExtraBoldItalic",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4ejLPrQVIT9c2c8.ttf",
		category: "sans-serif",
	},
	{
		id: "font_gfo3jYIjuY5eGdDwM5DoOHMr",
		family: "Raleway",
		full_name: "Raleway Black Italic",
		postscript_name: "Raleway-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/imVYqor9L9J9lo7D3N71yAs8.png",
		style: "Raleway-BlackItalic",
		url: "https://fonts.gstatic.com/s/raleway/v22/1Pt_g8zYS_SKggPNyCgSQamb1W0lwk4S4cHLPrQVIT9c2c8.ttf",
		category: "sans-serif",
	},
	{
		id: "font_aNuUw5KuFgJUYJ37zjY8ERFg",
		family: "Montserrat",
		full_name: "Montserrat Thin",
		postscript_name: "Montserrat-Thin",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/mkwJMnJv8VcNyyZNKZKthuyC.png",
		style: "Montserrat-Thin",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUQjIg1_i6t8kCHKm45_QphziTn89dtpQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_icttgsjAcKBGtmwaErp1ST8C",
		family: "Montserrat",
		full_name: "Montserrat ExtraLight",
		postscript_name: "Montserrat-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/gUBlzJTzKcFEiWNmoZE2GX76.png",
		style: "Montserrat-ExtraLight",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_aZA7g7J_950vCo.ttf",
		category: "sans-serif",
	},
	{
		id: "font_2M34cdBQTvL1_fL5eK2M7tWq",
		family: "Montserrat",
		full_name: "Montserrat Light",
		postscript_name: "Montserrat-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/KbmTKVC8AZ4YYAr8tVUKMm84.png",
		style: "Montserrat-Light",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_cJD7g7J_950vCo.ttf",
		category: "sans-serif",
	},
	{
		id: "font_4HZY7eCg2D5C1xLqPa5QirFw",
		family: "Montserrat",
		full_name: "Montserrat Medium",
		postscript_name: "Montserrat-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/s4dpLXYlrgYPx781u3f3ATjK.png",
		style: "Montserrat-Medium",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_ZpC7g7J_950vCo.ttf",
		category: "sans-serif",
	},
	{
		id: "font_8_OooB3vs2NTNqLjmduDoToN",
		family: "Montserrat",
		full_name: "Montserrat SemiBold",
		postscript_name: "Montserrat-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/b4qfSBq8agrfOYgxsWDBCSUl.png",
		style: "Montserrat-SemiBold",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF7g7J_950vCo.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ETsXBZC5ffIojlvhNfpwdYs2",
		family: "Montserrat",
		full_name: "Montserrat Bold",
		postscript_name: "Montserrat-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/DykW5AWFs0hu7zT9b5uqyZ2Q.png",
		style: "Montserrat-Bold",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_dJE7g7J_950vCo.ttf",
		category: "sans-serif",
	},
	{
		id: "font_rlDOsHk3_nlpnzO1QHxJ7lpE",
		family: "Montserrat",
		full_name: "Montserrat ExtraBold",
		postscript_name: "Montserrat-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/PuoDEGtOcNPHYdJj_wG9rYdn.png",
		style: "Montserrat-ExtraBold",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H7g7J_950vCo.ttf",
		category: "sans-serif",
	},
	{
		id: "font_7ywnCIOGrJRy0YOEgyaWPPtm",
		family: "Montserrat",
		full_name: "Montserrat Black",
		postscript_name: "Montserrat-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/bBx8wfKE5uB3RlD_0SXps7CK.png",
		style: "Montserrat-Black",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG7g7J_950vCo.ttf",
		category: "sans-serif",
	},
	{
		id: "font_cQQgdF93HWZq8Q9GumCyp5He",
		family: "Montserrat",
		full_name: "Montserrat Thin Italic",
		postscript_name: "Montserrat-ThinItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/CMY1taj_seWGK0rvenHyPWTa.png",
		style: "Montserrat-ThinItalic",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUOjIg1_i6t8kCHKm459WxZqi7j0dJ9pTOi.ttf",
		category: "sans-serif",
	},
	{
		id: "font__kuuJ1gHg6WSIlpMYn4fZwfN",
		family: "Montserrat",
		full_name: "Montserrat ExtraLight Italic",
		postscript_name: "Montserrat-ExtraLightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/CEg1BcFpMiOBJ_jc6fCzKNH3.png",
		style: "Montserrat-ExtraLightItalic",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZBg_D-_xxrCq7qg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_WkjPtZNgmWCehsT8rdYP6Qqy",
		family: "Montserrat",
		full_name: "Montserrat Light Italic",
		postscript_name: "Montserrat-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/PAfCy65ZODiV5XekNEQST6YU.png",
		style: "Montserrat-LightItalic",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZYgzD-_xxrCq7qg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_t7SwZ9Ig915ag3k2JIxIrUox",
		family: "Montserrat",
		full_name: "Montserrat Regular",
		postscript_name: "Montserrat-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/KogJudbg1TyewdhCWQdN0Yfx.png",
		style: "Montserrat-Regular",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm45xW5rygbi49c.ttf",
		category: "sans-serif",
	},
	{
		id: "font_qXSpPcqtUJuX2JrpyQuWOP5O",
		family: "Montserrat",
		full_name: "Montserrat Italic",
		postscript_name: "Montserrat-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/CHPqIx1OwNpGjzf_aWIyTtu1.png",
		style: "Montserrat-Italic",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUQjIg1_i6t8kCHKm459WxhziTn89dtpQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_BLIAm15FdnSBNaO8KTj8sX0e",
		family: "Montserrat",
		full_name: "Montserrat Medium Italic",
		postscript_name: "Montserrat-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/MqJVvkaZR2TyBTNZU9zTm5_e.png",
		style: "Montserrat-MediumItalic",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZOg3D-_xxrCq7qg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_hBJV0doQV2RAfNfabj_2kk7S",
		family: "Montserrat",
		full_name: "Montserrat SemiBold Italic",
		postscript_name: "Montserrat-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/4LYxFfYYcw2SYoNH8_YpFtvY.png",
		style: "Montserrat-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZFgrD-_xxrCq7qg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_rGee9i5o3URivLb0qMG9gETp",
		family: "Montserrat",
		full_name: "Montserrat Bold Italic",
		postscript_name: "Montserrat-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/nZBch_th6U1HYaBrK3wEVnWE.png",
		style: "Montserrat-BoldItalic",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZcgvD-_xxrCq7qg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_xEjsV90S0Wd4dGKt1OZmNe0B",
		family: "Montserrat",
		full_name: "Montserrat ExtraBold Italic",
		postscript_name: "Montserrat-ExtraBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/QV5fUyKYHVkhVloCyvJu9Hx_.png",
		style: "Montserrat-ExtraBoldItalic",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZbgjD-_xxrCq7qg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_3dp39P9TwsYvip8OhboTH2GY",
		family: "Montserrat",
		full_name: "Montserrat Black Italic",
		postscript_name: "Montserrat-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/nGclpcwdSHqiHeFV3u5DoDpG.png",
		style: "Montserrat-BlackItalic",
		url: "https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZSgnD-_xxrCq7qg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_J5xJbQk919efXmfdF7EKmhmC",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro ExtraLight",
		postscript_name: "SourceSansPro-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Ik7SNBs4rf9dqeG5C2FzD3qx.png",
		style: "SourceSansPro-ExtraLight",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_AkB1v_8CGxg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_kkv5ipgb9qYfmVOxfWhh9uZ8",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro Light",
		postscript_name: "SourceSansPro-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/cVmR8KYbx4QzBRAMSPZLB9F6.png",
		style: "SourceSansPro-Light",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zAkB1v_8CGxg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_bYsICfj080zUBRVAWLhx3wvO",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro SemiBold",
		postscript_name: "SourceSansPro-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/IftDUqG7jqgbWSkoTqO8lgIj.png",
		style: "SourceSansPro-SemiBold",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkB1v_8CGxg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_jDi1JqB8DMJuRQw4lVKoiHlz",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro Bold",
		postscript_name: "SourceSansPro-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/CdSDjFOdPYV54FFMQUiqxnS_.png",
		style: "SourceSansPro-Bold",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkB1v_8CGxg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_AUYS2FZX0ijUuvcGwJadaA61",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro Black",
		postscript_name: "SourceSansPro-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/_xhwIBLw0dHbKMltae_0snis.png",
		style: "SourceSansPro-Black",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nAkB1v_8CGxg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_hAclxjHmvnmWMu72SrQagGsi",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro ExtraLight Italic",
		postscript_name: "SourceSansPro-ExtraLightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/lG4J57IM6hjqlc6e2hhGjN4R.png",
		style: "SourceSansPro-ExtraLightItalic",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokRdr3cWWxg40.ttf",
		category: "sans-serif",
	},
	{
		id: "font_1vZLmzjbk9jh7F1n1kq7THt3",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro Light Italic",
		postscript_name: "SourceSansPro-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/HfU6Ed6Ci0yf0KxYz1_jEb_X.png",
		style: "SourceSansPro-LightItalic",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkhdr3cWWxg40.ttf",
		category: "sans-serif",
	},
	{
		id: "font_eUD2SYSMqCgVyKTafDmiHAjZ",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro Regular",
		postscript_name: "SourceSansPro-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/MWSczRVQz5GLgA0nmk2BoS2D.png",
		style: "SourceSansPro-Regular",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xK3dSBYKcSV-LCoeQqfX1RYOo3aP6TkmDZz9g.ttf",
		category: "sans-serif",
	},
	{
		id: "font_kuTuCOGELNtlTRoGmfnXRhoM",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro Italic",
		postscript_name: "SourceSansPro-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/H8pcs1hVMh0GcISDqNc7gBK3.png",
		style: "SourceSansPro-Italic",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPa7gujNj9tmf.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ybc6_s58CYDaf194TnLts7Uk",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro SemiBold Italic",
		postscript_name: "SourceSansPro-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/7YzR3b9tAwrGZ9RuX5Sh4_FN.png",
		style: "SourceSansPro-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lBdr3cWWxg40.ttf",
		category: "sans-serif",
	},
	{
		id: "font_6X9OZn4QRaOhDuKmoPDJzOPG",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro Bold Italic",
		postscript_name: "SourceSansPro-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/npDAUVKAwn2Hmz7HsvHKsQ61.png",
		style: "SourceSansPro-BoldItalic",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclRdr3cWWxg40.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Uzu5qMxWd87w72HIVZtUzPQf",
		family: "Source Sans Pro",
		full_name: "Source Sans Pro Black Italic",
		postscript_name: "SourceSansPro-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/plUHCcITheB0aNNguaRIIZJE.png",
		style: "SourceSansPro-BlackItalic",
		url: "https://fonts.gstatic.com/s/sourcesanspro/v18/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklxdr3cWWxg40.ttf",
		category: "sans-serif",
	},
	{
		id: "font_j6GAHDUF0sIRCdRinVKrVw5C",
		family: "Signika",
		full_name: "Signika Light",
		postscript_name: "Signika-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/TOzEFki6FvjsRBY1CORRID_C.png",
		style: "Signika-Light",
		url: "https://fonts.gstatic.com/s/signika/v15/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tIJHJbGhs_cfKe1.ttf",
		category: "sans-serif",
	},
	{
		id: "font_DQibzbdhJLW51P6bCZcgn19C",
		family: "Signika",
		full_name: "Signika Medium",
		postscript_name: "Signika-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/VJuNrG7HhqlmwHz2D2Hbq1lf.png",
		style: "Signika-Medium",
		url: "https://fonts.gstatic.com/s/signika/v15/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tJlHJbGhs_cfKe1.ttf",
		category: "sans-serif",
	},
	{
		id: "font_UCJbvOkLNnpfgdwMQhk5SKts",
		family: "Signika",
		full_name: "Signika SemiBold",
		postscript_name: "Signika-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/sAuP3yErh8_dB8nm9ka05TNY.png",
		style: "Signika-SemiBold",
		url: "https://fonts.gstatic.com/s/signika/v15/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tKJG5bGhs_cfKe1.ttf",
		category: "sans-serif",
	},
	{
		id: "font_e3I2hnqy4B6GvRX6fGaVB6Rk",
		family: "Signika",
		full_name: "Signika Bold",
		postscript_name: "Signika-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/TrZqwT8n7gt96u7E_HOEfEzx.png",
		style: "Signika-Bold",
		url: "https://fonts.gstatic.com/s/signika/v15/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tKwG5bGhs_cfKe1.ttf",
		category: "sans-serif",
	},
	{
		id: "font_4fAkmsQ7o8clH9w1ZC3imr04",
		family: "Signika",
		full_name: "Signika Regular",
		postscript_name: "Signika-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/PEplNp98Z3VLgmVihSTubwMl.png",
		style: "Signika-Regular",
		url: "https://fonts.gstatic.com/s/signika/v15/vEFO2_JTCgwQ5ejvMV0O96D01E8J0tJXHJbGhs_cfKe1.ttf",
		category: "sans-serif",
	},
	{
		id: "font_m6_XY8uKNu3G08bf6ovcqtxj",
		family: "Slabo 27px",
		full_name: "Slabo 27px Regular",
		postscript_name: "Slabo27px-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/EcutSK_LfDxe0b6noczdPPJh.png",
		style: "Slabo27px-Regular",
		url: "https://fonts.gstatic.com/s/slabo27px/v7/mFT0WbgBwKPR_Z4hGN2qsxgJ1EJ7i90.ttf",
		category: "serif",
	},
	{
		id: "font_pUwsp5OAItgcWc3UStprJH7k",
		family: "Roboto",
		full_name: "Roboto Thin",
		postscript_name: "Roboto-Thin",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/_dDgzWpViB_PNOOfyEaHm9qW.png",
		style: "Roboto-Thin",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf",
		category: "sans-serif",
	},
	{
		id: "font_pGBFZy5cz8mDhXTf3v9mcjM0",
		family: "Roboto",
		full_name: "Roboto Light",
		postscript_name: "Roboto-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/EaZTBm3rX55nZjFl0YiNfYKq.png",
		style: "Roboto-Light",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_QfVXALZvaxiLuE_5Al0vDmzn",
		family: "Roboto",
		full_name: "Roboto Medium",
		postscript_name: "Roboto-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/2CKyJuM2fuGuMpT3v9uaDE7V.png",
		style: "Roboto-Medium",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ZBC5vF7lauVUMPAhr7wRmpJA",
		family: "Roboto",
		full_name: "Roboto Bold",
		postscript_name: "Roboto-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/16pni8DfciSZKQGmaMAy4HN3.png",
		style: "Roboto-Bold",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_pyWPQRw8lXEtN9h736VatRb0",
		family: "Roboto",
		full_name: "Roboto Black",
		postscript_name: "Roboto-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/FmvfwB4UOJSOsw74DAYJT7AI.png",
		style: "Roboto-Black",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_wzWn6kSmp49gZ5EE191z8E4p",
		family: "Roboto",
		full_name: "Roboto Thin Italic",
		postscript_name: "Roboto-ThinItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/qULFKRoUIDXPFM0p7Ua5i7ge.png",
		style: "Roboto-ThinItalic",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf",
		category: "sans-serif",
	},
	{
		id: "font_e_q_zsscPAjcqrmABdJ4s9Df",
		family: "Roboto",
		full_name: "Roboto Light Italic",
		postscript_name: "Roboto-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/IWypoh6kwmhQNMQkggIVnRff.png",
		style: "Roboto-LightItalic",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf",
		category: "sans-serif",
	},
	{
		id: "font_GnsQL5DFYrLLAFwOOnmaIwt6",
		family: "Roboto",
		full_name: "Roboto Regular",
		postscript_name: "Roboto-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/FzQ8nT_dmynGs1_VUqM27zAr.png",
		style: "Roboto-Regular",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_w9h1yNuSDvwVj_Kq0TjhnsiL",
		family: "Roboto",
		full_name: "Roboto Italic",
		postscript_name: "Roboto-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/FqOFRduxCCMhsOiTXxfFYUqU.png",
		style: "Roboto-Italic",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Q_DnaIvQqnksXE1ImVdJluQ1",
		family: "Roboto",
		full_name: "Roboto Medium Italic",
		postscript_name: "Roboto-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/lxvTfLxUHsqg1gpr5cOq8ObY.png",
		style: "Roboto-MediumItalic",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf",
		category: "sans-serif",
	},
	{
		id: "font_PK9I5xhpFA99qVDb9qFNt8Ri",
		family: "Roboto",
		full_name: "Roboto Bold Italic",
		postscript_name: "Roboto-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/UdzhqT2wL9zqjGbHOjI0nQv2.png",
		style: "Roboto-BoldItalic",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf",
		category: "sans-serif",
	},
	{
		id: "font_3XhUEZpN10Z6RD5Pzanpem9p",
		family: "Roboto",
		full_name: "Roboto Black Italic",
		postscript_name: "Roboto-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/XRCpmsCcioSTBYVnuQkr10p1.png",
		style: "Roboto-BlackItalic",
		url: "https://fonts.gstatic.com/s/roboto/v29/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf",
		category: "sans-serif",
	},
	{
		id: "font_NA4REzNnW21VUQmbi401waF3",
		family: "Oswald",
		full_name: "Oswald ExtraLight",
		postscript_name: "Oswald-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/vHjCzKqPE30Lfgwa5jCZMPlf.png",
		style: "Oswald-ExtraLight",
		url: "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs13FvgUFoZAaRliE.ttf",
		category: "sans-serif",
	},
	{
		id: "font_RPNqoVn7JMxqWM4vLDC4QNjg",
		family: "Oswald",
		full_name: "Oswald Light",
		postscript_name: "Oswald-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/63zSAlkaIibgTAeNDNaObd_q.png",
		style: "Oswald-Light",
		url: "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs169vgUFoZAaRliE.ttf",
		category: "sans-serif",
	},
	{
		id: "font_VODlW_owv6oulOS9ly2SO2RE",
		family: "Oswald",
		full_name: "Oswald Medium",
		postscript_name: "Oswald-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/9qUmQUXz8roVMK4muvepKVXq.png",
		style: "Oswald-Medium",
		url: "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs18NvgUFoZAaRliE.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Qa_0RxZ0t4AskKw5qMT6SRGg",
		family: "Oswald",
		full_name: "Oswald SemiBold",
		postscript_name: "Oswald-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/nMOcyaQrdp8e1ks5Y2vsGYL7.png",
		style: "Oswald-SemiBold",
		url: "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1y9ogUFoZAaRliE.ttf",
		category: "sans-serif",
	},
	{
		id: "font_8PcyRRmMsbxL8nkpSgb53J6_",
		family: "Oswald",
		full_name: "Oswald Bold",
		postscript_name: "Oswald-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/hRCgOEO7GG0fmoMRXadwf8CK.png",
		style: "Oswald-Bold",
		url: "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZogUFoZAaRliE.ttf",
		category: "sans-serif",
	},
	{
		id: "font_6jp_PMcSA9HeZx2BOf_NypmV",
		family: "Oswald",
		full_name: "Oswald Regular",
		postscript_name: "Oswald-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/JETbxHwkXRcjSZC0zjXMis7J.png",
		style: "Oswald-Regular",
		url: "https://fonts.gstatic.com/s/oswald/v40/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf",
		category: "sans-serif",
	},
	{
		id: "font_oWecKuLWPcBh3RIRGnn7MXLh",
		family: "PT Sans",
		full_name: "PT Sans Bold",
		postscript_name: "PTSans-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/rcfyXUOXOo_RzaOiRvdmCHQc.png",
		style: "PTSans-Bold",
		url: "https://fonts.gstatic.com/s/ptsans/v12/jizfRExUiTo99u79B_mh4OmnLD0Z4zM.ttf",
		category: "sans-serif",
	},
	{
		id: "font_XiTUmZE139HZUH_L8qTxaog5",
		family: "PT Sans",
		full_name: "PT Sans Regular",
		postscript_name: "PTSans-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/waQBFKhjnqBzwC7QpU2RAaMw.png",
		style: "PTSans-Regular",
		url: "https://fonts.gstatic.com/s/ptsans/v12/jizaRExUiTo99u79P0WOxOGMMDQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_oOswv4IFH4un1TjA_qQmNIho",
		family: "PT Sans",
		full_name: "PT Sans Italic",
		postscript_name: "PTSans-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/qMWCkQKitgUgSaKcLYUiv_db.png",
		style: "PTSans-Italic",
		url: "https://fonts.gstatic.com/s/ptsans/v12/jizYRExUiTo99u79D0eEwMOJIDQA-g.ttf",
		category: "sans-serif",
	},
	{
		id: "font_D35X5MeYEghiGGjU6SD1aVXD",
		family: "PT Sans",
		full_name: "PT Sans Bold Italic",
		postscript_name: "PTSans-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/CwmVtZXGCBLbyX8ODQ9H3rbn.png",
		style: "PTSans-BoldItalic",
		url: "https://fonts.gstatic.com/s/ptsans/v12/jizdRExUiTo99u79D0e8fOytKB8c8zMrig.ttf",
		category: "sans-serif",
	},
	{
		id: "font_WpWgT5XmkQbVPOO7Ag3GEnxK",
		family: "Merriweather",
		full_name: "Merriweather Light",
		postscript_name: "Merriweather-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/AyJEI7XDz2Riqau2pV2XtBVG.png",
		style: "Merriweather-Light",
		url: "https://fonts.gstatic.com/s/merriweather/v27/u-4n0qyriQwlOrhSvowK_l521wRpX837pvjxPA.ttf",
		category: "serif",
	},
	{
		id: "font_hABSkptSSRoqZDCU2K_0ZaCt",
		family: "Merriweather",
		full_name: "Merriweather Bold",
		postscript_name: "Merriweather-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/YwoLaX9REO4wNx8fVgE3OJ3o.png",
		style: "Merriweather-Bold",
		url: "https://fonts.gstatic.com/s/merriweather/v27/u-4n0qyriQwlOrhSvowK_l52xwNpX837pvjxPA.ttf",
		category: "serif",
	},
	{
		id: "font_EL2B3mXMTQnfI_qEUh6JoS49",
		family: "Merriweather",
		full_name: "Merriweather Black",
		postscript_name: "Merriweather-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/hfHKQxy61jEabR_C0Gjkwx2x.png",
		style: "Merriweather-Black",
		url: "https://fonts.gstatic.com/s/merriweather/v27/u-4n0qyriQwlOrhSvowK_l52_wFpX837pvjxPA.ttf",
		category: "serif",
	},
	{
		id: "font_AYZwQWw5o1wKqQrfsU3EefzQ",
		family: "Merriweather",
		full_name: "Merriweather Light Italic",
		postscript_name: "Merriweather-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/JQk7oKKgm0pINf0QzfoV2P2c.png",
		style: "Merriweather-LightItalic",
		url: "https://fonts.gstatic.com/s/merriweather/v27/u-4l0qyriQwlOrhSvowK_l5-eR7lXcf_hP3hPGWH.ttf",
		category: "serif",
	},
	{
		id: "font_gvHHavZ6byPiGHfBkiEGVYVn",
		family: "Merriweather",
		full_name: "Merriweather Regular",
		postscript_name: "Merriweather-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/x_fBxcM0Zs_ulsy6gwtLiMmC.png",
		style: "Merriweather-Regular",
		url: "https://fonts.gstatic.com/s/merriweather/v27/u-440qyriQwlOrhSvowK_l5OeyxNV-bnrw.ttf",
		category: "serif",
	},
	{
		id: "font_LfXaVv95_D3XQMG24DoImMxf",
		family: "Merriweather",
		full_name: "Merriweather Italic",
		postscript_name: "Merriweather-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/4fLN3H9VZ40Z7DrL4021ikrZ.png",
		style: "Merriweather-Italic",
		url: "https://fonts.gstatic.com/s/merriweather/v27/u-4m0qyriQwlOrhSvowK_l5-eSZJdeP3r-Ho.ttf",
		category: "serif",
	},
	{
		id: "font_E69s5HwvfU5k1Roi9akDNg25",
		family: "Merriweather",
		full_name: "Merriweather Bold Italic",
		postscript_name: "Merriweather-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/WXSkToOIWB1nT1JFdvi6dpQ9.png",
		style: "Merriweather-BoldItalic",
		url: "https://fonts.gstatic.com/s/merriweather/v27/u-4l0qyriQwlOrhSvowK_l5-eR71Wsf_hP3hPGWH.ttf",
		category: "serif",
	},
	{
		id: "font_bHyEd5PnO0QV36qn7qs4UGCk",
		family: "Merriweather",
		full_name: "Merriweather Black Italic",
		postscript_name: "Merriweather-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/0TPcUd9QYw_LsWZZkIkn31fT.png",
		style: "Merriweather-BlackItalic",
		url: "https://fonts.gstatic.com/s/merriweather/v27/u-4l0qyriQwlOrhSvowK_l5-eR7NWMf_hP3hPGWH.ttf",
		category: "serif",
	},
	{
		id: "font_jTIaVrShJZbVGjr4k32f883q",
		family: "Prompt",
		full_name: "Prompt Thin",
		postscript_name: "Prompt-Thin",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/APFgoeTsftFqL7USj7yNnHhA.png",
		style: "Prompt-Thin",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_9XJnvUD7dzB2CA9oYREcjeo0k.ttf",
		category: "sans-serif",
	},
	{
		id: "font_gdPbNz2jorm4q9cbW6sQnlwU",
		family: "Prompt",
		full_name: "Prompt ExtraLight",
		postscript_name: "Prompt-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/i3G2C6FROnC8RmU8Jf7n2heX.png",
		style: "Prompt-ExtraLight",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_8XJnvUD7dzB2Cr_s4bmkvc5Q9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_pKs5KieDQnlmOOvHONSkLpmN",
		family: "Prompt",
		full_name: "Prompt Light",
		postscript_name: "Prompt-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/p6aXsZ2uw9aTbDkQtBaoY8by.png",
		style: "Prompt-Light",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_8XJnvUD7dzB2Cy_g4bmkvc5Q9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_sn9Xv8Dlm1GW4rkNfkIyvutw",
		family: "Prompt",
		full_name: "Prompt Medium",
		postscript_name: "Prompt-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/LgT444f_QW3Pd7D27RUaLJhs.png",
		style: "Prompt-Medium",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_8XJnvUD7dzB2Ck_k4bmkvc5Q9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_wgZ18dUNQN6e69tgla7ViZE5",
		family: "Prompt",
		full_name: "Prompt SemiBold",
		postscript_name: "Prompt-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Moa1SNvMLdVj6jQ7IhUvZTFx.png",
		style: "Prompt-SemiBold",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_8XJnvUD7dzB2Cv_44bmkvc5Q9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_fqacZEA5p9Wqw8cALtGn3Mu1",
		family: "Prompt",
		full_name: "Prompt Bold",
		postscript_name: "Prompt-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/_OQn6X9m5uwa9HWNfcCtnL4O.png",
		style: "Prompt-Bold",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_8XJnvUD7dzB2C2_84bmkvc5Q9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Ip2K3RXQI8TBFAw0kmEC5ioc",
		family: "Prompt",
		full_name: "Prompt ExtraBold",
		postscript_name: "Prompt-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/squbIVYWCaDYJCZdu84JGota.png",
		style: "Prompt-ExtraBold",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_8XJnvUD7dzB2Cx_w4bmkvc5Q9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Rgbz7qQAp_k_SgKU5Cbp8jQq",
		family: "Prompt",
		full_name: "Prompt Black",
		postscript_name: "Prompt-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/opbLP6yxW5zjtz2Cb64bMj_4.png",
		style: "Prompt-Black",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_8XJnvUD7dzB2C4_04bmkvc5Q9dw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_lLy9Nm4B0FPKvug4C0dBhbeR",
		family: "Prompt",
		full_name: "Prompt Thin Italic",
		postscript_name: "Prompt-ThinItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/V3lgO5rJZw7BZRvvbKpx4tVA.png",
		style: "Prompt-ThinItalic",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_7XJnvUD7dzB2KZeJ8TkMBf50kbiM.ttf",
		category: "sans-serif",
	},
	{
		id: "font_KVeW2KXyEC3eagqB4wDBTiHX",
		family: "Prompt",
		full_name: "Prompt ExtraLight Italic",
		postscript_name: "Prompt-ExtraLightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/zoi2Vhaf0yVYNtVeF2SBbzhx.png",
		style: "Prompt-ExtraLightItalic",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_6XJnvUD7dzB2KZeLQb2MrUZEtdzow.ttf",
		category: "sans-serif",
	},
	{
		id: "font_GhRKaPTIfAg9TnM1JLPpmlZE",
		family: "Prompt",
		full_name: "Prompt Light Italic",
		postscript_name: "Prompt-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/SJvjwtWhCwZ3c6Xdf5_eo2xo.png",
		style: "Prompt-LightItalic",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_6XJnvUD7dzB2KZeK0bGMrUZEtdzow.ttf",
		category: "sans-serif",
	},
	{
		id: "font_waLIekm6b6cddloN8G3_LgUF",
		family: "Prompt",
		full_name: "Prompt Regular",
		postscript_name: "Prompt-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/hi4paflrQDRH7GUNdeSbomi6.png",
		style: "Prompt-Regular",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W__XJnvUD7dzB26Z9AcZkIzeg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_T1ubZM3iPdiQQ2JLsaRUzIt3",
		family: "Prompt",
		full_name: "Prompt Italic",
		postscript_name: "Prompt-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/qZOzUJWfR_xGTID_44QapIVI.png",
		style: "Prompt-Italic",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_9XJnvUD7dzB2KZdoYREcjeo0k.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Euur7CMOGTEblclTntI17mtV",
		family: "Prompt",
		full_name: "Prompt Medium Italic",
		postscript_name: "Prompt-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/9jhYWLW03bZWFMF8Ebou3wPA.png",
		style: "Prompt-MediumItalic",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_6XJnvUD7dzB2KZeLsbWMrUZEtdzow.ttf",
		category: "sans-serif",
	},
	{
		id: "font_mn1Sw7gxrskgVPrO8jQJjtdP",
		family: "Prompt",
		full_name: "Prompt SemiBold Italic",
		postscript_name: "Prompt-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/BRDp4gsH1EIjBaNmvOwuobXt.png",
		style: "Prompt-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_6XJnvUD7dzB2KZeLAamMrUZEtdzow.ttf",
		category: "sans-serif",
	},
	{
		id: "font_6OMMrF8jwa224ZqaeCpc6pF5",
		family: "Prompt",
		full_name: "Prompt Bold Italic",
		postscript_name: "Prompt-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/yDhfcTH5It2H6ajHxh3xgFLp.png",
		style: "Prompt-BoldItalic",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_6XJnvUD7dzB2KZeKka2MrUZEtdzow.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ppDTZMQWJ6_zng1dS3Lwsg6j",
		family: "Prompt",
		full_name: "Prompt ExtraBold Italic",
		postscript_name: "Prompt-ExtraBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/0oLLLyc2_CESlE8sz8sVHshd.png",
		style: "Prompt-ExtraBoldItalic",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_6XJnvUD7dzB2KZeK4aGMrUZEtdzow.ttf",
		category: "sans-serif",
	},
	{
		id: "font_0ILD8Zi5j4HWG2SsNS89Miwr",
		family: "Prompt",
		full_name: "Prompt Black Italic",
		postscript_name: "Prompt-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/J3UybkkqzJhqekpKXNz6qen4.png",
		style: "Prompt-BlackItalic",
		url: "https://fonts.gstatic.com/s/prompt/v5/-W_6XJnvUD7dzB2KZeKcaWMrUZEtdzow.ttf",
		category: "sans-serif",
	},
	{
		id: "font_dZFQnESVRq3kq69HHBQUx8h4",
		family: "Comic Neue",
		full_name: "Comic Neue Light",
		postscript_name: "ComicNeue-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/1sOjxBV5WX_DfxbtShjSqAAX.png",
		style: "ComicNeue-Light",
		url: "https://fonts.gstatic.com/s/comicneue/v3/4UaErEJDsxBrF37olUeD_wHLwpteLwtHJlc.ttf",
		category: "handwriting",
	},
	{
		id: "font_j6W4h_90J6ZRAA47DQcJq8FA",
		family: "Comic Neue",
		full_name: "Comic Neue Bold",
		postscript_name: "ComicNeue-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/vMjP03QdzXfgHQc2AyPUQvzW.png",
		style: "ComicNeue-Bold",
		url: "https://fonts.gstatic.com/s/comicneue/v3/4UaErEJDsxBrF37olUeD_xHMwpteLwtHJlc.ttf",
		category: "handwriting",
	},
	{
		id: "font_UmiRIJNFcqgkIjr0f7oMqG_y",
		family: "Comic Neue",
		full_name: "Comic Neue Light Italic",
		postscript_name: "ComicNeue-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/AJpE33Lk1GUfTU1Wia7wAKiy.png",
		style: "ComicNeue-LightItalic",
		url: "https://fonts.gstatic.com/s/comicneue/v3/4UaarEJDsxBrF37olUeD96_RTplUKylCNlcw_Q.ttf",
		category: "handwriting",
	},
	{
		id: "font_W4ldfa1VjribjiMzOb3TqOPv",
		family: "Comic Neue",
		full_name: "Comic Neue Regular",
		postscript_name: "ComicNeue-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/SSvMlM_7Z06DnF_NHLWfZf5O.png",
		style: "ComicNeue-Regular",
		url: "https://fonts.gstatic.com/s/comicneue/v3/4UaHrEJDsxBrF37olUeDx63j5pN1MwI.ttf",
		category: "handwriting",
	},
	{
		id: "font_Hv_j_wtGDV9QkErpC8ekX2oi",
		family: "Comic Neue",
		full_name: "Comic Neue Italic",
		postscript_name: "ComicNeue-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/NuA3pO0Jpfd9szrt67erpZKU.png",
		style: "ComicNeue-Italic",
		url: "https://fonts.gstatic.com/s/comicneue/v3/4UaFrEJDsxBrF37olUeD96_p4rFwIwJePw.ttf",
		category: "handwriting",
	},
	{
		id: "font_hDGmlA9Rhbbj1fEAyUXm5M2K",
		family: "Comic Neue",
		full_name: "Comic Neue Bold Italic",
		postscript_name: "ComicNeue-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/8tAJI1NhnFvENEQwWd06LlCr.png",
		style: "ComicNeue-BoldItalic",
		url: "https://fonts.gstatic.com/s/comicneue/v3/4UaarEJDsxBrF37olUeD96_RXp5UKylCNlcw_Q.ttf",
		category: "handwriting",
	},
	{
		id: "font_e7LI1Mt95v9VTvV1bWk8hZW_",
		family: "Cookie",
		full_name: "Cookie Regular",
		postscript_name: "Cookie-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/ePxAxGF7SVF_t27gsBP0W1iO.png",
		style: "Cookie-Regular",
		url: "https://fonts.gstatic.com/s/cookie/v12/syky-y18lb0tSbfNlQCT9tPdpw.ttf",
		category: "handwriting",
	},
	{
		id: "font_INYS65VoyS2NXJOLd2vD8IRH",
		family: "Corinthia",
		full_name: "Corinthia Bold",
		postscript_name: "Corinthia-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Tl3SBPhORcK1M2mi4RvameUi.png",
		style: "Corinthia-Bold",
		url: "https://fonts.gstatic.com/s/corinthia/v7/wEO6EBrAnchaJyPMHE097d8v1GAbgbLXQA.ttf",
		category: "handwriting",
	},
	{
		id: "font_flBBcUR37wNmX53sjjkfQp6C",
		family: "Corinthia",
		full_name: "Corinthia Regular",
		postscript_name: "Corinthia-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/e15Ncz56WtFWuOOfoDzByk2F.png",
		style: "Corinthia-Regular",
		url: "https://fonts.gstatic.com/s/corinthia/v7/wEO_EBrAnchaJyPMHE0FUfAL3EsHiA.ttf",
		category: "handwriting",
	},
	{
		id: "font_TgU9ziebqOX3jmSFOpvjF1pA",
		family: "Work Sans",
		full_name: "Work Sans Thin",
		postscript_name: "WorkSans-Thin",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/xvBPXRDNlFzUWfXChVydjuZU.png",
		style: "WorkSans-Thin",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nWNigDp6_cOyA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_wClq0xgQtHggASOc9SV1bFCA",
		family: "Work Sans",
		full_name: "Work Sans ExtraLight",
		postscript_name: "WorkSans-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/zTalGLy34no7GOSZEQTrruUk.png",
		style: "WorkSans-ExtraLight",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nXNigDp6_cOyA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_4d8a5a8xgJNuYdLhQarOZHnO",
		family: "Work Sans",
		full_name: "Work Sans Light",
		postscript_name: "WorkSans-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/XOn1vdmpas2wXUdpePVmQwIH.png",
		style: "WorkSans-Light",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32KxfXNigDp6_cOyA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_1d8_ASsUAyLwxiNSQsvfYNxI",
		family: "Work Sans",
		full_name: "Work Sans Medium",
		postscript_name: "WorkSans-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/UYFYK8wfLsYLMvk0xa7vMr49.png",
		style: "WorkSans-Medium",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K3vXNigDp6_cOyA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_7nvRDWoy9gguai6rAQgM6LFb",
		family: "Work Sans",
		full_name: "Work Sans SemiBold",
		postscript_name: "WorkSans-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/zbKEBkNn6QBiEJlEPy4cmZGc.png",
		style: "WorkSans-SemiBold",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K5fQNigDp6_cOyA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_S3_FGp3j7gY4lo8DboJWLFK6",
		family: "Work Sans",
		full_name: "Work Sans Bold",
		postscript_name: "WorkSans-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/l6HcEIHNC1AFw5_TlR3bn6MP.png",
		style: "WorkSans-Bold",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K67QNigDp6_cOyA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_rgkWgwCyTCXc84VFl1v9HbYA",
		family: "Work Sans",
		full_name: "Work Sans ExtraBold",
		postscript_name: "WorkSans-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/3ekXACh_EwBaZgU_tCyVJJDs.png",
		style: "WorkSans-ExtraBold",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K8nQNigDp6_cOyA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_RUErd3z0zALNq3RGHzFNsCoC",
		family: "Work Sans",
		full_name: "Work Sans Black",
		postscript_name: "WorkSans-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/SyHziAYJxstGq8SxNFg2vZfj.png",
		style: "WorkSans-Black",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K-DQNigDp6_cOyA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_k26_9PlTMCI7UGasIcGCHzAH",
		family: "Work Sans",
		full_name: "Work Sans Regular",
		postscript_name: "WorkSans-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/47IlhI8BjyPQJhyMSv8etby7.png",
		style: "WorkSans-Regular",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_XEMKWADZi7LxxqNrcnmzZhRQ",
		family: "Work Sans",
		full_name: "Work Sans Thin Italic",
		postscript_name: "WorkSans-ThinItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/v9TqO_ic0NcdeBPAPV7u_o9t.png",
		style: "WorkSans-ThinItalic",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3moJo43ZKyDSQQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_GV008XdFkCh2Q8JnHEZb71td",
		family: "Work Sans",
		full_name: "Work Sans ExtraLight Italic",
		postscript_name: "WorkSans-ExtraLightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/ixY2darw3Poeig2WMNtIA1at.png",
		style: "WorkSans-ExtraLightItalic",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmsJo43ZKyDSQQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_x5x6CgVS9n67lBiErorI3f36",
		family: "Work Sans",
		full_name: "Work Sans Light Italic",
		postscript_name: "WorkSans-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/C1HkDO6Jp0VGmgyKIGMkDN7F.png",
		style: "WorkSans-LightItalic",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUgGsJo43ZKyDSQQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_PWLKLaX0lJcC95U7tmQyO7Nw",
		family: "Work Sans",
		full_name: "Work Sans Italic",
		postscript_name: "WorkSans-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/DfYuGtd9yAxIM9fjNM1hYadm.png",
		style: "WorkSans-Italic",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU3msJo43ZKyDSQQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_tz_W5W0JR9nZ2i89AHkOR2Gb",
		family: "Work Sans",
		full_name: "Work Sans Medium Italic",
		postscript_name: "WorkSans-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/S2GDK7jX4XSTBEANMLd4c1e3.png",
		style: "WorkSans-MediumItalic",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGU7GsJo43ZKyDSQQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_knSqB1XtI4oPlocEziCdBCAz",
		family: "Work Sans",
		full_name: "Work Sans SemiBold Italic",
		postscript_name: "WorkSans-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/skZe3Ie4JbMKuzThgSBGY4bv.png",
		style: "WorkSans-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUAGwJo43ZKyDSQQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ExiLyWKhMmSTE_Lp_lttE9WP",
		family: "Work Sans",
		full_name: "Work Sans Bold Italic",
		postscript_name: "WorkSans-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/BEYmcAySfhVDxNLPfa9RlFlS.png",
		style: "WorkSans-BoldItalic",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUOWwJo43ZKyDSQQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_HVVeFIHKKe0qeNobjJJ6w_KQ",
		family: "Work Sans",
		full_name: "Work Sans ExtraBold Italic",
		postscript_name: "WorkSans-ExtraBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/C4hxFLsT_1FQ5ZKMDvQCDNrB.png",
		style: "WorkSans-ExtraBoldItalic",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUXmwJo43ZKyDSQQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_clWzjpGFZrsROhw9mU6vHths",
		family: "Work Sans",
		full_name: "Work Sans Black Italic",
		postscript_name: "WorkSans-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/B3a4enwiVxXT5NSKV9fVPfAZ.png",
		style: "WorkSans-BlackItalic",
		url: "https://fonts.gstatic.com/s/worksans/v13/QGY9z_wNahGAdqQ43Rh_ebrnlwyYfEPxPoGUd2wJo43ZKyDSQQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_I4QFIERzj6bciNFdVlTI_FSM",
		family: "Rubik",
		full_name: "Rubik Light",
		postscript_name: "Rubik-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/gaFYHL5Jidi1xDwe8QFKcCIt.png",
		style: "Rubik-Light",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-WYi1UE80V4bVkA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_h82OT6enlfbhIEWgXgw8MqOf",
		family: "Rubik",
		full_name: "Rubik Medium",
		postscript_name: "Rubik-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/wlz54Mh3NTxFLcZDJWCUOz65.png",
		style: "Rubik-Medium",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UE80V4bVkA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_SWsIy4fF8AKqDN6lBcA5Vkmd",
		family: "Rubik",
		full_name: "Rubik SemiBold",
		postscript_name: "Rubik-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Q5lNTyaFcW61VdaRFCpv4Wyp.png",
		style: "Rubik-SemiBold",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UE80V4bVkA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_lX240J7d5HkOL6qdDKAujOFO",
		family: "Rubik",
		full_name: "Rubik Bold",
		postscript_name: "Rubik-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/LZ9mrDeYgqEUighG8lKiRW2H.png",
		style: "Rubik-Bold",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UE80V4bVkA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_BtyQPebgAQiMIKCKa06aP02M",
		family: "Rubik",
		full_name: "Rubik ExtraBold",
		postscript_name: "Rubik-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/6WNxKgmxXFgD3CuasZVC2pb6.png",
		style: "Rubik-ExtraBold",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-h4-1UE80V4bVkA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_2CpJG7lxK3qvS8qaG_MgaNMw",
		family: "Rubik",
		full_name: "Rubik Black",
		postscript_name: "Rubik-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/JA6vOt_tgbAcx29lObX92Qlf.png",
		style: "Rubik-Black",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-ro-1UE80V4bVkA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_qZutZIl84cV9Qr_brbukL29a",
		family: "Rubik",
		full_name: "Rubik Regular",
		postscript_name: "Rubik-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/mKaKhMnjZeXENB9NRtIwucOc.png",
		style: "Rubik-Regular",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_vgsdgm44MvZwi6Od_O68GVSn",
		family: "Rubik",
		full_name: "Rubik Light Italic",
		postscript_name: "Rubik-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/o3Jtn38M3Q4e0syTKqcscDqn.png",
		style: "Rubik-LightItalic",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8sDE0UwdYPFkJ1O.ttf",
		category: "sans-serif",
	},
	{
		id: "font_VPG_aF2AmrjGXGW6E00iFtSO",
		family: "Rubik",
		full_name: "Rubik Italic",
		postscript_name: "Rubik-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/NhiRBnazZWzV4Vaz82TErYOj.png",
		style: "Rubik-Italic",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0UwdYPFkJ1O.ttf",
		category: "sans-serif",
	},
	{
		id: "font_9ackWPTu7vaY6HMST0ZYEWIT",
		family: "Rubik",
		full_name: "Rubik Medium Italic",
		postscript_name: "Rubik-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/lUkJpRbwjOLDUR84uH19RYY9.png",
		style: "Rubik-MediumItalic",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0UwdYPFkJ1O.ttf",
		category: "sans-serif",
	},
	{
		id: "font_gdRNBgyh6_s4rGJCLQtu2tJP",
		family: "Rubik",
		full_name: "Rubik SemiBold Italic",
		postscript_name: "Rubik-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/MJ7_8ludvsKNvpmGDnJ6yFqv.png",
		style: "Rubik-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFEUwdYPFkJ1O.ttf",
		category: "sans-serif",
	},
	{
		id: "font_D2X1vuddbRT0fLs_txxWhbY4",
		family: "Rubik",
		full_name: "Rubik Bold Italic",
		postscript_name: "Rubik-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/rUF5GRJnNTHzMVkre7yYWVSp.png",
		style: "Rubik-BoldItalic",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUwdYPFkJ1O.ttf",
		category: "sans-serif",
	},
	{
		id: "font_2cpjDBLIBg_lQx3FHWKiV7R6",
		family: "Rubik",
		full_name: "Rubik ExtraBold Italic",
		postscript_name: "Rubik-ExtraBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/GXDmzxve2FHdOjAOgPaADVhv.png",
		style: "Rubik-ExtraBoldItalic",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8vdFEUwdYPFkJ1O.ttf",
		category: "sans-serif",
	},
	{
		id: "font_z1DalQH30sRzzdX8B1VP92RC",
		family: "Rubik",
		full_name: "Rubik Black Italic",
		postscript_name: "Rubik-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/UCxgaAF80rdpBOJx0VLTHb2n.png",
		style: "Rubik-BlackItalic",
		url: "https://fonts.gstatic.com/s/rubik/v14/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8v0FEUwdYPFkJ1O.ttf",
		category: "sans-serif",
	},
	{
		id: "font_TjTa7gCquJieS7H8EBIxOKhl",
		family: "Ubuntu",
		full_name: "Ubuntu Light",
		postscript_name: "Ubuntu-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/cGq5QodEuFXXinjF382WUqW7.png",
		style: "Ubuntu-Light",
		url: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoC1CzTt2aMH4V_gg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_fGCQmjpFC_IOQeENIgwGKmZY",
		family: "Ubuntu",
		full_name: "Ubuntu Medium",
		postscript_name: "Ubuntu-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/kXNKYJ7jTlw7ne5Bns5vewQj.png",
		style: "Ubuntu-Medium",
		url: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCjC3Tt2aMH4V_gg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ZmMjpcvCZtOtvjETEHTbcSx9",
		family: "Ubuntu",
		full_name: "Ubuntu Bold",
		postscript_name: "Ubuntu-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/eGuHP0wMxPP9Sli_8kwjULA2.png",
		style: "Ubuntu-Bold",
		url: "https://fonts.gstatic.com/s/ubuntu/v15/4iCv6KVjbNBYlgoCxCvTt2aMH4V_gg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_dO9vO228A76UjITOgY0qgj6m",
		family: "Ubuntu",
		full_name: "Ubuntu Light Italic",
		postscript_name: "Ubuntu-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/ydybXicjWETsyUC9nzO7xS0h.png",
		style: "Ubuntu-LightItalic",
		url: "https://fonts.gstatic.com/s/ubuntu/v15/4iCp6KVjbNBYlgoKejZftWyIPYBvgpUI.ttf",
		category: "sans-serif",
	},
	{
		id: "font_JR53jiWqiA9rGRlm0wK66L1J",
		family: "Ubuntu",
		full_name: "Ubuntu Regular",
		postscript_name: "Ubuntu-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/TdS6eL3ru_gpQy8W_eGP9o9L.png",
		style: "Ubuntu-Regular",
		url: "https://fonts.gstatic.com/s/ubuntu/v15/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_tvt4gw29skrtl4LpWUt2DLv3",
		family: "Ubuntu",
		full_name: "Ubuntu Italic",
		postscript_name: "Ubuntu-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/nAecvKkkEdTbPS3VN8f9kOUj.png",
		style: "Ubuntu-Italic",
		url: "https://fonts.gstatic.com/s/ubuntu/v15/4iCu6KVjbNBYlgoKeg7znUiAFpxm.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Byc9netXkTUb6kJBp_nbHiCv",
		family: "Ubuntu",
		full_name: "Ubuntu Medium Italic",
		postscript_name: "Ubuntu-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/HgIRFT9FiQj7oNR8xiInNCrG.png",
		style: "Ubuntu-MediumItalic",
		url: "https://fonts.gstatic.com/s/ubuntu/v15/4iCp6KVjbNBYlgoKejYHtGyIPYBvgpUI.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ur9fjfbsHMakt9zJ_Lq3VojB",
		family: "Ubuntu",
		full_name: "Ubuntu Bold Italic",
		postscript_name: "Ubuntu-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/5DPe0YPKHwfk9uV02y7DAFEm.png",
		style: "Ubuntu-BoldItalic",
		url: "https://fonts.gstatic.com/s/ubuntu/v15/4iCp6KVjbNBYlgoKejZPsmyIPYBvgpUI.ttf",
		category: "sans-serif",
	},
	{
		id: "font_BGT_KXSAwxbgjFsZRo3OPi5L",
		family: "Ubuntu Condensed",
		full_name: "Ubuntu Condensed Regular",
		postscript_name: "UbuntuCondensed-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/7iuWAbFxsuAXmtsNNOCAZK_b.png",
		style: "UbuntuCondensed-Regular",
		url: "https://fonts.gstatic.com/s/ubuntucondensed/v11/u-4k0rCzjgs5J7oXnJcM_0kACGMtf-fVqvHoJXw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_ff5MkcWn6xpKH0O5IXx4GH1R",
		family: "Ubuntu Mono",
		full_name: "Ubuntu Mono Bold",
		postscript_name: "UbuntuMono-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/czWesSMGxXtdPSL8iwjO08X_.png",
		style: "UbuntuMono-Bold",
		url: "https://fonts.gstatic.com/s/ubuntumono/v10/KFO-CneDtsqEr0keqCMhbC-BL-Hyv4xGemO1.ttf",
		category: "monospace",
	},
	{
		id: "font_QKc3Rsv63RDcl0OulWmd8o26",
		family: "Ubuntu Mono",
		full_name: "Ubuntu Mono Regular",
		postscript_name: "UbuntuMono-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/eJAh2d4FHYpIvs6cv6OvIhn1.png",
		style: "UbuntuMono-Regular",
		url: "https://fonts.gstatic.com/s/ubuntumono/v10/KFOjCneDtsqEr0keqCMhbBc9AMX6lJBP.ttf",
		category: "monospace",
	},
	{
		id: "font_fOn1mnNboOHDmx4dFiepDkNA",
		family: "Ubuntu Mono",
		full_name: "Ubuntu Mono Italic",
		postscript_name: "UbuntuMono-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/ipEAi2aP2d0Mo77V809DMr5_.png",
		style: "UbuntuMono-Italic",
		url: "https://fonts.gstatic.com/s/ubuntumono/v10/KFOhCneDtsqEr0keqCMhbCc_CsHYkYBPY3o.ttf",
		category: "monospace",
	},
	{
		id: "font_PWFTMM1fGbvqSXrbRbDZhohW",
		family: "Ubuntu Mono",
		full_name: "Ubuntu Mono Bold Italic",
		postscript_name: "UbuntuMono-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/s1sIS2mKg_07NK6edkXD2c16.png",
		style: "UbuntuMono-BoldItalic",
		url: "https://fonts.gstatic.com/s/ubuntumono/v10/KFO8CneDtsqEr0keqCMhbCc_Mn33tYhkf3O1GVg.ttf",
		category: "monospace",
	},
	{
		id: "font_5oWEvKzZ93qGU6PlpbXgX94A",
		family: "Cabin",
		full_name: "Cabin Medium",
		postscript_name: "Cabin-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/D3LVoMJpNMzdPDtCUvrU2ceP.png",
		style: "Cabin-Medium",
		url: "https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkW-EL7Gvxm7rE_s.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Lq7cwu3rA3eaysJElnTAWi9v",
		family: "Cabin",
		full_name: "Cabin SemiBold",
		postscript_name: "Cabin-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/3cVs8xNYbQqtteAemTQKLwyT.png",
		style: "Cabin-SemiBold",
		url: "https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkYODL7Gvxm7rE_s.ttf",
		category: "sans-serif",
	},
	{
		id: "font_MMUEA0XXDtcgRPH2AUBMJMhp",
		family: "Cabin",
		full_name: "Cabin Bold",
		postscript_name: "Cabin-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/uXE_vNoJkTPle6mhx_TXZx6w.png",
		style: "Cabin-Bold",
		url: "https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkbqDL7Gvxm7rE_s.ttf",
		category: "sans-serif",
	},
	{
		id: "font_bm20gGXJ8xC9jv9Xk_dEeT4U",
		family: "Cabin",
		full_name: "Cabin Regular",
		postscript_name: "Cabin-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/tlCsHZTijg1maAKT1O3A0bxS.png",
		style: "Cabin-Regular",
		url: "https://fonts.gstatic.com/s/cabin/v18/u-4X0qWljRw-PfU81xCKCpdpbgZJl6XFpfEd7eA9BIxxkV2EL7Gvxm7rE_s.ttf",
		category: "sans-serif",
	},
	{
		id: "font_FRL_zJv9by44f5DpscfuAYlo",
		family: "Cabin",
		full_name: "Cabin Italic",
		postscript_name: "Cabin-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/g1IOMiHMVJc6hezdpPj_mjKN.png",
		style: "Cabin-Italic",
		url: "https://fonts.gstatic.com/s/cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHx_KlwkzuA_u1Bg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_7RwoyYmLSLMGVDheQywTb1TF",
		family: "Cabin",
		full_name: "Cabin Medium Italic",
		postscript_name: "Cabin-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/5aHrIOKFWT0TPFDzmN1Mdngr.png",
		style: "Cabin-MediumItalic",
		url: "https://fonts.gstatic.com/s/cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXH9fKlwkzuA_u1Bg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_O5zklJ_kNTsLttG8eCFm43KZ",
		family: "Cabin",
		full_name: "Cabin SemiBold Italic",
		postscript_name: "Cabin-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/0LeFfJe049OPBQtfsJC8J4KG.png",
		style: "Cabin-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHGfWlwkzuA_u1Bg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_3CeUvdwB_bFMGSAWiGhpGf6V",
		family: "Cabin",
		full_name: "Cabin Bold Italic",
		postscript_name: "Cabin-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/hIIiCRkzYaVejfdxXkweb2lI.png",
		style: "Cabin-BoldItalic",
		url: "https://fonts.gstatic.com/s/cabin/v18/u-4V0qWljRw-Pd815fNqc8T_wAFcX-c37MPiNYlWniJ2hJXHIPWlwkzuA_u1Bg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_oMJWBMfe0vR5To9RU4prpaqf",
		family: "Patrick Hand",
		full_name: "Patrick Hand Regular",
		postscript_name: "PatrickHand-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/PltltOyeJeqfwSPK22XLfR7m.png",
		style: "PatrickHand-Regular",
		url: "https://fonts.gstatic.com/s/patrickhand/v14/LDI1apSQOAYtSuYWp8ZhfYeMWcjKm7sp8g.ttf",
		category: "handwriting",
	},
	{
		id: "font_45IJ1z0tfrWZMzJ4qTf8cZuI",
		family: "IM Fell Double Pica",
		full_name: "IM Fell Double Pica Regular",
		postscript_name: "IM_FELL_Double_Pica_Roman",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/vxXybuHPF9K2yyaLafPCxdIv.png",
		style: "IM_FELL_Double_Pica_Roman",
		url: "https://fonts.gstatic.com/s/imfelldoublepica/v10/3XF2EqMq_94s9PeKF7Fg4gOKINyMtZ8rT0S1UL5Ayp0.ttf",
		category: "serif",
	},
	{
		id: "font_nnzCxadWZIbJ7auoDtjAuxtt",
		family: "IM Fell Double Pica",
		full_name: "IM Fell Double Pica Italic",
		postscript_name: "IM_FELL_Double_Pica_Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/jTAnutYDSMWNS4ZG5YCfT2nY.png",
		style: "IM_FELL_Double_Pica_Italic",
		url: "https://fonts.gstatic.com/s/imfelldoublepica/v10/3XF0EqMq_94s9PeKF7Fg4gOKINyMtZ8rf0a_VJxF2p2G8g.ttf",
		category: "serif",
	},
	{
		id: "font_kl0O9BymD2urKmsVw9vd2eNq",
		family: "Istok Web",
		full_name: "Istok Web Bold",
		postscript_name: "IstokWeb-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Y2jp9AN8cBX5egB2_i8KfkYh.png",
		style: "IstokWeb-Bold",
		url: "https://fonts.gstatic.com/s/istokweb/v15/3qTqojGmgSyUukBzKslhvU5a_mkUYBfcMw.ttf",
		category: "sans-serif",
	},
	{
		id: "font_fmv8VOZCum7ZBKdFmJC3s0zh",
		family: "Istok Web",
		full_name: "Istok Web Regular",
		postscript_name: "IstokWeb-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/uReuKH8kQciDhHpoAt4Uzp8p.png",
		style: "IstokWeb-Regular",
		url: "https://fonts.gstatic.com/s/istokweb/v15/3qTvojGmgSyUukBzKslZAWF-9kIIaQ.ttf",
		category: "sans-serif",
	},
	{
		id: "font_sDLDkwPpbe9RLTBGuRGHLTaR",
		family: "Istok Web",
		full_name: "Istok Web Italic",
		postscript_name: "IstokWeb-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/5NuHHPRlwulN6l9PrY8bOF1Q.png",
		style: "IstokWeb-Italic",
		url: "https://fonts.gstatic.com/s/istokweb/v15/3qTpojGmgSyUukBzKslpA2t61EcYaQ7F.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Ij0Rrhk9AIpXX9NIplClk4WA",
		family: "Istok Web",
		full_name: "Istok Web Bold Italic",
		postscript_name: "IstokWeb-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/tqflWV1xqd2bDq7XGh7nL8z7.png",
		style: "IstokWeb-BoldItalic",
		url: "https://fonts.gstatic.com/s/istokweb/v15/3qT0ojGmgSyUukBzKslpA1PG-2MQQhLMMygN.ttf",
		category: "sans-serif",
	},
	{
		id: "font_FUyclhIwAOzHu6KtPoFIazDP",
		family: "Jim Nightshade",
		full_name: "Jim Nightshade Regular",
		postscript_name: "JimNightshade-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/TwlMShVIMFw6sReuDpNv3hty.png",
		style: "JimNightshade-Regular",
		url: "https://fonts.gstatic.com/s/jimnightshade/v10/PlIkFlu9Pb08Q8HLM1PxmB0g-OS4V3qKaMxD.ttf",
		category: "handwriting",
	},
	{
		id: "font_Kh3qqLNuJC7ue4jsDvT_5WFl",
		family: "Kaisei HarunoUmi",
		full_name: "Kaisei HarunoUmi Medium",
		postscript_name: "KaiseiHarunoUmi-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/bg9y1y2uHmdccoJvz46Nqyjt.png",
		style: "KaiseiHarunoUmi-Medium",
		url: "https://fonts.gstatic.com/s/kaiseiharunoumi/v6/HI_WiZQSLqBQoAHhK_C6N_nzy_jcIj_QlMcFwmC9FAU.ttf",
		category: "serif",
	},
	{
		id: "font_8mHXkH9ejYTGNLUcnkyPOXkR",
		family: "Kaisei HarunoUmi",
		full_name: "Kaisei HarunoUmi Bold",
		postscript_name: "KaiseiHarunoUmi-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/VrLcwVCFKOQC6Rs9Q0wATL3D.png",
		style: "KaiseiHarunoUmi-Bold",
		url: "https://fonts.gstatic.com/s/kaiseiharunoumi/v6/HI_WiZQSLqBQoAHhK_C6N_nzy_jcInfWlMcFwmC9FAU.ttf",
		category: "serif",
	},
	{
		id: "font_E6buCFMnVQi2LKRF8rZBjJQR",
		family: "Kaisei HarunoUmi",
		full_name: "Kaisei HarunoUmi Regular",
		postscript_name: "KaiseiHarunoUmi-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/JxEsnipPYhbWdk5o32HOgTER.png",
		style: "KaiseiHarunoUmi-Regular",
		url: "https://fonts.gstatic.com/s/kaiseiharunoumi/v6/HI_RiZQSLqBQoAHhK_C6N_nzy_jcGsv5sM8u3mk.ttf",
		category: "serif",
	},
	{
		id: "font_7I9g8RyCiRRIgvJhAWuaWSk6",
		family: "Khmer",
		full_name: "Khmer Regular",
		postscript_name: "Khmer",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/xcPYBK5uZtBggW4xR7S13oHT.png",
		style: "Khmer",
		url: "https://fonts.gstatic.com/s/khmer/v15/MjQImit_vPPwpF-BpN2EeYmD.ttf",
		category: "display",
	},
	{
		id: "font_NJjtEcq5puaErRsLBFjQT8Ir",
		family: "Kirang Haerang",
		full_name: "Kirang Haerang Regular",
		postscript_name: "KirangHaerang-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/3g7OXQeK8Q3XDErPIp5SjvGB.png",
		style: "KirangHaerang-Regular",
		url: "https://fonts.gstatic.com/s/kiranghaerang/v10/E21-_dn_gvvIjhYON1lpIU4-bcqvWPaJq4no.ttf",
		category: "display",
	},
	{
		id: "font_Vg4bmBg4WmsTIZX6crBQ4lW5",
		family: "KoHo",
		full_name: "KoHo ExtraLight",
		postscript_name: "KoHo-ExtraLight",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/0QcnevAyKEvc_34GcJzO5V5w.png",
		style: "KoHo-ExtraLight",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FxfZ5fmddNPuE1WJ75JoKhHys.ttf",
		category: "sans-serif",
	},
	{
		id: "font_rQVZideuOqaSBS9aTbueKKru",
		family: "KoHo",
		full_name: "KoHo Light",
		postscript_name: "KoHo-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/HF_agNijaKtEYFiSUG_Au76E.png",
		style: "KoHo-Light",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FxfZ5fmddNPoU2WJ75JoKhHys.ttf",
		category: "sans-serif",
	},
	{
		id: "font_GYirT__pjbTNCRDY6MNru055",
		family: "KoHo",
		full_name: "KoHo Medium",
		postscript_name: "KoHo-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/kyiJyFZVNli6z3QWz5N9p_5O.png",
		style: "KoHo-Medium",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FxfZ5fmddNPt03WJ75JoKhHys.ttf",
		category: "sans-serif",
	},
	{
		id: "font_oa8Hcj0AD6KehE2wsxw531H_",
		family: "KoHo",
		full_name: "KoHo SemiBold",
		postscript_name: "KoHo-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/VaSV0O_BSqD3zPIJtUDBySDl.png",
		style: "KoHo-SemiBold",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FxfZ5fmddNPvEwWJ75JoKhHys.ttf",
		category: "sans-serif",
	},
	{
		id: "font_WHY7tIqhAMmSHN6AowI84aD9",
		family: "KoHo",
		full_name: "KoHo Bold",
		postscript_name: "KoHo-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/_KVOFcmqmwUDhc3QTFx5Bpyr.png",
		style: "KoHo-Bold",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FxfZ5fmddNPpUxWJ75JoKhHys.ttf",
		category: "sans-serif",
	},
	{
		id: "font_vwtRrfpz3OMSOOKw1yxURaqn",
		family: "KoHo",
		full_name: "KoHo ExtraLight Italic",
		postscript_name: "KoHo-ExtraLightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/s5W836NzxLkGh6FqkU4PvtEX.png",
		style: "KoHo-ExtraLightItalic",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FzfZ5fmddNNisssJ_zIqCkDyvqZA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_XDgLvZjKMqio5NnUdJUQB4wf",
		family: "KoHo",
		full_name: "KoHo Light Italic",
		postscript_name: "KoHo-LightItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/ERhCLmqNRCB5nArtEuo8eesw.png",
		style: "KoHo-LightItalic",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FzfZ5fmddNNiss1JzzIqCkDyvqZA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_dfGvTcmlqyKY1mfV4sOXXsE0",
		family: "KoHo",
		full_name: "KoHo Regular",
		postscript_name: "KoHo-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/i79QO0GWwT8LEHEiY569issG.png",
		style: "KoHo-Regular",
		url: "https://fonts.gstatic.com/s/koho/v6/K2F-fZ5fmddNBikefJbSOos.ttf",
		category: "sans-serif",
	},
	{
		id: "font_74EMYxom0vQbP03YrsBtaLPi",
		family: "KoHo",
		full_name: "KoHo Italic",
		postscript_name: "KoHo-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/DjS4EcujCx1ToyqSaoqZiMY1.png",
		style: "KoHo-Italic",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FwfZ5fmddNNisUeLTXKou4Bg.ttf",
		category: "sans-serif",
	},
	{
		id: "font__qWo3QRNJV0WejCX5MeqraKQ",
		family: "KoHo",
		full_name: "KoHo Medium Italic",
		postscript_name: "KoHo-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/hF7H0flflkRn_o7sRm5L4dDp.png",
		style: "KoHo-MediumItalic",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FzfZ5fmddNNissjJ3zIqCkDyvqZA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_qx_VOrtZYCKFsUXjQFqvZ61L",
		family: "KoHo",
		full_name: "KoHo SemiBold Italic",
		postscript_name: "KoHo-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/eFeaQXOS0UhSvoGI1ZsKmL0m.png",
		style: "KoHo-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FzfZ5fmddNNissoJrzIqCkDyvqZA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_TWexo7iYlW0JilIvRJy1AOc_",
		family: "KoHo",
		full_name: "KoHo Bold Italic",
		postscript_name: "KoHo-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/uGeqssPEWVb88FLlpca8blLb.png",
		style: "KoHo-BoldItalic",
		url: "https://fonts.gstatic.com/s/koho/v6/K2FzfZ5fmddNNissxJvzIqCkDyvqZA.ttf",
		category: "sans-serif",
	},
	{
		id: "font_kT7XPfv2jsijA40WijQiYook",
		family: "Kreon",
		full_name: "Kreon Light",
		postscript_name: "Kreon-Light",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/kHjaDr9oGzPb_GGuAUa47Vs4.png",
		style: "Kreon-Light",
		url: "https://fonts.gstatic.com/s/kreon/v27/t5t9IRIUKY-TFF_LW5lnMR3v2DnvPNimejUfp2dWNg.ttf",
		category: "serif",
	},
	{
		id: "font_Oz90J8Ud9hCctFHFEGJEQ1Kw",
		family: "Kreon",
		full_name: "Kreon Medium",
		postscript_name: "Kreon-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/QNSQwWgi23jMxCry0HPLltnL.png",
		style: "Kreon-Medium",
		url: "https://fonts.gstatic.com/s/kreon/v27/t5t9IRIUKY-TFF_LW5lnMR3v2DnvUNimejUfp2dWNg.ttf",
		category: "serif",
	},
	{
		id: "font_fT6fINstiUGiE7XX56CCIHcD",
		family: "Kreon",
		full_name: "Kreon SemiBold",
		postscript_name: "Kreon-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/If6FLFDGMFXc5eVVYWbueNEN.png",
		style: "Kreon-SemiBold",
		url: "https://fonts.gstatic.com/s/kreon/v27/t5t9IRIUKY-TFF_LW5lnMR3v2DnvvN-mejUfp2dWNg.ttf",
		category: "serif",
	},
	{
		id: "font_S1tGj4ycsrI9CoW1eXzKweRX",
		family: "Kreon",
		full_name: "Kreon Bold",
		postscript_name: "Kreon-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/Rd8jQT0oqwoyi0K0mW8kmkSQ.png",
		style: "Kreon-Bold",
		url: "https://fonts.gstatic.com/s/kreon/v27/t5t9IRIUKY-TFF_LW5lnMR3v2Dnvhd-mejUfp2dWNg.ttf",
		category: "serif",
	},
	{
		id: "font_a7JnizxZ_1zOqfu5ayQcgTlM",
		family: "Kreon",
		full_name: "Kreon Regular",
		postscript_name: "Kreon-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/zwybiy5PlasxvMJeB0C0Kn7M.png",
		style: "Kreon-Regular",
		url: "https://fonts.gstatic.com/s/kreon/v27/t5t9IRIUKY-TFF_LW5lnMR3v2DnvYtimejUfp2dWNg.ttf",
		category: "serif",
	},
	{
		id: "font__4cgJ0QNQXiRaCIM5e8RBnzM",
		family: "Kufam",
		full_name: "Kufam Medium",
		postscript_name: "Kufam-Medium",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/miF1B8fyaKqcGuixQhSFPAVR.png",
		style: "Kufam-Medium",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c-4cY7pG7w_oSJDszBXsKCcBH3pKk7qQCJHvIwYg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_fUjv9XON0j25jDS64uk8TwTy",
		family: "Kufam",
		full_name: "Kufam SemiBold",
		postscript_name: "Kufam-SemiBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/o0IMfYBkOcPvSb2mNIj5cnCM.png",
		style: "Kufam-SemiBold",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c-4cY7pG7w_oSJDszBXsKCcBH3SK47qQCJHvIwYg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_FhUFsQLd5hJQvyOp3si8gsxp",
		family: "Kufam",
		full_name: "Kufam Bold",
		postscript_name: "Kufam-Bold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/5wltVgrIzKfITOjo7qTdQgb_.png",
		style: "Kufam-Bold",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c-4cY7pG7w_oSJDszBXsKCcBH3ca47qQCJHvIwYg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_NfRVq8tdfHPeTRafkXXtiuF2",
		family: "Kufam",
		full_name: "Kufam ExtraBold",
		postscript_name: "Kufam-ExtraBold",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/jB0Kh5HO7o2jholOsipLOnVw.png",
		style: "Kufam-ExtraBold",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c-4cY7pG7w_oSJDszBXsKCcBH3Fq47qQCJHvIwYg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_0VhBr1USwMCh_1T_ZKo0dkww",
		family: "Kufam",
		full_name: "Kufam Black",
		postscript_name: "Kufam-Black",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/aK5_zSRvzoAhLFf1afr34_c2.png",
		style: "Kufam-Black",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c-4cY7pG7w_oSJDszBXsKCcBH3P647qQCJHvIwYg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_3AhBWjtIM05oUXBwB57WqmFm",
		family: "Kufam",
		full_name: "Kufam Regular",
		postscript_name: "Kufam-Regular",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/8hFnmY4ZkJBAifcCl7Sblyb9.png",
		style: "Kufam-Regular",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c-4cY7pG7w_oSJDszBXsKCcBH3lqk7qQCJHvIwYg.ttf",
		category: "sans-serif",
	},
	{
		id: "font_H1nKmHtePGqK9iRXX9QzQxZu",
		family: "Kufam",
		full_name: "Kufam Italic",
		postscript_name: "Kufam-Italic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/XCsGN_nsC_ASS9VVh1azZNqu.png",
		style: "Kufam-Italic",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c84cY7pG7w_q6APDMZN6kY3hbiXurT6gqNPPcgYp0i.ttf",
		category: "sans-serif",
	},
	{
		id: "font_VsnVuaKbzrmY1gLu_M4XL7YF",
		family: "Kufam",
		full_name: "Kufam Medium Italic",
		postscript_name: "Kufam-MediumItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/ZHxWeWvitaNewJwcCw7SAD5z.png",
		style: "Kufam-MediumItalic",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c84cY7pG7w_q6APDMZN6kY3hbiXurh6gqNPPcgYp0i.ttf",
		category: "sans-serif",
	},
	{
		id: "font_W0fWjhq7y3dKjZW0JuEx30UN",
		family: "Kufam",
		full_name: "Kufam SemiBold Italic",
		postscript_name: "Kufam-SemiBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/GkvlbQgvYLU6Mcp2jGjb4o_Z.png",
		style: "Kufam-SemiBoldItalic",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c84cY7pG7w_q6APDMZN6kY3hbiXuoN7QqNPPcgYp0i.ttf",
		category: "sans-serif",
	},
	{
		id: "font_Xotu0C4vacqd_RoHyZJMwDTE",
		family: "Kufam",
		full_name: "Kufam Bold Italic",
		postscript_name: "Kufam-BoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/giwMqALCmDGBsQWVqVSa9Yph.png",
		style: "Kufam-BoldItalic",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c84cY7pG7w_q6APDMZN6kY3hbiXuo07QqNPPcgYp0i.ttf",
		category: "sans-serif",
	},
	{
		id: "font_mHVTX3sr_Q4KwtHJ0ynYGpf_",
		family: "Kufam",
		full_name: "Kufam ExtraBold Italic",
		postscript_name: "Kufam-ExtraBoldItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/bEtXrsGeziw35EBq5SaV5yHY.png",
		style: "Kufam-ExtraBoldItalic",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c84cY7pG7w_q6APDMZN6kY3hbiXupT7QqNPPcgYp0i.ttf",
		category: "sans-serif",
	},
	{
		id: "font_O7o4UquTMNQSwSZ40rvrUbuV",
		family: "Kufam",
		full_name: "Kufam Black Italic",
		postscript_name: "Kufam-BlackItalic",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/SM2mlBF8J6jr1evPvRaYBofg.png",
		style: "Kufam-BlackItalic",
		url: "https://fonts.gstatic.com/s/kufam/v11/C8c84cY7pG7w_q6APDMZN6kY3hbiXup67QqNPPcgYp0i.ttf",
		category: "sans-serif",
	},
	{
		id: "font_U75vuTcE1tlIGGt5g50fzZav",
		family: "La Belle Aurore",
		full_name: "La Belle Aurore Regular",
		postscript_name: "LaBelleAurore",
		preview: "https://ik.imagekit.io/scenify/fonts/previews/sk7Tz0h1yXWRqpvQ5LbMdJTp.png",
		style: "LaBelleAurore",
		url: "https://fonts.gstatic.com/s/labelleaurore/v11/RrQIbot8-mNYKnGNDkWlocovHeIIG-eFNVmULg.ttf",
		category: "handwriting",
	},
];
const textComponent1 = {
	id: "8_rxQGqFj-sJWchXvBM49",
	layers: [
		{
			angle: 0,
			stroke: null,
			strokeWidth: 0,
			left: 0,
			top: 0,
			width: 573.06,
			height: 244.56,
			opacity: 1,
			originX: "left",
			originY: "top",
			scaleX: 1,
			scaleY: 1,
			type: "Group",
			flipX: false,
			flipY: false,
			skewX: 0,
			skewY: 0,
			shadow: null,
			visible: true,
			start: 0,
			stop: 10,
			objects: [
				{
					id: "3gfzP58He89jnBXlC4dNo",
					angle: 0,
					stroke: "#ffffff",
					strokeWidth: 0,
					left: -251.96,
					top: -122.28,
					width: 480,
					height: 103.96,
					opacity: 1,
					originX: "left",
					originY: "top",
					scaleX: 1,
					scaleY: 1,
					type: "StaticText",
					flipX: false,
					flipY: false,
					skewX: 0,
					skewY: 0,
					shadow: null,
					visible: true,
					start: 0,
					stop: 10,
					text: "Hola",
					fontSize: 92,
					fontFamily: "OpenSans-Light",
					textAlign: "center",
					fontURL:
						"https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf",
					fill: "#000000",
					charSpacing: 0,
					template: "Hola",
					lineHeight: 1.16,
					metadata: {},
				},
				{
					id: "9941Rqfh4nreFT8MYkrZz",
					angle: 0,
					stroke: "#ffffff",
					strokeWidth: 0,
					left: -286.53,
					top: 18.32,
					width: 573.06,
					height: 103.96,
					opacity: 1,
					originX: "left",
					originY: "top",
					scaleX: 1,
					scaleY: 1,
					type: "StaticText",
					flipX: false,
					flipY: false,
					skewX: 0,
					skewY: 0,
					shadow: null,
					visible: true,
					start: 0,
					stop: 10,
					text: "Amiguitos",
					fontSize: 92,
					fontFamily: "OpenSans-Medium",
					textAlign: "center",
					fontURL:
						"https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4nY1M2xLER.ttf",
					fill: "#000000",
					charSpacing: 0,
					lineHeight: 1.16,
					metadata: {},
				},
			],
		},
	],
	metadata: {
		category: "single",
		types: ["StaticText"],
	},
	preview: "https://ik.imagekit.io/scenify/bdj0qkh18v4Zx06ZoMbGWb2O.png",
	userId: "_O8L0WDPlpKhDXspugZe3",
};

const textComponent2 = {
	id: "CN3w326wV9VUbq-CMkRxQ",
	layers: [
		{
			id: "CN3w326wV9VUbq-CMkRxQ",
			angle: 0,
			stroke: "#ffffff",
			strokeWidth: 0,
			left: 0,
			top: 0,
			width: 363.87,
			height: 107.4,
			opacity: 1,
			originX: "left",
			originY: "top",
			scaleX: 1,
			scaleY: 1,
			type: "StaticText",
			flipX: false,
			flipY: false,
			skewX: 0,
			skewY: 0,
			shadow: {
				color: "#000000",
				blur: 25,
				offsetX: 10,
				offsetY: 10,
				affectStroke: false,
				nonScaling: false,
			},
			visible: true,
			start: 0,
			stop: 10,
			text: "Sample text with shadow",
			fontSize: 44,
			fontFamily: "OpenSans-Light",
			textAlign: "center",
			fontStyle: "normal",
			fontURL:
				"https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf",
			fill: "#000000",
			fontWeight: "normal",
			charSpacing: 0,
			template: "Sample text with shadow",
			lineHeight: 1.16,
		},
	],
	metadata: {
		category: "single",
		types: ["StaticText"],
	},
	preview: "https://ik.imagekit.io/scenify/lJaXfdYQMHysY8VwUkWcIgqd.png",
	userId: "_O8L0WDPlpKhDXspugZe3",
};
export const textComponents = [textComponent1, textComponent2];

export const sampleFrames = [
	{
		id: 1,
		name: "Presentation (4:3)",
		width: 1024,
		height: 768,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-presentation-4x3.svg",
	},
	{
		id: 2,
		name: "Presentation (16:9)",
		width: 1920,
		height: 1080,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-presentation-16x9.svg",
	},
	{
		id: 3,
		name: "Social Media Story",
		width: 1080,
		height: 1920,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-social-media-story.svg",
	},
	{
		id: 4,
		name: "Instagram Post",
		width: 1080,
		height: 1080,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-social-media-post.svg",
	},
	{
		id: 5,
		name: "Facebook Post",
		width: 1200,
		height: 1200,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-social-media-post.svg",
	},
	{
		id: 6,
		name: "Facebook Cover / Page Cover",
		width: 1702,
		height: 630,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-facebook-event-cover.svg",
	},
	{
		id: 7,
		name: "Facebook Event Cover",
		width: 1920,
		height: 1080,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-facebook-event-cover.svg",
	},
	{
		id: 8,
		name: "YouTube Channel Art",
		width: 2560,
		height: 1440,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-youtube-channel-art.svg",
	},
	{
		id: 9,
		name: "YouTube Thumbnail",
		width: 1280,
		height: 720,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-youtube-thumbnail.svg",
	},
	{
		id: 10,
		name: "Twitter Post",
		width: 1200,
		height: 675,
		unit: "px",
		preview: "https://ik.imagekit.io/scenify/social-twittter-post.svg",
	},
];

// const layers: ILayer[] = [];

// const graphicTemplate = {
// 	id: "",
// 	name: "",
// 	frame: {},
// 	content: [...layers],
// };
