import { Close } from "@mui/icons-material";
import { Box } from "@mui/material";
import { LegacyRef, useRef, useState } from "react";
import { EditIcon } from "../../../assets/images/icons/EditIcon.tsx";
import useOutsideClick from "../../../hooks/useOutsideClick.tsx";
import styles from "./EditableChip.module.scss";

interface IProps {
	label: string;
	onDelete: (label: string) => void;
	onEdit?: (value: string) => void;
	enableOnMouseEnter?: boolean;
	enableOnMouseLeave?: boolean;
	hideButtons?: boolean;
	className?: string;
}

function EditableChip({
	label,
	onDelete,
	onEdit,
	className,
	hideButtons = false,
	enableOnMouseEnter = false,
	enableOnMouseLeave = false,
}: IProps) {
	const [isEditing, setIsEditing] = useState(false);
	const [editedLabel, setEditedLabel] = useState(label);
	const inputRef = useRef<HTMLElement | null>(null);
	const handleEditClick = () => {
		setIsEditing(true);
	};

	const handleSaveClick = () => {
		setIsEditing(false);
		onEdit && onEdit(editedLabel);
	};

	useOutsideClick(inputRef, () => {
		handleSaveClick();
	});

	return (
		<Box
			className={styles.root}
			onClick={(e) => e.stopPropagation()}
			onMouseDown={(e) => e.stopPropagation()}
			onKeyDown={(e) => e.stopPropagation()}
			onMouseEnter={() => enableOnMouseEnter && setIsEditing(true)}
			onMouseLeave={() => {
				if (enableOnMouseLeave) {
					setIsEditing(false);
					handleSaveClick();
				}
			}}
		>
			{isEditing ? (
				<Box className={styles.editableContainer}>
					<input
						ref={inputRef as LegacyRef<HTMLInputElement> | undefined}
						type="text"
						value={editedLabel}
						onChange={(e) => setEditedLabel(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								handleSaveClick();
							}
						}}
					/>
					{/*<button onClick={handleSaveClick}>Save</button>*/}
				</Box>
			) : (
				<Box className={`${styles.container} ${className}`}>
					<Box>{label}</Box>
					{!hideButtons && (
						<Box className={styles.buttonContainer}>
							{!!onEdit && <EditIcon onClick={handleEditClick} />}
							<Close onClick={() => onDelete(label)} />
						</Box>
					)}
				</Box>
			)}
		</Box>
	);
}

export default EditableChip;
