// Import necessary modules and types
import { getSelectedOrganization } from "../helpers/localStorage";
import { BrandLogo, defaultBrandLogos } from "./brandLogo";
import { BrandText, defaultBrandText } from "./brandText";
import { ColorPallet, defaultColorPallet } from "./colorPallete";
import { CampaignEntityStatus } from "./common";

export interface BrandDefinition {
	id: number;
	user_id?: number;
	name: string;
	description?: string;
	color_pallete_id?: number;
	color_pallete?: ColorPallet;
	status: CampaignEntityStatus;
	org_id?: string;
	logos?: BrandLogo[];
	brand_texts?: BrandText[];
	created_at: Date;
	updated_at: Date;
}

export const getOrgId = () => getSelectedOrganization()?.organization?.uid || "";

export const defaultBrandDefinition: BrandDefinition = {
	id: 0,
	name: "Untitled - Brand Name",
	color_pallete_id: undefined,
	status: CampaignEntityStatus.PENDING,
	org_id: getOrgId(),
	created_at: new Date(),
	updated_at: new Date(),
	logos: defaultBrandLogos,
	color_pallete: defaultColorPallet,
	brand_texts: Array(5).fill(defaultBrandText),
};
