import { Box } from "@mui/material";
import clsx from "clsx";
import TextAutoAlignIcon from "../../../assets/images/icons/Alignment/Text/TextAutoAlignIcon";
import styles from "./FontPopover.module.scss";

type Props = {
	disable?: boolean;
};
const TextHeightButtons = ({ disable }: Props) => {
	return (
		<Box className={styles.iconsGroup}>
			<TextAutoAlignIcon
				className={clsx({
					[styles.disabled]: disable,
				})}
			/>
			<Box
				className={clsx({
					[styles.disabled]: disable,
					[styles.autoBtn]: true,
				})}
			>
				Auto
			</Box>
		</Box>
	);
};

export default TextHeightButtons;
