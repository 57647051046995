import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import HomeGraphicalBackground from "../../assets/images/svgs/home-graphical-bg.svg";
import { DashboardSectionComponent } from "../../components/Dashboard/DashboardSectionComponent";
import SubscriptionWidget from "../../components/Home/SubscriptionWidget/SubscriptionWidget.tsx";
import TailoredGenerationWidget from "../../components/Home/TailoredGenerationWidget/TailoredGenerationWidget.tsx";
import Background from "../../components/common/Background/Background.tsx";
import {
	aIImageCreationCapabilitiesCards,
	aiImageCustomizationCapabilitiesCards,
	cardDataImplementedOptions,
} from "../../constants/dashboardConstants";
import styles from "./Home.module.scss";

const Home = () => {
	const { t } = useTranslation("translation", { keyPrefix: "briaProductsAndServices" });

	return (
		<Box className={styles.container}>
			<SubscriptionWidget />
			<Box className={styles.innerContainer}>
				<Box className={styles.firstSeparotor} />
				<DashboardSectionComponent
					title={t("implementationOptions.title")}
					description={t("implementationOptions.description")}
					cards={cardDataImplementedOptions}
				/>
				<Box className={styles.topMarginedSeperator} />
				<TailoredGenerationWidget />
			</Box>
			<Background
				background={HomeGraphicalBackground}
				width="100%"
				height="50px"
				borderRadius="0px"
				backgroundRepeat="repeat-x"
				backgroundPosition="unset"
			/>
			<Box className={styles.innerContainer}>
				<DashboardSectionComponent
					mainTitle={t("aIImageCreationCapabilities.mainTitle")}
					title={t("aIImageCreationCapabilities.title")}
					description={t("aIImageCreationCapabilities.description")}
					cards={aIImageCreationCapabilitiesCards}
				/>
				<Box className={styles.separotor}> </Box>

				<DashboardSectionComponent
					title={t("aIImageCustomizationCapabilities.title")}
					description={t("aIImageCustomizationCapabilities.description")}
					cards={aiImageCustomizationCapabilitiesCards}
				/>
			</Box>
		</Box>
	);
};

export default observer(Home);
