import RouterConstants from "../constants/RouterConstants.ts";
import QueryService from "../utils/QueryService.ts";

class BillingService {
	queryService = new QueryService("/billing/");

	fetchClientSecret = async (
		priceIds: string[] | undefined,
		planName: string | undefined,
		allowPromotionCodes?: boolean,
		paymentMethodConfigurationId?: string | null,
	) => {
		const checkoutSession = await this.queryService.post("checkout_session", {
			plan: planName,
			price_ids: priceIds,
			return_url: `${window.location.protocol}//${window.location.host}${RouterConstants.SUBSCRIPTION.fullPath}`,
			allow_promotion_codes: allowPromotionCodes,
			payment_method_configuration_id: paymentMethodConfigurationId,
		});
		return checkoutSession.client_secret;
	};

	fetchCheckoutSession = async (sessionId: string) => {
		return await this.queryService.get("checkout_session", {
			params: {
				session_id: sessionId,
			},
		});
	};

	listProducts = async () => {
		return await this.queryService.get("products");
	};

	listTermsAndConditionFiles = async () => {
		return await this.queryService.get("terms_and_conditions");
	};

	listPaymentMethodConfigurations = async () => {
		return await this.queryService.get("payment_method_configurations");
	};

	loadOrgSubscription = async () => {
		return await this.queryService.get("subscription");
	};

	updateOrgSubscription = async (newPriceIds: string[] | null | undefined) => {
		return await this.queryService.put("subscription", { new_price_ids: newPriceIds });
	};

	loadOrgUsage = async () => {
		return await this.queryService.get("org_usage");
	};

	createCustomerPortalSession = async (returnUrl: string) => {
		return await this.queryService.post("customer_portal_session", {
			return_url: returnUrl,
		});
	};

	createCustomerPortalCancelSubscriptionSession = async (returnUrl: string) => {
		return await this.queryService.post("customer_portal_cancel_subscription_session", {
			return_url: returnUrl,
		});
	};
}

export default BillingService;
