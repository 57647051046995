export type Channel = {
	id: number;
	name: string;
	org_id: string;
	created_at: Date;
	updated_at: Date;
	placements: number[];
};

export const defaultChannel: Channel = {
	id: NaN,
	name: "",
	org_id: "",
	placements: [],
	created_at: new Date(),
	updated_at: new Date(),
};
