import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function TextAutoAlignIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 19 16" {...props}>
			<path
				d="M1 4L4 1M4 1L7 4M4 1V15M1 12L4 15M4 15L7 12M11 2H18M11 8H18M11 14H18"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default TextAutoAlignIcon;
