import { Box, CircularProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { DesignEditorContext } from "../../../contexts/DesignEditor.tsx";
import { useEditor } from "../../../hooks/useEditor.tsx";
import { ILayer, type IScene } from "../../../types";
import {
	BackgroundOptionsEnum,
	BackgroundRoute,
	ObjectsEnum,
} from "../../../views/DesignEditor/components/Panels/panelItems";
import { useSmartImageUtils } from "../../../views/DesignEditor/utils/smartImageUtils.ts";
import Icons from "../../Icons";
import DeleteIcon from "../../Icons/Delete.tsx";
import styles from "./SmartImageHeader.module.scss";

interface IProps {
	buttonPosition: { left: number; top: number; right?: number };
}

const SmartImageHeader = ({ buttonPosition }: IProps) => {
	const editor = useEditor();
	const { getSmartImageSize, resetRectangleInAGroup, findObjectFromScene } = useSmartImageUtils();
	const { imagesStore, designEditorStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.canvasControllers" });
	const { scenes, setScenes, setCurrentScene } = useContext(DesignEditorContext);
	return (
		<Box
			className={styles.smartImageBox}
			style={{
				right: `${buttonPosition.left}px`,
				top: `${buttonPosition.top - 35}px`,
			}}
		>
			<Box
				className={clsx({
					[styles.buttonWrapper]: true,
					[styles.disabled]:
						imagesStore.isGeneratingImages || imagesStore.smartImageForm.originalImage.imageUrl === "",
				})}
				onClick={async () => {
					const currentScene = editor && editor.scene.exportToJSON();
					if (!currentScene) return;
					const operation =
						imagesStore.smartImageForm.backgroundOption === BackgroundOptionsEnum.KEEP_BACKGROUND
							? BackgroundRoute.Expand
							: BackgroundRoute.Replace;
					const smartImageSize = await getSmartImageSize(currentScene);
					if (smartImageSize) {
						imagesStore.generateOrExpandImageBackground(operation, smartImageSize);
					}
				}}
			>
				<>
					<Icons.GenerateBackground size={16} />
					<Typography className={styles.buttonLabel}>
						{imagesStore.isGeneratingImages ? (
							<CircularProgress color="inherit" size={16} />
						) : (
							t("generate")
						)}
					</Typography>
				</>
			</Box>
			{/* Commented out per WAI-2942 1/a/ii */}
			{/*<Box*/}
			{/*	className={styles.buttonWrapper}*/}
			{/*	onClick={() => {*/}
			{/*		imagesStore.initializeSmartImage(editor);*/}
			{/*	}}*/}
			{/*>*/}
			{/*	<Icons.EditWithAI size={16} />*/}
			{/*	<Typography className={styles.buttonLabel}>{t("editUsingAI")}</Typography>*/}
			{/*</Box>*/}
			<Box
				className={styles.buttonWrapper}
				onClick={async () => {
					if (editor) {
						const _currentScene = editor.scene.exportToJSON();
						await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);
						await resetRectangleInAGroup(_currentScene, ObjectsEnum.InnerRectangle);
						const innerRectangle = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);
						designEditorStore.setProperty("originalInnerRectangle", innerRectangle as ILayer);
						imagesStore.setProperty("smartImageBackgroundOptions", []);
						const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
						const updatedScene = { ..._currentScene, preview: updatedPreview, duration: 1000 };
						const updatedScenes = scenes.map((scene: IScene) => {
							if (scene.id === updatedScene.id) {
								return { ...updatedScene, preview: updatedPreview };
							}
							return editor.scene.formalizeSceneAttributes(scene);
						}) as IScene[];
						setScenes(updatedScenes);
						setCurrentScene(updatedScene);
					}
				}}
			>
				<Icons.Uploads1 size={16} />
				<Typography className={styles.buttonLabel}>{t("upload")}</Typography>
			</Box>
			<Box
				className={styles.buttonWrapper}
				onClick={() => {
					imagesStore.resetSmartImage(editor, true);
				}}
			>
				<DeleteIcon size={16} />
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(SmartImageHeader);
export default ObservedComponent;
