function TextAlignRight({ size }: { size: number }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
			<path
				d="M3.33398 5H16.6673M8.33398 10H16.6673M5.00065 15H16.6673"
				stroke="#5B5B5B"
				stroke-width="1.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default TextAlignRight;
