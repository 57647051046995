import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UploadSVG from "../../../assets/images/svgs/cloud-upload.svg";
import { TAILORED_GENERATION_TYPES } from "../../../constants/TGConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { ITailoredGenerationDataset, TailoredGenerationBrandStyle } from "../../../models/tailoredGeneration.ts";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import BriaDropDown, { DropDownItem } from "../../common/BriaDropDown/BriaDropDown.tsx";
import styles from "./UploadImages.module.scss";

const UploadImages = ({ handleNext }: { handleNext: () => void }) => {
	const [selectedDataset, setSelectedDataset] = useState<string>("");
	const { uiStore, tailoredGenerationStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation.models.stepper.createDataSet" });
	const [datasets, setDatasets] = useState<DropDownItem[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const handleChange = (event: SelectChangeEvent<typeof selectedDataset>) => {
		setSelectedDataset(event.target.value as string);
		const selectedDataSet: ITailoredGenerationDataset | undefined = tailoredGenerationStore?.datasets?.find(
			(dataset) => dataset.id?.toString() === event.target.value,
		);
		if (selectedDataSet) {
			tailoredGenerationStore.selectedDatasetOption = selectedDataSet;
			tailoredGenerationStore.handleFormChange("dataset", selectedDataSet);
			if (handleNext) {
				handleNext();
			}
			tailoredGenerationStore.createNewDataset = true;
		}
	};

	useEffect(() => {
		const loadData = async () => {
			if (tailoredGenerationStore.datasets.length == 0) {
				await tailoredGenerationStore.loadDatasets();
			}

			setIsLoading(false);
			let filteredDropDownItems: any = [];
			if (tailoredGenerationStore.modelForm.type == TAILORED_GENERATION_TYPES.SINGLE_SUBJECT) {
				filteredDropDownItems = tailoredGenerationStore.datasets.filter(
					(dataset: ITailoredGenerationDataset) => {
						return dataset.brandStyleType === TailoredGenerationBrandStyle.CHARACTER.name;
					},
				);
			} else {
				filteredDropDownItems = tailoredGenerationStore.datasets.filter(
					(dataset: ITailoredGenerationDataset) => {
						return dataset.brandStyleType !== TailoredGenerationBrandStyle.CHARACTER.name;
					},
				);
			}

			let datasetDropDownItems: DropDownItem[] = filteredDropDownItems.map(
				(dataset: ITailoredGenerationDataset) => ({
					key: dataset.name || "",
					value: dataset.id ? String(dataset.id) : "",
				}),
			);

			setDatasets(datasetDropDownItems);
			setIsLoading(false);
		};
		loadData();
	}, []);

	const handleUploadClick = () => {
		if (uiStore.tgUploadFileInput.current) {
			uiStore.tgUploadFileInput.current.click();
		}
	};

	return (
		<Box className={styles.container}>
			<Typography className={styles.title}>{t("title")}</Typography>
			{tailoredGenerationStore?.modelForm?.type === TAILORED_GENERATION_TYPES.SINGLE_SUBJECT ? (
				<Typography className={styles.description}>{t("description", { from: 10, to: 20 })}</Typography>
			) : tailoredGenerationStore?.modelForm?.type === TAILORED_GENERATION_TYPES.ICONS ? (
				<Typography className={styles.description}>{t("description", { from: 20, to: 50 })}</Typography>
			) : (
				<Typography className={styles.description}>{t("description", { from: 20, to: 200 })}</Typography>
			)}
			<Box className={styles.buttonsContainer}>
				<BriaButton className={styles.button} buttonType="primary" onClick={handleUploadClick}>
					<img className={styles.uploadIcon} src={UploadSVG} /> {t("uploadImages")}
				</BriaButton>
				<BriaButton
					buttonType="textMedium"
					onClick={() => {
						uiStore.showDialog("GuidelinesForTailoredModelsDialog");
					}}
				>
					{t("reviewBRIAsBestPracticesForDatasetCreation")}
				</BriaButton>
			</Box>

			{(datasets.length > 0 || isLoading) && (
				<Box>
					<Typography className={styles.or}>{t("or")}</Typography>
					<BriaDropDown
						value={selectedDataset}
						onChange={handleChange}
						placeholder={t("dropDownPlaceholder")}
						items={datasets}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
						loading={isLoading}
					/>
				</Box>
			)}

			<Typography className={styles.supportedFormats}>{t("supportedFormats")}</Typography>
		</Box>
	);
};

const ObservedComponent = observer(UploadImages);
export default ObservedComponent;
