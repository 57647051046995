import { Box, CircularProgress, Tooltip } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import captions from "../../../assets/images/svgs/Captions.svg";
import download from "../../../assets/images/svgs/Download.svg";
import upload from "../../../assets/images/svgs/Upload.svg";
import { TAILORED_GENERATION_MODEL_STEP } from "../../../constants/TGConstants";
import { useAppStore } from "../../../hooks/useStores";
import ConfirmationPopup from "../ConfirmationPopup/ConfirmationPopup";
import styles from "./UpdateCaptionButtons.module.scss";

interface IUpdateCaptionButtons {
	step: string;
}
const UpdateCaptionButtons = ({ step }: IUpdateCaptionButtons) => {
	const { uiStore, tailoredGenerationStore } = useAppStore();
	const [openUpdateCaptionsManuallyConfirmationPopup, setOpenUpdateCaptionsManuallyConfirmationPopup] =
		useState(false);
	const [openAutoCaptionsUpdateConfirmationPopup, setOpenAutoCaptionsUpdateConfirmationPopup] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation.datasets" });
	const [file, setFile] = useState<File | undefined>(undefined);

	const updateCaptionsManually = async () => {
		try {
			await tailoredGenerationStore.uploadDatasetCSV(file);
		} catch {
			setOpenUpdateCaptionsManuallyConfirmationPopup(false);
		}
		setOpenUpdateCaptionsManuallyConfirmationPopup(false);
	};

	const autoCaptions = async () => {
		try {
			const dataset = tailoredGenerationStore?.modelForm?.dataset;
			if (dataset) {
				await tailoredGenerationStore.updateDatasetAssetsAutoCaptions(dataset);
			}
			setOpenAutoCaptionsUpdateConfirmationPopup(false);
		} catch {
			setOpenAutoCaptionsUpdateConfirmationPopup(false);
		}
	};

	const handleDownloadClick = async () => {
		const datasetId = tailoredGenerationStore?.modelForm?.dataset?.id;
		const datasetName = tailoredGenerationStore?.modelForm?.dataset?.name;
		try {
			if (datasetId) {
				setIsDownloading(true);
				const csvFile: string = await tailoredGenerationStore.getDatasetAssetsAsCSV(datasetId);
				const blob = new Blob([csvFile], { type: "text/csv" });
				const url = URL.createObjectURL(blob);
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", datasetName ? datasetName : "dataset" + ".csv");
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				setIsDownloading(false);
			}
		} catch {
			setIsDownloading(false);
		}
	};

	const handleUploadClick = async () => {
		const datasetId = tailoredGenerationStore?.modelForm?.dataset?.id;
		if (datasetId) {
			if (uiStore.tgCSVUploadFileInput.current) {
				uiStore.tgCSVUploadFileInput.current.click();
			}
		}
	};

	const handleAutoCaptionClick = async () => {
		setOpenAutoCaptionsUpdateConfirmationPopup(true);
	};
	return (
		<>
			{step === TAILORED_GENERATION_MODEL_STEP.CREATE_DATASET && (
				<Box className={styles.captionsButtonsContainer}>
					<input
						ref={uiStore.tgCSVUploadFileInput}
						type="file"
						className={styles.hidden}
						onChange={async (e) => {
							setFile(e?.target?.files?.[0]);
							e.target.value = "";
							setOpenUpdateCaptionsManuallyConfirmationPopup(true);
						}}
						accept=".csv"
					/>
					<Tooltip title={t("datasetAssetsCaptionsUpdate.autoCaptions")} placement="top">
						<img className={styles.Icon} onClick={handleAutoCaptionClick} src={captions} />
					</Tooltip>
					{!isDownloading ? (
						<Tooltip title={t("datasetAssetsCaptionsUpdate.downloadDataset")} placement="top">
							<img className={styles.Icon} onClick={handleDownloadClick} src={download} />
						</Tooltip>
					) : (
						<Box className={styles.circleLoader}>
							<CircularProgress color="inherit" size={16} />
						</Box>
					)}
					<Tooltip title={t("datasetAssetsCaptionsUpdate.uploadDatasetCaptions")} placement="top">
						<img className={styles.Icon} onClick={handleUploadClick} src={upload} />
					</Tooltip>
				</Box>
			)}
			<ConfirmationPopup
				onClose={() => {
					setOpenUpdateCaptionsManuallyConfirmationPopup(false);
				}}
				title={t("captionsUpdatePopup.title")}
				description={t("captionsUpdatePopup.description")}
				confirmButtonText={t("captionsUpdatePopup.proceed")}
				open={openUpdateCaptionsManuallyConfirmationPopup}
				onClick={updateCaptionsManually}
				hideCancel={true}
				loading={tailoredGenerationStore.isUploadingDataCSV}
			/>

			<ConfirmationPopup
				onClose={() => {
					setOpenAutoCaptionsUpdateConfirmationPopup(false);
				}}
				title={t("captionsUpdatePopup.title")}
				description={t("captionsUpdatePopup.description")}
				confirmButtonText={t("captionsUpdatePopup.proceed")}
				open={openAutoCaptionsUpdateConfirmationPopup}
				onClick={autoCaptions}
				hideCancel={true}
				loading={tailoredGenerationStore.isAutoCaption}
			/>
		</>
	);
};
const ObservedComponent = observer(UpdateCaptionButtons);
export default ObservedComponent;
