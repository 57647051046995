import { Box } from "@mui/material";
import clsx from "clsx";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton.tsx";
import Icons from "../../../../../components/Icons";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import { useActiveObject } from "../../../../../hooks/useActiveObject.tsx";
import { useEditor } from "../../../../../hooks/useEditor.tsx";
import { ICON_SIZE } from "../../ContextMenu/ContextMenusData.tsx";
import EditAllAdsSwitch from "../../Panels/panelItems/Common/EditAllAdsSwitch/EditAllAdsSwitch";
import Align from "./Align/Align.tsx";
import styles from "./Multiple.module.scss";

export default function () {
	const { isPopupView } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.multiple" });
	const [state, setState] = useState({ isGroup: false, isMultiple: false });
	const activeObject = useActiveObject() as any;

	const editor = useEditor();

	useEffect(() => {
		if (activeObject) {
			setState({ isGroup: activeObject.type === "group", isMultiple: activeObject.type === "activeSelection" });
		}
	}, [activeObject]);

	useEffect(() => {
		let watcher = async () => {
			if (activeObject) {
				setState({
					isGroup: activeObject.type === "group",
					isMultiple: activeObject.type === "activeSelection",
				});
			}
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor, activeObject]);

	function LockUnlock() {
		const [state, setState] = useState<{ locked: boolean }>({ locked: false });
		const editor = useEditor();
		const activeObject = useActiveObject();

		useEffect(() => {
			if (activeObject) {
				// @ts-ignore
				setState({ locked: !!activeObject.locked });
			}
		}, [activeObject]);

		return (
			<>
				{state.locked ? (
					<Box
						onClick={() => {
							editor?.objects.unlock();
							setState({ locked: false });
						}}
					>
						<Icons.Unlocked size={ICON_SIZE} />
					</Box>
				) : (
					<Box
						onClick={() => {
							editor?.objects.lock();
							setState({ locked: true });
						}}
					>
						<Icons.Locked size={ICON_SIZE} />
					</Box>
				)}
			</>
		);
	}
	if (isPopupView) {
		return null;
	}

	return (
		<Box
			className={clsx({
				[styles.textControls]: true,
				[styles.hide]: isPopupView,
			})}
		>
			<Box className={styles.groupControlsWrapper}>
				{state.isGroup ? (
					<BriaButton
						buttonType="textMedium"
						className={styles.groupButton}
						onClick={() => {
							editor?.objects.ungroup();
							setState({ ...state, isGroup: false });
						}}
					>
						{t("ungroup")}
					</BriaButton>
				) : state.isMultiple ? (
					<BriaButton
						buttonType="textMedium"
						className={styles.groupButton}
						onClick={() => {
							editor?.objects.group();
							setState({ ...state, isGroup: true });
						}}
					>
						{t("group")}
					</BriaButton>
				) : null}
				<Align />
				<Box onClick={() => editor?.objects.clone()}>
					<Icons.Duplicate size={ICON_SIZE} />
				</Box>
				<Box onClick={() => editor?.objects.remove()}>
					<Icons.Delete size={ICON_SIZE} />
				</Box>
				<LockUnlock />

				<Box className={styles.groupControlsIcons}></Box>
			</Box>
			<EditAllAdsSwitch />
		</Box>
	);
}
