import { Box, Skeleton, SkeletonProps } from "@mui/material";
import { useState } from "react";
import { AspectRatio } from "../DropDowns/StaticDropdown";
import styles from "./SkeletonGallery.module.scss";

type SkeletonGalleryProps = {
	length: number;
	aspectRatio?: AspectRatio;
	className?: string;
};

export const SkeletonGallery = ({ length, aspectRatio = "1:1", className }: SkeletonGalleryProps) => {
	const [localAspectRatio, _setLocalAspectRatio] = useState(
		aspectRatio !== ("auto" as AspectRatio) ? aspectRatio : "1:1",
	);
	return (
		<Box className={`${className} ${styles.skeletonGallery}`}>
			{Array.from({ length: length }).map((_, index) => (
				<SkeletonImage width={`calc( ${100 / length}% - 4vh)`} key={index} aspectRatio={localAspectRatio} />
			))}
		</Box>
	);
};

type SkeletonImageProps = {
	aspectRatio?: AspectRatio;
};

export const SkeletonImage = ({ aspectRatio = "1:1", className, ...rest }: SkeletonImageProps & SkeletonProps) => {
	const [localAspectRatio, _setLocalAspectRatio] = useState(
		aspectRatio !== ("auto" as AspectRatio) ? aspectRatio : "1:1",
	);
	return (
		<Skeleton
			variant="rectangular"
			className={`${className} ${styles.skeletonImage}`}
			style={{ aspectRatio: localAspectRatio.replace(":", "/") }}
			{...rest}
		/>
	);
};
