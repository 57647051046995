function DuplicateIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
			<path
				d="M8.842 9.92783C8.842 10.4914 8.38513 10.9482 7.82159 10.9482H1.90322C1.33967 10.9482 0.882812 10.4914 0.882812 9.92783V4.00947C0.882812 3.44592 1.33967 2.98906 1.90322 2.98906H7.82159C8.38513 2.98906 8.842 3.44592 8.842 4.00947V9.92783Z"
				stroke="#5B5B5B"
				stroke-width="1.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M2.41309 0.948092H9.86207C10.4256 0.948092 10.8825 1.40495 10.8825 1.9685V9.41748"
				stroke="#5B5B5B"
				stroke-width="1.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M4.8623 5.43804V8.49927"
				stroke="#5B5B5B"
				stroke-width="1.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
			<path
				d="M3.33154 6.96851H6.39277"
				stroke="#5B5B5B"
				stroke-width="1.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default DuplicateIcon;
