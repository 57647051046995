import SearchIcon from "@mui/icons-material/Search";
import { Box } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./SearchBar.module.scss";

interface IProps {
	onSearch: (query: string) => void;
}
const SearchBar: React.FC<IProps> = ({ onSearch }) => {
	const [query, setQuery] = useState("");
	const { t } = useTranslation("translation", { keyPrefix: "account.users" });

	const handleSearch = (newQuery: string) => {
		setQuery(newQuery);
		onSearch(newQuery.toLowerCase());
	};

	return (
		<Box className={styles.searchbar}>
			<Box className={styles.icon}>
				<SearchIcon />
			</Box>
			<InputBase
				className={styles.input}
				placeholder={t("searchBar.title")}
				value={query}
				onChange={(e) => handleSearch(e.target.value)}
			/>
		</Box>
	);
};

export default SearchBar;
