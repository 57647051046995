import styled from "@emotion/styled";
import { Box, Button, ButtonProps, CircularProgress } from "@mui/material";
import { CSSProperties } from "react";
import styles from "./BriaButton.module.scss";

export type ButtonTypes =
	| "primary"
	| "outlined"
	| "tertiaryMedium"
	| "secondarySmall"
	| "secondaryMedium"
	| "primaryMedium"
	| "textSmall"
	| "textMedium";

export type BriaButtonProps = {
	buttonType?: ButtonTypes;
	style?: CSSProperties;
	className?: any;
	disabledColor?: string;
	loading?: boolean;
	loadingPosition?: "start" | "no-text" | "end";
} & ButtonProps;

const StyledBriaButton = styled(Button)<BriaButtonProps>`
	&.Mui-disabled {
		color: ${({ disabledColor }) => disabledColor || "unset"};
	}
`;

const BriaButton = ({
	children,
	buttonType,
	style,
	className,
	disabledColor,
	loading,
	loadingPosition = "end",
	...rest
}: BriaButtonProps) => {
	return (
		<StyledBriaButton
			{...rest}
			style={style}
			disabledColor={disabledColor}
			className={`${buttonType ? styles[buttonType] : ""} ${styles.textCommonStyle} ${className}`}
			endIcon={
				loading && loadingPosition === "end" ? (
					<>
						{rest.endIcon}
						<CircularProgress color="inherit" size={18} />
					</>
				) : (
					rest.endIcon
				)
			}
			startIcon={
				loading && loadingPosition === "start" ? (
					<>
						<CircularProgress color="inherit" size={18} />
						{rest.startIcon}
					</>
				) : (
					rest.startIcon
				)
			}
		>
			{loading && loadingPosition === "no-text" ? (
				<>
					<CircularProgress sx={{ position: "absolute" }} color="inherit" size={18} />
					<Box sx={{ opacity: 0 }}>{children}</Box>
				</>
			) : (
				children
			)}
		</StyledBriaButton>
	);
};

export default BriaButton;
