import { Block } from "baseui/block";
import { Delete } from "baseui/icon";
import { throttle } from "lodash";
import React from "react";
import { HexColorPicker } from "react-colorful";
import Scrollable from "../../../../../components/Scrollable";
import { useActiveObject } from "../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../hooks/useEditor";

const PRESET_COLORS = [
	"#f44336",
	"#ff9800",
	"#ffee58",
	"#66bb6a",
	"#26a69a",
	"#03a9f4",
	"#3f51b5",
	"#673ab7",
	"#9c27b0",
	"#ec407a",
	"#8d6e63",
	"#d9d9d9",
];

export default function () {
	const [_color, setColor] = React.useState("#b32aa9");
	const activeObject = useActiveObject();
	const editor = useEditor();

	const updateObjectFill = throttle((color: string) => {
		if (activeObject) {
			editor?.objects.update({ fill: color });
		}

		setColor(color);
	}, 100);

	return (
		<Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
			<Block
				$style={{
					display: "flex",
					alignItems: "center",
					fontWeight: 500,
					justifyContent: "space-between",
					padding: "1.5rem",
				}}
			>
				<Block>Path Fill</Block>

				<Block $style={{ cursor: "pointer", display: "flex" }}>
					<Delete size={24} />
				</Block>
			</Block>
			<Scrollable>
				<Block padding={"0 1.5rem"}>
					<HexColorPicker onChange={updateObjectFill} style={{ width: "100%" }} />
					<Block>
						<Block $style={{ padding: "0.75rem 0", fontWeight: 500, fontSize: "14px" }}>
							Preset colors
						</Block>
						<Block
							$style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", gap: "0.25rem" }}
						>
							{PRESET_COLORS.map((color, index) => (
								<Block
									$style={{
										cursor: "pointer",
									}}
									onClick={() => updateObjectFill(color)}
									backgroundColor={color}
									height={"38px"}
									key={index}
								></Block>
							))}
						</Block>
					</Block>
				</Block>
			</Scrollable>
		</Block>
	);
}
