import { fabric } from "fabric";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useRef } from "react";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { PanelType } from "../../constants/app-options.ts";
import { PopUpSteps } from "../../constants/mock-data.ts";
import { DesignEditorContext } from "../../contexts/DesignEditor.tsx";
import useAppContext from "../../hooks/useAppContext.tsx";
import useDesignEditorContext from "../../hooks/useDesignEditorContext";
import { useEditor } from "../../hooks/useEditor.tsx";
import { ILayer } from "../../types";
import GraphicEditor from "./GraphicEditor";
import { ObjectsEnum } from "./components/Panels/panelItems";
import Preview from "./components/Preview";
import { useSmartImageUtils } from "./utils/smartImageUtils.ts";

function DesignEditor(props: { isPopup?: boolean }) {
	const TEXT_INDEX = PopUpSteps.indexOf("text");
	const { setActivePanel } = useAppContext();
	const editor = useEditor();
	const { findObjectFromScene } = useSmartImageUtils();
	const draggedObjectRef = useRef<ILayer | undefined>(undefined);
	const { designEditorStore, uiStore, imagesStore } = useAppStore();
	const { displayPreview, setDisplayPreview } = useDesignEditorContext();
	const { isPopupView } = useContext(DesignEditorContext);
	const SMART_IMAGE_INDEX = PopUpSteps.indexOf("images");
	useEffect(() => {
		if (designEditorStore.activeStep === TEXT_INDEX) {
			setActivePanel(PanelType.INSERTTEXT);
		} else {
			if (isPopupView) setActivePanel(designEditorStore.editorStepperSteps[designEditorStore.activeStep].tabName);
		}

		const originalImage = editor?.objects.findOneById(ObjectsEnum.OriginalImage);
		const smartImage = editor?.objects.findOneById(ObjectsEnum.SmartImage);
		designEditorStore.setProperty("hasSmartImages", smartImage && originalImage);
	}, [designEditorStore.activeStep]);

	useEffect(() => {
		const handleTemplateLoading = async () => {
			uiStore.hideSideBar();
			if (designEditorStore.designEditorPopup && designEditorStore.activeStep !== 1) {
				imagesStore.resetSmartImage(null);
				designEditorStore.setProperty("editorStepperDisabledStep", undefined);
			} else {
				setActivePanel(PanelType.CAMPAIGNS);
			}
		};

		handleTemplateLoading();
	}, [designEditorStore.designEditorPopup]);

	useEffect(() => {
		if (editor) {
			editor.canvas.canvas.on("object:added", onAddedObject);
			if (!isPopupView) {
				// @ts-ignore
				editor?.canvas.canvas.on({
					"mouse:up": onMouseUp,
					"object:scaling": onMouseScaling,
					"object:added": onAddedObject,
					"object:moving": onObjectMoving,
				});
			}
		}

		return () => {
			if (designEditorStore.activeStep !== SMART_IMAGE_INDEX) {
				imagesStore.resetSmartImage(editor);
			}

			// @ts-ignore
			editor?.canvas.canvas.on({
				"mouse:up": onMouseUp,
				"object:scaling": onMouseScaling,
				"object:added": onAddedObject,
				"object:moving": onObjectMoving,
			});
		};
	}, [editor]);

	const onAddedObject = async (e: fabric.IEvent<MouseEvent>) => {
		// Set hasSmartImage variable
		if (!editor) return;
		const _currentScene = editor.scene.exportToJSON();
		const originalImage = await findObjectFromScene(_currentScene, ObjectsEnum.OriginalImage);
		const smartImage = await findObjectFromScene(_currentScene, ObjectsEnum.SmartImage);
		designEditorStore.setProperty("hasSmartImages", !!smartImage && !!originalImage);

		// set originalInnerRectangle: This will save the original values for the reset functionality
		const numberOfAddedObjects = _currentScene.layers.length ?? 0;
		const obj: ILayer = await editor.objects.exportFabricObjectAsLayer(e.target as fabric.Object);

		if (numberOfAddedObjects <= 2) {
			designEditorStore.setProperty("originalInnerRectangle", undefined);
		}
		if (obj?.type?.toLowerCase() === "group") {
			const foundObject = await findObjectFromScene(_currentScene, ObjectsEnum.InnerRectangle);
			if (foundObject) {
				!designEditorStore.originalInnerRectangle &&
					designEditorStore.setProperty("originalInnerRectangle", obj);
			}
		} else if (obj && obj.id === ObjectsEnum.InnerRectangle) {
			!designEditorStore.originalInnerRectangle && designEditorStore.setProperty("originalInnerRectangle", obj);
		}
	};

	const onMouseScaling = async (e: fabric.IEvent<MouseEvent>) => {
		if (!editor) return;
		const obj: ILayer = await editor.objects.exportFabricObjectAsLayer(e.target as fabric.Object);
		if (obj?.id === ObjectsEnum.InnerRectangle) {
			designEditorStore.setProperty("originalInnerRectangle", obj);
		}
	};

	const onMouseUp = async () => {
		if (!editor) return;
		if (draggedObjectRef.current?.id === ObjectsEnum.InnerRectangle) {
			designEditorStore.setProperty("originalInnerRectangle", draggedObjectRef.current);
		}

		draggedObjectRef.current = undefined;
	};

	const onObjectMoving = async (e: fabric.IEvent<MouseEvent>) => {
		if (!editor) return;
		const GRID_SIZE = 10;
		const fabricObject = e.target;
		if (fabricObject?.id === ObjectsEnum.InnerRectangle) {
			const obj: ILayer = await editor.objects.exportFabricObjectAsLayer(fabricObject as fabric.Object);
			draggedObjectRef.current = obj ?? undefined;
		}
		if (fabricObject && fabricObject.left && fabricObject.top) {
			fabricObject.set({
				left: Math.round(fabricObject.left / GRID_SIZE) * GRID_SIZE,
				top: Math.round(fabricObject.top / GRID_SIZE) * GRID_SIZE,
			});

			fabricObject.setCoords();
		}
	};

	return (
		<>
			{displayPreview && <Preview isOpen={displayPreview} setIsOpen={setDisplayPreview} />}
			<GraphicEditor isPopup={props.isPopup} />
		</>
	);
}

const ObservedComponent = observer(DesignEditor);
export default ObservedComponent;
