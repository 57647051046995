import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ImageEditor from "../../components/AIEditor/ImageEditor/ImageEditor.tsx";
import Objects from "../../components/AIEditor/Objects/Objects.tsx";
import Sidebar from "../../components/AIEditor/Sidebar/Sidebar.tsx";
import { useAppStore } from "../../hooks/useStores.tsx";
import { AIEditorOptions, AIEditorOptionsEnum, ObjectsRefine } from "../../models/aiEditor.ts";
import styles from "./AIEditor.module.scss";

export type AIEditorNavOptions = {
	selectedImageUrl?: string;
	refine?: ObjectsRefine;
};

const AIEditor = () => {
	const { uiStore, aiEditorStore, objectsStore } = useAppStore();
	const location = useLocation();
	const navigateOptions = location.state as AIEditorNavOptions;

	useEffect(() => {
		uiStore.hideSideBar();
		aiEditorStore.setProperty("selectedImageUrl", navigateOptions?.selectedImageUrl ?? "");
		objectsStore.setProperty("refine", { ...objectsStore.refine, ...navigateOptions?.refine });

		if (!navigateOptions?.selectedImageUrl || navigateOptions?.selectedImageUrl === "") {
			aiEditorStore.setProperty("openImageUploadPopup", true);
		}

		return () => {
			uiStore.showSideBar();
			aiEditorStore.setProperty("openImageUploadPopup", false);
		};
	}, []);

	return (
		<Box className={styles.container}>
			<Sidebar items={AIEditorOptions} />
			<Box className={styles.toolsEditor}>
				{aiEditorStore.selectedEditorTabLabel === AIEditorOptionsEnum.Objects ? <Objects /> : null}
			</Box>
			<Box className={styles.imageEditor}>
				<ImageEditor />
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(AIEditor);
export default ObservedComponent;
