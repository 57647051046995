import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AppsWelcomePopup from "../../components/common/AppsWelcomePopup/AppsWelcomePopup";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../constants/RouterConstants";
import { USER_SETTINGS } from "../../constants/UserConstants";
import { getSelectedOrganization, setSelectedOrganization } from "../../helpers/localStorage";
import useOrgNavigation from "../../hooks/useOrgNavigation";
import { useAppStore } from "../../hooks/useStores";
import { useSubscription } from "../../hooks/useSubscription.tsx";
import styles from "./PrivateLayout.module.scss";

interface LayoutProps {
	ignoreUserChecks?: boolean;
	ignoreOrgs?: boolean;
	displayAppsWelcomePopup?: boolean;
}

const PrivateLayout = ({
	ignoreUserChecks = false,
	ignoreOrgs = true,
	displayAppsWelcomePopup = false,
}: LayoutProps) => {
	const { authStore, appStore } = useAppStore();
	const [showWelcomePopup, setShowWelcomePopup] = useState(false);
	const { isRouteRequireOrg } = useOrgNavigation();
	const loc = useLocation();
	const { isPremiumOrgSubscription } = useSubscription();

	useEffect(() => {
		const loadData = async () => {
			if (authStore.user) {
				const hidePlatformAppsWelcomePopup = await authStore.user?.getFreshSettingsCopy(
					USER_SETTINGS.hidePlatformAppsWelcomePopup,
				);
				setShowWelcomePopup(!hidePlatformAppsWelcomePopup && !isPremiumOrgSubscription());
			}
		};

		loadData();
	}, [authStore.user]);

	const getContent = (): ReactNode => {
		const queryParams = new URLSearchParams(location.search);

		if (isRouteRequireOrg(window.location.pathname)) {
			return <Navigate to={`${RouterConstants.CONSOLE.fullPath}?${queryParams.toString()}`} state={loc.state} />;
		}

		if (!authStore.isLoggedIn) {
			let queryString = "";
			if (!ignoreUserChecks && location.pathname) {
				const newQueryParams = new URLSearchParams();
				newQueryParams.set("continueUrl", `${location.pathname}?${queryParams.toString()}`);
				queryString = newQueryParams.toString();
			}
			if (location.pathname && location.pathname.includes("apps")) {
				return <Navigate to={`${RouterConstants.SIGNUP.path}?${queryString}`} state={loc.state} />;
			} else {
				return <Navigate to={`${RouterConstants.LOGIN.path}?${queryString}`} state={loc.state} />;
			}
		} else {
			if (
				/**
				 * We always prioritize the user invitations, we don't need to create an organization of fill the
				 * user info in case of finding a pending invitation (the user info will be filled on the next login)
				 */
				!getSelectedOrganization() &&
				!ignoreUserChecks &&
				!authStore?.isError &&
				!(
					appStore.invitedUser &&
					appStore.invitedUser.email?.toLowerCase() === authStore?.user?.email?.toLowerCase()
				)
			) {
				if (ignoreOrgs && authStore?.userOrganizations.length > 0) {
					setSelectedOrganization(authStore?.userOrganizations[0]);
				}

				if (!authStore.user?.userName || authStore.user?.userName === "") {
					return <Navigate to={RouterConstants.USER_INFO.path} />;
				}
				// else if (authStore?.userOrganizations?.length === 0 && !ignoreOrgs) {
				// 	return <Navigate to={RouterConstants.CREATE_ORGANIZATION.path} />;
				// } else if (authStore?.userOrganizations?.length > 1 && !ignoreOrgs) {
				// 	return <Navigate to={RouterConstants.SELECT_ORGANIZATION.path} />;
				// }
				else {
					return <Outlet />;
				}
			} else {
				return <Outlet />;
			}
		}
	};

	return (
		<LoadingPlaceholder className={styles.loaderStyle} isLoading={authStore.isLoading}>
			{getContent()}
			{!isPremiumOrgSubscription() && !authStore.isLoadingOrgSubscriptions && displayAppsWelcomePopup && (
				<AppsWelcomePopup
					open={showWelcomePopup}
					onClose={() => {
						setShowWelcomePopup(false);
						authStore.user?.updateSettings(USER_SETTINGS.hidePlatformAppsWelcomePopup, true);
					}}
				/>
			)}
		</LoadingPlaceholder>
	);
};

const ObservedComponent = observer(PrivateLayout);
export default ObservedComponent;
