import { isAfter, subYears } from "date-fns";
import React from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";
import { TypeAttributes } from "rsuite/cjs/@types/common";
import styled from "styled-components";
import { PredefinedDateRanges, UsagePeriod } from "../../../models/usage.ts";
import clsx from "clsx";

const StyledDateRangePicker = styled.div`
	.rs-input-group {
		height: 32px;
		width: 220px;
		border: 1px solid #b3b3b9;
	}

	.rs-input-group:not(.rs-input-group-disabled):hover,
	.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
		border-color: #1a0638;
	}

	.rs-input-group:focus-within {
		border-color: #fff;
		outline: 1px solid #8800ff;
	}

	input::placeholder {
		font-size: 14px;
		color: #1a0638;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}

	.without-icon {
		.rs-input-group {
			width: 180px;
		}
		.rs-input-group-addon {
			display: none;
		}
	}

	input {
		pointer-events: none;
	}
`;

interface IProps {
	loading?: boolean;
	placement?: TypeAttributes.Placement | undefined;
	value?: [Date, Date] | undefined;
	hoverRange?: UsagePeriod.Weekly | UsagePeriod.Monthly | ((date: Date) => [Date, Date]);
	onChange: (props: any) => void;
	cleanable: boolean;
}

const CustomDateRangePicker: React.FC<IProps> = ({
	loading,
	placement,
	value,
	hoverRange,
	cleanable = true,
	...rest
}) => {
	const oneYearAgo = subYears(new Date(), 1);
	return (
		<StyledDateRangePicker>
			<DateRangePicker
				ranges={PredefinedDateRanges}
				showHeader={false}
				disabled={loading}
				loading={loading}
				placement={placement}
				value={value}
				hoverRange={hoverRange}
				shouldDisableDate={(date) => isAfter(date, new Date()) || isAfter(oneYearAgo, date)}
				cleanable={cleanable}
				className={clsx({
					"without-icon": !cleanable && !loading
				})}
				{...rest}
			/>
		</StyledDateRangePicker>
	);
};

export default CustomDateRangePicker;
