import { useEffect, useState } from "react";
import { getSelectedOrganization } from "../helpers/localStorage";
import {
	IFRAME_FEATURES_MAP,
	IFRAME_LANGUAGES_MAP,
	IFRAME_PAGES_MAP,
	IframeFormType,
	IframeUrlParams,
	PLAYGROUND_VHASH_EXAMPLE,
	PagesEnum,
	getIframeHost,
} from "../pages/Iframe/iframeTypes";

const mapObjectToIdStr = (
	object: { [key: string]: boolean } = {},
	mapper: typeof IFRAME_PAGES_MAP | typeof IFRAME_FEATURES_MAP | typeof IFRAME_LANGUAGES_MAP,
) => {
	return Object.keys(object ?? {})
		.filter((key) => object[key])
		.map((key) => mapper[key as keyof typeof mapper])
		.join(",");
};

const useIframePreview = (iframeForm: IframeFormType, previewPage?: string) => {
	const [iframeUrl, setIframeUrl] = useState("");
	useEffect(() => {
		const generateIframeUrl = () => {
			const iframeUrlParams: IframeUrlParams = {
				iframeId: "preview",
				target: previewPage,
				enabledTgModels: (iframeForm.enabled_tg_models ?? []).join(","),
				enabledPages: mapObjectToIdStr(iframeForm?.enabled_pages, IFRAME_PAGES_MAP),
				enabledLanguages: mapObjectToIdStr(iframeForm?.enabled_languages, IFRAME_LANGUAGES_MAP),
				defaultGenAspectRatio: iframeForm.default_gen_aspect_ratio,
				color: iframeForm?.custom_style?.primaryColor,
				enabledFeatures: mapObjectToIdStr(iframeForm?.enabled_features, IFRAME_FEATURES_MAP),
				vhash: previewPage === PagesEnum.Playground ? PLAYGROUND_VHASH_EXAMPLE : undefined,
				usageText: iframeForm.usageText,
				embeddedIframe: iframeForm.embedded_iframe === false ? "false" : "true",
				enableDownload: iframeForm.enable_download ? "true" : "false",
				enableCloseButton: iframeForm.enable_close_button ? "true" : "false",
				disableDownloadPsd: iframeForm.disable_download_psd ? "true" : undefined,
				disableUpload: iframeForm.disable_upload ? "true" : undefined,
				disableSave: iframeForm.disable_save ? "true" : undefined,
				disableSavePsd: iframeForm.disable_save_psd ? "true" : undefined,
				saveLabel: iframeForm?.save_label,
				orgIdForGallery: iframeForm.use_public_gallery
					? undefined
					: getSelectedOrganization()?.organization.uid,
				orgId: getSelectedOrganization()?.organization.uid,
				bria2_3_fast: iframeForm.gen_models?.bria2_3_fast,
				bria2_2_hd: iframeForm.gen_models?.bria2_2_hd,
				bria2_3: iframeForm.gen_models?.bria2_3,
			};

			const queryParams = new URLSearchParams(
				Object.fromEntries(
					Object.entries(iframeUrlParams).filter(
						([_key, value]) => value !== undefined && value !== null && value.length > 0,
					),
				),
			);

			return `${getIframeHost()}/?${queryParams.toString()}`;
		};
		setIframeUrl(generateIframeUrl());
	}, [iframeForm, previewPage]);

	return iframeUrl;
};

export default useIframePreview;
