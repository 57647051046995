import { Block } from "baseui/block";
import { Modal, ModalBody, ROLE, SIZE } from "baseui/modal";
import Graphic from "./Graphic";

interface ComponentProps {
	isOpen: boolean;
	setIsOpen: (v: boolean) => void;
}

export default function ({ isOpen, setIsOpen }: ComponentProps) {
	return (
		<Modal
			onClose={() => setIsOpen(false)}
			closeable
			isOpen={isOpen}
			animate
			autoFocus
			size={SIZE.full}
			role={ROLE.dialog}
			overrides={{
				Root: {
					style: {
						zIndex: 5,
					},
				},
				Dialog: {
					style: {
						marginTop: 0,
						marginLeft: 0,
						marginRight: 0,
						marginBottom: 0,
						borderTopRightRadius: 0,
						borderTopLeftRadius: 0,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					},
				},
			}}
		>
			<ModalBody
				$style={{
					display: "flex",
					flexDirection: "column",
					marginTop: 0,
					marginLeft: 0,
					marginRight: 0,
					marginBottom: 0,
					height: "100%",
					position: "relative",
				}}
			>
				<Block
					$style={{
						position: "absolute",
						flex: 1,
						height: "100%",
						width: "100%",
						display: "flex",
					}}
				>
					<Graphic />
				</Block>
			</ModalBody>
		</Modal>
	);
}
