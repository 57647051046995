import { ButtonProps } from "@mui/material";
import clsx from "clsx";
import React from "react";
import BriaTooltip from "../BriaTooltip/BriaTooltip";
import styles from "./BriaIconButton.module.scss";

export type ButtonTypes = "primary" | "outlined" | "secondary" | "secondaryLarge";

export type BriaButtonProps = {
	title?: string;
	buttonType?: ButtonTypes;
	className?: any;
	disabled?: boolean;
	component?: React.ElementType;
	onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
} & ButtonProps;

const BriaIconButton: React.FC<BriaButtonProps> = ({
	title,
	children,
	buttonType = "secondary",
	className,
	disabled,
	component = "button",
	onClick,
	...props
}) => {
	const Component = component;
	const handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
		if (onClick) {
			onClick(event);
		}
	};

	return (
		<BriaTooltip title={title}>
			<Component
				tabindex="0"
				className={clsx(className, styles.iconButton, {
					[styles.primaryBtn]: buttonType === "primary",
					[styles.outlinedBtn]: buttonType === "outlined",
					[styles.secondaryBtn]: buttonType === "secondary",
					[styles.secondaryLargeBtn]: buttonType === "secondaryLarge",
				})}
				disabled={disabled}
				onClick={handleClick}
				{...props}
			>
				{children}
			</Component>
		</BriaTooltip>
	);
};

export default BriaIconButton;
