import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import LoadingOverlay from "../../../../../../components/common/LoadingOverlay/LoadingOverlay.tsx";
import { useAppStore } from "../../../../../../hooks/useStores.tsx";
import { defaultBrandDefinition } from "../../../../../../models/brandDefinition.ts";
import { DEFAULT_SCENE_PROCESSING_TIME } from "../../../../../constants/contants.ts";
import { PopUpSteps } from "../../../../../constants/mock-data.ts";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import { useEditor } from "../../../../../hooks/useEditor.tsx";
import useIsSidebarOpen from "../../../../../hooks/useIsSidebarOpen.tsx";
import useSetIsSidebarOpen from "../../../../../hooks/useSetIsSidebarOpen.tsx";
import { IStaticImage, type IScene } from "../../../../../types";
import { useSmartImageUtils } from "../../../utils/smartImageUtils.ts";
import Canvas from "../../Canvas";
import Footer from "../../Footer";
import Panels from "../../Panels";
import { ObjectsEnum, SecondaryTabTypeEnum } from "../../Panels/panelItems";
import Toolbox from "../../Toolbox";
import CampaignInformation from "../CamapignInformation/CampaignInformation.tsx";
import CampaignEmptyState from "../CampaignEmptyState/CampaignEmptyState.tsx";
import CampaignResult from "../CampaignResult/CampaignResult.tsx";
import styles from "./CampaignWorkspace.module.scss";

const CampaignWorkspace = () => {
	const editor = useEditor();
	const isSidebarOpen = useIsSidebarOpen();
	const { resetRectangleInAGroup, findObjectFromScene } = useSmartImageUtils();
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const CAMPAIGN_INDEX = PopUpSteps.indexOf("campaigns");
	const SMART_IMAGE_INDEX = PopUpSteps.indexOf("images");
	const { designEditorStore, imagesStore, brandsDefinitionStore, campaignStore, playgroundStore } = useAppStore();
	const { isPopupView, scenes, setScenes, setCurrentScene } = useContext(DesignEditorContext);
	const closeSidebar = () => setIsSidebarOpen(false);
	const openSidebar = () => setIsSidebarOpen(true);

	useEffect(() => {
		if (brandsDefinitionStore.brands.items.length === 0 && !brandsDefinitionStore.isLoading) {
			brandsDefinitionStore.loadBrandsDefinition();
		}
		designEditorStore.setProperty("campaignUpdatedTemplate", undefined);

		return () => {
			openSidebar();
			designEditorStore.setProperty("activeStep", 0);
			designEditorStore.setProperty("newStep", 0);
			brandsDefinitionStore.setProperty("selectedBrand", defaultBrandDefinition);
			campaignStore.handleSetSelectedWizardTemplate(null);
		};
	}, []);

	useEffect(() => {
		const updateWizard = async () => {
			const { newStep } = designEditorStore;

			if (newStep === CAMPAIGN_INDEX) {
				closeSidebar();
			} else if (newStep === SMART_IMAGE_INDEX) {
				designEditorStore.setProperty("isLoadingSmartImageStep", true);
				openSidebar();
				await addObject();
				imagesStore.clearSmartImageForm();
				playgroundStore.setTextToGenerate("");
				if (imagesStore.smartImagesHistory.length > 1) {
					imagesStore.smartImagesHistory = imagesStore.smartImagesHistory.slice(0, 1);
				}
				imagesStore.setProperty("secondaryTabType", SecondaryTabTypeEnum.SMART_IMAGE);
				designEditorStore.setProperty("isLoadingSmartImageStep", false);
			} else {
				openSidebar();
			}
			designEditorStore.setProperty("activeStep", newStep);
		};
		updateWizard();
	}, [designEditorStore.newStep]);

	const addObject = React.useCallback(async () => {
		/*
		 * This is special case for the campaign wizard,
		 * Once "Edit main object" is clicked we will retrieve the stored images and redraw them in the canvas
		 * */
		if (editor && isPopupView) {
			const _currentScene = editor.scene.exportToJSON();
			let foundSmartGroup = await findObjectFromScene(_currentScene, ObjectsEnum.SmartImage, true);
			if (foundSmartGroup?.type?.toLowerCase() !== "group") {
				foundSmartGroup = undefined;
			}
			if (foundSmartGroup) {
				const smartImage = await findObjectFromScene(_currentScene, ObjectsEnum.SmartImage);
				const smartImageUrl = (smartImage as IStaticImage)?.src;
				smartImageUrl &&
					imagesStore.smartImagesHistory.unshift({
						id: uuidv4(),
						visual_hash: "",
						url: smartImageUrl,
						seed: 0,
						sid: "",
						input_params: {
							...(smartImage?.metadata as any),
						},
					});

				// Upload and draw the original image and its background options (Expand & Replace)
				imagesStore.secondaryTabType = SecondaryTabTypeEnum.SMART_IMAGE;
				imagesStore.setProperty("smartImageBackgroundOptions", []);
				designEditorStore.setProperty("hasSmartImages", false);

				await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);
				await resetRectangleInAGroup(_currentScene, ObjectsEnum.InnerRectangle);

				if (campaignStore.selectedWizardTemplate) {
					const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
					const updatedScene = { ..._currentScene, preview: updatedPreview, duration: 1000 };
					const updatedScenes = campaignStore.selectedWizardTemplate?.ads_json.scenes.map((scene: IScene) => {
						if (scene.id === updatedScene.id) {
							return { ...updatedScene, preview: updatedPreview };
						}
						return editor.scene.formalizeSceneAttributes(scene);
					}) as IScene[];
					setScenes(updatedScenes);
					setCurrentScene(updatedScene);
				}
			}
		}
	}, [editor]);

	return (
		<Box component="div" className={styles.graphicContainer}>
			<Panels />
			<Box component="div" className={styles.flexColumn}>
				{designEditorStore.activeStep != CAMPAIGN_INDEX && <Toolbox />}
				<Box
					component="div"
					className={clsx({
						[styles.graphicSubContainer]: true,
						[styles.graphicSubContainerExtension]: !isSidebarOpen,
					})}
				>
					{!designEditorStore.isGeneratingAdvertisements ? (
						<CampaignResult />
					) : (
						<>
							{designEditorStore.isTemplateEmptyState && <CampaignEmptyState />}
							{designEditorStore.activeStep === CAMPAIGN_INDEX && <CampaignInformation />}
							<LoadingOverlay
								progressBarTime={DEFAULT_SCENE_PROCESSING_TIME * scenes.length}
								loading={designEditorStore.isGenerateAdsLoading}
								coverImageUrl={scenes?.[0]?.preview}
								className={clsx({
									[styles.hiddenVisibility]: designEditorStore.isTemplateEmptyState,
								})}
							>
								<>
									<Canvas id={"bria_campaign_wizard_canvas"} />
									<Box className={styles.hide}>
										<Footer />
									</Box>
								</>
							</LoadingOverlay>
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(CampaignWorkspace);
export default ObservedComponent;
