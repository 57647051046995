import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import CompareIcon from "../../../../assets/images/icons/CompareIcon";
import DownloadIcon from "../../../../assets/images/icons/DownloadIcon";
import EyeOffIcon from "../../../../assets/images/icons/EyeOffIcon";
import useImageUtils from "../../../../utils/useImageUtils";
import BriaButtonGroup from "../../BriaButtonGroup/BriaButtonGroup";
import BriaTooltip from "../../BriaTooltip/BriaTooltip";
import { FullScreenImageType } from "../Image/FullScreenImage";
import styles from "./FullScreenImageOverlay.module.scss";

type FullScreenImageOverlayProps = {
	image: FullScreenImageType;
	index: number;
	displayMode: "arrows" | "scroll";
	displayedImages: FullScreenImageType[];
	setDisplayedImages: (images: FullScreenImageType[]) => void;
	onDownload?: () => void;
	onCompare?: () => void;
};

const FullScreenImageOverlay = ({
	image,
	index,
	displayMode,
	displayedImages,
	setDisplayedImages,
	onDownload,
	onCompare,
}: FullScreenImageOverlayProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "briaImage.fullScreenView.overlay" });
	const { downloadImage } = useImageUtils();

	const compareImage = () => {
		onCompare && !displayedImages[index].openCompare && onCompare();
		setDisplayedImages(
			displayedImages.map((image, i) => (index === i ? { ...image, openCompare: !image.openCompare } : image)),
		);
	};

	const hideImage = () => {
		setDisplayedImages(displayedImages.map((image, i) => (index === i ? { ...image, hide: !image.hide } : image)));
	};

	return (
		<Box className={styles.fullScreenImageOverlayContainer}>
			<BriaButtonGroup className={styles.actionBtns} onClick={(e) => e.stopPropagation()}>
				<Box className={styles.rightSection}>
					{image.compareTo && (
						<BriaTooltip title={t("compare")}>
							<IconButton className={clsx(styles.button, "iconHover")} onClick={compareImage}>
								<CompareIcon />
							</IconButton>
						</BriaTooltip>
					)}
					{displayMode === "scroll" && displayedImages.filter((image) => !image.hide).length > 1 && (
						<BriaTooltip title={t("hide")}>
							<IconButton className={clsx(styles.button, "iconHover")} onClick={hideImage}>
								<EyeOffIcon />
							</IconButton>
						</BriaTooltip>
					)}
				</Box>
				<Box className={styles.leftSection}>
					<BriaTooltip title={t("download")}>
						<IconButton
							className={clsx(styles.button, {
								iconHover: true,
							})}
							onClick={() => {
								downloadImage(image.src, image.fileName ?? t("image"));
								onDownload && onDownload();
							}}
						>
							<Typography className={styles.imageFormat}>
								{(image.format ?? "png")?.toUpperCase()}
							</Typography>
							<DownloadIcon />
						</IconButton>
					</BriaTooltip>
				</Box>
			</BriaButtonGroup>
		</Box>
	);
};
export default observer(FullScreenImageOverlay);
