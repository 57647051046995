import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaDropDown from "../../../../../components/common/BriaDropDown/BriaDropDown";
import { useAppStore } from "../../../../../hooks/useStores";
import useDesignEditorUtils, { FeatureType } from "../../../../CustomUtils/UseDesignEditor";
import Icons from "../../../../components/Icons";
import { PanelType } from "../../../../constants/app-options";
import { DesignEditorContext } from "../../../../contexts/DesignEditor.tsx";
import useAppContext from "../../../../hooks/useAppContext";
import { useContextMenuRequest } from "../../../../hooks/useContextMenuRequest";
import useDesignEditorContext from "../../../../hooks/useDesignEditorContext";
import { useEditor } from "../../../../hooks/useEditor";
import { LayerType } from "../../../../types";
import useSceneOperations from "../Footer/Graphic/Scenes/utils/SceneOperations";
import styles from "./ContextMenu.module.scss";
import ContextMenuItem from "./ContextMenuItem";
import { ContextMenuData, ICON_SIZE, OBJECT_TYPES } from "./ContextMenusData";

type Position = { left: number; top: number };
interface PanelConfig {
	panels: PanelType[];
	targetPanel: PanelType;
}
function ContextMenu() {
	const { setContextMenuTimelineRequest, contextMenuTimelineRequest } = useDesignEditorContext();
	const editor = useEditor();
	const { campaignStore } = useAppStore();
	const contextMenuRequest = useContextMenuRequest();
	const { isPopupView } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.dropDownActions" });
	const ContextData = ContextMenuData(t, editor, campaignStore.isAdminMode);
	const [activeObject, setActiveObject] = useState(editor?.canvas?.canvas.getActiveObject()) as any;
	const [contextMenuPosition, setContextMenuPosition] = useState<Position>({
		left: activeObject?.left ?? 0,
		top: activeObject?.top ?? 0,
	});
	const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);
	const [isContextMoving, setIsContextMoving] = useState<boolean>(false);
	const [componentStatus, setComponentStatus] = useState("");
	const [items, setItems] = useState<any>([]);
	const { EditAllAds } = useDesignEditorUtils();
	const { activePanel, setActivePanel } = useAppContext();
	const { deleteScene, duplicateScene } = useSceneOperations();

	useEffect(() => {
		handleUpdateActiveObjectAndPosition();
		setContextMenuOpen(false);
		const canvas = editor?.canvas?.canvas;
		if (activeObject) {
			const isStaticText =
				activeObject?.name === OBJECT_TYPES.STATIC_TEXT ||
				activeObject?.type === OBJECT_TYPES.STATIC_TEXT ||
				activeObject?._objects?.[0]?.type === OBJECT_TYPES.STATIC_TEXT;

			setComponentStatus(
				isStaticText
					? activeObject?.textType || activeObject?._objects?.[0]?.textType
					: activeObject?.imageType || activeObject?._objects?.[0]?.imageType,
			);
			setItems(isStaticText ? ContextData.textComponentStatusMenu : ContextData.imageComponentStatusMenu);
		}

		if (canvas) {
			canvas.on("object:moving", handleObjectMoving);
			canvas.on("object:modified", handleUpdateActiveObjectAndPosition);
			canvas.on("object:scaling", handleUpdateActiveObjectAndPosition);
			window.addEventListener("resize", handleUpdateActiveObjectAndPosition);
		}
		const panelMap: Partial<Record<LayerType, PanelConfig>> = {
			[LayerType.STATIC_TEXT]: {
				panels: [PanelType.SHAPEFILLCOLOR, PanelType.RESIZE, PanelType.CANVASCOLOR],
				targetPanel: PanelType.FONTSELECTOR,
			},
			[LayerType.STATIC_VECTOR]: {
				panels: [PanelType.RESIZE, PanelType.CANVASCOLOR, PanelType.TEXTFILL, PanelType.FONTSELECTOR],
				targetPanel: PanelType.SHAPEFILLCOLOR,
			},
			[LayerType.STATIC_IMAGE]: {
				panels: [
					PanelType.RESIZE,
					PanelType.CANVASCOLOR,
					PanelType.TEXTFILL,
					PanelType.FONTSELECTOR,
					PanelType.SHAPEFILLCOLOR,
				],
				targetPanel: PanelType.IMAGES,
			},
		};

		if (activeObject?.type && activeObject.type in panelMap && !isPopupView) {
			const layerType = activeObject.type as LayerType;
			const panelConfig = panelMap[layerType];
			if (panelConfig && panelConfig.panels.includes(activePanel)) {
				setActivePanel(panelConfig.targetPanel);
			}
		}

		return () => {
			if (canvas) {
				canvas.off("object:moving", handleObjectMoving);
				canvas.off("object:modified", handleUpdateActiveObjectAndPosition);
				canvas.off("object:scaling", handleUpdateActiveObjectAndPosition);
				window.removeEventListener("resize", handleUpdateActiveObjectAndPosition);
			}
		};
	}, [activeObject, editor?.canvas?.canvas.getActiveObject()]);

	useEffect(() => {
		editor?.canvas?.canvas.on("text:editing:exited", async function (e) {
			const modifiedObject = e.target;
			if (modifiedObject?.type === "StaticText") {
				const newTextValue = modifiedObject.text;
				EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.TEXT, newTextValue);
			}
		});
		return () => {
			if (editor?.canvas?.canvas) {
				editor?.canvas?.canvas.off("text:editing:exited");
			}
		};
	}, [activeObject]);

	const handleObjectMoving = () => {
		setIsContextMoving(true);
	};

	const handleUpdateActiveObjectAndPosition = () => {
		setIsContextMoving(false);
		const active: any = editor?.canvas?.canvas.getActiveObject();
		setActiveObject(active);
		setContextMenuPosition({ left: active?.lineCoords?.tr?.x ?? 0, top: active?.lineCoords?.tl?.y ?? 0 });
	};

	const showContextMenu = () => {
		setContextMenuOpen(!contextMenuOpen);
	};

	const renderMenuItems = (menuItems: any[]) => {
		return menuItems.map((item, index) => (
			<Box key={index}>
				{item.divider ? (
					<hr className={styles.divider} />
				) : (
					<ContextMenuItem
						disabled={item.disabled}
						onClick={item.onClick}
						icon={item.icon}
						label={item.label}
						isSubMenu={item.isSubMenu}
						iconOnly={item.iconOnly}
						Selected={item.Selected}
					>
						{item.children && <div className={styles.subMenu}>{renderMenuItems(item.children)}</div>}
					</ContextMenuItem>
				)}
			</Box>
		));
	};

	const renderContextMenu = (menuItems: any[]) => {
		return (
			<Box
				onContextMenu={(e) => e.preventDefault()}
				className={styles.contextMenu}
				style={{
					top: `${contextMenuRequest ? contextMenuRequest.top : 34}px`,
					left: `${contextMenuRequest ? contextMenuRequest.left : -170}px`,
				}}
			>
				{renderMenuItems(menuItems)}
			</Box>
		);
	};
	const renderContextMenuItems = () => {
		if (!contextMenuRequest || !contextMenuRequest.target || isPopupView) {
			return null;
		}
		if (
			contextMenuRequest.target.type === OBJECT_TYPES.FRAME ||
			contextMenuRequest.target.type === OBJECT_TYPES.INITIAL_FRAME
		) {
			return renderContextMenu(ContextData.backgroundMenu);
		}
		if (contextMenuRequest.target.locked) {
			return renderContextMenu(ContextData.lockedMenu);
		}
		return renderContextMenu(ContextData.mainMenu);
	};
	return (
		<>
			{!isContextMoving && activeObject && !isPopupView && (
				<Box
					onContextMenu={(e) => e.preventDefault()}
					className={styles.actionsMenu}
					style={{
						top: `calc(${contextMenuPosition?.top}px - 45px)`,
						left:
							activeObject.type === OBJECT_TYPES.STATIC_VECTOR ||
							activeObject.type === OBJECT_TYPES.FRAME ||
							activeObject.name === OBJECT_TYPES.GROUP
								? `calc(${contextMenuPosition.left}px - 100px)`
								: `calc(${contextMenuPosition.left}px - 270px)`,
					}}
				>
					{activeObject.type !== OBJECT_TYPES.STATIC_VECTOR &&
						activeObject.type !== OBJECT_TYPES.FRAME &&
						activeObject.name !== OBJECT_TYPES.GROUP && (
							<BriaDropDown
								value={componentStatus}
								onChange={(e) => {
									setComponentStatus(e.target.value);
									if (
										activeObject.name === LayerType.STATIC_TEXT ||
										activeObject.type === LayerType.STATIC_TEXT
									) {
										activeObject.textType = e.target.value;
									} else {
										activeObject.imageType = e.target.value;
									}
								}}
								items={items}
								height="20px"
								width="170px"
								className={styles.componentStatus}
								renderValue={(selected) => {
									if (selected) {
										return <Typography className={styles.renderedValue}>{selected}</Typography>;
									} else {
										return (
											<Typography className={styles.renderedValue}>
												{activeObject.type === OBJECT_TYPES.STATIC_TEXT
													? t("configureHeadingLevel")
													: t("configureImageLevel")}
											</Typography>
										);
									}
								}}
								placeholder={
									activeObject.type === OBJECT_TYPES.STATIC_TEXT
										? t("configureHeadingLevel")
										: t("configureImageLevel")
								}
							/>
						)}
					<ContextMenuItem
						onClick={async () => {
							if (activeObject?.type == OBJECT_TYPES.FRAME) {
								if (!contextMenuTimelineRequest.id && editor) {
									setContextMenuTimelineRequest({
										...contextMenuTimelineRequest,
										id: editor?.scene.exportToJSON().id,
									});
								}
								await duplicateScene(editor?.scene.exportToJSON().id);
							} else {
								editor?.objects.copy();
								editor?.objects.paste();
								editor?.cancelContextMenuRequest();
							}
						}}
						icon={<Icons.Duplicate size={ICON_SIZE} />}
						iconOnly={true}
					/>
					<ContextMenuItem
						onClick={async () => {
							if (activeObject?.type == OBJECT_TYPES.FRAME) {
								if (!contextMenuTimelineRequest.id && editor) {
									setContextMenuTimelineRequest({
										...contextMenuTimelineRequest,
										id: editor?.scene.exportToJSON().id, // Replace with the current scene ID
									});
								}
								await deleteScene(editor?.scene.exportToJSON().id);
							} else {
								editor?.objects.remove();
								editor?.cancelContextMenuRequest();
							}
						}}
						icon={<Icons.Delete size={ICON_SIZE} />}
						iconOnly={true}
					/>
					<ContextMenuItem onClick={showContextMenu} icon={<Icons.Dots size={ICON_SIZE} />} iconOnly={true}>
						{contextMenuOpen &&
							(!activeObject?.locked
								? activeObject.type === OBJECT_TYPES.FRAME ||
								  activeObject.type === OBJECT_TYPES.INITIAL_FRAME
									? renderContextMenu(ContextData.backgroundMenu)
									: renderContextMenu(ContextData.mainMenu)
								: renderContextMenu(ContextData.lockedMenu))}
					</ContextMenuItem>
				</Box>
			)}
			{renderContextMenuItems()}
		</>
	);
}

const ObservedComponent = observer(ContextMenu);
export default ObservedComponent;
