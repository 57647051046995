import AssetsCreationAtScale from "../assets/images/Assets_Creation_At_Scale.png";
import BrandCharacters from "../assets/images/Brand_Characters.png";
import BrandedAssets from "../assets/images/Branded_Assets.png";
import IconsAndIllustrations from "../assets/images/Icons_And_Illustrations.png";
import StoryBoarding from "../assets/images/Story_Boarding.png";
import RouterConstants from "./RouterConstants";

export interface ITailoredModelsFeaturesConstants {
	title: string;
	link?: any;
	image?: string;
}

export const TailoredModelsFeatures: ITailoredModelsFeaturesConstants[] = [
	{
		title: "brandedAssets",
		link: RouterConstants.TG_MODELS.fullPath,
		image: BrandedAssets,
	},
	{
		title: "brandCharacters",
		link: RouterConstants.TG_MODELS.fullPath,
		image: BrandCharacters,
	},
	{
		title: "iconsAndIllustrations",
		link: RouterConstants.TG_MODELS.fullPath,
		image: IconsAndIllustrations,
	},
	{
		title: "assetsCreationAtScale",
		link: RouterConstants.TG_MODELS.fullPath,
		image: AssetsCreationAtScale,
	},
	{
		title: "storyBoarding",
		link: RouterConstants.TG_MODELS.fullPath,
		image: StoryBoarding,
	},
];
