import { useState } from "react";
import AlignmentIcon from "../../../assets/images/icons/Alignment/Object/ObjectAlignmentIcon";
import { Alignment } from "../../../models/layout";
import ObjectAlignmentPopover from "./ObjectAlignmentPopover";

type Props = {
	value?: Alignment;
	handleIconClick?: (alignment: Alignment) => void;
};

const ObjectAlignmentButton = ({ value, handleIconClick }: Props) => {
	const [anchorEl, setAnchorEl] = useState<SVGSVGElement>();

	const handleOpenDialog = (event: React.MouseEvent<SVGSVGElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	return (
		<>
			<AlignmentIcon
				sx={{
					width: "18px",
					cursor: "pointer",
				}}
				onClick={handleOpenDialog}
			/>
			<ObjectAlignmentPopover
				open={!!anchorEl}
				value={value}
				handleIconClick={handleIconClick}
				handleClose={handleClose}
				anchorEl={anchorEl}
			/>
		</>
	);
};

export default ObjectAlignmentButton;
