import AddIcon from "@mui/icons-material/Add";
import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import { FilterByOptions, PaginationOptions } from "../../../components/common/BriaTable/BriaTable";
import BriaTooltip from "../../../components/common/BriaTooltip/BriaTooltip";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import EmptyTable from "../../../components/common/EmptyTable/EmptyTable";
import FileUploader from "../../../components/common/FileUploader/FileUploader";
import { VisuallyHiddenInput } from "../../../components/common/VisuallyHiddenInput";
import { useAppStore } from "../../../hooks/useStores";
import { useUserChecks } from "../../../hooks/useUserChecks";
import TableLayout, { TableRow } from "../../../layout/TableLayout/TableLayout";
import { Font, defaultFont } from "../../../models/font";
import { formatDate } from "../../../utils";
import styles from "./Fonts.module.scss";

type FontTableRow = {
	fontName: string | ReactElement;
	updated: string | ReactElement;
	actions: ReactElement;
};

const Fonts = () => {
	const { t: ct } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.fonts" });
	const { fontsStore } = useAppStore();
	const { canUserEditOrganization } = useUserChecks();
	``;
	const [tableData, setTableData] = useState<TableRow<FontTableRow>[]>();
	const [fontToDelete, setFontToDelete] = useState<Font>();
	// const [loadingRow, setLoadingRow] = useState<{ data: Font; column: string }>();
	const [openDeletePopup, setOpenDeletePopup] = useState(false);
	const headerArray = [t("table.headers.name"), t("table.headers.updated_at"), t("table.headers.actions")];
	const [filterBy, setFilterBy] = useState<FilterByOptions>();
	const paginationOptions: PaginationOptions = {
		rowsPerPage: fontsStore.rowsPerPage,
		totalRows: fontsStore.paginatedFonts.total,
		loadNextPage: async (pageNumber: number, filterBy?: FilterByOptions) => {
			await fontsStore.loadFonts(pageNumber, false, filterBy);
		},
	};

	useEffect(() => {
		fontsStore.loadFonts(1).then(() => {
			setTableData(fontsStore.paginatedFonts.items.map(createRow));
		});
	}, []);

	useEffect(() => {
		if (!fontsStore.isLoading) {
			const newTableData: TableRow<FontTableRow>[] = fontsStore.paginatedFonts.items.map(createRow);
			setTableData([...newTableData]);
		}
	}, [fontsStore.paginatedFonts.items]);

	useEffect(() => {
		if (tableData) {
			fontsStore.isLoading = false;
		}
	}, [tableData]);

	const uploadFont = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: File | null = e.target.files && e.target.files[0];
		e.target.value = "";
		file && (await fontsStore.createFont({ ...defaultFont, name: file.name.split(".")[0] }, file));
	};

	const deleteFont = async () => {
		if (fontToDelete) {
			await fontsStore.deleteFont(fontToDelete.id);
			closeDeletePopup();
		}
	};

	const createRow = (font: Font): TableRow<FontTableRow> => {
		return {
			key: font.id,
			data: {
				fontName: <Box className={styles.nameStyle}>{font.name}</Box>,
				updated: <Box>{formatDate(font.updated_at)}</Box>,
				actions: (
					<Box className={styles.actionsContainer}>
						{[
							{
								icon: (
									<BriaTooltip title={t("delete")}>
										<DeleteIcon className={styles.actionBtn} />
									</BriaTooltip>
								),
								onClick: () => canUserEditOrganization() && handleOpenDeletePopup(font),
							},
						].map((action, index) => (
							<Box
								key={index}
								onClick={action.onClick}
								className={clsx({
									[styles.disabledOpacity]: !canUserEditOrganization(),
								})}
							>
								{action.icon}
							</Box>
						))}
					</Box>
				),
			},
		};
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const updatedFilterBy: FilterByOptions = {
			...filterBy,
			search: { text: e.target.value, fields: ["name", "updated_at"] },
		};
		setFilterBy(updatedFilterBy);
		await fontsStore.loadFonts(1, false, updatedFilterBy);
	};

	const handleOpenDeletePopup = (font: Font) => {
		setOpenDeletePopup(true);
		setFontToDelete(font);
	};

	const closeDeletePopup = () => {
		setOpenDeletePopup(false);
		setFontToDelete(undefined);
	};

	return (
		<>
			<TableLayout
				title={t("table.title")}
				description={t("table.description")}
				primaryButtonText={
					<FileUploader
						buttonText={t("primaryBtn")}
						buttonType="primaryMedium"
						inputProps={{ accept: ".ttf" }}
						buttonProps={{ startIcon: <AddIcon /> }}
						onUpload={uploadFont}
						loading={fontsStore.loadingUpload}
					/>
				}
				hideSecondaryButton
				headerArray={headerArray}
				tableData={tableData}
				enableSearch={true}
				searchBoxPlaceholder={t("table.searchPlaceholder")}
				handleSearchChange={handleSearchChange}
				filterBy={filterBy}
				paginationOptions={paginationOptions}
				emptyStateComponent={
					<EmptyTable message={t("table.emptyState")} component="label" className={styles.emptyTable}>
						<VisuallyHiddenInput
							type="file"
							inputProps={{ accept: ".ttf" }}
							style={{ display: "none" }}
							onChange={uploadFont}
						/>
					</EmptyTable>
				}
				loading={fontsStore.isLoading}
				// loadingRow={loadingRow}
			/>
			<ConfirmationPopup
				onClose={closeDeletePopup}
				title={t("deletePopup.title")}
				description={t("deletePopup.description")}
				confirmButtonText={ct("delete")}
				open={openDeletePopup}
				onClick={deleteFont}
				loading={fontsStore.loadingDelete}
			/>
		</>
	);
};

export default observer(Fonts);

// const EmptyTable = ({ uploadFont }: { uploadFont: (e: React.ChangeEvent<HTMLInputElement>) => void }) => {
// 	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.fonts" });
// 	return (
// 		<Box className={styles.emptyTable} component="label">
// 			<VisuallyHiddenInput
// 				type="file"
// 				inputProps={{ accept: ".ttf" }}
// 				style={{ display: "none" }}
// 				onChange={uploadFont}
// 			/>
// 			<img src={GeneratedImgSvg} />
// 			<Typography className={styles.text}>{t("table.emptyState")}</Typography>
// 		</Box>
// 	);
// };
