import { Box } from "@mui/material";
import { nanoid } from "nanoid";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import MainTabHeader from "../../../../../../components/common/MainTabHeader/MainTabHeader";
import TextBoxType from "../../../../../../components/common/TextBoxType";
import { TextTypeClass, textTypesStyles } from "../../../../../../constants/TextTypes";
import { defaultFont } from "../../../../../../constants/mock-data";
import { DesignEditorContext } from "../../../../../../contexts/DesignEditor.tsx";
import { useEditor } from "../../../../../../hooks/useEditor";
import { FontItem, TextStyleOptions } from "../../../../../../interfaces/common";
import { loadFonts } from "../../../../../../utils/fonts.ts";
import styles from "./Text.module.scss";

const Text = () => {
	const editor = useEditor();
	const { isPopupView } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.text" });

	const applyAdjustedFontSize = (desiredFontSize: number, scaleFactor: number) => {
		return desiredFontSize / scaleFactor;
	};

	const getScaleFactorFromViewport = () => {
		const transform = editor?.canvas.canvas.viewportTransform;
		return transform ? transform[0] : 1;
	};

	const getTextOptions = async (id: string): Promise<TextStyleOptions | undefined> => {
		const font: FontItem = {
			name: defaultFont.name,
			url: defaultFont.url,
		};
		await loadFonts([font]);
		const style = textTypesStyles.find((style) => style.id === id);
		if (!style) return;
		const scaleFactor = getScaleFactorFromViewport();
		const adjustedFontSize = applyAdjustedFontSize(parseInt(style.style.fontSize), scaleFactor).toFixed(0);

		return {
			id: nanoid(),
			type: "StaticText",
			width: 420,
			text: style.text,
			fontSize: Number(adjustedFontSize),
			fontFamily: font.name,
			textAlign: "center",
			fontStyle: "normal",
			fontURL: font.url,
			fill: style.style.color,
			metadata: {},
			fontWeight: style.style.fontWeight,
			textType: style.type,
		};
	};

	const addObject = async (id: string) => {
		if (editor) {
			const options = await getTextOptions(id);
			if (options) {
				editor.objects.add(options);
			}
		}
	};

	return (
		<Box className={styles.textTapContainer}>
			<MainTabHeader title={t("title")} />
			<Box className={styles.tabs}>
				<Box className={styles.textTapContent}>
					{!isPopupView && (
						<>
							{textTypesStyles.map(({ id, text }) => (
								<TextBoxType
									key={id}
									textTypeClass={id as TextTypeClass}
									label={text}
									onClick={() => addObject(id)}
								/>
							))}
						</>
					)}
				</Box>
			</Box>
		</Box>
	);
};

export default Text;
