import { Box } from "@mui/material";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ResizeObserver from "resize-observer-polyfill";
import EditableChip from "../../../components/common/EditableChip/EditableChip.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { DesignEditorContext } from "../../contexts/DesignEditor.tsx";
import { Context } from "../../contexts/context";
import { Editor } from "../../core";
import { useEditor } from "../../hooks/useEditor";
import type { EditorConfig, IScene } from "../../types";
import { SecondaryTabTypeEnum } from "../../views/DesignEditor/components/Panels/panelItems";
import SmartImageHeader from "../common/SmartImageHeader/SmartImageHeader.tsx";
import styles from "./canvas.module.scss";

export interface ICanvasProps {
	config?: Partial<EditorConfig>;
	id?: string;
}

export const Canvas = (props: ICanvasProps) => {
	const editor = useEditor();
	const context = React.useContext(Context);
	const { isPopupView } = useContext(DesignEditorContext);
	const { imagesStore, campaignStore } = useAppStore();
	const { scenes, setScenes } = React.useContext(DesignEditorContext);
	const containerRef = React.useRef<HTMLDivElement>(null);
	const [buttonPosition, setButtonPosition] = React.useState({ left: 0, top: 0 });
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.canvasControllers" });
	const [editedLabel, setEditedLabel] = React.useState(
		editor?.scene.name && editor?.scene.name != t("untitledDesign")
			? editor?.scene.name
			: t("Untitled") + editor?.frame?.frame?.width + "x" + editor?.frame?.frame?.height,
	);

	if (editor) {
		editor.scene.name =
			editor?.scene.name && editor?.scene.name != t("untitledDesign")
				? editor?.scene.name
				: t("Untitled") + " " + editor?.frame?.frame?.width + "x" + editor?.frame?.frame?.height;
	}

	useEffect(() => {
		const container = containerRef.current as HTMLDivElement;
		const { clientHeight, clientWidth } = container;
		const editor = new Editor({
			id: props.id ?? "bria_canvas",
			config: {
				...props.config,
				size: {
					width: clientWidth,
					height: clientHeight,
				},
			},
			state: context,
		});

		campaignStore.handleSetEditor(editor);

		const resizeObserver = new ResizeObserver((entries) => {
			const { width = clientWidth, height = clientHeight } = (entries[0] && entries[0].contentRect) || {};
			editor.canvas.resize({
				width,
				height,
			});
			updateButtonPosition(width, height);
		});
		resizeObserver.observe(container);
		return () => {
			editor.destroy();
			if (container) {
				resizeObserver.unobserve(container);
			}
		};
	}, []);

	useEffect(() => {
		setEditedLabel(
			editor?.scene.name && editor?.scene.name != t("untitledDesign")
				? editor?.scene.name
				: t("Untitled") + " " + editor?.frame?.frame?.width + "x" + editor?.frame?.frame?.height,
		);
	}, [editor?.scene.name]);

	useEffect(() => {
		const updateCurrentScene = async () => {
			if (editor) {
				const updatedTemplate = editor.scene.exportToJSON();
				const updatedPreview = await editor.renderer.render(updatedTemplate);

				const updatedScenes = scenes.map((scene) => {
					if (scene.id === updatedTemplate.id) {
						return { ...updatedTemplate, preview: updatedPreview };
					}
					return scene;
				}) as IScene[];
				setScenes(updatedScenes);
			}
		};
		!isPopupView && updateCurrentScene();
	}, [editedLabel]);

	useEffect(() => {
		updateButtonPosition(
			editor?.canvas?.getConfig().size?.width ?? 0,
			editor?.canvas?.getConfig().size?.height ?? 0,
		);
	}, [editor?.canvas?.canvas?.viewportTransform?.[0]]);

	const updateButtonPosition = (width: number, height: number) => {
		if (editor && editor.canvas && editor.frame) {
			const viewportTransform = editor.canvas.canvas?.viewportTransform;

			if (viewportTransform) {
				const newLeft = width / 2 - (viewportTransform[0] * editor.frame.frame.width) / 2;
				const newTop = height / 2 - (viewportTransform[3] * editor.frame.frame.height) / 2;
				setButtonPosition({ left: newLeft, top: newTop });
			}
		}
	};

	return (
		<Box component={"div"} className={styles.container} id="bria_canvas_container" ref={containerRef}>
			<Box component={"div"} className={styles.canvasWrapper}>
				<canvas id={props.id ?? "bria_canvas"}></canvas>
			</Box>
			<Box
				className={styles.canvasHeader}
				style={{ left: `${buttonPosition.left}px`, top: `${buttonPosition.top - 35}px` }}
			>
				<EditableChip
					key={editedLabel}
					label={editedLabel}
					onEdit={(newValueL: string) => {
						if (editor) editor.scene.name = newValueL;
						setEditedLabel(newValueL);
					}}
					onDelete={() => {}}
					className={styles.backgroundColor}
					enableOnMouseEnter
					enableOnMouseLeave
					hideButtons
				/>
			</Box>
			{imagesStore.secondaryTabType === SecondaryTabTypeEnum.SMART_IMAGE && (
				<SmartImageHeader buttonPosition={buttonPosition} />
			)}
		</Box>
	);
};
