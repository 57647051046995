import { Box, CircularProgress, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../../../../../../hooks/useStores";
import styles from "./EditAllAdsSwitch.module.scss";
const EditAllAdsSwitch = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers" });
	const { campaignStore, designEditorStore } = useAppStore();
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		campaignStore.isEditingAllAds = event.target.checked;
		campaignStore.isFirstTimeBrandApplied = true;
	};
	return (
		<Box className={styles.container}>
			{designEditorStore.isLoadingTemplate && <CircularProgress color="inherit" size={18} />}
			<FormGroup>
				<FormControlLabel
					className={styles.formControlLabel}
					control={<Switch checked={campaignStore.isEditingAllAds} onChange={handleChange} />}
					label={t("editAllAdsSimultaneously")}
					labelPlacement="start"
				/>
			</FormGroup>
		</Box>
	);
};
const ObservedComponent = observer(EditAllAdsSwitch);
export default ObservedComponent;
