import { logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { makeAutoObservable } from "mobx";
import { isProduction } from "./config/env.ts";
import { firebaseAnalytics } from "./config/firebase.ts";
import { getSelectedOrganization } from "./helpers/localStorage.ts";
import { IRootStore } from "./mobx/root-store.tsx";
import { ImageToImageConfigType, PlaygroundImage } from "./models/image-to-image.ts";
import User from "./models/user.ts";
import HubspotService from "./services/HubspotService.ts";

export enum HUBSPOT_EVENTS {}

export enum ANALYTICS_EVENTS {
	REGISTER = "REGISTER",
	LOGIN = "LOGIN",
	IMAGE_UPLOAD_ITI = "IMAGE_UPLOAD_ITI",
	IMAGE_DOWNLOAD_ITI = "IMAGE_DOWNLOAD_ITI",
	IMAGE_ERROR_ITI = "IMAGE_ERROR_ITI",
	IMAGE_COMPARE_ITI = "IMAGE_COMPARE_ITI",
	PLAYGROUND_IMAGE_DOWNLOAD_ITI = "PLAYGROUND_IMAGE_DOWNLOAD_ITI",
	PLAYGROUND_IMAGE_UPLOAD = "PLAYGROUND_IMAGE_UPLOAD",
	PLAYGROUND_IMAGE_SAVE_ITI = "PLAYGROUND_IMAGE_SAVE_ITI",
	ITI_GENERATE = "ITI_GENERATE",
	ITI_MIX_FACE_BODY = "ITI_MIX_FACE_BODY",
	ITI_REFINE_IMAGE = "ITI_REFINE_IMAGE",
	ITI_COLOR_REFINE_IMAGE = "ITI_COLOR_REFINE_IMAGE",
	IMAGE_UPLOAD_PP = "IMAGE_UPLOAD_PP",
	IMAGE_DOWNLOAD_PP = "IMAGE_DOWNLOAD_PP",
	IMAGE_PSD_DOWNLOAD_PP = "IMAGE_PSD_DOWNLOAD_PP",
	PP_GENERATE = "PP_GENERATE",
	MANUAL_PP_GENERATE = "MANUAL_PP_GENERATE",
}

export interface IAnalyticsStore {
	setUser(currentUser: User | null, registrationSource?: string): Promise<void>;

	logHubspotEvent(eventName: HUBSPOT_EVENTS | any, eventValue: any): Promise<void>;

	logRegistrationEvent(): void;

	logEvent(eventName: ANALYTICS_EVENTS, eventParams?: { [key: string]: any }): void;

	logImageToImageEvent(eventName: ANALYTICS_EVENTS, image: PlaygroundImage): void;
}

export default class AnalyticsStore implements IAnalyticsStore {
	rootStore: IRootStore;
	hubspotService = new HubspotService();

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}

	setHubspotUser = async (currentUser: User, registrationSource?: string) => {
		await this.hubspotService.upsertContact({
			is_sso: false,
			extra_properties: registrationSource ? { platform_source: registrationSource } : undefined,
		});

		if (currentUser) {
			const splittedUserName = currentUser.userName.split(" ");
			const _hsq = ((window as any)._hsq = (window as any)._hsq || []);
			_hsq.push([
				"identify",
				{
					contact_source: `${isProduction() ? "prod" : "int"}_platform`,
					email: currentUser.email,
					firstname: splittedUserName[0],
					lastname: splittedUserName.filter((_part, index) => index !== 0).join(" "),
					company: currentUser.company,
				},
			]);
		}
	};

	logHubspotEvent = async (eventName: HUBSPOT_EVENTS | any, eventValue: any = true) => {
		await this.hubspotService.upsertContact({ is_sso: false, extra_properties: { [eventName]: eventValue } });
	};

	setUser = async (currentUser: User | null, registrationSource?: string) => {
		if (currentUser) {
			setUserProperties(firebaseAnalytics, {
				role: currentUser.role,
				company: currentUser.company,
				user_domain: currentUser.email.split("@").pop(),
				user_id: currentUser.uid,
			});
			setUserId(firebaseAnalytics, currentUser.uid);
			await this.setHubspotUser(currentUser, registrationSource);
		}
	};

	logRegistrationEvent = () => {
		this.logEvent(ANALYTICS_EVENTS.REGISTER);
		(window as any).lintrk("track", {
			conversion_id: 13339457,
		});
	};

	logEvent = (eventName: ANALYTICS_EVENTS, eventParams: { [key: string]: any } = {}) => {
		logEvent(firebaseAnalytics, eventName, {
			platformUserId: this.rootStore.authStore.user?.uid,
			platformOrgId: getSelectedOrganization()?.organization.uid,
			...eventParams,
		});
	};

	logImageToImageEvent = (eventName: ANALYTICS_EVENTS, image: PlaygroundImage) => {
		this.logEvent(eventName, {
			itiSessionId: image?.sessionId ?? "",
			tailored_style: (image.config as ImageToImageConfigType)?.style?.tailored_style,
			sub_style: (image.config as ImageToImageConfigType)?.style?.sub_style,
		});
	};
}
