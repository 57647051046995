import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import LoadingPlaceholder from "../../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { ORG_SUBSCRIPTION_PLANS, SubscriptionIntervalMapping } from "../../../constants/billing.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import BillingService from "../../../services/BillingService.ts";
import styles from "./Subscription.module.scss";

const Subscription = () => {
	const { authStore, pricingStore } = useAppStore();
	const billingService = new BillingService();
	const { t } = useTranslation("translation", { keyPrefix: "account.subscription" });
	const [loadingCustomerPortal, setLoadingCustomerPortal] = useState(false);
	const [loadingCancelSubscriptionCustomerPortal, setLoadingCancelSubscriptionCustomerPortal] = useState(false);

	return (
		<Box className={styles.container}>
			<LoadingPlaceholder isLoading={authStore.isLoadingOrgSubscriptions} className={styles.loaderStyle}>
				<Box className={styles.headerContainer}>
					<Typography className={styles.title}>{t("title")}</Typography>
					<Box className={styles.buttonContainer}>
						{!authStore.orgSubscription?.cancels_on && (
							<BriaButton
								className={styles.button}
								buttonType="textMedium"
								type="button"
								color="primary"
								loading={loadingCancelSubscriptionCustomerPortal}
								disabled={authStore.orgSubscription?.plan_name === ORG_SUBSCRIPTION_PLANS.free.name}
								onClick={() => {
									setLoadingCancelSubscriptionCustomerPortal(true);
									const currentUrl = new URL(window.location.href);
									currentUrl.searchParams.set("isSubscriptionCancelled", "true");
									currentUrl.searchParams.set("pricing", "true");
									billingService
										.createCustomerPortalCancelSubscriptionSession(currentUrl.href)
										.then((session) => {
											window.open(session.url, "_self");
										})
										.finally(() => {
											setLoadingCancelSubscriptionCustomerPortal(false);
										});
								}}
							>
								{t("cancelSubscription")}
							</BriaButton>
						)}
						<BriaButton
							className={styles.button}
							buttonType="primary"
							type="button"
							variant="contained"
							color="secondary"
							onClick={() => {
								pricingStore.openPricingFlow();
							}}
						>
							{authStore.orgSubscription?.cancels_on ? t("renewSubscription") : t("updateYourPlan")}
						</BriaButton>
					</Box>
				</Box>
				<Box className={styles.summary}>
					<Box className={styles.row}>
						<Typography className={styles.title}>{t("plan")}</Typography>
						<Typography className={styles.value}>{authStore.orgSubscription?.plan_name}</Typography>
					</Box>
					<Box className={styles.row}>
						<Typography className={styles.title}>{t("status")}</Typography>
						<Typography className={styles.value}>
							{authStore.orgSubscription?.status.replace("_", " ")}
							<Typography display="inline" className={styles.valueFreeText}>
								{authStore.orgSubscription?.cancels_on
									? t("willBeCancelledOn", { cancelledOn: authStore.orgSubscription?.cancels_on })
									: ""}
							</Typography>
						</Typography>
					</Box>
					{authStore.orgSubscription?.plan_name !== ORG_SUBSCRIPTION_PLANS.free.name && (
						<>
							<Box className={styles.row}>
								<Typography className={styles.title}>{t("billingInterval")}</Typography>
								<Typography className={styles.value}>
									{SubscriptionIntervalMapping[authStore.orgSubscription?.interval ?? "month"]}
									<Typography display="inline" className={styles.valueFreeText}>
										{!authStore.orgSubscription?.cancels_on && authStore.orgSubscription?.renews_on
											? t("renewsOn", { renewsOn: authStore.orgSubscription?.renews_on })
											: ""}
									</Typography>
								</Typography>
							</Box>
							{authStore.orgSubscription?.hide_fees !== "true" && (
								<>
									<Box className={styles.row}>
										<Typography className={styles.title}>{t("platformFee")}</Typography>
										<Typography className={styles.value}>
											{authStore.orgSubscription?.platform_fee}
										</Typography>
									</Box>
									<Box className={styles.row}>
										<Typography className={styles.title}>{t("usageFee")}</Typography>
										<Typography className={styles.value}>
											{authStore.orgSubscription?.usage_fee}
										</Typography>
									</Box>
								</>
							)}
						</>
					)}
					{authStore.orgSubscription?.discount && (
						<Box className={styles.row}>
							<Typography className={styles.title}>{t("discount")}</Typography>
							<Typography className={styles.value}>{authStore.orgSubscription?.discount}</Typography>
						</Box>
					)}
				</Box>
				<Box className={styles.footer}>
					<Typography className={styles.footerText}>{t("footerText")}</Typography>
					<BriaButton
						className={styles.button}
						buttonType="secondaryMedium"
						type="button"
						color="primary"
						loading={loadingCustomerPortal}
						disabled={authStore.orgSubscription?.plan_name === ORG_SUBSCRIPTION_PLANS.free.name}
						onClick={() => {
							setLoadingCustomerPortal(true);
							billingService
								.createCustomerPortalSession(window.location.href)
								.then((session) => {
									window.open(session.url, "_self");
								})
								.finally(() => {
									setLoadingCustomerPortal(false);
								});
						}}
					>
						{t("paymentDashboard")}
					</BriaButton>
				</Box>
			</LoadingPlaceholder>
		</Box>
	);
};

export default observer(Subscription);
