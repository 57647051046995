import { UserOrganization } from "../models/organization";

export const getSelectedOrganization = () => {
	const selectedOrg = localStorage.getItem("selected_org");
	return selectedOrg ? (JSON.parse(selectedOrg) as UserOrganization) : null;
};

export const setSelectedOrganization = (selectedOrg: UserOrganization) => {
	return localStorage.setItem("selected_org", JSON.stringify(selectedOrg));
};

export const clearSelectedOrganization = () => {
	return localStorage.removeItem("selected_org");
};
