import { Block } from "baseui/block";
import React from "react";
import { useEditor } from "../../../../hooks/useEditor";

export default function () {
	const editor = useEditor();
	const [loading, setLoading] = React.useState(true);
	const [state, setState] = React.useState({
		image: "",
	});

	const makePreview = React.useCallback(async () => {
		if (editor) {
			const template = editor.scene.exportToJSON();
			const image = (await editor.renderer.render(template)) as string;
			setState({ image });
			setLoading(false);
		}
	}, [editor]);

	React.useEffect(() => {
		makePreview();
	}, [editor]);

	return (
		<Block $style={{ flex: 1, alignItems: "center", justifyContent: "center", display: "flex", padding: "5rem" }}>
			{!loading && <img width={"auto"} height={"100%"} src={state.image} />}
		</Block>
	);
}
