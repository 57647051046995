const PlusIcon = ({ fillColor = "#8800FF", width = "12", height = "12" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
			<path
				d="M11.9987 6.85697H6.85694V11.9987H5.14302V6.85697H0.00128174V5.14305H5.14302V0.00131226H6.85694V5.14305H11.9987V6.85697Z"
				fill={fillColor}
			/>
		</svg>
	);
};

export default PlusIcon;
