import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Github(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 20 20" {...props}>
			<path
				d="M10.25 0.5C4.72833 0.5 0.25 5.09069 0.25 10.7528C0.25 15.2828 3.115 19.1259 7.08917 20.4819C7.58833 20.5767 7.75 20.2589 7.75 19.9889V18.0801C4.96833 18.7004 4.38917 16.8703 4.38917 16.8703C3.93417 15.6853 3.27833 15.37 3.27833 15.37C2.37083 14.7335 3.3475 14.7471 3.3475 14.7471C4.35167 14.8189 4.88 15.804 4.88 15.804C5.77167 17.371 7.21917 16.9182 7.79 16.6559C7.87917 15.9937 8.13833 15.5409 8.425 15.2854C6.20417 15.0248 3.86917 14.1456 3.86917 10.2179C3.86917 9.09783 4.26 8.18362 4.89917 7.46593C4.79583 7.20704 4.45333 6.16382 4.99667 4.75235C4.99667 4.75235 5.83667 4.47723 7.7475 5.80326C8.545 5.57599 9.4 5.46236 10.25 5.45808C11.1 5.46236 11.9558 5.57599 12.755 5.80326C14.6642 4.47723 15.5025 4.75235 15.5025 4.75235C16.0467 6.16467 15.7042 7.2079 15.6008 7.46593C16.2425 8.18362 16.63 9.09868 16.63 10.2179C16.63 14.1559 14.2908 15.0231 12.0642 15.2769C12.4225 15.5947 12.75 16.2184 12.75 17.1753V19.9889C12.75 20.2614 12.91 20.5818 13.4175 20.481C17.3883 19.1234 20.25 15.2811 20.25 10.7528C20.25 5.09069 15.7725 0.5 10.25 0.5Z"
				fill="black"
			/>
		</SvgIcon>
	);
}

export default Github;
