import { Channel, defaultChannel } from "./channel";

export type Placement = {
	id: number;
	name: string;
	width: number;
	height: number;
	channel_id: number;
	channel: Channel;
	created_at: Date;
	updated_at: Date;
	layouts: number[];
};

export const defaultPlacement: Placement = {
	id: NaN,
	name: "",
	width: NaN,
	height: NaN,
	channel_id: NaN,
	channel: defaultChannel,
	layouts: [],
	created_at: new Date(),
	updated_at: new Date(),
};
