import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TAILORED_GENERATION_MODEL_STEP } from "../../../constants/TGConstants.ts";
import useErrorToast from "../../../hooks/useErrorToast.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { ITailoredGenerationDataset } from "../../../models/tailoredGeneration.ts";
import LoadingPlaceholder from "../../common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import DatasetImagesGrid from "../DatasetImagesGrid/DatasetImagesGrid.tsx";
import styles from "./ViewDataset.module.scss";

const ViewDataset = () => {
	const { datasetId } = useParams();
	const errorToast = useErrorToast();
	const { uiStore, tailoredGenerationStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation.datasets" });

	useEffect(() => {
		const loadData = async () => {
			if (datasetId) {
				const localModel = await tailoredGenerationStore.findModelByDatasetId(datasetId);
				if (localModel?.model_url) {
					tailoredGenerationStore.selectedModel = localModel;
				} else {
					const selectedDataSet: ITailoredGenerationDataset | undefined =
						tailoredGenerationStore?.datasets?.find((dataset) => dataset.id?.toString() === datasetId);
					if (selectedDataSet) {
						tailoredGenerationStore.viewedDataset = selectedDataSet;
					} else {
						errorToast.showError(t("validation.modelNotFound"));
					}
				}
			}
		};
		const initialize = async () => {
			loadData();
			uiStore.updateTgStep(TAILORED_GENERATION_MODEL_STEP.VIEW_DATASET);
		};

		initialize();
		return () => {
			tailoredGenerationStore.selectedModel = undefined;
		};
	}, []);

	useEffect(() => {
		return () => {
			uiStore.updateTGDataset = false;
		};
	}, []);
	return (
		<Box className={styles.container}>
			<LoadingPlaceholder className={styles.loadingPlaceholder} isLoading={tailoredGenerationStore.isLoading}>
				<Box className={styles.row}>
					<Typography className={styles.datasetLabel}>{t("datasetName")}: </Typography>
					<Typography className={styles.datasetName}>
						{tailoredGenerationStore.selectedModel
							? tailoredGenerationStore.selectedModel?.dataset.name
							: tailoredGenerationStore.viewedDataset?.name}
					</Typography>
				</Box>
				<Box className={styles.row}>
					<Typography className={styles.datasetLabel}>{t("styleDescription")}: </Typography>
					<Typography className={styles.datasetName}>
						{tailoredGenerationStore.selectedModel
							? tailoredGenerationStore.selectedModel?.dataset.description
							: tailoredGenerationStore.viewedDataset?.description}
					</Typography>
				</Box>
				<Box className={styles.gridContainer}>
					{tailoredGenerationStore.selectedModel?.dataset ? (
						<DatasetImagesGrid dataset={tailoredGenerationStore.selectedModel.dataset} />
					) : (
						<DatasetImagesGrid dataset={tailoredGenerationStore.viewedDataset} />
					)}
				</Box>
			</LoadingPlaceholder>
		</Box>
	);
};

const ObservedComponent = observer(ViewDataset);
export default ObservedComponent;
