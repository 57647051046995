import { useSortable } from "@dnd-kit/sortable";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import useDesignEditorContext from "../../../../../../hooks/useDesignEditorContext";
import type { IScene } from "../../../../../../types";
import styles from "./SceneItem.module.scss";

interface Props {
	isCurrentScene: boolean;
	scene: IScene;
	preview: string;
	index: number;
	changePage: (p: IScene) => void;
	containerClassName?: string;
}
const SceneItem = ({ isCurrentScene, scene, preview, changePage, containerClassName = "" }: Props) => {
	const { attributes, listeners, setNodeRef } = useSortable({ id: scene.id });
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.scenes" });
	const sceneRef = React.useRef<HTMLDivElement>(null);
	const { setContextMenuTimelineRequest } = useDesignEditorContext();

	React.useEffect(() => {
		const timeLineItemDiv = sceneRef.current;
		const handleContextMenu = (event: MouseEvent) => {
			event.preventDefault();
			setContextMenuTimelineRequest({
				id: scene.id,
				left: event.pageX,
				top: event.pageY,
				visible: true,
			});
		};
		if (timeLineItemDiv) {
			timeLineItemDiv.addEventListener("contextmenu", handleContextMenu);
		}
		return () => {
			if (timeLineItemDiv) {
				timeLineItemDiv.removeEventListener("contextmenu", handleContextMenu);
			}
		};
	}, [sceneRef, scene]);

	return (
		<Box
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			className={clsx(styles.sceneItemContainer, containerClassName, {
				[styles.isCurrentScene]: isCurrentScene,
			})}
			onClick={async () => changePage(scene)}
		>
			<Box ref={sceneRef} className={styles.sceneImage}>
				<img src={preview} />
			</Box>
			<Typography className={styles.sceneName}>{scene.name ?? t("untitled")}</Typography>
			<Typography className={styles.sceneDimensions}>
				{t("key", { width: scene.frame?.width, height: scene.frame?.height })}
			</Typography>
		</Box>
	);
};

export default SceneItem;
