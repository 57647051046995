import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import GuidelinesForTailoredModelsDialog from "../../components/TailoredGeneration/GuidelinesForTailoredModelsDialog/GuidelinesForTailoredModelsDialog.tsx";
import TGLayout from "../../components/TailoredGeneration/TGLayout/TGLayout.tsx";
import RouterConstants from "../../constants/RouterConstants";
import { TAILORED_GENERATION_MODEL_STATUS, TAILORED_GENERATION_MODEL_STEP } from "../../constants/TGConstants.ts";
import { useAppStore } from "../../hooks/useStores.tsx";
import TabsLayout, { ITabLayout } from "../../layout/TabsLayout/TabsLayout";

const TailoredGeneration = () => {
	const { uiStore, tailoredGenerationStore } = useAppStore();

	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });
	const subTabs = [RouterConstants.TG_MODELS.path, RouterConstants.TG_DATASET.path];
    const [selectedModalName, setSelectedModalName] = useState<string>('');

	let playgroundTabs: ITabLayout[] = [
		{
			disabled: !tailoredGenerationStore.selectedModel,
			name: RouterConstants.TG_PLAYGROUND.path,
			link: `${RouterConstants.TG_PLAYGROUND.editPath}/${tailoredGenerationStore.selectedModel?.id}`,
		},
		{
			disabled: !tailoredGenerationStore.selectedModel,
			name: RouterConstants.DATASET.path,
			link: `${RouterConstants.DATASET.editPath}/${tailoredGenerationStore.selectedModel?.dataset?.id}`,
		},
	];

	if (
		uiStore.updateTGDataset ||
		(tailoredGenerationStore.selectedModelUsedToControlThePlayGroundTaps?.status &&
			tailoredGenerationStore.selectedModelUsedToControlThePlayGroundTaps?.status !=
				TAILORED_GENERATION_MODEL_STATUS.COMPLETED)
	) {
		let dataSetId = tailoredGenerationStore.selectedModelUsedToControlThePlayGroundTaps?.dataset?.id;
		if (uiStore.updateTGDataset) {
			dataSetId = tailoredGenerationStore.selectedModel
				? tailoredGenerationStore.selectedModel?.dataset?.id
				: tailoredGenerationStore?.viewedDataset?.id;
		}
		playgroundTabs = [
			{
				disabled: !tailoredGenerationStore.selectedModelUsedToControlThePlayGroundTaps,
				name: RouterConstants.DATASET.path,
				link: `${RouterConstants.DATASET.editPath}/${dataSetId}`,
			},
		];
	}

	useEffect(()=>{
		setSelectedModalName("");
		if(tailoredGenerationStore.selectedModel?.name) {
			setSelectedModalName(tailoredGenerationStore.selectedModel?.name);
		}
	},[tailoredGenerationStore.selectedModel])

	useEffect(() => {
		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
			uiStore.updateTgStep(null);
		};
	}, []);

	useEffect(() => {
		if (uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.LIST_MODELS) {
			uiStore.showSideBar();
			uiStore.hideBackButton();
		} else if (uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.CREATE_MODELS) {
			uiStore.hideSideBar();
			tailoredGenerationStore.clearModelForm();
			uiStore.showBackButton(
				RouterConstants.TG_MODELS.fullPath,
				{},
				t("models.backToModels"),
			);
		} else if (uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.CREATE_DATASET) {
			if (!uiStore.createDataSet) {
				uiStore.showBackButton(
					RouterConstants.TG_MODELS.fullPath,
					{},
					t("models.backToModels"),
				);
			}
			uiStore.hideSideBar();
		} else if (uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.PLAYGROND) {
			uiStore.hideSideBar();
			uiStore.showBackButton(
				RouterConstants.TG_MODELS.fullPath,
				{},
				t("models.playground.backToTailoredModels"),
			);
		} else if (uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.VIEW_DATASET) {
			uiStore.hideSideBar();
			const returnRoutePath =
				uiStore.prev_tg_step === TAILORED_GENERATION_MODEL_STEP.LIST_DATASETS
					? RouterConstants.TG_DATASET.fullPath
					: RouterConstants.TG_MODELS.fullPath;
			uiStore.showBackButton(returnRoutePath);
		} else if (uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.SELECT_TYPE && uiStore.createDataSet) {
			uiStore.hideSideBar();
			uiStore.showBackButton(
				RouterConstants.TG_DATASET.fullPath,
				{},
				t("datasets.backtoDatasets"),
			);
		} else if (uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.LIST_DATASETS) {
			tailoredGenerationStore.selectedDatasetOption = undefined;
			tailoredGenerationStore.createNewDataset = false;
			tailoredGenerationStore.clearModelForm();
			uiStore.duplicateDatasetFlow = false;
			uiStore.showSideBar();
			uiStore.hideBackButton();
		} else {
			uiStore.showSideBar();
		}
	}, [uiStore.tg_step]);

	return (
		<>
			{uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.CREATE_MODELS ||
			uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.CREATE_DATASET ? (
				<TGLayout title={t("secondaryTitle")} progressValue={tailoredGenerationStore.progressPercentage}>
					<Outlet />
				</TGLayout>
			) : uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.PLAYGROND ? (
				<TabsLayout
					title={selectedModalName}
					subTabs={playgroundTabs}
				>
					<Outlet />
				</TabsLayout>
			) : uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.VIEW_DATASET ? (
				<TabsLayout
					title={selectedModalName}
					subTabs={playgroundTabs}
					hideTitle={
						uiStore.updateTGDataset ||
						tailoredGenerationStore.selectedModelUsedToControlThePlayGroundTaps?.status !=
							TAILORED_GENERATION_MODEL_STATUS.COMPLETED
					}
				>
					<Outlet />
				</TabsLayout>
			) : uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.SELECT_TYPE && uiStore.createDataSet ? (
				<TGLayout title={t("secondaryTitle")} progressValue={tailoredGenerationStore.progressPercentage}>
					<Outlet />
				</TGLayout>
			) : (
				<TabsLayout title={t("mainTitle")} subTabs={subTabs}>
					<Outlet />
				</TabsLayout>
			)}
			<GuidelinesForTailoredModelsDialog />
		</>
	);
};

const ObservedComponent = observer(TailoredGeneration);
export default ObservedComponent;
