import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function InfoIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 12 13" {...props}>
			<path
				d="M6 3.71525H6.00583M5.41667 6.04858H6V8.38192H6.58333M11.25 6.04858C11.25 8.94808 8.89949 11.2986 6 11.2986C3.1005 11.2986 0.75 8.94808 0.75 6.04858C0.75 3.14909 3.1005 0.798584 6 0.798584C8.89949 0.798584 11.25 3.14909 11.25 6.04858Z"
				stroke="#5300C9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default InfoIcon;
