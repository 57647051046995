import { Box } from "@mui/material";
import { BaseProvider, LightTheme } from "baseui";
import i18next from "i18next";
import { observer } from "mobx-react-lite";
import React from "react";
import { I18nextProvider } from "react-i18next";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import { AppProvider } from "./contexts/AppContext";
import { DesignEditorProvider } from "./contexts/DesignEditor";
import { TimerProvider } from "./contexts/TimerContext";
import { Provider as ScenifyProvider } from "./contexts/context";
import styles from "./styles/DesignEditor.module.scss";
import "./translations";

const engine = new Styletron();

function CustomProvider({ children, isPopupView }: { children: React.ReactNode; isPopupView?: boolean }) {
	return (
		<DesignEditorProvider isPopupView={isPopupView}>
			<TimerProvider>
				<AppProvider>
					<ScenifyProvider isPopupView={isPopupView}>
						<StyletronProvider value={engine}>
							{isPopupView ? (
								<Box className={styles.providerContainer}>
									<BaseProvider theme={LightTheme}>
										<I18nextProvider i18n={i18next}>{children}</I18nextProvider>
									</BaseProvider>
								</Box>
							) : (
								<BaseProvider theme={LightTheme}>
									<I18nextProvider i18n={i18next}>{children}</I18nextProvider>
								</BaseProvider>
							)}
						</StyletronProvider>
					</ScenifyProvider>
				</AppProvider>
			</TimerProvider>
		</DesignEditorProvider>
	);
}

const ObservedComponent = observer(CustomProvider);
export default ObservedComponent;
