import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { InputBase, MenuItem, Popover } from "@mui/material";
import { useEffect, useState } from "react";
import { FONT_SIZES } from "../../../../../../constants/editor";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { IStaticText } from "../../../../../../types";
import styles from "./TextFontSize.module.scss";

const TextFontSize = () => {
	const editor = useEditor();
	const activeObject = useActiveObject() as IStaticText;
	const [textFontSize, setTextFontSize] = useState(12);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		if (activeObject && activeObject.type === "StaticText") {
			// @ts-ignore
			setTextFontSize(activeObject.fontSize);
		}
	}, [activeObject]);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const onSizeChange = (size: number) => {
		editor?.objects.update({ fontSize: Number(size) });
		setTextFontSize(size);
		handleClose();
	};

	return (
		<div className={styles.textFontSizeContainer}>
			{/* Input with arrow icon inside */}
			<div className={styles.inputWrapper} onClick={handleClick}>
				<InputBase
					value={textFontSize}
					onChange={(e) => onSizeChange(Number(e.target.value))}
					className={styles.input}
					type="number"
					endAdornment={
						<ExpandMoreIcon
							sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "22px" }}
							className={styles.expandIcon}
						/>
					}
				/>
			</div>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				sx={{
					"& .MuiPopover-paper": {
						top: "88px !important",
						left: "551px !important",
						position: "absolute",
					},
				}}
			>
				<div className={styles.popoverContent}>
					{FONT_SIZES.map((size, index) => (
						<MenuItem
							key={index}
							onClick={() => onSizeChange(size)}
							className={`${styles.menuItem} ${textFontSize === size ? styles.selected : ""}`}
						>
							{size}
						</MenuItem>
					))}
				</div>
			</Popover>
		</div>
	);
};

export default TextFontSize;
