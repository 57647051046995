import { SvgIcon, SvgIconProps } from "@mui/material";

type IconProps = SvgIconProps & {
	size?: number;
};

export const EditIcon = ({ size = 20, ...props }: IconProps) => {
	return (
		<SvgIcon width={size} height={size} viewBox="0 0 24 24" {...props}>
			<path
				fill="none"
				d="M11 7L17 13M4 16L15.7 4.30002C15.8869 4.11679 16.1382 4.01416 16.4 4.01416C16.6618 4.01416 16.9131 4.11679 17.1 4.30002L19.7 6.90002C19.8832 7.08695 19.9859 7.33826 19.9859 7.60002C19.9859 7.86177 19.8832 8.11309 19.7 8.30002L8 20H4V16Z"
				stroke="#5b5b5b"
				strokeWidth="1.7"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
