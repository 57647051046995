export const PLACEMENTS = [
	"upper_center",
	"upper_left",
	"upper_right",
	"bottom_left",
	"bottom_center",
	"bottom_right",
	"right_center",
	"left_center",
	"center_vertical",
	"center_horizontal",
];