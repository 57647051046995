import { ORGANIZATIONS, USER_ORGANIZATION_ROLES } from "../constants/UserConstants.ts";
import { getSelectedOrganization } from "../helpers/localStorage.ts";
import { MyAccountUser } from "../models/organization.ts";
import { getOrganizationAdmins, isBriaAdmin } from "../utils";
import { useAppStore } from "./useStores.tsx";

export const useUserChecks = () => {
	const { authStore } = useAppStore();
	const selectedOrg = getSelectedOrganization();

	const isLoggedInOwnerOrAdmin = () => {
		const adminsList = getOrganizationAdmins(authStore.user);
		return adminsList.includes(selectedOrg?.organization?.uid);
	};

	const hideSecondaryButton = () => {
		return (
			selectedOrg?.organization.name?.toLowerCase() === ORGANIZATIONS.BRIA ||
			!(selectedOrg?.role === USER_ORGANIZATION_ROLES.ADMIN_HIDDEN || isBriaAdmin(authStore.user))
		);
	};

	const isTableRowOwner = (user: MyAccountUser) => {
		return user.role === USER_ORGANIZATION_ROLES.OWNER;
	};

	const hasEditPermission = (user: MyAccountUser) => {
		return isLoggedInOwnerOrAdmin() && !isTableRowOwner(user);
	};

	const canUserEditOrganization = () => {
		return (
			selectedOrg?.role === USER_ORGANIZATION_ROLES.OWNER ||
			selectedOrg?.role === USER_ORGANIZATION_ROLES.ADMIN ||
			selectedOrg?.role === USER_ORGANIZATION_ROLES.ADMIN_HIDDEN
		);
	};

	return {
		hideSecondaryButton,
		hasEditPermission,
		canUserEditOrganization,
	};
};
