import { Box, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaDropDown, { DropDownItem } from "../../../../../components/common/BriaDropDown/BriaDropDown";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import { useAppStore } from "../../../../../hooks/useStores";
import InputLayout from "../../../../../layout/InputLayout/InputLayout";
import { Channel } from "../../../../../models/channel";
import { Layout } from "../../../../../models/layout";
import { Placement, defaultPlacement } from "../../../../../models/placement";
import LayoutConfig from "./LayoutConfig";
import styles from "./LayoutForm.module.scss";

interface Props {
	layoutForm: Layout;
	handleFormChange: <K extends keyof Layout>(key: K, value: Layout[K]) => void;
	graphicImagesToUpload: File[];
	setGraphicImagesToUpload: (updatedArray: File[]) => void;
	graphicImagesToDelete: string[];
	setGraphicImagesToDelete: (updatedArray: string[]) => void;
}

const LayoutForm = ({
	layoutForm,
	handleFormChange,
	graphicImagesToUpload,
	setGraphicImagesToUpload,
	graphicImagesToDelete,
	setGraphicImagesToDelete,
}: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.layoutEditor.form" });
	const { channelsStore, placementsStore, layoutsStore } = useAppStore();
	const [channelsOptions, setChannelsOptions] = useState<DropDownItem[]>([]);
	const [placementsOptions, setPlacementsOptions] = useState<DropDownItem[]>([]);
	const [selectedChannelId, setSelectedChannelId] = useState<number>(layoutForm.placement.channel_id);

	useEffect(() => {
		layoutsStore.configErrors = {};
		channelsStore.loadChannels(1, true).then(() => {
			const channelsOptions: DropDownItem[] = channelsStore.paginatedChannels.items.map((channel: Channel) => ({
				key: channel.name,
				value: channel.id,
			}));
			setChannelsOptions(channelsOptions);
		});
	}, []);

	useEffect(() => {
		setSelectedChannelId(layoutForm.placement.channel_id);
		handleFormChange("placement_id", layoutForm.placement.id);
	}, [layoutForm.placement]);

	useEffect(() => {
		if (selectedChannelId) {
			loadPlacements(selectedChannelId);
			if (selectedChannelId === layoutForm.placement.channel_id) {
				handleFormChange("placement", layoutForm.placement);
			} else {
				handleFormChange("placement", defaultPlacement);
			}
		}
	}, [selectedChannelId]);

	const handleSelectPlacement = (placementId: number) => {
		layoutsStore.configErrors.placement_id = undefined;
		handleFormChange(
			"placement",
			placementsStore.paginatedPlacements.items.find((placement) => placement.id === placementId)!,
		);
	};

	const loadPlacements = async (channelId: number) => {
		await placementsStore.loadPlacements([channelId], 1);
		const placementsOptions: DropDownItem[] = placementsStore.paginatedPlacements.items.map(
			(placement: Placement) => ({
				key: placement.name,
				value: placement.id,
			}),
		);
		setPlacementsOptions(placementsOptions);
	};

	return (
		<Box className={styles.container}>
			<Box className={styles.placementContainer}>
				<InputLayout label={t("channel")} className={styles.input}>
					<BriaDropDown
						value={selectedChannelId}
						onChange={(e) => setSelectedChannelId(e.target.value as number)}
						placeholder={t("channelsPlaceholder")}
						items={channelsOptions}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
						loading={channelsStore.isLoading}
					/>
				</InputLayout>
				<InputLayout label={t("placement")} className={styles.input}>
					<BriaDropDown
						value={layoutForm.placement.id}
						onChange={(e) => handleSelectPlacement(e.target.value as number)}
						placeholder={t("placementsPlaceholder")}
						items={placementsOptions}
						className={styles.dropDown}
						width={"-webkit-fill-available"}
						loading={placementsStore.isLoading}
						error={!!layoutsStore.configErrors.placement_id}
					/>
				</InputLayout>
				<InputLayout label={t("resolution.label")} className={styles.input}>
					<Box className={styles.resolution}>
						<BriaInput
							value={`W ${!isNaN(layoutForm.placement.width) ? layoutForm.placement.width : ""} px`}
							placeholder={t("resolution.width")}
							fullWidth
							InputProps={{ classes: { root: styles.textField } }}
							disabled
						/>
						<BriaInput
							value={`H ${!isNaN(layoutForm.placement.height) ? layoutForm.placement.height : ""} px`}
							placeholder={t("resolution.height")}
							fullWidth
							InputProps={{ classes: { root: styles.textField } }}
							disabled
						/>
					</Box>
				</InputLayout>
			</Box>
			<Divider className={styles.divider} />
			<LayoutConfig
				{...{
					layoutForm,
					handleFormChange,
					graphicImagesToUpload,
					setGraphicImagesToUpload,
					graphicImagesToDelete,
					setGraphicImagesToDelete,
				}}
			/>
		</Box>
	);
};

export default observer(LayoutForm);
