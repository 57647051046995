import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function TextTopIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 17" fill="none" {...props}>
			<path
				d="M1.25 1.25H14.75"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.00195 5.23047V15.7305"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.125 5L7.99673 4.25L6.875 5"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default TextTopIcon;
