import { Box } from "@mui/material";
import clsx from "clsx";
import { useContext } from "react";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import EditAllAdsSwitch from "../../Panels/panelItems/Common/EditAllAdsSwitch/EditAllAdsSwitch";
import Flip from "../Shared/Flip/Flip";
import styles from "./Image.module.scss";
export default function () {
	const { isPopupView } = useContext(DesignEditorContext);
	return (
		<Box
			className={clsx({
				[styles.imageToolBar]: true,
				[styles.hide]: isPopupView,
			})}
		>
			<Box>
				<Flip />
			</Box>
			<EditAllAdsSwitch />
		</Box>
	);
}
