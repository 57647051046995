import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrownIcon } from "../../../assets/images/icons/CrownIcon";
import { getSelectedOrganization } from "../../../helpers/localStorage";
import { useAppStore } from "../../../hooks/useStores";
import { useSubscription } from "../../../hooks/useSubscription";
import { EventsLogEnum } from "../../../models/common";
import BriaButton, { ButtonTypes } from "../../common/BriaButton/BriaButton";
import ConfirmationPopup from "../../common/ConfirmationPopup/ConfirmationPopup";
import RequestAccessForm from "../../common/RequestAccessForm/RequestAccessForm";
import styles from "./RequestAccessContainer.module.scss";

interface RequestAccessContainer {
	children?: ReactNode;
	requestFeature: string;
	event?: EventsLogEnum;
	buttonType?: ButtonTypes;
	showCrown?: boolean;
	buttonMessage: string;
	requestTitle?: string;
	requestMessage?: string;
	submissionMessage?: string;
	className?: string;
	paperClass?: string;
}

const RequestAccessContainer = ({
	children,
	requestFeature,
	event,
	buttonType = "primary",
	showCrown = true,
	buttonMessage,
	className,
	paperClass,
	submissionMessage,
	requestTitle,
	requestMessage,
}: RequestAccessContainer) => {
	const [openRequest, setOpenRequest] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [message, setMessage] = useState("");
	const [openSuccessEmail, setOpenSuccessEmail] = useState(false);
	const { t } = useTranslation("translation", { keyPrefix: "requestAccessContainer" });
	const { authStore, appStore } = useAppStore();
	const { isPremiumOrgSubscription } = useSubscription();
	const isUserProOrUltimate = isPremiumOrgSubscription();

	return (
		<Box className={clsx(styles.container, className)}>
			{children && isUserProOrUltimate ? (
				<>{children}</>
			) : (
				<BriaButton
					className={clsx({
						[styles.primaryButton]: true,
						[styles.requestAccessButtonContainer]: true,
						[styles.secondaryMedium]: buttonType === "secondaryMedium",
					})}
					buttonType={buttonType}
					onClick={() => {
						setOpenRequest(true);
					}}
				>
					{showCrown && <CrownIcon stroke="white" />}
					{buttonMessage}
				</BriaButton>
			)}

			<ConfirmationPopup
				onClose={() => {
					setOpenRequest(false);
				}}
				title={requestTitle ? requestTitle : t("requestAccess.title")}
				description={requestMessage ? requestMessage : t("requestAccess.description")}
				confirmButtonText={t("requestAccess.primaryButton")}
				firstButtonText={t("requestAccess.secondaryButton")}
				hideCancel={true}
				open={openRequest}
				onClick={async () => {
					const userEmail = authStore?.user?.email || "";
					const userName = authStore?.user?.userName || "";
					const orgName = getSelectedOrganization()?.organization.name || "";
					const response = await appStore.sendRequestAccess(
						userName,
						userEmail,
						orgName,
						message,
						phoneNumber,
						requestFeature,
					);
					if (response) {
						event && appStore.logEvent({ event: event });
						setOpenSuccessEmail(true);
					}
					setOpenRequest(false);
				}}
				paperClassName={clsx(styles.requestAccessCustomPaperClass, paperClass)}
				loading={appStore.isSendRequestAccessLoading}
			>
				<RequestAccessForm
					setMessage={setMessage}
					message={message}
					setPhoneNumber={setPhoneNumber}
					phoneNumber={phoneNumber}
				/>
			</ConfirmationPopup>

			<ConfirmationPopup
				onClose={() => {
					setOpenSuccessEmail(false);
				}}
				title={t("successRequestAccess.title")}
				description={submissionMessage ? submissionMessage : t("successRequestAccess.description")}
				confirmButtonText={t("successRequestAccess.primaryButton")}
				open={openSuccessEmail}
				onClick={async () => {
					setOpenSuccessEmail(false);
				}}
				hideCancel={true}
				paperClassName={styles.customPaperClass}
			></ConfirmationPopup>
		</Box>
	);
};

const ObservedComponent = observer(RequestAccessContainer);
export default ObservedComponent;
