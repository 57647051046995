import { getSelectedOrganization } from "../helpers/localStorage";
import { Font } from "./font";

export interface BrandText {
	id?: number;
	brand_definition: number;
	brand_definition_id: number;
	font?: Font;
	font_id?: number;
	type: string;
	created_at: Date;
	updated_at: Date;
}

const getOrgId = () => getSelectedOrganization()?.organization?.uid || "";

export const defaultBrandText: BrandText = {
	id: 0,
	brand_definition: 0,
	brand_definition_id: 0,
	font: {
		id: 0,
		name: "",
		org_id: getOrgId(),
		src: "",
		created_at: new Date(),
		updated_at: new Date(),
	},
	font_id: 0,
	created_at: new Date(),
	updated_at: new Date(),
	type: "",
};
