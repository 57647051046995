import "firebase/analytics";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import "firebase/database";
import { getDatabase } from "firebase/database";
import "firebase/performance";
import { getPerformance } from "firebase/performance";
import "firebase/remote-config";
import { isCloudEnv } from "./env.ts";

const config = {
	apiKey: "AIzaSyAaFpGrDQWREIYlqPG06gn4gg5oB0E58M8",
	authDomain: "auth.bria.ai",
	databaseURL: "https://image-viewer-120cb.firebaseio.com",
	projectId: "image-viewer-120cb",
	storageBucket: "image-viewer-120cb.appspot.com",
	messagingSenderId: "573857372557",
	appId: "1:573857372557:web:3f8218f4d1ef37903a85c4",
	measurementId: "G-S63HD9ZS4V",
};

export const firebaseApp = initializeApp(config);
export const firebaseDatabase = getDatabase(firebaseApp);
export const firebaseAuth = getAuth(firebaseApp);
export const firebasePerformance = getPerformance(firebaseApp);
export const firebaseAnalytics = getAnalytics(firebaseApp);
const enableAnalytics = isCloudEnv();
firebasePerformance.dataCollectionEnabled = enableAnalytics;
firebasePerformance.instrumentationEnabled = enableAnalytics;
setAnalyticsCollectionEnabled(firebaseAnalytics, enableAnalytics);
