import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function AlignmentIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 18 18" {...props}>
			<path
				d="M0.999939 5.86914H8.99994M8.99994 11.8691H16.9999M8.99994 0.869141V16.8691M2.99994 0.869141H14.9999C16.1045 0.869141 16.9999 1.76457 16.9999 2.86914V14.8691C16.9999 15.9737 16.1045 16.8691 14.9999 16.8691H2.99994C1.89537 16.8691 0.999939 15.9737 0.999939 14.8691V2.86914C0.999939 1.76457 1.89537 0.869141 2.99994 0.869141Z"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default AlignmentIcon;
