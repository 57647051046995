function Undo({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.8117 13.4886L6.51758 10.1945M6.51758 10.1945L9.8117 6.90039M6.51758 10.1945H15.5764C16.4501 10.1945 17.2879 10.5416 17.9057 11.1593C18.5235 11.7771 18.8705 12.615 18.8705 13.4886C18.8705 14.3623 18.5235 15.2002 17.9057 15.8179C17.2879 16.4357 16.4501 16.7827 15.5764 16.7827H14.7529"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Undo;
