import { Box, SelectChangeEvent, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import InfoIcon from "../../../../../DesignEditor/assets/svgs/InfoIcon.svg";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import StaticDropdown, { AspectRatio } from "../../../../../components/common/DropDowns/StaticDropdown";
import TextToImageModelsDropdown, {
	getModelKey,
} from "../../../../../components/common/DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown";
import { useAppStore } from "../../../../../hooks/useStores";
import styles from "./GenerateImagesSection.module.scss";

interface IProps {
	textToGenarate: string;
	setTextToGenarate: (args: string) => void;
	selctedAspectRatio: AspectRatio;
	handleGenAspectRatioChange: (args: SelectChangeEvent<AspectRatio | AspectRatio[]>) => void;
	handleGenSubmit: () => void;
}
const GenerateImagesSection = ({
	textToGenarate,
	setTextToGenarate,
	selctedAspectRatio,
	handleGenAspectRatioChange,
	handleGenSubmit,
}: IProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.images" });
	const { textToImageStore } = useAppStore();
	return (
		<Box className={styles.generateImage}>
			<Box className={styles.titleContainer}>
				<Typography className={styles.title}>{t("imageGeneration.title")}</Typography>
				<Box onClick={() => {}} className={styles.infoIcon}>
					<img src={InfoIcon} alt="info icon" />
				</Box>
			</Box>
			<TextToImageModelsDropdown
				selectedValue={
					textToImageStore.config.tailored_model_id ||
					getModelKey(textToImageStore.config.model, textToImageStore.config.model_version)
				}
				handleChange={textToImageStore.handleText2ImagePopupChange}
				placeholder={t("models.placeholder")}
			/>

			<BriaInput
				multiline
				fullWidth={true}
				type="text"
				placeholder={t("imageGeneration.placeholderText")}
				value={textToGenarate}
				onEnterPress={handleGenSubmit}
				onChange={(e) => {
					setTextToGenarate(e.target.value);
				}}
				InputProps={{
					className: styles.textFieldStyle,
				}}
			/>
			<StaticDropdown<AspectRatio>
				type="aspectRatio"
				value={selctedAspectRatio}
				onChange={handleGenAspectRatioChange}
				placeholder={t("imageGeneration.defaultGenAspectRatio.placeholder")}
				className={styles.dropDown}
				width={"-webkit-fill-available"}
				height="32px"
				inputProps={{ className: styles.dropdownAspectRatio }}
			/>
			<BriaButton
				className={styles.primaryButton}
				buttonType="primary"
				onClick={handleGenSubmit}
				disabled={!textToGenarate}
			>
				{t("imageGeneration.button")}
			</BriaButton>
		</Box>
	);
};

const ObservedComponent = observer(GenerateImagesSection);
export default ObservedComponent;
