import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../../../components/common/BriaButtonGroup/BriaButtonGroup";
import BriaDropdown, { DropDownItem } from "../../../../components/common/BriaDropDown/BriaDropDown";
import { isProduction } from "../../../../config/env";
import { firebaseAuth } from "../../../../config/firebase";
import { IframePostMessageTypes, PagesEnum, getIframeHost } from "../../iframeTypes";
import styles from "./IframePreview.module.scss";

interface Props {
	iframeUrl: string;
	pagesOptions: DropDownItem[];
	previewPage?: PagesEnum;
	setPreviewPage: (previewPage: PagesEnum) => void;
}

const IframePreview = ({ iframeUrl, pagesOptions, previewPage, setPreviewPage }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "iframeEditor.preview" });
	const [iframeKey, setIframeKey] = useState(0);
	const iframeRef = useRef<any>();

	const refreshIframe = () => {
		setIframeKey((prevKey) => prevKey + 1);
	};

	useEffect(() => {
		if (iframeRef.current) {
			window.addEventListener(
				"message",
				(event) => {
					if (event.data && event.data["type"]) {
						if (!isProduction()) {
							console.log(event.data);
						}
						if (event.data["type"] === IframePostMessageTypes.AuthInit) {
							firebaseAuth.currentUser?.getIdToken().then((token) => {
								iframeRef.current.contentWindow.postMessage(
									{
										type: IframePostMessageTypes.Auth,
										message: token,
									},
									getIframeHost(),
								);
							});
						}
					}
				},
				false,
			);
		}
	}, [iframeRef.current]);

	return (
		<Box className={styles.container}>
			<Box className={styles.iframePreviewWrapper}>
				<Box className={styles.headerWithButtons}>
					<Typography className={styles.subHeader}>{t("iframePreview")}</Typography>
					<BriaButtonGroup>
						<BriaButton onClick={refreshIframe} buttonType="textSmall">
							{t("refreshBtn")}
						</BriaButton>
						<BriaDropdown
							value={previewPage}
							onChange={(e) => setPreviewPage(e.target.value as PagesEnum)}
							items={pagesOptions}
							className={styles.dropDown}
							placeholder={t("selectPage")}
						/>
					</BriaButtonGroup>
				</Box>
				<iframe
					id="iframePreview"
					key={iframeKey}
					src={iframeUrl}
					className={styles.iframePreview}
					frameBorder="0"
					ref={iframeRef}
				></iframe>
			</Box>
		</Box>
	);
};

export default IframePreview;
