import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import DeleteIcon from "../../../../../../../../../assets/images/icons/DeleteIcon";
import { EditIcon } from "../../../../../../../../../assets/images/icons/EditIcon";
import SolidGradientColorPicker from "../../../../../../../../../components/common/SolidGradientColorPicker/SolidGradientColorPicker";

import PlusIcon from "../../../../../../../../../assets/images/icons/PlusIcon";
import BriaIconButton from "../../../../../../../../../components/common/BriaIconButton/BriaIconButton";
import { ColorTypeMappingEnum } from "../../../../../../../../../models/common";
import common from "../../Common.module.scss";
import styles from "./ColorItem.module.scss";

interface ColorItemProps {
	color: string;
	colors: string[];
	index: number;
	isAdminMode: boolean | undefined;
	activeColorIndex: number | null;
	setActiveColorIndex: (index: number | null) => void;
	setColors: (colors: string[]) => void;
}

const ColorItem: React.FC<ColorItemProps> = ({
	color,
	colors,
	index,
	isAdminMode,
	activeColorIndex,
	setActiveColorIndex,
	setColors,
}) => {
	const colorItemRef = useRef<HTMLDivElement>(null);
	const emptyColor = color.trim().length === 0;
	const colorKey = `COLOR_${index + 1}` as keyof typeof ColorTypeMappingEnum;

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (colorItemRef.current && !colorItemRef.current.contains(event.target as Node)) {
				setActiveColorIndex(null);
			}
		};

		if (activeColorIndex === index) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [activeColorIndex, index, setActiveColorIndex]);

	const handleDeleteColor = (index: number) => {
		const updatedColors = [...colors];
		updatedColors[index] = "";
		setColors(updatedColors);
	};

	const handleColorChange = (color: string, index: number) => {
		const updatedColors = [...colors];
		updatedColors[index] = color;
		setColors(updatedColors);
	};

	const renderItemActions = () => {
		if (isAdminMode) {
			return (
				<Box className={common.itemActions}>
					{color && (
						<BriaIconButton className={common.deleteBtn} onClick={() => handleDeleteColor(index)}>
							<DeleteIcon />
						</BriaIconButton>
					)}

					<BriaIconButton
						className={common.editBtn}
						onClick={() => {
							if (activeColorIndex !== index) {
								setActiveColorIndex(index);
							} else {
								setActiveColorIndex(null);
							}
						}}
					>
						<EditIcon />
					</BriaIconButton>
					{activeColorIndex === index && (
						<Box className={styles.colorPicker}>
							<SolidGradientColorPicker
								color={color}
								onChange={(color) => {
									handleColorChange(color, index);
								}}
							/>
						</Box>
					)}
				</Box>
			);
		}
	};

	return (
		<Box
			ref={colorItemRef}
			key={index}
			className={clsx(styles.ColorItemContainer, common.listItem, { [common.editable]: isAdminMode })}
		>
			<Box className={common.itemContent}>
				<Box
					sx={{ background: color }}
					className={clsx(common.itemPreview, {
						[common.emptyStatus]: emptyColor,
					})}
				>
					{!color && <PlusIcon />}
				</Box>
				<Typography className={common.itemLabel}>{ColorTypeMappingEnum[colorKey]}</Typography>
			</Box>
			{renderItemActions()}
		</Box>
	);
};

export default ColorItem;
