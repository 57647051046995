import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ANALYTICS_EVENTS } from "../../../../../analytics-store.tsx";
import DeleteIcon from "../../../../../assets/images/icons/DeleteIcon";
import DownloadIcon from "../../../../../assets/images/icons/DownloadIcon";
import FullscreenIcon from "../../../../../assets/images/icons/Image/FullscreenIcon";
import BriaTooltip from "../../../../../components/common/BriaTooltip/BriaTooltip";
import { BriaImageProps } from "../../../../../components/common/Galleries/BriaImage.tsx";
import ScrollableGallery from "../../../../../components/common/Galleries/ScrollableGallery";
import { useAppStore } from "../../../../../hooks/useStores";
import { PlaygroundImage, PlaygroundResult } from "../../../../../models/image-to-image";
import PlaygroundImageOverlay from "../../../Images/Overlay/PlaygroundImageOverlay";
import styles from "./UploadedOrSavedResult.module.scss";

type Props = {
	playgroundResult: PlaygroundResult;
	label?: string;
};

const UploadedOrSavedResult = ({ playgroundResult, label = "upload" }: Props) => {
	const { t } = useTranslation("translation", {
		keyPrefix: label === "save" ? "playground.savedImages.result" : "playground.uploadedImages.result",
	});
	const { playgroundStore, analyticsStore } = useAppStore();
	const [openFullScreen, setOpenFullScreen] = useState(false);

	return (
		<Box
			className={clsx({
				[styles.galleryWrapper]: true,
				["galleryWrapper"]: true,
			})}
		>
			<Box className={styles.header}>
				<Box className={styles.headerText}>{t("header")}</Box>
				<Box className={styles.actionBtns}>
					<BriaTooltip title={t("download")}>
						<DownloadIcon
							className={styles.iconButton}
							onClick={() => playgroundStore.downloadImages(playgroundResult.images, "bria_uploaded")}
						/>
					</BriaTooltip>
					<BriaTooltip title={t("delete")}>
						<DeleteIcon
							className={styles.iconButton}
							onClick={() => playgroundStore.deleteResult(playgroundResult.id)}
						/>
					</BriaTooltip>
					<BriaTooltip title={t("fullScreen")}>
						<FullscreenIcon className={styles.iconButton} onClick={() => setOpenFullScreen(true)} />
					</BriaTooltip>
				</Box>
			</Box>
			<ScrollableGallery
				images={playgroundResult.images.map(
					(image) =>
						({
							...image,
							ImageOverlay: <PlaygroundImageOverlay {...{ image }} />,
							displayOverlay: "hover",
							selectable: true,
							htmlJsonData: image.id,
							fullScreenProps: {
								fileName: image.file?.name.split(".")[0],
							},
							downloadProps: { fileName: playgroundStore.getFileName(image) },
						}) as BriaImageProps & PlaygroundImage,
				)}
				fullScreenProps={{
					open: openFullScreen,
					onClose: () => setOpenFullScreen(false),
					displayMode: "scroll",
				}}
				className={styles.gallery}
				onDownload={(fullscreenImages) => {
					fullscreenImages.forEach((fullscreenImage) => {
						playgroundStore.selectedConfig === "productPlacement" &&
							analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_PP);

						const itiImage = playgroundResult.images.find((img) => img.url === fullscreenImage.src);
						itiImage &&
							itiImage.type === "imageToImage" &&
							analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_ITI, itiImage);
					});
				}}
			/>
		</Box>
	);
};

export default observer(UploadedOrSavedResult);
