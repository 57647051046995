import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingPlaceholder from "../../../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import { Layout, LayoutLayer } from "../../../../models/layout";
import { getGraphicIndex, isGraphicalElement } from "../Editor/Form/LayoutConfig";
import styles from "./LayoutSkeleton.module.scss";

type Props = {
	layout: Layout;
	loading?: boolean;
};
const LayoutSkeleton = ({ layout, loading = false }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.layoutEditor.form.preview" });
	const maxWidth = 327;
	const maxHeight = 380;

	let containerWidth = layout?.placement?.width ?? maxWidth;
	let containerHeight = layout?.placement?.height ?? maxHeight;
	if (containerWidth > maxWidth || containerHeight > maxHeight) {
		const widthRatio = maxWidth / containerWidth;
		const heightRatio = maxHeight / containerHeight;
		const ratio = Math.min(widthRatio, heightRatio);

		containerWidth = Math.floor(containerWidth * ratio);
		containerHeight = Math.floor(containerHeight * ratio);
	}

	const Container = ({ children }: any) => {
		if (!layout || (isNaN(layout?.placement?.height) && isNaN(layout?.placement?.width))) return null;

		return (
			<Box
				style={{
					width: `${containerWidth}px`,
					height: `${containerHeight}px`,
				}}
				className={styles.eleContainer}
			>
				{children}
			</Box>
		);
	};

	const EleWithPosition = ({ x, y, width, height, children, border, backGroundColor, zIndex }: any) => (
		<>
			{Boolean(width && height) && (
				<Box
					style={{
						position: "absolute",
						left:
							x + width >= layout?.placement?.width ? "auto" : (x / layout?.placement?.width) * 100 + "%",
						top:
							y + height >= layout?.placement?.height
								? "auto"
								: (y / layout?.placement?.height) * 100 + "%",
						width: (width / layout?.placement?.width) * 100 + "%",
						height: (height / layout?.placement?.height) * 100 + "%",
						right: x + width >= layout?.placement?.width ? 0 : "auto",
						bottom: y + height >= layout?.placement?.height ? 0 : "auto",
						border: border,
						backgroundColor: backGroundColor,
						zIndex: zIndex,
					}}
				>
					{children}
				</Box>
			)}
		</>
	);
	return (
		<>
			<LoadingPlaceholder className={styles.loaderStyle} isLoading={loading}>
				{layout && (
					<Box className={styles.mainContainer}>
						<Box>
							<Container>
								{layout?.order?.map((key: string, index: number) => {
									const zIndex = layout?.order.length - index;

									if (isGraphicalElement(key)) {
										const graphicIndex = getGraphicIndex(key);
										return (
											<EleWithPosition
												key={key}
												x={layout?.graphical_elements?.[graphicIndex]?.placement?.x}
												y={layout?.graphical_elements?.[graphicIndex]?.placement?.y}
												width={layout?.graphical_elements?.[graphicIndex]?.placement?.width}
												height={layout?.graphical_elements?.[graphicIndex]?.placement?.height}
												border="1px solid var(--secondary-text-on-secondary-color, rgba(91, 91, 91, 0.50))"
												backGroundColor="rgb(223, 217, 232,0.5)"
												zIndex={zIndex}
											>
												<Typography style={{ zIndex: zIndex }} className={styles.eleName}>
													{t("graphics")}
													{graphicIndex + 1}
												</Typography>
											</EleWithPosition>
										);
									} else {
										const elementData: any = {
											primary_message: {
												label: t("mainText"),
												backgroundColor: "rgba(190, 182, 202, 0.50)",
											},
											secondary_message: {
												label: t("secondaryText"),
												backgroundColor: "rgba(190, 182, 202, 0.50)",
											},
											logo: {
												label: t("logo"),
												backgroundColor: "rgba(136, 0, 255, 0.50)",
											},
											cta: {
												label: t("cta"),
												backgroundColor: "#D80067",
											},
											foreground: {
												label: t("object"),
												backgroundColor: "rgba(216, 204, 232, 0.50)",
											},
											background: {
												label: t("background"),
												backgroundColor: "rgba(216, 204, 232, 0.50)",
											},
										};
										const { label = "", backgroundColor = "" } = elementData[key] || {};

										return (
											<EleWithPosition
												key={key}
												x={layout?.[key as LayoutLayer]?.placement?.x}
												y={layout?.[key as LayoutLayer]?.placement?.y}
												width={layout?.[key as LayoutLayer]?.placement?.width}
												height={layout?.[key as LayoutLayer]?.placement?.height}
												border="1.002px solid var(--secondary-text-on-secondary-color, rgba(91, 91, 91, 0.50))"
												backGroundColor={backgroundColor}
												zIndex={zIndex}
											>
												<Typography style={{ zIndex: zIndex }} className={styles.eleName}>
													{label}
												</Typography>
											</EleWithPosition>
										);
									}
								})}
							</Container>
							<Box>
								{!isNaN(layout?.placement?.height) && !isNaN(layout?.placement?.width) && (
									<Typography className={styles.containerAspectRatio}>
										{layout?.placement?.width} x {layout?.placement?.height}px
									</Typography>
								)}
							</Box>
						</Box>
					</Box>
				)}
			</LoadingPlaceholder>
		</>
	);
};

export default LayoutSkeleton;
