import { Box, Dialog, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import WelcomeImage from "../../../assets/images/welcome.png";
import RouterConstants from "../../../constants/RouterConstants.ts";
import useOrgNavigation from "../../../hooks/useOrgNavigation.tsx";
import BriaButton from "../BriaButton/BriaButton";
import styles from "./WelcomePopup.module.scss";

interface IProps {
	open: boolean;
	onClose: () => void;
}

export default function WelcomePopup({ open, onClose }: IProps) {
	const { t } = useTranslation("translation", { keyPrefix: "welcomePopups.console" });
	const { orgNavigationHandler } = useOrgNavigation();

	return (
		<Dialog
			classes={{
				paper: styles.paper,
			}}
			onClose={onClose}
			open={open}
			maxWidth={false}
		>
			<Box className={styles.container}>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("title")}</Typography>
					<Box className={styles.descriptionContainer}>
						<Typography className={styles.description}>
							{t("description1-1")} <span className={styles.bold}>{t("description1-2")}</span>
						</Typography>
						<Typography className={styles.description}>
							{t("description2-1")} <span className={styles.bold}>{t("description2-2")}</span>
							{t("description2-3")}
						</Typography>
						<Typography className={styles.description}>{t("description3")}</Typography>
						<Typography className={styles.description}>{t("description4")}</Typography>
					</Box>
					<Box className={styles.buttonsContainer}>
						<Box className={styles.buttons}>
							<BriaButton
								onClick={() => orgNavigationHandler(RouterConstants.AUTH_KEYS.fullPath, onClose)}
								className={styles.upgradeButton}
								buttonType="primaryMedium"
							>
								{t("getApiKey")}
							</BriaButton>
							<BriaButton className={styles.gotItButton} onClick={onClose} buttonType="tertiaryMedium">
								{t("gotIt")}
							</BriaButton>
						</Box>
					</Box>
				</Box>
				<Box className={styles.imageContainer}>
					<img alt="welcome image" src={WelcomeImage} />
				</Box>
			</Box>
		</Dialog>
	);
}
