import {
	User as FirebaseUser,
	applyActionCode,
	checkActionCode,
	confirmPasswordReset,
	createUserWithEmailAndPassword,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signInWithPopup,
} from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import RouterConstants from "../constants/RouterConstants";

import { useTranslation } from "react-i18next";
import { firebaseAuth } from "../config/firebase.ts";
import { createFirebaseUser, getUserWithUID } from "../helpers/firebase.ts";
import useErrorToast from "./useErrorToast.tsx";
import { useAppStore } from "./useStores.tsx";

export const useAuthService = () => {
	const errorToast = useErrorToast();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const EXCLUSION_EXCEPTIONS_LIST = ["auth/popup-closed-by-user", "auth/cancelled-popup-request"];
	const [loading, setIsLoading] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<boolean | string>(false);
	const { authStore, analyticsStore } = useAppStore();

	const loginWithEmail = async (
		email: string,
		password: string,
		continueUrl?: string,
		state?: any,
		onSuccessCallback?: () => void,
	) => {
		setIsLoading(true);
		try {
			await signInWithEmailAndPassword(firebaseAuth, email, password);
			onSuccessCallback?.();
			if (continueUrl) {
				navigate(continueUrl, { state: state });
			}
		} catch (error: any) {
			setErrorMessage(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	async function createFirebaseUserAndUpdateStore(
		userToRegister: FirebaseUser,
		getInfo: boolean,
		username?: string,
		company?: string,
		role?: string,
	) {
		await createFirebaseUser(userToRegister, getInfo, username, company, role);

		// Retry getting user if it doesn't exist immediately
		let updatedUser = await getUserWithUID(userToRegister.uid);
		if (!updatedUser) {
			await new Promise((resolve) => setTimeout(resolve, 500));
			updatedUser = await getUserWithUID(userToRegister.uid);
		}

		await authStore.setUser(updatedUser);
		await authStore.setPostRegistrationConfigs();
		analyticsStore.logRegistrationEvent();
		return updatedUser;
	}

	const authenticateWithPopup = async (provider: any) => {
		try {
			const result = await signInWithPopup(firebaseAuth, provider);
			const userToRegister = result.user;

			if (!userToRegister) {
				errorToast.showError();
				return Promise.reject(t("somethingWentWrong"));
			}

			const userObject = await getUserWithUID(userToRegister.uid);

			if (userObject) {
				// Login
				return Promise.resolve(userObject?.getInfo ?? true);
			} else {
				// Signup
				try {
					const updatedUser = await createFirebaseUserAndUpdateStore(userToRegister, false);
					return Promise.resolve(updatedUser?.getInfo ?? true);
				} catch (error) {
					errorToast.showError(t("couldntRegisterFirebaseUser"));
					return Promise.reject(error);
				}
			}
		} catch (error: any) {
			if (error.code === "auth/account-exists-with-different-credential") {
				errorToast.showError(t("userExistsWithDifferentCredentials"));
				return Promise.reject(t("userExistsWithDifferentCredentials"));
			} else if (!EXCLUSION_EXCEPTIONS_LIST.includes(error.code)) {
				errorToast.showError();
				return Promise.reject(error);
			}
			authStore.registrationSource = undefined;
		}
	};

	const registerWithEmail = async (
		username: string,
		email: string,
		password: string,
		company?: string,
		role?: string,
	) => {
		try {
			setIsLoading(true);
			const result = await createUserWithEmailAndPassword(firebaseAuth, email, password);
			const userToRegister = await result.user;
			if (!userToRegister) {
				errorToast.showError();
				return Promise.reject(t("somethingWentWrong"));
			}
			const updatedUser = await createFirebaseUserAndUpdateStore(userToRegister, true, username, company, role);
			setIsLoading(false);
			return Promise.resolve(updatedUser?.getInfo ?? true);
		} catch (error: any) {
			setIsLoading(false);
			setErrorMessage(error.message);
			authStore.registrationSource = undefined;
			return Promise.reject(error);
		}
	};

	const resetPassword = async (email: string, continueUrl?: string, state?: any) => {
		setIsLoading(true);
		if (continueUrl && state && state.prompt) {
			continueUrl += `?prompt=${encodeURIComponent(state.prompt)}`;
		}
		const actionCodeSettings = continueUrl
			? {
					url: `${location.origin}${continueUrl}`,
			  }
			: undefined;

		return !actionCodeSettings
			? sendPasswordResetEmail(firebaseAuth, email)
			: sendPasswordResetEmail(firebaseAuth, email, actionCodeSettings)
					.then(() => {
						setErrorMessage(false);
					})
					.catch((error) => {
						setErrorMessage(error.message);
						errorToast.showError();
					})
					.finally(() => {
						setIsLoading(false);
					});
	};

	const confirmPasswordResetFunction = (code: string, newPassword: string) => {
		setIsLoading(true);
		return confirmPasswordReset(firebaseAuth, code, newPassword)
			.then((res) => res)
			.catch((error) => {
				setErrorMessage(error.code);
				// var errorCode = error.code;
				// var errorMessage = error.message;
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const sendEmailVerificationFunction = () => {
		sendEmailVerification(firebaseAuth.currentUser!)
			// 	{
			// 	handleCodeInApp: true,
			// 	url: "http://localhost:3000/gallery",
			// }
			.then(() => {})
			.catch((error) => {
				setErrorMessage(error.message);
			});
	};

	const verifyUser = (code: string) => {
		return checkActionCode(firebaseAuth, code)
			.then(() => {
				return applyActionCode(firebaseAuth, code);
			})
			.then(() => {
				navigate(RouterConstants.CONSOLE.path);
			});
	};

	return {
		loginWithEmail,
		registerWithEmail,
		authenticateWithPopup,
		resetPassword,
		confirmPasswordResetFunction,
		sendEmailVerificationFunction,
		verifyUser,
		loading,
		errorMessage,
		setErrorMessage,
	};
};
