import { NavigateNext } from "@mui/icons-material";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import BriaButton, { BriaButtonProps } from "../../../components/common/BriaButton/BriaButton";
import { SubSwipableMenuProps } from "../SubSwipableMenu/SubSwipableMenu";

export type SwipableMenuButtonProps = {
	swipeTo: ReactNode;
	subSwipableMenuProps?: Partial<SubSwipableMenuProps>;
	children?: ReactNode;
} & BriaButtonProps;

const SwipableMenuButton = observer(
	({ swipeTo, children, buttonType = "textMedium", ...rest }: SwipableMenuButtonProps) => {
		return (
			<BriaButton endIcon={<NavigateNext />} buttonType={buttonType} {...rest}>
				{children}
			</BriaButton>
		);
	},
);

SwipableMenuButton.displayName = "SwipableMenuButton";

export default SwipableMenuButton;
