import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Alert, Box, ButtonProps, CircularProgress, Dialog, Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../BriaButton/BriaButton";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder.tsx";
import styles from "./ConfirmationPopup.module.scss";

interface IProps {
	open: boolean;
	title?: string;
	description?: string | React.ReactNode;
	subDescription?: string | React.ReactNode;
	subTitle?: string;
	confirmButtonText: string;
	hideCancel?: boolean;
	onClick: () => void;
	onClose: () => void;
	onSecondaryClick?: () => void;
	children?: React.ReactNode;
	disableConfirm?: boolean;
	hideCloseIcon?: boolean;
	firstButtonText?: string;
	errorMessage?: string | null;
	loading?: boolean;
	loadingBody?: boolean;
	paperClassName?: string;
	contentClassName?: string;
	maxWidth?: string;
	image?: any;
}

export default function ConfirmationPopup({
	open,
	title,
	description,
	subDescription,
	subTitle,
	confirmButtonText,
	onClick,
	onClose,
	hideCancel,
	children,
	disableConfirm,
	firstButtonText,
	onSecondaryClick,
	errorMessage,
	loading = false,
	loadingBody = false,
	hideCloseIcon = false,
	paperClassName = "",
	contentClassName = "",
	maxWidth = "552px",
	image,
}: IProps & ButtonProps) {
	const { t } = useTranslation();

	return (
		<Dialog
			classes={{
				paper: clsx(styles.paper, paperClassName, { [styles.hasImage]: !!image }),
			}}
			onClose={onClose}
			open={open}
			maxWidth={false}
			PaperProps={{
				style: {
					maxWidth: maxWidth, // Set your desired width in pixels
				},
			}}
		>
			<LoadingPlaceholder isLoading={loadingBody} className={clsx(styles.loaderStyle)}>
				{image && <img className={styles.image} src={image} alt="popup image" />}
				<Box className={clsx(styles.contentContainer, contentClassName)}>
					{!hideCloseIcon && <CloseIcon onClick={onClose} className={styles.closeIcon} />}
					<Typography className={styles.title}>{title}</Typography>
					<Typography className={styles.description}>{description}</Typography>
					{subDescription && <Typography className={styles.subDescription}>{subDescription}</Typography>}
					{subTitle && <Typography className={styles.subTitle}>{subTitle}</Typography>}
					{children}
					<Box className={styles.buttonsContainer}>
						<Box>
							{errorMessage && (
								<Alert severity="error" variant="standard" className={styles.errorMessage}>
									{errorMessage}
								</Alert>
							)}
						</Box>
						<Box className={styles.buttons}>
							{!hideCancel && (
								<BriaButton
									onClick={onSecondaryClick ?? onClose}
									className={styles.cancelBtn}
									buttonType="tertiaryMedium"
								>
									{firstButtonText ? firstButtonText : t("cancel")}
								</BriaButton>
							)}

							<BriaButton
								className={styles.confirmBtn}
								disabled={disableConfirm}
								onClick={onClick}
								buttonType="primaryMedium"
							>
								{confirmButtonText}
								{loading && (
									<Box className={styles.circleLoader}>
										{<CircularProgress color="inherit" size={25} />}
									</Box>
								)}
							</BriaButton>
						</Box>
					</Box>
				</Box>
			</LoadingPlaceholder>
		</Dialog>
	);
}
