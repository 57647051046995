import {
	Avatar,
	Box,
	FormControl,
	List,
	ListItem,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	MenuItem,
	OutlinedInput,
	SelectChangeEvent,
} from "@mui/material";
import { SelectProps } from "@mui/material/Select";
import React, { Dispatch, SetStateAction, useState } from "react";
import { USER_ORGANIZATION_ROLES } from "../../../constants/UserConstants.ts";
import { MyAccountUser } from "../../../models/organization.ts";
import BriaCheckbox from "../BriaCheckbox/BriaCheckbox.tsx";
import BriaSelect from "../BriaSelect/BriaSelect.tsx";
import SearchBar from "../Searchbar/SearchBar.tsx";
import styles from "./GrantPermissions.module.scss";

interface IProps {
	items: MyAccountUser[];
	roles: USER_ORGANIZATION_ROLES[];
	ancestorsRole: string;
	setAncestorsRole: Dispatch<SetStateAction<string>>;
	ancestorsSelectedUsers: string[];
	setAncestorsSelectedUsers: Dispatch<SetStateAction<string[]>>;
}

const GrantPermissions: React.FC<IProps & SelectProps> = ({
	items,
	roles,
	ancestorsRole,
	setAncestorsRole,
	ancestorsSelectedUsers,
	setAncestorsSelectedUsers,
}) => {
	const [filteredItems, setFilteredItems] = useState(items);
	const handleAncestorsUsersChange = (value: string) => () => {
		const currentIndex = ancestorsSelectedUsers.indexOf(value);
		const newChecked = [...ancestorsSelectedUsers];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setAncestorsSelectedUsers(newChecked);
	};

	const handleAncestorsRoleChange = (event: SelectChangeEvent<string>) => {
		const {
			target: { value },
		} = event;
		setAncestorsRole(value);
	};

	const handleSearch = (query: string) => {
		const filtered = items.filter(
			(item) => item?.user_name?.toLowerCase().includes(query) || item?.email?.toLowerCase().includes(query),
		);
		setFilteredItems(filtered);
	};

	return (
		<Box className={styles.container}>
			<SearchBar onSearch={handleSearch} />
			<List className={styles.usersList}>
				{filteredItems.map((item) => {
					const labelId = `checkbox-list-secondary-label-${item.uid}`;
					return (
						<ListItem
							key={item.uid}
							secondaryAction={
								<BriaCheckbox
									edge="end"
									onChange={handleAncestorsUsersChange(item.uid)}
									checked={ancestorsSelectedUsers.indexOf(item.uid) !== -1}
									inputProps={{ "aria-labelledby": labelId }}
								/>
							}
							disablePadding
						>
							<ListItemButton>
								<ListItemAvatar>
									<Avatar src={`${item.profile_picture}`} />
								</ListItemAvatar>
								<ListItemText id={labelId} primary={`${item.user_name}`} secondary={`${item.email}`} />
							</ListItemButton>
						</ListItem>
					);
				})}
			</List>
			<FormControl className={styles.searchForm}>
				<BriaSelect
					value={ancestorsRole}
					onChange={(event: SelectChangeEvent<any>) => handleAncestorsRoleChange(event)}
					input={<OutlinedInput />}
					inputProps={{ "aria-label": "Without label" }}
				>
					{roles.map((name) => (
						<MenuItem key={name} value={name} sx={{ textTransform: "capitalize" }}>
							{name}
						</MenuItem>
					))}
				</BriaSelect>
			</FormControl>
		</Box>
	);
};

export default GrantPermissions;
