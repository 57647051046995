import backgroundRemoval from "../assets/images/pngs/API-Remove-Background.png";
import backgroundBlur from "../assets/images/pngs/API-Blur-Background.png";
import imageToPsd from "../assets/images/pngs/API-Image-to-PSD.png";
import imageExpansion from "../assets/images/pngs/API-Image-Expansion.png";
import increase_res from "../assets/images/pngs/API-Increase-Resolution.png";
import masksGenerator from "../assets/images/pngs/API-Masks-Generator.png";
import modifyPresenter from "../assets/images/pngs/API-Modify-Presenter.png";
import objectRemoval from "../assets/images/pngs/API-Object-Removal.png";
import TexttoImage from "../assets/images/pngs/API-Text-to-Image.png";
import LabelessAIImageSearch from "../assets/images/pngs/API-Labeless-AI-Image-Search.png";
import managedTailoredModelsPng from "../assets/images/pngs/API-Generate-Images.png";
import manageDatasetsPng from "../assets/images/pngs/API-Manage-Datasets.png";
import trainModelsPng from "../assets/images/pngs/API-Train-Models.png";
import generateImagesPng from "../assets/images/pngs/API-Managed-Tailored-Models.png";
import productCutoutsPng from "../assets/images/pngs/API-Product-Cutouts.png";
import productPackShotsPng from "../assets/images/pngs/API-Product-Pack-Shots.png";
import lifestyleProductTextPng from "../assets/images/pngs/API-Lifestyle-Product-Text.png";
import lifestyleProductShotByImagePng from "../assets/images/pngs/API-Lifestyle-Product-Image.png";
import consistentStudioShotsPng from "../assets/images/pngs/API-Consistent-Studio-Shots.png";
import contextualKeywordExtractionPng from "../assets/images/pngs/API-Contextual-Keyword-Extraction.png";
import { IAPICardsProps } from "../components/API/APICardComponent";
import LinkConstants from "./LinkConstants";

export enum APICardType {
	BaseSuiteAPICards = "base-platform",
	EcommProdAPICards = "ecommerce-suite",
	BranedGenAPICards = "branded-generation",
}

export const APICards: IAPICardsProps[] = [
	{
		type: APICardType.BaseSuiteAPICards,
		title: "text_to_image",
		description: "textToImageDescription",
		link: LinkConstants.BRIA_API_TEXT_TO_IMAGE,
		image: TexttoImage,
	},
	{
		type: APICardType.BaseSuiteAPICards,
		title: "increase_Resolution",
		description: "increaseResolutionDescription",
		link: LinkConstants.BRIA_API_INCREASE_RESOLUTION,
		image: increase_res,
	},
	{
		type: APICardType.BaseSuiteAPICards,
		title: "labeless_AI_Image_Search",
		description: "labelessAIImageSearchDescription",
		link: LinkConstants.BRIA_API_LABELESS_AI_IMAGE_SEARCH,
		image: LabelessAIImageSearch,
	},
	{
		type: APICardType.BaseSuiteAPICards,
		title: "background_Removal",
		description: "backgroundRemovalDescription",
		link: LinkConstants.BRIA_API_BACKGROUND_REMOVAL,
		image: backgroundRemoval,
	},
	{
		type: APICardType.BaseSuiteAPICards,
		title: "blur_Background",
		description: "blurBackgroundDescription",
		link: LinkConstants.BRIA_API_BACKGROUND_BLUR,
		image: backgroundBlur,
	},
	{
		type: APICardType.BaseSuiteAPICards,
		title: "image_Expansion",
		description: "imageExpansionDescription",
		link: LinkConstants.BRIA_API_IMAGE_EXPANSTION,
		image: imageExpansion,
	},
	{
		type: APICardType.BaseSuiteAPICards,
		title: "masks_Generator",
		description: "masksGeneratorDescription",
		link: LinkConstants.BRIA_API_MASK_GENERATOR,
		image: masksGenerator,
	},
	{
		type: APICardType.BaseSuiteAPICards,
		title: "object_Removal",
		description: "objectRemovalDescription",
		link: LinkConstants.BRIA_API_REMOVE_OBJECT,
		image: objectRemoval,
	},
	{
		type: APICardType.BaseSuiteAPICards,
		title: "modify_Presenter",
		description: "modifyPresenterDescription",
		link: LinkConstants.BRIA_API_MODIFY_PRESENTER,
		image: modifyPresenter,
	},
	{
		type: APICardType.BaseSuiteAPICards,
		title: "image_To_PSD",
		description: "imageToPSDDescription",
		link: LinkConstants.BRIA_API_IMAGE_TO_PSD,
		image: imageToPsd,
	},
	{
		type: APICardType.EcommProdAPICards,
		title: "productCutouts",
		description: "productCutoutsDescription",
		link: LinkConstants.BRIA_API_PRODUCT_CUTOUTS,
		image: productCutoutsPng,
	},
	{
		type: APICardType.EcommProdAPICards,
		title: "productPackShots",
		description: "productPackShotsDescription",
		link: LinkConstants.BRIA_API_PRODUCT_PACK_SHOTS,
		image: productPackShotsPng,
	},
	{
		type: APICardType.EcommProdAPICards,
		title: "lifestyleProductShotByText",
		description: "lifestyleProductShotByTextDescription",
		link: LinkConstants.BRIA_API_TEXT_PROMPTS_LIFESTYLE,
		image: lifestyleProductTextPng,
	},
	{
		type: APICardType.EcommProdAPICards,
		title: "lifestyleProductShotByImage",
		description: "lifestyleProductShotByImageDescription",
		link: LinkConstants.BRIA_API_Lifestyle_Product_Shot_By_Image,
		image: lifestyleProductShotByImagePng,
		badges: ["comingSoon"]
	},
	{
		type: APICardType.EcommProdAPICards,
		title: "consistentStudioShots",
		description: "consistentStudioShotsDescription",
		link: LinkConstants.BRIA_API_CONSISTENT_STUDIO_SHOTS,
		image: consistentStudioShotsPng,
		badges: ["comingSoon"]
	},
	{
		type: APICardType.EcommProdAPICards,
		title: "contextualKeywordExtraction",
		description: "contextualKeywordExtractionDescription",
		link: LinkConstants.BRIA_API_CONTEXTUAL_KEYWORD_EXTRACTION,
		image: contextualKeywordExtractionPng,
		badges: ["comingSoon"]
	},
	{
		type: APICardType.BranedGenAPICards,
		title: "managedTailoredModels",
		description: "managedTailoredModelsDescription",
		link: LinkConstants.BRIA_API_BRANDED_MODELS_MANAGEMENT,
		image: managedTailoredModelsPng,
	},
	{
		type: APICardType.BranedGenAPICards,
		title: "manageDatasets",
		description: "manageDatasetsDescription",
		link: LinkConstants.BRIA_API_DATASET_MANAGMENT,
		image: manageDatasetsPng,
	},
	{
		type: APICardType.BranedGenAPICards,
		title: "trainModels",
		description: "trainModelsDescription",
		link: LinkConstants.BRIA_API_TRAIN_MODELS,
		image: trainModelsPng,
	},
	{
		type: APICardType.BranedGenAPICards,
		title: "generateImages",
		description: "generateImagesDescription",
		link: LinkConstants.BRIA_API_GENERATE_IMAGES,
		image: generateImagesPng,
	}
];