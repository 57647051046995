import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import pageNotFound from "../../assets/images/pageNotFoundLogo.png";
import Header from "../../layout/HeaderLayout/AppHeader/AppHeader";
import styles from "./PageNotFound.module.scss";

const PageNotFound = () => {
	const { t } = useTranslation("translation");

	return (
		<>
			<Header />
			<Box className={styles.notFoundContent}>
				<img src={pageNotFound} alt="" />
				<Typography className={styles.messsageText}>{t("pageNotAvailable")}</Typography>
			</Box>
		</>
	);
};

export default PageNotFound;
