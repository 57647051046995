import * as Sentry from "@sentry/react";
import { makeAutoObservable, runInAction } from "mobx";
import { ORG_SUBSCRIPTION_PLANS } from "../../../constants/billing.ts";
import { AESCipher } from "../../../helpers/encryption.ts";
import { getSelectedOrganization, setSelectedOrganization } from "../../../helpers/localStorage.ts";
import { IRootStore } from "../../../mobx/root-store.tsx";
import { OrgSubscriptionPlan } from "../../../models/billing.ts";
import { UserOrganization } from "../../../models/organization.ts";
import BillingService from "../../../services/BillingService.ts";
import { capitalizeFirstLetter } from "../../../utils";
import { showErrorToast } from "../../../utils/toast.tsx";

export const PRICING_QUERY_PARAMS = [
	"pricing",
	"enc",
	"orgId",
	"selectedPlan",
	"session_id",
	"isSubscriptionCancelled",
	"priceIds",
	"termsAndConditions",
	"paymentMethodConfigurationId",
	"allowPromotionCodes",
	"userEmails",
	"validityDate",
];

export enum PRICING_STEPS {
	NONE = "NONE",
	PRICING_POPUP = "PRICING_POPUP",
	CHECKOUT_POPUP = "CHECKOUT_POPUP",
	AGREE_ON_TERMS_POPUP = "AGREE_ON_TERMS_POPUP",
	SUBSCRIPTION_UPDATED_POPUP = "SUBSCRIPTION_UPDATED_POPUP",
	SUBSCRIPTION_UPDATE_CONFIRMATION_POPUP = "SUBSCRIPTION_UPDATE_CONFIRMATION_POPUP",
	WRONG_ORG_ERROR_POPUP = "WRONG_ORG_ERROR_POPUP",
	LINK_EXPIRED_ERROR_POPUP = "LINK_EXPIRED_ERROR_POPUP",
	SUBSCRIPTION_RENEW_CONFIRMATION_POPUP = "SUBSCRIPTION_RENEW_CONFIRMATION_POPUP",
	SELECT_ORG_POPUP = "SELECT_ORG_POPUP",
	CREATE_ORG_POPUP = "CREATE_ORG_POPUP",
	CHECKOUT_THANK_YOU_POPUP = "CHECKOUT_THANK_YOU_POPUP",
	CLOSE_CONFIRMATION_POPUP = "CLOSE_CONFIRMATION_POPUP",
	SUBSCRIPTION_CANCELLED_POPUP = "SUBSCRIPTION_CANCELLED_POPUP",
}

export interface IPricingStore {
	billingService: BillingService;
	activeStep: PRICING_STEPS;
	isPricingFlowOpen: boolean;
	isTermsPopupOpen: boolean;
	isSubscriptionCancelled: boolean;
	isLoadingUpdateSubscription: boolean;
	selectedPlan: OrgSubscriptionPlan | undefined;
	allowPromotionCodes: boolean | undefined;
	paymentMethodConfigurationId: string | null;
	userEmails: string[] | undefined;
	validityDate: Date | undefined;
	targetOrgId: string | null;
	oldOrgId: string | undefined;
	queryParams: URLSearchParams;
	checkoutSessionId: string | null;
	checkoutSession: any;

	handlePlanButtonClick(plan: OrgSubscriptionPlan): void;

	openPricingFlow(forceOrg?: boolean): void;

	_openPricingFlow(): void;

	getEncryptedQueryParams(): Promise<string>;

	handleCloseSelectOrgPopup(selectedOrg: UserOrganization | null | undefined): void;

	openCheckoutFlow(): void;

	closePricingFlow(): void;

	reset(reloadSubscription?: boolean): void;

	fetchCheckoutSession(): Promise<void>;

	fetchClientSecret(): Promise<string>;

	updateSubscription(): Promise<void>;

	setProperty<K extends keyof IPricingStore>(key: K, value: IPricingStore[K]): void;
}

export default class PricingStore implements IPricingStore {
	rootStore: IRootStore;
	billingService: BillingService;
	aesCipher: AESCipher;
	activeStep = PRICING_STEPS.NONE;
	isPricingFlowOpen: boolean = false;
	isTermsPopupOpen: boolean = false;
	isSubscriptionCancelled: boolean = false;
	isLoadingUpdateSubscription: boolean = false;
	allowPromotionCodes: boolean | undefined = undefined;
	paymentMethodConfigurationId: string | null = null;
	userEmails: string[] | undefined = undefined;
	validityDate: Date | undefined = undefined;
	selectedPlan: OrgSubscriptionPlan | undefined = undefined;
	targetOrgId: string | null = null;
	checkoutSessionId: string | null = null;
	checkoutSession: any = null;
	queryParams: URLSearchParams = new URLSearchParams(location.search);
	oldOrgId: string | undefined = getSelectedOrganization()?.organization.uid;
	today: Date = new Date();

	constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.today.setHours(0, 0, 0, 0);
		this.billingService = new BillingService();
		this.aesCipher = new AESCipher();
		this.rootStore = rootStore;
		this.parsePricingQueryParams();
	}

	parsePricingQueryParams = async () => {
		let priceIdsList = null;
		const enc = this.queryParams.get("enc");
		if (enc) {
			const decryptedParams = await this.aesCipher.decrypt(decodeURIComponent(enc));
			this.queryParams = new URLSearchParams({
				...Object.fromEntries(this.queryParams),
				...Object.fromEntries(new URLSearchParams(decryptedParams)),
			});

			this.setProperty("allowPromotionCodes", this.queryParams.get("allowPromotionCodes") === "true");

			const validityDateString = this.queryParams.get("validityDate");
			if (validityDateString) {
				const validityDate = new Date(validityDateString);
				validityDate.setHours(0, 0, 0, 0);
				this.setProperty("validityDate", validityDate);
			}
			const userEmailsString = this.queryParams.get("userEmails");
			if (userEmailsString) {
				this.setProperty("userEmails", userEmailsString.split(";"));
			}
			this.setProperty("paymentMethodConfigurationId", this.queryParams.get("paymentMethodConfigurationId"));
			this.setProperty("targetOrgId", this.queryParams.get("orgId"));

			const priceIdsStr = this.queryParams.get("priceIds");
			if (priceIdsStr) {
				priceIdsList = priceIdsStr.trim().split(",");
			}
		}

		this.setProperty("checkoutSessionId", this.queryParams.get("session_id"));
		this.setProperty("isSubscriptionCancelled", this.queryParams.get("isSubscriptionCancelled") === "true");
		const selectedPlanName = this.queryParams.get("selectedPlan");
		this.setProperty("selectedPlan", {
			name: selectedPlanName?.toLowerCase() ?? ORG_SUBSCRIPTION_PLANS.custom.name,
			displayName: capitalizeFirstLetter(selectedPlanName ?? ORG_SUBSCRIPTION_PLANS.custom.displayName),
			selfService: false,
			termsAndConditionsLink: this.queryParams.get("termsAndConditions") ?? undefined,
		});

		if (priceIdsList && priceIdsList.length > 0) {
			this.setProperty("selectedPlan", {
				...this.selectedPlan,
				selfService: true,
				priceIds: priceIdsList,
			} as OrgSubscriptionPlan);
		} else {
			if (selectedPlanName && Object.keys(ORG_SUBSCRIPTION_PLANS).includes(selectedPlanName)) {
				this.setProperty("selectedPlan", ORG_SUBSCRIPTION_PLANS[selectedPlanName]);
			}
		}
		if (this.queryParams.get("pricing") === "true") {
			this.openPricingFlow(false);
		}
	};

	getEncryptedQueryParams = async () => {
		const queryParams = new URLSearchParams(this.queryParams);
		queryParams.delete("pricing");
		queryParams.delete("enc");
		const encryptedParams = await this.aesCipher.encrypt(queryParams.toString());
		return new URLSearchParams("pricing=true&enc=" + encodeURIComponent(encryptedParams)).toString();
	};

	openCheckoutFlow = () => {
		if (this.rootStore.authStore.orgSubscription?.plan_name === ORG_SUBSCRIPTION_PLANS.free.name) {
			this.setProperty("activeStep", PRICING_STEPS.CHECKOUT_POPUP);
		} else if (
			this.selectedPlan?.name.toLowerCase() !== ORG_SUBSCRIPTION_PLANS.custom.name &&
			this.rootStore.authStore.orgSubscription?.plan_name.toLowerCase() === this.selectedPlan?.name.toLowerCase()
		) {
			this.setProperty("activeStep", PRICING_STEPS.SUBSCRIPTION_RENEW_CONFIRMATION_POPUP);
		} else {
			this.setProperty("activeStep", PRICING_STEPS.SUBSCRIPTION_UPDATE_CONFIRMATION_POPUP);
		}
	};

	handleCloseSelectOrgPopup = async (selectedOrg: UserOrganization | null | undefined) => {
		if (selectedOrg) {
			if (selectedOrg.organization.uid !== this.oldOrgId) {
				this.queryParams.set("orgId", selectedOrg.organization.uid);
				const encryptedParams = await this.getEncryptedQueryParams();
				window.history.replaceState(null, "", window.location.pathname + "?" + encryptedParams);
				setSelectedOrganization(selectedOrg);
				window.location.reload();
			} else {
				this.setProperty("targetOrgId", selectedOrg.organization.uid);
				this._openPricingFlow();
			}
		} else {
			this.closePricingFlow();
		}
	};

	_openPricingFlow = async () => {
		if (this.isSubscriptionCancelled) {
			if (this.rootStore.authStore.orgSubscription) {
				if (this.rootStore.authStore.orgSubscription?.cancels_on) {
					this.setProperty("activeStep", PRICING_STEPS.SUBSCRIPTION_CANCELLED_POPUP);
				} else {
					this.reset();
				}
			}
		} else if (this.checkoutSessionId) {
			this.setProperty("activeStep", PRICING_STEPS.CHECKOUT_THANK_YOU_POPUP);
			this.fetchCheckoutSession();
		} else {
			if (this.validityDate && this.validityDate < this.today) {
				this.setProperty("activeStep", PRICING_STEPS.LINK_EXPIRED_ERROR_POPUP);
			} else if (
				this.targetOrgId &&
				this.userEmails &&
				this.userEmails.length > 0 &&
				this.rootStore.authStore.user &&
				!this.rootStore.authStore.user.isSuperAdmin() &&
				this.userEmails.includes(this.rootStore.authStore.user.email) &&
				!this.rootStore.authStore.userOrganizations.some((org) => org.organization.uid === this.targetOrgId)
			) {
				await this.rootStore.authStore.grantUserPermission(this.targetOrgId);
				const encryptedParams = await this.getEncryptedQueryParams();
				window.history.replaceState(null, "", window.location.pathname + "?" + encryptedParams);
				window.location.reload();
			} else if (this.rootStore.authStore.userOrganizations.length === 0) {
				this.setProperty("activeStep", PRICING_STEPS.CREATE_ORG_POPUP);
				const encryptedParams = await this.getEncryptedQueryParams();
				localStorage.setItem("destination_route", `${window.location.href}?${encryptedParams}`);
				this.rootStore.uiStore.showDialog("CreateOrganizationDialog");
			} else if (this.rootStore.authStore.userOrganizations.length > 1 && !this.targetOrgId) {
				this.setProperty("activeStep", PRICING_STEPS.SELECT_ORG_POPUP);
			} else if (this.targetOrgId && this.targetOrgId !== getSelectedOrganization()?.organization.uid) {
				let existingOrg: UserOrganization | undefined;
				if (this.rootStore.authStore.user?.isSuperAdmin()) {
					existingOrg = await this.rootStore.authStore.loadOrganization(this.targetOrgId);
				} else {
					existingOrg = this.rootStore.authStore.userOrganizations.find(
						(org) => org.organization.uid === this.targetOrgId,
					);
				}
				if (existingOrg) {
					this.handleCloseSelectOrgPopup(existingOrg);
				} else {
					this.setProperty("activeStep", PRICING_STEPS.WRONG_ORG_ERROR_POPUP);
				}
			} else if (
				this.selectedPlan &&
				this.selectedPlan.selfService &&
				(this.selectedPlan.name.toLowerCase() === ORG_SUBSCRIPTION_PLANS.custom.name ||
					this.selectedPlan.name.toLowerCase() !==
						this.rootStore.authStore.orgSubscription?.plan_name.toLowerCase())
			) {
				this.handlePlanButtonClick(this.selectedPlan);
			} else {
				this.setProperty("activeStep", PRICING_STEPS.PRICING_POPUP);
			}
		}
	};

	openPricingFlow = (forceOrg: boolean = true) => {
		if (forceOrg) {
			this.setProperty("targetOrgId", getSelectedOrganization()?.organization.uid ?? null);
		}
		this.setProperty("isPricingFlowOpen", true);
	};

	handlePlanButtonClick = (plan: OrgSubscriptionPlan) => {
		this.setProperty("selectedPlan", plan);
		if (plan.selfService) {
			if (plan.termsAndConditionsLink) {
				this.setProperty("activeStep", PRICING_STEPS.AGREE_ON_TERMS_POPUP);
			} else {
				this.openCheckoutFlow();
			}
		}
	};

	closePricingFlow = (): void => {
		this.reset();
	};

	setProperty = async <K extends keyof IPricingStore>(key: K, value: IPricingStore[K]) => {
		runInAction(() => ((this as IPricingStore)[key] = value));
	};

	fetchCheckoutSession = () => {
		return this.billingService.fetchCheckoutSession(this.checkoutSessionId ?? "").then((checkoutSession: any) => {
			if (checkoutSession?.status === "complete") {
				this.setProperty("checkoutSession", checkoutSession);
			} else {
				showErrorToast();
				Sentry.captureMessage(`Stripe payment failed, checkoutSession status is not "complete"`, {
					user: { email: this.rootStore.authStore.user?.email ?? "" },
				});
				this.closePricingFlow();
			}
		});
	};

	fetchClientSecret = async () => {
		if (this.selectedPlan) {
			return await this.billingService.fetchClientSecret(
				this.selectedPlan.priceIds,
				this.selectedPlan.name,
				this.allowPromotionCodes,
				this.paymentMethodConfigurationId,
			);
		}
		return null;
	};

	updateSubscription = () => {
		this.setProperty("isLoadingUpdateSubscription", true);
		return this.billingService
			.updateOrgSubscription(this.selectedPlan?.priceIds)
			.then((_res: any) => {
				this.setProperty("activeStep", PRICING_STEPS.SUBSCRIPTION_UPDATED_POPUP);
			})
			.finally(() => {
				this.setProperty("isLoadingUpdateSubscription", false);
			});
	};

	reset = () => {
		this.isPricingFlowOpen = false;
		this.isTermsPopupOpen = false;
		this.isSubscriptionCancelled = false;
		this.isLoadingUpdateSubscription = false;
		this.selectedPlan = undefined;
		this.targetOrgId = null;
		this.checkoutSessionId = null;
		this.checkoutSession = null;
		this.activeStep = PRICING_STEPS.NONE;
	};
}
