import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { startDownload } from "../helpers/images.ts";

export const useImageActions = ({ setDownloadSaveLoading, setIsImageLoading, imageUrl, useAlerts = true }: any) => {
	const { t } = useTranslation("translation");

	const doDownloadImage = async () => {
		try {
			if (useAlerts) {
				setDownloadSaveLoading && setDownloadSaveLoading(true);
				setIsImageLoading && setIsImageLoading(true);

				toast.info(<Typography>{t("preparingImageForDownload")}</Typography>, {
					toastId: "my_id",
					autoClose: false,
					style: { backgroundColor: "#8800FF", color: "#FFF" },
				});
			}

			await startDownload(imageUrl ?? "", "download-img", "", false, undefined);

			if (useAlerts) {
				toast.update("my_id", {
					render: (
						<Typography>
							{t("imageWas")}
							<strong>{t("downloaded")}</strong>
						</Typography>
					),
					type: toast.TYPE.SUCCESS,
					autoClose: 3000,
				});
			}
		} catch (e) {
			setDownloadSaveLoading && setDownloadSaveLoading(false);
			setIsImageLoading && setIsImageLoading(false);
		}
	};

	return {
		doDownloadImage,
	};
};
