import { addDays, addMonths, endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";

export type IUsageData = {
	name: string;
	[key: string]: any;
};

export type IUsageSummary = {
	monthlyUsage: string | number;
	totalUsage: string | number;
};

export type ITableUsageRecord = {
	[key: string]: any;
};

export enum UsagePeriod {
	Daily = "day",
	Weekly = "week",
	Monthly = "month",
}

export enum UsageType {
	Total = "Total calls",
	API = "API calls",
	Iframe = "Iframe calls",
}

export const LineChartColors = [
	"#2A0067",
	"#5300C9",
	"#A43CFF",
	"#E4D4FF",
	"#FF3CA5",
	"#D80067",
	"#FFC847",
	"#C49991",
	"#FF9061",
	"#FFCAAC",
	"#78E3FD",
	"#109DBF",
	"#1B998B",
	"#2DD881",
	"#FFB6C1",
	"#FFA07A",
	"#FA8072",
	"#FF7F50",
	"#FF6347",
	"#FF4500",
	"#FFD700",
	"#FF8C00",
	"#FFA500",
	"#FFDAB9",
	"#FFDEAD",
	"#F0E68C",
	"#ADFF2F",
	"#7FFF00",
	"#32CD32",
	"#00FF00",
	"#98FB98",
	"#90EE90",
	"#00FA9A",
	"#00FF7F",
];

export const LineChartDefaultValues = [
	{
		name: "Jan",
		"text-to-vector/base/23": 0,
		"text-to-vector/fast/23": 0,
		"text-to-image/base/22": 0,
		"text-to-image/base/23": 0,
		"text-to-image/fast/22": 0,
		"text-to-image/fast/23": 0,
		"text-to-image/hd": 0,
		replace_bg: 0,
		style: 0,
		image_expansion: 0,
		campaign_generator: 0,
		increase_resolution: 0,
		image_to_psd: 0,
		"background/remove": 0,
		"background/blur": 0,
		"objects/remove": 0,
		search_similar_images: 0,
		caption: 0,
		product_placement: 0,
		"product/cutout": 0,
		"product/packshot": 0,
		face: 0,
		prompt_enhancer: 0,
		"product/shadow": 0,
		"product/lifestyle_shot_by_text": 0,
		erase_foreground: 0,
		crop: 0,
	},
	{ name: "Feb" },
	{ name: "Mar" },
	{ name: "Apr" },
	{ name: "May" },
	{ name: "Jun" },
	{ name: "July" },
	{ name: "Aug" },
	{ name: "Sep" },
	{ name: "Oct" },
	{ name: "Nov" },
	{ name: "Dec" },
];

export const PredefinedDateRanges: any = [
	{
		label: "Today",
		value: [new Date(), new Date()],
		placement: "left",
	},
	{
		label: "Yesterday",
		value: [addDays(new Date(), -1), addDays(new Date(), -1)],
		placement: "left",
	},
	{
		label: "This week",
		value: [startOfWeek(new Date()), endOfWeek(new Date())],
		placement: "left",
	},
	{
		label: "This month",
		value: [startOfMonth(new Date()), new Date()],
		placement: "left",
	},
	{
		label: "Last month",
		value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
		placement: "left",
	},
];
