import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function Google(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
			<mask
				id="a"
				width={19}
				height={20}
				x={1}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "luminance",
				}}
			>
				<path
					fill="#fff"
					d="M19.034 8.333h-8.542v3.542h4.917c-.458 2.25-2.375 3.541-4.917 3.541-3 0-5.416-2.416-5.416-5.416 0-3 2.416-5.417 5.416-5.417a5.3 5.3 0 0 1 3.375 1.208l2.667-2.666C14.909 1.708 12.826.833 10.492.833A9.14 9.14 0 0 0 1.326 10a9.14 9.14 0 0 0 9.166 9.166c4.584 0 8.75-3.333 8.75-9.166 0-.542-.083-1.125-.208-1.667Z"
				/>
			</mask>
			<g mask="url(#a)">
				<path fill="#FBBC05" d="M.492 15.416V4.583L7.576 10 .492 15.416Z" />
			</g>
			<mask
				id="b"
				width={19}
				height={20}
				x={1}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "luminance",
				}}
			>
				<path
					fill="#fff"
					d="M19.034 8.333h-8.542v3.542h4.917c-.458 2.25-2.375 3.541-4.917 3.541-3 0-5.416-2.416-5.416-5.416 0-3 2.416-5.417 5.416-5.417a5.3 5.3 0 0 1 3.375 1.208l2.667-2.666C14.909 1.708 12.826.833 10.492.833A9.14 9.14 0 0 0 1.326 10a9.14 9.14 0 0 0 9.166 9.166c4.584 0 8.75-3.333 8.75-9.166 0-.542-.083-1.125-.208-1.667Z"
				/>
			</mask>
			<g mask="url(#b)">
				<path fill="#EA4335" d="M.492 4.583 7.576 10l2.916-2.542 10-1.625V0h-20v4.583Z" />
			</g>
			<mask
				id="c"
				width={19}
				height={20}
				x={1}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "luminance",
				}}
			>
				<path
					fill="#fff"
					d="M19.034 8.333h-8.542v3.542h4.917c-.458 2.25-2.375 3.541-4.917 3.541-3 0-5.416-2.416-5.416-5.416 0-3 2.416-5.417 5.416-5.417a5.3 5.3 0 0 1 3.375 1.208l2.667-2.666C14.909 1.708 12.826.833 10.492.833A9.14 9.14 0 0 0 1.326 10a9.14 9.14 0 0 0 9.166 9.166c4.584 0 8.75-3.333 8.75-9.166 0-.542-.083-1.125-.208-1.667Z"
				/>
			</mask>
			<g mask="url(#c)">
				<path fill="#34A853" d="m.492 15.417 12.5-9.584 3.292.417L20.492 0v20h-20v-4.583Z" />
			</g>
			<mask
				id="d"
				width={19}
				height={20}
				x={1}
				y={0}
				maskUnits="userSpaceOnUse"
				style={{
					maskType: "luminance",
				}}
			>
				<path
					fill="#fff"
					d="M19.034 8.333h-8.542v3.542h4.917c-.458 2.25-2.375 3.541-4.917 3.541-3 0-5.416-2.416-5.416-5.416 0-3 2.416-5.417 5.416-5.417a5.3 5.3 0 0 1 3.375 1.208l2.667-2.666C14.909 1.708 12.826.833 10.492.833A9.14 9.14 0 0 0 1.326 10a9.14 9.14 0 0 0 9.166 9.166c4.584 0 8.75-3.333 8.75-9.166 0-.542-.083-1.125-.208-1.667Z"
				/>
			</mask>
			<g mask="url(#d)">
				<path fill="#4285F4" d="M20.492 20 7.576 10 5.909 8.75l14.584-4.167V20Z" />
			</g>
		</SvgIcon>
	);
}

export default Google;
