// @ts-ignore
function Resize({ size }: { size: number }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
			<path
				d="M3.45068 9.16668V15.8333C3.45068 16.0544 3.53848 16.2663 3.69476 16.4226C3.85104 16.5789 4.063 16.6667 4.28402 16.6667H10.9507M3.45068 5.00001V4.16668C3.45068 3.94566 3.53848 3.7337 3.69476 3.57742C3.85104 3.42114 4.063 3.33334 4.28402 3.33334H5.11735M9.28402 3.33334H10.9507M15.1173 3.33334H15.9507C16.1717 3.33334 16.3837 3.42114 16.5399 3.57742C16.6962 3.7337 16.784 3.94566 16.784 4.16668V5.00001M16.784 9.16668V10.8333M16.784 15V15.8333C16.784 16.0544 16.6962 16.2663 16.5399 16.4226C16.3837 16.5789 16.1717 16.6667 15.9507 16.6667H15.1173M3.45068 10H9.28402C9.50503 10 9.71699 10.0878 9.87327 10.2441C10.0296 10.4004 10.1174 10.6123 10.1174 10.8333V16.6667"
				stroke="#5B5B5B"
				stroke-width="1.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}

export default Resize;
