import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../../../components/common/BriaTable/BriaTable";
import { BriaAxios } from "../../../config/axios";
import useErrorToast from "../../../hooks/useErrorToast";
import { Brand } from "../../../models/brand";
import { PaginatedItems } from "../../../models/common";
import QueryService from "../../../utils/QueryService";

export interface IBrandStore {
	paginatedBrands: PaginatedItems<Brand>;
	rowsPerPage: number;
	isLoading: boolean;
	isLoadingEditor: boolean;
	isError: boolean;
	formErrors: { save?: boolean; invalidName?: boolean };
	loadBrands(pageNumber: number, filterBy?: FilterByOptions): Promise<void>;
	updateBrand(updatedBrand: Brand): Promise<void>;
	getBrand(templateId: number): Promise<Brand>;
	createBrand(newBrand: Brand): Promise<number>;
	createLogo(file: File): Promise<string>;
	createColorPallete(colors: any): Promise<string>;
}

export default class BrandsStore implements IBrandStore {
	private queryService: QueryService = new QueryService("/brands");
	errorToast = useErrorToast();
	paginatedBrands: PaginatedItems<Brand> = { total: 0, items: [] };
	rowsPerPage: number = 20;
	isLoading: boolean = false;
	isLoadingEditor: boolean = false;
	isError: boolean = false;
	formErrors: { save?: boolean; invalidName?: boolean } = {};

	constructor() {
		makeAutoObservable(this);
	}

	loadBrands = async (pageNumber: number, filterBy?: FilterByOptions): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			const brands: PaginatedItems<Brand> = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					page: pageNumber,
					per_page: this.rowsPerPage,
				},
			});

			runInAction(() => {
				this.isLoading = false;
				this.paginatedBrands = brands;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getBrand = async (brandId: number): Promise<Brand> => {
		try {
			this.isLoadingEditor = true;

			const brand: Brand = await this.queryService.get(`/${brandId}`);
			runInAction(() => {
				this.isLoadingEditor = false;
			});
			return brand;
		} catch (e) {
			runInAction(() => {
				this.isLoadingEditor = false;
				this.isError = true;
			});

			return Promise.reject(e);
		}
	};

	createBrand = async (newBrand: Brand): Promise<number> => {
		this.isError = false;
		try {
			const brandId = await this.queryService.post(`/`, newBrand);
			runInAction(() => {
				this.isError = false;
			});
			return brandId;
		} catch (e: any) {
			runInAction(() => {
				if (e.response?.status === 409) {
					this.formErrors.invalidName = true;
				}
				this.isError = true;
			});
			return Promise.reject(`Error creating new brand: ${e.message || e.toString()}`);
		}
	};

	updateBrand = async (updatedBrand: Brand): Promise<void> => {
		this.isError = false;
		try {
			await this.queryService.put(`/`, updatedBrand);
			runInAction(() => {
				this.paginatedBrands.items = this.paginatedBrands?.items.map((brand) =>
					brand.id === updatedBrand.id ? updatedBrand : brand,
				);
				this.isError = false;
			});
		} catch (e: any) {
			runInAction(() => {
				if (e.response?.status === 409) {
					this.formErrors.invalidName = true;
				}
				this.isError = true;
			});
		}
	};

	createLogo = async (file: File): Promise<string> => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const res = await (
				await BriaAxios()
			).post(`/logo`, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			runInAction(() => {
				this.isError = false;
			});
			return res.data;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(`Error uploading PSD file: ${e.message || e.toString()}`);
		}
	};

	createColorPallete = async (colors: any): Promise<string> => {
		try {
			const res = await (await BriaAxios()).post(`/color_pallete`, colors);
			return res.data;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(`Error uploading PSD file: ${e.message || e.toString()}`);
		}
	};
}
