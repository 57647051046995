import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import foxLogo from "../../../assets/images/pngs/FS HIGH RES.png";
import Logo from "../../../assets/images/svgs/Bria-logo.svg";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import OrganizationsDropdown from "../../../components/common/OrganizationsDropdown/OrganizationsDropdown.tsx";
import LinkConstants from "../../../constants/LinkConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { hasOrganization, isFoxOrg } from "../../../utils";
import HeaderLayout from "../HeaderLayout.tsx";
import styles from "./HubHeader.module.scss";

const HubHeader = () => {
	const navigate = useNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "headers.hub" });

	const handleBriaLogoClick = () => {
		location.replace(LinkConstants.BRIA_WEBSITE);
	};
	const handleFoxLogoClick = () => {
		// navigate(RouterConstants.APPS.path);
	};

	const productPlacementAppMatch = window.location.pathname.match(/\/(product-placement)\/?([^/]*)/);
	const designEditorAppMatch = window.location.pathname.match(/\/(campaign-editor)\/?([^/]*)/);

	if (designEditorAppMatch) {
		return null;
	}

	return (
		<HeaderLayout
			className={styles.header}
			logo={isFoxOrg() ? foxLogo : Logo}
			onLogoClick={!isFoxOrg() ? handleBriaLogoClick : handleFoxLogoClick}
		>
			{!isFoxOrg() && !productPlacementAppMatch && (
				<>
					<BriaButton
						buttonType="textMedium"
						onClick={() => window.open(LinkConstants.CONTACT_US_FORM_LINK, "_blank")}
					>
						{t("contactSales")}
					</BriaButton>
					<BriaButton buttonType="primaryMedium" onClick={() => navigate(RouterConstants.CONSOLE.path)}>
						{hasOrganization() ? t("console") : t("getApi")}
					</BriaButton>
				</>
			)}
			<OrganizationsDropdown />
		</HeaderLayout>
	);
};

export default observer(HubHeader);
