import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function FullscreenIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 14 14" {...props}>
			<path
				d="M9.33372 1.40039H12.6004M12.6004 1.40039V4.66706M12.6004 1.40039L8.86706 5.13372M1.40039 9.33372V12.6004M1.40039 12.6004H4.66706M1.40039 12.6004L5.13372 8.86706"
				stroke="#5B5B5B"
				strokeWidth="1.25"
			/>
		</SvgIcon>
	);
}

export default FullscreenIcon;
