function BringToFront({ size }: { size: number }) {
	return (
		<svg height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.7888 16.0075H7.21863C4.89248 16.0075 3 14.115 3 11.7888V7.21863C3 4.89248 4.89248 3 7.21863 3H11.7888C14.115 3 16.0075 4.89248 16.0075 7.21863V11.7888C16.0075 14.115 14.115 16.0075 11.7888 16.0075ZM7.21863 4.40621C5.66786 4.40621 4.40621 5.66786 4.40621 7.21863V11.7888C4.40621 13.3396 5.66786 14.6012 7.21863 14.6012H11.7888C13.3396 14.6012 14.6012 13.3396 14.6012 11.7888V7.21863C14.6012 5.66786 13.3396 4.40621 11.7888 4.40621H7.21863ZM20.9995 17.8327V15.0611C21.0297 13.444 19.6751 11.9887 18.0615 11.9025C17.6749 11.8746 17.3378 12.1662 17.31 12.5536C17.2823 12.9409 17.5738 13.2774 17.9611 13.3051C18.8763 13.3706 19.5933 14.142 19.5933 15.0611V17.8327C19.5933 18.8035 18.8035 19.5933 17.8327 19.5933H15.0611C14.1156 19.5933 13.343 18.8542 13.3022 17.9106C13.2854 17.5226 12.9573 17.2215 12.5693 17.2386C12.1814 17.2554 11.8805 17.5835 11.8973 17.9714C11.9707 19.6694 13.3604 20.9995 15.0611 20.9995H17.8327C19.5789 20.9995 20.9995 19.5789 20.9995 17.8327Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default BringToFront;
