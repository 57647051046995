import { ThemeOptions } from "@mui/material";

export const lightTheme: ThemeOptions = {
	palette: {
		mode: "light",
		common: {
			white: "#FFFFFF",
			black: "#495057",
		},
		primary: {
			light: "#D80067",
			main: "#8800FF",
			dark: "#5300C9",
		},
		secondary: {
			main: "#D80067",
		},
		text: {
			primary: "#495057",
			secondary: "#ABB5BE",
		},
		error: {
			main: "#DC3545",
		},
		warning: {
			main: "#FFC107",
		},
		success: {
			main: "#28A745",
		},
		background: {
			default: "radial-gradient(60.83% 60.83% at 50% 39.17%, #FFFFFF 21.87%, #EDEDED 100%)",
			paper: "#FFFFFF",
		},
	},
	typography: {
		fontFamily: "Montserrat",
	},
	shape: {
		borderRadius: 10,
	},
	components: {
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontSize: "14px",
					color: "#5B5B5B",
				},
			},
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					padding: 0,
					"&:before": {
						display: "none",
					},
				},
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				root: {
					minHeight: 0,
					padding: 0,
					marginBottom: 24,
				},
				content: {
					margin: 0,
					fontSize: 14,
					fontWeight: 600,
					color: "#1a0638",
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				root: {
					height: 2,
				},
				thumb: {
					width: 12,
					height: 12,
					backgroundColor: "white",
				},
				markLabel: {
					fontSize: 12,
					fontWeight: 700,
				},
				mark: {
					display: "none",
				},
				valueLabel: {
					borderRadius: 14,
					backgroundColor: "#8800FF",
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				root: {
					padding: 0,
					height: "fit-content",
					width: "fit-content",
				},
				switchBase: {
					padding: 2,
					"&.Mui-checked": {
						transform: "translateX(17px)",
						color: "#fff",
					},
					"&.Mui-checked+.MuiSwitch-track": {
						opacity: 1,
						backgroundColor: "#5300C9",
					},
				},
				track: {
					height: 14,
					width: 32,
				},
				thumb: {
					height: 10.5,
					width: 10.87,
				},
			},
		},
	},
};
