import { getSelectedOrganization } from "../helpers/localStorage";

export interface ColorPallet {
	id?: number;
	colors: string[];
	org_id?: string;
	created_at?: Date;
	updated_at?: Date;
}

export const defaultColorPallet: ColorPallet = {
	colors: Array(6).fill(""),
	org_id: getSelectedOrganization()?.organization?.uid || "",
};
