function AlignLeft({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.33301 3.3335V16.6668M8.33301 7.50016H14.9997C15.9201 7.50016 16.6663 8.24635 16.6663 9.16683V10.8335C16.6663 11.754 15.9201 12.5002 14.9997 12.5002H8.33301C7.41253 12.5002 6.66634 11.754 6.66634 10.8335V9.16683C6.66634 8.24635 7.41253 7.50016 8.33301 7.50016Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AlignLeft;
