import { FontItem } from "../interfaces/common";

export const graphics = [
	{
		left: 0,
		top: 0,
		width: 60,
		height: 60,
		originX: "left",
		originY: "top",
		scaleX: 4,
		scaleY: 4,
		type: "StaticPath",
		path: [["M", 60, 0], ["L", 0, 0], ["L", 0, 60], ["L", 60, 60], ["L", 60, 0], ["Z"]],
		fill: "#CBCBCB",
		metadata: {},
		preview: "https://ik.imagekit.io/scenify/1635011325399_603749.png",
		id: "E2mcHFkwGA-MTJcfl3Abs",
	},
	{
		left: 0,
		top: 0,
		width: 90,
		height: 77.14,
		originX: "left",
		originY: "top",
		scaleX: 2.67,
		scaleY: 2.67,
		type: "StaticPath",
		path: [["M", 50, 11.429], ["L", 5, 88.571], ["L", 95, 88.571], ["z"]],
		fill: "#CBCBCB",
		metadata: {},
		id: "SY15tkntkk8S1PudoMfRI",
		preview: "https://ik.imagekit.io/scenify/1635011631415_820612.png",
	},
	{
		left: 0,
		top: 0,
		width: 498.84,
		height: 436.27,
		originX: "left",
		originY: "top",
		scaleX: 0.48,
		scaleY: 0.48,
		type: "StaticPath",
		path: [
			["M", 497.434, 243.764],
			["L", 377.754, 36.403999999999996],
			["C", 376.05600000000004, 33.37, 372.90700000000004, 31.432999999999996, 369.434, 31.283999999999995],
			["L", 130.71400000000003, 31.283999999999995],
			[
				"C",
				127.02300000000002,
				31.250999999999994,
				123.59900000000003,
				33.206999999999994,
				121.75400000000002,
				36.403999999999996,
			],
			["L", 1.434000000000026, 244.404],
			["C", -0.477999999999974, 247.626, -0.477999999999974, 251.635, 1.434000000000026, 254.857],
			["L", 121.75400000000002, 463.07],
			["C", 123.65600000000002, 465.803, 126.74500000000002, 467.467, 130.074, 467.55],
			["L", 369.434, 467.55],
			["C", 372.77200000000005, 467.502, 375.877, 465.83, 377.754, 463.07],
			["L", 497.434, 254.21699999999998],
			["C", 499.302, 250.983, 499.302, 246.999, 497.434, 243.764],
			["z"],
		],
		fill: "#CBCBCB",
		metadata: {},
		preview: "https://ik.imagekit.io/scenify/1635014101144_519480.png",
		id: "9VWsxoB_O8LSAkCIRjNFY",
	},
	{
		left: 0,
		top: 0,
		width: 433.46,
		height: 495.17,
		originX: "left",
		originY: "top",
		scaleX: 0.55,
		scaleY: 0.55,
		type: "StaticPath",
		path: [
			["M", 462.595, 123.753],
			["C", 460.63500000000005, 120.115, 456.68100000000004, 118.011, 452.56800000000004, 118.42],
			["L", 314.115, 133.567],
			["L", 257.795, 5.567000000000007],
			[
				"C",
				256.814,
				3.8150000000000075,
				255.36800000000002,
				2.3690000000000073,
				253.61700000000002,
				1.3890000000000073,
			],
			[
				"C",
				248.37400000000002,
				-1.5459999999999927,
				241.74400000000003,
				0.3240000000000074,
				238.80900000000003,
				5.567000000000007,
			],
			["L", 182.70200000000003, 133.567],
			["L", 44.035, 118.42],
			["C", 39.86, 117.98100000000001, 35.827999999999996, 120.081, 33.794999999999995, 123.753],
			["C", 31.562999999999995, 127.114, 31.562999999999995, 131.485, 33.794999999999995, 134.846],
			["L", 115.502, 247.7],
			["L", 32.515, 359.06],
			["C", 30.301000000000002, 362.504, 30.301000000000002, 366.923, 32.515, 370.367],
			["C", 34.588, 373.998, 38.591, 376.083, 42.755, 375.70000000000005],
			["L", 181.422, 360.98],
			["L", 237.529, 488.98],
			["C", 239.22, 492.76300000000003, 242.986, 495.189, 247.129, 495.16700000000003],
			[
				"C",
				250.78699999999998,
				494.87800000000004,
				254.02599999999998,
				492.692,
				255.66199999999998,
				489.40700000000004,
			],
			["L", 311.98199999999997, 361.40700000000004],
			["L", 450.43499999999995, 376.55400000000003],
			["C", 454.53599999999994, 376.90500000000003, 458.46099999999996, 374.817, 460.46199999999993, 371.221],
			["C", 462.7899999999999, 367.812, 462.7899999999999, 363.324, 460.46199999999993, 359.914],
			["L", 378.541, 247.7],
			["L", 462.594, 134.84699999999998],
			["C", 464.885, 131.504, 464.885, 127.097, 462.595, 123.753],
			["z"],
		],
		fill: "#CBCBCB",
		metadata: {},
		preview: "https://ik.imagekit.io/scenify/1635014130664_615881.png",
		id: "fRmRlanl-hydswssHKhn6",
	},
	{
		left: 0,
		top: 0,
		width: 456.18,
		height: 507.74,
		originX: "left",
		originY: "top",
		scaleX: 0.53,
		scaleY: 0.53,
		type: "StaticPath",
		path: [
			["M", 470.1, 314.036],
			["L", 425.94000000000005, 253.876],
			["L", 469.24700000000007, 193.289],
			["C", 478.1700000000001, 181.003, 482.2100000000001, 165.839, 480.5810000000001, 150.742],
			[
				"C",
				476.95400000000006,
				117.12199999999999,
				446.7600000000001,
				92.809,
				413.1400000000001,
				96.43599999999999,
			],
			["L", 339.1130000000001, 104.54299999999999],
			["L", 309.88600000000014, 36.489999999999995],
			[
				"C",
				303.7230000000001,
				22.547999999999995,
				292.58100000000013,
				11.405999999999995,
				278.6390000000001,
				5.242999999999995,
			],
			[
				"C",
				247.71100000000013,
				-8.428000000000004,
				211.55700000000013,
				5.561999999999995,
				197.88600000000014,
				36.489999999999995,
			],
			["L", 168.44600000000014, 104.54299999999999],
			["L", 93.56600000000014, 96.863],
			["C", 69.50100000000015, 94.41, 46.22500000000014, 106.257, 34.04600000000014, 127.156],
			["C", 21.89500000000014, 148.059, 23.234000000000144, 174.16500000000002, 37.45900000000014, 193.716],
			["L", 81.61900000000014, 253.876],
			["L", 37.459000000000145, 315.103],
			["C", 23.250000000000146, 334.73900000000003, 21.913000000000146, 360.894, 34.04600000000015, 381.876],
			["C", 46.63200000000015, 402.59299999999996, 70.12500000000014, 414.09, 94.20600000000015, 411.316],
			["L", 168.44600000000014, 403.20899999999995],
			["L", 197.88600000000014, 471.26199999999994],
			[
				"C",
				207.66900000000012,
				493.40099999999995,
				229.57500000000013,
				507.69899999999996,
				253.77900000000014,
				507.74199999999996,
			],
			[
				"C",
				277.6340000000001,
				507.47099999999995,
				299.17600000000016,
				493.41499999999996,
				309.03200000000015,
				471.68899999999996,
			],
			["L", 339.11200000000014, 403.20899999999995],
			["L", 413.99200000000013, 411.1019999999999],
			[
				"C",
				429.24100000000016,
				412.7899999999999,
				444.56900000000013,
				408.6909999999999,
				456.9400000000001,
				399.6169999999999,
			],
			["C", 484.208, 379.619, 490.099, 341.302, 470.1, 314.036],
			["z"],
		],
		fill: "#CBCBCB",
		metadata: {},
		preview: "https://ik.imagekit.io/scenify/1635014150489_628269.png",
		id: "A8WLbfJFV1D58X1RUZADd",
	},
	{
		left: 0,
		top: 0,
		width: 512,
		height: 422.71,
		originX: "left",
		originY: "top",
		scaleX: 0.47,
		scaleY: 0.47,
		type: "StaticPath",
		path: [
			["M", 504.839844, 48.585938],
			["C", 502.84765600000003, 46.59375, 500.480469, 44.984375, 497.89843800000006, 43.800781],
			["C", 499.62890600000003, 40.132812, 501.183594, 36.390625, 502.55468800000006, 32.574219],
			["C", 506.16015600000003, 23.253906, 504.277344, 13.664061999999998, 497.47656200000006, 6.863281000000001],
			[
				"C",
				490.5351560000001,
				-0.0742187999999997,
				479.66015600000003,
				-1.96875,
				470.83593800000006,
				2.171875000000001,
			],
			["C", 451.97656200000006, 10.253906, 433.80078100000003, 17.898438000000002, 418.7148440000001, 22.835938],
			["C", 401.1289060000001, 13.398437999999999, 372.0507810000001, 0.015625, 348.6093750000001, 0.015625],
			["L", 348.3984380000001, 0.015625],
			["C", 316.26953100000014, 0.46875, 286.1562500000001, 13.742188, 263.6054690000001, 37.390625],
			["C", 244.65234400000008, 57.261719, 232.9101560000001, 82.515625, 229.75000000000009, 109.683594],
			["C", 158.9726560000001, 94.128906, 118.03515600000009, 71.394531, 72.33203100000009, 23.480469],
			["L", 66.55078100000009, 17.691406],
			["C", 62.53906200000009, 13.675781, 56.804688000000084, 11.824219, 51.19531200000009, 12.730469],
			["C", 45.593750000000085, 13.636719, 40.734375000000085, 17.207031, 38.187500000000085, 22.289062],
			["L", 35.011719000000085, 28.644531],
			["C", 21.285156000000086, 54.949219, 16.972656000000086, 81.933594, 22.199219000000085, 108.855469],
			["C", 24.082031000000086, 118.570312, 27.257812000000087, 128.230469, 31.625000000000085, 137.636719],
			["C", 27.574219000000085, 137.730469, 23.539062000000087, 138.832031, 19.824219000000085, 140.964844],
			["C", 10.968750000000085, 145.753906, 6.136719000000085, 156.867188, 8.296875000000085, 167.675781],
			["L", 8.433594000000085, 168.351562],
			["L", 8.632812000000085, 169.015625],
			["C", 15.000000000000085, 190.386719, 26.359375000000085, 210.59375, 41.742188000000084, 228.355469],
			["C", 39.125000000000085, 229.1875, 36.652344000000085, 230.429688, 34.464844000000085, 232.074219],
			["C", 25.597656000000086, 238.722656, 22.277344000000085, 249.882812, 26.125000000000085, 259.945312],
			["C", 37.566406000000086, 292.210938, 56.796875000000085, 314.5, 84.48046900000008, 327.37890600000003],
			["C", 67.12500000000009, 332.433594, 48.000000000000085, 335.039062, 26.140625000000085, 335.417969],
			["C", 15.519531000000086, 335.480469, 5.718750000000085, 342.214844, 1.7187500000000853, 352.21875],
			["L", 1.4179690000000853, 353.042969],
			[
				"C",
				-1.8789059999999147,
				362.92578100000003,
				0.6367190000000853,
				372.996094,
				8.312500000000085,
				380.67968800000006,
			],
			["L", 8.882812000000085, 381.25000000000006],
			["L", 9.511719000000085, 381.75390600000003],
			[
				"C",
				41.82031200000009,
				407.78125,
				109.36328100000009,
				422.70703100000003,
				194.8164060000001,
				422.70703100000003,
			],
			[
				"C",
				345.5195310000001,
				422.70703100000003,
				468.4804690000001,
				290.91406200000006,
				471.5468750000001,
				127.632812,
			],
			["C", 489.91015600000014, 115.726562, 503.7890620000001, 96.757812, 511.01953100000014, 73.46875],
			["L", 511.24609400000014, 72.652344],
			["C", 513.3906250000001, 64.066406, 510.8750000000001, 54.621094, 504.83984400000014, 48.585938],
			["z"],
			["M", 504.839844, 48.585938],
		],
		fill: "#CBCBCB",
		metadata: {},
		preview: "https://ik.imagekit.io/scenify/1635014314495_390592.png",
		id: "0dwJMLuw8DK9jddPSapOV",
	},
	{
		left: 0,
		top: 0,
		width: 512,
		height: 455.52,
		originX: "left",
		originY: "top",
		scaleX: 0.47,
		scaleY: 0.47,
		type: "StaticPath",
		path: [
			["M", 471.382812, 44.578125],
			["C", 444.87890600000003, 15.832031, 408.51171899999997, 0, 368.97265600000003, 0],
			["C", 339.417969, 0, 312.35156200000006, 9.34375, 288.52343800000006, 27.769531],
			["C", 276.50000000000006, 37.070312, 265.6054690000001, 48.449219, 256.00000000000006, 61.730469],
			["C", 246.39843800000006, 48.453125, 235.50000000000006, 37.070312, 223.47265600000006, 27.769531],
			["C", 199.64843800000006, 9.34375, 172.58203100000006, 0, 143.02734400000006, 0],
			["C", 103.48828100000006, 0, 67.11718800000006, 15.832031, 40.61328100000006, 44.578125],
			["C", 14.425781000000057, 72.988281, 5.684341886080802e-14, 111.800781, 5.684341886080802e-14, 153.871094],
			[
				"C",
				5.684341886080802e-14,
				197.171875,
				16.136719000000056,
				236.808594,
				50.78125000000006,
				278.61328100000003,
			],
			[
				"C",
				81.77343800000006,
				316.00781200000006,
				126.31640600000006,
				353.96875,
				177.89843800000006,
				397.92578100000003,
			],
			["C", 195.51171900000006, 412.9375, 215.47656200000006, 429.953125, 236.20703100000006, 448.078125],
			["C", 241.68359400000006, 452.875, 248.71093800000006, 455.515625, 256.00000000000006, 455.515625],
			["C", 263.28515600000003, 455.515625, 270.31640600000003, 452.875, 275.78515600000003, 448.085938],
			["C", 296.515625, 429.957031, 316.49218800000006, 412.93359399999997, 334.11328100000003, 397.914062],
			["C", 385.6875, 353.96484399999997, 430.230469, 316.007812, 461.22265600000003, 278.609375],
			["C", 495.86718800000006, 236.808594, 512, 197.171875, 512, 153.867188],
			["C", 512, 111.800781, 497.57421899999997, 72.988281, 471.382812, 44.578125],
			["z"],
			["M", 471.382812, 44.578125],
		],
		fill: "#CBCBCB",
		metadata: {},
		preview: "https://ik.imagekit.io/scenify/1635014323800_275038.png",
		id: "3w9KPQbyeUe3kgtwpMZx4",
	},
	{
		left: 0,
		top: 0,
		width: 458.41,
		height: 512,
		originX: "left",
		originY: "top",
		scaleX: 0.52,
		scaleY: 0.52,
		type: "StaticPath",
		path: [
			["M", 410.941406, 189.394531],
			["L", 295.08593799999994, 174.847656],
			["C", 304.82031199999994, 151.589844, 316.10156199999994, 114.679688, 316.10156199999994, 68.546875],
			["C", 316.10156199999994, 42.683594, 307.8007809999999, 23.617188, 291.42968799999994, 11.882812000000001],
			[
				"C",
				280.42968799999994,
				4.000000000000001,
				266.28906199999994,
				1.7763568394002505e-15,
				249.40624999999994,
				1.7763568394002505e-15,
			],
			["L", 248.57031199999994, 1.7763568394002505e-15],
			["L", 247.74218799999994, 0.09375000000000178],
			["C", 220.00390599999994, 3.1875000000000018, 211.65234399999994, 29.113281, 212.77343799999994, 46.003906],
			["C", 212.91406199999994, 50.355469, 212.45312499999994, 54.621094, 211.40624999999994, 58.679688],
			["C", 208.31249999999994, 70.648438, 203.19531199999994, 80.625, 190.83203099999994, 104.730469],
			["L", 190.25390599999994, 105.851562],
			["C", 181.99609399999994, 121.976562, 172.50781199999994, 127.769531, 159.37890599999994, 135.789062],
			["C", 148.19531199999994, 142.621094, 135.51953099999994, 150.359375, 121.84374999999994, 165.320312],
			["C", 115.65624999999994, 172.074219, 108.53124999999994, 181.949219, 102.20312499999994, 192.539062],
			["C", 101.05468799999994, 194.070312, 100.08203099999994, 195.800781, 99.26171899999994, 197.746094],
			["C", 99.16015599999994, 197.945312, 99.05078099999994, 198.125, 98.94531199999994, 198.304688],
			["C", 98.64453099999994, 198.832031, 98.24218799999994, 199.53125, 97.81640599999994, 200.375],
			["C", 97.18359399999994, 201.542969, 96.48046899999994, 202.847656, 95.75781199999994, 204.230469],
			["C", 83.88281199999994, 214.539062, 65.52734399999994, 214.34375, 54.37109399999994, 214.21875],
			["C", 53.222655999999944, 214.207031, 52.164061999999944, 214.195312, 51.207030999999944, 214.195312],
			["C", 31.648437999999945, 214.195312, 20.078124999999943, 221.867188, 13.820311999999944, 228.304688],
			["C", 5.109374999999945, 237.253906, 0.5078119999999444, 249.90625, 0.5078119999999444, 264.890625],
			["L", 0.49999999999994443, 428.101562],
			["C", 0.49999999999994443, 438.851562, 1.8671879999999446, 454.375, 13.699218999999944, 465.08203100000003],
			[
				"C",
				24.746093999999943,
				475.08203100000003,
				40.39843799999994,
				476.23828100000003,
				51.207030999999944,
				476.23828100000003,
			],
			["L", 86.90624999999994, 476.23828100000003],
			[
				"C",
				95.66796899999994,
				476.23828100000003,
				104.11328099999994,
				480.00000000000006,
				114.81249999999994,
				484.765625,
			],
			[
				"C",
				120.85546899999994,
				487.457031,
				127.70703099999994,
				490.507812,
				135.54687499999994,
				493.26171899999997,
			],
			[
				"C",
				172.34765599999994,
				506.01171899999997,
				221.32421899999994,
				511.94921899999997,
				289.67187499999994,
				511.94921899999997,
			],
			[
				"C",
				302.91796899999997,
				511.94921899999997,
				344.0039059999999,
				511.99999999999994,
				344.02343799999994,
				511.99999999999994,
			],
			[
				"C",
				358.83984399999997,
				511.99999999999994,
				372.7851559999999,
				506.18359399999997,
				384.34374999999994,
				495.18359399999997,
			],
			[
				"C",
				390.46874999999994,
				489.363281,
				394.1914059999999,
				482.04296899999997,
				395.71874999999994,
				472.80859399999997,
			],
			[
				"C",
				396.05468799999994,
				470.76562499999994,
				396.27734399999997,
				466.460938,
				396.3007809999999,
				464.128906,
			],
			["C", 396.5351559999999, 458.27734399999997, 395.6757809999999, 452.710938, 393.8320309999999, 447.597656],
			[
				"C",
				405.7031249999999,
				446.25781199999994,
				416.9023439999999,
				441.13281199999994,
				425.4648439999999,
				432.953125,
			],
			["C", 435.9687499999999, 422.914062, 441.7539059999999, 408.96875, 441.7539059999999, 393.679688],
			["C", 441.7539059999999, 382.472656, 438.20312499999994, 371.88671899999997, 431.6289059999999, 363.191406],
			["C", 435.5117189999999, 361.27734399999997, 439.1914059999999, 358.800781, 442.4726559999999, 355.609375],
			["C", 450.9804689999999, 347.335938, 455.3789059999999, 335.867188, 455.5585939999999, 321.515625],
			[
				"C",
				456.1054689999999,
				307.19921899999997,
				451.1210939999999,
				294.75,
				441.7460939999999,
				285.55078100000003,
			],
			["C", 451.6640619999999, 275.65625, 458.17968799999994, 261.484375, 458.8554689999999, 245.76953100000003],
			[
				"C",
				460.1328119999999,
				216.13281200000003,
				438.6953119999999,
				190.99609400000003,
				410.9414059999999,
				189.39453100000003,
			],
			["z"],
			["M", 410.941406, 189.394531],
		],
		fill: "#CBCBCB",
		metadata: {},
		preview: "https://ik.imagekit.io/scenify/1635014340531_452464.png",
		id: "vAE3f8-4M0-2j5PF04cVY",
	},
];

export const defaultFont: FontItem = {
	name: "Lato-Black",
	url: "https://bria-org-assets.s3.amazonaws.com/96c241f8-dd6a-11eb-ba80-0242ac130004/Fonts/Lato-Black.ttf",
};

export const PopUpSteps = ["templates", "images", "brands", "text", "campaigns"];
