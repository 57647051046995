import { Box } from "@mui/material";
import { StatefulPopover } from "baseui/popover";
import { PLACEMENT } from "baseui/tooltip";
import { useEffect, useState } from "react";
import BriaButton from "../../../../../../../components/common/BriaButton/BriaButton";
import BriaTooltip from "../../../../../../../components/common/BriaTooltip/BriaTooltip";
import useDesignEditorUtils, { FeatureType } from "../../../../../../CustomUtils/UseDesignEditor";
import align from "../../../../../../assets/svgs/Icon Popup.svg";
import TextAlignCenter from "../../../../../../components/Icons/TextAlignCenter";
import TextAlignJustify from "../../../../../../components/Icons/TextAlignJustify";
import TextAlignLeft from "../../../../../../components/Icons/TextAlignLeft";
import TextAlignRight from "../../../../../../components/Icons/TextAlignRight";
import { useActiveObject } from "../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../hooks/useEditor";
import { IStaticText, LayerType } from "../../../../../../types";
import styles from "./TextAlign.module.scss";

const TextAlign = () => {
	const TEXT_ALIGNS = ["left", "center", "right", "justify"];

	const editor = useEditor();
	const activeObject = useActiveObject() as Required<IStaticText>;
	const [state, setState] = useState<{ align: string }>({ align: "left" });
	const { EditAllAds } = useDesignEditorUtils();

	useEffect(() => {
		if (activeObject) {
			// @ts-ignore
			setState({ align: activeObject.textAlign });
		}
	}, [activeObject]);
	return (
		<StatefulPopover
			showArrow={true}
			placement={PLACEMENT.bottom}
			overrides={{
				Body: {
					style: {
						zIndex: "1600",
					},
				},
			}}
			content={() => (
				<Box className={styles.alignContainer}>
					<BriaButton
						disableRipple
						className={`${state.align === TEXT_ALIGNS[0] ? styles.selectedButton : ""} ${
							styles.alignButton
						}`}
						onClick={() => {
							editor?.objects.update({ textAlign: TEXT_ALIGNS[0] });
							setState({ align: TEXT_ALIGNS[0] });
							EditAllAds(
								LayerType.STATIC_TEXT,
								activeObject.textType,
								FeatureType.TEXT_ALIGN,
								TEXT_ALIGNS[0],
							);
						}}
					>
						<TextAlignLeft size={20} />
					</BriaButton>
					<BriaButton
						disableRipple
						className={`${state.align === TEXT_ALIGNS[1] ? styles.selectedButton : ""} ${
							styles.alignButton
						}`}
						onClick={(e) => {
							e.stopPropagation();
							editor?.objects.update({ textAlign: TEXT_ALIGNS[1] });
							setState({ align: TEXT_ALIGNS[1] });
							EditAllAds(
								LayerType.STATIC_TEXT,
								activeObject.textType,
								FeatureType.TEXT_ALIGN,
								TEXT_ALIGNS[1],
							);
						}}
					>
						<TextAlignCenter size={20} />
					</BriaButton>
					<BriaButton
						disableRipple
						className={`${state.align === TEXT_ALIGNS[2] ? styles.selectedButton : ""} ${
							styles.alignButton
						}`}
						onClick={() => {
							editor?.objects.update({ textAlign: TEXT_ALIGNS[2] });
							setState({ align: TEXT_ALIGNS[2] });
							EditAllAds(
								LayerType.STATIC_TEXT,
								activeObject.textType,
								FeatureType.TEXT_ALIGN,
								TEXT_ALIGNS[2],
							);
						}}
					>
						<TextAlignRight size={20} />
					</BriaButton>
					<BriaButton
						disableRipple
						className={`${state.align === TEXT_ALIGNS[3] ? styles.selectedButton : ""} ${
							styles.alignButton
						}`}
						onClick={() => {
							editor?.objects.update({ textAlign: TEXT_ALIGNS[3] });
							setState({ align: TEXT_ALIGNS[3] });
							EditAllAds(
								LayerType.STATIC_TEXT,
								activeObject.textType,
								FeatureType.TEXT_ALIGN,
								TEXT_ALIGNS[3],
							);
						}}
					>
						<TextAlignJustify size={20} />
					</BriaButton>
				</Box>
			)}
			returnFocus
			autoFocus
		>
			<Box>
				<BriaTooltip title={"Align"} placement="bottom">
					<img className={styles.textControl} src={align} alt="" />
				</BriaTooltip>
			</Box>
		</StatefulPopover>
	);
};

export default TextAlign;
