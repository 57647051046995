import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { SecondaryTabTypeEnum } from "../..";
import DebouncedInput from "../../../../../../../../components/common/DebouncedInput/DebouncedInput";
import { useAppStore } from "../../../../../../../../hooks/useStores";
import ImageGrid from "../../../../../../../components/common/ImageGrid/ImageGrid";
import { Image } from "../../../../../../../models/image.ts";
import { IMenuItem } from "../Images";
import styles from "./GridTab.module.scss";
export type IProps = {
	searchBoxVisible: boolean;
	secondaryTabType: SecondaryTabTypeEnum;
	handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
	menuItems: IMenuItem[];
	numberOfColumns: number;
	addImageObject: (image: Image) => void;
	loadMore: () => void;
};

const GridTab = ({
	searchBoxVisible,
	secondaryTabType,
	handleSearchChange,
	menuItems,
	numberOfColumns,
	addImageObject,
	loadMore,
}: IProps) => {
	const { imagesStore } = useAppStore();
	return (
		<Box className={styles.secondaryTab}>
			{searchBoxVisible && secondaryTabType == SecondaryTabTypeEnum.UNSPLASH && (
				<Box className={styles.searchBoxContainer}>
					<DebouncedInput
						onChange={handleSearchChange}
						className={styles.customBriaInput}
						onKeyDown={(e) => e.stopPropagation()}
						height="40px"
						placeHolder={"Search"}
					/>
				</Box>
			)}
			<ImageGrid
				menuItems={menuItems}
				numberOfColumns={numberOfColumns}
				galleryData={imagesStore.tabGallery}
				addImageObject={addImageObject}
				loadMore={loadMore}
				tabType={secondaryTabType}
			/>
		</Box>
	);
};

export default observer(GridTab);
