import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import styled from "styled-components";

const StyledLineChart = styled.div`
	.recharts-legend-item {
		margin-bottom: 10px;
	}
	.recharts-legend-item-text {
		color: #5b5b5b !important;
		font-size: 12px;
		margin-left: 5px;
	}
	.recharts-legend-wrapper {
		left: 30px !important;
		bottom: -5px !important;
	}
`;

interface ILineChart {
	data: any;
	colors: string[];
	total?: number;
}

const CustomLineChart: React.FC<ILineChart> = ({ data, colors, total = 0, ...rest }) => {
	return (
		<StyledLineChart>
			<LineChart
				width={730}
				height={250}
				data={data}
				margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
				{...rest}
			>
				<CartesianGrid strokeDasharray="0" />
				<XAxis
					dataKey="name"
					tick={{ color: "#5B5B5B", fontSize: "10px", fontWeight: 600 }}
					axisLine={false}
					tickLine={false}
					tickMargin={7}
				/>
				<YAxis
					tick={{ color: "#5B5B5B", opacity: "0.5", fontSize: "10px", fontWeight: 600 }}
					axisLine={false}
					tickLine={false}
					tickMargin={25}
				/>
				<Tooltip itemStyle={{ fontSize: 16 }} />
				{total > 0 && <Legend align="left" iconSize={16} />}

				{data &&
					data.length > 0 &&
					Object.keys(data[0]).map(
						(key, idx) =>
							key !== "name" && (
								<Line
									key={idx}
									type="linear"
									strokeWidth={2}
									legendType="square"
									dot={{ fill: colors[idx % colors.length] }}
									dataKey={key}
									stroke={colors[idx]}
								/>
							),
					)}
			</LineChart>
		</StyledLineChart>
	);
};

export default CustomLineChart;
