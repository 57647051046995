export const DashboardIcon = ({ stroke }: any) => {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5 1H3C1.89543 1 1 1.89543 1 3V4C1 5.10457 1.89543 6 3 6H5C6.10457 6 7 5.10457 7 4V3C7 1.89543 6.10457 1 5 1Z"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 10H3C1.89543 10 1 10.8954 1 12V15C1 16.1046 1.89543 17 3 17H5C6.10457 17 7 16.1046 7 15V12C7 10.8954 6.10457 10 5 10Z"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 1H13C11.8954 1 11 1.89543 11 3V15C11 16.1046 11.8954 17 13 17H15C16.1046 17 17 16.1046 17 15V3C17 1.89543 16.1046 1 15 1Z"
				stroke={stroke}
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
