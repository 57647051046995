import { ORG_BLOCK_REASONS, ORG_SUBSCRIPTION_PLANS } from "../constants/billing.ts";
import { useAppStore } from "./useStores.tsx";

export const useSubscription = () => {
	const { authStore } = useAppStore();
	const isPremiumOrgSubscription = () => {
		const plan = authStore.orgSubscription?.plan_name ?? ORG_SUBSCRIPTION_PLANS.free.name;
		return plan !== ORG_SUBSCRIPTION_PLANS.free.name && plan !== ORG_SUBSCRIPTION_PLANS.starter.name;
	};

	const isOrgBlocked = () => {
		return (
			authStore.isLoadingOrgSubscriptions ||
			(authStore.orgSubscription?.is_blocked &&
				authStore.orgSubscription.block_reason !== ORG_BLOCK_REASONS.PASSED_FREE_LIMITS)
		);
	};

	return {
		isPremiumOrgSubscription,
		isOrgBlocked,
	};
};
