import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { MouseEventHandler, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icons from "../../../assets/images/Icons@2x.png";
import SingleObject from "../../../assets/images/Single subject@2x.png";
import Style from "../../../assets/images/Style@2x.png";

import { TAILORED_GENERATION_MODEL_STEP, TAILORED_GENERATION_TYPES } from "../../../constants/TGConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { isBriaOrg } from "../../../utils/index.ts";
import styles from "./SelectType.module.scss";
import TypeItem from "./TypeItem.tsx";

interface IModelDetails {
	handleNext: MouseEventHandler<any>;
}

const SelectType = ({ handleNext }: IModelDetails) => {
	const { tailoredGenerationStore, uiStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });

	const handleClick = (type: string, event?: React.MouseEvent<any, MouseEvent>) => {
		if (handleNext) {
			handleNext(event!);
		}
		tailoredGenerationStore.handleFormChange("type", type);
	};

	useEffect(() => {
		if (uiStore.createDataSet) {
			uiStore.updateTgStep(TAILORED_GENERATION_MODEL_STEP.SELECT_TYPE);
		}
	}, [uiStore.createDataSet]);

	return (
		<Box className={styles.container}>
			<Typography className={styles.title}>{t("models.stepper.selectType.title")}</Typography>
			<Typography className={styles.description}>{t("models.stepper.selectType.description")}</Typography>

			<Box className={styles.typesContainer}>
				{/* Style Type */}
				<TypeItem
					id={t("guidelinesForTailoredModels.taps.Style.id")}
					title={t("models.stepper.selectType.styleLabel")}
					description={t("models.stepper.selectType.styleTypeRequiredImages")}
					imgSrc={Style}
					imgAlt="Style"
					handleClick={() => handleClick(TAILORED_GENERATION_TYPES.STYLE)}
				/>
				{/* Single Subject Type */}
				<TypeItem
					id={t("guidelinesForTailoredModels.taps.singleSubject.id")}
					title={t("models.stepper.selectType.singleSubjectLabel")}
					description={t("models.stepper.selectType.singleSubjectTypeRequiredImages")}
					imgSrc={SingleObject}
					imgAlt="SingleObject"
					handleClick={() => handleClick(TAILORED_GENERATION_TYPES.SINGLE_SUBJECT)}
				/>

				{isBriaOrg() && (
					<>
						{/*Icons Type */}
						<TypeItem
							id={t("guidelinesForTailoredModels.taps.Icons.id")}
							title={t("models.stepper.selectType.iconsLabel")}
							description={t("models.stepper.selectType.iconsTypeRequiredImages")}
							imgSrc={Icons}
							imgAlt="Icons"
							handleClick={() => handleClick(TAILORED_GENERATION_TYPES.ICONS)}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(SelectType);
export default ObservedComponent;
