function Duplicate({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M13.699 14.9745C13.699 15.6789 13.1279 16.25 12.4235 16.25H5.02551C4.32108 16.25 3.75 15.6789 3.75 14.9745V7.57653C3.75 6.8721 4.32108 6.30102 5.02551 6.30102H12.4235C13.1279 6.30102 13.699 6.8721 13.699 7.57653V14.9745Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeMiterlimit="10"
			/>
			<path
				d="M5.66309 3.74969H14.9743C15.6787 3.74969 16.2498 4.32077 16.2498 5.0252V14.3364"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M8.72461 9.36243V13.189"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M6.81104 11.2754H10.6376"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
		</svg>
	);
}

export default Duplicate;
