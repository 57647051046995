// @ts-ignore
function Color({ size }: { size: number }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
			<rect x="-6.91699" y="-7.46338" width="32.4988" height="32.6032" stroke="#E7E7E7" />
			<path
				d="M22.418 8.83813C22.418 11.1423 21.8134 13.4058 20.6649 15.4013L9.33252 8.83813H22.418Z"
				fill="#F45542"
			/>
			<path
				d="M20.6641 15.4026C19.5154 17.3979 17.8633 19.0547 15.874 20.2065L9.33252 8.83813L20.6641 15.4026Z"
				fill="#DD3242"
			/>
			<path
				d="M15.874 20.2065C13.8846 21.3583 11.628 21.9646 9.33105 21.9644L9.33243 8.83813L15.874 20.2065Z"
				fill="#AF2F79"
			/>
			<path
				d="M-3.75293 8.83813C-3.75293 11.1423 -3.14832 13.4058 -1.9998 15.4013L9.33255 8.83813H-3.75293Z"
				fill="#2656AB"
			/>
			<path
				d="M-1.99902 15.4026C-0.850315 17.3979 0.801759 19.0547 2.79114 20.2065L9.33257 8.83813L-1.99902 15.4026Z"
				fill="#7943A3"
			/>
			<path
				d="M2.79102 20.2065C4.78037 21.3583 7.03696 21.9646 9.33392 21.9644L9.33254 8.83813L2.79102 20.2065Z"
				fill="#A23CA2"
			/>
			<path
				d="M-3.75293 8.83827C-3.75293 6.53412 -3.14832 4.2706 -1.9998 2.27515L9.33255 8.83827H-3.75293Z"
				fill="#019A85"
			/>
			<path
				d="M-1.99902 2.2737C-0.850315 0.278381 0.801759 -1.37845 2.79114 -2.53027L9.33257 8.83813L-1.99902 2.2737Z"
				fill="#01AE5C"
			/>
			<path
				d="M2.79102 -2.53019C4.78037 -3.68205 7.03696 -4.28832 9.33392 -4.28809L9.33254 8.83815L2.79102 -2.53019Z"
				fill="#A1CF49"
			/>
			<path
				d="M22.418 8.83827C22.418 6.53412 21.8134 4.2706 20.6649 2.27515L9.33252 8.83827H22.418Z"
				fill="#F97827"
			/>
			<path
				d="M20.6641 2.2737C19.5154 0.278381 17.8633 -1.37845 15.874 -2.53027L9.33252 8.83813L20.6641 2.2737Z"
				fill="#FCA313"
			/>
			<path
				d="M15.874 -2.53019C13.8846 -3.68205 11.628 -4.28832 9.33105 -4.28809L9.33243 8.83815L15.874 -2.53019Z"
				fill="#FFE503"
			/>
		</svg>
	);
}

export default Color;
