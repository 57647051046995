import { Grid, Typography, styled } from "@mui/material";

interface ImageErrorContainerProps {
	imageWidth: string;
	imageHeight: string;
}

interface TitleProps {
	titleFontSize: string;
}

interface TextProps {
	massageTextSize: string;
}

const ImageErrorContainer = styled(Grid)<ImageErrorContainerProps>(({ imageWidth, imageHeight }) => ({
	width: imageWidth,
	height: imageHeight,
	textAlign: "center",
	borderRadius: "10px",
	display: "inline-flex",
	cursor: "default",
}));

const Title = styled("div")<TitleProps>(({ titleFontSize }) => ({
	fontWeight: 600,
	fontSize: titleFontSize,
	color: "#A4A4A4",
}));

const Text = styled(Typography)<TextProps>(({ massageTextSize }) => ({
	color: "#A4A4A4",
	textAlign: "center",
	lineHeight: "normal",
	margin: "0 15% 0 15%",
	fontSize: massageTextSize,
}));

const DefaultBackground = styled(ImageErrorContainer)({
	background:
		"linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, rgba(196, 196, 196, 0) 0%, rgba(73, 80, 87, 0.3) 100%)",
	filter: "drop-shadow(0px 6px 40px rgba(73, 80, 87, 0.2))",
});

const SolidBackground = styled(ImageErrorContainer)({
	background: "#D9D9D9",
});

export default function ImageError({
	imageWidth,
	imageHeight,
	massageText,
	titleText,
	titleFontSize,
	largeSvg = false,
	massageTextSize,
}: {
	imageWidth: string;
	imageHeight: string;
	massageText: string;
	titleText: string;
	titleFontSize: string;
	largeSvg?: boolean;
	massageTextSize: string;
}) {
	const ContainerComponent = largeSvg ? SolidBackground : DefaultBackground;

	return (
		<ContainerComponent
			container
			justifyContent="center"
			alignItems="center"
			xs={12}
			imageWidth={imageWidth}
			imageHeight={imageHeight}
		>
			<Grid xs={12}>
				<Grid item xs={12}>
					{largeSvg ? (
						<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0ZM22 30H18V26H22V30ZM22 22H18V10H22V22Z"
								fill="#A4A4A4"
							/>
						</svg>
					) : (
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8.8 12H7.2V10.4H8.8V12ZM8.8 8.8H7.2V4H8.8V8.8Z"
								fill="#A4A4A4"
							/>
						</svg>
					)}
				</Grid>
				<Grid item xs={12}>
					<Title as="h1" titleFontSize={titleFontSize}>
						{titleText}
					</Title>

					{massageText && <Text massageTextSize={massageTextSize}>{massageText}</Text>}
				</Grid>
			</Grid>
		</ContainerComponent>
	);
}
