import { Box, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "baseui";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../../../../hooks/useStores";
import Icons from "../../../../components/Icons";
import Scrollable from "../../../../components/Scrollable";
import { BASE_ITEMS, PanelType } from "../../../../constants/app-options";
import useAppContext from "../../../../hooks/useAppContext";
import useSetIsSidebarOpen from "../../../../hooks/useSetIsSidebarOpen";

interface StyleProps {
	name: string;
	activePanel: string;
}
const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	editorPanelList: (props: StyleProps) => ({
		height: "58px",
		marginRight: "5px",
		marginLeft: "5px",
		marginTop: "8px",
		backgroundColor: props.name === props.activePanel ? "rgba(83, 0, 201, 0.05)" : theme?.palette?.common?.white,
		borderRadius: props.name === props.activePanel ? "8px" : "",
		border: props.name === props.activePanel ? "2px solid rgba(83, 0, 201, 0.05)" : "",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "center",
		color: "#5B5B5B",
		gap: "3px",
		"&:hover, &.active": {
			cursor: "pointer",
			borderRadius: "8px",
			color: "#5300C9 !important",
			background: "rgba(83, 0, 201, 0.05)",

			"& svg path": {
				stroke: "#5300C9",
			},
		},
	}),
	tapsTitle: {
		fontFamily: "Montserrat",
		fontSize: "8px",
		fontWeight: 600,
		lineHeight: "normal",
	},
}));

const Container = styled("div", (_props) => ({
	backgroundColor: "white",
	display: "flex",
	borderRadius: "8px",
	border: "1px solid #E7E7E7",
	background: "#FFF",
	boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.04)",
	width: "64px",
}));

function PanelsList() {
	const { activePanel, setActivePanel } = useAppContext();
	const { t } = useTranslation("translation");
	const { campaignStore } = useAppStore();
	const [updatedItems, setUpdatedItems] = useState(BASE_ITEMS);

	useEffect(() => {
		let Items;

		if (!campaignStore.isAdminMode) {
			Items = BASE_ITEMS.filter((item) => item.id !== "templates");
		} else {
			Items = BASE_ITEMS.filter((item) => item.id !== "campaigns");
		}

		setUpdatedItems(Items);
	}, [campaignStore.isAdminMode]);

	useEffect(() => {
		if (!campaignStore.isAdminMode) {
			if (activePanel == PanelType.TEMPLATES) setActivePanel(PanelType.CAMPAIGNS);
		} else {
			if (activePanel == PanelType.CAMPAIGNS) setActivePanel(PanelType.TEMPLATES);
		}
	}, [updatedItems]);

	return (
		<Container>
			<Scrollable autoHide={true}>
				{updatedItems.map((panelListItem) => (
					<PanelListItem
						label={t(`editor.panels.panelsList.${panelListItem.id}`)}
						name={panelListItem.name}
						key={panelListItem.name}
						icon={panelListItem.name}
						activePanel={activePanel}
					/>
				))}
			</Scrollable>
		</Container>
	);
}

function PanelListItem({ label, icon, activePanel, name }: any) {
	const { setActivePanel } = useAppContext();
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const classes = useStyles({ name: name, activePanel: activePanel });
	// @ts-ignore
	const Icon = Icons[icon];

	return (
		<Box
			id={"EditorPanelList"}
			onClick={() => {
				setIsSidebarOpen(true);
				setActivePanel(name);
			}}
			className={`${classes.editorPanelList} ${name === activePanel ? "active" : ""}`}
		>
			<Icon size={24} />
			<Typography className={classes.tapsTitle}>{label}</Typography>
		</Box>
	);
}

const ObservedComponent = observer(PanelsList);
export default ObservedComponent;
