import { BriaAxios } from "../config/axios.tsx";
import { IHubspotContactModel } from "../models/hubspotModel.ts";

const BASE_URL = "/hubspot";

class HubspotService {
	upsertContact = async (contactModel: IHubspotContactModel) => {
		const response = await (await BriaAxios()).post(`${BASE_URL}/upsert_contact/`, contactModel);
		return response.data;
	};
}

export default HubspotService;
