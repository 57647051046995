import { Box, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaIconButton from "../../../../../../../../../components/common/BriaIconButton/BriaIconButton";
import FileUploader from "../../../../../../../../../components/common/FileUploader/FileUploader";
import { useAppStore } from "../../../../../../../../../hooks/useStores";
import { Font, defaultFont } from "../../../../../../../../../models/font";
import { FontOptions } from "../../../../../../../../../models/fontOptions";
import Close from "../../../../../../../../assets/svgs/Close.svg";
import styles from "./FontsListModel.module.scss";

interface FontsListModelProps {
	index: number;
	setActiveFontIndex: (index: number | null) => void;
	fontsOption: FontOptions[];
	loadAllFonts: () => void;
	handleFontChange: (font: Font, index: number) => void;
}

const FontsListModel: React.FC<FontsListModelProps> = ({
	index,
	setActiveFontIndex,
	fontsOption,
	loadAllFonts,
	handleFontChange,
}) => {
	const { brandsDefinitionStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.brands" });
	const [filteredOptions, setFilteredOptions] = useState(fontsOption);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		setFilteredOptions(fontsOption);
	}, [fontsOption]);

	const handleChange = (value: string) => {
		setSearchTerm(value);
		const filtered = fontsOption
			.map((fontCategory) => ({
				...fontCategory,
				options: fontCategory.options.filter((font: any) =>
					font.label.toLowerCase().includes(value.toLowerCase()),
				),
			}))
			.filter((fontCategory) => fontCategory.options.length > 0);
		setFilteredOptions(filtered);
	};

	const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: File | null = e.target.files && e.target.files[0];
		e.target.value = "";
		if (file) {
			const res: any = await brandsDefinitionStore.uploadFont(
				{ ...defaultFont, name: file.name.split(".")[0] },
				file,
			);
			if (res) {
				const newFont: Font = {
					...defaultFont,
					name: file.name.split(".")[0],
					id: res.id,
					src: res.src,
				};
				handleFontChange(newFont, index);
				loadAllFonts();
				setActiveFontIndex(null);
			}
		}
	};

	return (
		<Box className={clsx(styles.fontsListContainer)} key={index}>
			<Box className={styles.listHeader}>
				<Box className={styles.title}>{t("selectFont")}</Box>
				<Box className={styles.iconsContainer}>
					<BriaIconButton className={styles.actionBtn} onClick={() => setActiveFontIndex(null)}>
						<img src={Close} alt={t("closeBrand")} />
					</BriaIconButton>
				</Box>
			</Box>
			<TextField
				id={t("search")}
				placeholder={t("search")}
				value={searchTerm}
				onChange={(e) => handleChange(e.target.value)}
				className={styles.searchTextField}
				fullWidth
				InputProps={{ classes: { root: styles.searchTextField } }}
			/>
			<Box className={styles.fontsOptionWrapper}>
				{filteredOptions
					?.sort((a: FontOptions, b: FontOptions) => a.name.localeCompare(b.name))
					.map((group: FontOptions, groupIndex: number) => (
						<Box className={styles.fontContainer} key={groupIndex}>
							<Typography className={styles.fontName}>{group.name}</Typography>
							<Box className={styles.fontOptions}>
								{group.options
									.sort((a: Font, b: Font) => a.name?.localeCompare(b.name))
									.map((font: Font, fontIndex: number) => (
										<Box
											className={styles.fontItem}
											sx={{ fontFamily: font.name }}
											key={fontIndex}
											onClick={() => handleFontChange(font, index)}
										>
											{font.name}
										</Box>
									))}
							</Box>
						</Box>
					))}
			</Box>
			<Box className={styles.fileUploaderContainer}>
				<FileUploader
					buttonText={t("addNewFont")}
					buttonType="secondaryMedium"
					inputProps={{ accept: ".ttf" }}
					onUpload={handleFileChange}
					buttonProps={{ className: styles.uploadBtn }}
					loading={brandsDefinitionStore.loadingUpload}
				/>
			</Box>
		</Box>
	);
};

export default FontsListModel;
