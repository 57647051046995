import { styled } from "@mui/system";
import { observer } from "mobx-react-lite";
import "swiper/css";
import "swiper/css/navigation";
// @ts-expect-error "React couldn't see Navigation inside swiper/modules while I ensured it's there"
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ImageViewer from "../Image/Image.tsx";
import styles from "./CustomSwiper.module.scss";

// Styled Swiper component
const StyledSwiper = styled(Swiper)({
	width: "100%",
	"--swiper-navigation-size": "20px",
	"--swiper-navigation-color": "#1A0638",
});

interface IStyledSwiper {
	items: string[];
}

const CustomSwiper = ({ items }: IStyledSwiper) => {
	return (
		<StyledSwiper navigation={true} modules={[Navigation]}>
			{items.map((item, index) => (
				<SwiperSlide key={index}>
					<ImageViewer className={styles.image} name={item} />
				</SwiperSlide>
			))}
		</StyledSwiper>
	);
};

const ObservedComponent = observer(CustomSwiper);
export default ObservedComponent;
