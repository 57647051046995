import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";

interface IProps {
	background: string;
	height: string;
	width: string;
	borderRadius: string;
	backgroundRepeat: string;
	backgroundPosition: string;
	children?: ReactNode;
}

const Background = ({
	background,
	height,
	width,
	borderRadius,
	backgroundRepeat,
	backgroundPosition,
	children,
}: IProps) => {
	const boxStyle = {
		backgroundImage: `url(${background})`,
		backgroundRepeat: `${backgroundRepeat}`,
		width: `${width}`,
		height: `${height}`,
		borderRadius: `${borderRadius}`,
		backgroundPosition: `${backgroundPosition}`,
	};

	return <Box style={boxStyle}>{children}</Box>;
};

const ObservedComponent = observer(Background);
export default ObservedComponent;
