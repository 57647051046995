import { SvgIcon, SvgIconProps } from "@mui/material";

export const EditIcon2 = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 16 16" {...props}>
			<path
				d="M3.57459 4.22591H2.74125C2.29922 4.22591 1.8753 4.40151 1.56274 4.71407C1.25018 5.02663 1.07458 5.45055 1.07458 5.89258V13.3926C1.07458 13.8346 1.25018 14.2585 1.56274 14.5711C1.8753 14.8836 2.29922 15.0592 2.74125 15.0592H10.2413C10.6833 15.0592 11.1072 14.8836 11.4198 14.5711C11.7323 14.2585 11.9079 13.8346 11.9079 13.3926V12.5592M11.0746 2.55924L13.5746 5.05924M14.7288 3.88017C15.057 3.55196 15.2413 3.10682 15.2413 2.64267C15.2413 2.17851 15.057 1.73337 14.7288 1.40517C14.4005 1.07696 13.9554 0.892578 13.4913 0.892578C13.0271 0.892578 12.582 1.07696 12.2538 1.40517L5.24125 8.39267V10.8927H7.74125L14.7288 3.88017Z"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
