import { Box, FormControlLabel, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Vector from "../../../../../assets/images/svgs/VectorIcon.svg";
import BriaButton from "../../../../../components/common/BriaButton/BriaButton";
import BriaCheckbox from "../../../../../components/common/BriaCheckbox/BriaCheckbox";
import BriaInput from "../../../../../components/common/BriaInput/BriaInput";
import SurpriseButton from "../../../../../components/common/SurpriseButton/SurpriseButton";
import { useAppStore } from "../../../../../hooks/useStores";
import styles from "./TextToImageSearch.module.scss";

const TextToImageSearch = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.textToImage.generator.searchBox" });
	const { textToImageStore, playgroundStore } = useAppStore();
	const isGenerateEnabled =
		(textToImageStore.config.model || textToImageStore.config.model_url) &&
		textToImageStore.config.prompt &&
		!playgroundStore.playgroundResults[playgroundStore.playgroundResults.length - 1]?.images.some(
			(image) => image.loading,
		);

	useEffect(() => {
		if (textToImageStore.config.tailored_model_id) {
			textToImageStore.handleConfigChange("prompt_enhancement", false);
		} else if (!textToImageStore.isTextToVector()) {
			textToImageStore.handleConfigChange("prompt_enhancement", true);
		}
	}, [textToImageStore.config.tailored_model_id]);

	return (
		<Box className={playgroundStore.isText2ImagePopupOpened ? styles.rootModal : styles.root}>
			{textToImageStore.errorMessage && (
				<Box className={styles.errorMessageContainer}>
					<img src={Vector} />
					<Typography className={styles.errorMessage}>{textToImageStore.errorMessage}</Typography>
				</Box>
			)}
			<Box className={styles.searchBoxWrapper}>
				<BriaInput
					value={textToImageStore.config.prompt}
					onChange={(e) => textToImageStore.handleConfigChange("prompt", e.target.value)}
					onEnterPress={textToImageStore.generateTextToImage}
					className={styles.searchBox}
					placeholder={t("placeholder")}
					InputProps={{
						endAdornment: (
							<SurpriseButton
								textsListType={textToImageStore.isTextToVector() ? "textToVector" : "textToImage2"}
								onSurprise={(prompt) => textToImageStore.handleConfigChange("prompt", prompt)}
							/>
						),
					}}
					inputProps={{ className: styles.inputBase }}
				/>
				<BriaButton
					buttonType="primaryMedium"
					className={styles.button}
					onClick={textToImageStore.generateTextToImage}
					disabled={!isGenerateEnabled}
				>
					{t("button")}
				</BriaButton>
			</Box>
			{!textToImageStore.isTextToVector() && !textToImageStore.config.tailored_model_id && (
				<FormControlLabel
					control={
						<BriaCheckbox
							checked={textToImageStore.config?.prompt_enhancement}
							onChange={(e) =>
								textToImageStore.handleConfigChange("prompt_enhancement", e.target.checked)
							}
						/>
					}
					label={t("enhancedPrompt")}
				/>
			)}
		</Box>
	);
};

export default observer(TextToImageSearch);
