function Sticker({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M17.3431 10.3664L15.6764 10.783C14.9491 10.8467 14.2167 10.7502 13.5306 10.5005C12.8445 10.2508 12.2215 9.85385 11.7052 9.33759C11.1889 8.82133 10.792 8.19827 10.5423 7.5122C10.2926 6.82613 10.1961 6.0937 10.2598 5.36637L10.6764 3.69971M17.3431 10.3664L10.6764 3.69971M17.3431 10.3664C17.3431 11.6849 16.9521 12.9738 16.2196 14.0702C15.487 15.1665 14.4458 16.021 13.2277 16.5256C12.0095 17.0302 10.669 17.1622 9.37583 16.9049C8.08263 16.6477 6.89474 16.0128 5.96239 15.0804C5.03004 14.1481 4.3951 12.9602 4.13787 11.667C3.88063 10.3738 4.01265 9.03333 4.51724 7.81515C5.02182 6.59698 5.87631 5.55579 6.97263 4.82324C8.06896 4.0907 9.35789 3.69971 10.6764 3.69971"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Sticker;
