import { Box, Typography } from "@mui/material";
import React from "react";
import EmptyListIcon from "../../../assets/images/icons/EmptyListIcon";
import classes from "./EmptyList.module.scss";

interface IProps {
	message: string;
}

const EmptyList: React.FC<IProps> = ({ message }) => {
	return (
		<Box className={classes.emptyList}>
			<EmptyListIcon className={classes.emptyListIcon}/>
			<Typography className={classes.message}>{message}</Typography>
		</Box>
	);
};

export default EmptyList;
