import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { Trans, useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import Card1_1 from "../../../assets/images/svgs/foxGuidlinesCards/card1_1.svg";
import Card1_2 from "../../../assets/images/svgs/foxGuidlinesCards/card1_2.svg";
import Card1_3 from "../../../assets/images/svgs/foxGuidlinesCards/card1_3.svg";
import Card2_1 from "../../../assets/images/svgs/foxGuidlinesCards/card2_1.svg";
import Card2_2 from "../../../assets/images/svgs/foxGuidlinesCards/card2_2.svg";
import Card2_3 from "../../../assets/images/svgs/foxGuidlinesCards/card2_3.svg";
import Card3_1 from "../../../assets/images/svgs/foxGuidlinesCards/card3_1.svg";
import Card3_2 from "../../../assets/images/svgs/foxGuidlinesCards/card3_2.svg";
import Card3_3 from "../../../assets/images/svgs/foxGuidlinesCards/card3_3.svg";
import Card4_1 from "../../../assets/images/svgs/foxGuidlinesCards/card4_1.svg";
import Card4_2 from "../../../assets/images/svgs/foxGuidlinesCards/card4_2.svg";
import Card4_3 from "../../../assets/images/svgs/foxGuidlinesCards/card4_3.svg";
import { useAppStore } from "../../../hooks/useStores.tsx";
import PopupWithSidebar from "../../Popups/PopupWithSidebar/PopupWithSidebar.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import styles from "./FoxGuidelinesPopup.module.scss";

const FoxGuidelinesPopup = () => {
	const { uiStore } = useAppStore();
	const transKeyPrefix = "playground.FoxGuidelinesPopup";
	const { t } = useTranslation("translation", { keyPrefix: transKeyPrefix });
	const sideMenuButtons = Object.values(t("tabs", { returnObjects: true }));
	const guidelinesCards = [
		{
			titleKey: "card1.title",
			descriptionKey: "card1.description",
			images: [Card1_1, Card1_2, Card1_3],
		},
		{
			titleKey: "card2.title",
			descriptionKey: "card2.description",
			images: [Card2_1, Card2_2, Card2_3],
		},
		{
			titleKey: "card3.title",
			descriptionKey: "card3.description",
			images: [Card3_1, Card3_2, Card3_3],
		},
		{
			titleKey: "card4.title",
			descriptionKey: "card4.description",
			images: [Card4_1, Card4_2, Card4_3],
		},
	];

	const handleCloseDialog = () => {
		uiStore.hideDialog("FoxGuidelinesPopup");
	};

	const GuidelinesCard = ({
		titleKey,
		descriptionKey,
		images,
	}: {
		titleKey: string;
		descriptionKey: string;
		images: string[];
	}) => (
		<Box className={styles.guidelinesCard}>
			<Box className={styles.guidelinesCardContent}>
				<Typography className={styles.title}>
					<Trans
						i18nKey={`${transKeyPrefix}.tabs.inputImageGuidelines.content.guidelinesSection.cards.${titleKey}`}
					/>
				</Typography>
				<Typography className={styles.description}>
					<Trans
						i18nKey={`${transKeyPrefix}.tabs.inputImageGuidelines.content.guidelinesSection.cards.${descriptionKey}`}
					/>
				</Typography>
			</Box>
			<Box className={styles.guidelinesCardImages}>
				{images.map((image) => (
					<img src={image} />
				))}
			</Box>
		</Box>
	);

	const GuidelinesCardsSection = () => (
		<Box className={styles.guidelinesSection}>
			<Typography className={styles.title}>
				{t("tabs.inputImageGuidelines.content.guidelinesSection.title")}
			</Typography>
			{guidelinesCards.map((card) => (
				<GuidelinesCard titleKey={card.titleKey} descriptionKey={card.descriptionKey} images={card.images} />
			))}
		</Box>
	);

	return (
		<PopupWithSidebar
			sideMenuButtons={sideMenuButtons}
			handleCloseDialog={handleCloseDialog}
			open={uiStore.FoxGuidelinesPopup}
		>
			<Box className={styles.contentContainer}>
				<Box className={clsx(styles.tabContent, styles.firstTabContent)}>
					<Typography id={t("tabs.guidelines.id")} className={styles.title}>
						{t("tabs.guidelines.content.title")}
					</Typography>
					<Typography className={styles.description}>{t("tabs.guidelines.content.description")}</Typography>
					<Typography className={styles.subTitle}>{t("tabs.guidelines.content.subTitle")}</Typography>
					<Typography className={styles.description}>
						{t("tabs.guidelines.content.subTitleDescription")}
					</Typography>
					<BriaButton
						className={styles.button}
						buttonType="primary"
						onClick={() =>
							window.open(
								"https://docs.google.com/presentation/d/1j5OmprYRWYYFhclVgI3uRBJR77KrLBWwImw906WR5q8/view",
								"_blank",
							)
						}
					>
						{t("tabs.guidelines.content.button")}
					</BriaButton>
				</Box>
				<Box className={styles.tabContent}>
					<Typography id={t("tabs.inputImageGuidelines.id")} className={styles.title}>
						{t("tabs.inputImageGuidelines.title")}
					</Typography>
					<Typography className={styles.description}>
						<Trans i18nKey={`${transKeyPrefix}.tabs.inputImageGuidelines.content.description`} />
					</Typography>
					<GuidelinesCardsSection />
				</Box>
			</Box>
		</PopupWithSidebar>
	);
};

const ObservedComponent = observer(FoxGuidelinesPopup);
export default ObservedComponent;
