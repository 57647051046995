import API from "../assets/images/svgs/API.svg";
import backgroundGeneration from "../assets/images/svgs/Background Generation.svg";
import campaignGeneration from "../assets/images/svgs/Campaign Generation.svg";
import Group from "../assets/images/svgs/Group 85433.svg";
import imageExpansion from "../assets/images/svgs/Image Expansion.svg";
import imageToPSD from "../assets/images/svgs/Image-to-PSD (Delayering).svg";
import increaseResolution from "../assets/images/svgs/Increase Resolution.svg";
import Models from "../assets/images/svgs/Models.svg";
import presenterRecasting from "../assets/images/svgs/Presenter Recasting.svg";
import texTtoImage from "../assets/images/svgs/Text-to-Image Generation.svg";
import iFrameIcon from "../assets/images/svgs/iFrame.svg";
import { ICardProps } from "../components/Dashboard/DashoboardCardComponent";
import LinkConstants from "../constants/LinkConstants";
import RouterConstants from "./RouterConstants";

export const cardDataImplementedOptions: ICardProps[] = [
	{
		icon: Models,
		cardTitle: "implementationOptions.cards.modelsTitle",
		cardDescription: "implementationOptions.cards.modelsDescription",
		mainButton: "get_started",
		background: "#5300C9",
		newTab: false,
		href: RouterConstants.FOUNDATION_MODELS.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: API,
		cardTitle: "implementationOptions.cards.APITitle",
		cardDescription: "implementationOptions.cards.APIDescription",
		mainButton: "get_started",
		background: "#5300C9",
		newTab: false,
		href: RouterConstants.BASE_PLATFORM_APIS.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: iFrameIcon,
		cardTitle: "implementationOptions.cards.iframeTitle",
		cardDescription: "implementationOptions.cards.iframeDescription",
		mainButton: "get_started",
		background: "#5300C9",
		newTab: false,
		href: RouterConstants.IFRAME_CONFIG.fullPath,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
];

export const aIImageCreationCapabilitiesCards: ICardProps[] = [
	{
		icon: texTtoImage,
		cardTitle: "aIImageCreationCapabilities.cards.texttoImageGenerationTitle",
		cardDescription: "aIImageCreationCapabilities.cards.texttoImageGenerationDescription",
		mainButton: "Playground",
		background: "#D80067",
		newTab: false,
		href: `/${RouterConstants.APPS.path}/${RouterConstants.TEXT_TO_IMAGE.path}`,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: campaignGeneration,
		cardTitle: "aIImageCreationCapabilities.cards.campaignGenerationTitle",
		cardDescription: "aIImageCreationCapabilities.cards.campaignGenerationDescription",
		mainButton: "Playground",
		background: "#D80067",
		newTab: true,
		href: LinkConstants.CAMPAIGN_GENERATION,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
];

export const aiImageCustomizationCapabilitiesCards: ICardProps[] = [
	{
		icon: backgroundGeneration,
		cardTitle: "aIImageCustomizationCapabilities.cards.backgroundGenerationTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.backgroundGenerationDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: true,
		href: LinkConstants.BACKGROUND_GENERATION,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: imageExpansion,
		cardTitle: "aIImageCustomizationCapabilities.cards.imageExpansionTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.imageExpansionDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: true,
		href: LinkConstants.IMAGE_EXPANSION,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: presenterRecasting,
		cardTitle: "aIImageCustomizationCapabilities.cards.presenterAppearanceTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.presenterAppearanceDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: true,
		href: LinkConstants.PRESENTER_RECASTING,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: increaseResolution,
		cardTitle: "aIImageCustomizationCapabilities.cards.increaseResolutionTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.increaseResolutionDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: true,
		href: LinkConstants.INCREASE_RESOLUTION,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: Group,
		cardTitle: "aIImageCustomizationCapabilities.cards.objectErasureAndReplacementTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.objectErasureAndReplacementDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: true,
		href: LinkConstants.OBJECT_ERASURE_AND_REPLACMENT,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
	{
		icon: imageToPSD,
		cardTitle: "aIImageCustomizationCapabilities.cards.imageToPSDTitle",
		cardDescription: "aIImageCustomizationCapabilities.cards.imageToPSDDescription",
		mainButton: "Playground",
		background: "#1A0638",
		newTab: true,
		href: LinkConstants.IMAGE_TO_PSD,
		containGetApiKeyButton: false,
		containGetModelButton: false,
		containconfigureIframeButton: false,
	},
];
