import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function TextVerticalCenterIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 16" {...props}>
			<path
				d="M1.25 7.94824H14.75"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.25 10.2324L7.25 13.9824"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.25 1.98242L7.25 5.73242"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.33984 1.98242L7.21157 1.23242L6.08984 1.98242"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.12402 14.0166L7.2523 14.7666L8.37402 14.0166"
				stroke="#5B5B5B"
				strokeWidth="1.125"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default TextVerticalCenterIcon;
