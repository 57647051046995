function Brands(_size: number) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
			<path
				d="M11.9987 17.1299L12.29 16.5769C12.1078 16.4809 11.8901 16.4808 11.7078 16.5767L11.9987 17.1299ZM6.93237 19.7935L6.31637 19.6879C6.27614 19.9223 6.37253 20.1593 6.565 20.2992C6.75747 20.439 7.01264 20.4574 7.22322 20.3467L6.93237 19.7935ZM7.90015 14.1518L8.51615 14.2575C8.55096 14.0546 8.48361 13.8476 8.33609 13.704L7.90015 14.1518ZM3.7959 10.1568L3.70626 9.53821C3.47069 9.57235 3.27497 9.73733 3.20146 9.96371C3.12794 10.1901 3.18939 10.4386 3.35995 10.6046L3.7959 10.1568ZM9.45977 9.3359L9.54941 9.95444C9.75307 9.92492 9.92915 9.79704 10.0202 9.6125L9.45977 9.3359ZM11.9929 4.20312L12.5534 3.92652C12.4481 3.71319 12.2308 3.57812 11.9929 3.57812C11.755 3.57812 11.5377 3.71319 11.4324 3.92652L11.9929 4.20312ZM14.5261 9.3359L13.9656 9.6125C14.0567 9.79704 14.2327 9.92492 14.4364 9.95444L14.5261 9.3359ZM20.1899 10.1568L20.6259 10.6046C20.7964 10.4386 20.8579 10.1901 20.7844 9.96371C20.7108 9.73733 20.5151 9.57235 20.2796 9.53821L20.1899 10.1568ZM16.0857 14.1518L15.6497 13.704C15.5022 13.8476 15.4349 14.0546 15.4697 14.2575L16.0857 14.1518ZM17.0535 19.7935L16.7621 20.3465C16.9726 20.4574 17.228 20.4392 17.4206 20.2993C17.6132 20.1595 17.7097 19.9225 17.6695 19.6879L17.0535 19.7935ZM11.7078 16.5767L6.64152 19.2403L7.22322 20.3467L12.2895 17.6831L11.7078 16.5767ZM7.54837 19.8992L8.51615 14.2575L7.28415 14.0462L6.31637 19.6879L7.54837 19.8992ZM8.33609 13.704L4.23184 9.70889L3.35995 10.6046L7.46421 14.5997L8.33609 13.704ZM3.88554 10.7753L9.54941 9.95444L9.37012 8.71736L3.70626 9.53821L3.88554 10.7753ZM10.0202 9.6125L12.5534 4.47973L11.4324 3.92652L8.8993 9.0593L10.0202 9.6125ZM11.4324 4.47973L13.9656 9.6125L15.0865 9.0593L12.5534 3.92652L11.4324 4.47973ZM14.4364 9.95444L20.1003 10.7753L20.2796 9.53821L14.6157 8.71736L14.4364 9.95444ZM19.754 9.70889L15.6497 13.704L16.5216 14.5997L20.6259 10.6046L19.754 9.70889ZM15.4697 14.2575L16.4375 19.8992L17.6695 19.6879L16.7017 14.0462L15.4697 14.2575ZM17.3448 19.2406L12.29 16.5769L11.7073 17.6828L16.7621 20.3465L17.3448 19.2406Z"
				fill="#5B5B5B"
			/>
		</svg>
	);
}

export default Brands;
