import { getSelectedOrganization } from "../helpers/localStorage";
import { ColorPallet } from "./colorPallete";
import { StatusEnum } from "./common";
import { Logo } from "./logo";

export interface Brand {
	id: number;
	name: string;
	description?: string;
	primary_message?: { size?: number; color?: string };
	secondary_message?: { size?: number; color?: string };
	primary_font_id?: number;
	secondary_font_id?: number;
	logo_id?: number;
	color_pallete_id?: number;
	status: StatusEnum;
	org_id: string;
	created_at: Date;
	updated_at: Date;
	logo?: Logo;
	color_pallete?: ColorPallet;
}

export const defaultBrand: Brand = {
	id: NaN,
	name: "Untitled - Brand Name",
	status: StatusEnum.PENDING,
	org_id: getSelectedOrganization()?.organization?.uid || "",
	primary_message: {},
	secondary_message: {},
	created_at: new Date(),
	updated_at: new Date(),
};
