import { Box, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import TrashSVG from "../../../assets/images/svgs/trash.svg";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { IDatasetImage, ITailoredGenerationDataset } from "../../../models/tailoredGeneration.ts";
import styles from "./DatasetImagesGrid.module.scss";

interface IProps {
	dataset: ITailoredGenerationDataset | undefined;
	handleDeleteImage?: (asset_id: string | undefined) => void;
}

const DatasetImagesGrid = ({ dataset, handleDeleteImage }: IProps) => {
	const { tailoredGenerationStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });

	return (
		<Box className={styles.container}>
			<Grid container spacing={4}>
				{dataset?.assets?.map((imageObject: IDatasetImage, index) => (
					<Grid item xs={12} sm={12} md={6} lg={3} xl={3} className={styles.itemGrid}>
						<Box className={styles.imgContainer}>
							{handleDeleteImage && !tailoredGenerationStore.isUploading && (
								<img
									src={TrashSVG}
									className={styles.trash}
									onClick={() => {
										handleDeleteImage(imageObject.id);
									}}
									alt={`${index}`}
								/>
							)}
							<img key={index} src={imageObject.imageUrl} alt={`${index}`} className={styles.srcImage} />
						</Box>
						<TextField
							value={imageObject.caption}
							placeholder={t("models.stepper.createDataSet.imageCaptionPlaceholder")}
							fullWidth
							multiline
							minRows={2}
							maxRows={2}
							disabled={true}
							className={styles.bigTextFieldContainer}
							InputProps={{
								classes: {
									root: styles.bigTextField,
								},
							}}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

const ObservedComponent = observer(DatasetImagesGrid);
export default ObservedComponent;
