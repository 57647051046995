export enum PanelType {
	TEMPLATES = "Templates",
	BACKGROUND = "Background",
	FONTSELECTOR = "FontSelector",
	TEXTFILL = "TextFill",
	SHAPEFILLCOLOR = "ShapeFillColor",
	IMAGES = "Images",
	TEXT = "Text",
	GRAPHICS = "Graphics",
	BRANDS = "Brands",
	LAYERS = "Layers",
	CAMPAIGNS = "Campaigns",
	RESIZE = "Resize",
	CANVASCOLOR = "CanvasColor",
	INSERTTEXT = "InsertText",
}

export const BASE_ITEMS = [
	{
		id: "templates",
		name: "Templates",
		stepperTitle: "Select template",
		tabName: PanelType.TEMPLATES,
	},
	{
		id: "campaigns",
		name: "Campaigns",
		stepperTitle: "Create campaign",
		tabName: PanelType.CAMPAIGNS,
	},
	{
		id: "images",
		name: "Images",
		stepperTitle: "Edit main object",
		tabName: PanelType.IMAGES,
	},
	{
		id: "text",
		name: "Text",
		stepperTitle: "Edit text",
		tabName: PanelType.TEXT,
	},
	{
		id: "graphics",
		name: "Graphics",
		stepperTitle: "",
		tabName: PanelType.GRAPHICS,
	},
	{
		id: "brands",
		name: "Brands",
		stepperTitle: "Apply brand",
		tabName: PanelType.BRANDS,
	},
	{
		id: "layers",
		name: "Layers",
		stepperTitle: "",
		tabName: PanelType.LAYERS,
	},
	// {
	// 	id: "customize",
	// 	name: "Customize",
	// },
	// {
	// 	id: "elements",
	// 	name: "Elements",
	// },

	// {
	// 	id: "uploads",
	// 	name: "Uploads",
	// }
];
