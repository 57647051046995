import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useAppStore } from "../../hooks/useStores";
import TabsLayout from "../../layout/TabsLayout/TabsLayout";

const Models = () => {
	const { appStore } = useAppStore();
	const { t } = useTranslation();
	const subTabs = ["foundation-models", "auxiliary-models", "access-models"];

	return (
		<>
			<TabsLayout title={t("bria-models")} subTabs={subTabs} isLoading={appStore.isLoading}>
				<Outlet />
			</TabsLayout>
		</>
	);
};

export default observer(Models);
