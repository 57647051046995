import { SvgIcon, SvgIconProps } from "@mui/material";

export const DownArrow = (props: SvgIconProps) => {
	return (
		<SvgIcon width="16" height="17" viewBox="0 0 16 17" {...props}>
			<path
				d="M4 6.07129L8 10.0713L12 6.07129"
				stroke="#1A0638"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
};
