import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function CompareIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 1.25C12.4142 1.25 12.75 1.58578 12.75 2V3C12.75 3.13807 12.8619 3.25 13 3.25C13.4142 3.25 13.75 3.58578 13.75 4C13.75 4.41421 13.4142 4.75 13 4.75C12.8619 4.75 12.75 4.86192 12.75 5V19C12.75 19.138 12.8619 19.25 13 19.25C13.4142 19.25 13.75 19.5857 13.75 20C13.75 20.4142 13.4142 20.75 13 20.75C12.8619 20.75 12.75 20.8619 12.75 21V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V21.25C11.25 20.9738 11.0261 20.75 10.75 20.75H6C4.48122 20.75 3.25 19.5187 3.25 18V6C3.25 4.48121 4.48122 3.25 6 3.25H10.75C11.0261 3.25 11.25 3.02614 11.25 2.75V2C11.25 1.58578 11.5858 1.25 12 1.25ZM11.25 5.25C11.25 4.97385 11.0261 4.75 10.75 4.75H6C5.30964 4.75 4.75 5.30964 4.75 6V18C4.75 18.6903 5.30964 19.25 6 19.25H10.75C11.0261 19.25 11.25 19.0261 11.25 18.75V5.25ZM16.25 4C16.25 3.58578 16.5858 3.25 17 3.25H18C19.5188 3.25 20.75 4.48121 20.75 6V7C20.75 7.41421 20.4142 7.75 20 7.75C19.5858 7.75 19.25 7.41421 19.25 7V6C19.25 5.30964 18.6904 4.75 18 4.75H17C16.5858 4.75 16.25 4.41421 16.25 4ZM20 10.25C20.4142 10.25 20.75 10.5857 20.75 11V13C20.75 13.4142 20.4142 13.75 20 13.75C19.5858 13.75 19.25 13.4142 19.25 13V11C19.25 10.5857 19.5858 10.25 20 10.25ZM20 16.25C20.4142 16.25 20.75 16.5857 20.75 17V18C20.75 19.5187 19.5188 20.75 18 20.75H17C16.5858 20.75 16.25 20.4142 16.25 20C16.25 19.5857 16.5858 19.25 17 19.25H18C18.6904 19.25 19.25 18.6903 19.25 18V17C19.25 16.5857 19.5858 16.25 20 16.25Z"
			/>
		</SvgIcon>
	);
}

export default CompareIcon;
