import { Block } from "baseui/block";
import React from "react";

export default function ({ children }: { children: React.ReactNode }) {
	return (
		<Block
			$style={{
				width: "100vw",
				// height: "100vh",
				display: "flex",
				flexDirection: "column",
				fontFamily: "Montserrat, serif",
			}}
		>
			{children}
		</Block>
	);
}
