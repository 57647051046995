import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress, Link, Typography } from "@mui/material";
import clsx from "clsx";
import { GithubAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Github from "../../../assets/images/icons/Github.tsx";
import Google from "../../../assets/images/icons/Google.tsx";
import Agreement from "../../../components/common/Agreement/Agreement.tsx";
import BriaButton from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useAuthService } from "../../../hooks/useAuthService.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import classes from "./Register.module.scss";

const Register = () => {
	const initialValues = {
		username: "",
		email: "",
		password: "",
		company: "",
		role: "",
		feedback: "",
	};

	const navigate = useNavigate();
	const { authStore } = useAppStore();
	const { t } = useTranslation();
	const [email, setEmail] = useState<string>("");
	const { errorMessage, authenticateWithPopup, registerWithEmail, loading } = useAuthService();
	const queryParams = new URLSearchParams(location.search);
	const continueUrl = queryParams.get("continueUrl") || undefined;
	const loc = useLocation();
	const state = loc.state;

	const handleContinue = (values: any) => {
		setEmail(values.email);
	};

	const signUpUsingProvider = async (authProvider: any) => {
		if (continueUrl && continueUrl.includes("apps")) {
			authStore.registrationSource = "apps";
		} else {
			authStore.registrationSource = "console";
		}
		await authenticateWithPopup(authProvider);
		authStore.registrationSource = undefined;
		if (continueUrl) {
			navigate(continueUrl, { state: state });
		}
	};

	const handleSubmit = (values: any) => {
		if (continueUrl && continueUrl.includes("apps")) {
			authStore.registrationSource = "apps";
		} else {
			authStore.registrationSource = "console";
		}
		registerWithEmail(
			values.username,
			values.email ? values.email : email,
			values.password,
			values.company,
			values.role,
		)
			.then(() => {
				authStore.registrationSource = undefined;
				if (continueUrl) {
					navigate(continueUrl, { state: state });
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Box className={classes.content}>
			{!email ? (
				<>
					<Typography className={classes.mainTitle}>{t("getStartedWithBria")}</Typography>
					<Box className={classes.signInWithContainer}>
						<BriaButton
							buttonType="outlined"
							variant="outlined"
							startIcon={<Google className={classes.icon} />}
							onClick={async () => {
								signUpUsingProvider(new GoogleAuthProvider());
							}}
						>
							{t("signUpWithGoogle")}
						</BriaButton>
						<BriaButton
							buttonType="outlined"
							variant="outlined"
							startIcon={<Github className={classes.icon} />}
							onClick={async () => {
								signUpUsingProvider(new GithubAuthProvider());
							}}
						>
							{t("signUpWithGithub")}
						</BriaButton>
					</Box>
					<Typography className={classes.or}>{t("or")}</Typography>
					<Formik
						initialValues={initialValues}
						validateOnMount={false}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={Yup.object().shape({
							email: Yup.string().email(t("invalidEmail")).required(t("emailIsRequired")),
						})}
						onSubmit={(values) => {
							handleContinue(values);
						}}
					>
						{({ values, errors, handleChange, handleSubmit }) => (
							<Form className={classes.form} noValidate>
								<Box className={classes.formElements}>
									<BriaInput
										id="email"
										label={t("Email")}
										onChange={handleChange}
										value={values.email}
										error={!!errors.email}
										helperText={errors.email}
										className={classes.textField}
									/>

									{errorMessage && (
										<Typography className={classes.error} color="secondary">
											<ErrorIcon className={classes.errorIcon} />
											{t(`${errorMessage}`)}
										</Typography>
									)}

									<BriaButton
										buttonType="primary"
										onClick={() => handleSubmit(this)}
										variant="contained"
										color="secondary"
										fullWidth={true}
									>
										{t("signUp")}
									</BriaButton>
									<Box>
										<Agreement />
									</Box>
									<Typography className={classes.alreadyHaveAnAccount}>
										{t("alreadyHaveAnAccount")}
										<Link
											className={classes.signin}
											onClick={() => {
												navigate(`${RouterConstants.LOGIN.path}?${queryParams.toString()}`, {
													state: state,
												});
											}}
										>
											{t("logIn")}
										</Link>
									</Typography>
								</Box>
							</Form>
						)}
					</Formik>
				</>
			) : (
				<>
					<Typography className={classes.mainTitle}>{t("howDidYouHearAboutUs")}</Typography>
					<Formik
						initialValues={initialValues}
						validateOnMount={false}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={Yup.object().shape({
							username: Yup.string().required(t("fullNameIsRequired")),
							password: Yup.string()
								.min(6, t("mustBe6OrMoreCharacters"))
								.required(t("passwordIsRequired")),
						})}
						onSubmit={handleSubmit}
					>
						{({ values, errors, touched, handleChange, handleBlur }) => (
							<Form className={clsx(classes.form, classes.feedbackForm)} noValidate>
								<Box className={classes.formElements}>
									<BriaInput
										id="username"
										label={t("fullName")}
										onChange={handleChange}
										onBlur={handleBlur}
										value={values.username}
										error={!!touched.username && !!errors.username}
										helperText={touched.username ? errors.username : ""}
										className={classes.textField}
									/>

									<BriaInput
										id="password"
										label={t("password")}
										type="password"
										autoComplete="current-password"
										value={values.password}
										onChange={handleChange}
										onBlur={handleBlur}
										error={!!touched.password && !!errors.password}
										helperText={touched.password ? errors.password : ""}
										className={classes.textField}
									/>

									<BriaButton
										buttonType="primary"
										type="submit"
										variant="contained"
										color="secondary"
										fullWidth={true}
									>
										{t("getStarted")}
										<Box className={classes.circleLoader}>
											{loading && <CircularProgress color="inherit" size={25} />}
										</Box>
									</BriaButton>

									{errorMessage && (
										<Typography className={classes.error} color="secondary">
											<ErrorIcon className={classes.errorIcon} />
											{t(`${errorMessage}`)}
										</Typography>
									)}
								</Box>
							</Form>
						)}
					</Formik>
					<Link className={classes.backToLogInPage} onClick={() => setEmail("")}>
						{t("backToRegistration")}
					</Link>
				</>
			)}
		</Box>
	);
};

export default Register;
