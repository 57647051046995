import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./HeaderLayout.module.scss";

import BriaButton from "../../components/common/BriaButton/BriaButton";

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DesignEditorContext } from "../../DesignEditor/contexts/DesignEditor.tsx";
import BackButton from "../../components/common/BackButton/BackButton.tsx";
import RouterConstants from "../../constants/RouterConstants.ts";
import { USER_DEFAULT_AVATAR } from "../../constants/UserConstants.ts";
import { useAppStore } from "../../hooks/useStores.tsx";

type Props = {
	logo: any;
	onLogoClick: () => void;
	className?: string;
	children: ReactNode;
};

const HeaderLayout = ({ logo, className, children, onLogoClick }: Props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { isPopupView } = useContext(DesignEditorContext);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const {
		uiStore,
		authStore: authStore,
		authStore: { user, logout },
		playgroundStore,
		textToImageStore,
	} = useAppStore();

	const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box
			className={clsx(className, styles.headerContainer, {
				[styles.isCampaignPage]: isPopupView,
			})}
		>
			<Box className={styles.logoContainer}>
				<img className={styles.logo} src={logo} onClick={onLogoClick} />
				{uiStore.backButton && (
					<BackButton
						text={uiStore.backButton.text}
						navigateOptions={uiStore.backButton.navigateOptions}
						to={uiStore.backButton.to}
						showWarning={uiStore.backButton.showWarning}
						loadingWarning={uiStore.backButton.loadingWarning}
						onWarningConfirm={uiStore.backButton.onWarningConfirm}
						currentAppName={uiStore.backButton.currentAppName}
					/>
				)}
			</Box>
			<Box className={styles.buttonsContainer}>
				{children}
				{authStore.user && (
					<Box className={styles.userIconConainter}>
						<BriaButton
							onClick={handleAvatarClick}
							aria-label={t("accountOfCurrentUser")}
							className={styles.avatarButton}
							disableRipple
						>
							<Avatar src={user?.profilePicture ?? USER_DEFAULT_AVATAR} className={styles.avatarStyle} />
						</BriaButton>
					</Box>
				)}
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					// PaperProps={{
					// 	style: {
					// 		width: 150,
					// 	},
					// }}
				>
					{authStore.isLoggedIn && authStore.user?.isSuperAdmin() && (
						<MenuItem onClick={() => uiStore.showDialog("SubscriptionLinkGeneratorPopup")}>
							<Typography variant="inherit">{t("generateSubscriptionLink")}</Typography>
						</MenuItem>
					)}
					<MenuItem
						onClick={() => {
							logout().finally(() => {
								playgroundStore.clearResults();
								textToImageStore.clearConfig();
								textToImageStore.errorMessage = "";
								handleClose();
								const queryParams = new URLSearchParams(location.search);
								const continueUrl = queryParams.get("continueUrl") || undefined;

								if (
									(location.pathname && location.pathname.includes("apps")) ||
									(continueUrl && continueUrl.includes("apps"))
								) {
									if (
										(location.pathname && location.pathname.includes("fox")) ||
										(continueUrl && continueUrl.includes("fox"))
									) {
										navigate(RouterConstants.FOX_APPS.path);
									} else {
										navigate(RouterConstants.APPS.path);
									}
								} else {
									navigate(RouterConstants.LOGIN.path);
								}
							});
						}}
					>
						<Typography variant="inherit">{t("logout")}</Typography>
					</MenuItem>
				</Menu>
			</Box>
		</Box>
	);
};

export default observer(HeaderLayout);
