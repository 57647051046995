function Redo({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M14.1883 13.4886L17.4824 10.1945M17.4824 10.1945L14.1883 6.90039M17.4824 10.1945H8.4236C7.54994 10.1945 6.71207 10.5416 6.09431 11.1593C5.47654 11.7771 5.12948 12.615 5.12948 13.4886C5.12948 14.3623 5.47654 15.2002 6.09431 15.8179C6.71207 16.4357 7.54994 16.7827 8.4236 16.7827H9.24713"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Redo;
