import { Box } from "@mui/material";
import CopyToClipboardButton, { CopyToClipboardButtonProps } from "../CopyToClipboardButton/CopyToClipboardButton";
import styles from "./CopyToClipboard.module.scss";
interface Props {
	textToCopy?: string;
	textToShow?: string;
	textClassName?: string;
	className?: string;
	buttonProps?: CopyToClipboardButtonProps;
}

const CopyToClipboard = ({ textToCopy, textToShow = textToCopy, textClassName, className, buttonProps }: Props) => {
	return (
		<Box className={`${styles.container} ${className}`}>
			<Box className={`${styles.textToCopy} ${textClassName}`}>{textToShow}</Box>
			<CopyToClipboardButton {...buttonProps} textToCopy={textToCopy} />
		</Box>
	);
};

export default CopyToClipboard;
