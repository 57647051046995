import { ReactElement } from "react";

export interface ITailoredGenerationEndpoint {
	id: string;
	name: string;
	icon: any;
}

export interface ITailoredGenerationModel {
	id?: string;
	name: string;
	link?: string;
	description: string;
	modelType: string;
	status: string;
	dataset: ITailoredGenerationDataset;
	trainingProgress: number;
	model_url?: string;
	type: string;
}

export interface ITailoredGenerationDataset {
	id?: string;
	name: string;
	status: string;
	description: string;
	brandStyleType: string;
	assets: IDatasetImage[];
	numberOfAssets: number;
	creationDate?: string;
}

export interface IDatasetImage {
	id?: string;
	name: string;
	caption: string;
	imageUrl: string;
	imageBytes?: File;
}

export interface IGeneratedImage {
	seed: number;
	urls: string[];
	uuid: string;
	finalUrl: string;
	fake_url: boolean;
	description?: string;
}

export type DatasetTableRow = {
	datasetName: string | ReactElement;
	images: string | ReactElement;
	creationDate: string | ReactElement;
	status: string | ReactElement;
	actions: ReactElement;
};

export const TailoredGenerationModelType = {
	BRIA_2_3: {
		name: "BRIA 2.3",
		imageSource: "",
	},
};

export const TailoredGenerationBrandStyle = {
	SELECT_OPTION: {
		name: "Select Option",
		value: "Select Option",
	},
	VECTOR_ART: {
		name: "Vector Art",
		value: "VECTOR ART",
	},
	GAMING: {
		name: "Gaming",
		value: "GAMING",
	},
	CHARACTER: {
		name: "Character",
		value: "CHARACTER",
	},
};
