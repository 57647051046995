import { Box, List, ListItem, ListItemText } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import {
	DragDropContext,
	DragDropContextProps,
	Draggable,
	DraggableProps,
	DraggableProvided,
	DropResult,
	Droppable,
	DroppableProps,
	DroppableProvided,
} from "react-beautiful-dnd";
import trash from "../../../../../../../assets/svgs/trash.svg";
import DotsVertical from "../../../../../../../components/Icons/DotsVertical";
import Eye from "../../../../../../../components/Icons/Eye";
import EyeCrossed from "../../../../../../../components/Icons/EyeCrossed";
import Locked from "../../../../../../../components/Icons/Locked";
import Unlocked from "../../../../../../../components/Icons/Unlocked";
import { useActiveObject } from "../../../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../../../hooks/useEditor";
import { ILayer, IStaticText, LayerType } from "../../../../../../../types";
import styles from "./DraggableList.module.scss";
interface DraggableListProps {
	layerObjects: ILayer[];
	onSelect: (id: string) => void;
	onToggleLock: (id: string, locked: boolean) => void;
	onToggleVisibility: (id: string, visible?: boolean) => void;
	onRemove: (id: string) => void;
	onDragEnd: (newLayerObjects: ILayer[]) => void;
}
const DraggableList = ({
	layerObjects,
	onSelect,
	onToggleLock,
	onToggleVisibility,
	onRemove,
	onDragEnd,
}: DraggableListProps) => {
	const Draggable1 = Draggable as React.ComponentClass<DraggableProps>;
	const DragDropContext1 = DragDropContext as React.ComponentClass<DragDropContextProps>;
	const Droppable1 = Droppable as React.ComponentClass<DroppableProps>;

	const [items, setItems] = useState<ILayer[]>(layerObjects);
	const editor = useEditor();
	const activeObject = useActiveObject() as Required<any>;
	useEffect(() => {
		setItems(layerObjects);
	}, [layerObjects]);
	const handleOnDragEnd = (result: DropResult) => {
		if (!result.destination) return;

		const reorderedItems = Array.from(items);
		const [movedItem] = reorderedItems.splice(result.source.index, 1);
		reorderedItems.splice(result.destination.index, 0, movedItem);
		const draggedItemId = result.draggableId;
		const draggedItem = reorderedItems.find((item) => item.id === draggedItemId);
		if (draggedItem && editor?.canvas?.canvas) {
			const canvasObject = editor.canvas.canvas.getObjects().find((obj) => obj.id === draggedItem.id);
			canvasObject?.moveTo(result.destination.index + 2);
		}

		setItems(reorderedItems);
		onDragEnd(reorderedItems);
	};

	return (
		<DragDropContext1 onDragEnd={handleOnDragEnd}>
			<Droppable1 droppableId="droppable">
				{(provided: DroppableProvided) => (
					<List className={styles.droppableList} {...provided.droppableProps} ref={provided.innerRef}>
						{items?.map((item: ILayer, index: number) => (
							<Draggable1 key={item.id} draggableId={item.id} index={index}>
								{(provided: DraggableProvided) => (
									<ListItem
										className={`${styles.listItem}`}
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										sx={{
											marginBottom: 1,
											display: "grid",
											gridTemplateColumns: "1fr 90px",
										}}
									>
										<Box
											className={` ${styles.layer} ${
												activeObject?.id === item.id ? styles.activeLayer : ""
											}`}
										>
											<Box className={styles.layerNameContainer}>
												<DotsVertical className={styles.dotsVertical} />
												<ListItemText
													className={styles.layerName}
													primary={
														item.type === LayerType.STATIC_TEXT
															? (item as IStaticText).text.length > 15
																? (item as IStaticText).text.slice(0, 15) + "..."
																: (item as IStaticText).text
															: item?.type
													}
													onClick={() => onSelect(item.id)}
												/>
											</Box>
											<Box className={styles.actionsContainer}>
												{
													// @ts-ignore
													<Box onClick={() => onToggleLock(item.id, item.locked)}>
														{
															// @ts-ignore
															item.locked ? <Locked size={24} /> : <Unlocked size={24} />
														}
													</Box>
												}
												<Box onClick={() => onToggleVisibility(item.id, item.visible)}>
													{item.visible ? <Eye size={24} /> : <EyeCrossed size={24} />}
												</Box>
												<img onClick={() => onRemove(item.id)} src={trash} alt="" />
											</Box>
										</Box>
									</ListItem>
								)}
							</Draggable1>
						))}
					</List>
				)}
			</Droppable1>
		</DragDropContext1>
	);
};

const ObservedComponent = observer(DraggableList);
export default ObservedComponent;
