import { ChevronRight } from "@mui/icons-material";
import clsx from "clsx";
import styles from "./ContextMenu.module.scss";

type ContextMenuItemProps = {
	icon?: React.ReactNode;
	label?: string;
	onClick: () => void;
	disabled?: boolean;
	children?: React.ReactNode;
	isSubMenu?: boolean;
	iconOnly?: boolean;
	Selected?: boolean;
};

function ContextMenuItem({
	icon,
	label,
	onClick,
	children,
	disabled = false,
	isSubMenu = false,
	Selected = false,
	iconOnly = false,
}: ContextMenuItemProps) {
	return (
		<div
			onClick={onClick}
			className={clsx(styles.menuItem, {
				[styles.hasSubMenu]: isSubMenu,
				[styles.disabled]: disabled,
				[styles.iconOnly]: iconOnly,
				[styles.selected]: Selected,
			})}
		>
			{children} {icon} {label && <span className={styles.menuItemLabel}>{label}</span>}
			{isSubMenu && <ChevronRight className={styles.subMenuLeftIcon} />}
		</div>
	);
}

export default ContextMenuItem;
