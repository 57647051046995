import { Box, Dialog, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import welcomeAiEditor from "../../../assets/images/welcome-ai-editor.png";
import welcomeDesignEditor from "../../../assets/images/welcome-design-editor.png";
import welcomeImageToImage from "../../../assets/images/welcome-image-to-image.png";
import welcomeTextToImage from "../../../assets/images/welcome-text-to-image.png";
import welcomeTextToVector from "../../../assets/images/welcome-text-to-vector.png";
import RouterConstants from "../../../constants/RouterConstants";
import BriaButton from "../BriaButton/BriaButton";
import BriaButtonGroup from "../BriaButtonGroup/BriaButtonGroup";
import styles from "./AppsWelcomePopup.module.scss";

const welcomePopoupImages = {
	[RouterConstants.TEXT_TO_IMAGE.path]: welcomeTextToImage,
	[RouterConstants.TEXT_TO_VECTOR.path]: welcomeTextToVector,
	[RouterConstants.IMAGE_TO_IMAGE.path]: welcomeImageToImage,
	[RouterConstants.AI_EDITOR.path]: welcomeAiEditor,
	[RouterConstants.DESIGN_EDITOR.path]: welcomeDesignEditor,
};

type AppsWelcomePopupProps = {
	open: boolean;
	onClose: () => void;
};

export default function AppsWelcomePopup({ open, onClose }: AppsWelcomePopupProps) {
	const { pathname } = useLocation();
	const route = pathname.split("/")[2];
	const { t } = useTranslation("translation", { keyPrefix: `welcomePopups.apps.${route}` });

	return (
		<Dialog
			classes={{
				paper: styles.paper,
			}}
			onClose={onClose}
			open={open}
			maxWidth={false}
		>
			<Box className={styles.container}>
				<Box className={styles.content}>
					<Typography className={styles.title}>
						<Trans i18nKey={t("title")} />
					</Typography>
					<Typography className={styles.body}>
						<Trans i18nKey={t("body")} />
					</Typography>
					<BriaButtonGroup className={styles.actionBtns}>
						{t("buttons.primary", { defaultValue: "" }) && (
							<BriaButton onClick={onClose} buttonType="primaryMedium">
								<Trans i18nKey={t("buttons.primary")} />
							</BriaButton>
						)}
						{t("buttons.secondary", { defaultValue: "" }) && (
							<BriaButton className={styles.actionBtn} onClick={onClose} buttonType="tertiaryMedium">
								{t("buttons.secondary")}
							</BriaButton>
						)}
					</BriaButtonGroup>
				</Box>
				<Box className={styles.imageContainer}>
					<img src={welcomePopoupImages[route]} />
				</Box>
			</Box>
		</Dialog>
	);
}
