import { Box } from "@mui/material";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { StatefulTooltip } from "baseui/tooltip";
import Icons from "../../../../../../components/Icons/index.ts";
import { useEditor } from "../../../../../../hooks/useEditor.tsx";
import styles from "./Align.module.scss";

export default function () {
	const ICON_SIZE = 20;
	const editor = useEditor();

	return (
		<StatefulPopover
			placement={PLACEMENT.bottomRight}
			overrides={{
				Body: {
					props: {
						className: styles.popoverBody,
					},
				},
			}}
			content={() => (
				<Box className={styles.alignment}>
					<Box onClick={() => editor?.objects.alignLeft()}>
						<Icons.AlignLeft size={ICON_SIZE} />
					</Box>
					<Box onClick={() => editor?.objects.alignCenter()}>
						<Icons.AlignCenter size={ICON_SIZE} />
					</Box>
					<Box onClick={() => editor?.objects.alignRight()}>
						<Icons.AlignRight size={ICON_SIZE} />
					</Box>
					<Box onClick={() => editor?.objects.alignTop()}>
						<Icons.AlignTop size={ICON_SIZE} />
					</Box>
					<Box onClick={() => editor?.objects.alignMiddle()}>
						<Icons.AlignMiddle size={ICON_SIZE} />
					</Box>
					<Box onClick={() => editor?.objects.alignBottom()}>
						<Icons.AlignBottom size={ICON_SIZE} />
					</Box>
				</Box>
			)}
			returnFocus
			autoFocus
		>
			<Box>
				<StatefulTooltip
					placement={PLACEMENT.bottom}
					showArrow={true}
					accessibilityType={"tooltip"}
					content="Align"
				>
					<Box>
						<Icons.Alignment size={ICON_SIZE} />
					</Box>
				</StatefulTooltip>
			</Box>
		</StatefulPopover>
	);
}
