import { Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ORG_SUBSCRIPTION_PLANS } from "../../../../constants/billing.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import ConfirmationPopup from "../../../common/ConfirmationPopup/ConfirmationPopup.tsx";
import { PRICING_STEPS } from "../store.tsx";
import styles from "./SubscriptionCancelledPopup.module.scss";

const SubscriptionCancelledPopup = () => {
	const { t } = useTranslation("translation", { keyPrefix: "pricingFlow.subscriptionCancelledPopup" });
	const { pricingStore, authStore } = useAppStore();

	return (
		<ConfirmationPopup
			onClose={pricingStore.closePricingFlow}
			paperClassName={clsx(styles.cancelledSubscriptionPopup, styles.popupPaper)}
			title={t("title", {
				plan: authStore.orgSubscription?.plan_name ?? ORG_SUBSCRIPTION_PLANS.free.name,
			})}
			description={
				<Typography className={styles.description}>
					{t("description", {
						cancels_on: authStore.orgSubscription?.cancels_on,
					})}
				</Typography>
			}
			subDescription={<Typography className={styles.boldDescription}>{t("subDescription")}</Typography>}
			confirmButtonText={t("buttonText")}
			open={pricingStore.activeStep === PRICING_STEPS.SUBSCRIPTION_CANCELLED_POPUP}
			hideCancel={true}
			loadingBody={authStore.isLoadingOrgSubscriptions}
			onClick={() => {
				pricingStore.closePricingFlow();
			}}
		/>
	);
};

export default observer(SubscriptionCancelledPopup);
