import { Block } from "baseui/block";
import { Button, KIND, SIZE } from "baseui/button";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import { useContext } from "react";
import UnlockedIcon from "../../../../components/Icons/Unlocked";
import { DesignEditorContext } from "../../../../contexts/DesignEditor.tsx";
import { useEditor } from "../../../../hooks/useEditor";

export default function () {
	const editor = useEditor();
	const { isPopupView } = useContext(DesignEditorContext);

	return (
		<Block
			$style={{
				flex: 1,
				display: isPopupView ? "none" : "flex",
				alignItems: "center",
				padding: "0 12px",
				justifyContent: "flex-end",
			}}
		>
			<StatefulTooltip
				placement={PLACEMENT.bottom}
				showArrow={true}
				accessibilityType={"tooltip"}
				content="Unlock"
			>
				<Button
					onClick={() => {
						editor?.objects.unlock();
					}}
					size={SIZE.mini}
					kind={KIND.tertiary}
				>
					<UnlockedIcon size={24} />
				</Button>
			</StatefulTooltip>
		</Block>
	);
}
