import { Block } from "baseui/block";
import { Button, KIND, SIZE } from "baseui/button";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { StatefulTooltip } from "baseui/tooltip";
import React from "react";
import FlipHorizontal from "../../../../../components/Icons/FlipHorizontal";
import FlipVertical from "../../../../../components/Icons/FlipVertical";
import { useActiveObject } from "../../../../../hooks/useActiveObject";
import { useEditor } from "../../../../../hooks/useEditor";

export default function () {
	const editor = useEditor();
	const activeObject = useActiveObject() as any;
	const [state, setState] = React.useState({ flipX: false, flipY: false });

	React.useEffect(() => {
		if (activeObject) {
			setState({
				flipX: activeObject.flipX,
				flipY: activeObject.flipY,
			});
		}
	}, [activeObject]);

	const flipHorizontally = React.useCallback(() => {
		editor?.objects.update({ flipX: !state.flipX });
		setState({ ...state, flipX: !state.flipX });
	}, [editor, state]);

	const flipVertically = React.useCallback(() => {
		editor?.objects.update({ flipY: !state.flipY });
		setState({ ...state, flipY: !state.flipY });
	}, [editor, state]);

	return (
		<StatefulPopover
			placement={PLACEMENT.bottom}
			content={() => (
				<Block width={"180px"} padding={"12px"} backgroundColor={"#ffffff"}>
					<Block>
						<Button
							style={{ width: "100%", justifyContent: "flex-start" }}
							startEnhancer={<FlipHorizontal size={24} />}
							onClick={flipHorizontally}
							kind={KIND.tertiary}
							size={SIZE.mini}
						>
							Flip horizontally
						</Button>
					</Block>
					<Button
						style={{ width: "100%", justifyContent: "flex-start" }}
						startEnhancer={<FlipVertical size={24} />}
						onClick={flipVertically}
						kind={KIND.tertiary}
						size={SIZE.mini}
					>
						Flip vertically
					</Button>
				</Block>
			)}
		>
			<Block>
				<StatefulTooltip
					placement={PLACEMENT.bottom}
					showArrow={true}
					accessibilityType={"tooltip"}
					content="Layers"
				>
					<Button size={SIZE.compact} kind={KIND.tertiary}>
						Flip
					</Button>
				</StatefulTooltip>
			</Block>
		</StatefulPopover>
	);
}
