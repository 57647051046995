import { useCallback } from "react";

type ColorInput = string;

export const useCustomColorPicker = () => {
	const componentToHex = useCallback((c: number): string => {
		const hex = c.toString(16);
		return hex.length === 1 ? "0" + hex : hex;
	}, []);

	const rgbToHex = useCallback(
		(r: number, g: number, b: number): string => {
			return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
		},
		[componentToHex],
	);

	const rgbaToHex = useCallback(
		(r: number, g: number, b: number, a: number): string => {
			const alpha = Math.round(a * 255);
			return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b) + componentToHex(alpha);
		},
		[componentToHex],
	);

	const parseRgbString = useCallback((rgb: string): [number, number, number] | null => {
		const result = rgb.match(/^rgb\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*\)$/);
		return result ? [parseInt(result[1]), parseInt(result[2]), parseInt(result[3])] : null;
	}, []);

	const parseRgbaString = useCallback((rgba: string): [number, number, number, number] | null => {
		const result = rgba.match(
			/^rgba\(\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([0-9]{1,3})\s*,\s*([01]?(\.\d+)?)\s*\)$/,
		);
		return result ? [parseInt(result[1]), parseInt(result[2]), parseInt(result[3]), parseFloat(result[4])] : null;
	}, []);

	const colorToHex = useCallback(
		(color: ColorInput): string | null => {
			if (color.startsWith("rgb(")) {
				const rgb = parseRgbString(color);
				if (rgb) {
					return rgbToHex(rgb[0], rgb[1], rgb[2]);
				}
			} else if (color.startsWith("rgba(")) {
				const rgba = parseRgbaString(color);
				if (rgba) {
					return rgbaToHex(rgba[0], rgba[1], rgba[2], rgba[3]);
				}
			} else if (color.startsWith("linear-gradient(") || color.startsWith("radial-gradient(")) {
				// Handle gradients (simplified example, converting first color stop)
				const firstColor = color.match(/(rgb[a]?\([^\)]+\))/i);
				if (firstColor) {
					return colorToHex(firstColor[0]);
				}
			} else if (/^#[0-9A-F]{6}$/i.test(color)) {
				// Already in hex
				return color;
			}
			// Handle other cases (like named colors) or return null if not convertible
			return null;
		},
		[parseRgbString, parseRgbaString, rgbToHex, rgbaToHex],
	);

	return { colorToHex };
};
