import Box from "@mui/material/Box";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import LoadingAnimation, { LoadingAnimationProps } from "../LoadingAnimation/LoadingAnimation";
import styles from "./LoadingOverlay.module.scss";

interface IProps extends LoadingAnimationProps {
	children: React.ReactNode;
	className?: string;
	coverImageUrl?: string;
}

const LoadingOverlay = ({ children, className, coverImageUrl, loading, ...props }: IProps) => {
	return (
		<Box className={clsx(className, styles.container)}>
			<Box className={clsx({ [styles.hidden]: !loading }, styles.loadingContainer)}>
				<LoadingAnimation loading={loading} {...props} />
			</Box>
			{loading && coverImageUrl && (
				<Box className={clsx({ [styles.loading]: loading }, styles.loadingImageContainer)}>
					<img
						className={clsx(styles.loadingImage)}
						style={{
							backgroundImage: `url(${coverImageUrl})`,
						}}
					/>
				</Box>
			)}
			<Box
				className={clsx(
					{
						[styles.loading]: loading,
						[styles.hiddenVisibility]: !!coverImageUrl && loading,
					},
					styles.contentContainer,
				)}
			>
				{children}
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(LoadingOverlay);
export default ObservedComponent;
