import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import RouterConstants from "../../../../constants/RouterConstants";
import { useAppStore } from "../../../../hooks/useStores";
import styles from "./ProductImageOverlay.module.scss";

export type ProductImageOverlayProps = {
	image: { url: string; id: string };
};

const ProductImageOverlay = ({ image }: ProductImageOverlayProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "galleryHub" });
	const { productPlacementStore } = useAppStore();

	return (
		<Box
			className={styles.overlay}
			onClick={async (e) => {
				await productPlacementStore.setPlaygroundResult(image);
				navigate(`/${RouterConstants.PRODUCT_PLACEMENT.fullPath}`);
				e?.stopPropagation();
			}}
		>
			<Box className={styles.overlayContent}>
				<Box className={styles.buttonsContainer}>
					<BriaButton className={styles.button}> {t("Generate")} </BriaButton>
				</Box>
			</Box>
		</Box>
	);
};

export default ProductImageOverlay;
