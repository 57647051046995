import { Close } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress, Dialog, IconButton, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import CreateOrganizationImage from "../../../assets/images/svgs/create-organization.svg";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import LinkConstants from "../../../constants/LinkConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { EventsLogEnum } from "../../../models/common.ts";
import BriaButton from "../BriaButton/BriaButton.tsx";
import styles from "./CreateOrganizationPopup.module.scss";

interface IProps {
	open: boolean;
	onClose: () => void;
}

export default function CreateOrganizationPopup({ open, onClose }: IProps) {
	const initialValues = {
		organization: "",
	};
	const { authStore, appStore } = useAppStore();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState();

	const handleSubmit = async (values: any) => {
		let response = null;
		setLoading(true);
		try {
			setErrorMessage(undefined);
			response = await authStore.createOrganization(values.organization);
			await authStore.loadUserData();
			if (response.type === "success" && authStore.userOrganizations.length > 0) {
				appStore.logEvent({ event: EventsLogEnum.NEW_USER });
				window.location.href = localStorage.getItem("destination_route") || RouterConstants.CONSOLE.fullPath;
			} else if (response.type === "failed") {
				setErrorMessage(response.message ?? t("pleaseTryAgainLater"));
			}
		} catch (e) {
			setErrorMessage(response?.message ?? t("pleaseTryAgainLater"));
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog
			classes={{
				paper: styles.paper,
			}}
			onClose={onClose}
			open={open}
			maxWidth={false}
		>
			<Box className={styles.container}>
				<IconButton onClick={onClose} className={styles.closeButton}>
					<Close />
				</IconButton>
				<Box className={styles.content}>
					<Typography className={styles.title}>{t("createYourOrganization")}</Typography>
					<Box className={styles.descriptionContainer}>
						<Typography className={clsx(styles.description, styles.firstDescription)}>
							{t("createYourOrganizationDescription5")}
						</Typography>
						<Typography className={styles.description}>
							{t("createYourOrganizationDescription3")}
							<Link
								onClick={(_e: React.MouseEvent<any>) => {
									window.open(LinkConstants.CONTACT_US_FORM_LINK, "_blank");
								}}
								className={styles.link}
							>
								{t("contact us.")}
							</Link>
						</Typography>
					</Box>
					<Formik
						initialValues={initialValues}
						validateOnMount={false}
						validateOnChange={false}
						validateOnBlur={false}
						validationSchema={Yup.object().shape({
							organization: Yup.string().required(t("organizationIsRequired")),
						})}
						onSubmit={handleSubmit}
					>
						{({ errors, touched, handleChange, handleBlur }) => (
							<Form noValidate>
								<Box className={styles.formContainer}>
									<BriaInput
										id="organization"
										label={t("createYourOrganizationPopup.organizationName")}
										size="small"
										onChange={handleChange}
										error={(Boolean(errors.organization) && touched.organization) as boolean}
										onBlur={handleBlur}
										helperText={touched.organization ? errors.organization : ""}
										className={styles.textField}
									/>
									<BriaButton
										buttonType="primary"
										type="submit"
										color="secondary"
										className={styles.submitButton}
									>
										{t("createOrganization")}
										{loading && (
											<Box className={styles.circleLoader}>
												<CircularProgress color="inherit" size={18} />
											</Box>
										)}
									</BriaButton>
									{errorMessage && (
										<Typography className={styles.error} color="secondary">
											<ErrorIcon className={styles.errorIcon} />
											{errorMessage}
										</Typography>
									)}
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
				<Box className={styles.imageContainer}>
					<img alt="Create Organization image" src={CreateOrganizationImage} />
				</Box>
			</Box>
		</Dialog>
	);
}
