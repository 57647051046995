import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function TextRightIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 18 14" {...props}>
			<path
				d="M1 1H17M7 7H17M3 13H17"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default TextRightIcon;
