import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Dialog } from "@mui/material";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { observer } from "mobx-react-lite";
import { ENV_VARS } from "../../../../config/env.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import styles from "./CheckoutPopup.module.scss";

const stripePromise = loadStripe(ENV_VARS.VITE_STRIPE_PUBLISHABLE_KEY, {});

interface IProps {
	open: boolean;
	onClose: () => void;
}

const CheckoutPopup = ({ open, onClose }: IProps) => {
	const { pricingStore } = useAppStore();

	return open ? (
		<Dialog
			classes={{
				paper: `${styles.container}`,
			}}
			onClose={onClose}
			open={open}
			maxWidth={false}
		>
			<CloseIcon onClick={onClose} className={styles.closeIcon} />

			<EmbeddedCheckoutProvider
				stripe={stripePromise}
				options={{
					fetchClientSecret: pricingStore.fetchClientSecret,
				}}
			>
				<EmbeddedCheckout />
			</EmbeddedCheckoutProvider>
		</Dialog>
	) : (
		<></>
	);
};

export default observer(CheckoutPopup);
