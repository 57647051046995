import { Alert, Box, Snackbar } from "@mui/material";
import i18n from "i18next";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import useDesignEditorUtils from "../../../DesignEditor/CustomUtils/UseDesignEditor.tsx";
import { DesignEditorContext } from "../../../DesignEditor/contexts/DesignEditor.tsx";
import { useEditor } from "../../../DesignEditor/hooks/useEditor.tsx";
import { IScene } from "../../../DesignEditor/types";
import { ObjectsEnum } from "../../../DesignEditor/views/DesignEditor/components/Panels/panelItems/index.ts";
import { useSmartImageUtils } from "../../../DesignEditor/views/DesignEditor/utils/smartImageUtils.ts";
import { ANALYTICS_EVENTS } from "../../../analytics-store.tsx";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import BriaButtonGroup from "../../../components/common/BriaButtonGroup/BriaButtonGroup";
import ConfirmationPopup from "../../../components/common/ConfirmationPopup/ConfirmationPopup";
import FileUploader from "../../../components/common/FileUploader/FileUploader";
import FullScreenViewer from "../../../components/common/FullScreenViewer/FullScreenViewer";
import useSelectable from "../../../components/common/Selectable/useSelectable";
import { APPS } from "../../../constants/AppsConstants.ts";
import LinkConstants from "../../../constants/LinkConstants.ts";
import { useAppStore } from "../../../hooks/useStores";
import { ImageToImageConfigType } from "../../../models/image-to-image";
import { SaveImagesLoadingState } from "../playground-store.tsx";
import styles from "./PlaygroundActionsHeader.module.scss";

const PlaygroundActionsHeader = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.actionsHeader" });
	const { analyticsStore, playgroundStore, imageToImageStore } = useAppStore();
	const { selectAll } = useSelectable(playgroundStore.getSelecto());
	const selectedImages = playgroundStore.getSelectedImages();
	const availableImages = playgroundStore.getAvailableImages();
	const [clearWarningPopup, setClearWarningPopup] = useState(false);
	const [saveToMyCampaignLoading, setSaveToMyCampaignLoading] = useState(false);
	const [isViewerOpen, setIsViewerOpen] = useState(false);
	const { addImageObjectToCanvas } = useDesignEditorUtils();
	const { isPopupView, scenes, setCurrentScene, setScenes } = useContext(DesignEditorContext);
	const editor = useEditor();
	const { uploadAndRegisterBackgroundImages, resetRectangleInAGroup } = useSmartImageUtils();
	const handleSelectAll = () => {
		selectAll();
		playgroundStore.handleSelectImagesElements(playgroundStore.getSelecto()?.getSelectedTargets());
	};

	const downloadImages = async () => {
		selectedImages.length
			? playgroundStore.downloadImages(selectedImages)
			: playgroundStore.downloadImages(availableImages);

		(selectedImages.length ? selectedImages : availableImages).forEach((image) => {
			if (image.type === "imageToImage") {
				analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_ITI, image);
			}
			if (image.type === "productPlacement") {
				analyticsStore.logEvent(ANALYTICS_EVENTS.IMAGE_DOWNLOAD_PP);
			}
		});
	};

	const SaveTheImageToMyAssetsAndAdditToCanvas = async () => {
		await playgroundStore.saveMyImages(SaveImagesLoadingState.SaveAndAddToCanvas);
		for (const vhash of playgroundStore.uploadedImagesVhash) {
			addImageObjectToCanvas({
				url: LinkConstants.BRIA_IMAGES_URL + "images/" + vhash + ".png",
				id: vhash,
				visual_hash: vhash,
			});
		}
		setTimeout(() => {
			playgroundStore.showSuccessToastSavedImage = false;
			playgroundStore.closeModal();
		}, 1000);
		playgroundStore.uploadedImagesVhash = [];
	};

	const saveTextToImageResultToCampaign = async () => {
		setSaveToMyCampaignLoading(true);
		const selectedImages = playgroundStore.getSelectedImages();
		const firstSelectedImage = selectedImages[0];
		const url = firstSelectedImage.url;
		if (editor) {
			const _currentScene = editor.scene.exportToJSON();
			await uploadAndRegisterBackgroundImages({} as ChangeEvent<HTMLInputElement>, url, _currentScene);
			await resetRectangleInAGroup(_currentScene, ObjectsEnum.OuterRectangle);

			const updatedPreview = (await editor?.renderer.render(_currentScene)) as string;
			const updatedScene = { ..._currentScene, preview: updatedPreview, duration: 1000 };
			const updatedScenes = scenes.map((scene: IScene) => {
				if (scene.id === updatedScene.id) {
					return { ...updatedScene, preview: updatedPreview };
				}
				return editor.scene.formalizeSceneAttributes(scene);
			}) as IScene[];
			setScenes(updatedScenes);
			setCurrentScene(updatedScene);
		}

		playgroundStore.showSuccessToastSavedImage = false;
		playgroundStore.closeModal();

		setSaveToMyCampaignLoading(false);
	};

	const hideToastHandler = () => {
		playgroundStore.showSuccessToastSavedImage = false;
	};

	return (
		<Box className={styles.root}>
			<BriaButtonGroup>
				<BriaButton
					className={styles.transparentColor}
					buttonType="textSmall"
					onClick={() => playgroundStore.playgroundResults.length && setClearWarningPopup(true)}
				>
					{t("clear")}
				</BriaButton>
				<ConfirmationPopup
					open={clearWarningPopup}
					title={t("clearWarning.title")}
					description={t("clearWarning.description")}
					confirmButtonText={t("clearWarning.confirmBtn")}
					firstButtonText={t("clearWarning.closeBtn")}
					onClick={() => {
						playgroundStore.clearResults();
						setClearWarningPopup(false);
					}}
					onClose={() => setClearWarningPopup(false)}
				/>
				{playgroundStore.selectedConfig !== APPS.PRODUCT_PLACEMENT && !isPopupView && (
					<BriaButton className={styles.transparentColor} buttonType="textSmall" onClick={handleSelectAll}>
						{t("selectAll")}
					</BriaButton>
				)}
				{playgroundStore.selectedConfig !== APPS.PRODUCT_PLACEMENT &&
					selectedImages.length > 0 &&
					!isPopupView && (
						<BriaButton
							className={styles.transparentColor}
							buttonType="textSmall"
							onClick={playgroundStore.openSelected}
						>
							{t("openSelected", {
								countSelected: selectedImages.length,
								countAvailable: availableImages.length,
							})}
						</BriaButton>
					)}
			</BriaButtonGroup>
			<BriaButtonGroup>
				{selectedImages.length > 0 && (
					<BriaButton
						className={styles.transparentColor}
						buttonType="textSmall"
						onClick={() => setIsViewerOpen(true)}
					>
						{t("fullScreenView.button")}
					</BriaButton>
				)}
				<BriaButton
					className={styles.transparentColor}
					buttonType="textSmall"
					onClick={downloadImages}
					loading={playgroundStore.loadingDownload}
				>
					{t("downloadSelected", { count: selectedImages.length })}
				</BriaButton>
				{playgroundStore?.isText2ImagePopupOpened && !isPopupView && (
					<Box className={styles.saveButtonsForPopup}>
						<BriaButton
							buttonType="secondaryMedium"
							onClick={async () => {
								await playgroundStore.saveMyImages(SaveImagesLoadingState.Save);
								playgroundStore.uploadedImagesVhash = [];
							}}
							loading={playgroundStore?.loadingSaveToMyImages}
							className={styles.saveandAddMyImageBtn}
							disabled={selectedImages.length == 0}
						>
							{t("saveToMyAssets")}
						</BriaButton>
						<BriaButton
							buttonType="primary"
							onClick={SaveTheImageToMyAssetsAndAdditToCanvas}
							loading={playgroundStore?.loadingSaveToMyImagesAndInsertImageToAd}
							className={styles.saveMyImagesBtn}
							disabled={selectedImages.length == 0}
						>
							{t("insertAndClose")}
						</BriaButton>
					</Box>
				)}
				{playgroundStore?.isText2ImagePopupOpened && isPopupView && (
					<Box className={styles.saveButtonsForPopup}>
						<BriaButton
							buttonType="primary"
							onClick={saveTextToImageResultToCampaign}
							loading={saveToMyCampaignLoading}
							className={styles.saveMyImagesBtn}
							disabled={selectedImages.length == 0 || saveToMyCampaignLoading}
						>
							{t("saveToMyCampaign")}
						</BriaButton>
					</Box>
				)}
				{playgroundStore.selectedConfig === "imageToImage" && (
					<FileUploader
						buttonText={t("upload")}
						buttonType="secondaryMedium"
						onUpload={imageToImageStore.handleUploadImages}
						inputProps={{ accept: "image/png, image/jpeg, image/jpg", multiple: true }}
						buttonProps={{ className: styles.uploadBtn }}
						maxFilesLimit={imageToImageStore.MAX_FILES_LIMIT}
						// maxFileSize={imageToImageStore.MAX_FILE_SIZE}
					/>
				)}
			</BriaButtonGroup>
			<Snackbar
				autoHideDuration={2000}
				onClose={hideToastHandler}
				open={playgroundStore.showSuccessToastSavedImage}
				className={styles.saveSuccessToast}
			>
				<Alert onClose={hideToastHandler} severity="success">
					{t("saveImageSuccess")}
				</Alert>
			</Snackbar>
			{isViewerOpen && (
				<FullScreenViewer
					open={isViewerOpen}
					images={playgroundStore.getSelectedImages().map((image) => ({
						src: image.url,
						fileName: playgroundStore.getFileName(image),
						compareTo: (image.config as ImageToImageConfigType)?.original_image?.url,
						compareLabel:
							image.type === "imageToImage"
								? `${i18n.t("playground.imageToImage.result.gallery.variationLabel", {
										variationNum: image.variationNum?.toString().padStart(2, "0"),
								  })}`
								: t(`fullScreenView.${image.type}.compareLabel`, { defaultValue: "" }),
						compareToLabel: t(`fullScreenView.${image.type}.compareToLabel`),
					}))}
					onClose={() => setIsViewerOpen(false)}
					displayMode="scroll"
				/>
			)}
		</Box>
	);
};

export default observer(PlaygroundActionsHeader);
