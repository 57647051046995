import { BriaAxios } from "../config/axios.tsx";
import { IEventsLog } from "../models/eventsLog.ts";

const BASE_URL = "/events";

class EventsLogService {
	logEvent = async (model: IEventsLog) => {
		const response = await (await BriaAxios()).post(`${BASE_URL}/`, model);
		return response.data;
	};
}

export default EventsLogService;
