function Alignment({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.375 2V18M8.375 4H11.375C12.4796 4 13.375 4.89543 13.375 6V7C13.375 8.10457 12.4796 9 11.375 9H8.375C7.27043 9 6.375 8.10457 6.375 7V6C6.375 4.89543 7.27043 4 8.375 4ZM8.375 12H16.375C17.4796 12 18.375 12.8954 18.375 14V15C18.375 16.1046 17.4796 17 16.375 17H8.375C7.27043 17 6.375 16.1046 6.375 15V14C6.375 12.8954 7.27043 12 8.375 12Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Alignment;
