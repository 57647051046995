import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { TextTypeClass, textStyles } from "../../constants/TextTypes";

const useStyles = makeStyles((_theme) => ({
	...textStyles,
	textTypeBox: {
		padding: "12px 16px",
		paddingLeft: "0px",
		"&:hover": {
			background: "#E7E7E7",
			boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.02) inset",
		},
	},
}));

interface IProps {
	textTypeClass: TextTypeClass;
	label: string;
	onClick: () => void;
}
const TextBoxType = ({ textTypeClass, label, onClick }: IProps) => {
	const classes: any = useStyles();

	return (
		<>
			<Box className={classes.textTypeBox} onClick={onClick}>
				<Typography className={classes[textTypeClass]}>{label}</Typography>
			</Box>
			<Divider />
		</>
	);
};

export default TextBoxType;
