import { Box, Divider, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import { useAppStore } from "../../../../../../hooks/useStores";
import Icons from "../../../../../components/Icons";
import { PanelType } from "../../../../../constants/app-options.ts";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import { useActiveObject } from "../../../../../hooks/useActiveObject";
import useAppContext from "../../../../../hooks/useAppContext";
import { useEditor } from "../../../../../hooks/useEditor";
import useSetIsSidebarOpen from "../../../../../hooks/useSetIsSidebarOpen.tsx";
import EditAllAdsSwitch from "../../Panels/panelItems/Common/EditAllAdsSwitch/EditAllAdsSwitch";
import styles from "./Canvas.module.scss";

const Canvas = () => {
	const { campaignStore } = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);
	const [_state, setState] = React.useState({ fill: "#000000" });
	const { setActivePanel } = useAppContext();
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const editor = useEditor();
	const activeObject = useActiveObject() as any;
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.canvasControllers" });

	React.useEffect(() => {
		if (editor) {
			setState({ fill: editor.canvas.backgroundColor as string });
		}
	}, [editor]);

	React.useEffect(() => {
		const watcher = async () => {
			(campaignStore.canvasColorPicker =
				editor && editor?.frame?.background?.fill && typeof editor.frame.background.fill == "string"
					? editor.frame.background.fill
					: "#fff"),
				setState({ fill: editor?.canvas.backgroundColor as string });
		};
		if (editor) {
			editor.on("canvas:updated", watcher);
		}
		return () => {
			if (editor) {
				editor.off("canvas:updated", watcher);
			}
		};
	}, [editor, activeObject]);

	return (
		<Box
			className={clsx({
				[styles.canvasControlsContainer]: true,
				[styles.hide]: isPopupView,
			})}
		>
			<Box className={styles.canvasControlsWrapper}>
				<BriaButton
					buttonType="textMedium"
					onClick={() => {
						setIsSidebarOpen(true);
						setActivePanel(PanelType.RESIZE);
					}}
					className={styles.ControlButtonWrapper}
				>
					<Icons.Resize size={24} />
					<Typography className={styles.buttonLabel}>{t("Resize")}</Typography>
				</BriaButton>
				<Divider
					className={clsx({
						[styles.divider]: true,
					})}
					orientation={"vertical"}
				/>
				<Box
					style={{
						backgroundColor: campaignStore.canvasColorPicker,
						background: campaignStore.canvasColorPicker,
						opacity: editor?.frame?.background?.opacity,
					}}
					onClick={() => {
						setIsSidebarOpen(true);
						setActivePanel(PanelType.CANVASCOLOR);
					}}
					className={styles.colorBox}
				></Box>
			</Box>

			<Box>
				<EditAllAdsSwitch />
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(Canvas);
export default ObservedComponent;
