import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./InsertText.module.scss";

const InsertText = () => {
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.insertText" });

	return (
		<Box className={styles.container}>
			<Typography className={styles.title}> {t("insertText")} </Typography>
			<Typography className={styles.description}>{t("descriptions1")}</Typography>
			<Typography className={styles.description}> {t("descriptions2")}</Typography>
		</Box>
	);
};

export default InsertText;
