import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../components/common/BriaTable/BriaTable";
import useErrorToast from "../hooks/useErrorToast";
import { PaginatedItems } from "../models/common";
import { Font } from "../models/font";
import QueryService from "../utils/QueryService";
export interface IBrandFontsStore {
	paginatedBrandFonts: PaginatedItems<Font>;
	brandOrgFonts: Font[];
	rowsPerPage: number;
	isLoading: boolean;
	isError: boolean;
	getBrandFont(fontId: number): Promise<Font>;
	loadBrandFonts(includePublic?: boolean, filterBy?: FilterByOptions): Promise<void>;
	getAllOrgBrandFonts(includePublic?: boolean): Promise<void>;
}

export default class BrandFontsStore implements IBrandFontsStore {
	private queryService: QueryService = new QueryService("/brands-definition/fonts");
	errorToast = useErrorToast();
	paginatedBrandFonts: PaginatedItems<Font> = { total: 0, items: [] };
	brandOrgFonts: Font[] = [];
	rowsPerPage: number = 20;
	isLoading: boolean = false;
	isError: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	loadBrandFonts = async (includePublic: boolean = false, filterBy?: FilterByOptions): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			const fonts: Font[] = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					include_public: includePublic,
				},
			});

			runInAction(() => {
				this.brandOrgFonts = fonts;
				this.isError = false;
				this.isLoading = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getAllOrgBrandFonts = async (includePublic: boolean = false): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			const fonts: Font[] = await this.queryService.get("/all", {
				params: {
					include_public: includePublic,
				},
			});

			runInAction(() => {
				this.brandOrgFonts = fonts;
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getBrandFont = async (fontId: number): Promise<Font> => {
		try {
			const font: Font = await this.queryService.get(`${fontId}`);
			return font;
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});

			return Promise.reject(e);
		}
	};
}
