import { Box, Divider, Slider } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { ANALYTICS_EVENTS } from "../../../analytics-store.tsx";
import { default as DashedBoxIcon } from "../../../assets/images/icons/DashedBoxIcon.tsx";
import CompareSVG from "../../../assets/images/svgs/AIEditor/compare.svg";
import RedoSVG from "../../../assets/images/svgs/AIEditor/redo.svg";
import ResetSVG from "../../../assets/images/svgs/AIEditor/reset.svg";
import UndoSVG from "../../../assets/images/svgs/AIEditor/undo.svg";
import { useImageActions } from "../../../hooks/useImageActions.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { AIEditorOption, BoxGranularityEnum, ImageEnum } from "../../../models/aiEditor.ts";
import { PlaygroundImage } from "../../../models/image-to-image.ts";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import BriaTooltip from "../../common/BriaTooltip/BriaTooltip.tsx";
import InteractiveBriaImage from "../InteractiveBriaImage/InteractiveBriaImage.tsx";
import styles from "./ImageEditor.module.scss";

const ImageEditor = () => {
	const { playgroundStore, analyticsStore, aiEditorStore, imageToImageStore } = useAppStore();
	const imageUrl = aiEditorStore.selectedImageUrl;
	const [_isImageLoading, setIsImageLoading] = useState<boolean>(true);
	const [_downloadSaveLoading, setDownloadSaveLoading] = useState(false);
	const { doDownloadImage } = useImageActions({ setDownloadSaveLoading, setIsImageLoading, imageUrl });
	const [selectedTab, setSelectedTab] = useState<AIEditorOption | undefined>(undefined);
	const { t } = useTranslation("translation", { keyPrefix: "aiEditor.imageEditor" });

	useEffect(() => {
		const initialize = async () => {
			try {
				const tab = await aiEditorStore.getTabObjectByLabel(aiEditorStore.selectedEditorTabLabel);
				setSelectedTab(tab);
			} catch (error) {
				console.error(error);
			}
		};

		initialize();
	}, []);

	const handleUploadClick = () => {
		aiEditorStore.setProperty("openImageUploadPopup", true);
	};

	const handleDownloadClick = async () => {
		await doDownloadImage();

		const analyticsUploadedImageUrl = new URL(aiEditorStore.selectedImageUrl);
		const analyticsUploadedImageId = analyticsUploadedImageUrl.searchParams.get("imageId");
		const analyticsImage: PlaygroundImage = {
			id: uuidv4(),
			url: aiEditorStore.selectedImageUrl,
			type: "imageToImage",
			sessionId:
				aiEditorStore.selectedImageUrl.split("?")[0] +
				(analyticsUploadedImageId ? `?imageId=${analyticsUploadedImageId}` : ""),
		};

		if (analyticsUploadedImageId) {
			analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.PLAYGROUND_IMAGE_DOWNLOAD_ITI, analyticsImage);
		} else {
			const itiImage = playgroundStore
				.getAvailableImages()
				.find((image) => aiEditorStore.selectedImageUrl === image?.url);
			if (itiImage) {
				analyticsStore.logImageToImageEvent(ANALYTICS_EVENTS.PLAYGROUND_IMAGE_DOWNLOAD_ITI, itiImage);
			}
		}
	};

	const handleSaveAndCloseClick = () => {
		imageToImageStore.addAIEditorImageInARow(aiEditorStore.selectedImageUrl);
		aiEditorStore.setProperty("aiEditorPopup", false);
	};

	function getImageButtons() {
		return (
			<>
				<BriaButton
					onClick={handleUploadClick}
					className={clsx({
						[styles.button]: true,
					})}
					buttonType="textMedium"
				>
					{t("buttons.upload")}
				</BriaButton>
				<BriaButton
					disabled={!aiEditorStore.selectedImageUrl}
					onClick={handleDownloadClick}
					className={styles.button}
					buttonType={aiEditorStore.aiEditorPopup ? "textMedium" : "secondaryMedium"}
				>
					{t("buttons.download")}
				</BriaButton>
				{aiEditorStore.aiEditorPopup && (
					<BriaButton
						onClick={handleSaveAndCloseClick}
						className={clsx({
							[styles.button]: true,
						})}
						buttonType="secondaryMedium"
					>
						{t("buttons.saveAndClose")}
					</BriaButton>
				)}
			</>
		);
	}

	function getImageIconsButtons() {
		return (
			<>
				<BriaTooltip title={t("tooltips.reset")}>
					<img
						className={clsx({
							[styles.icon]: true,
							[styles.hidden]: true,
						})}
						src={ResetSVG}
						alt=""
					/>
				</BriaTooltip>
				<BriaTooltip title={t("tooltips.undo")}>
					<img
						className={clsx({
							[styles.icon]: true,
							[styles.hidden]: true,
						})}
						src={UndoSVG}
						alt=""
					/>
				</BriaTooltip>
				<BriaTooltip title={t("tooltips.redo")}>
					<img
						className={clsx({
							[styles.icon]: true,
							[styles.hidden]: true,
						})}
						src={RedoSVG}
						alt=""
					/>
				</BriaTooltip>
				<BriaTooltip title={t("tooltips.compare")}>
					<img
						className={clsx({
							[styles.icon]: true,
							[styles.hidden]: true,
						})}
						src={CompareSVG}
						alt=""
					/>
				</BriaTooltip>
				<Divider
					className={clsx({
						[styles.divider]: true,
						[styles.hidden]: true,
					})}
					orientation={"vertical"}
				/>
				<Box className={styles.boxSliderWrapper}>
					<Box className={styles.boxSliderIcons}>
						<DashedBoxIcon
							className={styles.smallBox}
							onClick={() => aiEditorStore.setProperty("shapeSize", BoxGranularityEnum.Min)}
						/>
						<DashedBoxIcon
							className={styles.largeBox}
							onClick={() => aiEditorStore.setProperty("shapeSize", BoxGranularityEnum.Max)}
						/>
					</Box>
					<Slider
						value={aiEditorStore.shapeSize}
						onChange={(_e, value) => aiEditorStore.setProperty("shapeSize", value as number)}
						min={BoxGranularityEnum.Min}
						max={BoxGranularityEnum.Max}
						className={styles.boxSlider}
					/>
				</Box>
			</>
		);
	}

	return (
		<Box className={styles.container}>
			<Box className={styles.header}>
				<Box className={styles.leftSide}>{getImageIconsButtons()}</Box>
				<Box className={styles.rightSide}>{getImageButtons()}</Box>
			</Box>
			{selectedTab?.imageType === ImageEnum.Interactive ? (
				<InteractiveBriaImage imageUrl={aiEditorStore.selectedImageUrl} />
			) : (
				<Box className={styles.imageContainer}>
					<img src={aiEditorStore.selectedImageUrl} />
				</Box>
			)}
		</Box>
	);
};

const ObservedComponent = observer(ImageEditor);
export default ObservedComponent;
