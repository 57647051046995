import { Box, TextField, Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EditIcon } from "../../../../../../../../assets/images/icons/EditIcon";
import BriaButton from "../../../../../../../../components/common/BriaButton/BriaButton";
import BriaIconButton from "../../../../../../../../components/common/BriaIconButton/BriaIconButton";
import { BRIA_PUBLIC_ORG_ID } from "../../../../../../../../constants/OrgConstants";
import { useAppStore } from "../../../../../../../../hooks/useStores";
import InputLayout from "../../../../../../../../layout/InputLayout/InputLayout";
import { BrandDefinition } from "../../../../../../../../models/brandDefinition";
import { BrandLogo, defaultBrandLogos } from "../../../../../../../../models/brandLogo";
import { BrandText, defaultBrandText } from "../../../../../../../../models/brandText";
import { Font } from "../../../../../../../../models/font";
import { FontOptions } from "../../../../../../../../models/fontOptions";
import { loadFont, sortById } from "../../../../../../../../utils";
import ColorItem from "./ColorItem/ColorItem";
import styles from "./EditBrand.module.scss";
import FontItem from "./FontItem/FontItem";
import LogoItem from "./LogoItem/LogoItem";

interface IEditBrandProps {
	isAdminMode: boolean | undefined;
	loadBrands: (forcefetchBrands: boolean) => void;
	handleShowBrandsList: () => void;
	setSuccessMessage: (message: string) => void;
}
const EditBrand: FC<IEditBrandProps> = ({ isAdminMode, loadBrands, handleShowBrandsList, setSuccessMessage }) => {
	const { brandsDefinitionStore } = useAppStore();
	const [brand, setBrand] = useState<BrandDefinition>(brandsDefinitionStore.selectedBrand);
	const [colors, setColors] = useState<string[]>(brand?.color_pallete?.colors ?? Array(6).fill(""));
	const [activeColorIndex, setActiveColorIndex] = useState<number | null>(null);
	const [activeFontIndex, setActiveFontIndex] = useState<number | null>(null);
	const [logos, setLogos] = useState<BrandLogo[]>(sortById(brand?.logos));
	const [brandTexts, setBrandTexts] = useState<BrandText[]>(sortById(brand?.brand_texts));
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.brands" });
	const hasValidColor = colors.some((color) => color !== "");
	const hasValidLogo = logos.some((logo) => logo.src && logo.src !== "");
	const hasValidFont = brandTexts.some((brandText) => brandText.font?.src && brandText.font.src !== "");
	const isCreateNewBrandButtonLoading =
		brandsDefinitionStore.isCreating ||
		brandsDefinitionStore.isUploadingBrandLogo ||
		brandsDefinitionStore.isCreatingColorPallet;
	const isUpdateBrandButtonLoading =
		brandsDefinitionStore.isCreating ||
		brandsDefinitionStore.isUploadingBrandLogo ||
		brandsDefinitionStore.isCreatingColorPallet;
	useEffect(() => {
		loadAllFonts();
	}, []);

	useEffect(() => {
		setBrand(brandsDefinitionStore.selectedBrand);
		if (brand?.logos?.length === 0) {
			setLogos(defaultBrandLogos);
		}
		if (brand?.brand_texts?.length === 0) {
			setBrandTexts(Array(5).fill(defaultBrandText));
		}
	}, [brandsDefinitionStore.selectedBrand, brand?.logos?.length, brand?.brand_texts?.length]);

	useEffect(() => {
		brandTexts?.forEach((brandText: BrandText) => {
			if (brandText.font?.src) {
				loadFont(brandText.font);
			}
		});
	}, [brandTexts]);

	const [fontsOption, setFontsOptions] = useState<FontOptions[]>([]);

	const loadAllFonts = useCallback(async () => {
		await brandsDefinitionStore.getAllOrgFonts(true);
		const fontOptions = brandsDefinitionStore.orgFonts.map((font: Font) => {
			loadFont(font);
			return {
				name: font.name,
				id: font.id,
				src: font.src,
				org_id: font.org_id,
			};
		});

		const groupedFonts = fontOptions.reduce(
			(groupedFontsByOrgId: Record<string, Font[]>, font: Font) => {
				const orgId = font.org_id;
				if (!groupedFontsByOrgId[orgId]) {
					groupedFontsByOrgId[orgId] = [];
				}
				groupedFontsByOrgId[orgId].push(font);
				return groupedFontsByOrgId;
			},
			{} as Record<string, Font[]>,
		);

		const privateFonts: FontOptions[] = Object.entries(groupedFonts)
			.filter(([orgId]) => orgId !== BRIA_PUBLIC_ORG_ID)
			.map(([, options]) => ({
				name: t("privateFonts"),
				options: options as Font[],
			}));

		const publicFonts: FontOptions[] = Object.entries(groupedFonts)
			.filter(([orgId]) => orgId === BRIA_PUBLIC_ORG_ID)
			.map(([, options]) => ({
				name: t("publicFonts"),
				options: options as Font[],
			}));

		setFontsOptions([...privateFonts, ...publicFonts]);
	}, [brandsDefinitionStore]);

	const handleColorPaletteChange = async () => {
		const response: any = await brandsDefinitionStore.createColorPallete({
			colors,
			color_pallete_id: brandsDefinitionStore.selectedBrand.color_pallete_id,
		});

		if (!brandsDefinitionStore.selectedBrand.color_pallete_id) {
			brandsDefinitionStore.handleBrandChange("color_pallete_id", parseInt(response.color_palette_id));
		}
	};

	const createNewBrand = useCallback(async () => {
		if (colors.some((color) => color !== "")) {
			await handleColorPaletteChange();
		}
		if (hasValidColor || hasValidLogo || hasValidFont) {
			try {
				const response: any = await brandsDefinitionStore.handleCreateNewBrand();
				if (response.brand_id) {
					brandsDefinitionStore.isUploadingBrandLogo = true;
					for (const [index, logo] of logos.entries()) {
						try {
							const type = `LOGO_${index + 1}`;
							await brandsDefinitionStore.uploadBrandLogo(response.brand_id, type, logo.file);
						} catch (error) {
							console.error(`Error uploading logo at index ${index}:`, error);
							brandsDefinitionStore.isUploadingBrandLogo = false;
						}
					}
					brandsDefinitionStore.isUploadingBrandLogo = false;
					setSuccessMessage(response.message);
				}
				loadBrands(true);

				handleShowBrandsList();
			} catch (error) {
				console.error(error);
			}
		}
	}, [brandsDefinitionStore, colors, logos, loadBrands]);

	const updateBrand = useCallback(async () => {
		if (colors.some((color) => color !== "")) {
			await handleColorPaletteChange();
		}
		try {
			const response: any = await brandsDefinitionStore.handleUpdateBrand();
			if (response.type === "success") {
				const selectedBrandLogos = brandsDefinitionStore.selectedBrand.logos;
				brandsDefinitionStore.isUploadingBrandLogo = true;
				for (const [index, logo] of logos.entries()) {
					try {
						const matchingLogo = selectedBrandLogos?.find((selectedLogo) => selectedLogo.id === logo.id);
						if (matchingLogo) {
							if (
								(logo.file && logo.id && matchingLogo.src !== logo.src) ||
								(logo.id && matchingLogo.src !== logo.src)
							) {
								const type = matchingLogo.type;
								await brandsDefinitionStore.uploadBrandLogo(brand?.id, type, logo.file, logo.id);
							}
						}
					} catch (error) {
						console.error(`Error uploading logo at index ${index}:`, error);
						brandsDefinitionStore.isUploadingBrandLogo = false;
					}
				}
				brandsDefinitionStore.isUploadingBrandLogo = false;

				setSuccessMessage(response.message);
				loadBrands(true);

				handleShowBrandsList();
			}
		} catch (error) {
			console.error(error);
		}
	}, [brandsDefinitionStore, colors, logos, brand?.id]);

	return (
		<Box className={styles.container}>
			<Box className={styles.section}>
				<Box className={styles.sectionHeader}>
					<Typography className={styles.sectionTitle}>{t("description")}</Typography>
					{isAdminMode && (
						<Box className={styles.itemActions}>
							<BriaIconButton className={styles.editBtn}>
								<EditIcon />
							</BriaIconButton>
						</Box>
					)}
				</Box>
				<Typography className={styles.brandDescription}>
					<InputLayout showLabel={false}>
						<TextField
							variant="outlined"
							value={brand?.description}
							onChange={(e) => {
								brandsDefinitionStore.handleBrandChange("description", e.target.value);
								setBrand(brandsDefinitionStore.selectedBrand);
							}}
							placeholder={t("description")}
							fullWidth
							InputProps={{
								classes: {
									root: clsx(styles.textField, {
										[styles.readOnly]: !isAdminMode,
									}),
								},
							}}
						/>
					</InputLayout>
				</Typography>
			</Box>
			<Box className={styles.section}>
				<Typography className={styles.sectionTitle}>{t("colors")}</Typography>
				<Box className={styles.listItemsWrapper}>
					{colors.map((color, index) => (
						<ColorItem
							key={index}
							color={color}
							colors={colors}
							index={index}
							isAdminMode={isAdminMode}
							activeColorIndex={activeColorIndex}
							setActiveColorIndex={setActiveColorIndex}
							setColors={setColors}
						/>
					))}
				</Box>
			</Box>
			<Box className={styles.section}>
				<Typography className={styles.sectionTitle}>{t("logos")}</Typography>
				<Box className={styles.listItemsWrapper}>
					{logos.map((logo, index) => (
						<LogoItem
							key={index}
							logo={logo}
							logos={logos}
							index={index}
							isAdminMode={isAdminMode}
							setLogos={setLogos}
						/>
					))}
				</Box>
			</Box>
			<Box className={styles.section}>
				<Typography className={styles.sectionTitle}>{t("fonts")}</Typography>
				<Box className={styles.listItemsWrapper}>
					{brandTexts.map((brandText, index) => (
						<FontItem
							key={index}
							isAdminMode={isAdminMode}
							brandText={brandText}
							brandTexts={brandTexts}
							index={index}
							activeFontIndex={activeFontIndex}
							setActiveFontIndex={setActiveFontIndex}
							setBrandTexts={setBrandTexts}
							fontsOption={fontsOption}
							loadAllFonts={loadAllFonts}
						/>
					))}
				</Box>
			</Box>
			{isAdminMode && (
				<Box className={styles.editBrandActions}>
					{brand?.id ? (
						<BriaButton
							onClick={updateBrand}
							buttonType="primaryMedium"
							loading={isUpdateBrandButtonLoading}
							fullWidth
							size="small"
							disabled={isUpdateBrandButtonLoading || !(hasValidColor || hasValidLogo || hasValidFont)}
						>
							{t("update")}
						</BriaButton>
					) : (
						<BriaButton
							onClick={createNewBrand}
							buttonType="secondaryMedium"
							size="small"
							loading={isCreateNewBrandButtonLoading}
							fullWidth
							disabled={isCreateNewBrandButtonLoading || !(hasValidColor || hasValidLogo || hasValidFont)}
						>
							{t("create")}
						</BriaButton>
					)}
				</Box>
			)}
		</Box>
	);
};

const ObservedComponent = observer(EditBrand);
export default ObservedComponent;
