function AlignCenter({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10 3.3335V7.50016M10 12.5002V16.6668M6.66667 7.50016H13.3333C14.2538 7.50016 15 8.24635 15 9.16683V10.8335C15 11.754 14.2538 12.5002 13.3333 12.5002H6.66667C5.74619 12.5002 5 11.754 5 10.8335V9.16683C5 8.24635 5.74619 7.50016 6.66667 7.50016Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AlignCenter;
