import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { ReactNode } from "react";
import GeneratedImgSvg from "../../../assets/images/svgs/generated-img.svg";
import styles from "./EmptyTable.module.scss";

interface IProps {
	message: string;
	component?: React.ElementType;
	className?: string;
	children?: ReactNode;
}

export default function EmptyTable({ message, component: Component = "div", className, children }: IProps) {
	return (
		<Box className={clsx(className, styles.emptyTable)} component={Component}>
			{children}
			<img src={GeneratedImgSvg} />
			<Typography className={styles.text}>{message}</Typography>
		</Box>
	);
}
