import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function DownloadIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 21 22" {...props}>
			<path
				d="M17.498 15.3711L17.498 17.1211C17.498 17.5852 17.3137 18.0303 16.9855 18.3585C16.6573 18.6867 16.2122 18.8711 15.748 18.8711L5.24804 18.8711C4.78392 18.8711 4.3388 18.6867 4.01061 18.3585C3.68242 18.0303 3.49804 17.5852 3.49804 17.1211L3.49804 15.3711M14.873 10.1211L10.498 14.4961M10.498 14.4961L6.12305 10.1211M10.498 14.4961L10.498 3.99609"
				stroke="#5B5B5B"
				strokeWidth="1.40625"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default DownloadIcon;
