import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, MenuItem, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";
import { EventsLogEnum } from "../../../models/common.ts";
import { TailoredGenerationModelType } from "../../../models/tailoredGeneration.ts";
import { isBriaMember, isModelTypeDropDownVisible } from "../../../utils/index.ts";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import BriaSelect from "../../common/BriaSelect/BriaSelect.tsx";
import ImageViewer from "../../common/Image/Image.tsx";
import styles from "./TGPublishToTrainingDialog.module.scss";

const TGPublishToTrainingDialog = () => {
	const { tailoredGenerationStore, authStore, uiStore, appStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });
	const [newDatasetName, setNewDatasetName] = useState<string>("");
	const [isUpdatingDatasetName, setIsUpdatingDatasetName] = useState<boolean>(false);
	const navigate = useNavigate();

	/* This snippet of code will be retrieved later */
	const handleModelChange = (e: SelectChangeEvent<any>) => {
		const selectedTarget = e.target.value as string;
		tailoredGenerationStore.handleFormChange("modelType", selectedTarget);
	};

	const publishToTrainingHandler = async () => {
		const isNameEmpty = tailoredGenerationStore.modelForm.dataset.name.trim() === "";
		const isDescriptionEmpty = tailoredGenerationStore.modelForm.dataset.description.trim() === "";

		if (isNameEmpty || isDescriptionEmpty) {
			if (isNameEmpty) {
				tailoredGenerationStore.setIsValidDatasetName(false);
			}
			if (isDescriptionEmpty) {
				tailoredGenerationStore.setIsEmptyDescription(true);
			}
			uiStore.hideDialog("TGPublishToTrainingDialog");
			return;
		} else if (newDatasetName.trim()) {
			setIsUpdatingDatasetName(true);
			tailoredGenerationStore.handleFormChange("dataset", {
				...tailoredGenerationStore.modelForm.dataset,
				name: newDatasetName,
			});
		}

		tailoredGenerationStore.isLoading = true;
		const modelsCount = tailoredGenerationStore.models.length;
		if (modelsCount === 0 && (await tailoredGenerationStore.getModelsCount()) === 0) {
			appStore.logEvent({ event: EventsLogEnum.SUBMITTED_FIRST_TRAINING_MODEL });
		}

		await tailoredGenerationStore.updateDataset(tailoredGenerationStore.modelForm.dataset, false);
		await tailoredGenerationStore.createModel(tailoredGenerationStore.modelForm);
		await tailoredGenerationStore.loadDatasets();
		uiStore.hideDialog("TGPublishToTrainingDialog");
		navigate(RouterConstants.TG_MODELS.fullPath);
	};

	const isUpdateDataset = () => {
		const selectedDataset = tailoredGenerationStore.selectedDatasetOption;
		const modelFormDataset = tailoredGenerationStore.modelForm.dataset;
		if (
			selectedDataset &&
			((selectedDataset.id !== modelFormDataset.id && selectedDataset.name === modelFormDataset.name) ||
				(selectedDataset.id === modelFormDataset.id &&
					selectedDataset.description !== modelFormDataset.description &&
					selectedDataset.name === modelFormDataset.name))
		) {
			return true;
		}
		return false;
	};

	return (
		<Box className={styles.form}>
			<Dialog
				onClose={() => {
					uiStore.hideDialog("TGPublishToTrainingDialog");
				}}
				open={uiStore.TGPublishToTrainingDialog}
			>
				<Box className={styles.dialogContent}>
					<IconButton
						onClick={() => {
							uiStore.hideDialog("TGPublishToTrainingDialog");
						}}
						className={styles.closeButton}
					>
						<Close />
					</IconButton>
					<Typography variant="h6" className={styles.title}>
						{t("models.stepper.sendToTraining.publishToTrainingDialog.title")}
					</Typography>
					<Typography className={styles.description}>
						{t("models.stepper.sendToTraining.publishToTrainingDialog.description")}
					</Typography>
					<InputLayout label={t("models.stepper.modelDetails.modelNameLabel")}>
						<TextField
							value={tailoredGenerationStore.modelForm.name}
							onChange={(e) => tailoredGenerationStore.handleFormChange("name", e.target.value)}
							placeholder={t("models.stepper.modelDetails.modelNamePlaceholder")}
							fullWidth
							InputProps={{ classes: { root: styles.textField } }}
						/>
					</InputLayout>
					<InputLayout label={t("models.stepper.modelDetails.descriptionLabel")}>
						<TextField
							value={tailoredGenerationStore.modelForm.description}
							onChange={(e) => tailoredGenerationStore.handleFormChange("description", e.target.value)}
							placeholder={t("models.stepper.modelDetails.descriptionPlaceholder")}
							fullWidth
							multiline
							minRows={3}
							InputProps={{
								classes: {
									root: styles.bigTextField,
								},
							}}
						/>
					</InputLayout>
					{/* This snippet of code will be retrieved later  */}
					{isBriaMember(authStore.user) && isModelTypeDropDownVisible() && (
						<InputLayout label={t("models.stepper.modelDetails.modelTypeLabel")}>
							<BriaSelect
								value={tailoredGenerationStore.modelForm.modelType}
								onChange={handleModelChange}
								className={styles.dropDown}
								renderValue={(selectedValue) => <>{selectedValue}</>}
								MenuProps={{
									anchorOrigin: {
										vertical: "top",
										horizontal: "center",
									},
									transformOrigin: {
										vertical: "bottom",
										horizontal: "center",
									},
									PaperProps: {
										style: {
											backgroundColor: "white",
											maxHeight: "30vh",
											overflowY: "auto",
										},
									},
								}}
							>
								{Object.values(TailoredGenerationModelType).map((model) => (
									<MenuItem
										sx={{
											padding: 0,
										}}
										key={model.name}
										value={model.name}
									>
										{model.name}

										{model?.imageSource && (
											<ImageViewer className={styles.image} name={model.imageSource} />
										)}
									</MenuItem>
								))}
							</BriaSelect>
						</InputLayout>
					)}
					{(isUpdateDataset() || isUpdatingDatasetName) && (
						<InputLayout
							validateOn={!tailoredGenerationStore.isValidDatasetName}
							validationMessage={t("models.validation.dataSetNameAlreadyExists")}
							label={t("models.stepper.modelDetails.datasetNameLabel")}
						>
							<Typography className={styles.inputDescription}>
								{t("models.stepper.modelDetails.datasetNameDescription")}
							</Typography>
							<TextField
								value={newDatasetName}
								onChange={(e) => {
									setNewDatasetName(e.target.value);
									tailoredGenerationStore.setIsValidDatasetName(true);
								}}
								placeholder={t("models.stepper.modelDetails.datasetNamePlaceholder")}
								fullWidth
								InputProps={{ classes: { root: styles.textField } }}
							/>
						</InputLayout>
					)}

					<BriaButton
						disabled={
							(tailoredGenerationStore.modelForm.name.trim() === "" ||
							(isUpdateDataset() && newDatasetName.trim() === "")) || tailoredGenerationStore.isLoading
						}
						buttonType="primary"
						className={styles.button}
						onClick={publishToTrainingHandler}
						size="small"
						loading={tailoredGenerationStore.isLoading}
					>
						{t("models.stepper.sendToTraining.publishToTrainingDialog.button")}
					</BriaButton>
				</Box>
			</Dialog>
		</Box>
	);
};

const ObservedComponent = observer(TGPublishToTrainingDialog);
export default ObservedComponent;
