import Box from "@mui/material/Box";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import styles from "./LinearProgressBar.module.scss";

export enum LinearType {
	BOLD = "bold",
	LIGHT = "light",
}

interface IProgressBar {
	progress: number;
	linearType?: LinearType;
}

const BoldLinearProgress = styled(LinearProgress)(() => ({
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: "#B3B3B9",
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
	},
}));

const LightLinearProgress = styled(LinearProgress)(() => ({
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: "#e7e7e7",
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
	},
}));

export const LinearWithValueLabel = ({ progress, linearType = LinearType.LIGHT }: IProgressBar) => {
	return (
		<Box className={styles.container}>
			{linearType === LinearType.BOLD ? (
				<BoldLinearProgress variant="determinate" value={progress} />
			) : (
				<LightLinearProgress variant="determinate" value={progress} />
			)}
		</Box>
	);
};
