import { Alert, AlertProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface IProps {
	bgColor?: string;
	fontColor?: string;
	boxShadow?: string;
}

const StyledAlert = styled(Alert)<{ bgColor?: string; fontColor?: string; boxShadow?: string }>`
	background-color: ${(props) => props.bgColor};
	color: ${(props) => (props.fontColor ? props.fontColor : "#1A0638")};
	display: flex;
	align-items: center;
	box-shadow: ${(props) => props.boxShadow};
`;

const AlertNotification: React.FC<IProps & AlertProps> = ({ children, bgColor, fontColor, boxShadow, ...rest }) => {
	return (
		<StyledAlert bgColor={bgColor} fontColor={fontColor} boxShadow={boxShadow} {...rest}>
			{children}
		</StyledAlert>
	);
};

export default AlertNotification;
