import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";

function useAppContext() {
	const {
		isMobile,
		setIsMobile,
		activePanel,
		setActivePanel,
		templates,
		setTemplates,
		shapes,
		setShapes,
		activeSubMenu,
		setActiveSubMenu,
		uploads,
		setUploads,
		currentTemplate,
		setCurrentTemplate,
	} = useContext(AppContext);
	return {
		isMobile,
		setIsMobile,
		activePanel,
		setActivePanel,
		templates,
		setTemplates,
		shapes,
		setShapes,
		activeSubMenu,
		setActiveSubMenu,
		uploads,
		setUploads,
		currentTemplate,
		setCurrentTemplate,
	};
}

export default useAppContext;
