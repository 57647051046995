import fabric from "fabric/fabric-impl";
import { groupBy } from "lodash";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../../hooks/useStores";
import { BrandLogo } from "../../models/brandLogo";
import { BrandText } from "../../models/brandText";
import { HorizontalEnum, LogoTypeEnum, VerticalEnum } from "../../models/common";
import { Font } from "../../models/font";
import useImageUtils from "../../utils/useImageUtils.tsx";
import { DesignEditorContext } from "../contexts/DesignEditor";
import useDesignEditorPages from "../hooks/useDesignEditorScenes";
import { useEditor } from "../hooks/useEditor";
import { IDesign } from "../interfaces/DesignEditor.ts";
import { FontItem } from "../interfaces/common";
import { BrandDefinition } from "../models/brandDefinition";
import { Image } from "../models/image.ts";
import { IBackground, IScene, IStaticImage, IStaticText, IStaticVector, LayerType } from "../types";
import { loadFonts } from "../utils/fonts";
import { ImageSize, ObjectsEnum } from "../views/DesignEditor/components/Panels/panelItems/index.ts";
import {
	getImageDimensionsByImageUrl,
	getImageToRectScaleFactor,
	useSmartImageUtils,
} from "../views/DesignEditor/utils/smartImageUtils";

type DesignEditorUtils = {
	EditAllAds: (layerType: string, type: string, featureToEdit: string, value: any) => void;
	applyBrandConfigration: (brand: BrandDefinition, scenes: IScene[]) => void;
	applyBrandConfigrationOnScene: (
		brand: BrandDefinition,
		scene: IScene,
		sceneIndex: number,
		hardUpdate?: boolean,
	) => Promise<IScene>;
	makeDownloadTemplate: () => void;
	updateAllScenes: () => Promise<void>;
	addImageObjectToCanvas: (image: Image) => void;
	getRgbAndOpacityFromRgba: (color: string) => { opacity: number; rgb: string } | undefined;
	updateColorOpacity: (color: string, opacity: number) => string;
};

const useDesignEditorUtils = (): DesignEditorUtils => {
	const scenes = useDesignEditorPages();
	const { waitForRender, changePage } = useSmartImageUtils();
	const editor = useEditor();
	const { campaignStore } = useAppStore();
	const { setScenes, currentScene, currentDesign, setCurrentScene } = useContext(DesignEditorContext);
	const { t } = useTranslation("translation", { keyPrefix: "campaignTab.dropDownActions" });
	const { convertS3UriToHttpsUrl, isS3Uri } = useImageUtils();
	const makeDownloadTemplate = () => {
		const makeDownload = (data: IDesign) => {
			const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
			const virtualAnchorLink = document.createElement("a");
			virtualAnchorLink.href = dataStr;
			virtualAnchorLink.download = "template.json";
			virtualAnchorLink.click();
		};
		const currentScene = editor && editor.scene.exportToJSON();
		if (currentScene) {
			const updatedScenes = scenes.map((scn) => {
				if (scn.id === currentScene.id) {
					return {
						id: currentScene.id,
						layers: currentScene.layers,
						name: currentScene.name,
					};
				}
				return {
					id: scn.id,
					layers: scn.layers,
					name: scn.name,
				};
			});
			if (currentDesign) {
				const graphicTemplate: IDesign = {
					id: currentDesign.id,
					name: currentDesign.name,
					frame: currentDesign.frame,
					scenes: updatedScenes,
					metadata: {},
					preview: "",
				};
				makeDownload(graphicTemplate);
			} else {
				console.log("NO CURRENT DESIGN");
			}
		}
	};
	const EditAllAds = async (layerType: string, type: string, featureToEdit: string, value: any) => {
		if (campaignStore.isEditingAllAds) {
			editor?.canvas.canvas.getObjects().forEach((object: fabric.Object) => {
				if (
					object.type === LayerType.STATIC_TEXT &&
					type == object.textType &&
					featureToEdit &&
					type !== t("configureHeadingLevel")
				) {
					EditTextElementsinAllAds(object, featureToEdit, value, true);
				} else if (object.type === LayerType.STATIC_VECTOR && type == object.imageType && featureToEdit) {
					EditShapeElementsinAllAds(object, featureToEdit, value);
				} else if (
					object.type === LayerType.STATIC_IMAGE &&
					type == object.imageType &&
					featureToEdit &&
					type !== t("configureImageLevel")
				) {
					EditImageElementsinAllAds(object, featureToEdit, value);
				}
			});

			scenes.map((scene) => {
				scene.layers.forEach(async (layer) => {
					if (layer.type === layerType && featureToEdit) {
						switch (layerType) {
							case LayerType.STATIC_TEXT:
								if (type === layer.textType) {
									EditTextElementsinAllAds(layer as IStaticText, featureToEdit, value);
								}
								break;
							case LayerType.BACKGROUND:
								EditCanvasElementsinAllAds(layer as IBackground, featureToEdit, value);
								break;
							case LayerType.STATIC_VECTOR:
								if (type === layer.imageType) {
									EditShapeElementsinAllAds(layer as IStaticVector, featureToEdit, value);
								}
								break;
							case LayerType.STATIC_IMAGE:
								if (type === layer.imageType) {
									EditImageElementsinAllAds(layer as IStaticImage, featureToEdit, value);
								}
								break;
							default:
								break;
						}
					}
				});
			});

			await updateAllScenes();
		}
	};

	const EditTextElementsinAllAds = async (
		object: fabric.Object | IStaticText,
		featureToEdit: string,
		value: any,
		current = false,
	) => {
		switch (featureToEdit) {
			case FeatureType.COLOR:
				object.fill = value;
				if (current) editor?.objects?.update?.({ fill: value }, object.id);
				break;
			case FeatureType.UNDERLINE:
				if (current)
					editor?.objects.update(
						{
							underline: value,
						},
						object.id,
					);
				object.underline = value;
				break;
			case FeatureType.BOLD:
				if (current)
					editor?.objects.update({
						fontWeight: value,
					});
				object.fontWeight = value;
				break;
			case FeatureType.ITALIC:
				if (current)
					editor?.objects.update({
						fontStyle: value,
					});
				object.fontStyle = value;
				break;
			case FeatureType.TEXT_ALIGN:
				if (current) editor?.objects.update({ textAlign: value });
				object.textAlign = value;
				break;
			case FeatureType.CHAR_SPACING:
				object.charSpacing = value;
				if (current)
					editor?.objects.update({
						[featureToEdit]: value,
					});
				break;
			case FeatureType.LINE_HEIGHT:
				object.lineHeight = value;
				if (current)
					editor?.objects.update({
						[featureToEdit]: value,
					});
				break;
			case FeatureType.LETTER_CASE:
				if (value === "uppercase") {
					object.text = object.text.toUpperCase();
				} else {
					object.text = object.text.toLowerCase();
				}
				editor?.history.save(currentScene?.id);
				break;
			case FeatureType.TEXT:
				object.text = value;
				if (current)
					editor?.objects.update({
						text: value,
					});
				break;
			case FeatureType.FONT:
				object.fontFamily = value.name;
				object.fontURL = value.url;
				if (current)
					editor?.objects.update({
						fontFamily: value.name,
						fontURL: value.url,
					});
				break;
			default:
				console.warn(`Feature ${featureToEdit} is not supported.`);
		}
	};

	const EditCanvasElementsinAllAds = async (
		object: fabric.Object | IBackground,
		featureToEdit: string,
		value: any,
	) => {
		switch (featureToEdit) {
			case FeatureType.COLOR:
				object.fill = value;
				break;
			default:
				console.warn(`Feature ${featureToEdit} is not supported.`);
		}
	};

	const EditShapeElementsinAllAds = async (
		object: fabric.Object | IStaticVector,
		featureToEdit: string,
		value: any,
	) => {
		switch (featureToEdit) {
			case FeatureType.COLOR:
				const objects = object._objects?.[0]?._objects;
				const objectColors = groupBy(objects, FeatureType.FILL);
				campaignStore.vectorColors = {
					...campaignStore.vectorColors,
					colors: Object.keys(objectColors),
					colorMap: object.colorMap,
				};

				Object.keys(campaignStore.vectorColors.colorMap).map((color, _index) => {
					campaignStore.prevColor = color;
				});
				object?.updateLayerColor?.(campaignStore.prevColor, value);
				object.fill = value;
				if (object.strokeOnly) object.stroke = value;
				object.colorMap[campaignStore.prevColor] = value;

				break;
			default:
				console.warn(`Feature ${featureToEdit} is not supported.`);
		}
	};

	const EditImageElementsinAllAds = async (
		object: fabric.Object | IStaticImage,
		featureToEdit: string,
		value: any,
	) => {
		switch (featureToEdit) {
			case FeatureType.FLIPX:
				object.flipX = value;
				break;
			case FeatureType.FLIPY:
				object.flipY = value;
				break;
			default:
				console.warn(`Feature ${featureToEdit} is not supported.`);
		}
	};

	const updateAllScenes = async () => {
		const new_scenes: IScene[] = [];
		for (const scene of scenes) {
			const updatedPreview = (await editor?.renderer.render(scene)) as string;
			const updatedScene = { ...scene, preview: updatedPreview };
			new_scenes.push(updatedScene);
		}
		setScenes(new_scenes);
	};

	const applyBrandConfigration = async (brand: BrandDefinition, scenes: IScene[]) => {
		if (campaignStore.isEditingAllAds) {
			const new_scenes: IScene[] = [];
			for (let sceneIndex = 0; sceneIndex < scenes.length; sceneIndex++) {
				const updatedScene = await applyBrandConfigrationOnScene(brand, scenes[sceneIndex], sceneIndex);
				new_scenes.push(updatedScene);
			}
			setScenes(new_scenes);
			setCurrentScene(new_scenes[0]);
		} else {
			const currentSce = editor?.scene.exportToJSON();
			let currentSceneIdx = 0;
			for (let sceneIndex = 0; sceneIndex < scenes.length; sceneIndex++) {
				if (currentSce?.id === scenes[sceneIndex].id) currentSceneIdx = sceneIndex;
			}
			if (currentSce) {
				scenes[currentSceneIdx] = await applyBrandConfigrationOnScene(
					brand,
					scenes[currentSceneIdx],
					currentSceneIdx,
				);
				setScenes(scenes);
			}
		}
	};

	const applyBrandConfigrationOnScene = async (
		brand: BrandDefinition,
		scene: IScene,
		sceneIndex: number,
		hardUpdate?: boolean | undefined,
	) => {
		const selectedBrand = brand;
		let fonts: { [key: string]: Font | undefined } = {};
		if (!selectedBrand) {
			return scene;
		}

		const colors = selectedBrand.color_pallete?.colors || [];
		if (selectedBrand?.brand_texts) {
			fonts = selectedBrand.brand_texts.reduce(
				(fontOptions: { [key: string]: Font | undefined }, font: BrandText) => {
					fontOptions[font?.type] = font.font;
					return fontOptions;
				},
				{},
			);
		}

		const logos: { [key in LogoTypeEnum]: string } =
			selectedBrand?.logos?.reduce(
				(logosOptions: { [key in LogoTypeEnum]: string }, logo: BrandLogo) => {
					logosOptions[logo?.type] = logo.src;
					return logosOptions;
				},
				{} as { [key in LogoTypeEnum]: string },
			) || defaultLogos;

		for (let i = 0; i < scene.layers.length; i++) {
			const layer = scene.layers[i];
			if (layer.type === LayerType.STATIC_IMAGE) {
				const updatedLayer = await applyImageConfig(layer as IStaticImage, logos, sceneIndex);
				if (updatedLayer) {
					scene.layers[i] = updatedLayer;
				}
			} else if (layer.type === LayerType.STATIC_TEXT) {
				await applyTextConfig(layer as IStaticText, fonts, colors);
			} else if (layer.type === LayerType.STATIC_VECTOR) {
				applyVectorConfig(layer as IStaticVector, colors);
			} else if (layer.type === LayerType.BACKGROUND) {
				applyBackgroundConfig(layer as IBackground, colors);
			}
		}

		if (hardUpdate) {
			await changePage(scene);
			await waitForRender();
		}

		const updatedPreview = (await editor?.renderer.render(scene)) as string;
		return { ...scene, preview: updatedPreview };
	};

	const applyTextConfig = async (
		object: IStaticText,
		fonts: { [key: string]: Font | undefined },
		colors: any,
		current = false,
	) => {
		const { colorNumber, fontType } = object;
		if (colorNumber && colorNumber >= 1 && colorNumber <= 6 && colors[colorNumber - 1]) {
			EditTextElementsinAllAds(object, FeatureType.COLOR, colors[colorNumber - 1], current);
		}
		if (fontType && fonts && fonts[fontType]) {
			const fontOption = fonts[fontType];
			const font = {
				name: fontOption?.name,
				url: fontOption?.src && convertS3UriToHttpsUrl(fontOption?.src),
			};
			object.fontFamily = font.name;
			object.fontURL = font.url;
			await loadFonts([font as FontItem]);
			if (current) {
				editor?.objects.update(
					{
						fontFamily: font.name,
						fontURL: font.url,
					},
					object.id,
				);
			}
		}
	};

	const applyVectorConfig = (object: IStaticVector, colors: string[]) => {
		const { colorNumber } = object;
		if (colorNumber && colorNumber >= 1 && colorNumber <= 6 && colors[colorNumber - 1]) {
			EditShapeElementsinAllAds(object, FeatureType.COLOR, colors[colorNumber - 1]);
		}
	};

	const applyBackgroundConfig = (object: IBackground, colors: string[]) => {
		const { colorNumber } = object;
		if (colorNumber && colorNumber >= 1 && colorNumber <= 6 && colors[colorNumber - 1]) {
			EditCanvasElementsinAllAds(object, FeatureType.COLOR, colors[colorNumber - 1]);
		}
	};

	const applyImageConfig = async (
		object: IStaticImage,
		logos: { [key in LogoTypeEnum]: string },
		sceneIndex: number,
	) => {
		const { logoType } = object;
		const currentObject = object;
		if (logoType && logos && logos[logoType as LogoTypeEnum]) {
			const originalLogoObjects = campaignStore.getOriginalLogoObjects(sceneIndex) || [];
			const storedObject = originalLogoObjects.find((fabricObject) => fabricObject.id === object.id);

			if (campaignStore.isFirstTimeBrandApplied) {
				// First-time brand application logic
				if (!storedObject) {
					originalLogoObjects.push(object);
					campaignStore.storeOriginalLogoObjects(sceneIndex, originalLogoObjects);
				} else {
					object = storedObject;
				}
			} else {
				if (!storedObject) {
					originalLogoObjects.push(object);
					campaignStore.storeOriginalLogoObjects(sceneIndex, originalLogoObjects);
				} else {
					object = storedObject;
				}
			}
			const src = logos[logoType as LogoTypeEnum]; // new source
			const logoUrl = convertS3UriToHttpsUrl(src);

			const originalImageDimensions = await getImageDimensionsByImageUrl(logoUrl);
			const scaleFactor = await getImageToRectScaleFactor(logoUrl, object);

			const newImageOptions = {
				id: object.id,
				type: "StaticImage",
				src: logoUrl,
				left: object?.left,
				top: object?.top,
				width: originalImageDimensions.width,
				height: originalImageDimensions.height,
				scaleX: scaleFactor,
				scaleY: scaleFactor,
				imageType: currentObject.imageType,
				logoType: currentObject.logoType,
				horizontalAlignment: currentObject.horizontalAlignment,
				verticalAlignment: currentObject.verticalAlignment,
			};

			// Add the new image to the editor canvas only if it's the current scene
			if (editor?.canvas.canvas.getObjects().includes(object as fabric.Object)) {
				editor?.canvas.canvas.remove(object as fabric.Object);
				await editor?.objects.add(newImageOptions);
				editor?.objects.update(newImageOptions);
			}

			// Update the Logo Alignment
			const { top, left } = updateImageAlignment(object, currentObject, originalImageDimensions, scaleFactor);
			newImageOptions.top = top;
			newImageOptions.left = left;

			// Return the updated image layer for other scenes
			return newImageOptions;
		}
		return object;
	};

	const addImageObjectToCanvas = useCallback(
		async (image: Image) => {
			let url = image.url;
			if (isS3Uri(url)) {
				url = convertS3UriToHttpsUrl(image.url);
			}
			const objects = editor?.objects;
			const referenceObject = objects?.findOneById(ObjectsEnum.InitialFrame);
			const scaleFactor = await getImageToRectScaleFactor(url, referenceObject);
			const originalImageDimensions = await getImageDimensionsByImageUrl(url);

			const options = {
				type: "StaticImage",
				src: url,
				left: referenceObject.left,
				top: referenceObject.top,
				width: originalImageDimensions.width,
				height: originalImageDimensions.height,
				scaleX: scaleFactor,
				scaleY: scaleFactor,
			};
			await editor?.objects.add(options);
		},
		[editor],
	);
	/**
	 * Calculates the new left and new top for the new brand logo based on the original logo.
	 *
	 * @param object - The Original staticImage Object.
	 * @param currentObject - The Current staticImage Object.
	 * @param originalImageDimensions - pre-fetched dimensions of the new logo (width and height).
	 * @param scaleFactor - the calculated scale Factor needed to fit the new logo  within the original logo.
	 * @returns The top and left for the new  Logo.
	 */

	const updateImageAlignment = (
		object: IStaticImage,
		currentObject: IStaticImage,
		originalImageDimensions: ImageSize,
		scaleFactor: number,
	) => {
		const objectWidth = (object.width ?? 0) * (object.scaleX ?? 1);
		const objectHeight = (object.height ?? 0) * (object.scaleY ?? 1);
		const replaceLogoWidth = originalImageDimensions.width * scaleFactor;
		const replaceLogoHeight = originalImageDimensions.height * scaleFactor;

		const newLeft = currentObject.horizontalAlignment
			? calculateAlignment(
					currentObject.horizontalAlignment as HorizontalEnum,
					objectWidth,
					replaceLogoWidth,
					object.left ?? 0,
			  )
			: object.left ?? 0;

		const newTop = currentObject.verticalAlignment
			? calculateAlignment(
					currentObject.verticalAlignment as VerticalEnum,
					objectHeight,
					replaceLogoHeight,
					object.top ?? 0,
			  )
			: object.top ?? 0;

		return {
			top: newTop,
			left: newLeft,
		};
	};

	/**
	 * Calculates new Alignment.
	 *
	 * @param alignment - Alignment Type.
	 * @param objectSize - The  Object width or object height based on Alignment Type.
	 * @param replaceSize - the new logo width or heightbased on Alignment Type.
	 * @param objectPos - the original object top or left based on Alignment Type .
	 * @returns The top or left for the new  Logo based on Alignment Type .
	 */

	const calculateAlignment = (
		alignment: HorizontalEnum | VerticalEnum,
		objectSize: number,
		replaceSize: number,
		objectPos: number,
	) => {
		switch (alignment) {
			case HorizontalEnum.RIGHT:
			case VerticalEnum.BOTTOM:
				return objectPos + (objectSize - replaceSize);
			case HorizontalEnum.LEFT:
			case VerticalEnum.TOP:
				return objectPos;
			case HorizontalEnum.CENTER:
			case VerticalEnum.CENTER:
				return objectPos + (objectSize / 2 - replaceSize / 2);
			default:
				return objectPos;
		}
	};

	/**
	 *parse RGBA color to Get RGB and opacity.
	 *
	 * @param color - Color in RGBA format.
	 * @returns The opacity and  RGB .
	 */

	const getRgbAndOpacityFromRgba = (color: string) => {
		// Regular expression to match rgba color format
		const rgbaRegex = /^rgba\(\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0|1|0?\.\d+)\s*\)$/;

		// Check if the color matches the rgba format
		const match = rgbaRegex.exec(color);

		if (match) {
			// Extract the opacity value (4th capture group in the regex)
			const opacity = parseFloat(match[4]);
			const red = parseInt(match[1], 10);
			const green = parseInt(match[2], 10);
			const blue = parseInt(match[3], 10);

			// Return the RGB values as a string in the format "rgb(r, g, b)"
			return { opacity: opacity, rgb: `rgb(${red}, ${green}, ${blue})` };
		}

		// Return undefined if the color is not in rgba format
		return undefined;
	};

	/**
	 *update given color opacity to prepare it for the opensource color picker.
	 *
	 * @param color - Color in RGBA or rgb format.
	 * @returns The updated color with opacity in  RGBA format .
	 */

	const updateColorOpacity = (color: string, opacity: number) => {
		const rgbRegex = /^rgb\(\s*([\d]{1,3})\s*,\s*([\d]{1,3})\s*,\s*([\d]{1,3})\s*\)$/i;
		const rgbaRegex = /^rgba\(\s*([\d]{1,3})\s*,\s*([\d]{1,3})\s*,\s*([\d]{1,3})\s*,\s*([\d.]+)\s*\)$/i;

		let match;
		if (opacity) {
			if ((match = color.match(rgbRegex))) {
				// Convert rgb to rgba with the new opacity
				const [, r, g, b] = match;
				return `rgba(${r}, ${g}, ${b}, ${opacity})`;
			} else if ((match = color.match(rgbaRegex))) {
				// Update opacity in the existing rgba
				const [, r, g, b] = match;
				return `rgba(${r}, ${g}, ${b}, ${opacity})`;
			} else {
				return color;
			}
		}
		return color;
	};

	return {
		EditAllAds,
		applyBrandConfigration,
		applyBrandConfigrationOnScene,
		makeDownloadTemplate,
		updateAllScenes,
		addImageObjectToCanvas,
		getRgbAndOpacityFromRgba,
		updateColorOpacity,
	};
};

export default useDesignEditorUtils;

export enum FeatureType {
	COLOR = "color",
	UNDERLINE = "underline",
	BOLD = "bold",
	ITALIC = "italic",
	TEXT_ALIGN = "textAlign",
	CHAR_SPACING = "charSpacing",
	LINE_HEIGHT = "lineHeight",
	LETTER_CASE = "letterCase",
	FLIPX = "flipX",
	FLIPY = "flipY",
	FILL = "fill",
	TEXT = "text",
	FONT = "font",
}

const defaultLogos: { [key in LogoTypeEnum]: string } = {
	LOGO_1: "",
	LOGO_2: "",
	LOGO_3: "",
	LOGO_4: "",
	LOGO_5: "",
	LOGO_6: "",
};
