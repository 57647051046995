import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getSummerHost } from "../../../config/env";
import { HubgalleryImage } from "../../../constants/HubGalleryImagesConstants";
import RouterConstants from "../../../constants/RouterConstants";
import BriaButton from "../BriaButton/BriaButton";
import styles from "./HubImageOverlay.module.scss";

export type HubImageOverlayProps = {
	image: HubgalleryImage;
};

const HubImageOverlay = ({ image }: HubImageOverlayProps) => {
	const navigate = useNavigate();
	const { t } = useTranslation("translation", { keyPrefix: "galleryHub" });

	return (
		<Box className={styles.overlay}>
			<Box className={styles.overlayContent}>
				<Typography className={styles.prompt}>{t(image?.prompt)}</Typography>
				<Box className={styles.buttonsContainer}>
					<BriaButton
						onClick={(e) => {
							navigate(RouterConstants.TEXT_TO_IMAGE.path, {
								state: { prompt: t(image?.prompt) },
							});
							e.stopPropagation();
						}}
						className={styles.button}
					>
						{t("Generate")}
					</BriaButton>
					<BriaButton
						onClick={(e) => {
							window.open(`${getSummerHost()}/gallery/${image?.vhash}/?from_platform_hub=true`, "_blank");
							e.stopPropagation();
						}}
						className={styles.button}
					>
						{t("editUsingAI")}
					</BriaButton>
				</Box>
			</Box>
		</Box>
	);
};

export default HubImageOverlay;
