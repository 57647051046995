import threeDrender from "../../../assets/images/pngs/3D render.png";
import consistentimagestyle from "../../../assets/images/pngs/Consistent image style_.png";
import consistentimagestyle2 from "../../../assets/images/pngs/Consistent image style_2.png";
import consistentimagestyle3 from "../../../assets/images/pngs/Consistent image style_3.png";
import defineTheStyleOfTheIconsInDetails1 from "../../../assets/images/pngs/Define the style of the icons in details.png";
import defineTheStyleOfTheIconsInDetails3 from "../../../assets/images/pngs/Define the style of the icons in details3.png";
import defineTheStyleOfTheIconsInDetails2 from "../../../assets/images/pngs/Define the style of the icons in details4.png";
import flatVectorIllustration from "../../../assets/images/pngs/Flat vector illustration.png";
import forSvgImagesUseSimple2dImagesForTraining1 from "../../../assets/images/pngs/For SVG images, use simple 2D images for training.png";
import forSvgImagesUseSimple2dImagesForTraining2 from "../../../assets/images/pngs/For SVG images, use simple 2D images for training2.png";
import forSvgImagesUseSimple2dImagesForTraining3 from "../../../assets/images/pngs/For SVG images, use simple 2D images for training3.png";
import groupOfSubjects from "../../../assets/images/pngs/Group Of subjects.png";
import groupOfSubjects2 from "../../../assets/images/pngs/Group Of subjects2.png";
import groupOfSubjects3 from "../../../assets/images/pngs/Group Of subjects3.png";
import iconsShareTheSameStyle1 from "../../../assets/images/pngs/Icons Share the same style_1.png";
import iconsShareTheSameStyle2 from "../../../assets/images/pngs/Icons Share the same style_2.png";
import iconsShareTheSameStyle3 from "../../../assets/images/pngs/Icons Share the same style_3.png";
import incorporatingBackgrounds1 from "../../../assets/images/pngs/Incorporating Backgrounds_-1.png";
import incorporatingBackgrounds2 from "../../../assets/images/pngs/Incorporating Backgrounds_-2.png";
import incorporatingBackgrounds from "../../../assets/images/pngs/Incorporating Backgrounds_.png";
import mixingImage1 from "../../../assets/images/pngs/Mixing image styles may lead to poor results..png";
import mixingImage2 from "../../../assets/images/pngs/Mixing image styles may lead to poor results.2.png";
import mixingImage3 from "../../../assets/images/pngs/Mixing image styles may lead to poor results.3.png";
import multiPerspective from "../../../assets/images/pngs/Multi-Perspective.png";
import multiPerspective2 from "../../../assets/images/pngs/Multi-Perspective2.png";
import multiPerspective3 from "../../../assets/images/pngs/Multi-Perspective3.png";
import pixelGameArt from "../../../assets/images/pngs/Pixel game art.png";
import shareTheSameStyle1 from "../../../assets/images/pngs/Share the same style_1.png";
import shareTheSameStyle2 from "../../../assets/images/pngs/Share the same style_2.png";
import shareTheSameStyle3 from "../../../assets/images/pngs/Share the same style_3.png";
import transparentorsolidbackground from "../../../assets/images/pngs/Transparent or solid background_.png";
import transparentorsolidbackground2 from "../../../assets/images/pngs/Transparent or solid background_2.png";
import transparentorsolidbackground3 from "../../../assets/images/pngs/Transparent or solid background_3.png";
import waterColor from "../../../assets/images/pngs/Water Color.png";

import { TFunction } from "i18next";

export const getGuidelinesData = (t: TFunction) => {
	return {
		datasetExampleImages: [
			{
				image: flatVectorIllustration,
				alt: "",
				text: t("datasetDescription.useCases.flatVectorIllustration"),
			},
			{
				image: threeDrender,
				alt: "",
				text: t("datasetDescription.useCases.3Drender"),
			},
			{
				image: waterColor,
				alt: "",
				text: t("datasetDescription.useCases.waterColor"),
			},
			{
				image: pixelGameArt,
				alt: "",
				text: t("datasetDescription.useCases.pixelGameart"),
			},
		],
		sideMenuButtons: [
			{
				title: t("taps.datasetDescription.title"),
				id: t("taps.datasetDescription.id"),
			},
			{
				title: t("taps.Style.title"),
				id: t("taps.Style.id"),
				child: true,
			},
			{
				title: t("taps.singleSubject.title"),
				id: t("taps.singleSubject.id"),
				child: true,
			},
		],
		stylesExampleUseCases: [
			{
				images: [shareTheSameStyle3, shareTheSameStyle2, shareTheSameStyle1],
				title: t("types.Style.useCases.shareTheSameStyle.title"),
				description: t("types.Style.useCases.shareTheSameStyle.description"),
			},
			{
				images: [mixingImage1, mixingImage2, mixingImage3],
				title: t("types.Style.useCases.MixingImageStyles.title"),
				description: t("types.Style.useCases.MixingImageStyles.description"),
			},
		],
		singleSubjectExampleUseCases: [
			{
				images: [multiPerspective, multiPerspective2, multiPerspective3],
				title: t("types.singleSubject.useCases.multiPerspective.title"),
				description: t("types.singleSubject.useCases.multiPerspective.description"),
			},
			{
				images: [incorporatingBackgrounds, incorporatingBackgrounds1, incorporatingBackgrounds2],
				title: t("types.singleSubject.useCases.incorporatingBackgrounds.title"),
				description: t("types.singleSubject.useCases.incorporatingBackgrounds.description"),
			},
			{
				images: [transparentorsolidbackground, transparentorsolidbackground2, transparentorsolidbackground3],
				title: t("types.singleSubject.useCases.transparentOrSolidBackground.title"),
				description: t("types.singleSubject.useCases.transparentOrSolidBackground.description"),
			},
			{
				images: [consistentimagestyle, consistentimagestyle2, consistentimagestyle3],
				title: t("types.singleSubject.useCases.consistentImageStyle.title"),
				description: t("types.singleSubject.useCases.consistentImageStyle.description"),
			},
			{
				images: [groupOfSubjects, groupOfSubjects2, groupOfSubjects3],
				title: t("types.singleSubject.useCases.groupOfSubjects.title"),
				description: t("types.singleSubject.useCases.groupOfSubjects.description"),
			},
		],
		iconsExampleUseCases: [
			{
				images: [iconsShareTheSameStyle1, iconsShareTheSameStyle2, iconsShareTheSameStyle3],
				title: t("types.Icons.useCases.shareTheSameStyle.title"),
				description: t("types.Icons.useCases.shareTheSameStyle.description"),
			},
			{
				images: [
					defineTheStyleOfTheIconsInDetails1,
					defineTheStyleOfTheIconsInDetails2,
					defineTheStyleOfTheIconsInDetails3,
				],
				title: t("types.Icons.useCases.DefineTheStyleOfTheIconsInDetails.title"),
				description: t("types.Icons.useCases.DefineTheStyleOfTheIconsInDetails.description"),
			},
			{
				images: [
					forSvgImagesUseSimple2dImagesForTraining1,
					forSvgImagesUseSimple2dImagesForTraining2,
					forSvgImagesUseSimple2dImagesForTraining3,
				],
				title: t("types.Icons.useCases.ForSvgImagesUseSimple2dImagesForTraining.title"),
				description: t("types.Icons.useCases.ForSvgImagesUseSimple2dImagesForTraining.description"),
			},
		],
	};
};
