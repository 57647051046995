function SwapHorizontal({ size }: { size: number }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height={size}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.5454 3.18829C13.7965 2.93724 14.2035 2.93724 14.4546 3.18829L18.9546 7.68829C19.2056 7.93934 19.2056 8.34637 18.9546 8.59743L14.4546 13.0974C14.2035 13.3485 13.7965 13.3485 13.5454 13.0974C13.2944 12.8464 13.2944 12.4393 13.5454 12.1883L16.948 8.78571H5.64286C5.28782 8.78571 5 8.4979 5 8.14286C5 7.78782 5.28782 7.5 5.64286 7.5H16.948L13.5454 4.09743C13.2944 3.84637 13.2944 3.43934 13.5454 3.18829ZM10.5974 10.9026C10.8485 11.1536 10.8485 11.5607 10.5974 11.8117L7.19485 15.2143H18.5C18.855 15.2143 19.1429 15.5021 19.1429 15.8571C19.1429 16.2122 18.855 16.5 18.5 16.5H7.19485L10.5974 19.9026C10.8485 20.1536 10.8485 20.5607 10.5974 20.8117C10.3464 21.0628 9.93934 21.0628 9.68829 20.8117L5.18829 16.3117C4.93724 16.0607 4.93724 15.6536 5.18829 15.4026L9.68829 10.9026C9.93934 10.6515 10.3464 10.6515 10.5974 10.9026Z"
				fill="currentColor"
			/>
		</svg>
	);
}

export default SwapHorizontal;
