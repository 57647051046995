import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Dialog, Link } from "@mui/material";
import { Box } from "@mui/system";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import styles from "./PopupWithSidebar.module.scss";

interface IProps {
	sideMenuButtons: { title: string; id: string; child?: boolean }[];
	selectedLink?: string | null;
	children: ReactNode | ReactNode[];
	handleCloseDialog: () => void;
	open: boolean;
}

const PopupWithSidebar = ({ sideMenuButtons, selectedLink, children, handleCloseDialog, open }: IProps) => {
	const [localSelectedLink, setLocalSelectedLink] = useState(selectedLink ?? sideMenuButtons[0].id);

	const handleLinkClick = (id: string) => {
		setLocalSelectedLink(id);
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	return (
		<Dialog
			classes={{
				paper: `${styles.paper}`,
			}}
			onClose={handleCloseDialog}
			open={open}
		>
			<CloseIcon onClick={handleCloseDialog} className={styles.closeIcon} />
			<Box className={styles.sidePageContainer}>
				<Box className={styles.sideBarContainer}>
					{sideMenuButtons.map((btn: any, index: any) => (
						<Link
							key={index}
							className={clsx(styles.sideMenuButton, {
								[styles.selectedBtn]: localSelectedLink === btn.id,
								[styles.child]: btn.child,
							})}
							href="#"
							onClick={(e) => {
								e.preventDefault();
								handleLinkClick(btn.id);
							}}
						>
							{btn.title}
						</Link>
					))}
				</Box>
				<Box className={styles.mainContentcontainer}>{children}</Box>
			</Box>
		</Dialog>
	);
};

const ObservedComponent = observer(PopupWithSidebar);
export default ObservedComponent;
