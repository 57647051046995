import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import styles from "./TailoredModelTypeGuidelines.module.scss";
export interface IModelProps {
	type: string;
	description: string;
	extraDescription: string;
	examplesOfCommonUseCases: string;
	typeUseCases: any;
	id: string;
}

export const TailoredModelTypeGuidelines = ({
	type,
	description,
	extraDescription,
	examplesOfCommonUseCases,
	typeUseCases,
	id,
}: IModelProps) => {
	return (
		<Box className={styles.container} id={id}>
			<Typography className={styles.type}>{type}</Typography>
			<Typography className={styles.description}>{description}</Typography>
			<Typography className={styles.extraDescription}>{extraDescription}</Typography>
			<Typography className={styles.examplesOfCommonUseCases}>{examplesOfCommonUseCases}</Typography>
			<Box className={styles.useCasesContainer}>
				{typeUseCases.map((useCase: any, index: any) => {
					return (
						<Box
							className={styles.useCase}
							style={{ borderBottom: index == typeUseCases.length - 1 ? "" : " 2px solid #e7e7e7" }}
						>
							<Box className={styles.useCasetextsContainer}>
								<Typography className={styles.useCaseTitle}>{useCase.title}</Typography>
								<Typography className={styles.useCaseDescription}>{useCase.description}</Typography>
							</Box>
							<Box className={styles.useCaseImagesContainer}>
								{useCase.images.map((image: string, index: number) => (
									<Box>
										<img className={styles.img} key={index} src={image} alt="" />
									</Box>
								))}
							</Box>
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(TailoredModelTypeGuidelines);
export default ObservedComponent;
