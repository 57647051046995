function Palette({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10 18.0068C8.01088 18.0068 6.10322 17.2167 4.6967 15.8101C3.29018 14.4036 2.5 12.496 2.5 10.5068C2.5 8.51771 3.29018 6.61006 4.6967 5.20354C6.10322 3.79701 8.01088 3.00684 10 3.00684C14.1417 3.00684 17.5 5.99184 17.5 9.6735C17.5 10.5568 17.105 11.4052 16.4017 12.0302C15.6983 12.6552 14.7442 13.0068 13.75 13.0068H11.6667C11.2949 13.0008 10.9318 13.1194 10.6351 13.3435C10.3384 13.5677 10.1252 13.8846 10.0294 14.2438C9.93362 14.6031 9.9607 14.9841 10.1064 15.3262C10.252 15.6683 10.5079 15.9519 10.8333 16.1318C10.9997 16.2854 11.1138 16.4872 11.1596 16.7089C11.2054 16.9306 11.1806 17.1611 11.0886 17.368C10.9967 17.5749 10.8422 17.7478 10.647 17.8623C10.4517 17.9769 10.2255 18.0275 10 18.0068Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.08333 10.0902C7.54357 10.0902 7.91667 9.71707 7.91667 9.25684C7.91667 8.7966 7.54357 8.4235 7.08333 8.4235C6.6231 8.4235 6.25 8.7966 6.25 9.25684C6.25 9.71707 6.6231 10.0902 7.08333 10.0902Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.4167 7.59017C10.8769 7.59017 11.25 7.21707 11.25 6.75684C11.25 6.2966 10.8769 5.9235 10.4167 5.9235C9.95643 5.9235 9.58333 6.2966 9.58333 6.75684C9.58333 7.21707 9.95643 7.59017 10.4167 7.59017Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.75 10.0902C14.2102 10.0902 14.5833 9.71707 14.5833 9.25684C14.5833 8.7966 14.2102 8.4235 13.75 8.4235C13.2898 8.4235 12.9167 8.7966 12.9167 9.25684C12.9167 9.71707 13.2898 10.0902 13.75 10.0902Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Palette;
