import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import InputLayout from "../../../layout/InputLayout/InputLayout";
import styles from "./RequestAccessForm.module.scss";

const RequestAccessForm = ({ setMessage, message, setPhoneNumber, phoneNumber }: any) => {
	const { t } = useTranslation("translation", { keyPrefix: "requestAccessContainer" });
	return (
		<Box className={styles.container}>
			<InputLayout optional={true} className={styles.inputText} label={t("requestAccess.dropUsAMessage")}>
				<TextField
					value={message}
					onChange={(e) => setMessage(e.target.value)}
					placeholder={t("requestAccess.dropUsAMessage")}
					multiline
					minRows={3}
					InputProps={{
						classes: {
							root: styles.bigTextField,
						},
					}}
				/>
			</InputLayout>
			<Box className={styles.phoneNumberContainer}>
				<InputLayout optional={true} label={t("requestAccess.phoneNumber")}>
					<TextField
						value={phoneNumber}
						onChange={(e) => setPhoneNumber(e.target.value)}
						placeholder="+1 (123) 456-7890"
						fullWidth
						InputProps={{ classes: { root: styles.textField } }}
					/>
				</InputLayout>
			</Box>
		</Box>
	);
};

export default RequestAccessForm;
