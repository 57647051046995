import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import styles from "./CustomDialog.module.scss";

export type IProps = {
	open: boolean;
	onClose: () => void;
	containerClassName?: string;
	children?: ReactNode;
};

const CustomDialog = ({ open, onClose, children, containerClassName }: IProps) => {
	if (!open) {
		return null;
	}

	return (
		<Box className={`${styles.dialog} ${containerClassName}`} onClick={onClose}>
			<Box
				className={styles.container}
				onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the dialog
			>
				{children}
			</Box>
		</Box>
	);
};

const ObservedComponent = observer(CustomDialog);
export default ObservedComponent;
