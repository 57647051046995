import { Box, CircularProgress, Dialog, TextField, Typography } from "@mui/material";
import InputLayout from "../../../layout/InputLayout/InputLayout.tsx";

import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DatasetSVG from "../../../assets/images/svgs/dataset-example.svg";
import Plus from "../../../assets/images/svgs/plus.svg";

import { useNavigate } from "react-router";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { TAILORED_GENERATION_MODEL_STEP } from "../../../constants/TGConstants.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { useSubscription } from "../../../hooks/useSubscription.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import { LinearWithValueLabel } from "../../common/LinearProgressBar/LinearProgressBar.tsx";
import styles from "./TGLayout.module.scss";

interface IProps {
	children: React.ReactNode;
	title: string;
	progressValue?: number;
}

const TGLayout = ({ title, children, progressValue = 0 }: IProps) => {
	const { uiStore, tailoredGenerationStore, authStore } = useAppStore();
	const [open, setOpen] = useState(false);
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation" });
	const navigate = useNavigate();
	const { isOrgBlocked } = useSubscription();

	const createDatasetHandler = async () => {
		const selectedDataset = tailoredGenerationStore.selectedDatasetOption;
		const modelFormDataset = tailoredGenerationStore.modelForm.dataset;
		if (
			selectedDataset &&
			((selectedDataset.id !== modelFormDataset.id && selectedDataset.name === modelFormDataset.name) ||
				(selectedDataset.id === modelFormDataset.id &&
					selectedDataset.description !== modelFormDataset.description &&
					selectedDataset.name === modelFormDataset.name))
		) {
			uiStore.showDialog("TGPDataSetCreationDialogAndChangeDatasetName");
			return;
		}
		const isNameEmpty = tailoredGenerationStore.modelForm.dataset.name.trim() === "";
		const isDescriptionEmpty = tailoredGenerationStore.modelForm.dataset.description.trim() === "";
		if (isNameEmpty || isDescriptionEmpty) {
			if (isNameEmpty) {
				tailoredGenerationStore.setIsValidDatasetName(false);
			}
			if (isDescriptionEmpty) {
				tailoredGenerationStore.setIsEmptyDescription(true);
			}
			return;
		}
		tailoredGenerationStore.isLoading = true;
		tailoredGenerationStore.datasets = [];
		await tailoredGenerationStore.updateDataset(tailoredGenerationStore.modelForm.dataset, true);
		uiStore.showSnackbar("datasetCreationSuccessSnackbar");
		navigate(RouterConstants.TG_DATASET.fullPath);
	};

	return (
		<Box className={styles.container}>
			<Box className={styles.headingContainer}>
				<Box>
					{uiStore.createDataSet ? (
						<Typography className={styles.titleStyle}>{t("datasets.createNewDataset")}</Typography>
					) : uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.CREATE_DATASET ? (
						<InputLayout showLabel={false}>
							<TextField
								variant="outlined"
								value={tailoredGenerationStore.modelForm.name}
								onChange={(e) => {
									tailoredGenerationStore.handleFormChange("name", e.target.value);
									tailoredGenerationStore.setIsValidDatasetName(true);
								}}
								placeholder={t("models.title")}
								fullWidth
								InputProps={{ classes: { root: styles.textField } }}
							/>
						</InputLayout>
					) : (
						<Typography className={styles.titleStyle}>{title}</Typography>
					)}
				</Box>
				{uiStore.tg_step === TAILORED_GENERATION_MODEL_STEP.CREATE_DATASET && (
					<Box className={styles.buttonsGroup} ref={uiStore.TGButtonsRef}>
						{tailoredGenerationStore.isUploading ? (
							<Box className={styles.uploadingCount}>
								<Typography className={styles.uploadingCounter}>
									{t("models.remainingImages", {
										count: tailoredGenerationStore.successUploaded,
										total: tailoredGenerationStore.totalSelectedImages,
									})}
								</Typography>
								<Typography className={styles.uploadingMessage}>
									{t("models.uploadingImages")}
								</Typography>
								<CircularProgress color="inherit" size={16} />
							</Box>
						) : (
							<>
								{!uiStore.createDataSet ? (
									<BriaButton
										className={styles.primaryButton}
										buttonType="primary"
										onClick={() => uiStore.showDialog("TGPublishToTrainingDialog")}
										disabled={
											tailoredGenerationStore.isLoading ||
											tailoredGenerationStore.errorMessage !== null ||
											isOrgBlocked()
										}
										loading={authStore.isLoadingOrgSubscriptions}
									>
										{t("buttons.createModel")}
									</BriaButton>
								) : (
									<BriaButton
										className={styles.primaryButton}
										buttonType="primary"
										onClick={createDatasetHandler}
										disabled={
											tailoredGenerationStore.isLoading ||
											tailoredGenerationStore.errorMessage !== null ||
											(tailoredGenerationStore.selectedDatasetOption?.id ==
												tailoredGenerationStore.modelForm.dataset.id &&
												tailoredGenerationStore.selectedDatasetOption?.description ==
													tailoredGenerationStore.modelForm.dataset.description &&
												tailoredGenerationStore.selectedDatasetOption?.name ==
													tailoredGenerationStore.modelForm.dataset.name) ||
											isOrgBlocked()
										}
										loading={authStore.isLoadingOrgSubscriptions}
									>
										{t("datasets.createDataset")}
									</BriaButton>
								)}
								<BriaButton
									className={styles.button}
									buttonType="tertiaryMedium"
									onClick={() => {
										if (uiStore.tgUploadFileInput.current) {
											uiStore.tgUploadFileInput.current.click();
										}
									}}
									disabled={tailoredGenerationStore.isLoading}
								>
									<img src={Plus} />
									{t("buttons.addImages")}
								</BriaButton>

								<BriaButton
									className={styles.button}
									buttonType="tertiaryMedium"
									onClick={() => {
										uiStore.showDialog("GuidelinesForTailoredModelsDialog");
									}}
								>
									{t("buttons.datasetCreationBestPractices")}
								</BriaButton>

								<Dialog
									open={open}
									onClose={() => {
										setOpen(false);
									}}
									maxWidth={"lg"}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
								>
									<img src={DatasetSVG} alt="" />
								</Dialog>
							</>
						)}
					</Box>
				)}
			</Box>
			<Box className={styles.progressBarContainer}>
				<LinearWithValueLabel progress={progressValue} />
			</Box>
			<Box className={styles.contentContainer}>{children}</Box>
		</Box>
	);
};

const ObservedComponent = observer(TGLayout);
export default ObservedComponent;
