function AlignTop({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.33301 3.3335H16.6663M9.16634 6.66683H10.833C11.7535 6.66683 12.4997 7.41302 12.4997 8.3335V15.0002C12.4997 15.9206 11.7535 16.6668 10.833 16.6668H9.16634C8.24587 16.6668 7.49967 15.9206 7.49967 15.0002V8.3335C7.49967 7.41302 8.24587 6.66683 9.16634 6.66683Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AlignTop;
