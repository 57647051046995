import { useState } from "react";
import RootStore, { IRootStore } from "./root-store";
import { storeContext } from "./store-context";

interface IStoreProviderProps {
	children: React.ReactNode;
}

export default function StoreProvider({ children }: IStoreProviderProps) {
	const [store] = useState<IRootStore>(new RootStore() as IRootStore);
	return <storeContext.Provider value={store}>{children}</storeContext.Provider>;
}
