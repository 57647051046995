import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useAppStore } from "../../../../../../../../hooks/useStores.tsx";
import Search from "../../../../../../../assets/svgs/search.svg";
import { DesignEditorContext } from "../../../../../../../contexts/DesignEditor.tsx";
import styles from "./TabHeader.module.scss";

export type IProps = {
	setSearchBoxVisible: (value: boolean) => void;
	searchBoxVisible: boolean;
	headerText?: string;
	enableSearch?: boolean;
};

const TabHeader = ({ setSearchBoxVisible, searchBoxVisible, headerText, enableSearch = false }: IProps) => {
	const { imagesStore } = useAppStore();
	const { isPopupView } = useContext(DesignEditorContext);

	return (
		<Box className={styles.tapHeader}>
			<Typography className={styles.headerTitle}>{headerText}</Typography>
			<Box className={styles.iconsContainer}>
				{enableSearch && (
					<img
						className={styles.searchImg}
						onClick={() => {
							setSearchBoxVisible(!searchBoxVisible);
						}}
						src={Search}
						alt="search"
					/>
				)}

				{!isPopupView && (
					<Box
						onClick={() => (imagesStore.secondaryTabType = null)}
						sx={{ cursor: "pointer", display: "flex" }}
					>
						<ChevronLeftIcon className={styles.backButton} />
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default observer(TabHeader);
