function Text(_size: number) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
			<path
				d="M3 7.5V5.5H16V7.5M10 5.5V19.5M12 19.5H8M15 13.5V12.5H21V13.5M18 12.5V19.5M17 19.5H19"
				stroke="#5B5B5B"
				stroke-width="1.25"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	);
}
export default Text;
