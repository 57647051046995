import { Box, Typography } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LoadingAnimation.css";

export interface LoadingAnimationProps {
	loading: boolean;
	loadingStringsPosition?: string;
	progressBarTime?: number;
	color?: string;
	loadingStringsTimeout?: number;
	loadingStrings?: string[];
	showAnimation?: boolean;
	showLoadingStrings?: boolean;
}

export default function LoadingAnimation(props: LoadingAnimationProps) {
	const { t } = useTranslation();
	const {
		loading,
		loadingStringsPosition = "bottom",
		progressBarTime = 6,
		color,
		loadingStringsTimeout = 2000,
		loadingStrings = [`${t("loading")}...`, t("pleaseWait"), t("almostThere")],
		showAnimation = true,
		showLoadingStrings = false,
	} = props;

	const [progressBarValue, setProgressBarValue] = useState<number>(0);
	const [progressBarIntervalId, setProgressBarIntervalId] = useState<any>();
	const [loadingStringIntervalId, setLoadingStringIntervalId] = useState<any>();
	const [loadingStringIdx, setLoadingStringIdx] = useState<number>(0);

	const stopProgressBar = () => {
		setProgressBarValue(0);
		clearInterval(progressBarIntervalId);
		setProgressBarIntervalId(null);
	};

	const stopLoadingStringsShuffle = () => {
		setLoadingStringIdx(0);
		clearInterval(loadingStringIntervalId);
		setLoadingStringIntervalId(null);
	};

	const runProgressBar = () => {
		if (!progressBarIntervalId) {
			const id = setInterval(() => {
				setProgressBarValue((prevCounterValue) => {
					const newValue = prevCounterValue + 100 / progressBarTime;
					return newValue > 100 ? 100 : newValue;
				});
			}, 1000);
			setProgressBarIntervalId(id);
		}
	};

	const shuffleLoadingStrings = () => {
		if (showLoadingStrings && loadingStrings.length > 0 && !loadingStringIntervalId) {
			const id = setInterval(() => {
				setLoadingStringIdx((prevIdxValue) => {
					const newValue = prevIdxValue + 1;
					return newValue >= loadingStrings.length ? 0 : newValue;
				});
			}, loadingStringsTimeout);
			setProgressBarIntervalId(id);
		}
	};

	useEffect(() => {
		if (progressBarTime > 0) {
			if (!progressBarIntervalId && loading) {
				runProgressBar();
			} else {
				stopProgressBar();
			}
		}
		if (showLoadingStrings && loadingStrings.length > 0) {
			if (!loadingStringIntervalId && loading) {
				shuffleLoadingStrings();
			} else {
				stopLoadingStringsShuffle();
			}
		}
	}, [loading]);

	return (
		<>
			{loading && (
				<Box
					flexDirection="column"
					className={clsx("container", { ["left"]: loadingStringsPosition === "left" })}
				>
					<Box className="loading">
						{showAnimation && (
							<Box className="animation">
								<Box className="circle circle-a" style={color ? { backgroundColor: color } : {}}></Box>
								<Box className="circle circle-b" style={color ? { backgroundColor: color } : {}}></Box>
								<Box className="circle circle-c" style={color ? { backgroundColor: color } : {}}></Box>
							</Box>
						)}
						{progressBarTime > 0 && (
							<LinearProgress
								className="progress-bar"
								value={progressBarValue}
								variant="determinate"
								color="primary"
							/>
						)}
					</Box>

					{loadingStringsPosition === "bottom" && showLoadingStrings && loadingStrings.length > 0 && (
						<>
							<br></br>
							<br></br>
							<span className="loading-string">
								<Typography>{loadingStrings[loadingStringIdx]}</Typography>
							</span>
						</>
					)}
					{loadingStringsPosition === "left" && showLoadingStrings && loadingStrings.length > 0 && (
						<span className="loading-string">
							<Typography>{loadingStrings[loadingStringIdx]}</Typography>
						</span>
					)}
				</Box>
			)}
		</>
	);
}
