export enum TAILORED_GENERATION_MODEL_STATUS {
	CREATED = "Created",
	IN_PROGRESS = "Training",
	STOPPING = "Stopping",
	STOPPED = "Stopped",
	FAILED = "Failed",
	COMPLETED = "Completed",
}

export enum TAILORED_GENERATION_DATASET_STATUS {
	DRAFT = "Draft",
	COMPLETED = "Active",
	DELETED = "Deleted",
}

export enum TAILORED_GENERATION_MODEL_STEP {
	LIST_MODELS = "list_models",
	LIST_DATASETS = "list_datasets",
	CREATE_MODELS = "create_model",
	CREATE_DATASET = "create_dataset",
	PLAYGROND = "playground",
	VIEW_DATASET = "view_dataset",
	SELECT_TYPE = "select_type",
}

export enum TAILORED_GENERATION_TYPES {
	SINGLE_SUBJECT = "Single subject",
	STYLE = "Style",
	ICONS = "Icons"
}
