import { Close } from "@mui/icons-material";
import { Alert, Box, Dialog, FormControlLabel, IconButton, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AESCipher } from "../../../helpers/encryption.ts";
import { getSelectedOrganization } from "../../../helpers/localStorage.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { UserOrganization } from "../../../models/organization.ts";
import BillingService from "../../../services/BillingService.ts";
import { validateEmail } from "../../../utils";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import BriaCheckbox from "../../common/BriaCheckbox/BriaCheckbox.tsx";
import BriaDropdown, { DropDownItem } from "../../common/BriaDropDown/BriaDropDown.tsx";
import BriaInput from "../../common/BriaInput/BriaInput.tsx";
import ConfirmationPopup from "../../common/ConfirmationPopup/ConfirmationPopup.tsx";
import CustomDatePicker from "../../common/DatePicker/DatePicker.tsx";
import OrganizationsDropdown from "../../common/OrganizationsDropdown/OrganizationsDropdown.tsx";
import styles from "./SubscriptionLinkGeneratorPopup.module.scss";

interface Product {
	name: string;
	price_ids: string[];
}

interface TermsAndConditionFile {
	name: string;
	url: string;
}

interface PaymentMethodConfiguration {
	id: string;
	name: string;
}

const SubscriptionLinkGeneratorPopup = () => {
	const { uiStore } = useAppStore();
	const aesCipher = new AESCipher();
	const queryService = new BillingService();
	const { t } = useTranslation("translation", { keyPrefix: "subscriptionLinkGeneratorPopup" });
	const [productsLoading, setProductsLoading] = useState<boolean>(false);
	const [termsAndConditionFilesLoading, setTermsAndConditionFilesLoading] = useState<boolean>(false);
	const [paymentMethodConfigurationsLoading, setPaymentMethodConfigurationsLoading] = useState<boolean>(false);
	const [isSnackbarOpen, setSnackbarOpen] = useState(false);
	const [selectedOrg, setSelectedOrg] = useState<UserOrganization | null>(getSelectedOrganization());
	const [selectedPrices, setSelectedPrices] = useState<string[]>([]);
	const [products, setProducts] = useState<DropDownItem[]>([]);
	const [filteredProducts, setFilteredProducts] = useState<DropDownItem[]>([]);
	const [includeCoupon, setIncludeCoupon] = useState<boolean>(false);
	const [isTermsPopupOpen, setIsTermsPopupOpen] = useState<boolean>(false);
	const [additionalEmails, setAdditionalEmails] = useState<string>();
	const [selectedPaymentMethodConfiguration, setSelectedPaymentMethodConfiguration] = useState<string>();
	const [paymentMethodConfigurations, setPaymentMethodConfigurations] = useState<DropDownItem[]>([]);
	const [termsAndConditionsFiles, setTermsAndConditionsFiles] = useState<DropDownItem[]>([]);
	const [selectedTermsAndConditions, setSelectedTermsAndConditions] = useState<string>();
	const [validityDate, setValidityDate] = useState<Date | undefined>();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = () => {
		setProductsLoading(true);
		setTermsAndConditionFilesLoading(true);
		setPaymentMethodConfigurationsLoading(true);
		queryService
			.listProducts()
			.then((products: Product[]) => {
				const productsList = products.map((product) => ({
					key: product.name,
					value: product.price_ids.join(","),
				}));
				setProducts(productsList);
				setFilteredProducts(productsList);
			})
			.finally(() => {
				setProductsLoading(false);
			});
		queryService
			.listTermsAndConditionFiles()
			.then((termsAndConditionFiles: TermsAndConditionFile[]) => {
				setTermsAndConditionsFiles(
					termsAndConditionFiles.map((termsAndConditionFile) => ({
						key: termsAndConditionFile.name,
						value: termsAndConditionFile.url,
					})),
				);
			})
			.finally(() => {
				setTermsAndConditionFilesLoading(false);
			});
		queryService
			.listPaymentMethodConfigurations()
			.then((paymentMethodConfigurations: PaymentMethodConfiguration[]) => {
				setPaymentMethodConfigurations(
					paymentMethodConfigurations.map((paymentMethodConfiguration) => ({
						key: paymentMethodConfiguration.name,
						value: paymentMethodConfiguration.id,
					})),
				);
			})
			.finally(() => {
				setPaymentMethodConfigurationsLoading(false);
			});
	};

	const onSubmit = async () => {
		const encryptedUrlSearchParams = new URLSearchParams();
		if (selectedOrg?.organization.uid) {
			encryptedUrlSearchParams.append("orgId", selectedOrg?.organization.uid);
		}
		if (additionalEmails && additionalEmails.length > 0) {
			let userEmails = additionalEmails.split(";");
			userEmails = userEmails.filter((email: string) => validateEmail(email));
			encryptedUrlSearchParams.append("userEmails", userEmails.join(";"));
		}
		if (selectedPaymentMethodConfiguration) {
			encryptedUrlSearchParams.append("paymentMethodConfigurationId", selectedPaymentMethodConfiguration);
		}
		if (validityDate) {
			validityDate.setHours(0, 0, 0, 0);
			encryptedUrlSearchParams.append("validityDate", validityDate.toISOString());
		}
		if (
			selectedTermsAndConditions &&
			selectedTermsAndConditions.length > 0 &&
			selectedTermsAndConditions !== "None"
		) {
			encryptedUrlSearchParams.append("termsAndConditions", selectedTermsAndConditions);
		}
		encryptedUrlSearchParams.append("priceIds", selectedPrices.join(","));
		encryptedUrlSearchParams.append("allowPromotionCodes", Boolean(includeCoupon).toString());

		const encryptedSearchParams = encodeURIComponent(await aesCipher.encrypt(encryptedUrlSearchParams.toString()));
		const urlSearchParams = new URLSearchParams("pricing=true&enc=" + encryptedSearchParams);
		const linkToCopy = `${location.origin}/console?${urlSearchParams.toString()}`;
		await navigator.clipboard.writeText(linkToCopy);
		setSnackbarOpen(true);
	};

	const onClose = () => {
		uiStore.hideDialog("SubscriptionLinkGeneratorPopup");
		setProductsLoading(false);
		setSelectedOrg(null);
		setSelectedPrices([]);
		setPaymentMethodConfigurations([]);
		setProducts([]);
		setTermsAndConditionsFiles([]);
		setIncludeCoupon(false);
		setIsTermsPopupOpen(false);
		setAdditionalEmails(undefined);
		setSelectedTermsAndConditions(undefined);
		setSelectedPaymentMethodConfiguration(undefined);
		setValidityDate(undefined);
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	return (
		<Dialog
			classes={{
				paper: clsx(styles.container),
			}}
			onClose={onClose}
			open={uiStore.SubscriptionLinkGeneratorPopup}
		>
			<IconButton onClick={onClose} className={styles.closeButton}>
				<Close />
			</IconButton>
			<Box className={styles.content}>
				<Typography className={styles.title}>{t("title")}</Typography>
				<Box className={styles.formContainer}>
					<Box className={styles.formFieldGroup}>
						<Typography className={styles.formFieldLabel}>{t("selectOrg")}</Typography>
						<OrganizationsDropdown
							placeholder={t("orgIsNotRequired")}
							noneOption={true}
							skipGlobalOrgUpdate={true}
							labelFontWeight={400}
							labelFontSize={"14px"}
							reload={false}
							onOrgChange={(value) => {
								setSelectedOrg(value);
							}}
							className={clsx(styles.formField, styles.orgsDropdown)}
						/>
					</Box>
					<Box className={styles.formFieldGroup}>
						<Typography className={styles.formFieldLabel}>{t("additionalEmails")}</Typography>
						<BriaInput
							disabled={!selectedOrg}
							placeholder={t("additionalEmailsPlaceholder")}
							onChange={(e) => {
								setAdditionalEmails(e.target.value);
							}}
							inputProps={{ className: styles.formField }}
							fullWidth
							value={additionalEmails}
							height={"40px"}
							className={styles.formField}
						/>
					</Box>
					<Box className={styles.formFieldGroup}>
						<Typography className={styles.formFieldLabel}>{t("selectProducts")}</Typography>
						<BriaDropdown
							searchable={true}
							onClose={() => setFilteredProducts(products)}
							onSearchChange={(e) => {
								if (e.target.value && e.target.value.length > 0) {
									const filteredProducts = products.filter((item) =>
										item.key.toLowerCase().includes(e.target.value.toLowerCase()),
									);
									setFilteredProducts(filteredProducts);
								} else {
									setFilteredProducts(products);
								}
							}}
							searchIcon={true}
							SearchPlaceholder={t("searchProductsPlaceholder")}
							placeholder={t("selectProductsPlaceholder")}
							multiple={true}
							selectMultipleCheckbox={true}
							value={selectedPrices}
							items={filteredProducts}
							loading={productsLoading}
							onChange={(e) => {
								setSelectedPrices(e.target.value);
							}}
							handleDelete={(keyToDelete: string | number) => {
								const index = selectedPrices.indexOf(keyToDelete as string);
								if (index > -1) {
									selectedPrices.splice(index, 1);
								}
								setSelectedPrices([...selectedPrices]);
							}}
							width={"100%"}
							labelFontWeight={400}
							labelFontSize={"14px"}
							minHeight="auto !important"
						/>
					</Box>
					<Box className={clsx(styles.formFieldGroup, styles.includeCouponCheckbox)}>
						<FormControlLabel
							className={clsx(styles.formFieldLabel)}
							control={
								<BriaCheckbox
									checked={includeCoupon}
									onChange={(e) => setIncludeCoupon(e.target.checked)}
								/>
							}
							label={t("includeCoupon")}
						/>
					</Box>
					<Box className={styles.formFieldGroup}>
						<Typography className={styles.formFieldLabel}>{t("validityDate")}</Typography>
						<CustomDatePicker
							value={validityDate}
							placement={"auto"}
							cleanable={true}
							onChange={(value) => {
								setValidityDate(value ?? undefined);
							}}
						></CustomDatePicker>
					</Box>
					<Box className={styles.formFieldGroup}>
						<Typography className={styles.formFieldLabel}>{t("termsAndConditions")}</Typography>
						<BriaDropdown
							placeholder={t("termsAndConditionsPlaceholder")}
							value={selectedTermsAndConditions}
							items={[{ key: "None", value: "None" }, ...termsAndConditionsFiles]}
							loading={termsAndConditionFilesLoading}
							onChange={(e) => {
								setSelectedTermsAndConditions(e.target.value);
							}}
							width={"100%"}
							labelFontWeight={400}
							labelFontSize={"14px"}
							minHeight="auto !important"
						/>
					</Box>
					<Box className={styles.formFieldGroup}>
						<Typography className={styles.formFieldLabel}>{t("paymentMethodConfigurations")}</Typography>
						<BriaDropdown
							placeholder={t("paymentMethodConfigurationsPlaceholder")}
							value={selectedPaymentMethodConfiguration}
							items={paymentMethodConfigurations}
							loading={paymentMethodConfigurationsLoading}
							onChange={(e) => {
								setSelectedPaymentMethodConfiguration(e.target.value);
							}}
							width={"100%"}
							labelFontWeight={400}
							labelFontSize={"14px"}
							minHeight="auto !important"
						/>
					</Box>
					<Box className={styles.buttonsContainer}>
						<BriaButton
							buttonType="textMedium"
							color="secondary"
							className={styles.button}
							disabled={!selectedTermsAndConditions || selectedTermsAndConditions === "None"}
							onClick={() => {
								setIsTermsPopupOpen(true);
							}}
						>
							{t("viewTermsAndConditions")}
						</BriaButton>
						<BriaButton
							buttonType="primary"
							color="secondary"
							className={styles.button}
							disabled={selectedPrices.length === 0 || !selectedTermsAndConditions}
							onClick={onSubmit}
						>
							{t("submit")}
						</BriaButton>
					</Box>
				</Box>
			</Box>
			<Snackbar open={isSnackbarOpen} autoHideDuration={2000} onClose={handleCloseSnackbar}>
				<Alert onClose={handleCloseSnackbar} severity="success">
					{t("copyToClipboardSuccess")}
				</Alert>
			</Snackbar>
			<ConfirmationPopup
				paperClassName={styles.termsPopup}
				contentClassName={styles.termsPopupContent}
				title={""}
				maxWidth={"unset"}
				description={
					<embed
						src={`${selectedTermsAndConditions}?#scrollbar=0&toolbar=0&navpanes=0&view=FitH`}
						type="application/pdf"
						width="100%"
						height="100%"
					/>
				}
				confirmButtonText={t("termsPopup.close")}
				hideCancel={true}
				onClick={async () => {
					setIsTermsPopupOpen(false);
				}}
				onClose={() => {
					setIsTermsPopupOpen(false);
				}}
				open={isTermsPopupOpen}
			/>
		</Dialog>
	);
};

export default observer(SubscriptionLinkGeneratorPopup);
