import clsx from "clsx";
import { isBefore } from "date-fns";
import { DatePicker } from "rsuite";
import "rsuite/DatePicker/styles/index.css";
import { TypeAttributes } from "rsuite/cjs/@types/common";
import styled from "styled-components";

const StyledDatePicker = styled.div`
	.rs-picker {
		width: 100%;
		height: 40px;
	}

	.rs-input-group {
		border: 1px solid #b3b3b9;
	}

	.rs-input-group:not(.rs-input-group-disabled):hover,
	.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
		border-color: #1a0638;
	}

	.rs-input-group:focus-within {
		border-color: #fff;
		outline: 1px solid #8800ff;
	}

	input::placeholder {
		font-size: 14px;
		color: #1a0638;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}

	.without-icon {
		.rs-input-group {
			width: 180px;
		}

		.rs-input-group-addon {
			display: none;
		}
	}

	input {
		pointer-events: none;
	}
`;

interface IProps {
	loading?: boolean;
	placement?: TypeAttributes.Placement | undefined;
	value?: Date | undefined;
	onChange: (value: Date | null) => void;
	cleanable: boolean;
}

const CustomDatePicker: React.FC<IProps> = ({ loading, placement, value, cleanable = true, ...rest }) => {
	return (
		<StyledDatePicker>
			<DatePicker
				disabled={loading}
				loading={loading}
				placement={placement}
				value={value}
				shouldDisableDate={(date) => isBefore(date, new Date())}
				cleanable={cleanable}
				className={clsx({
					"without-icon": !cleanable && !loading,
				})}
				{...rest}
			/>
		</StyledDatePicker>
	);
};

export default CustomDatePicker;
