import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import { AspectRatio } from "../../components/common/DropDowns/StaticDropdown";
import RouterConstants from "../../constants/RouterConstants";
import { useAppStore } from "../../hooks/useStores";
import PlaygroundImages from "./Images/PlaygroundImages";
import styles from "./Playground.module.scss";
import { PlaygroundConfigs } from "./playground-store";

interface IPlayground {
	selectedConfig?: PlaygroundConfigs;
	location?: {
		state: {
			prompt?: string;
			aspectRatio?: AspectRatio;
		};
	};
}

const Playground = ({ location: propLocation, selectedConfig }: IPlayground) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground" });
	const { t: appsTrans } = useTranslation("translation", { keyPrefix: "platformHub.Cards" });
	const { playgroundStore, uiStore, textToImageStore } = useAppStore();
	const location = propLocation || useLocation();
	const navigateOptions = location.state;
	const generateFromNavigation = !!(
		navigateOptions &&
		navigateOptions.prompt &&
		((textToImageStore.config.model && textToImageStore.config.model_version) || textToImageStore.config.model_url)
	);

	useEffect(() => {
		const appsMatch = window.location.pathname.match(/\/(apps|fox-apps)\/([^/]+)/);

		if (appsMatch) {
			const selectedApp = appsMatch[2].replace(/-([a-z])/g, (_match, letter) => letter.toUpperCase());
			playgroundStore.selectedConfig = textToImageStore.isTextToVector()
				? "textToImage"
				: selectedConfig || (selectedApp as PlaygroundConfigs);
		}

		uiStore.hideSideBar();
		playgroundStore.selectedConfig === "textToImage" &&
			uiStore.showBackButton(
				RouterConstants.APPS.path,
				undefined,
				t(`${playgroundStore.selectedConfig}.backBtn`),
				undefined,
				undefined,
				undefined,
				textToImageStore.isTextToVector() ? appsTrans(`textToVector.title`) : appsTrans(`textToImage.title`),
			);

		playgroundStore.selectedConfig === "productPlacement" &&
			uiStore.showBackButton(
				`/${RouterConstants.PRODUCT_PLACEMENT_GALLERY.fullPath}`,
				undefined,
				t(`${playgroundStore.selectedConfig}.backBtn`),
				undefined,
				undefined,
				undefined,
				t(`${playgroundStore.selectedConfig}.title`),
			);

		return () => {
			uiStore.showSideBar();
			uiStore.hideBackButton();
		};
	}, []);

	useEffect(() => {
		if (generateFromNavigation) {
			textToImageStore.handleConfigChange("prompt", navigateOptions.prompt);
			navigateOptions.aspectRatio &&
				textToImageStore.handleConfigChange("aspect_ratio", navigateOptions.aspectRatio);
			textToImageStore.generateTextToImage();
			window.history.replaceState({}, "");
		} else if (navigateOptions?.prompt === "" || navigateOptions?.aspectRatio) {
			textToImageStore.handleConfigChange("prompt", "");
			textToImageStore.handleConfigChange("aspect_ratio", "4:3");
		}
	}, [generateFromNavigation]);

	// const handleConfigToggle = (_e: any, selectedConfig: PlaygroundConfigs) => {
	// 	playgroundStore.selectedConfig = selectedConfig;
	// 	selectedConfig === "textToImage"
	// 		? uiStore.showBackButton(RouterConstants.APPS.path, undefined, t(`${selectedConfig}.backBtn`))
	// 		: uiStore.hideBackButton();
	// };

	return (
		<Box className={playgroundStore?.isText2ImagePopupOpened ? styles.rootModal : styles.root}>
			<Box className={styles.configsWrapper}>
				{/*<BriaToggleButtonGroup*/}
				{/*	className={styles.BriaToggleButtonGroup}*/}
				{/*	value={playgroundStore.selectedConfig}*/}
				{/*	onChange={handleConfigToggle}*/}
				{/*>*/}
				{/*	<NavLink*/}
				{/*		to={`/${RouterConstants.APPS.path}/${RouterConstants.TEXT_TO_IMAGE.path}`}*/}
				{/*		className={styles.fullSize}*/}
				{/*	>*/}
				{/*		<ToggleButton value="textToImage" className={styles.fullSize}>*/}
				{/*			{t(`textToImage.toggleBtn`)}*/}
				{/*		</ToggleButton>*/}
				{/*	</NavLink>*/}
				{/*	<NavLink*/}
				{/*		to={`/${RouterConstants.APPS.path}/${RouterConstants.IMAGE_TO_IMAGE.path}`}*/}
				{/*		className={styles.fullSize}*/}
				{/*	>*/}
				{/*		<ToggleButton value="imageToImage" className={styles.fullSize}>*/}
				{/*			{t(`imageToImage.toggleBtn`)}*/}
				{/*		</ToggleButton>*/}
				{/*	</NavLink>*/}
				{/*</BriaToggleButtonGroup>*/}
				<Outlet />
			</Box>
			<PlaygroundImages />
		</Box>
	);
};

export default observer(Playground);
