import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../../../components/common/BriaTable/BriaTable";
import { PaginatedItems } from "../../../models/common";
import { Placement } from "../../../models/placement";
import QueryService from "../../../utils/QueryService";

export interface IPlacementsStore {
	paginatedPlacements: PaginatedItems<Placement>;
	rowsPerPage: number;
	loadPlacementsRequests: number;
	isLoading: boolean;
	isLoadingEditor: boolean;
	isError: boolean;
	getPlacement(placementId: number): Promise<Placement>;
	loadPlacements(channelsIds: number[], pageNumber: number, filterBy?: FilterByOptions): Promise<void>;
	createPlacement(newPlacement: Placement): Promise<number>;
	updatePlacement(updatedPlacement: Placement): Promise<void>;
	disableLoading(): Promise<void>;
}

export default class PlacementsStore implements IPlacementsStore {
	private queryService: QueryService = new QueryService("/placements");
	private channelService: QueryService = new QueryService("/channels");
	paginatedPlacements: PaginatedItems<Placement> = { total: 0, items: [] };
	rowsPerPage: number = 20;
	loadPlacementsRequests: number = 0;
	isLoading: boolean = false;
	isLoadingEditor: boolean = false;
	isError: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	loadPlacements = async (channelsIds: number[], pageNumber: number, filterBy?: FilterByOptions): Promise<void> => {
		try {
			this.isError = false;
			this.loadPlacementsRequests += 1;
			this.isLoading = true;

			const placements: PaginatedItems<Placement> = await this.channelService.get("/placements", {
				params: {
					channels_ids: channelsIds.join(","),
					filter_by: filterBy,
					page: pageNumber,
					per_page: this.rowsPerPage,
				},
			});

			runInAction(() => {
				this.paginatedPlacements = placements;
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			throw e;
		} finally {
			this.loadPlacementsRequests -= 1;
		}
	};

	getPlacement = async (placementId: number): Promise<Placement> => {
		try {
			this.isLoadingEditor = true;
			const placement: Placement = await this.queryService.get(`/${placementId}`);
			runInAction(() => {
				this.isLoadingEditor = false;
			});
			return placement;
		} catch (e) {
			runInAction(() => {
				this.isLoadingEditor = false;
				this.isError = true;
			});

			return Promise.reject(e);
		}
	};

	disableLoading = async () => {
		// Set isLoading to false only when no calls are in progress
		if (this.loadPlacementsRequests === 0) {
			runInAction(() => {
				this.isLoading = false;
			});
		}
	};

	createPlacement = async (newPlacement: Placement): Promise<number> => {
		this.isError = false;
		try {
			const placementId = await this.queryService.post("/", newPlacement);
			runInAction(() => {
				this.isError = false;
			});
			return placementId;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
			});
			return Promise.reject(`Error creating new placement: ${e.message || e.toString()}`);
		}
	};

	updatePlacement = async (updatedPlacement: Placement): Promise<void> => {
		this.isError = false;
		try {
			await this.queryService.put("/", updatedPlacement);
			runInAction(() => {
				this.paginatedPlacements.items = this.paginatedPlacements?.items.map((placement) =>
					placement.id === updatedPlacement.id ? updatedPlacement : placement,
				);
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
		}
	};
}
