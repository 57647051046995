import { Box, FormControlLabel, FormGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../components/common/BriaCheckbox/BriaCheckbox";
import DebouncedInput from "../../../../components/common/DebouncedInput/DebouncedInput";
import {
	Checkboxes,
	FeaturesEnum,
	FeaturesLabels,
	IframeFormType,
	ImportExportLabels,
	PagesEnum,
	PagesLabels,
} from "../../iframeTypes";
import styles from "./IframeForm.module.scss";

type Props = {
	checkboxes: PagesLabels | FeaturesLabels | ImportExportLabels;
	checkboxConfig: keyof Checkboxes;
	iframeForm: IframeFormType;
	handleFormChange: <K extends keyof IframeFormType>(key: K, value: IframeFormType[K]) => void;
	setPreviewPage?: (previewPage?: PagesEnum) => void;
};

const IframeCheckboxGroup = ({ checkboxes, checkboxConfig, iframeForm, handleFormChange, setPreviewPage }: Props) => {
	const { t } = useTranslation("translation", { keyPrefix: "iframeEditor.form" });

	// TODO: remove disableFeatures once changing all features in summer to "enable_" type
	const disableFeatures: Array<keyof IframeFormType> = [
		"disable_download_psd",
		"disable_upload",
		"disable_save",
		"disable_save_psd",
	];

	const updatePageState = (pageKey: keyof typeof checkboxes, isChecked: boolean) => {
		const updatedEnabledPages = { ...iframeForm["enabled_pages"], [pageKey]: isChecked };
		handleFormChange("enabled_pages", updatedEnabledPages);
		if (!iframeForm.target && isChecked) {
			handleFormChange("target", pageKey);
			setPreviewPage?.(pageKey);
		} else if (iframeForm.target === pageKey && !isChecked) {
			const firstEnabledPage = Object.keys(updatedEnabledPages).find(
				(key) => updatedEnabledPages[key as keyof typeof checkboxes],
			) as PagesEnum;
			handleFormChange("target", firstEnabledPage || undefined);
			setPreviewPage?.(firstEnabledPage || undefined);
		}
	};

	const updateFeatureState = (checkboxKey: keyof typeof checkboxes, isChecked: boolean) => {
		const { remove, blur, replace, expand_background } = iframeForm["enabled_features"] ?? {};

		const updatedBgFeatures = {
			Remove: checkboxKey === FeaturesEnum.Remove ? isChecked : remove,
			Blur: checkboxKey === FeaturesEnum.Blur ? isChecked : blur,
			Replace: checkboxKey === FeaturesEnum.Replace ? isChecked : replace,
			ExpandBackground: checkboxKey === FeaturesEnum.ExpandBackground ? isChecked : expand_background,
		};

		const enableBackground = Object.values(updatedBgFeatures).some((feature) => feature);

		handleFormChange("enabled_features", {
			...iframeForm["enabled_features"],
			[checkboxKey]: isChecked,
			background: enableBackground,
		});
	};

	const updateImportExportState = (checkboxKey: keyof IframeFormType, isChecked: boolean) => {
		if (checkboxKey === "disable_download_psd") {
			handleFormChange("enabled_features", {
				...iframeForm["enabled_features"],
				psd_download: isChecked,
			});
		}
		handleFormChange(checkboxKey, disableFeatures.includes(checkboxKey) ? !isChecked : isChecked);
	};

	const isImportExportChecked = (checkboxKey: keyof IframeFormType): boolean => {
		return disableFeatures.includes(checkboxKey) ? !iframeForm[checkboxKey] : !!iframeForm[checkboxKey];
	};

	return (
		<FormGroup>
			{Object.keys(checkboxes).map((key) => {
				const checkboxKey = key as keyof typeof checkboxes;
				return (
					<Box key={checkboxKey} className={styles.checkboxWithField}>
						<FormControlLabel
							control={
								checkboxConfig === "enabled_pages" ? (
									<BriaCheckbox
										checked={iframeForm[checkboxConfig]?.[checkboxKey]}
										onChange={(e) => {
											updatePageState(checkboxKey, e.target.checked);
										}}
									/>
								) : checkboxConfig === "enabled_features" ? (
									<BriaCheckbox
										checked={iframeForm[checkboxConfig]?.[checkboxKey]}
										onChange={(e) => {
											updateFeatureState(checkboxKey, e.target.checked);
										}}
									/>
								) : (
									<BriaCheckbox
										checked={isImportExportChecked(checkboxKey)}
										onChange={(e) => {
											updateImportExportState(
												checkboxKey as keyof IframeFormType,
												e.target.checked,
											);
										}}
									/>
								)
							}
							label={checkboxes[checkboxKey]}
						/>
						{(checkboxKey as keyof IframeFormType) === "disable_save" && !iframeForm[checkboxKey] && (
							<DebouncedInput
								value={iframeForm?.save_label}
								onChange={(e) => handleFormChange("save_label", e.target.value)}
								placeholder={t("saveLabel.placeholder")}
								InputProps={{ classes: { root: styles.smallTextField } }}
							/>
						)}
					</Box>
				);
			})}
		</FormGroup>
	);
};

export default IframeCheckboxGroup;
