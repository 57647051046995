import { observer } from "mobx-react-lite";
import { useAppStore } from "../../../hooks/useStores.tsx";
import ImageUploadPopup from "../ImageUploadPopup/ImageUploadPopup.tsx";

interface IProps {
	maxWidth?: string;
}

const BriaUploadPopup = ({ maxWidth }: IProps) => {
	const { imagesStore } = useAppStore();
	const handleCloseClick = () => {
		imagesStore.openUploadPopup = false;
	};

	return (
		<ImageUploadPopup
			open={imagesStore.openUploadPopup}
			onUpload={async (e) => {
				if (!imagesStore.isLogoUploading) {
					const res = await imagesStore.uploadImageViaAPI(
						e?.target?.files?.[0],
						imagesStore.uploadToUserGallery.toString(),
					);
					if (res?.visualId) {
						imagesStore.uploadProcessDone = true;
					}
				} else {
					await imagesStore.uploadLogo(e?.target?.files?.[0]);
					imagesStore.uploadProcessDone = true;
				}
				handleCloseClick();
			}}
			handleCloseClick={handleCloseClick}
			maxWidth={maxWidth}
			loading={imagesStore.isImageUploading}
		/>
	);
};

const ObservedComponent = observer(BriaUploadPopup);
export default ObservedComponent;
