import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { isBriaOrg } from "../../../utils";
import PopupWithSidebar from "../../Popups/PopupWithSidebar/PopupWithSidebar.tsx";
import TailoredModelTypeGuidelines from "../TailoredModelTypeGuidelines/TailoredModelTypeGuidelines.tsx";
import styles from "./GuidelinesForTailoredModelsDialog.module.scss";
import { getGuidelinesData } from "./guidelinesData.tsx";

const GuidelinesForTailoredModelsDialog = () => {
	const { uiStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation.guidelinesForTailoredModels" });
	const data = getGuidelinesData(t);
	const [sideMenuButtons, setSideMenuButtons] = useState(data.sideMenuButtons);

	useEffect(() => {
		if (isBriaOrg() && !sideMenuButtons.find((item) => item.title === t("taps.Icons.title"))) {
			setSideMenuButtons((prevButtons) => [
				...prevButtons,
				{
					title: t("taps.Icons.title"),
					id: t("taps.Icons.id"),
					child: true,
				},
			]);
		}
	}, [uiStore.GuidelinesForTailoredModelsDialog]);

	const handleCloseDialog = () => {
		localStorage.removeItem("guidelineActiveTap");
		uiStore.hideDialog("GuidelinesForTailoredModelsDialog");
	};

	return (
		<PopupWithSidebar
			sideMenuButtons={sideMenuButtons}
			selectedLink={localStorage.getItem("guidelineActiveTap")}
			handleCloseDialog={handleCloseDialog}
			open={uiStore.GuidelinesForTailoredModelsDialog}
		>
			<Box className={styles.dataSetDescriptionContainer}>
				<Typography id={t("taps.datasetDescription.id")} className={styles.datasetdescriptionTitle}>
					{t("datasetDescription.title")}
				</Typography>
				<Typography className={styles.datasetDescription}>
					{t("datasetDescription.datasetDescription")}
				</Typography>
				<Typography className={styles.datasetFeatureDescription}>
					{t("datasetDescription.enhanceTrainingPerformance")}
				</Typography>
				<ul>
					<li>
						<Typography className={styles.FeaturesOne}>
							{t("datasetDescription.firstFeaturePart1")}
							<span className={styles.firstFeaturepart2}>
								{t("datasetDescription.firstFeaturePart2")}
							</span>
							{t("datasetDescription.firstFeaturePart3")}
						</Typography>
					</li>
					<li>
						<Typography className={styles.FeaturesTwo}>
							{t("datasetDescription.secondFeaturePart1")}
							<span className={styles.secondFeaturepar2}>
								{t("datasetDescription.secondFeaturePart2")}
							</span>
							{t("datasetDescription.secondFeaturePart3")}
						</Typography>
					</li>
				</ul>
				<Typography className={styles.examplesOfDatasetDescriptions}>
					{t("datasetDescription.examplesOfDatasetDescriptions")}
				</Typography>
				<Box className={styles.datasetExampleImages}>
					{data.datasetExampleImages.map((image: any, index: any) => {
						return (
							<Box>
								<img key={index} src={image.image} alt={image.alt} className={styles.dataSetImage} />
								<Typography className={styles.dataSetImageDescription}>{image.text}</Typography>
							</Box>
						);
					})}
				</Box>
			</Box>
			<Box className={styles.datasetTypesContainer}>
				<TailoredModelTypeGuidelines
					type={t("types.Style.title")}
					description={t("types.Style.description")}
					extraDescription={t("types.Style.extraDescription")}
					examplesOfCommonUseCases={t("types.Style.examplesOfCommonUseCases")}
					typeUseCases={data.stylesExampleUseCases}
					id={t("taps.Style.id")}
				/>
				<TailoredModelTypeGuidelines
					type={t("types.singleSubject.title")}
					description={t("types.singleSubject.description")}
					extraDescription={t("types.singleSubject.extraDescription")}
					examplesOfCommonUseCases={t("types.singleSubject.examplesOfCommonUseCases")}
					typeUseCases={data.singleSubjectExampleUseCases}
					id={t("taps.singleSubject.id")}
				/>
				{isBriaOrg() && (
					<TailoredModelTypeGuidelines
						type={t("types.Icons.title")}
						description={t("types.Icons.description")}
						extraDescription={t("types.Icons.extraDescription")}
						examplesOfCommonUseCases={t("types.Icons.examplesOfCommonUseCases")}
						typeUseCases={data.iconsExampleUseCases}
						id={t("taps.Icons.id")}
					/>
				)}
			</Box>
		</PopupWithSidebar>
	);
};

const ObservedComponent = observer(GuidelinesForTailoredModelsDialog);
export default ObservedComponent;
