import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../../../components/common/BriaTable/BriaTable";
import useErrorToast from "../../../hooks/useErrorToast";
import { PaginatedItems } from "../../../models/common";
import { Font } from "../../../models/font";
import QueryService from "../../../utils/QueryService";

export interface IFontsStore {
	paginatedFonts: PaginatedItems<Font>;
	orgFonts: Font[];
	rowsPerPage: number;
	isLoading: boolean;
	loadingUpload: boolean;
	loadingDelete: boolean;
	isError: boolean;
	getFont(fontId: number): Promise<Font>;
	loadFonts(pageNumber: number, includePublic?: boolean, filterBy?: FilterByOptions): Promise<void>;
	createFont(newFont: Font, fontFile: File): Promise<number>;
	updateFont(updatedFont: Font): Promise<void>;
	deleteFont(fontId: number): Promise<void>;
	getAllOrgFonts(includePublic?: boolean): Promise<void>;
}

export default class FontsStore implements IFontsStore {
	private queryService: QueryService = new QueryService("/fonts");
	errorToast = useErrorToast();
	paginatedFonts: PaginatedItems<Font> = { total: 0, items: [] };
	orgFonts: Font[] = [];
	rowsPerPage: number = 20;
	isLoading: boolean = false;
	loadingUpload: boolean = false;
	loadingDelete: boolean = false;
	isError: boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	loadFonts = async (
		pageNumber: number,
		includePublic: boolean = false,
		filterBy?: FilterByOptions,
	): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			const fonts: PaginatedItems<Font> = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					page: pageNumber,
					per_page: this.rowsPerPage,
					include_public: includePublic,
				},
			});

			runInAction(() => {
				this.paginatedFonts = fonts;
				this.isError = false;
				this.isLoading = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getAllOrgFonts = async (includePublic: boolean = false): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			const fonts: Font[] = await this.queryService.get("/all", {
				params: {
					include_public: includePublic,
				},
			});

			runInAction(() => {
				this.orgFonts = fonts;
				this.isLoading = false;
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getFont = async (fontId: number): Promise<Font> => {
		try {
			const font: Font = await this.queryService.get(`${fontId}`);
			return font;
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});

			return Promise.reject(e);
		}
	};

	createFont = async (newFont: Font, fontFile: File): Promise<number> => {
		this.isError = false;
		this.loadingUpload = true;
		try {
			const formData = new FormData();
			formData.append("file", fontFile);
			formData.append("new_font", JSON.stringify(newFont));
			const fontId = await this.queryService.post("/", formData, {
				"Content-Type": "multipart/form-data",
			});
			runInAction(() => {
				this.isError = false;
				this.loadingUpload = false;
				this.paginatedFonts.items = [{ ...newFont, id: fontId }, ...this.paginatedFonts.items];
			});
			return fontId;
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.loadingUpload = false;
			});
			return Promise.reject(`Error creating new font: ${e.message || e.toString()}`);
		}
	};

	updateFont = async (updatedFont: Font): Promise<void> => {
		this.isError = false;
		try {
			await this.queryService.put("/", updatedFont);
			runInAction(() => {
				this.paginatedFonts.items = this.paginatedFonts?.items.map((font) =>
					font.id === updatedFont.id ? updatedFont : font,
				);
				this.isError = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
		}
	};

	deleteFont = async (fontId: number): Promise<void> => {
		this.isError = false;
		this.loadingDelete = true;
		try {
			await this.queryService.delete(`/${fontId}`);
			runInAction(() => {
				this.paginatedFonts.items = this.paginatedFonts?.items.filter((font) => font.id !== fontId);
				this.isError = false;
				this.loadingDelete = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isError = true;
				this.loadingDelete = false;
			});
		}
	};
}
