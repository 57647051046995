import { getSelectedOrganization } from "../helpers/localStorage";
import { StatusEnum } from "./common";
import { Layout } from "./layout";

export interface Template {
	id: number;
	name: string;
	description: string;
	status: StatusEnum;
	psd_example?: string;
	org_id: string;
	created_at: Date;
	updated_at: Date;
	layouts?: Layout[] | number[];
}

export const defaultTemplate: Template = {
	id: NaN,
	name: "Untitled - Template Name",
	description: "",
	status: StatusEnum.PENDING,
	created_at: new Date(),
	updated_at: new Date(),
	org_id: getSelectedOrganization()?.organization?.uid || "",
};
