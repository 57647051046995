function Paste({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7.49984 4.16667H5.83317C5.39114 4.16667 4.96722 4.34226 4.65466 4.65482C4.3421 4.96738 4.1665 5.39131 4.1665 5.83333V15.8333C4.1665 16.2754 4.3421 16.6993 4.65466 17.0118C4.96722 17.3244 5.39114 17.5 5.83317 17.5H8.33317M7.49984 4.16667C7.49984 5.08714 8.24603 5.83333 9.1665 5.83333H10.8332C11.7536 5.83333 12.4998 5.08714 12.4998 4.16667M7.49984 4.16667C7.49984 3.24619 8.24603 2.5 9.1665 2.5H10.8332C11.7536 2.5 12.4998 3.24619 12.4998 4.16667M15.8332 10V5.83333C15.8332 5.39131 15.6576 4.96738 15.345 4.65482C15.0325 4.34226 14.6085 4.16667 14.1665 4.16667H12.4998M10.8332 14.1667V13.3333C10.8332 13.1123 10.921 12.9004 11.0772 12.7441C11.2335 12.5878 11.4455 12.5 11.6665 12.5H12.4998M14.9998 12.5H15.8332C16.0542 12.5 16.2661 12.5878 16.4224 12.7441C16.5787 12.9004 16.6665 13.1123 16.6665 13.3333V14.1667M16.6665 16.6667V17.5C16.6665 17.721 16.5787 17.933 16.4224 18.0893C16.2661 18.2455 16.0542 18.3333 15.8332 18.3333H14.9998M12.4998 18.3333H11.6665C11.4455 18.3333 11.2335 18.2455 11.0772 18.0893C10.921 17.933 10.8332 17.721 10.8332 17.5V16.6667"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Paste;
