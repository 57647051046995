import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function EyeOffIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 21 21" {...props}>
			<path
				d="M18.377 3.125L2.62695 18.875M11.741 9.76366C12.0694 10.0918 12.2539 10.5369 12.2541 11.0012C12.2543 11.4654 12.07 11.9107 11.7419 12.239C11.4137 12.5674 10.9686 12.752 10.5044 12.7521C10.0401 12.7523 9.59486 12.568 9.26648 12.2399M12.8093 5.19443C12.0591 4.97981 11.2823 4.87229 10.502 4.87505C7.00195 4.87505 4.08558 6.91643 1.75195 11.0001C2.4327 12.1909 3.16245 13.2086 3.94208 14.0521M5.81458 15.6804C7.2417 16.6429 8.8027 17.1251 10.502 17.1251C14.002 17.1251 16.9183 15.0837 19.252 11.0001C18.0541 8.90443 16.7031 7.34693 15.199 6.32668"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.40625"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default EyeOffIcon;
