function TimePast({ size }: { size: number }) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="M12,0A11.972,11.972,0,0,0,4,3.073V1A1,1,0,0,0,2,1V4A3,3,0,0,0,5,7H8A1,1,0,0,0,8,5H5a.854.854,0,0,1-.1-.021A9.987,9.987,0,1,1,2,12a1,1,0,0,0-2,0A12,12,0,1,0,12,0Z"
			/>
			<path
				fill="currentColor"
				d="M12,6a1,1,0,0,0-1,1v5a1,1,0,0,0,.293.707l3,3a1,1,0,0,0,1.414-1.414L13,11.586V7A1,1,0,0,0,12,6Z"
			/>
		</svg>
	);
}

export default TimePast;
