import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useAppStore } from "../../../hooks/useStores";
import { capitalizeFirstLetter } from "../../../utils";
import BriaDropDown, { BriaDropDownProps, DropDownItem } from "../BriaDropDown/BriaDropDown";

type Props = {
	defaultOption?: boolean;
};
const TextToImageMediumsDropdown = ({
	defaultOption = false,
	...rest
}: Props & Partial<BriaDropDownProps<string | string[]>>) => {
	const { textToImageStore } = useAppStore();
	const [mediums, setMediums] = useState<DropDownItem[]>([]);

	useEffect(() => {
		const fetchMediums = async () => {
			await textToImageStore.getMediums();
			setMediums(generateDropDownItems(textToImageStore.mediums));
		};

		fetchMediums();
	}, []);

	const generateDropDownItems = (mediums: string[]): DropDownItem[] => {
		return (defaultOption ? [{ key: "Auto", value: "" }] : []).concat(
			mediums.map((medium) => ({
				key: capitalizeFirstLetter(medium),
				value: medium,
			})),
		);
	};

	return <BriaDropDown items={mediums} loading={textToImageStore.loadingMediums} {...rest} />;
};

export default observer(TextToImageMediumsDropdown);
