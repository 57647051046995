import { Block } from "baseui/block";
import React, { useContext } from "react";
import { CampaignEntityStatus } from "../../../../../models/common";
import { PanelType } from "../../../../constants/app-options.ts";
import { DesignEditorContext } from "../../../../contexts/DesignEditor";
import useAppContext from "../../../../hooks/useAppContext";
import useIsSidebarOpen from "../../../../hooks/useIsSidebarOpen";
import panelItems from "./panelItems";

interface State {
	panel: string;
}
function PanelsList() {
	const { isPopupView } = useContext(DesignEditorContext);
	const [state, setState] = React.useState<State>(
		isPopupView ? { panel: PanelType.TEMPLATES } : { panel: PanelType.CAMPAIGNS },
	);
	const isSidebarOpen = useIsSidebarOpen();
	const { activePanel, activeSubMenu } = useAppContext();

	React.useEffect(() => {
		setState({ panel: activePanel });
	}, [activePanel]);

	React.useEffect(() => {
		if (activeSubMenu) {
			setState({ panel: activeSubMenu });
		} else {
			setState({ panel: activePanel });
		}
	}, [activeSubMenu]);

	// @ts-ignore
	const Component = panelItems[state.panel];
	return (
		<Block
			id="EditorPanelItem"
			$style={{
				width: isSidebarOpen ? "324px" : 0,
				flex: "none",
				display: isSidebarOpen ? "flex" : "none",
				transition: "ease width 0.1s",
				borderRadius: "8px",
				border: "1px solid #E7E7E7",
				background: "#FFF",
				boxShadow: "0px 2px 8px 0px rgba(36, 0, 88, 0.04)",
				// paddingRight: isSidebarOpen ? "16px" : "",
				// paddingLeft: isSidebarOpen ? "16px" : "",
			}}
		>
			{Component && (
				<Component
					key={state.panel}
					type={
						activePanel.toString() == "Campaigns" ? CampaignEntityStatus.DRAFT : CampaignEntityStatus.ACTIVE
					}
				/>
			)}
		</Block>
	);
}

export default PanelsList;
