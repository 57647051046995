import { Box } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../assets/images/icons/DeleteIcon";
import DownloadIcon from "../../../../assets/images/icons/DownloadIcon";
import FullscreenIcon from "../../../../assets/images/icons/Image/FullscreenIcon";
import BriaTooltip from "../../../../components/common/BriaTooltip/BriaTooltip";
import CopyToClipboardButton from "../../../../components/common/CopyToClipboardButton/CopyToClipboardButton";
import { BriaImageProps, ImageErrorType } from "../../../../components/common/Galleries/BriaImage";
import LoadingAnimation from "../../../../components/common/LoadingAnimation/LoadingAnimation";
import { useAppStore } from "../../../../hooks/useStores";
import { PlaygroundImage, PlaygroundResult } from "../../../../models/image-to-image";
import { TextToImageConfigType } from "../../../../models/text-to-image";
import PlaygroundImageOverlay from "../../Images/Overlay/PlaygroundImageOverlay";
import ProductPlacementGallery from "./Gallery/ProductPlacementGallery";
import styles from "./ProductPlacementResult.module.scss";

type Props = {
	playgroundResult: PlaygroundResult;
	rowIndex?: number;
};

const AVG_RESULT_DURATION = 10;

const ProductPlacementResult: React.FC<Props> = ({ playgroundResult, rowIndex }) => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.productPlacement.result" });
	const config = playgroundResult.config as TextToImageConfigType;
	const { playgroundStore } = useAppStore();
	const [openFullScreen, setOpenFullScreen] = useState(false);
	const loading = !!playgroundResult.images.filter((image) => image.loading).length;
	const loadingDuration = AVG_RESULT_DURATION * (config.prompt_enhancement ? 1.2 : 1) * 1.1;

	return (
		<Box className={clsx(styles.ppGalleryWrapper)}>
			<Box className={styles.header}>
				<Box className={styles.headerText}>{config?.prompt}</Box>
				{loading ? (
					<LoadingAnimation loading={loading} progressBarTime={loadingDuration} showAnimation={false} />
				) : (
					<Box className={styles.actionBtns}>
						{rowIndex !== 0 && (
							<CopyToClipboardButton
								className={styles.iconButton}
								textToCopy={config?.prompt}
								tooltipText={t("copyPrompt")}
							/>
						)}
						<BriaTooltip title={t("download")}>
							<DownloadIcon
								className={styles.iconButton}
								onClick={() =>
									playgroundStore.downloadImages(playgroundResult.images, "bria_product_placement")
								}
							/>
						</BriaTooltip>
						{rowIndex !== 0 && (
							<BriaTooltip title={t("delete")}>
								<DeleteIcon
									className={styles.iconButton}
									onClick={() => playgroundStore.deleteResult(playgroundResult.id)}
								/>
							</BriaTooltip>
						)}
						<BriaTooltip title={t("fullScreen")}>
							<FullscreenIcon className={styles.iconButton} onClick={() => setOpenFullScreen(true)} />
						</BriaTooltip>
					</Box>
				)}
			</Box>
			<ProductPlacementGallery
				images={playgroundResult.images.map(
					(image) =>
						({
							ImageOverlay: <PlaygroundImageOverlay {...{ image, hideAiEditorButton: rowIndex === 0 }} />,
							displayOverlay: "customHover",
							aspectRatio: config?.aspect_ratio,
							selectable: rowIndex !== 0,
							htmlJsonData: image.id,
							async: loading,
							onSuccessPulling: () => playgroundStore.onSuccessResult(image),
							onErrorPulling: (errorType: ImageErrorType) =>
								playgroundStore.onErrorResult(image, errorType),
							fullScreenProps: {
								fileName: playgroundStore.getFileName(image),
							},
							...image,
						}) as BriaImageProps & PlaygroundImage,
				)}
				fullScreenProps={{
					open: openFullScreen,
					onClose: () => setOpenFullScreen(false),
					displayMode: "scroll",
				}}
				className={styles.gallery}
			/>
		</Box>
	);
};

export default observer(ProductPlacementResult);
