class AESCipher {
	private readonly bs: number;
	private readonly key: string;

	constructor(secret: string = "+__BRIA.ai__+") {
		this.bs = 16; // AES block size in bytes
		this.key = secret;
	}

	private async generateKey(secret: string): Promise<CryptoKey> {
		const encoder = new TextEncoder();
		const keyMaterial = await crypto.subtle.digest("SHA-256", encoder.encode(secret));
		return crypto.subtle.importKey("raw", keyMaterial, { name: "AES-CBC" }, false, ["encrypt", "decrypt"]);
	}

	async encrypt(raw: string): Promise<string> {
		const key = await this.generateKey(this.key);
		const iv = crypto.getRandomValues(new Uint8Array(this.bs));
		const paddedText = this._pad(raw);
		const encoder = new TextEncoder();
		const encrypted = await crypto.subtle.encrypt({ name: "AES-CBC", iv }, key, encoder.encode(paddedText));
		const encryptedBytes = new Uint8Array(encrypted);
		const finalCipher = new Uint8Array(iv.length + encryptedBytes.length);
		finalCipher.set(iv);
		finalCipher.set(encryptedBytes, iv.length);
		return btoa(String.fromCharCode(...finalCipher));
	}

	private _pad(s: string): string {
		const pad = this.bs - (s.length % this.bs);
		return s + String.fromCharCode(pad).repeat(pad);
	}

	private _unpad(s: string): string {
		const padChar = s.charCodeAt(s.length - 1);
		return s.slice(0, -padChar);
	}

	async decrypt(enc: string): Promise<string> {
		const key = await this.generateKey(this.key);
		const encBytes = new Uint8Array(
			atob(enc)
				.split("")
				.map((c) => c.charCodeAt(0)),
		);
		const iv = encBytes.slice(0, this.bs);
		const encryptedText = encBytes.slice(this.bs);
		const decrypted = await crypto.subtle.decrypt({ name: "AES-CBC", iv }, key, encryptedText);
		const decoder = new TextDecoder();
		const decryptedText = decoder.decode(decrypted);
		return this._unpad(decryptedText);
	}
}

export { AESCipher };
