import { makeAutoObservable, runInAction } from "mobx";
import { Image } from "../DesignEditor/models/image";
import { BrandLogo } from "../models/brandLogo.ts";
import { PaginatedImages, PaginatedItems } from "../models/common.ts";
import QueryService from "../utils/QueryService.ts";

export interface ILogoStore {
	isError: boolean;
	isLogoUploading: boolean;
	logos: PaginatedImages<Image>;
	uploadLogo(file: File | undefined): Promise<void>;
	getLogos(images_per_page: number, page_number: number): Promise<PaginatedItems<BrandLogo>>;
}

export default class LogoStore implements ILogoStore {
	isError: boolean = false;
	isLogoUploading: boolean = false;
	logos: PaginatedImages<Image> = { total_pages: 0, total_images: 0, images: [] };
	private logoQueryService: QueryService = new QueryService("/logos");

	constructor() {
		makeAutoObservable(this);
	}

	getLogos = async (images_per_page: number, page_number: number): Promise<PaginatedItems<BrandLogo>> => {
		try {
			this.isError = false;
			const images: PaginatedItems<BrandLogo> = await this.logoQueryService.get("/get_logos", {
				params: {
					page: page_number,
					per_page: images_per_page,
				},
			});
			return images;
		} catch (e) {
			runInAction(() => {
				this.isError = true;
			});
			return { total: 0, items: [] };
		}
	};

	uploadLogo = async (file: File | undefined): Promise<void> => {
		try {
			this.isLogoUploading = true;
			const formData = new FormData();
			file && formData.append("file", file);
			await this.logoQueryService.post("/upload_logo", formData, {
				"Content-Type": "multipart/form-data",
			});
			runInAction(() => {
				this.isLogoUploading = false;
				this.isError = false;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isError = true;
				this.isLogoUploading = false;
			});
			return Promise.reject(`Error uplaoding image: ${e.message || e.toString()}`);
		}
	};
}
