import { Dialog, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import "swiper/css";
import "swiper/css/navigation";
import { useAppStore } from "../../../hooks/useStores.tsx";
import BriaButton from "../../common/BriaButton/BriaButton.tsx";
import CustomSwiper from "../../common/CustomSwiper/CustomSwiper.tsx";
import styles from "./CaptionDialog.module.scss";

const CaptionDialog = () => {
	const { uiStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "tailored_generation.models.stepper.createDataSet" });
	const swiperItems = [
		"../../../assets/images/gifs/tg_caption_dialog_1.gif",
		"../../../assets/images/gifs/tg_caption_dialog_2.gif",
		"../../../assets/images/gifs/tg_caption_dialog_3.gif",
	];

	return (
		<Box className={styles.container}>
			<Dialog
				onClose={() => {
					uiStore.hideDialog("TGCaptionDialog");
				}}
				open={uiStore.TGCaptionDialog}
			>
				<Box className={styles.dialogContent}>
					<CustomSwiper items={swiperItems} />
					<Typography className={styles.title}>{t("captionDialog.title")}</Typography>
					<Typography className={styles.description}>{t("captionDialog.description")}</Typography>
					<BriaButton
						buttonType="primary"
						className={styles.button}
						onClick={() => {
							uiStore.hideDialog("TGCaptionDialog");
						}}
					>
						{t("captionDialog.button")}
					</BriaButton>
				</Box>
			</Dialog>
		</Box>
	);
};

const ObservedComponent = observer(CaptionDialog);
export default ObservedComponent;
