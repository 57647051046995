import { styled } from "baseui";
import { Theme } from "baseui/theme";
import useContextMenuTimelineRequest from "../../../../../hooks/useContextMenuTimelineRequest";
import SceneContextMenu from "./SceneContextMenu/SceneContextMenu";
import Scenes from "./Scenes/Scenes";

const Container = styled<"div", {}, Theme>("div", ({ $theme }) => ({
	background: $theme.colors.white,
	marginRight: "8px",
	marginBottom: "8px",
	borderRadius: "8px",
	border: "1px solid #e7e7e7",
	overflowX: "auto",
	maxWidth: "calc(-268px + 90vw)",
	overflowY: "hidden",
}));

export default function () {
	const contextMenuTimelineRequest = useContextMenuTimelineRequest();

	return (
		<>
			<Container>
				<Scenes />
				{/* <Common /> */}
			</Container>
			{contextMenuTimelineRequest.visible && <SceneContextMenu />}
		</>
	);
}
