import Add from "./Add";
import AddCircleOutline from "./AddCircleOutline";
import AlignBottom from "./AlignBottom";
import AlignCenter from "./AlignCenter";
import AlignLeft from "./AlignLeft";
import AlignMiddle from "./AlignMiddle";
import AlignRight from "./AlignRight";
import AlignTop from "./AlignTop";
import Alignment from "./Alignment";
import ArrowBackOutline from "./ArrowBackOutline";
import Background from "./Background";
import Brand from "./Brands";
import Campaigns from "./Campaigns";
import Color from "./Color";
import Compress from "./Compress";
import Copy from "./Copy";
import Customize from "./Customize";
import Delete from "./Delete";
import Dots from "./Dots";
import Download from "./Download";
import Duplicate from "./Duplicate";
import DuplicateIcon from "./DuplicateIcon";
import EditWithAI from "./EditWithAI";
import Elements from "./Elements";
import Expand from "./Expand";
import FullView from "./FullView";
import GenerateBackground from "./GenerateBackground";
import Graphics from "./Graphics";
import GridView from "./GridView";
import Images from "./Images";
import InformationCircleOutline from "./InformationCircleOutline";
import InsertImage from "./InsertImage";
import Layers from "./Layers";
import Locked from "./Locked";
import Logo from "./Logo";
import Palette from "./Palette";
import Paste from "./Paste";
import Redo from "./Redo";
import Refresh from "./Refresh";
import RemoveCircleOutline from "./RemoveCircleOutline";
import Resize from "./Resize";
import Search from "./Search";
import Sticker from "./Sticker";
import SwapHorizontal from "./SwapHorizontal";
import Templates from "./Templates";
import Text from "./Text";
import TextSize from "./TextSize";
import TimePast from "./TimePast";
import Underline from "./Underline";
import Undo from "./Undo";
import Unlocked from "./Unlocked";
import Uploads from "./Uploads";
import Uploads1 from "./Uploads1.tsx";
import Video from "./Video";

class Icons {
	static Background = Background;
	static Elements = Elements;
	static Text = Text;
	static Templates = Templates;
	static Search = Search;
	static Images = Images;
	static Videos = Video;
	static Graphics = Graphics;
	static Uploads = Uploads;
	static Uploads1 = Uploads1;
	static Logo = Logo;
	static Compress = Compress;
	static Expand = Expand;
	static Undo = Undo;
	static Redo = Redo;
	static Refresh = Refresh;
	static Layers = Layers;
	static TimePast = TimePast;
	static AddCircleOutline = AddCircleOutline;
	static RemoveCircleOutline = RemoveCircleOutline;
	static InformationCircleOutline = InformationCircleOutline;
	static Customize = Customize;
	static SwapHorizontal = SwapHorizontal;
	static ArrowBackOutline = ArrowBackOutline;
	static Underline = Underline;
	static Add = Add;
	static Campaigns = Campaigns;
	static Brands = Brand;
	static Color = Color;
	static InsertImage = InsertImage;
	static GenerateBackground = GenerateBackground;
	static EditWithAI = EditWithAI;
	static Resize = Resize;
	static DuplicateIcon = DuplicateIcon;
	static AlignBottom = AlignBottom;
	static AlignCenter = AlignCenter;
	static AlignLeft = AlignLeft;
	static AlignMiddle = AlignMiddle;
	static AlignRight = AlignRight;
	static AlignTop = AlignTop;
	static Alignment = Alignment;
	static Copy = Copy;
	static Delete = Delete;
	static Dots = Dots;
	static Duplicate = Duplicate;
	static Locked = Locked;
	static Paste = Paste;
	static Unlocked = Unlocked;
	static Palette = Palette;
	static TextSize = TextSize;
	static Sticker = Sticker;
	static GridView = GridView;
	static FullView = FullView;
	static Download = Download;
}

export default Icons;
