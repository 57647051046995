function EditWithAI({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_7236_9683)">
				<path
					d="M9.6766 5.33512H9.68326M9.00993 13.3351H4.34326C3.81283 13.3351 3.30412 13.1244 2.92905 12.7493C2.55398 12.3743 2.34326 11.8656 2.34326 11.3351V4.66846C2.34326 4.13802 2.55398 3.62932 2.92905 3.25424C3.30412 2.87917 3.81283 2.66846 4.34326 2.66846H11.0099C11.5404 2.66846 12.0491 2.87917 12.4241 3.25424C12.7992 3.62932 13.0099 4.13802 13.0099 4.66846V6.33512M2.34326 10.0018L5.00993 7.33513C5.6286 6.7398 6.39126 6.7398 7.00993 7.33513L8.34326 8.66846"
					stroke="#5B5B5B"
					stroke-width="1.25"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M10.3433 11.3351C11.816 11.3351 13.0099 12.529 13.0099 14.0018C13.0099 12.529 14.2038 11.3351 15.6766 11.3351C14.2038 11.3351 13.0099 10.1412 13.0099 8.66846C13.0099 10.1412 11.816 11.3351 10.3433 11.3351Z"
					stroke="#5B5B5B"
					stroke-width="1.25"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_7236_9683">
					<rect width="16" height="16" fill="white" transform="translate(0.343262 0.00195312)" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default EditWithAI;
