import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Box, Dialog } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { AIEditorOptions, AIEditorOptionsEnum } from "../../../models/aiEditor.ts";
import ImageEditor from "../ImageEditor/ImageEditor.tsx";
import Objects from "../Objects/Objects.tsx";
import Sidebar from "../Sidebar/Sidebar.tsx";
import styles from "./AIEditorPopup.module.scss";

interface IProps {
	maxWidth?: string;
}

const AIEditorPopup = ({ maxWidth }: IProps) => {
	const { aiEditorStore } = useAppStore();

	const handleCloseClick = () => {
		aiEditorStore.setProperty("aiEditorPopup", false);
	};

	return (
		<Dialog
			classes={{
				paper: `${styles.container}`,
			}}
			onClose={() => {
				if (!aiEditorStore.imageViewerOpen) {
					aiEditorStore.setProperty("aiEditorPopup", false);
				}
			}}
			open={aiEditorStore.aiEditorPopup}
			maxWidth={false}
			PaperProps={{
				style: {
					maxWidth: maxWidth,
				},
			}}
		>
			<Sidebar items={AIEditorOptions} />
			<Box className={styles.toolsEditor}>
				{aiEditorStore.selectedEditorTabLabel === AIEditorOptionsEnum.Objects ? <Objects /> : null}
			</Box>
			<Box className={styles.imageEditor}>
				<ImageEditor />
			</Box>
			<CloseIcon onClick={handleCloseClick} className={styles.closeIcon} />
		</Dialog>
	);
};

const ObservedComponent = observer(AIEditorPopup);
export default ObservedComponent;
