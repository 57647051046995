import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useAppStore } from "../../../hooks/useStores.tsx";
import ImageError from "../ImageError/ImageError.tsx";

import { useTranslation } from "react-i18next";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation.tsx";
import styles from "./GalleryImage.module.scss";

export default function GalleryImage({
	key,
	image,
	imageWidth,
	handleImgClick,
	offensiveGeneration,
	retry,
	isFakeUrl,
	imageLoadedCallback,
	imageErrorCallBack,
	passedImageHasTechnicalError,
	imageBorderRadius,
}: {
	key: string;
	image: any;
	imageWidth: any;
	handleImgClick: (image: any) => any;
	offensiveGeneration: boolean;
	retry?: boolean;
	isFakeUrl?: boolean;
	imageLoadedCallback?: () => void;
	imageErrorCallBack?: () => void;
	passedImageHasTechnicalError?: boolean;
	generatedFlag?: boolean;
	imageBorderRadius?: string;
	retryMaxAttempts?: number;
	retrySleepSecs?: number;
}) {
	const { tailoredGenerationStore } = useAppStore();
	const [imageHasTechnicalError, setImageHasTechnicalError] = useState(passedImageHasTechnicalError);
	const [passedImageUrl, setPassedImageUrl] = useState(image.finalUrl);
	const [isZeroByteImage, setIsZeroByteImage] = useState(false);
	const [imgUrl, setImgUrl] = useState<string>();
	const [loading, setLoading] = useState(false);
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const controllerRef = useRef(new window.AbortController());
	const { t } = useTranslation("translation");

	useEffect(() => {
		if (passedImageHasTechnicalError) {
			controllerRef.current && controllerRef.current.abort();
			setImageHasTechnicalError(passedImageHasTechnicalError);
		}
	}, [passedImageHasTechnicalError]);

	useEffect(() => {
		async function fetchMyAPI() {
			if (retry && isFakeUrl) {
				setLoading(true);
				return;
			}
			if (retry && passedImageUrl && passedImageUrl.length > 0) {
				try {
					await tailoredGenerationStore.pullUntilFileIsAvailable(passedImageUrl);
					const response = await fetch(passedImageUrl);
					const fileSize = (await response.blob()).size;
					if (fileSize !== undefined && fileSize === 0) {
						setIsZeroByteImage(true);
					} else {
						setImgUrl(passedImageUrl);
					}
				} catch (e) {
					console.error(e);
					setLoading(false);
					setImageHasTechnicalError(true);
					imageErrorCallBack && imageErrorCallBack();
				}
			} else {
				setImgUrl(passedImageUrl);
			}
		}

		fetchMyAPI();
	}, [passedImageUrl]);

	const updatedImgObj = {
		...image,
		updatedUrl: imgUrl,
	};

	const onImageError = async () => {
		const sourceUrl = image.source_url ?? image.sourceUrl;
		if (!retry && sourceUrl && passedImageUrl !== sourceUrl) {
			setPassedImageUrl(sourceUrl); //fallback url
		} else {
			setLoading(false);
			setImageHasTechnicalError(true);
			imageErrorCallBack && imageErrorCallBack();
		}
	};

	const onImageLoad = () => {
		setIsImageLoaded(true);
		imageLoadedCallback && imageLoadedCallback();
	};

	return (
		<>
			<Box className={styles.container}>
				{isZeroByteImage || offensiveGeneration ? (
					<ImageError
						imageWidth={`${imageWidth.toFixed(2)}vh`}
						imageHeight={`${imageWidth.toFixed(2)}vh`}
						massageText={t("offensiveMessage")}
						titleText={t("offensiveTitle")}
						titleFontSize="14px"
						massageTextSize="14px"
					/>
				) : imageHasTechnicalError ? (
					<ImageError
						imageWidth={`${imageWidth.toFixed(2)}vh`}
						imageHeight={`${imageWidth.toFixed(2)}vh`}
						massageText={t("imageUnavailableMessage")}
						titleText={t("imageUnavailableTitle")}
						titleFontSize="14px"
						massageTextSize="14px"
					/>
				) : (
					<>
						<LazyLoadImage
							wrapperClassName={styles.loadingImage}
							key={`${key}`}
							placeholderSrc={t("lazyLoadPlaceholder")}
							onClick={() => isImageLoaded && handleImgClick(updatedImgObj)}
							onLoad={onImageLoad}
							onError={onImageError}
							useIntersectionObserver={false}
							src={imgUrl}
							style={{
								width: `${imageWidth.toFixed(2)}vh`,
								height: `${isImageLoaded ? "" : imageWidth.toFixed(2)}vh`,
								borderRadius: imageBorderRadius ? imageBorderRadius : 10,
								filter: "drop-shadow(0px 0px 10px rgba(92,90,90,.2))",
								transition: "width 1s ease",
							}}
						/>
						<Box className={styles.loadingContainer}>
							<LoadingAnimation loading={loading || !isImageLoaded} progressBarTime={20} />
						</Box>
					</>
				)}
			</Box>
		</>
	);
}
