import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Template } from "../../../DesignEditor/models/template";
import { IMenuItem } from "../../../DesignEditor/views/DesignEditor/components/Panels/panelItems/Templates/Templates";
import useHoverOverlay from "../../../hooks/useHoverOverlay";
import styles from "./TemplateImageOverlay.module.scss";
export type EditorImageOverlayProps = {
	menuItems: IMenuItem[];
	template: Template;
	isPopupView: boolean;
};

const TemplateImageOverlay = ({ menuItems, template, isPopupView }: EditorImageOverlayProps) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [hoverRef, isHovered, setIsHovered] = useHoverOverlay();
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.templates" });
	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};
	const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(null);
		event.stopPropagation();
	};
	return (
		<Box ref={hoverRef} className={styles.overlay}>
			<Box className={styles.overlayContent}>
				<Box
					style={{ visibility: isHovered || Boolean(anchorEl) ? "visible" : "hidden" }}
					className={styles.lableContainer}
				>
					<Typography className={styles.numberOfAdslabel}>
						{template?.ads_json?.scenes?.length} {t("ads")}{" "}
					</Typography>
				</Box>
				{!isPopupView && (
					<IconButton
						className={styles.iconButton}
						onClick={handleMenuOpen}
						style={{ visibility: isHovered || Boolean(anchorEl) ? "visible" : "hidden" }}
					>
						<MoreHorizIcon className={styles.MoreHorizIcon} />
					</IconButton>
				)}
				<Menu
					classes={{ paper: styles.paper }}
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					MenuListProps={{
						onMouseEnter: () => setIsHovered(true),
						onMouseLeave: () => !anchorEl && setIsHovered(false),
					}}
				>
					<Typography className={styles.templateNameLabel}>{template.name}</Typography>
					<Divider />
					<Box>
						{menuItems.map((item: IMenuItem, index: number) => (
							<MenuItem
								key={index}
								onClick={(e: any) => {
									item.onClick(template);
									handleMenuClose(e);
								}}
								classes={{
									root: styles.menuItemRoot,
								}}
							>
								<Box className={styles.menuIconWrapper}>
									<img src={item.icon} />
									<Typography className={styles.menuLabel}>{item.label}</Typography>
								</Box>
							</MenuItem>
						))}
					</Box>
				</Menu>
			</Box>
		</Box>
	);
};

export default TemplateImageOverlay;
