import { useNavigate } from "react-router";
import RouterConstants from "../constants/RouterConstants";
import { Brand } from "../models/brand";
import { BrandFormType } from "../pages/Campaign/Brands/Editor/BrandEditor";
import { useAppStore } from "./useStores";

export const useBrand = ({
	colors,
	setColors,
	brandForm,
	handleFormChange,
	setSavedBrandActionClicked,
	setLogo,
	logo,
}: any) => {
	const navigate = useNavigate();
	const { brandsStore } = useAppStore();

	const handleUpload = async (e: any) => {
		const file: File | null = e.target.files && e.target.files[0];
		file && setLogo({ logoUrl: URL.createObjectURL(file), logoFile: file });
	};

	const deleteLogo = async () => {
		setLogo({ logoUrl: "", logoFile: "" });
		handleFormChange("logo_id", null);
	};

	const handleColorChange = (color: string, index: number, remove?: boolean) => {
		const updatedColors = [...colors];
		if (remove) {
			updatedColors.splice(index, 1);
		} else {
			updatedColors[index] = color;
		}

		const showAddIcon: boolean = Boolean(updatedColors[updatedColors.length - 1] && updatedColors.length < 5);
		if (showAddIcon) updatedColors.push("");
		const filteredUpdatedColors = colors.filter((color: string) => color.trim() !== "");
		filteredUpdatedColors.length && handleFormChange("color_pallete_id", null);

		setColors(updatedColors);
	};

	const handlePrimaryColorChange = (color: string, remove?: boolean) => {
		let showAddIcon = false;
		if (remove) {
			showAddIcon = true;
		} else {
			const updatedPrimaryMessage = {
				...brandForm.primary_message,
				color: color,
				size: brandForm.primary_message?.size ? Number(brandForm.primary_message?.size) : undefined,
			};
			handleFormChange("primary_message", updatedPrimaryMessage);
		}

		if (showAddIcon) {
			const updatedPrimaryMessage = {
				...brandForm.primary_message,
				color: "",
				size: brandForm.primary_message?.size ? Number(brandForm.primary_message?.size) : undefined,
			};
			handleFormChange("primary_message", updatedPrimaryMessage);
		}
	};

	const handleSecondaryColorChange = (color: string, remove?: boolean) => {
		let showAddIcon = false;
		if (remove) {
			showAddIcon = true;
		} else {
			const updatedSecondaryMessage = {
				...brandForm.secondary_message,
				color: color,
				size: brandForm?.secondary_message?.size ? Number(brandForm?.secondary_message?.size) : undefined,
			};
			handleFormChange("secondary_message", updatedSecondaryMessage);
		}

		if (showAddIcon) {
			const updatedSecondaryMessage = {
				...brandForm.secondary_message,
				color: "",
				size: brandForm?.secondary_message?.size ? Number(brandForm?.secondary_message?.size) : undefined,
			};
			handleFormChange("secondary_message", updatedSecondaryMessage);
		}
	};
	const onNameChange = (e: any) => {
		brandsStore.formErrors.invalidName = false;
		handleFormChange("name", e.target.value);
	};

	const onPrimaryFontChange = (e: any) => {
		handleFormChange("primary_font_id", Number(e.target.value));
	};

	const onSecondaryFontChange = (e: any) => {
		handleFormChange("secondary_font_id", Number(e.target.value));
	};

	const onSecondarySizeChange = (e: any) => {
		const updatedSecondaryMessage = {
			...brandForm.secondary_message,
			size: Number(e.target.value),
			color: brandForm?.secondary_message?.color,
		};
		handleFormChange("secondary_message", updatedSecondaryMessage);
	};

	const onPrimarySizeChange = (e: any) => {
		const updatedPrimaryMessage = {
			...brandForm.primary_message,
			size: Number(e.target.value),
			color: brandForm?.primary_message?.color,
		};
		handleFormChange("primary_message", updatedPrimaryMessage);
	};

	const saveBrand = async () => {
		if (!brandForm.name) return;

		try {
			setSavedBrandActionClicked(true);
			let logo_id = brandForm?.logo_id;
			let color_pallete_id = brandForm?.color_pallete_id;

			if (logo.logoFile) {
				logo_id = await brandsStore.createLogo(logo.logoFile);
				handleFormChange("logo_id", logo_id);
			}

			const filteredUpdatedColors = colors.filter((color: string) => color.trim() !== "");

			if (filteredUpdatedColors.length > 0) {
				color_pallete_id = await brandsStore.createColorPallete({ colors: filteredUpdatedColors });
				handleFormChange("color_pallete_id", color_pallete_id);
			}

			if (brandForm.id) {
				await updateBrand(logo_id, color_pallete_id);
			} else {
				await createNewBrand(logo_id, color_pallete_id);
			}
			setSavedBrandActionClicked(false);
		} catch (e) {
			console.error(e);
			setSavedBrandActionClicked(false);
		}
	};

	const updateBrand = async (logo_id: any, colors_pallete_id: any) => {
		try {
			const updatedBrand: Brand = {
				...brandForm,
				logo_id: logo_id,
				color_pallete_id: colors_pallete_id,
			};
			await brandsStore.updateBrand(updatedBrand);
			navigate(RouterConstants.BRANDS.fullPath);
		} catch (e) {
			console.error(e);
		}
	};

	const createNewBrand = async (logo_id: any, colors_pallete_id: any) => {
		try {
			const newBrand: BrandFormType = {
				...brandForm,
				logo_id: logo_id,
				color_pallete_id: colors_pallete_id,
			};
			await brandsStore.createBrand(newBrand);
			navigate(RouterConstants.BRANDS.fullPath);
		} catch (e) {
			console.error(e);
		}
	};

	return {
		handleColorChange,
		deleteLogo,
		handleUpload,
		handlePrimaryColorChange,
		handleSecondaryColorChange,
		onNameChange,
		onPrimaryFontChange,
		onSecondaryFontChange,
		onSecondarySizeChange,
		onPrimarySizeChange,
		saveBrand,
	};
};
