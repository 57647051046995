import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../../../components/common/BriaTable/BriaTable";
import useErrorToast from "../../../hooks/useErrorToast";
import { PaginatedItems } from "../../../models/common";
import { Template } from "../../../models/template";
import QueryService from "../../../utils/QueryService";

export interface ITemplatesStore {
	paginatedTemplates: PaginatedItems<Template>;
	rowsPerPage: number;
	isLoading: boolean;
	isLoadingEditor: boolean;
	isLoadingPsd: boolean;
	isError: boolean;
	formErrors: { save?: boolean; invalidName?: boolean };
	getTemplate(templateId: number): Promise<Template>;
	loadTemplates(pageNumber: number, filterBy?: FilterByOptions): Promise<void>;
	createTemplate(newTemplate: Template): Promise<number>;
	updateTemplate(updatedTemplate: Template): Promise<void>;
	uploadTemplatePsd(templateId: number, psdFile: File): Promise<string>;
	deleteTemplatePsd(templateId: number, fileName: string): Promise<void>;
}

export default class TemplatesStore implements ITemplatesStore {
	private queryService: QueryService = new QueryService("/templates");
	errorToast = useErrorToast();
	paginatedTemplates: PaginatedItems<Template> = { total: 0, items: [] };
	rowsPerPage: number = 20;
	isLoading: boolean = false;
	isLoadingEditor: boolean = false;
	isLoadingPsd: boolean = false;
	isError: boolean = false;
	formErrors: { save?: boolean; invalidName?: boolean } = {};

	constructor() {
		makeAutoObservable(this);
	}

	loadTemplates = async (pageNumber: number, filterBy?: FilterByOptions): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			const templates: PaginatedItems<Template> = await this.queryService.get("/", {
				params: {
					filter_by: filterBy,
					page: pageNumber,
					per_page: this.rowsPerPage,
				},
			});

			runInAction(() => {
				this.paginatedTemplates = templates;
				this.isError = false;
				this.isLoading = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
		}
	};

	getTemplate = async (templateId: number): Promise<Template> => {
		try {
			this.isLoadingEditor = true;

			const template: Template = await this.queryService.get(`/${templateId}`);
			runInAction(() => {
				this.isLoadingEditor = false;
			});
			return template;
		} catch (e) {
			runInAction(() => {
				this.isLoadingEditor = false;
				this.isError = true;
			});

			return Promise.reject(e);
		}
	};

	createTemplate = async (newTemplate: Template): Promise<number> => {
		this.isError = false;
		try {
			const templateId = await this.queryService.post("/", newTemplate);
			runInAction(() => {
				this.isError = false;
			});
			return templateId;
		} catch (e: any) {
			runInAction(() => {
				if (e.response?.status === 409) {
					this.formErrors.invalidName = true;
				}
				this.isError = true;
			});
			return Promise.reject(`Error creating new template: ${e.message || e.toString()}`);
		}
	};

	updateTemplate = async (updatedTemplate: Template): Promise<void> => {
		this.isError = false;
		try {
			await this.queryService.put("/", updatedTemplate);
			runInAction(() => {
				this.paginatedTemplates.items = this.paginatedTemplates?.items.map((template) =>
					template.id === updatedTemplate.id ? updatedTemplate : template,
				);
				this.isError = false;
			});
		} catch (e: any) {
			runInAction(() => {
				if (e.response?.status === 409) {
					this.formErrors.invalidName = true;
				}
				this.isError = true;
			});
		}
	};

	uploadTemplatePsd = async (templateId: number, psdFile: File): Promise<string> => {
		this.isLoadingPsd = true;
		try {
			const formData = new FormData();
			formData.append("file", psdFile);
			const path = await this.queryService.post(`/upload_psd/${templateId}`, formData, {
				"Content-Type": "multipart/form-data",
			});
			runInAction(() => {
				this.isLoadingPsd = false;
				this.isError = false;
			});
			return path;
		} catch (e: any) {
			runInAction(() => {
				this.isLoadingPsd = false;
				this.isError = true;
			});
			return Promise.reject(`Error uploading PSD file: ${e.message || e.toString()}`);
		}
	};

	deleteTemplatePsd = async (templateId: number, fileName: string): Promise<void> => {
		try {
			this.isLoadingPsd = true;
			await this.queryService.post(`/delete_psd/${templateId}`, { fileName });
			runInAction(() => {
				this.isLoadingPsd = false;
				this.isError = false;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isLoadingPsd = false;
				this.isError = true;
			});
			return Promise.reject(`Error uploading PSD file: ${e.message || e.toString()}`);
		}
	};
}
