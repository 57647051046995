function AlignMiddle({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.33301 10H7.49967M12.4997 10H16.6663M9.16634 5H10.833C11.7535 5 12.4997 5.74619 12.4997 6.66667V13.3333C12.4997 14.2538 11.7535 15 10.833 15H9.16634C8.24587 15 7.49967 14.2538 7.49967 13.3333V6.66667C7.49967 5.74619 8.24587 5 9.16634 5Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AlignMiddle;
