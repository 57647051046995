import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../../../../../../../assets/images/icons/DeleteIcon";
import { EditIcon } from "../../../../../../../../../assets/images/icons/EditIcon";

import BriaIconButton from "../../../../../../../../../components/common/BriaIconButton/BriaIconButton";
import { useAppStore } from "../../../../../../../../../hooks/useStores";
import { BrandText, defaultBrandText } from "../../../../../../../../../models/brandText";
import { FontOptions } from "../../../../../../../../../models/fontOptions";
import common from "../../Common.module.scss";
import FontsListModel from "../FontsListModel/FontsListModel";
import styles from "./FontItem.module.scss";

interface FontItemProps {
	brandText: BrandText;
	brandTexts: BrandText[];
	index: number;
	isAdminMode: boolean | undefined;
	activeFontIndex: number | null;
	setActiveFontIndex: (index: number | null) => void;
	setBrandTexts: (brandText: BrandText[]) => void;
	fontsOption: FontOptions[];
	loadAllFonts: () => void;
}

const FontItem: React.FC<FontItemProps> = ({
	brandText,
	brandTexts,
	index,
	isAdminMode,
	activeFontIndex,
	setActiveFontIndex,
	setBrandTexts,
	fontsOption,
	loadAllFonts,
}) => {
	const { brandsDefinitionStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.tabs.brands" });
	const fontItemRef = useRef<HTMLDivElement>(null);
	const isEmpty = !brandText?.font_id;

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (fontItemRef.current && !fontItemRef.current.contains(event.target as Node)) {
				setActiveFontIndex(null);
			}
		};

		if (activeFontIndex === index) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [activeFontIndex, index, setActiveFontIndex]);

	const handleDeleteFont = (index: number) => {
		const updatedBrandTexts = [...brandTexts];
		updatedBrandTexts[index] = {
			...defaultBrandText,
			font_id: undefined,
			id: brandText.id,
			type: brandText.type,
		};
		setBrandTexts(updatedBrandTexts);
		brandsDefinitionStore.handleBrandChange("brand_texts", updatedBrandTexts);
	};
	const handleFontChange = (font: any, index: number) => {
		const updatedBrandTexts = [...brandTexts];
		const newFont: BrandText = {
			...brandText,
			font_id: font.value ?? font.id,
			font: {
				name: font.key ?? font.name,
				id: font.value ?? font.id,
				org_id: font.org_id,
				src: font.src,
			},
		};

		if (index !== null) {
			updatedBrandTexts[index] = newFont;
		} else {
			updatedBrandTexts.push(newFont);
		}
		setBrandTexts(updatedBrandTexts);
		brandsDefinitionStore.handleBrandChange("brand_texts", updatedBrandTexts);
		setActiveFontIndex(null);
	};

	const renderItemActions = () => {
		if (isAdminMode) {
			return (
				<Box className={common.itemActions}>
					{!isEmpty && (
						<BriaIconButton className={common.deleteBtn} onClick={() => handleDeleteFont(index)}>
							<DeleteIcon />
						</BriaIconButton>
					)}

					<BriaIconButton
						className={common.editBtn}
						onClick={() => {
							if (activeFontIndex !== index) {
								setActiveFontIndex(index);
							} else {
								setActiveFontIndex(null);
							}
						}}
					>
						<EditIcon />
					</BriaIconButton>
					{activeFontIndex === index && (
						<FontsListModel
							index={index}
							setActiveFontIndex={setActiveFontIndex}
							fontsOption={fontsOption}
							loadAllFonts={loadAllFonts}
							handleFontChange={handleFontChange}
						/>
					)}
				</Box>
			);
		}
	};

	return (
		<Box ref={fontItemRef} key={index} className={clsx(common.listItem, { [common.editable]: isAdminMode })}>
			<Box className={common.itemContent}>
				<Box className={common.itemContent}>
					<Typography className={clsx(common.itemLabel, common.fontLabel)}>
						{t("font")} {index + 1}
					</Typography>
					{!isEmpty && (
						<Typography className={styles.fontName} style={{ fontFamily: brandText.font?.name }}>
							{brandText.font?.name}
						</Typography>
					)}
				</Box>
			</Box>
			{renderItemActions()}
		</Box>
	);
};

export default FontItem;
