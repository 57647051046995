import Container from "./Container";
import Provider from "./Provider";
import DesignEditor from "./views/DesignEditor";

const BriaDesignEditor = (props: { isPopupView?: boolean }) => {
	return (
		<Provider isPopupView={props.isPopupView}>
			<Container>
				<DesignEditor isPopup={props.isPopupView} />
			</Container>
		</Provider>
	);
};

export default BriaDesignEditor;
