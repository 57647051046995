import { styled, Typography } from "@mui/material";
import styles from "./InvitationLayout.module.scss";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ConfirmationPopup from "../../components/common/ConfirmationPopup/ConfirmationPopup";
import LoadingPlaceholder from "../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import RouterConstants from "../../constants/RouterConstants";
import { setSelectedOrganization } from "../../helpers/localStorage.ts";
import { useAppStore } from "../../hooks/useStores.tsx";
import { navigateWithParams } from "../../utils";

const InvitationLayout = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { appStore, authStore } = useAppStore();
	const [token, setToken] = useState<string | null>();
	const { t } = useTranslation("translation", { keyPrefix: "invitation" });
	const [inValidInvitation, setInvalidInvitation] = useState(false);
	const [invalidEmail, setInvalidEmail] = useState(false);
	const [joinAccountPopup, setJoinAccountPopup] = useState(false);
	const [joinedAccountPopup, setJoinedAccountPopup] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const localStorgeToken = localStorage.getItem("token");
		const tokenVal = new URLSearchParams(location.search).get("token");
		if (tokenVal) {
			setToken(tokenVal);
			localStorage.setItem("token", tokenVal);
		} else if (localStorgeToken) {
			setToken(localStorgeToken);
		}
	}, []);

	useEffect(() => {
		const loadData = async () => {
			if (token && !authStore.isLoading && !loading) {
				setLoading(true);
				// Fetch invitation if a new token is provided
				if (!appStore.invitedUser || (appStore.invitedUser && appStore.invitedUser.token !== token)) {
					await appStore.getUserInvitation(token);
				}

				if (appStore.invitedUser?.is_expired || appStore.isError) {
					setInvalidInvitation(true);
				} else if (appStore.invitedUser?.flow_step === 1 && !authStore.isLoggedIn) {
					setJoinAccountPopup(true);
				} else if (appStore.invitedUser) {
					if (appStore.invitedUser.email?.toLowerCase() === authStore?.user?.email?.toLowerCase()) {
						setJoinAccountPopup(false);
						await appStore.acceptUserInvitation();
						setJoinedAccountPopup(true);
						await authStore.loadUserData();
						setToken(undefined);
						localStorage.removeItem("token");
					} else if (authStore.isLoggedIn && urlContainsToken()) {
						setInvalidEmail(true);
					}
				}
				setLoading(false);
			}
		};
		loadData();
	}, [token, authStore.isLoading]);

	const urlContainsToken = (): boolean => {
		const urlParams = new URLSearchParams(location.search);
		return urlParams.has("token");
	};

	const changeToInvitationOrg = () => {
		const invitedOrg = authStore.userOrganizations.find(
			(org) => org.organization.name === appStore.invitedUser?.org_name,
		);
		invitedOrg && setSelectedOrganization(invitedOrg);
		localStorage.removeItem("token");
		window.location.search = "";
	};

	const handleCloseInvalidInvitation = () => {
		setInvalidInvitation(false);
		setToken(undefined);
		localStorage.removeItem("token");
		window.location.search = "";
	};

	const handleCloseInvalidEmail = async () => {
		setInvalidEmail(false);
		appStore.invitedUser = null;
		await authStore.logout();
		// logout will clear the localStorage
		// but in this case, we still need the token to be inside the localStorage
		localStorage.setItem("token", token ?? "");
		setJoinAccountPopup(true);
	};

	return (
		<LoadingPlaceholder className={styles.loaderStyle} isLoading={authStore.isLoading || loading}>
			<Outlet />
			<ConfirmationPopup
				onClose={changeToInvitationOrg}
				title={t("joinedAccountPopup.title", { account_name: appStore.invitedUser?.org_name })}
				description={t("joinedAccountPopup.description", { account_name: appStore.invitedUser?.org_name })}
				confirmButtonText={t("joinedAccountPopup.confirmText")}
				open={joinedAccountPopup}
				onClick={changeToInvitationOrg}
				hideCancel={true}
			/>
			<ConfirmationPopup
				onClose={() => {
					setJoinAccountPopup(false);
					if (appStore.invitedUser) {
						appStore.invitedUser.flow_step = 2;
					}
					navigateWithParams(navigate, RouterConstants.SIGNUP.path);
				}}
				title={t("joinAccountPopup.title", { account_name: appStore.invitedUser?.org_name })}
				description={t("joinAccountPopup.description", { account_name: appStore.invitedUser?.org_name })}
				confirmButtonText={t("joinAccountPopup.confirmText")}
				open={joinAccountPopup}
				onClick={() => {
					setJoinAccountPopup(false);
					if (appStore.invitedUser) {
						appStore.invitedUser.flow_step = 2;
					}
					navigateWithParams(navigate, RouterConstants.LOGIN.path);
				}}
				firstButtonText={t("joinAccountPopup.firstButtonText")}
			>
				<StyledTypography>
					<span>{t("joinAccountPopup.login")}</span> {t("joinAccountPopup.or")}
					<span>{t("joinAccountPopup.register")}</span> {t("joinAccountPopup.toAcceptTheInvitation")}
				</StyledTypography>
			</ConfirmationPopup>
			<ConfirmationPopup
				onClose={() => handleCloseInvalidInvitation()}
				title={t("inValidInvitation.title")}
				description={t("inValidInvitation.description")}
				confirmButtonText={t("inValidInvitation.confirmText")}
				open={inValidInvitation}
				onClick={() => handleCloseInvalidInvitation()}
				hideCancel={true}
			/>
			<ConfirmationPopup
				onClose={() => handleCloseInvalidEmail()}
				title={t("invalidEmail.title")}
				description={t("invalidEmail.description")}
				confirmButtonText={t("invalidEmail.confirmText")}
				open={invalidEmail}
				onClick={() => handleCloseInvalidEmail()}
				hideCancel={true}
			/>
		</LoadingPlaceholder>
	);
};

const StyledTypography = styled(Typography)({
	color: "#5B5B5B",
	fontSize: 16,
	fontWeight: 400,
	span: {
		fontWeight: 600,
	},
});

const ObservedComponent = observer(InvitationLayout);
export default ObservedComponent;
