function Locked({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.6665 9.16667V5.83333C6.6665 4.94928 7.01769 4.10143 7.64281 3.47631C8.26794 2.85119 9.11578 2.5 9.99984 2.5C10.8839 2.5 11.7317 2.85119 12.3569 3.47631C12.982 4.10143 13.3332 4.94928 13.3332 5.83333V9.16667M5.83317 9.16667H14.1665C15.087 9.16667 15.8332 9.91286 15.8332 10.8333V15.8333C15.8332 16.7538 15.087 17.5 14.1665 17.5H5.83317C4.9127 17.5 4.1665 16.7538 4.1665 15.8333V10.8333C4.1665 9.91286 4.9127 9.16667 5.83317 9.16667ZM10.8332 13.3333C10.8332 13.7936 10.4601 14.1667 9.99984 14.1667C9.5396 14.1667 9.1665 13.7936 9.1665 13.3333C9.1665 12.8731 9.5396 12.5 9.99984 12.5C10.4601 12.5 10.8332 12.8731 10.8332 13.3333Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Locked;
