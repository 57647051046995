import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function UploadImageIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 20 20" {...props}>
			<path
				d="M11.9999 4.86914H12.0099M8.99994 16.8691H3.99994C3.20429 16.8691 2.44123 16.5531 1.87862 15.9905C1.31601 15.4279 0.999939 14.6648 0.999939 13.8691V3.86914C0.999939 3.07349 1.31601 2.31043 1.87862 1.74782C2.44123 1.18521 3.20429 0.869141 3.99994 0.869141H13.9999C14.7956 0.869141 15.5587 1.18521 16.1213 1.74782C16.6839 2.31043 16.9999 3.07349 16.9999 3.86914V8.86914M0.999939 11.8692L4.99994 7.86915C5.92794 6.97615 7.07194 6.97615 7.99994 7.86915L11.9999 11.8692M10.9999 10.8691L11.9999 9.86914C12.6169 9.27614 13.3279 9.07614 14.0089 9.27114M15.9999 18.8691V12.8691M15.9999 12.8691L18.9999 15.8691M15.9999 12.8691L12.9999 15.8691"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default UploadImageIcon;
