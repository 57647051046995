import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { ReactNode, useState } from "react";
import SwipableMenuButton, { SwipableMenuButtonProps } from "../Button/SwipableMenuButton";
import SubSwipableMenu, { SubSwipableMenuProps } from "../SubSwipableMenu/SubSwipableMenu";

type SwipableMenuLayoutProps = {
	children: ReactNode;
	className?: string;
};

const SwipableMenuLayout = ({ children, className }: SwipableMenuLayoutProps) => {
	const [subMenuComponent, setSubMenuComponent] = useState<ReactNode>();
	const [subProps, setSubProps] = useState<Partial<SubSwipableMenuProps>>();

	const handleSwipe = (props: SwipableMenuButtonProps) => {
		setSubMenuComponent(props.swipeTo);
		setSubProps(props.subSwipableMenuProps);
	};

	const handleBack = () => {
		subProps?.onBackButtonClick?.();
		setSubMenuComponent(undefined);
	};

	const detectSwipeButtons = (child: ReactNode): ReactNode => {
		if (React.isValidElement(child)) {
			if ((child.type as any).displayName === "SwipableMenuButton") {
				const props = child.props as SwipableMenuButtonProps;
				return <SwipableMenuButton {...props} onClick={() => handleSwipe(props)} />;
			} else if ((child as any).props.children) {
				return React.cloneElement(child, {
					children: React.Children.map((child as any).props.children, detectSwipeButtons),
				} as any);
			}
		}
		return child;
	};

	return (
		<Box className={className}>
			{!subMenuComponent ? (
				React.Children.map(children, detectSwipeButtons)
			) : (
				<SubSwipableMenu onBackButtonClick={handleBack} {...subProps}>
					{subMenuComponent}
				</SubSwipableMenu>
			)}
		</Box>
	);
};

export default observer(SwipableMenuLayout);
