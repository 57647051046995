import { getSelectedOrganization } from "../helpers/localStorage";

export interface Font {
	id: number;
	name: string;
	src: string;
	org_id: string;
	created_at?: Date;
	updated_at?: Date;
}

export const defaultFont: Font = {
	id: NaN,
	name: "",
	src: "",
	org_id: getSelectedOrganization()?.organization?.uid || "",
	created_at: new Date(),
	updated_at: new Date(),
};

export const fontSizesList = [
	"10",
	"11",
	"12",
	"13",
	"14",
	"15",
	"16",
	"20",
	"24",
	"32",
	"36",
	"40",
	"48",
	"64",
	"96",
	"120",
	"128",
];
