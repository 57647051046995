function Dots({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.31283 11.2541C4.77306 11.2541 5.14616 10.881 5.14616 10.4207C5.14616 9.9605 4.77306 9.5874 4.31283 9.5874C3.85259 9.5874 3.47949 9.9605 3.47949 10.4207C3.47949 10.881 3.85259 11.2541 4.31283 11.2541Z"
				fill="#5B5B5B"
			/>
			<path
				d="M10.1462 11.2541C10.6064 11.2541 10.9795 10.881 10.9795 10.4207C10.9795 9.9605 10.6064 9.5874 10.1462 9.5874C9.68592 9.5874 9.31283 9.9605 9.31283 10.4207C9.31283 10.881 9.68592 11.2541 10.1462 11.2541Z"
				fill="#5B5B5B"
			/>
			<path
				d="M15.9795 11.2541C16.4397 11.2541 16.8128 10.881 16.8128 10.4207C16.8128 9.9605 16.4397 9.5874 15.9795 9.5874C15.5193 9.5874 15.1462 9.9605 15.1462 10.4207C15.1462 10.881 15.5193 11.2541 15.9795 11.2541Z"
				fill="#5B5B5B"
			/>
			<path
				d="M4.31283 11.2541C4.77306 11.2541 5.14616 10.881 5.14616 10.4207C5.14616 9.9605 4.77306 9.5874 4.31283 9.5874C3.85259 9.5874 3.47949 9.9605 3.47949 10.4207C3.47949 10.881 3.85259 11.2541 4.31283 11.2541Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.1462 11.2541C10.6064 11.2541 10.9795 10.881 10.9795 10.4207C10.9795 9.9605 10.6064 9.5874 10.1462 9.5874C9.68592 9.5874 9.31283 9.9605 9.31283 10.4207C9.31283 10.881 9.68592 11.2541 10.1462 11.2541Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.9795 11.2541C16.4397 11.2541 16.8128 10.881 16.8128 10.4207C16.8128 9.9605 16.4397 9.5874 15.9795 9.5874C15.5193 9.5874 15.1462 9.9605 15.1462 10.4207C15.1462 10.881 15.5193 11.2541 15.9795 11.2541Z"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default Dots;
