import CloseIcon from "@mui/icons-material/CloseTwoTone";
import { Box, Dialog } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../assets/images/svgs/AIEditor/cloud-upload.svg";
import ImageUploader from "../../common/ImageUploader/ImageUploader.tsx";
import styles from "./ImageUploadPopup.module.scss";

interface ImageUploadPopupProps {
	open: boolean;
	maxWidth?: string;
	onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<any>;
	handleCloseClick?: () => void;
	loading?: boolean;
}

const ImageUploadPopup = ({ onUpload, open, handleCloseClick, maxWidth, loading }: ImageUploadPopupProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "popups.imageUploader" });

	return (
		<Dialog
			classes={{
				paper: `${styles.container}`,
			}}
			onClose={handleCloseClick}
			open={open}
			maxWidth={false}
			PaperProps={{
				style: {
					maxWidth: maxWidth,
				},
			}}
		>
			<ImageUploader
				className={styles.imageUploader}
				emptyStateClassName={styles.emptyState}
				icon={CloudUploadSVG}
				largeIcon={true}
				loading={loading}
				description={
					<Box className={styles.imageUploaderDesc}>
						<Box className={styles.header}>{t("emptyState.header")}</Box>
						<Box className={styles.subHeader}>
							{t("emptyState.subHeader")}
							<span className={styles.subHeader1}>{t("emptyState.subHeader1")}</span>
						</Box>
					</Box>
				}
				onUpload={onUpload}
				inputProps={{ accept: "image/png, image/jpeg, image/jpg, image/webp" }}
			/>
			<CloseIcon onClick={handleCloseClick} className={styles.closeIcon} />
		</Dialog>
	);
};

const ObservedComponent = observer(ImageUploadPopup);
export default ObservedComponent;
