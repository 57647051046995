import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import Konva from "konva";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Group, Image as KonvaImage, Layer, Rect, Stage } from "react-konva";
import useImage from "use-image";
import FullscreenIcon from "../../../assets/images/icons/Image/FullscreenIcon.tsx";
import { useAppStore } from "../../../hooks/useStores.tsx";
import { RectType } from "../../../models/aiEditor.ts";
import FullScreenViewer from "../../common/FullScreenViewer/FullScreenViewer.tsx";
import ImageError from "../../common/ImageError/ImageError.tsx";
import LoadingAnimation from "../../common/LoadingAnimation/LoadingAnimation.tsx";
import { SkeletonImage } from "../../common/Skeleton/SkeletonGallery.tsx";
import styles from "./InteractiveBriaImage.module.scss";

interface IInteractiveBriaImage {
	imageUrl: string;
	offensiveGeneration?: boolean;
}

const InteractiveBriaImage = ({ imageUrl, offensiveGeneration }: IInteractiveBriaImage) => {
	const [image, status] = useImage(imageUrl, "anonymous", "origin");
	const imageContainerRef = useRef(null);
	const { t } = useTranslation("translation");
	const { aiEditorStore, objectsStore } = useAppStore();
	const [imageProps, setImageProps] = useState({ scaleX: 1, scaleY: 1, x: 0, y: 0 });
	const [imageContainerDimensions, setImageContainerDimensions] = useState({ width: 0, height: 0 });
	const [rectangles, setRectangles] = useState<RectType[]>([]);
	const [_currentRect, _setCurrentRect] = useState<RectType | null>(null);
	const [boxWidth, setBoxWidth] = useState<number>(256);
	const [isGroupOfRectsSelected, selectGroupOgRects] = useState<boolean>(false);
	const [isZeroByteImage, _setIsZeroByteImage] = useState(false);
	const [imageHasTechnicalError, setImageHasTechnicalError] = useState(false);
	const [scaledImageSize, setScaledImageSize] = useState({ width: 500, height: 100 });

	useEffect(() => {
		if (!image) {
			setImageHasTechnicalError(true);
		} else {
			setImageHasTechnicalError(false);
		}

		updateDimensions();
		window.addEventListener("resize", updateDimensions);
		return () => {
			window.removeEventListener("resize", updateDimensions);
		};
	}, [status]);

	useEffect(() => {
		setRectangles([
			{
				x: 0,
				y: 0,
				width: aiEditorStore.shapeSize * imageProps.scaleX,
				height: aiEditorStore.shapeSize * imageProps.scaleY,
			},
		]);
		setBoxWidth(aiEditorStore.shapeSize);
	}, [aiEditorStore.shapeSize, imageProps]);

	useEffect(() => {
		/*
		 * Calculate the scale factors for width and height
		 * Choose the smaller scale factor to maintain aspect ratio
		 * Calculate the new scaled width and height
		 * Calculate the position to center the image
		 * Update the state with the calculated values
		 * */
		if (image) {
			const { width: imageWidth, height: imageHeight } = image;

			const scaleX = imageContainerDimensions.width / imageWidth;
			const scaleY = imageContainerDimensions.height / imageHeight;

			const scale = Math.min(scaleX, scaleY);
			const scaledWidth = imageWidth * scale;
			const scaledHeight = imageHeight * scale;

			const x = (imageContainerDimensions.width - scaledWidth) / 2;
			const y = (imageContainerDimensions.height - scaledHeight) / 2;

			setImageProps({ scaleX: scale, scaleY: scale, x, y });
			setScaledImageSize({
				width: (image?.width ?? 0) * scale,
				height: (image?.height ?? 0) * scale,
			});
		}
	}, [image, imageContainerDimensions]);

	const updateDimensions = () => {
		if (imageContainerRef.current) {
			const { clientWidth, clientHeight } = imageContainerRef.current;
			setImageContainerDimensions({ width: clientWidth, height: clientHeight });
		}
	};

	const handleDragMove = (event: Konva.KonvaEventObject<MouseEvent>) => {
		/*
		 * Calculate the maximum allowed positions for the right and bottom edges of the rectangle
		 * Calculate the constrained position for the top-left corner of the rectangle
		 * Update the position of the rectangle
		 * Force the layer to redraw
		 * */
		const rect = event.target;

		const stage = event.target.getStage();
		if (stage) {
			const stageWidth = stage.width();
			const stageHeight = stage.height();

			const maxX = stageWidth - boxWidth * imageProps.scaleX;
			const maxY = stageHeight - boxWidth * imageProps.scaleY;

			const constrainedX = Math.max(0, Math.min(maxX, rect.x()));
			const constrainedY = Math.max(0, Math.min(maxY, rect.y()));
			rect.position({ x: constrainedX, y: constrainedY });
			rect?.getLayer()?.batchDraw();
		}
	};

	const handleDragEnd = (_index: number, event: Konva.KonvaEventObject<MouseEvent>) => {
		const newX = event.target.x() / imageProps.scaleX;
		const newY = event.target.y() / imageProps.scaleY;
		objectsStore.setProperty("box", {
			x: newX,
			y: newY,
			width: boxWidth,
			height: boxWidth,
		});
	};

	const openSingleFullScreen = () => {
		aiEditorStore.setProperty("imageViewerOpen", true);
	};

	return (
		<>
			{image === undefined ? (
				<Box className={styles.skeleton}>
					<SkeletonImage aspectRatio={"9:16"} />
				</Box>
			) : (
				<Box className={styles.container} ref={imageContainerRef}>
					{objectsStore.isLoading && (
						<Box
							className={styles.loadingContainer}
							style={{
								width: scaledImageSize.width,
								height: scaledImageSize.height,
							}}
						>
							<LoadingAnimation loading={objectsStore.isLoading} progressBarTime={20} />
						</Box>
					)}
					{isZeroByteImage || offensiveGeneration ? (
						<ImageError
							imageWidth={`${scaledImageSize.width}px`}
							imageHeight={`${scaledImageSize.height}px`}
							massageText={t("offensiveMessage")}
							titleText={t("offensiveTitle")}
							titleFontSize="14px"
							massageTextSize="14px"
						/>
					) : imageHasTechnicalError ? (
						<ImageError
							imageWidth={`${scaledImageSize.width}px`}
							imageHeight={`${scaledImageSize.height}px`}
							massageText={t("imageUnavailableMessage")}
							titleText={t("imageUnavailableTitle")}
							titleFontSize="14px"
							massageTextSize="14px"
						/>
					) : (
						<>
							<Box
								className={clsx({
									[styles.fullScreenIconContainer]: true,
									[styles.isLoading]: image && objectsStore.isLoading,
								})}
								style={{
									width: `${scaledImageSize.width}px`,
								}}
							>
								<FullscreenIcon className={styles.fullScreenIcon} onClick={openSingleFullScreen} />
							</Box>
							<Stage
								className={clsx({
									[styles.isLoading]: objectsStore.isLoading,
								})}
								width={scaledImageSize.width}
								height={scaledImageSize.height}
							>
								<Layer>
									<KonvaImage image={image} scaleX={imageProps.scaleX} scaleY={imageProps.scaleY} />

									{rectangles.map((rect: RectType, index) => (
										<Group
											draggable={true}
											onDragEnd={(e) => handleDragEnd(index, e)}
											onDragMove={(e) => handleDragMove(e)}
											onMouseDown={() => selectGroupOgRects(true)}
											onMouseUp={() => selectGroupOgRects(false)}
										>
											<Rect
												key={index}
												x={rect.x}
												y={rect.y}
												width={rect.width}
												height={rect.height}
												stroke={isGroupOfRectsSelected ? "#FAFAFA" : "#8800FF"}
												fill={isGroupOfRectsSelected ? "#D1B2FD33" : "#5300C933"}
												strokeWidth={1}
												dash={isGroupOfRectsSelected ? [3, 3] : []}
											/>
											<Rect
												x={rect.x + 1}
												y={rect.y + 1}
												width={rect.width - 2}
												height={rect.height - 2}
												stroke={isGroupOfRectsSelected ? "#5B5B5BD9" : "#FAFAFA"}
												fill={isGroupOfRectsSelected ? "#D1B2FD33" : "#5300C933"}
												strokeWidth={1}
											/>
										</Group>
									))}
								</Layer>
							</Stage>
						</>
					)}
					{aiEditorStore.imageViewerOpen && (
						<FullScreenViewer
							open={aiEditorStore.imageViewerOpen}
							images={[{ src: imageUrl, compareTo: objectsStore.refine.originalImage }]}
							onClose={() => aiEditorStore.setProperty("imageViewerOpen", false)}
						/>
					)}
					<Box
						className={styles.resolutionBox}
						style={{
							width: `${scaledImageSize.width}px`,
						}}
					>
						<Typography className={styles.resolution}>{`${Math.round(image.width)}X${Math.round(
							image.height,
						)}`}</Typography>
						<Typography className={styles.resolutionPercentage}>
							{` (${Math.round(imageProps.scaleX * 100)}%)`}
						</Typography>
					</Box>
				</Box>
			)}
		</>
	);
};

const ObservedComponent = observer(InteractiveBriaImage);
export default ObservedComponent;
