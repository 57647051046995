import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import CloudUploadSVG from "../../../../assets/images/svgs/AIEditor/cloud-upload.svg";
import ImageUploader from "../../../../components/common/ImageUploader/ImageUploader";
import { useAppStore } from "../../../../hooks/useStores";
import styles from "./EmptyPlayground.module.scss";

const EmptyPlayground = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.emptyState" });

	const { playgroundStore, imageToImageStore, textToImageStore } = useAppStore();
	return (
		<>
			{playgroundStore.selectedConfig === "imageToImage" ? (
				<Box className={styles.imageToImage}>
					<ImageUploader
						className={styles.imageUploader}
						emptyStateClassName={styles.emptyState}
						icon={CloudUploadSVG}
						showPreview={false}
						description={
							<Box className={styles.imageUploaderDesc}>
								<Box className={styles.header}>{t("header")}</Box>
								<Box className={styles.subHeader}>{t("subHeader")}</Box>
							</Box>
						}
						onUpload={imageToImageStore.handleUploadImages}
						inputProps={{ multiple: true }}
						maxFilesLimit={imageToImageStore.MAX_FILES_LIMIT}
						// maxFileSize={imageToImageStore.MAX_FILE_SIZE}
					/>
				</Box>
			) : (
				<Box className={`${styles.textToImage}`}>
					{Array.from({ length: 4 }).map((_, _index) => (
						<Box
							width="25%"
							className={styles.skeleton}
							style={{
								aspectRatio: textToImageStore.config.aspect_ratio.replace(":", "/"),
							}}
							key={_index}
						></Box>
					))}
				</Box>
			)}
		</>
	);
};

export default observer(EmptyPlayground);
