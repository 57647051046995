import { useTranslation } from "react-i18next";
import styles from './Beta.module.scss';

interface BetaProps {
	children: React.ReactNode;
}

const Beta: React.FC<BetaProps> = ({ children }) => {
	const { t } = useTranslation("translation", { keyPrefix: "sideMenu" });

	return (
		<>
			{children}
			<span className={styles.betaLabel}>{t("beta")}</span>
		</>
	);
};

export default Beta;
