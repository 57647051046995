import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { DesignEditorContext } from "../../../../contexts/DesignEditor.tsx";
import PanelItem from "./PanelItem";
import PanelsList from "./PanelsList";

function Panels() {
	const { isPopupView } = useContext(DesignEditorContext);
	return (
		<>
			{!isPopupView && <PanelsList />}
			<PanelItem />
		</>
	);
}

const ObservedComponent = observer(Panels);
export default ObservedComponent;
