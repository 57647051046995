export async function startDownload(
	fileUrl: string,
	passedFileName: string,
	blobInput?: string,
	disablePoll?: boolean,
	forceExt?: string,
) {
	try {
		let url = blobInput ? blobInput : fileUrl;
		if (!blobInput && !disablePoll) {
			const response = await fetch(fileUrl);
			const blob = await response.blob();
			url = window.URL.createObjectURL(new Blob([blob]));
		}
		const link = document.createElement("a");
		link.href = url;
		const filenameFromUrl = new URL(fileUrl).pathname.split("/").pop();
		let ext = forceExt ?? filenameFromUrl?.split(".").pop();
		if (fileUrl.startsWith("blob")) {
			ext = ".jpeg";
		}
		const filename = `bria_${passedFileName}.${ext}`;
		link.setAttribute("download", filename);
		document.body.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
	} catch (error) {
		console.log(error);
		throw error;
	}
}
