import { Box, Divider } from "@mui/material";
import { ChevronDown } from "baseui/icon";
import clsx from "clsx";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../../../components/common/BriaButton/BriaButton";
import BriaTooltip from "../../../../../../components/common/BriaTooltip/BriaTooltip";
import { useAppStore } from "../../../../../../hooks/useStores";
import useDesignEditorUtils, { FeatureType } from "../../../../../CustomUtils/UseDesignEditor";
import bold from "../../../../../assets/svgs/bold.svg";
import italic from "../../../../../assets/svgs/italic.svg";
import underline from "../../../../../assets/svgs/underline.svg";
import { PanelType } from "../../../../../constants/app-options";
import { PopUpSteps } from "../../../../../constants/mock-data.ts";
import { DesignEditorContext } from "../../../../../contexts/DesignEditor.tsx";
import { useActiveObject } from "../../../../../hooks/useActiveObject";
import useAppContext from "../../../../../hooks/useAppContext";
import { useEditor } from "../../../../../hooks/useEditor";
import useSetIsSidebarOpen from "../../../../../hooks/useSetIsSidebarOpen";
import { LayerType, type IStaticText } from "../../../../../types";
import { getTextProperties } from "../../../utils/text";
import EditAllAdsSwitch from "../../Panels/panelItems/Common/EditAllAdsSwitch/EditAllAdsSwitch";
import TextAlign from "./TextAlign/TextAlign";
import TextFontSize from "./TextFontSize/TextFontSize";
import TextLetterCase from "./TextLetterCase/TextLetterCase";
import TextSpacing from "./TextSpacing/TextSpacing";
import styles from "./TextToolBar.module.scss";

interface TextState {
	color: string;
	bold: boolean;
	italic: boolean;
	underline: boolean;
	family: string;
	styleOptions: StyleOptions;
}

interface StyleOptions {
	hasItalic: boolean;
	hasBold: boolean;
	options: any[];
}

export default function () {
	const initialOptions: TextState = {
		family: "Lato-Black",
		bold: false,
		italic: false,
		underline: false,
		color: "#1A0638",
		styleOptions: {
			hasBold: true,
			hasItalic: true,
			options: [],
		},
	};
	const setIsSidebarOpen = useSetIsSidebarOpen();
	const [state, setState] = useState<TextState>(initialOptions);
	const activeObject = useActiveObject() as Required<IStaticText>;
	const { setActivePanel } = useAppContext();
	const editor = useEditor();
	const { isPopupView } = useContext(DesignEditorContext);
	const { brandFontStore, designEditorStore } = useAppStore();
	const { t } = useTranslation("translation", { keyPrefix: "editor.controllers.textControllers" });
	const { EditAllAds } = useDesignEditorUtils();
	const TEXT_INDEX = PopUpSteps.indexOf("text");
	useEffect(() => {
		if (activeObject && activeObject.type === "StaticText") {
			if (brandFontStore?.brandOrgFonts?.length == 0) {
				brandFontStore.getAllOrgBrandFonts(true).then(() => {
					const textProperties = getTextProperties(activeObject, brandFontStore?.brandOrgFonts);
					setState({ ...state, ...textProperties });
				});
			} else {
				const textProperties = getTextProperties(activeObject, brandFontStore?.brandOrgFonts);
				setState({ ...state, ...textProperties });
			}
		}
	}, [activeObject]);

	useEffect(() => {
		const watcher = async () => {
			if (activeObject && activeObject.type === "StaticText") {
				const textProperties = getTextProperties(activeObject, brandFontStore?.brandOrgFonts);
				setState({ ...state, ...textProperties });
			}
		};
		if (editor) {
			editor.on("history:changed", watcher);
		}
		return () => {
			if (editor) {
				editor.off("history:changed", watcher);
			}
		};
	}, [editor, activeObject]);

	const makeBold = useCallback(() => {
		let fontWeight;
		const currentFontWeight =
			typeof activeObject.fontWeight === "number"
				? activeObject.fontWeight
				: activeObject.fontWeight === "bold"
				? 700
				: 400;
		const isCurrentlyBold = state.bold || currentFontWeight >= 600;

		if (isCurrentlyBold) {
			fontWeight = currentFontWeight >= 600 ? "normal" : currentFontWeight - 300;
			editor?.objects.update({
				fontWeight: fontWeight,
			});
			setState({ ...state, bold: false });
			EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.BOLD, fontWeight);
		} else {
			fontWeight = currentFontWeight < 600 ? 600 : "bold";
			editor?.objects.update({
				fontWeight: fontWeight,
			});
			EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.BOLD, fontWeight);
			setState({ ...state, bold: true });
		}
	}, [editor, state]);

	const makeItalic = useCallback(async () => {
		let fontStyle = "normal";
		if (state.italic) {
			editor?.objects.update({
				fontStyle: fontStyle,
			});
			EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.ITALIC, fontStyle);
			setState({ ...state, italic: false });
		} else {
			fontStyle = "italic";
			editor?.objects.update({
				fontStyle: fontStyle,
			});
			EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.ITALIC, fontStyle);
			setState({ ...state, italic: true });
		}
	}, [editor, state]);

	const makeUnderline = useCallback(() => {
		editor?.objects.update({
			underline: !state.underline,
		});
		EditAllAds(LayerType.STATIC_TEXT, activeObject.textType, FeatureType.UNDERLINE, !state.underline);
		setState({ ...state, underline: !state.underline });
	}, [editor, state]);

	return (
		<Box
			className={clsx({
				[styles.textControls]: true,
				[styles.hide]: isPopupView && designEditorStore.activeStep !== TEXT_INDEX,
			})}
		>
			<Box className={styles.textControlsWrapper}>
				<Box
					className={styles.fontSelector}
					onClick={() => {
						setIsSidebarOpen(true);
						setActivePanel(PanelType.FONTSELECTOR);
					}}
				>
					{state?.family?.length > 12 ? state?.family?.slice(0, 12) + "..." : state?.family}
					<Box display={"flex"}>
						<ChevronDown color=" rgba(0, 0, 0, 0.54);" size={24} />
					</Box>
				</Box>

				<TextFontSize />
				<Divider className={styles.divider} orientation={"vertical"} />

				<Box className={styles.textControlsIcons}>
					<Box
						style={{
							backgroundColor: state.color,
							background: state.color,
							opacity: activeObject?.opacity,
						}}
						onClick={() => {
							setIsSidebarOpen(true);
							setActivePanel(PanelType.TEXTFILL);
						}}
						className={styles.colorBox}
					></Box>
					<Divider className={styles.divider} orientation={"vertical"} />
					<BriaTooltip title={t("bold")} placement="bottom">
						<BriaButton
							className={styles.iconbutton}
							disableRipple
							disabled={state.styleOptions.hasBold}
							onClick={makeBold}
						>
							<img
								className={styles.textControl}
								style={{ ...(!state.bold && { color: "rgb(169,169,169)" }) }}
								src={bold}
								alt=""
							/>
						</BriaButton>
					</BriaTooltip>
					<BriaTooltip title={t("Italic")} placement="bottom">
						<BriaButton
							className={styles.iconbutton}
							disableRipple
							disabled={state.styleOptions.hasItalic}
							onClick={makeItalic}
						>
							<img
								className={styles.textControl}
								style={{ ...(!state.italic && { color: "rgb(169,169,169)" }) }}
								src={italic}
								alt=""
							/>
						</BriaButton>
					</BriaTooltip>
					<BriaTooltip title={t("Underline")} placement="bottom">
						<img
							className={styles.textControl}
							style={{ ...(!state.underline && { color: "rgb(169,169,169)" }) }}
							onClick={makeUnderline}
							src={underline}
							alt=""
						/>
					</BriaTooltip>
					<TextLetterCase />
					{!isPopupView && (
						<>
							<Divider className={styles.divider} orientation={"vertical"} /> <TextAlign />
							<Divider className={styles.divider} orientation={"vertical"} /> <TextSpacing />
						</>
					)}
				</Box>
			</Box>
			{!isPopupView && <EditAllAdsSwitch />}
		</Box>
	);
}
