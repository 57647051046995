import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CrownIcon } from "../../../../assets/images/icons/CrownIcon";
import { useAppStore } from "../../../../hooks/useStores";
import { useSubscription } from "../../../../hooks/useSubscription";

import { TAILORED_GENERATION_MODEL_STATUS } from "../../../../constants/TGConstants.ts";
import { isBriaMember } from "../../../../utils";
import CustomSingleSelect, {
	IGroup,
	IGroupOption,
	SingleSelectGroupedDropDownIProps,
} from "../../CustomSingleSelectDropdown/ٍSingleSelectGroupedDropDown";
import styles from "./TextToImageModelsDropdown.module.scss";

const TextToImageModelsDropdown = ({
	handleChange,
	...rest
}: Partial<SingleSelectGroupedDropDownIProps<string | string[]>>) => {
	const { t } = useTranslation("translation", {
		keyPrefix: "playground.textToImage.config.models.dropdownLabels",
	});
	const { textToImageStore, tailoredGenerationStore, authStore, pricingStore } = useAppStore();

	const { isPremiumOrgSubscription } = useSubscription();
	const isUserProOrUltimate = isPremiumOrgSubscription();
	const [foundationModelsItems, setFoundationModelsItems] = useState<IGroupOption[]>([]);
	const [tailoredModelsItems, setTailoredModelsItems] = useState<IGroupOption[]>([]);
	const modelGroups: IGroup[] = [
		{
			name: t("foundationModels"),
			options: foundationModelsItems,
			loading: textToImageStore.loadingModels,
		},
		{
			name: t("tailoredModels"),
			options: tailoredModelsItems,
			loading: tailoredGenerationStore.isLoading,
		},
	];

	useEffect(() => {
		const fetchModels = async () => {
			await Promise.all([
				setFoundationModelsItems(await createFoundationItems()),
				setTailoredModelsItems(await createTailoredItems()),
			]);
		};

		fetchModels();
	}, []);

	useEffect(() => {
		if (Object.keys(textToImageStore.models).length) {
			if (
				(!textToImageStore.config.model || !textToImageStore.config.model_version) &&
				!textToImageStore.config.tailored_model_id
			) {
				textToImageStore.handleConfigChange("model", "fast");
				textToImageStore.handleConfigChange("model_version", "2.3");
			}
		}
	}, [textToImageStore.models]);

	const handleModelChange = (event: any) => {
		const selectedOption = modelGroups
			.map((group) => group.options)
			.flat()
			.find((option) => option?.id === event.target.value);

		if (selectedOption) {
			if (
				!selectedOption.extraData?.isTailoredModel ||
				(selectedOption.extraData?.isTailoredModel && (isUserProOrUltimate || isBriaMember(authStore.user)))
			) {
				handleChange(event, selectedOption);
			} else {
				pricingStore.openPricingFlow();
			}
		}
	};

	const createFoundationItems = async (): Promise<IGroupOption[]> => {
		return Object.entries(await textToImageStore.getModels())
			.flatMap(([modelName, model]) =>
				model.versions.map((version: string) => ({
					id: getModelKey(modelName, version),
					key: `Bria ${version} ${t(modelName)}`,
					value: getModelKey(modelName, version),
					label: `Bria ${version} ${t(modelName)}`,
				})),
			)
			.slice()
			.sort(customfoundationModelsSort);
	};

	const createTailoredItems = async (): Promise<IGroupOption[]> => {
		return Object.entries(await tailoredGenerationStore.loadModels(TAILORED_GENERATION_MODEL_STATUS.COMPLETED)).map(
			([id, model]) => ({
				id: id,
				value: id,
				key: model.name,
				label: model.name,
				description: model.description ?? "",
				extraData: {
					endpointModelName: model.model_url,
					isTailoredModel: true,
				},
				icon: !isBriaMember(authStore.user) && !isUserProOrUltimate ? <CrownIcon /> : "",
			}),
		);
	};

	function customfoundationModelsSort(item1: IGroupOption, item2: IGroupOption) {
		const order = ["fast_2.3", "fast_2.2", "base_2.3", "hd_2.2"];
		return order.indexOf(item1.id) - order.indexOf(item2.id);
	}

	return (
		<CustomSingleSelect
			handleChange={handleModelChange}
			groups={modelGroups}
			paddingClass={styles.customSingleSelectRoot}
			selectStyle={styles.selectStyle}
			menuMaxHeight="50vh"
			className={styles.menuItemRoot}
			sortAlphabetically={false}
			hideEmptyGroups={true}
			{...rest}
		/>
	);
};

export default observer(TextToImageModelsDropdown);

export type TextToImageModels = {
	[model: string]: {
		versions: string[];
		steps: { min: number; max: number; default: number };
		max_results: number;
	};
};

export const getModelKey = (model: string, version: string) => {
	return `${model}_${version}`;
};

export const getModelName = (modelKey: string) => {
	return modelKey.split("_")[0];
};
export const getModelVersion = (modelKey: string) => {
	return modelKey.split("_")[1];
};
