import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function DashedBoxIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 10 10" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.375 2.14286C0.375 1.32177 0.915715 0.375 1.88889 0.375H3.44444C3.78962 0.375 4.06944 0.654822 4.06944 1C4.06944 1.34518 3.78962 1.625 3.44444 1.625H1.88889C1.87459 1.625 1.82183 1.63085 1.75369 1.71845C1.68491 1.80689 1.625 1.95485 1.625 2.14286V3.57143C1.625 3.91661 1.34518 4.19643 1 4.19643C0.654822 4.19643 0.375 3.91661 0.375 3.57143V2.14286ZM5.93056 1C5.93056 0.654822 6.21038 0.375 6.55556 0.375H8.11111C9.08428 0.375 9.625 1.32177 9.625 2.14286V3.57143C9.625 3.91661 9.34518 4.19643 9 4.19643C8.65482 4.19643 8.375 3.91661 8.375 3.57143V2.14286C8.375 1.95485 8.31509 1.80689 8.24631 1.71845C8.17817 1.63085 8.12541 1.625 8.11111 1.625H6.55556C6.21038 1.625 5.93056 1.34518 5.93056 1ZM1 5.80357C1.34518 5.80357 1.625 6.08339 1.625 6.42857V7.85714C1.625 8.04515 1.68491 8.19311 1.75369 8.28155C1.82183 8.36915 1.87459 8.375 1.88889 8.375H3.44444C3.78962 8.375 4.06944 8.65482 4.06944 9C4.06944 9.34518 3.78962 9.625 3.44444 9.625H1.88889C0.915715 9.625 0.375 8.67823 0.375 7.85714V6.42857C0.375 6.08339 0.654822 5.80357 1 5.80357ZM9 5.80357C9.34518 5.80357 9.625 6.08339 9.625 6.42857V7.85714C9.625 8.67823 9.08428 9.625 8.11111 9.625H6.55556C6.21038 9.625 5.93056 9.34518 5.93056 9C5.93056 8.65482 6.21038 8.375 6.55556 8.375H8.11111C8.12541 8.375 8.17817 8.36915 8.24631 8.28155C8.31509 8.19311 8.375 8.04515 8.375 7.85714V6.42857C8.375 6.08339 8.65482 5.80357 9 5.80357Z"
				fill="#5B5B5B"
			/>
		</SvgIcon>
	);
}

export default DashedBoxIcon;
