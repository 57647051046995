import CloseIcon from "@mui/icons-material/CloseTwoTone";
import {
	Box,
	Dialog,
	Paper,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import Check from "../../../../assets/images/svgs/Check.svg";
import Info from "../../../../assets/images/svgs/info-circle-black.svg";
import Vector from "../../../../assets/images/svgs/Vector 187.svg";
import { ORG_SUBSCRIPTION_PLANS } from "../../../../constants/billing.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import { OrgSubscriptionPlan } from "../../../../models/billing.ts";
import BriaButton from "../../../common/BriaButton/BriaButton.tsx";
import BriaTooltip from "../../../common/BriaTooltip/BriaTooltip.tsx";
import RequestAccessContainer from "../../../TailoredGeneration/RequestAccessContainer/RequestAccessContainer.tsx";
import styles from "./PricingPopup.module.scss";

interface Features {
	[key: string]: {
		title: string;
		tooltip: string;
		starter: string;
		pro: string;
		enterprise: string;
		tooltipLink?: string;
		showActionsPricingTooltip?: boolean;
	};
}

interface IProps {
	open: boolean;
	onClose: () => void;
	onPlanClick: (plan: OrgSubscriptionPlan) => void;
}

const PricingPopup = ({ open, onClose, onPlanClick }: IProps) => {
	const transKeyPrefix = "pricingFlow.pricingPopup";
	const { t } = useTranslation("translation", { keyPrefix: transKeyPrefix });
	const { authStore } = useAppStore();
	const isLoading = authStore.isLoadingUerOrganizations || authStore.isLoadingOrgSubscriptions;

	const currentPlanName = authStore.orgSubscription?.plan_name ?? ORG_SUBSCRIPTION_PLANS.free.name;
	const features: Features = t("features", {
		returnObjects: true,
	});

	const PlanButton = ({ plan }: { plan: OrgSubscriptionPlan }) =>
		plan.selfService ? (
			<BriaButton
				className={styles.planButton}
				loading={authStore.isLoadingOrgSubscriptions}
				disabled={
					(!authStore.orgSubscription?.cancels_on && currentPlanName.toLowerCase() === plan.name) || isLoading
				}
				onClick={() => onPlanClick(plan)}
				buttonType={"primary"}
			>
				{authStore.orgSubscription?.cancels_on && plan.name === currentPlanName.toLowerCase()
					? t("renewSubscription")
					: plan.name === currentPlanName.toLowerCase()
					? t("yourCurrentPlan")
					: t(`${plan.name}.CTA`)}
			</BriaButton>
		) : (
			<RequestAccessContainer
				paperClass={styles.contactUsPaper}
				className={styles.contactUsContainer}
				requestFeature={`${plan.displayName} Plan`}
				buttonMessage={t(`${plan.name}.CTA`)}
				requestTitle={t("upgradeToPopupTitle", { planName: plan.displayName })}
				requestMessage={" "}
				showCrown={false}
			/>
		);

	const PricingTableCell = ({
		children,
		firstColumn = false,
		tooltip = undefined,
		tooltipLink = undefined,
	}: {
		children: ReactNode;
		firstColumn?: boolean;
		tooltip?: string | ReactNode;
		tooltipLink?: string;
	}) => (
		<TableCell
			className={clsx(styles.tableCell, {
				[styles.firstColumn]: firstColumn,
				[styles.checkColumn]: children === "check",
			})}
		>
			<Box className={clsx(styles.tableCellContent)}>
				{children === "check" ? (
					<img src={Check} alt="check icon" />
				) : children === "none" ? (
					<img src={Vector} alt={"vector icon"} />
				) : (
					children
				)}
				{tooltip && (
					<BriaTooltip
						placement="right"
						title={
							tooltipLink ? (
								<Trans
									prefix={transKeyPrefix}
									i18nKey={tooltip as string}
									components={[
										<Link
											onClick={() => {
												window.open(tooltipLink, "_blank");
											}}
											className={styles.link}
										/>,
									]}
								/>
							) : (
								tooltip
							)
						}
						className={styles.tooltip}
						componentsProps={{ tooltip: { className: styles.briaTooltip } }}
					>
						<img src={Info} alt={"info icon"} />
					</BriaTooltip>
				)}
			</Box>
		</TableCell>
	);

	const PricingTableRow = ({ children, firstRow = false }: { children: ReactNode; firstRow?: boolean }) => (
		<TableRow className={clsx(styles.tableRow, { [styles.firstRow]: firstRow })}>{children}</TableRow>
	);

	const PricingTableHead = () => (
		<TableHead>
			<PricingTableRow firstRow={true}>
				<PricingTableCell firstColumn={true}>
					<Typography className={styles.pricingHeaderContainer}>
						<Typography className={styles.pricingHeader}>{t("briaAi")}</Typography>
						<Typography className={styles.pricingHeader}>{t("title")}</Typography>
					</Typography>
				</PricingTableCell>
				<PricingTableCell>
					<Box className={styles.planContainer}>
						<Box>
							<Typography className={styles.planTitle}>{t("starter.header")}</Typography>
							<Typography className={styles.planDesc}>{t("starter.subHeader")}</Typography>
						</Box>
						<PlanButton plan={ORG_SUBSCRIPTION_PLANS.starter} />
					</Box>
				</PricingTableCell>
				<PricingTableCell>
					<Box className={styles.planContainer}>
						<Box>
							<Typography className={styles.planTitle}>{t("pro.header")}</Typography>
							<Typography className={styles.planDesc}>{t("pro.subHeader")}</Typography>
						</Box>
						<PlanButton plan={ORG_SUBSCRIPTION_PLANS.pro} />
					</Box>
				</PricingTableCell>
				<PricingTableCell>
					<Box className={styles.planContainer}>
						<Box>
							<Typography className={styles.planTitle}>{t("enterprise.header")}</Typography>
							<Typography className={styles.planDesc}>{t("enterprise.subHeader")}</Typography>
						</Box>
						<PlanButton plan={ORG_SUBSCRIPTION_PLANS.enterprise} />
					</Box>
				</PricingTableCell>
			</PricingTableRow>
		</TableHead>
	);

	const ActionsPricingTable = ({ plan }: { plan: OrgSubscriptionPlan }) => {
		const planActionsPricing: { [row: string]: { col1: string; col2: string } } = t(`actionsPricing.${plan.name}`, {
			returnObjects: true,
		});
		return (
			<Box className={styles.actionsPricingTableContainer}>
				<Table className={styles.actionsPricingTable}>
					<TableHead className={styles.actionsPricingTableHead}>
						<TableRow>
							<TableCell className={styles.actionsPricingTableCell}> {t("monthlyActions")}</TableCell>
							<TableCell className={styles.actionsPricingTableCell}>{t("pricePerAction")}</TableCell>
						</TableRow>
					</TableHead>
					<TableBody className={styles.actionsPricingTableBody}>
						{Object.values(planActionsPricing).map((row) => (
							<TableRow>
								<TableCell className={styles.actionsPricingTableCell}>{row.col1}</TableCell>
								<TableCell className={styles.actionsPricingTableCell}>{row.col2}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
		);
	};

	const PricingTableBody = () => (
		<TableBody>
			{Object.entries(features).map(([_key, feature]) => (
				<PricingTableRow>
					<PricingTableCell firstColumn={true} tooltip={feature.tooltip} tooltipLink={feature.tooltipLink}>
						{feature.title}
					</PricingTableCell>
					<PricingTableCell
						tooltip={
							feature.showActionsPricingTooltip ? (
								<ActionsPricingTable plan={ORG_SUBSCRIPTION_PLANS.starter} />
							) : undefined
						}
					>
						{feature.starter}
					</PricingTableCell>
					<PricingTableCell
						tooltip={
							feature.showActionsPricingTooltip ? (
								<ActionsPricingTable plan={ORG_SUBSCRIPTION_PLANS.pro} />
							) : undefined
						}
					>
						{feature.pro}
					</PricingTableCell>
					<PricingTableCell>{feature.enterprise}</PricingTableCell>
				</PricingTableRow>
			))}
		</TableBody>
	);

	const PricingTable = () => (
		<TableContainer style={{ overflowX: "visible" }} className={styles.tableContainer} component={Paper}>
			<Table
				sx={{
					[`& .${tableCellClasses.root}`]: {
						border: "none",
					},
				}}
			>
				<PricingTableHead />
				<PricingTableBody />
			</Table>
		</TableContainer>
	);

	return (
		<Dialog
			classes={{
				paper: clsx(styles.container),
			}}
			onClose={() => {
				onClose();
			}}
			open={open}
			maxWidth={false}
		>
			<Box className={styles.closeIconContainer}>
				<CloseIcon onClick={onClose} className={styles.closeIcon} />
			</Box>
			<PricingTable />
		</Dialog>
	);
};

export default observer(PricingPopup);
