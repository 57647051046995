import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { DashboardIcon } from "../../assets/images/icons/DashboardIcon";
import AccountIcon from "../../assets/images/svgs/AccountIcon.svg";
import HelpIcon from "../../assets/images/svgs/HelpIcon.svg";
import TermsAndConditionsIcon from "../../assets/images/svgs/notes.svg";
import BriaButton from "../../components/common/BriaButton/BriaButton";
import MenuCell from "../../components/common/MenuCell/MenuCell";
import LinkConstants from "../../constants/LinkConstants";
import RouterConstants from "../../constants/RouterConstants";
import useOrgNavigation from "../../hooks/useOrgNavigation.tsx";
import { useAppStore } from "../../hooks/useStores.tsx";
import {
	isAccentureOrg,
	isAwsOrg,
	isBriaMember,
	isBriaOrg,
	isFoxOrg,
	isPublicisLePointUnileverOrg,
	isPublicisOrg,
	isTagWorldwideOrg,
} from "../../utils";
import styles from "./Sidebar.module.scss";

interface SideMenuButtonProps {
	title: string;
	url: string;
	icon?: ({ stroke }: any) => JSX.Element;
	isBeta?: boolean;
	newTab?: boolean;
}

function Sidebar() {
	const { uiStore, authStore } = useAppStore();
	const { orgNavigationHandler } = useOrgNavigation();

	const { t } = useTranslation("translation", { keyPrefix: "sideMenu" });
	const myOrgRoute = authStore.user?.isSuperAdmin()
		? RouterConstants.USAGE_BILLING.fullPath
		: RouterConstants.AUTH_KEYS.fullPath;

	const sideMenuButtons: SideMenuButtonProps[] = [
		{
			title: t("dashboard"),
			url: RouterConstants.CONSOLE.path,
			icon: DashboardIcon,
		},
		{
			title: t("models"),
			url: RouterConstants.FOUNDATION_MODELS.fullPath,
		},
		{
			title: t("apis"),
			url: RouterConstants.BASE_PLATFORM_APIS.fullPath,
		},
		{
			title: t("iframe"),
			url: RouterConstants.IFRAME_CONFIG.fullPath,
		},
		{
			title: t("apps"),
			url: `${RouterConstants.APPS.path}`,
		},
	];

	sideMenuButtons.splice(4, 0, {
		title: t("tailoredGeneration"),
		url: RouterConstants.TG_MODELS.fullPath,
		isBeta: true,
	});

	if (
		isAccentureOrg() ||
		isPublicisOrg() ||
		isPublicisLePointUnileverOrg() ||
		isBriaMember(authStore.user) ||
		isTagWorldwideOrg()
	) {
		sideMenuButtons.splice(5, 0, {
			title: t("campaignBrand"),
			url: RouterConstants.TEMPLATES.fullPath,
		});
	}

	if (isFoxOrg() || isBriaOrg()) {
		sideMenuButtons.push({
			title: t("foxApps"),
			url: `${RouterConstants.FOX_APPS.path}`,
		});
	}

	if (isBriaOrg() || isAwsOrg()) {
		sideMenuButtons.push({
			title: t("campaign"),
			url: `${RouterConstants.DESIGN_EDITOR.fullPath}`,
			newTab: true,
		});
	}

	return (
		<Box className={styles.sideBarContainer} ref={uiStore.sideBar}>
			{sideMenuButtons.map((btn: any, index: any) => {
				return (
					<div key={index}>
						<NavLink
							end={true}
							to={btn.url}
							className={styles.tabContainer}
							target={btn.newTab ? "_blank" : undefined}
							onClick={(e) => {
								e.preventDefault();
								orgNavigationHandler(btn.url, undefined, btn.newTab);
							}}
						>
							{({ isActive }: { isActive: boolean }) => (
								<MenuCell
									key={index}
									icon={btn.icon && btn.icon}
									selected={isActive}
									text={btn.title}
									isBeta={btn.isBeta}
								/>
							)}
						</NavLink>
					</div>
				);
			})}
			<Box className={styles.footerButtonsContainer}>
				<NavLink
					to={myOrgRoute}
					className={styles.tabContainer}
					onClick={(e) => {
						e.preventDefault();
						orgNavigationHandler(myOrgRoute);
					}}
				>
					{({ isActive }: { isActive: boolean }) => (
						<BriaButton
							className={`${styles.btnStyle} ${isActive && styles.selectedBtnStyle}`}
							buttonType="textSmall"
						>
							<img className={styles.icon} src={AccountIcon} />
							{t("myOrganization")}
						</BriaButton>
					)}
				</NavLink>
				<NavLink to={LinkConstants.TERMS_AND_CONDITIONS_LINK} className={styles.tabContainer} target="_blank">
					<BriaButton className={styles.btnStyle} buttonType="textSmall">
						<img className={styles.icon} src={TermsAndConditionsIcon} />
						{t("termsAndConditions")}
					</BriaButton>
				</NavLink>
				<NavLink to={LinkConstants.CONTACT_US_FORM_LINK} className={styles.tabContainer} target="_blank">
					<BriaButton className={styles.btnStyle} buttonType="textSmall">
						<img className={styles.icon} src={HelpIcon} />
						{t("getHelp")}
					</BriaButton>
				</NavLink>
			</Box>
		</Box>
	);
}

export default observer(Sidebar);
