import { TFunction } from "i18next";
import {
	ColorTypeMappingEnum,
	FontTypeEnum,
	HorizontalEnum,
	LogoTypeEnum,
	LogoTypeMappingEnum,
	VerticalEnum,
} from "../../../../../models/common";
import Icons from "../../../../components/Icons";
import { Editor } from "../../../../core";
import { IDynamicText, ILayer, IStaticText } from "../../../../types";
import useSceneOperations from "../Footer/Graphic/Scenes/utils/SceneOperations";
import { ObjectsEnum } from "../Panels/panelItems";

export const ICON_SIZE = 20;

export const OBJECT_TYPES = {
	BACKGROUND: "Background",
	INITIAL_FRAME: "Initial Frame",
	STATIC_IMAGE: "StaticImage",
	STATIC_TEXT: "StaticText",
	STATIC_VECTOR: "StaticVector",
	FRAME: "Frame",
	GROUP: "group",
};

// TODO To add it when support stroke color

// const getCommonMenus = (t: TFunction) => [
// 	{
// 		onClick: () => {},
// 		icon: <Icons.Palette size={ICON_SIZE} />,
// 		label: t("brandStrokeColor"),
// 		isSubMenu: true,
// 		children: [
// 			{
// 				onClick: () => {},
// 				label: `${t("color")} #1`,
// 			},
// 			{
// 				onClick: () => {},
// 				label: `${t("color")} #2`,
// 			},
// 			{
// 				onClick: () => {},
// 				label: `${t("color")} #3`,
// 			},
// 			{
// 				onClick: () => {},
// 				label: `${t("color")} #4`,
// 			},
// 			{
// 				onClick: () => {},
// 				label: `${t("color")} #5`,
// 			},
// 		],
// 	},
// ];
function hasFontType(layer: Partial<ILayer>): layer is IStaticText | IDynamicText {
	return layer.name === OBJECT_TYPES.STATIC_TEXT;
}
const getBrandMenus = (t: TFunction, activeObject: Partial<ILayer>, isAdmin: boolean, editor: Editor) => {
	if (!isAdmin) {
		return [];
	}
	// TODO To add it when support stroke color
	// const commonMenus = getCommonMenus(t);
	const elementName = activeObject?.name ?? activeObject?.type;
	const colorKey = Object.keys(ColorTypeMappingEnum) as Array<keyof typeof ColorTypeMappingEnum>;

	switch (elementName) {
		case OBJECT_TYPES.STATIC_TEXT:
			return [
				{
					onClick: () => {},
					icon: <Icons.Palette size={ICON_SIZE} />,
					label: t("brandColor"),
					isSubMenu: true,
					children: [
						{
							onClick: () => {
								activeObject.colorNumber = 1;
							},
							label: `${ColorTypeMappingEnum[colorKey[0]]}`,
							Selected: activeObject?.colorNumber === 1 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 2;
							},
							label: `${ColorTypeMappingEnum[colorKey[1]]}`,
							Selected: activeObject?.colorNumber === 2 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 3;
							},
							label: `${ColorTypeMappingEnum[colorKey[2]]}`,
							Selected: activeObject?.colorNumber === 3 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 4;
							},
							label: `${ColorTypeMappingEnum[colorKey[3]]}`,
							Selected: activeObject?.colorNumber === 4 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 5;
							},
							label: `${ColorTypeMappingEnum[colorKey[4]]}`,
							Selected: activeObject?.colorNumber === 5 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 6;
							},
							label: `${ColorTypeMappingEnum[colorKey[5]]}`,
							Selected: activeObject?.colorNumber === 6 ? true : false,
						},
					],
				},
				{
					onClick: () => {},
					icon: <Icons.TextSize size={ICON_SIZE} />,
					label: t("brandFont"),
					isSubMenu: true,
					children: [
						{
							onClick: () => {
								if (hasFontType(activeObject)) activeObject.fontType = FontTypeEnum.FONT_1;
							},
							label: `${t("font")} #1`,
							Selected:
								hasFontType(activeObject) && activeObject?.fontType === FontTypeEnum.FONT_1
									? true
									: false,
						},
						{
							onClick: () => {
								if (hasFontType(activeObject)) activeObject.fontType = FontTypeEnum.FONT_2;
							},
							label: `${t("font")} #2`,
							Selected:
								hasFontType(activeObject) && activeObject?.fontType === FontTypeEnum.FONT_2
									? true
									: false,
						},
						{
							onClick: () => {
								if (hasFontType(activeObject)) activeObject.fontType = FontTypeEnum.FONT_3;
							},
							label: `${t("font")} #3`,
							Selected:
								hasFontType(activeObject) && activeObject?.fontType === FontTypeEnum.FONT_3
									? true
									: false,
						},
						{
							onClick: () => {
								if (hasFontType(activeObject)) activeObject.fontType = FontTypeEnum.FONT_4;
							},
							label: `${t("font")} #4`,
							Selected:
								hasFontType(activeObject) && activeObject?.fontType === FontTypeEnum.FONT_4
									? true
									: false,
						},
						{
							onClick: () => {
								if (hasFontType(activeObject)) activeObject.fontType = FontTypeEnum.FONT_5;
							},
							label: `${t("font")} #5`,
							Selected:
								hasFontType(activeObject) && activeObject?.fontType === FontTypeEnum.FONT_5
									? true
									: false,
						},
					],
				},
				{ divider: true },
			];
		case OBJECT_TYPES.STATIC_VECTOR:
			return [
				{
					onClick: () => {},
					icon: <Icons.Palette size={ICON_SIZE} />,
					label: t("brandFillColor"),
					isSubMenu: true,
					children: [
						{
							onClick: () => {
								activeObject.colorNumber = 1;
							},
							label: `${ColorTypeMappingEnum[colorKey[0]]}`,
							Selected: activeObject?.colorNumber === 1 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 2;
							},
							label: `${ColorTypeMappingEnum[colorKey[1]]}`,
							Selected: activeObject?.colorNumber === 2 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 3;
							},
							label: `${ColorTypeMappingEnum[colorKey[2]]}`,
							Selected: activeObject?.colorNumber === 3 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 4;
							},
							label: `${ColorTypeMappingEnum[colorKey[3]]}`,
							Selected: activeObject?.colorNumber === 4 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 5;
							},
							label: `${ColorTypeMappingEnum[colorKey[4]]}`,
							Selected: activeObject?.colorNumber === 5 ? true : false,
						},
						{
							onClick: () => {
								activeObject.colorNumber = 6;
							},
							label: `${ColorTypeMappingEnum[colorKey[5]]}`,
							Selected: activeObject?.colorNumber === 6 ? true : false,
						},
					],
				},
				// TODO: To add it later when we support Graphics border
				// ...commonMenus,
				{ divider: true },
			];
		case OBJECT_TYPES.STATIC_IMAGE:
			const logoKeys = Object.keys(LogoTypeMappingEnum) as Array<keyof typeof LogoTypeMappingEnum>;

			return [
				{
					onClick: () => {},
					icon: <Icons.Sticker size={ICON_SIZE} />,
					label: t("brandLogo"),
					isSubMenu: true,
					children: [
						{
							onClick: () => {
								activeObject.logoType = LogoTypeEnum.LOGO_1;
							},
							label: ` ${LogoTypeMappingEnum[logoKeys[0]]}`,
							Selected: activeObject?.logoType === LogoTypeEnum.LOGO_1 ? true : false,
						},
						{
							onClick: () => {
								activeObject.logoType = LogoTypeEnum.LOGO_2;
							},
							label: ` ${LogoTypeMappingEnum[logoKeys[1]]}`,
							Selected: activeObject?.logoType === LogoTypeEnum.LOGO_2 ? true : false,
						},
						{
							onClick: () => {
								activeObject.logoType = LogoTypeEnum.LOGO_3;
							},
							label: ` ${LogoTypeMappingEnum[logoKeys[2]]}`,
							Selected: activeObject?.logoType === LogoTypeEnum.LOGO_3 ? true : false,
						},
						{
							onClick: () => {
								activeObject.logoType = LogoTypeEnum.LOGO_4;
							},
							label: ` ${LogoTypeMappingEnum[logoKeys[3]]}`,
							Selected: activeObject?.logoType === LogoTypeEnum.LOGO_4 ? true : false,
						},
						{
							onClick: () => {
								activeObject.logoType = LogoTypeEnum.LOGO_5;
							},
							label: ` ${LogoTypeMappingEnum[logoKeys[4]]}`,
							Selected: activeObject?.logoType === LogoTypeEnum.LOGO_5 ? true : false,
						},
						{
							onClick: () => {
								activeObject.logoType = LogoTypeEnum.LOGO_6;
							},
							label: ` ${LogoTypeMappingEnum[logoKeys[5]]}`,
							Selected: activeObject?.logoType === LogoTypeEnum.LOGO_6 ? true : false,
						},
					],
				},
				{
					onClick: () => {},
					icon: <Icons.Alignment size={ICON_SIZE} />,
					label: t("logoAlignment"),
					isSubMenu: true,
					children: [
						{
							onClick: (e: React.MouseEvent<HTMLDivElement>) => {
								editor.objects.update({ horizontalAlignment: HorizontalEnum.LEFT });
								editor.canvas.canvas.fire("object:modified", { target: activeObject });
								e.stopPropagation();
							},
							icon: <Icons.AlignLeft size={ICON_SIZE} />,
							label: t("alignLeft"),
							Selected: activeObject?.horizontalAlignment === HorizontalEnum.LEFT ? true : false,
						},
						{
							onClick: (e: React.MouseEvent<HTMLDivElement>) => {
								editor.objects.update({ horizontalAlignment: HorizontalEnum.CENTER });
								editor.canvas.canvas.fire("object:modified", { target: activeObject });
								e.stopPropagation();
							},
							icon: <Icons.AlignCenter size={ICON_SIZE} />,
							label: t("alignCenter"),
							Selected: activeObject?.horizontalAlignment === HorizontalEnum.CENTER ? true : false,
						},
						{
							onClick: (e: React.MouseEvent<HTMLDivElement>) => {
								editor.objects.update({ horizontalAlignment: HorizontalEnum.RIGHT });
								editor.canvas.canvas.fire("object:modified", { target: activeObject });
								e.stopPropagation();
							},
							icon: <Icons.AlignRight size={ICON_SIZE} />,
							label: t("alignRight"),
							Selected: activeObject?.horizontalAlignment === HorizontalEnum.RIGHT ? true : false,
						},
						{ divider: true },
						{
							onClick: (e: React.MouseEvent<HTMLDivElement>) => {
								editor.objects.update({ verticalAlignment: VerticalEnum.TOP });
								editor.canvas.canvas.fire("object:modified", { target: activeObject });
								e.stopPropagation();
							},
							icon: <Icons.AlignTop size={ICON_SIZE} />,
							label: t("alignTop"),
							Selected: activeObject?.verticalAlignment === VerticalEnum.TOP ? true : false,
						},
						{
							onClick: (e: React.MouseEvent<HTMLDivElement>) => {
								editor.objects.update({ verticalAlignment: VerticalEnum.CENTER });
								editor.canvas.canvas.fire("object:modified", { target: activeObject });
								e.stopPropagation();
							},
							icon: <Icons.AlignMiddle size={ICON_SIZE} />,
							label: t("alignMiddle"),
							Selected: activeObject?.verticalAlignment === VerticalEnum.CENTER ? true : false,
						},
						{
							onClick: (e: React.MouseEvent<HTMLDivElement>) => {
								editor.objects.update({ verticalAlignment: VerticalEnum.BOTTOM });
								editor.canvas.canvas.fire("object:modified", { target: activeObject });
								e.stopPropagation();
							},
							icon: <Icons.AlignBottom size={ICON_SIZE} />,
							label: t("alignBottom"),
							Selected: activeObject?.verticalAlignment === VerticalEnum.BOTTOM ? true : false,
						},
					],
				},

				// TODO: To add it later when we support  stroke color
				// ...commonMenus,
				{ divider: true },
			];
		case OBJECT_TYPES.INITIAL_FRAME || OBJECT_TYPES.BACKGROUND:
			const initialFrame = editor?.objects.findOneById(ObjectsEnum.InitialFrame);
			return [
				{
					onClick: () => {},
					icon: <Icons.Palette size={ICON_SIZE} />,
					label: t("brandFillColor"),
					isSubMenu: true,
					children: [
						{
							onClick: () => {
								initialFrame.colorNumber = 1;
							},
							label: `${ColorTypeMappingEnum[colorKey[0]]}`,
							Selected: initialFrame?.colorNumber === 1 ? true : false,
						},
						{
							onClick: () => {
								initialFrame.colorNumber = 2;
							},
							label: `${ColorTypeMappingEnum[colorKey[1]]}`,
							Selected: initialFrame?.colorNumber === 2 ? true : false,
						},
						{
							onClick: () => {
								initialFrame.colorNumber = 3;
							},
							label: `${ColorTypeMappingEnum[colorKey[2]]}`,
							Selected: initialFrame?.colorNumber === 3 ? true : false,
						},
						{
							onClick: () => {
								initialFrame.colorNumber = 4;
							},
							label: `${ColorTypeMappingEnum[colorKey[3]]}`,
							Selected: initialFrame?.colorNumber === 4 ? true : false,
						},
						{
							onClick: () => {
								initialFrame.colorNumber = 5;
							},
							label: `${ColorTypeMappingEnum[colorKey[4]]}`,
							Selected: initialFrame?.colorNumber === 5 ? true : false,
						},
						{
							onClick: () => {
								initialFrame.colorNumber = 6;
							},
							label: `${ColorTypeMappingEnum[colorKey[5]]}`,
							Selected: initialFrame?.colorNumber === 6 ? true : false,
						},
					],
				},
				{ divider: true },
			];
		default:
			return [];
	}
};

const getBackgroundMenu = (
	t: TFunction,
	activeObject: Partial<ILayer>,
	isAdmin: boolean,
	deleteScene: (id?: string) => Promise<void>,
	duplicateScene: (id?: string) => Promise<void>,
	editor: Editor,
) => [
	{
		onClick: () => {
			duplicateScene(editor?.scene.exportToJSON().id);
		},
		icon: <Icons.Duplicate size={ICON_SIZE} />,
		label: t("duplicate"),
	},
	{
		onClick: () => {
			deleteScene(editor?.scene.exportToJSON().id);
		},
		icon: <Icons.Delete size={ICON_SIZE} />,
		label: t("delete"),
	},
	{ divider: true },

	...getBrandMenus(t, activeObject, isAdmin, editor),
	{
		onClick: () => {
			editor.objects.lock();
			editor.cancelContextMenuRequest();
		},
		icon: <Icons.Locked size={ICON_SIZE} />,
		label: t("lock"),
	},
];

const getMainMenu = (t: TFunction, editor: Editor, isAdmin: boolean, activeObject: Partial<ILayer>) => [
	{
		onClick: () => {
			editor.objects.copy();
			editor.cancelContextMenuRequest();
		},
		icon: <Icons.Copy size={ICON_SIZE} />,
		label: t("copy"),
	},
	{
		onClick: () => {
			editor.objects.paste();
			editor.cancelContextMenuRequest();
		},
		icon: <Icons.Paste size={ICON_SIZE} />,
		label: t("paste"),
	},
	{
		onClick: () => {
			editor.objects.copy();
			editor.objects.paste();
			editor.cancelContextMenuRequest();
		},
		icon: <Icons.Duplicate size={ICON_SIZE} />,
		label: t("duplicate"),
	},
	{
		onClick: () => {
			editor.objects.remove();
			editor.cancelContextMenuRequest();
		},
		icon: <Icons.Delete size={ICON_SIZE} />,
		label: t("delete"),
	},
	{ divider: true },
	{
		onClick: () => {},
		icon: <Icons.Layers size={ICON_SIZE} />,
		label: t("layer"),
		isSubMenu: true,
		children: [
			{
				onClick: () => {
					editor.objects.bringToFront();
					editor.cancelContextMenuRequest();
				},
				label: t("bringToFront"),
			},
			{
				onClick: () => {
					editor.objects.bringForward();
					editor.cancelContextMenuRequest();
				},
				label: t("bringForward"),
			},
			{
				onClick: () => {
					editor.objects.sendBackwards();
					editor.cancelContextMenuRequest();
				},
				label: t("sendBackward"),
			},
			{
				onClick: () => {
					editor.objects.sendToBack();
					editor.cancelContextMenuRequest();
				},
				label: t("sendToBack"),
			},
			{
				onClick: () => {
					//editor?.objects.sendToBack();
				},
				label: t("showLayers"),
			},
		],
	},
	{
		onClick: () => {},
		icon: <Icons.Alignment size={ICON_SIZE} />,
		label: t("align"),
		isSubMenu: true,
		children: [
			{
				onClick: () => {
					editor.objects.alignLeft();
				},
				icon: <Icons.AlignLeft size={ICON_SIZE} />,
				label: t("alignLeft"),
			},
			{
				onClick: () => {
					editor.objects.alignCenter();
				},
				icon: <Icons.AlignCenter size={ICON_SIZE} />,
				label: t("alignCenter"),
			},
			{
				onClick: () => {
					editor.objects.alignRight();
				},
				icon: <Icons.AlignRight size={ICON_SIZE} />,
				label: t("alignRight"),
			},
			{
				onClick: () => {
					editor.objects.alignTop();
				},
				icon: <Icons.AlignTop size={ICON_SIZE} />,
				label: t("alignTop"),
			},
			{
				onClick: () => {
					editor.objects.alignMiddle();
				},
				icon: <Icons.AlignMiddle size={ICON_SIZE} />,
				label: t("alignMiddle"),
			},
			{
				onClick: () => {
					editor.objects.alignBottom();
				},
				icon: <Icons.AlignBottom size={ICON_SIZE} />,
				label: t("alignBottom"),
			},
		],
	},
	{ divider: true },
	...getBrandMenus(t, activeObject, isAdmin, editor),
	{
		onClick: () => {
			editor.objects.lock();
			editor.cancelContextMenuRequest();
		},
		icon: <Icons.Locked size={ICON_SIZE} />,
		label: t("lock"),
	},
];

const getLockedMenu = (t: TFunction, editor: Editor) => [
	{
		onClick: () => {
			editor.objects.unlock();
			editor.cancelContextMenuRequest();
		},
		icon: <Icons.Unlocked size={ICON_SIZE} />,
		label: t("unlock"),
	},
];

const getTextComponentStatusMenu = (t: TFunction) => [
	{
		key: t("configureHeadingLevel"),
		value: t("configureHeadingLevel"),
	},

	...Array.from({ length: 5 }, (_, i) => ({
		key: `${t("heading")} #${i + 1}`,
		value: `${t("heading")} #${i + 1}`,
	})).concat(
		...Array.from({ length: 5 }, (_, i) => ({
			key: `${t("body")} #${i + 1}`,
			value: `${t("body")} #${i + 1}`,
		})),
	),
];

const getImageComponentStatusMenu = (t: TFunction) => [
	{
		key: t("configureImageLevel"),
		value: t("configureImageLevel"),
	},
	...Array.from({ length: 5 }, (_, i) => ({
		key: `${t("image")} #${i + 1}`,
		value: `${t("image")} #${i + 1}`,
	})),
];

export const ContextMenuData = (t: TFunction, editor: any, isAdmin: boolean) => {
	const activeObject = editor?.canvas?.canvas.getActiveObject();
	const { deleteScene, duplicateScene } = useSceneOperations();

	return {
		backgroundMenu: getBackgroundMenu(t, activeObject, isAdmin, deleteScene, duplicateScene, editor),
		mainMenu: getMainMenu(t, editor, isAdmin, activeObject),
		lockedMenu: getLockedMenu(t, editor),
		textComponentStatusMenu: getTextComponentStatusMenu(t),
		imageComponentStatusMenu: getImageComponentStatusMenu(t),
	};
};
