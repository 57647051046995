import { fabric } from "fabric";
import _ from "lodash";
import throttle from "lodash/throttle";
import { LayerType } from "../common/constants";
import Base from "./Base";

class History extends Base {
	private redos: any[] = [];
	private undos: any[] = [];
	private current: any[] = [];
	private isActive: boolean = false;
	private changePage: ((scene: any) => void) | undefined;
	public setChangePage(func: (newValue: string) => void) {
		this.changePage = func;
	}
	private pageChangedOnRedo: boolean = false;

	public initialize = () => {
		const canvasJSON = this.canvas.toJSON(this.config.propertiesToInclude) as any;
		this.current = _.cloneDeep(canvasJSON.objects);
		canvasJSON.objects.forEach((object: fabric.Object) => {
			if (object.clipPath) {
				fabric.util.enlivenObjects(
					[object.clipPath],
					function (arg1: any) {
						object.clipPath = arg1[0];
					},
					"",
				);
			}
		});
	};
	public getStatus = () => {
		return {
			hasUndo: this.undos.length >= 1,
			hasRedo: this.undos.length > 0,
			undos: this.undos,
			redos: this.redos,
			state: this.current,
		};
	};

	public save = (sceneId: any) => {
		try {
			const canvasJSON = this.editor.canvas.canvas.toJSON(this.config.propertiesToInclude) as any;
			canvasJSON.objects.forEach((object: fabric.Object) => {
				if (object.clipPath) {
					fabric.util.enlivenObjects(
						[object.clipPath],
						function (arg1: any) {
							object.clipPath = arg1[0];
						},
						"",
					);
				}
			});
			this.current = _.cloneDeep(canvasJSON.objects);
			const json = this.current;
			if (this.pageChangedOnRedo) return;

			this.undos.push({
				type: "UPDATE",
				json,
				sceneId: sceneId,
			});
			this.pageChangedOnRedo = false;
		} catch (err) {
			console.log(err);
		}
		this.emitStatus();
	};

	public undo = throttle(async (sceneId: any) => {
		if (this.undos.length >= 1) {
			const undo = this.undos.pop();
			if (!undo) {
				return;
			}
			if (undo.sceneId !== sceneId) {
				await this.changeSceneAndRestore(undo.sceneId);
				this.redos.push({
					type: "redo",
					json: undo.json,
					sceneId: undo.sceneId,
				});
			} else {
				this.redos.push({
					type: "redo",
					json: undo.json,
					sceneId: sceneId,
				});
			}
			this.restore(undo);
		}
	}, 100);

	public redo = throttle(async (sceneId: any) => {
		const redo = this.redos.pop();
		if (!redo) {
			return;
		}
		///
		const lastSceneId = redo.sceneId;

		// Check if the lastSceneId is unique in the array
		const isUnique = this.redos.filter((item) => item.sceneId === lastSceneId).length === 0;

		// Check if the json array of the last object has length 2
		const hasJsonLengthTwo = redo.json.length === 2;
		if (redo.sceneId !== sceneId) {
			await this.changeSceneAndRestore(redo.sceneId);
			if (!(isUnique && hasJsonLengthTwo)) {
				this.restore(redo);
				this.undos.push({
					type: "undo",
					json: redo.json,
					sceneId: redo.sceneId,
				});
			}
		} else {
			if (!(isUnique && hasJsonLengthTwo)) {
				this.restore(redo);
				this.undos.push({
					type: "undo",
					json: redo.json,
					sceneId: sceneId,
				});
			}
		}
	}, 100);

	private changeSceneAndRestore = async (scene: any) => {
		this.pageChangedOnRedo = true;
		await this.changePage!(scene);
	};

	private restore = (transaction: any) => {
		if (!this.isActive) {
			this.editor.objects.clear();
			const objects = transaction.json;
			this.current = objects;
			this.isActive = true;
			fabric.util.enlivenObjects(
				objects,
				(enlivenObjects: any[]) => {
					enlivenObjects.forEach((enlivenObject) => {
						if (enlivenObject.type !== LayerType.FRAME) {
							this.canvas.add(enlivenObject);
						}
					});
					this.emitStatus();
				},
				"",
			);
			this.isActive = false;
		}
	};

	public reset = () => {
		this.redos = [];
		this.undos = [];
		this.emitStatus();
	};

	public emitStatus = () => {
		this.editor.emit("history:changed", {
			hasUndo: this.undos.length >= 1,
			hasRedo: this.redos.length > 0,
		});
	};

	public get status() {
		return {
			undos: this.undos,
			redos: this.redos,
			state: this.current,
		};
	}
}

export default History;
