import AddIcon from "@mui/icons-material/Add";
import {
	Box,
	Pagination,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	styled,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useEffect, useState } from "react";
import EmailButtonIcon from "../../../assets/images/icons/EmailButtonIcon";
import { ButtonIcons } from "../../../layout/TableLayout/TableLayout";
import BriaButton from "../BriaButton/BriaButton";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import styles from "./BriaTable.module.scss";

export interface FilterBySearch {
	text: string;
	fields: string[];
}

export interface FilterByOptions {
	search?: FilterBySearch;
}

export type PaginationOptions = {
	rowsPerPage: number;
	totalRows: number;
	loadNextPage: (pageNumber: number, filterBy?: FilterByOptions) => Promise<void>;
};

interface IProps {
	headerArray: any;
	rows: any;
	filterBy?: FilterByOptions;
	paginationOptions?: PaginationOptions;
	loading?: boolean;
	loadingRow?: { data: any; column: string };
	disableBottomButton?: boolean;
	hideBottomButton?: boolean;
	bottomButtonIconType?: ButtonIcons;
	bottomButtonText?: string;
	bottomButtonClick?: () => void;
}

const BriaTable = ({
	headerArray,
	rows,
	filterBy,
	paginationOptions,
	loading = false,
	loadingRow,
	hideBottomButton,
	disableBottomButton,
	bottomButtonIconType,
	bottomButtonText,
	bottomButtonClick,
}: IProps) => {
	const [page, setPage] = useState(0);
	const pagesCount = paginationOptions ? Math.ceil(paginationOptions.totalRows / paginationOptions.rowsPerPage!) : 1;
	const handleChangePage = async (_event: ChangeEvent<unknown> | null, page: number) => {
		setPage(page - 1);
		await paginationOptions?.loadNextPage(page, filterBy);
	};

	useEffect(() => {
		if (rows.length) {
			setPage(0);
		}
	}, [filterBy]);

	return (
		<Box className={styles.container}>
			<TableContainer
				// sx={{ height: paginationOptions ? `${paginationOptions?.rowsPerPage * 63.38}px` : "auto" }}
				className={styles.tableContainer}
				component={Paper}
			>
				<LoadingPlaceholder className={styles.loadingPlaceholder} isLoading={loading}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								{headerArray.map((el: any, index: number) => {
									return <TableCell key={index}>{el}</TableCell>;
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row: any, index: number) => (
								<TableRow
									key={index}
									sx={{
										backgroundColor: row["rowStyle"]?.backgroundColor,
										"&:last-child td, &:last-child th": { border: 0 },
									}}
								>
									{Object.entries(row.data).map(([column, v]: any, index: any) => {
										return (
											<TableCell key={index} align="left">
												<LoadingPlaceholder
													className={styles.loadingCell}
													size="25px"
													isLoading={
														!!(
															row.key &&
															column === loadingRow?.column &&
															row.key === loadingRow?.data.id
														)
													}
												>
													{v}
												</LoadingPlaceholder>
											</TableCell>
										);
									})}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</LoadingPlaceholder>
			</TableContainer>
			{bottomButtonText && !hideBottomButton && (
				<BriaButton
					onClick={bottomButtonClick}
					buttonType="textSmall"
					disabled={disableBottomButton}
					className={styles.bottomBtn}
				>
					{bottomButtonIconType === "email" ? (
						<EmailButtonIcon className={styles.emailButtonIcon} />
					) : bottomButtonIconType === "plus" ? (
						<AddIcon />
					) : (
						<></>
					)}
					{bottomButtonText}
				</BriaButton>
			)}
			{paginationOptions && pagesCount > 1 && (
				<Box
					marginTop={bottomButtonText ? "0" : "4vh"}
					display={"flex"}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<TablePagination
						classes={{
							root: styles.paginationRoot,
							ul: styles.ul,
						}}
						onChange={handleChangePage}
						sx={{
							"&.MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
								color: "#5300c9",
							},
						}}
						count={pagesCount}
						page={page + 1}
					/>
				</Box>
			)}
		</Box>
	);
};

export default observer(BriaTable);

const TablePagination = styled(Pagination)(() => ({
	ul: {
		"li:not(:last-child,:first-of-type)": {
			button: {
				fontWeight: 600,
				fontFamily: "Montserrat",
				fontSize: "10.6px",
				width: 24,
				minWidth: "unset",
				height: 24,
				borderRadius: "50%",
				backgroundColor: "#E7E7E7",
				"&.Mui-selected": {
					color: "white",
					backgroundColor: "#5300c9",
				},
			},
		},
	},
}));
