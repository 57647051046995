import { Box, Divider, Slider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../../components/common/BriaButton/BriaButton";
import { useAppStore } from "../../../../hooks/useStores";
import InputLayout from "../../../../layout/InputLayout/InputLayout";
import SwipableMenuButton from "../../../../layout/SwipableMenu/Button/SwipableMenuButton";
import SwipableMenuLayout from "../../../../layout/SwipableMenu/Menu/SwipableMenuLayout";
import StyleConfig from "./Features/Style/StyleConfig";
import styles from "./ImageToImageConfig.module.scss";

const ImageToImageConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.imageToImage.config" });
	const { imageToImageStore, playgroundStore } = useAppStore();
	const countSelected = playgroundStore.getSelectedImages().length;
	const isGenerateEnabled =
		((imageToImageStore.config.style &&
			imageToImageStore.config.style.tailored_style &&
			imageToImageStore.config.style.sub_style) ||
			(imageToImageStore.config.background && imageToImageStore.config.background.remove) ||
			imageToImageStore.config.brand ||
			imageToImageStore.config.size) &&
		countSelected &&
		countSelected * imageToImageStore.config.num_results <= 35 &&
		!playgroundStore.playgroundResults[playgroundStore.playgroundResults.length - 1]?.images.some(
			(image) => image.loading,
		);

	return (
		<Box className={styles.wrapper}>
			<SwipableMenuLayout className={styles.config}>
				<SwipableMenuButton
					swipeTo={<StyleConfig />}
					subSwipableMenuProps={{ title: t("features.style.title") }}
				>
					<Box className={styles.buttonLabel}>{t("features.style.button")}</Box>
				</SwipableMenuButton>
				<Divider />
				{/* <SwipableMenuButton
					swipeTo={<BackgroundConfig />}
					subSwipableMenuProps={{ title: t("features.background.title") }}
				>
					<Box className={styles.buttonLabel}>{t("features.background.button")}</Box>
				</SwipableMenuButton>
				<Divider /> */}
				{/* <SwipableMenuButton
					swipeTo={<BrandConfig />}
					subSwipableMenuProps={{ title: t("features.brand.title") }}
				>
					<Box className={styles.buttonLabel}>{t("features.brand.button")}</Box>
				</SwipableMenuButton>
				<Divider />
				<SwipableMenuButton swipeTo={<SizeConfig />} subSwipableMenuProps={{ title: t("features.size.title") }}>
					<Box className={styles.buttonLabel}>{t("features.size.button")}</Box>
				</SwipableMenuButton> */}
				<InputLayout label={t("numberOfImages.label")} labelClassName={styles.inputLabel}>
					<Slider
						value={imageToImageStore.config.num_results}
						onChange={(_e, value) => imageToImageStore.handleConfigChange("num_results", value as number)}
						min={1}
						max={4}
						marks={Array.from({ length: 4 }, (_, index) => ({ value: index + 1, label: `${index + 1}` }))}
					/>
				</InputLayout>
			</SwipableMenuLayout>
			<BriaButton
				buttonType="primaryMedium"
				disabled={!isGenerateEnabled}
				fullWidth
				onClick={() => imageToImageStore.generateImageToImage()}
			>
				{countSelected * imageToImageStore.config.num_results > 35 ? t("tooManySelected") : t("button")}
			</BriaButton>
		</Box>
	);
};

export default observer(ImageToImageConfig);
