import BackgroundImage from "../assets/images/svgs/AIEditor/background.svg";
import BrandImage from "../assets/images/svgs/AIEditor/brand.svg";
import ObjectsImage from "../assets/images/svgs/AIEditor/objects.svg";
import PresentersImage from "../assets/images/svgs/AIEditor/presenters.svg";
import SizeImage from "../assets/images/svgs/AIEditor/size.svg";
import StyleImage from "../assets/images/svgs/AIEditor/style.svg";
import TextImage from "../assets/images/svgs/AIEditor/text.svg";
import { TailoredStyles, TailoredSubStyles } from "../components/common/DropDowns/StaticDropdown";

export type ObjectsRefine = {
	tailored_style?: TailoredStyles;
	sub_style?: TailoredSubStyles;
	prompt?: string;
	originalImage?: string;
};

export type ObjectsRemoveOrReplace = {
	replace_prompt?: string;
};

export type RectType = {
	x: number;
	y: number;
	width: number;
	height: number;
};

export type AIEditorOption = {
	label: string;
	icon: string;
	isEnabled: boolean;
	imageType: ImageEnum;
};

export enum AIEditorOptionsEnum {
	Background = "Background",
	Objects = "Objects",
	Presenters = "Presenters",
	Style = "Style",
	Text = "Text",
	Size = "Size",
	Brand = "Brand",
}

export enum BoxGranularityEnum {
	Min = 64,
	Max = 512,
}

export enum ImageEnum {
	/*
	 * Static: Display-only and non-selectable areas
	 * Interactive: Interactive and selectable features
	 */
	Static = "Static",
	Interactive = "interactive",
}

export type FoxTeam =
	| "Arizona Cardinals"
	| "Atlanta Falcons"
	| "Baltimore Ravens"
	| "Buffalo Bills"
	| "Carolina Panthers"
	| "Chicago Bears"
	| "Cincinnati Bengals"
	| "Cleveland Browns"
	| "Dallas Cowboys"
	| "Denver Broncos"
	| "Detroit Lions"
	| "Green Bay Packers"
	| "Houston Texans"
	| "Indianapolis Colts"
	| "Jacksonville Jaguars"
	| "Kansas City Chiefs"
	| "Los Angeles Chargers"
	| "Los Angeles Rams"
	| "Las Vegas Raiders"
	| "Miami Dolphins"
	| "Minnesota Vikings"
	| "New England Patriots"
	| "New Orleans Saints"
	| "New York Giants"
	| "New York Jets"
	| "Philadelphia Eagles"
	| "Pittsburgh Steelers"
	| "Seattle Seahawks"
	| "San Francisco 49ers"
	| "Tampa Bay Buccaneers"
	| "Tennessee Titans"
	| "Washington Commanders";

export const AIEditorOptions: AIEditorOption[] = [
	{
		label: AIEditorOptionsEnum.Background,
		icon: BackgroundImage,
		isEnabled: false,
		imageType: ImageEnum.Static,
	},
	{
		label: AIEditorOptionsEnum.Objects,
		icon: ObjectsImage,
		isEnabled: true,
		imageType: ImageEnum.Interactive,
	},
	{
		label: AIEditorOptionsEnum.Presenters,
		icon: PresentersImage,
		isEnabled: false,
		imageType: ImageEnum.Static,
	},
	{
		label: AIEditorOptionsEnum.Style,
		icon: StyleImage,
		isEnabled: false,
		imageType: ImageEnum.Static,
	},
	{
		label: AIEditorOptionsEnum.Text,
		icon: TextImage,
		isEnabled: false,
		imageType: ImageEnum.Static,
	},
	{
		label: AIEditorOptionsEnum.Size,
		icon: SizeImage,
		isEnabled: false,
		imageType: ImageEnum.Static,
	},
	{
		label: AIEditorOptionsEnum.Brand,
		icon: BrandImage,
		isEnabled: false,
		imageType: ImageEnum.Static,
	},
];
