import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionSummary, Box, Divider, FormControlLabel, Slider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BriaCheckbox from "../../../../components/common/BriaCheckbox/BriaCheckbox.tsx";
import BriaInput from "../../../../components/common/BriaInput/BriaInput.tsx";
import StaticDropdown, { AspectRatio } from "../../../../components/common/DropDowns/StaticDropdown.tsx";
import TextToImageMediumsDropdown from "../../../../components/common/DropDowns/TextToImageMediumsDropdown.tsx";
import TextToImageModelsDropdown, {
	getModelKey,
	getModelName,
	getModelVersion,
} from "../../../../components/common/DropDowns/TextToImageModelsDropdown/TextToImageModelsDropdown.tsx";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import InputLayout from "../../../../layout/InputLayout/InputLayout.tsx";
import styles from "./TextToImageConfig.module.scss";

export const tailoredModelsConfig = {
	steps_num: 8,
	max_results: 4,
	steps: {
		min: 4,
		max: 20,
	},
};

const TextToImageConfig = () => {
	const { t } = useTranslation("translation", { keyPrefix: "playground.textToImage.config" });
	const { textToImageStore } = useAppStore();
	const [numResults, setNumResults] = useState(0);
	const [steps, setSteps] = useState(0);
	const [hideNegativePrompt, setHideNegativePrompt] = useState<boolean>(true);

	useEffect(() => {
		if (textToImageStore.isTextToVector()) {
			textToImageStore.handleConfigChange("prompt_enhancement", false);
			textToImageStore.handleConfigChange("format", "svg");
		} else {
			textToImageStore.handleConfigChange("prompt_enhancement", true);
			textToImageStore.handleConfigChange("format", "png");
		}
	}, []);

	useEffect(() => {
		if (textToImageStore.config.model) {
			textToImageStore.handleConfigChange(
				"steps_num",
				textToImageStore.models[textToImageStore.config.model]?.steps?.default,
			);
			setSteps(textToImageStore.models[textToImageStore.config.model]?.steps?.default);
			textToImageStore.handleConfigChange(
				"num_results",
				textToImageStore.models[textToImageStore.config.model]?.max_results,
			);
			setNumResults(textToImageStore.models[textToImageStore.config.model]?.max_results);
		}

		if (textToImageStore.config.tailored_model_id) {
			textToImageStore.handleConfigChange("medium", "");
		}
	}, [textToImageStore.config.model]);

	useEffect(() => {
		if (textToImageStore.config.model_url) {
			textToImageStore.handleConfigChange("steps_num", tailoredModelsConfig.steps_num);
			setSteps(tailoredModelsConfig.steps_num);
			textToImageStore.handleConfigChange("num_results", tailoredModelsConfig.max_results);
			setNumResults(tailoredModelsConfig.max_results);
		}
	}, [textToImageStore.config.model_url]);

	const handleNumResultsChange = (_event: any, numResults: number | number[]) => {
		textToImageStore.handleConfigChange("num_results", numResults as number);
		setNumResults(numResults as number);
	};

	const handleStepsChange = (_event: any, steps: number | number[]) => {
		textToImageStore.handleConfigChange("steps_num", steps as number);
		setSteps(steps as number);
	};

	const clearFoundationModelsSelections = () => {
		textToImageStore.handleConfigChange("model", "");
		textToImageStore.handleConfigChange("model_version", "");
	};

	const clearTailoredModelsSelections = () => {
		textToImageStore.handleConfigChange("model_url", undefined);
		textToImageStore.handleConfigChange("tailored_model_id", undefined);
	};

	const handleModelChange = (e: any, selectedOption: any) => {
		setHideNegativePrompt(false);
		const isTailoredModel = selectedOption && selectedOption?.extraData?.isTailoredModel;
		textToImageStore.handleConfigChange("model_name", selectedOption.key);

		if (!isTailoredModel) {
			clearTailoredModelsSelections();
			textToImageStore.handleConfigChange("model", getModelName(e.target.value as string));
			textToImageStore.handleConfigChange("model_version", getModelVersion(e.target.value as string));
			if (selectedOption.value.toLowerCase().includes("fast")) {
				setHideNegativePrompt(true);
				textToImageStore.handleConfigChange("negative_prompt", undefined);
			}
		} else {
			clearFoundationModelsSelections();
			textToImageStore.handleConfigChange("model_url", selectedOption?.extraData?.endpointModelName);
			textToImageStore.handleConfigChange("tailored_model_id", selectedOption?.id);
			setHideNegativePrompt(true);
			textToImageStore.handleConfigChange("negative_prompt", undefined);
		}
	};

	return (
		<Box className={styles.config}>
			<InputLayout label={t("models.label")} labelClassName={styles.inputLabel}>
				<TextToImageModelsDropdown
					selectedValue={
						textToImageStore.config.tailored_model_id ||
						getModelKey(textToImageStore.config.model, textToImageStore.config.model_version)
					}
					handleChange={handleModelChange}
					placeholder={t("models.placeholder")}
				/>
			</InputLayout>
			<InputLayout label={t("aspectRatio.label")} labelClassName={styles.inputLabel}>
				<StaticDropdown
					type="aspectRatio"
					value={textToImageStore.config?.aspect_ratio}
					onChange={(e) => textToImageStore.handleConfigChange("aspect_ratio", e.target.value as AspectRatio)}
					placeholder={t("aspectRatio.placeholder")}
				/>
			</InputLayout>
			{!textToImageStore.isTextToVector() && !textToImageStore.config.tailored_model_id && (
				<InputLayout label={t("medium.label")} labelClassName={styles.inputLabel}>
					<TextToImageMediumsDropdown
						value={textToImageStore.config?.medium}
						onChange={(e) => textToImageStore.handleConfigChange("medium", e.target.value as string)}
						defaultOption
						placeholder={t("medium.placeholder")}
					/>
				</InputLayout>
			)}
			<Divider className={styles.divider} />
			<Accordion elevation={0} disableGutters TransitionProps={{ timeout: 200 }}>
				<AccordionSummary color="initial" expandIcon={<ExpandMore />}>
					{t("advanced")}
				</AccordionSummary>
				<Box className={styles.advanced}>
					{!hideNegativePrompt && !textToImageStore.hideNegativePrompt && (
						<InputLayout
							label={t("negativePrompt.label")}
							labelClassName={styles.inputLabel}
							info={t("negativePrompt.info")}
						>
							<BriaInput
								value={textToImageStore.config?.negative_prompt}
								onChange={(e) => textToImageStore.handleConfigChange("negative_prompt", e.target.value)}
								multiline
								minRows={4}
								placeholder={t("negativePrompt.placeholder")}
								inputProps={{ className: styles.inputBase }}
							/>
						</InputLayout>
					)}
					<InputLayout
						label={t("numberOfImages.label")}
						labelClassName={styles.inputLabel}
						info={t("numberOfImages.info")}
					>
						<Slider
							value={numResults}
							onChange={handleNumResultsChange}
							step={1}
							min={1}
							max={
								textToImageStore.config.model_url
									? tailoredModelsConfig.max_results
									: textToImageStore.models[textToImageStore.config.model]?.max_results
							}
							marks={Array.from(
								{
									length: textToImageStore.config.model_url
										? tailoredModelsConfig.max_results
										: textToImageStore.models[textToImageStore.config.model]?.max_results,
								},
								(_, index) => ({ value: index + 1, label: `${index + 1}` }),
							)}
						/>
					</InputLayout>
					<InputLayout label={t("steps.label")} labelClassName={styles.inputLabel} info={t("steps.info")}>
						<Slider
							value={steps}
							onChange={handleStepsChange}
							step={1}
							min={
								textToImageStore.config.model_url
									? tailoredModelsConfig.steps.min
									: textToImageStore.models[textToImageStore.config.model]?.steps.min
							}
							max={
								textToImageStore.config.model_url
									? tailoredModelsConfig.steps.max
									: textToImageStore.models[textToImageStore.config.model]?.steps.max
							}
							valueLabelDisplay="on"
						/>
					</InputLayout>
					<InputLayout
						label={t("seedNumber.label")}
						labelClassName={styles.inputLabel}
						info={t("seedNumber.info")}
					>
						<BriaInput
							type="number"
							value={textToImageStore.config?.seed}
							onChange={(e) =>
								textToImageStore.handleConfigChange(
									"seed",
									e.target.value ? parseFloat(e.target.value) : undefined,
								)
							}
							placeholder={t("seedNumber.placeholder")}
							height="37px"
							inputProps={{ className: styles.inputBase }}
						/>
						<FormControlLabel
							control={
								<BriaCheckbox
									checked={textToImageStore.config?.displaySeed}
									onChange={(e) =>
										textToImageStore.handleConfigChange("displaySeed", e.target.checked)
									}
								/>
							}
							label={t("seedNumber.checkbox")}
						/>
					</InputLayout>
				</Box>
			</Accordion>
		</Box>
	);
};

export default observer(TextToImageConfig);
