import { useState } from "react";
import TextAlignmentIcon from "../../../assets/images/icons/Alignment/Text/TextAlignmentIcon";
import { HorizontalEnum, TextDirectionEnum, VerticalEnum } from "../../../models/common";
import FontPopover from "./FontPopover";

type Props = {
	fontId?: number;
	handleFontChange?: (fontId: number) => void;
	horizontalAlignment?: HorizontalEnum;
	handleHorizontalChange?: (alignment: HorizontalEnum) => void;
	disableHorizontal?: boolean;
	hideHorizontal?: boolean;
	hideTextHeight?: boolean;
	disableTextHeight?: boolean;
	verticalAlignment?: VerticalEnum;
	handleVerticalChange?: (alignment: VerticalEnum) => void;
	disableVertical?: boolean;
	hideVertical?: boolean;
	textDirection?: TextDirectionEnum;
	handleDirectionChange?: (alignment: TextDirectionEnum) => void;
	disableTextDirection?: boolean;
	hideTextDirection?: boolean;
};

const FontPopoverButton = ({
	fontId,
	handleFontChange = () => {},
	horizontalAlignment,
	handleHorizontalChange,
	disableHorizontal,
	hideHorizontal,
	hideTextHeight,
	disableTextHeight,
	verticalAlignment,
	handleVerticalChange,
	disableVertical,
	hideVertical,
	textDirection,
	handleDirectionChange,
	disableTextDirection,
	hideTextDirection,
}: Props) => {
	const [anchorEl, setAnchorEl] = useState<SVGSVGElement>();

	const handleOpenDialog = (event: React.MouseEvent<SVGSVGElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	return (
		<>
			<TextAlignmentIcon
				sx={{
					width: "18px",
					cursor: "pointer",
				}}
				onClick={handleOpenDialog}
			/>
			<FontPopover
				open={!!anchorEl}
				{...{
					fontId,
					handleFontChange,
					horizontalAlignment,
					handleHorizontalChange,
					disableHorizontal,
					hideHorizontal,
					hideTextHeight,
					disableTextHeight,
					verticalAlignment,
					handleVerticalChange,
					disableVertical,
					hideVertical,
					textDirection,
					handleDirectionChange,
					disableTextDirection,
					hideTextDirection,
					handleClose,
					anchorEl,
				}}
			/>
		</>
	);
};

export default FontPopoverButton;
