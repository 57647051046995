export const CopyIcon = ({ strokeColor = "#5B5B5B", width = "24", height = "24" }) => {
	return (
		<svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.3333 4.66683V3.00016C11.3333 2.55814 11.1577 2.13421 10.8451 1.82165C10.5325 1.50909 10.1086 1.3335 9.66659 1.3335H2.99992C2.55789 1.3335 2.13397 1.50909 1.82141 1.82165C1.50885 2.13421 1.33325 2.55814 1.33325 3.00016V9.66683C1.33325 10.1089 1.50885 10.5328 1.82141 10.8453C2.13397 11.1579 2.55789 11.3335 2.99992 11.3335H4.66659M6.33325 4.66683H12.9999C13.9204 4.66683 14.6666 5.41302 14.6666 6.3335V13.0002C14.6666 13.9206 13.9204 14.6668 12.9999 14.6668H6.33325C5.41278 14.6668 4.66659 13.9206 4.66659 13.0002V6.3335C4.66659 5.41302 5.41278 4.66683 6.33325 4.66683Z"
				stroke={strokeColor}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
