import { NavigateBefore } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import BriaButton from "../../../components/common/BriaButton/BriaButton";
import styles from "./SubSwipableMenu.module.scss";

export type SubSwipableMenuProps = {
	onBackButtonClick: () => void;
	title?: string;
	backButton?: ReactNode;
	children?: ReactNode;
};

const SubSwipableMenu = ({ title, onBackButtonClick, backButton, children }: SubSwipableMenuProps) => {
	const { t } = useTranslation("translation", { keyPrefix: "subSwipableMenuLayout" });

	return (
		<Box className={styles.root}>
			<Box className={styles.header}>
				{title && <Box>{title}</Box>}
				{backButton ? (
					backButton
				) : (
					<BriaButton buttonType="textSmall" onClick={onBackButtonClick} startIcon={<NavigateBefore />}>
						{t("backButton")}
					</BriaButton>
				)}
			</Box>
			<Divider className={styles.divider} />
			{children}
		</Box>
	);
};

export default observer(SubSwipableMenu);
