import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

function TextAlignmentIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="18" height="18" viewBox="0 0 18 18" {...props}>
			<path
				d="M14.9999 5.43457H0.999939M0.999939 1.43457H14.9999M9.99994 9.43457H0.999939M0.999939 13.4346H6.99994M13.9999 10.4346V16.4346M10.9999 13.4346H16.9999"
				fill="none"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default TextAlignmentIcon;
