function FullView({ size }: { size: number }) {
	return (
		<svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M18.75 13.8828L20.25 12.3828L18.75 10.8828"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.25 10.8828L3.75 12.3828L5.25 13.8828"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<rect
				x="7.5"
				y="6.38281"
				width="9"
				height="12"
				stroke="#5B5B5B"
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default FullView;
