export const textTypesStyles = [
	{
		id: "heading1",
		type: "Heading #1",
		text: "Heading",
		style: {
			color: "#1A0638",
			fontSize: "28px",
			fontWeight: 600,
			lineHeight: "16px",
			letterSpacing: "0.56px",
		},
	},
	{
		id: "heading2",
		type: "Heading #2",
		text: "Heading",
		style: {
			color: "#1A0638",
			fontSize: "20px",
			fontWeight: 700,
			lineHeight: "16px",
			letterSpacing: "0.4px",
		},
	},
	{
		id: "heading3",
		type: "Heading #3",
		text: "Heading",
		style: {
			color: "#1A0638",
			fontSize: "16px",
			fontWeight: 700,
			lineHeight: "16px",
			letterSpacing: "0.32px",
		},
	},
	{
		id: "heading4",
		type: "Heading #4",
		text: "Heading",
		style: {
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 700,
			lineHeight: "16px",
			letterSpacing: "0.28px",
		},
	},
	{
		id: "heading5",
		type: "Heading #5",
		text: "Heading",
		style: {
			color: "#1A0638",
			fontSize: "12px",
			fontWeight: 700,
			lineHeight: "16px",
			letterSpacing: "0.24px",
		},
	},
	{
		id: "heading6",
		type: "Heading #6",
		text: "Heading",
		style: {
			color: "#1A0638",
			fontSize: "10px",
			fontWeight: 700,
			lineHeight: "16px",
			letterSpacing: "0.2px",
		},
	},
	{
		id: "body1",
		type: "Body #1",
		text: "Body",
		style: {
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 500,
			lineHeight: "16px",
			letterSpacing: "0.28px",
		},
	},
	{
		id: "body2",
		type: "Body #2",
		text: "Body",
		style: {
			color: "#1A0638",
			fontSize: "14px",
			fontWeight: 400,
			lineHeight: "16px",
			letterSpacing: "0.28px",
		},
	},
	{
		id: "body3",
		text: "Body",
		style: {
			color: "#1A0638",
			fontSize: "12px",
			fontWeight: 500,
			lineHeight: "16px",
			letterSpacing: "0.24px",
		},
	},
	{
		id: "body4",
		type: "Body #4",
		text: "Body",
		style: {
			color: "#1A0638",
			fontSize: "12px",
			fontWeight: 400,
			lineHeight: "16px",
			letterSpacing: "0.24px",
		},
	},
	{
		id: "body5",
		type: "Body #5",
		text: "Body",
		style: {
			color: "#1A0638",
			fontSize: "10px",
			fontWeight: 500,
			lineHeight: "16px",
			letterSpacing: "0.2px",
		},
	},
	{
		id: "body6",
		type: "Body #6",
		text: "Body",
		style: {
			color: "#1A0638",
			fontSize: "10px",
			fontWeight: 400,
			lineHeight: "16px",
			letterSpacing: "0.2px",
		},
	},
];
export interface TextStyle {
	color: string;
	fontSize: string;
	fontWeight: number;
	lineHeight: string;
	letterSpacing: string;
}

export const textStyles: Record<string, TextStyle> = textTypesStyles.reduce(
	(acc, { id, style }) => {
		acc[id] = style;
		return acc;
	},
	{} as Record<string, TextStyle>,
);

export type TextTypeClass =
	| "heading1"
	| "heading2"
	| "heading3"
	| "heading4"
	| "heading5"
	| "heading6"
	| "body1"
	| "body2"
	| "body3"
	| "body4"
	| "body5"
	| "body6";
