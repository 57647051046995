import { HorizontalEnum, StatusEnum, TextDirectionEnum, VerticalEnum } from "./common";
import { Placement, defaultPlacement } from "./placement";
export type ObjectPlacement = {
	x: number;
	y: number;
	width: number;
	height: number;
};

export type Alignment = {
	horizontal?: HorizontalEnum;
	vertical?: VerticalEnum;
};

export type LayoutMessage = {
	placement?: ObjectPlacement;
	alignment?: Alignment;
	text_direction?: TextDirectionEnum;
	row_height?: number;
	font_id?: number;
};

export type Foreground = {
	placement: ObjectPlacement;
	alignment?: Alignment;
};

export type Background = {
	placement?: ObjectPlacement;
};

export type LogoProps = {
	placement?: ObjectPlacement;
	alignment?: Alignment;
};

export type Cta = {
	placement?: ObjectPlacement;
	alignment?: Alignment;
};

export type GraphicalElement = {
	element: string;
	placement?: ObjectPlacement;
};

export type LayoutLayer = Exclude<keyof LayoutObjects, "graphical_elements">;

export type Layout = {
	id: number;
	name: string;
	description: string;
	order: LayoutLayer[];
	status: StatusEnum;
	image_example?: string;
	template_id: number;
	placement_id: number;
	placement: Placement;
	created_at: Date;
	updated_at: Date;
} & LayoutObjects;

export type LayoutObjects = {
	primary_message: LayoutMessage;
	secondary_message: LayoutMessage;
	foreground: Foreground;
	background: Background;
	cta: Cta;
	logo: LogoProps;
	graphical_elements?: GraphicalElement[];
};

export const LayoutLayersEnum: { [key in LayoutLayer]: string } = {
	primary_message: "Main Text",
	secondary_message: "Secondary Text",
	background: "Background",
	cta: "CTA",
	foreground: "Object",
	logo: "Logo",
};

export const defaultObjectPlacement: ObjectPlacement = {
	x: NaN,
	y: NaN,
	width: NaN,
	height: NaN,
};

export const defaultGraphic: GraphicalElement = {
	element: "",
};

export const defaultLayout: Layout = {
	id: NaN,
	name: "Untitled - Layout Name",
	description: "",
	primary_message: {},
	secondary_message: {},
	foreground: { placement: defaultObjectPlacement },
	background: {},
	cta: {},
	logo: {},
	order: ["primary_message", "secondary_message", "logo", "cta", "foreground", "background"],
	status: StatusEnum.PENDING,
	template_id: NaN,
	placement_id: NaN,
	placement: defaultPlacement,
	created_at: new Date(),
	updated_at: new Date(),
};
