import { Typography } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import thanksForSubscribing from "../../../../assets/images/pngs/thanksForSubscribing.png";
import { ORG_SUBSCRIPTION_PLANS } from "../../../../constants/billing.ts";
import { useAppStore } from "../../../../hooks/useStores.tsx";
import ConfirmationPopup from "../../../common/ConfirmationPopup/ConfirmationPopup.tsx";
import { PRICING_STEPS } from "../store.tsx";
import styles from "./ThankYouCheckoutPopup.module.scss";

const ThankYouCheckoutPopup = () => {
	const { t } = useTranslation("translation", { keyPrefix: "pricingFlow" });
	const { pricingStore, authStore } = useAppStore();
	const tPlanName = Object.keys(ORG_SUBSCRIPTION_PLANS).includes(pricingStore.selectedPlan?.name ?? "")
		? pricingStore.selectedPlan?.name
		: ORG_SUBSCRIPTION_PLANS.custom.name;

	const onClose = () => {
		if (pricingStore.selectedPlan?.name.toLowerCase() !== authStore.orgSubscription?.plan_name.toLowerCase()) {
			window.location.reload();
		} else {
			pricingStore.closePricingFlow();
		}
	};

	return (
		<ConfirmationPopup
			onClose={onClose}
			hideCloseIcon={true}
			paperClassName={clsx(styles.thanksForSubscribingDialog, styles.popupPaper)}
			title={t(`thanksForSubscribingDialog.${tPlanName}.title`)}
			description={
				<Typography className={styles.boldDescription}>
					{t(`thanksForSubscribingDialog.${tPlanName}.description`, {
						plan: pricingStore.selectedPlan?.displayName,
					})}
				</Typography>
			}
			fullWidth={false}
			hideCancel={true}
			subDescription={
				<Typography className={styles.lightDescription}>
					{t(`thanksForSubscribingDialog.${tPlanName}.subDescription`, {
						plan: pricingStore.selectedPlan?.displayName,
					})}
				</Typography>
			}
			confirmButtonText={t(`thanksForSubscribingDialog.${tPlanName}.buttonText`)}
			image={thanksForSubscribing}
			open={pricingStore.activeStep === PRICING_STEPS.CHECKOUT_THANK_YOU_POPUP}
			onClick={onClose}
		/>
	);
};

export default observer(ThankYouCheckoutPopup);
