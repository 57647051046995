import { Feedback } from "../pages/Playground/Images/Overlay/PlaygroundImageOverlay.tsx";
import QueryService from "../utils/QueryService.ts";

class ImagesService {
	queryService = new QueryService("/images/");

	exportPsd = async (imageUrl: string): Promise<string> => {
		const { url: psdUrl } = await this.queryService.post("export_psd", {
			url: imageUrl,
		});
		return psdUrl;
	};

	sendFeedback = (newFeedback: Feedback): Promise<any> => {
		return this.queryService.post("feedback", newFeedback);
	};
}

export default ImagesService;
