import { Box } from "@mui/material";
import clsx from "clsx";
import TextCenterIcon from "../../../assets/images/icons/Alignment/Text/Horizontal/TextCenterIcon";
import TextLeftIcon from "../../../assets/images/icons/Alignment/Text/Horizontal/TextLeftIcon";
import TextRightIcon from "../../../assets/images/icons/Alignment/Text/Horizontal/TextRightIcon";
import { HorizontalEnum } from "../../../models/common";
import styles from "./FontPopover.module.scss";

type Props = {
	value?: HorizontalEnum;
	handleChange?: (alignment: HorizontalEnum) => void;
	disable?: boolean;
};

const TextHorizontalButtons = ({ value, handleChange, disable }: Props) => {
	return (
		<Box className={styles.iconsGroup}>
			<TextLeftIcon
				className={clsx({
					[styles.selected]: value === HorizontalEnum.LEFT,
					[styles.disabled]: disable,
				})}
				onClick={() => handleChange?.(HorizontalEnum.LEFT)}
			/>
			<TextCenterIcon
				className={clsx({
					[styles.selected]: value === HorizontalEnum.CENTER,
					[styles.disabled]: disable,
				})}
				onClick={() => handleChange?.(HorizontalEnum.CENTER)}
			/>
			<TextRightIcon
				className={clsx({
					[styles.selected]: value === HorizontalEnum.RIGHT,
					[styles.disabled]: disable,
				})}
				onClick={() => handleChange?.(HorizontalEnum.RIGHT)}
			/>
		</Box>
	);
};

export default TextHorizontalButtons;
