import { Box, InputBaseComponentProps } from "@mui/material";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DeleteIcon from "../../../assets/images/icons/DeleteIcon";
import ImageIcon from "../../../assets/images/icons/Image/ImageIcon";
import { showErrorToast } from "../../../utils/toast";
import { supportedImageFileTypes } from "../../../utils/useImageUtils";
import LoadingPlaceholder from "../LoadingPlaceholder/LoadingPlaceholder";
import { VisuallyHiddenInput } from "../VisuallyHiddenInput";
import styles from "./ImageUploader.module.scss";

type Props = {
	src?: string;
	description?: string | ReactNode;
	showPreview?: boolean;
	loading?: boolean;
	disabled?: boolean;
	inputProps?: InputBaseComponentProps;
	onUpload?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<any>;
	onDelete?: () => Promise<void>;
	className?: string;
	icon?: string;
	largeIcon?: boolean;
	emptyStateClassName?: string;
	titleClassName?: string;
	descriptionClassName?: string;
	maxFilesLimit?: number;
	maxFileSize?: number;
};

const defaultAcceptTypes = supportedImageFileTypes.map((type) => `image/${type}`).join(", ");

const ImageUploader = ({
	src,
	description,
	icon,
	largeIcon,
	showPreview = true,
	loading = false,
	disabled = false,
	inputProps,
	onUpload,
	onDelete,
	className,
	emptyStateClassName,
	titleClassName,
	descriptionClassName,
	maxFilesLimit = Infinity,
	maxFileSize = Infinity,
}: Props) => {
	const [blobUrl, setBlobUrl] = useState<string>();
	const { t } = useTranslation("translation", { keyPrefix: "playground" });

	useEffect(() => {
		setBlobUrl(undefined);
	}, [src]);

	const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		const files: File[] | null = Array.from(e.target.files || []);

		if (files.length > maxFilesLimit) {
			showErrorToast(t("imageToImage.filesExceedLimit"));
			return;
		}

		const oversizedFile = files.find((file) => file.size > maxFileSize);
		if (oversizedFile) {
			showErrorToast(t("imageToImage.fileExceedsSize"));
			return;
		}

		if (file) {
			const url = URL.createObjectURL(file);
			setBlobUrl(url);
			await onUpload?.(e);
		}

		e.target.value = "";
	};

	const handleDelete = async (e: React.MouseEvent<SVGElement, MouseEvent>) => {
		e.preventDefault();
		if (blobUrl) {
			URL.revokeObjectURL(blobUrl);
			setBlobUrl(undefined);
		}
		await onDelete?.();
	};
	const renderFileInput = () => (
		<VisuallyHiddenInput
			type="file"
			id="fileInput"
			style={{ display: "none" }}
			inputProps={{ ...inputProps, accept: inputProps?.accept ?? defaultAcceptTypes }}
			onChange={handleUpload}
			disabled={disabled}
		/>
	);

	const supportedFiles = (inputProps?.accept || defaultAcceptTypes)
		.split("image/")
		.map((type: string) => type.trim().toUpperCase())
		.join(" ");

	return (
		<>
			<Box
				className={`${className} ${styles.container}`}
				component="label"
				sx={{ cursor: disabled ? "not-allowed" : "pointer" }}
			>
				{renderFileInput()}
				{showPreview && (blobUrl || src || loading) ? (
					<LoadingPlaceholder className={styles.loading} isLoading={loading}>
						<Box className={styles.imgContainer}>
							<DeleteIcon className={styles.deleteIcon} onClick={handleDelete} />
							<LazyLoadImage className={styles.img} src={src || blobUrl} />
						</Box>
					</LoadingPlaceholder>
				) : (
					<Box className={`${emptyStateClassName} ${styles.emptyState}`}>
						{icon ? (
							<img
								className={clsx({
									[styles.image]: true,
									[styles.largeIcon]: largeIcon,
								})}
								src={icon}
								alt=""
							/>
						) : (
							<ImageIcon />
						)}
						<Box className={`${styles.description} ${titleClassName}`}>{description}</Box>
						<Box className={`${styles.support} ${descriptionClassName}`}>
							{t("emptyState.supportedFiles")} {supportedFiles} {/*t("emptyState.upTo")*/}
						</Box>
					</Box>
				)}
			</Box>
		</>
	);
};

export default observer(ImageUploader);
