import { makeAutoObservable, runInAction } from "mobx";
import { FilterByOptions } from "../../../components/common/BriaTable/BriaTable";
import { PaginatedItems } from "../../../models/common";
import { Layout, LayoutLayer, LayoutLayersEnum, LayoutObjects, ObjectPlacement } from "../../../models/layout";
import QueryService from "../../../utils/QueryService";
import { hasNonEmptyValues } from "../../../utils/validators";

export interface ILayoutsStore {
	paginatedLayouts: PaginatedItems<Layout>;
	rowsPerPage: number;
	loadLayoutsRequests: number;
	isLoading: boolean;
	isLoadingEditor: boolean;
	isLoadingSave: boolean;
	isLoadingImgExample: boolean;
	loadingGraphicIndex: number;
	isError: boolean;
	configErrors: Partial<Layout>;
	getLayout(layoutId: number): Promise<Layout>;
	loadLayouts(templateId: number, pageNumber: number, filterBy?: FilterByOptions): Promise<void>;
	createLayout(newLayout: Layout): Promise<number>;
	updateLayout(updatedLayout: Layout): Promise<void>;
	uploadImgExample(layoutId: number, templateId: number, imgFile: File): Promise<string>;
	deleteImgExample(layoutId: number, templateId: number, fileName: string): Promise<void>;
	uploadGraphics(
		filesWithIndexes: { file: File; graphicIndex: number }[],
		layoutId: number,
		templateId: number,
	): Promise<string[]>;
	deleteGraphics(fileName: string[], layoutId: number, templateId: number): Promise<void>;
	validate(layout: Layout): boolean;
}

export default class LayoutsStore implements ILayoutsStore {
	private queryService: QueryService = new QueryService("/layouts");
	private templateService: QueryService = new QueryService("/templates");
	paginatedLayouts: PaginatedItems<Layout> = { total: 0, items: [] };
	rowsPerPage: number = 20;
	loadLayoutsRequests: number = 0;
	isLoading: boolean = false;
	isLoadingEditor: boolean = false;
	isLoadingSave: boolean = false;
	isLoadingImgExample: boolean = false;
	loadingGraphicIndex: number = NaN;
	isError: boolean = false;
	configErrors: Partial<Layout> = {};

	constructor() {
		makeAutoObservable(this);
	}

	loadLayouts = async (templateId: number, pageNumber: number, filterBy?: FilterByOptions): Promise<void> => {
		try {
			this.isError = false;
			this.isLoading = true;

			const layouts: PaginatedItems<Layout> = templateId
				? await this.templateService.get(`/${templateId}/layouts`, {
						params: {
							filter_by: filterBy,
							page: pageNumber,
							per_page: this.rowsPerPage,
						},
				  })
				: { items: [], total: 0 };

			runInAction(() => {
				this.paginatedLayouts = layouts;
				this.isError = false;
				this.isLoading = false;
			});
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			throw e;
		}
	};

	getLayout = async (layoutId: number): Promise<Layout> => {
		try {
			this.isLoadingEditor = true;
			const layout = await this.queryService.get(`/${layoutId}`);
			runInAction(() => {
				this.isLoadingEditor = false;
			});
			return layout;
		} catch (e) {
			runInAction(() => {
				this.isLoadingEditor = false;
				this.isError = true;
			});

			return Promise.reject(e);
		}
	};

	createLayout = async (newLayout: Layout): Promise<number> => {
		this.isLoadingSave = true;
		this.isError = false;
		try {
			const layoutId = await this.queryService.post("/", newLayout);
			runInAction(() => {
				this.isLoadingSave = false;
				this.isError = false;
			});

			return layoutId;
		} catch (e: any) {
			runInAction(() => {
				if (e.response?.status === 409) {
					this.configErrors = { ...this.configErrors, name: "Invalid or existing name" };
				} else if (e.response?.status === 400) {
					this.configErrors = e.response?.data;
				}
				this.isLoadingSave = false;
				this.isError = true;
			});
			return Promise.reject(`Error creating new layout: ${e.message || e.toString()}`);
		}
	};

	updateLayout = async (updatedLayout: Layout): Promise<void> => {
		this.isLoadingSave = true;
		this.isError = false;
		try {
			await this.queryService.put("/", updatedLayout);
			runInAction(() => {
				this.paginatedLayouts.items = this.paginatedLayouts?.items.map((layout) =>
					layout.id === updatedLayout.id ? updatedLayout : layout,
				);
				this.isLoadingSave = false;
				this.isError = false;
			});
		} catch (e: any) {
			runInAction(() => {
				if (e.response?.status === 409) {
					this.configErrors = { ...this.configErrors, name: "Invalid or existing name" };
				} else if (e.response?.status === 400) {
					this.configErrors = e.response?.data;
				}
				this.isLoadingSave = false;
				this.isError = true;
			});
			return Promise.reject(`Error updating layout: ${e.message || e.toString()}`);
		}
	};

	uploadImgExample = async (layoutId: number, templateId: number, imgFile: File): Promise<string> => {
		this.isLoadingImgExample = true;
		try {
			const formData = new FormData();
			formData.append("file", imgFile);
			const path = await this.queryService.post(
				`/upload_img/${layoutId}`,
				formData,
				{
					"Content-Type": "multipart/form-data",
				},
				{ params: { templateId } },
			);
			runInAction(() => {
				this.isLoadingImgExample = false;
				this.isError = false;
				this.paginatedLayouts.items = this.paginatedLayouts?.items.map((layout) =>
					layout.id === layoutId ? { ...layout, image_example: path } : layout,
				);
			});
			return path;
		} catch (e: any) {
			runInAction(() => {
				this.isLoadingImgExample = false;
				this.isError = true;
			});
			return Promise.reject(`Error uploading Image file: ${e.message || e.toString()}`);
		}
	};

	deleteImgExample = async (layoutId: number, templateId: number, fileName: string): Promise<void> => {
		try {
			this.isLoadingImgExample = true;
			await this.queryService.post(`/delete_img/${layoutId}`, { fileName, templateId });
			runInAction(() => {
				this.isLoadingImgExample = false;
				this.isError = false;
				this.paginatedLayouts.items = this.paginatedLayouts.items.map((layout) =>
					layout.id === layoutId ? { ...layout, image_example: undefined } : layout,
				);
			});
		} catch (e: any) {
			runInAction(() => {
				this.isLoadingImgExample = false;
				this.isError = true;
			});
			return Promise.reject(`Error uploading Image file: ${e.message || e.toString()}`);
		}
	};

	uploadGraphics = async (
		filesWithIndexes: { file: File; graphicIndex: number }[],
		layoutId: number,
		templateId: number,
	): Promise<string[]> => {
		try {
			this.isLoadingSave = true;

			const formData = new FormData();
			for (const { file, graphicIndex } of filesWithIndexes) {
				formData.append(`${graphicIndex}`, file);
			}

			const path = await this.queryService.post(
				`/upload_graphics/${layoutId}`,
				formData,
				{
					"Content-Type": "multipart/form-data",
				},
				{ params: { templateId } },
			);

			runInAction(() => {
				this.isLoadingSave = false;
				this.isError = false;
			});

			return path;
		} catch (error: any) {
			runInAction(() => {
				this.isLoadingSave = false;
				this.isError = true;
			});
			return Promise.reject(`Error uploading graphic files: ${error.message || error.toString()}`);
		}
	};

	deleteGraphics = async (fileNames: string[], layoutId: number, templateId: number): Promise<void> => {
		try {
			this.isLoadingSave = true;
			await this.queryService.post(`/delete_graphics/${layoutId}`, { fileNames, templateId });
			runInAction(() => {
				this.isLoadingSave = false;
				this.isError = false;
			});
		} catch (e: any) {
			runInAction(() => {
				this.isLoadingSave = false;
				this.isError = true;
			});
			return Promise.reject(`Error deleting Graphic files: ${e.message || e.toString()}`);
		}
	};

	validate = (layout: Layout): boolean => {
		runInAction(() => {
			this.validateZeros(layout);
		});
		return !hasNonEmptyValues(this.configErrors);
	};

	validateZeros = (layout: Layout) => {
		Object.entries(layout as LayoutObjects).forEach(([key, value]) => {
			if (key === "graphical_elements") {
				this.configErrors.graphical_elements = layout.graphical_elements?.map((graphic, index) =>
					this.validatePlacement(graphic, this.configErrors.graphical_elements?.[index]),
				);
			} else if (Object.keys(LayoutLayersEnum).includes(key)) {
				this.configErrors = {
					...this.configErrors,
					[key]: this.validatePlacement(value, this.configErrors?.[key as LayoutLayer]),
				};
			}
		});
	};

	validatePlacement = (value: any, configError: any) => {
		return {
			...configError,
			placement: {
				...configError?.placement,
				width: value.placement?.width <= 0 ? "Invalid Input" : configError?.placement?.width,
				height: value.placement?.height <= 0 ? "Invalid Input" : configError?.placement?.height,
			} as ObjectPlacement,
		};
	};
}
